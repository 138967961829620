import * as React from 'react';
import { Card } from '@mui/material';
import { DispatchCall, IAuthState, IRootState, RootAction } from '../../../@types/redux';
import { dataSetReports, dataSetPrintServers } from '../../../store/masterData/Actions';
import { bindActionCreators, Dispatch } from 'redux';
import { formatDateTime, isNullOrWhiteSpace, upsertArrayElement } from '../../../services/appFunctionsService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import Screen from '../../../components/Screen';
import { connect } from 'react-redux';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikHelpers } from 'formik';
import { createSelector } from 'reselect';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import { ConfirmationDialog, CustomTable } from '@zz2/zz2-ui';
import { v4 as uuidv4 } from 'uuid';
import { IReport, Report } from '../../../@types/model/masterData/report/report';
import { IPrintServer } from '../../../@types/model/masterData/printServer/printServer';
import ReportHttpService from '../../../services/http/masterData/reportHttpService';
import PrintServerHttpService from '../../../services/http/masterData/printServerHttpService';
import { IReportFormValues, ReportFormValues } from '../../../@types/model/masterData/report/reportFormValues';
import ReportForm from './form/ReportForm';
import { setReportMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface IReportScreenProps extends RouteComponentProps {
    dataSetReports : DispatchCall<Array<IReport>>;
    dataSetPrintServers : DispatchCall<Array<IPrintServer>>;
    reports : Array<IReport>;
    printServers : Array<IPrintServer>;
    auth : IAuthState;
}

interface IReportScreenState {
    rows : Array<IReport>;
    isLoading : boolean;
    selectedReport ?: IReport;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    formGuid ?: string;
    isDeletePopupOpen : boolean;
    deletingReport ?: IReport;
}

class ReportScreen extends React.Component<IReportScreenProps, IReportScreenState> {
    constructor(props : IReportScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedReport: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await ReportHttpService.getReportData();

                const reportData = res.data;
                if (reportData) {
                    const res2 = await PrintServerHttpService.getPrintServerData();

                    const printServers = res2.data;
                    this.props.dataSetPrintServers(printServers);
                    this.props.dataSetReports(reportData);
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading reports.');
            } finally {
                this.setLoading(false);
            }
        }
    };

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            formGuid: undefined,
            selectedReport: undefined,
        });
    };

    public openDialog = () => {
        this.setState({
            formGuid: uuidv4(),
            isDialogOpen: true,
        });
    };

    public editReport = (selectedReport : IReport) => {
        this.setState({
            isDialogOpen: true,
            selectedReport,
        });
        this.openDialog();
    };

    public onSubmit = async (values : IReportFormValues) => {
        this.setLoading(true);

        let payload = { ...values };
        if (isNullOrWhiteSpace(payload.guid)) {
            payload.guid = this.state.formGuid ?? '';
        }

        try {
            const res = await ReportHttpService.addOrUpdateReport(new Report(payload));

            const newReportList = upsertArrayElement(this.props.reports, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetReports(newReportList);
            await setReportMasterDataIndexedDB(newReportList);

            if (this.state.selectedReport) {
                generalShowSuccessSnackbar('Report updated successfully.');
            } else {
                generalShowSuccessSnackbar('Report added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating report data.');
        } finally {
            this.setLoading(false);
        }
    };

    private openDeleteConfirmationPopup = (report : IReport) => {
        this.setState({ isDeletePopupOpen: true, deletingReport: report });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingReport: undefined });
    };

    private removeReport = async () => {
        const newReport = this.state.deletingReport;
        if (newReport) {
            newReport.isActive = false;
            this.setLoading(true);

            try {
                const res = await ReportHttpService.deleteReport(newReport.id);

                const newReportList = upsertArrayElement(this.props.reports, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetReports(newReportList);
                await setReportMasterDataIndexedDB(newReportList);

                generalShowSuccessSnackbar('Report updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting report.');
                newReport.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    public onReset = async (formValues : IReportFormValues, formikHelpers : FormikHelpers<IReportFormValues>) => {
        formikHelpers.resetForm();
        this.closeDialog();
    };

    public getSelectedReport = (props : IReportScreenProps, state : IReportScreenState) => state.selectedReport;

    public getInitialFormValues = createSelector(
        [this.getSelectedReport],
        (report : IReport) => {
            return new ReportFormValues(report, this.props.printServers);
        },
    );

    private getPrintServerName = (printServerId : number) => {
        const printServer = this.props.printServers?.find(x => x.id === printServerId);
        return printServer?.name ?? '';
    };

    private getRights = (props : IReportScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IReportScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.props.reports ? this.props.reports : [];
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Report'
                    maxWidth={'xs'}
                    fullWidth
                    isEdit={!!this.state.selectedReport}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={ReportFormValues.formSchema}
                        component={ReportForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IReport>
                            enableAdding={this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.editReport}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(report : IReport) => report.isActive && this.hasEditingRight(this.props)}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            fitWidthToPage
                            enablePagination
                            columns={[
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Print Server', field: 'printServerId', formatFunction: this.getPrintServerName, enableFiltering: true, enableSorting: true },
                                { title: 'Can Palletize?', field: 'canLivePalletize', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IReport) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationDialog
                    title={'Delete Report'}
                    open={this.state.isDeletePopupOpen}
                    description={'Are you sure you want to delete this report?'}
                    onAccept ={this.removeReport}
                    onClose ={this.closeDeleteConfirmationPopup}
                    dialogType='orange'
                    isLoading={this.state.isLoading}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        reports: state.masterData.reports,
        printServers: state.masterData.printServers,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetReports, dataSetPrintServers }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportScreen);
