import { getState, dispatch } from '../Index';
import {
    dataSetPacks,
    dataSetSizes,
    dataSetColours,
    dataSetCountries,
    dataSetFarms,
    dataSetGrades,
    dataSetMarkets,
    dataSetOrchards,
    dataSetOrganizations,
    dataSetRegions,
    dataSetSites,
    dataSetVarieties,
    dataSetCommodities,
    dataSetCommodityConditions,
    dataSetPalletBaseTypes,
    dataSetPackCategories,
    dataSetInspectionPoints,
    dataSetBrands,
    dataSetLotTypes,
    dataSetCommodityStates,
    dataSetMaterials,
    dataSetMaterialTypes,
    dataSetUnitOfMeasures,
    dataSetUnitOfMeasureTypes,
    dataSetCarriers,
    dataSetStorageUnits,
    dataSetInventories,
    dataSetDepartments,
    dataSetContactInfos,
    dataSetTruckTypes,
    dataSetDevices,
    dataSetProjects,
    dataSetBarcodeRanges,
    dataSetAccreditations,
    dataSetPackLines,
    dataSetOutlets,
    dataSetReports,
    dataSetPrintServers,
    dataSetPrinters,
    dataSetAgreementCodes,
    dataSetSalesPoints,
    // dataSetSeasons,
    // dataSetDomains,
    dataSetMasterDataSyncData,
    dataSetFtpDetails,
    dataSetSiteSettings,
} from './Actions';
import { addArrayElement, setArrayElement } from '../../services/appFunctionsService';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { IPackRelatedData } from '../../@types/model/masterData/pack/packRelatedData';
import { ISize } from '../../@types/model/masterData/size/size';
import { IColour } from '../../@types/model/masterData/colour/colour';
import { IGrade } from '../../@types/model/masterData/grade/grade';
import { ICommodityState } from '../../@types/model/masterData/commodityState/commodityState';
import { IOrchard } from '../../@types/model/masterData/orchard/orchard';
import { ICarrier } from '../../@types/model/masterData/carrier/carrier';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { ICountry } from '../../@types/model/masterData/country/country';
import { IFarm } from '../../@types/model/masterData/farm/farm';
import { IPalletBaseType } from '../../@types/model/masterData/palletBaseType/palletBaseType';
import { IMarket } from '../../@types/model/masterData/market/market';
import { IBrand } from '../../@types/model/masterData/brand/brand';
import { ILotType } from '../../@types/model/masterData/lotType/lotType';
import { IRegion } from '../../@types/model/masterData/region/region';
import { ISite } from '../../@types/model/masterData/site/site';
import { IInspectionPoint } from '../../@types/model/masterData/inspectionPoint/inspectionPoint';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IMaterial } from '../../@types/model/masterData/material/material';
import { IMaterialType } from '../../@types/model/masterData/materialType/materialType';
import { IUnitOfMeasure } from '../../@types/model/masterData/unitOfMeasure/unitOfMeasure';
import { IUnitOfMeasureType } from '../../@types/model/masterData/unitOfMeasureType/unitOfMeasureType';
import { ICommodityCondition } from '../../@types/model/masterData/commodityCondition/commodityCondition';
import { IStorageUnit } from '../../@types/model/masterData/storageUnit/storageUnit';
import { IInventory } from '../../@types/model/masterData/inventory/inventory';
import { IDepartment } from '../../@types/model/masterData/department/department';
import { IContactInfo } from '../../@types/model/masterData/contactInfo/contactInfo';
import { ITruckType } from '../../@types/model/masterData/truckType/truckType';
import { IDevice } from '../../@types/model/masterData/device/device';
import { IProject } from '../../@types/model/masterData/project/project';
import { IBarcodeRange } from '../../@types/model/masterData/barcodeRange/barcodeRange';
import { IAccreditation } from '../../@types/model/masterData/accreditation/accreditation';
import { IPackLine } from '../../@types/model/masterData/packLine/packLine';
import { IOutlet } from '../../@types/model/masterData/outlet/outlet';
import { IPrinter } from '../../@types/model/masterData/printer/printer';
import { IPrintServer } from '../../@types/model/masterData/printServer/printServer';
import { IReport } from '../../@types/model/masterData/report/report';
import { IAgreementCode } from '../../@types/model/masterData/agreementCode/agreementCode';
import { ISalesPoint } from '../../@types/model/masterData/salesPoint/salesPoint';
import { IStorageUnitDashboard } from '../../@types/model/masterData/storageUnit/storageUnitDashboardData';
import { dataSetLots } from '../data/Actions';
import { IMasterDataSyncData } from '../../@types/model/masterData/sync/MasterDataSyncData';
import { IFtpDetail } from '../../@types/model/masterData/ftpDetail/ftpDetail';
import { ISiteSetting } from '../../@types/model/masterData/siteSetting/siteSetting';
// import { batch } from 'react-redux';

/* MasterData Functions */

export const dataSetOrganization = (value : IOrganization) => {
    const state = getState();
    const position = state.masterData.organizations.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetOrganizations(setArrayElement(state.masterData.organizations, position, { ...value })));
    } else {
        dispatch(dataSetOrganizations(addArrayElement(state.masterData.organizations, { ...value })));
    }
};

export const dataSetPack = (value : IPack) => {
    const state = getState();
    const position = state.masterData.packs.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetPacks(setArrayElement(state.masterData.packs, position, { ...value })));
    } else {
        dispatch(dataSetPacks(addArrayElement(state.masterData.packs, { ...value })));
    }
};

export const dataSetAllSyncedMasterData = (data : IMasterDataSyncData) => {
    dispatch(dataSetMasterDataSyncData(data));
};

export const checkIfReduxMasterDataIsPopulated = () => {
    const masterdataState = getState().masterData;

    if (masterdataState.organizations.length < 1
        || masterdataState.sites.length < 1
        || masterdataState.accreditations.length < 1
        || masterdataState.agreementCodes.length < 1
        || masterdataState.barcodeRanges.length < 1
        || masterdataState.carriers.length < 1
        || masterdataState.colours.length < 1
        || masterdataState.ftpDetails.length < 1
        || masterdataState.commodities.length < 1
        || masterdataState.commodityConditions.length < 1
        || masterdataState.commodityStates.length < 1
        || masterdataState.grades.length < 1
        || masterdataState.orchards.length < 1
        || masterdataState.packs.length < 1
        || masterdataState.seasons.length < 1
        || masterdataState.sizes.length < 1
        || masterdataState.varieties.length < 1
        || masterdataState.contactInfos.length < 1
        || masterdataState.countries.length < 1
        || masterdataState.departments.length < 1
        || masterdataState.devices.length < 1
        || masterdataState.domains.length < 1
        || masterdataState.farms.length < 1
        || masterdataState.inspectionPoints.length < 1
        || masterdataState.inventories.length < 1
        || masterdataState.lotTypes.length < 1
        || masterdataState.brands.length < 1
        || masterdataState.markets.length < 1
        || masterdataState.materials.length < 1
        || masterdataState.materialTypes.length < 1
        || masterdataState.outlets.length < 1
        || masterdataState.packCategories.length < 1
        || masterdataState.packLines.length < 1
        || masterdataState.palletBaseTypes.length < 1
        || masterdataState.printers.length < 1
        || masterdataState.projects.length < 1
        || masterdataState.printServers.length < 1
        || masterdataState.regions.length < 1
        || masterdataState.reports.length < 1
        || masterdataState.siteSettings.length < 1
        || masterdataState.salesPoints.length < 1
        || masterdataState.storageUnits.length < 1
        || masterdataState.truckTypes.length < 1
        || masterdataState.unitOfMeasures.length < 1
        || masterdataState.unitOfMeasureTypes.length < 1) {
        return false;
    } else {
        return true;
    }
};

export const dataSetAllPackRelatedData = (data : IPackRelatedData) => {
    dispatch(dataSetPacks(data ? data.packs : []));
    dispatch(dataSetPackCategories(data ? data.packCategories : []));
    dispatch(dataSetSizes(data ? data.sizes : []));
    dispatch(dataSetMaterials(data ? data.materials : []));
};

export const dataSetSize = (value : ISize) => {
    const state = getState();
    const position = state.masterData.sizes.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetSizes(setArrayElement(state.masterData.sizes, position, { ...value })));
    } else {
        dispatch(dataSetSizes(addArrayElement(state.masterData.sizes, { ...value })));
    }
};

export const dataSetAccreditation = (value : IAccreditation) => {
    const state = getState();
    const position = state.masterData.accreditations.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetAccreditations(setArrayElement(state.masterData.accreditations, position, { ...value })));
    } else {
        dispatch(dataSetAccreditations(addArrayElement(state.masterData.accreditations, { ...value })));
    }
};

export const dataSetColour = (value : IColour) => {
    const state = getState();
    const position = state.masterData.colours.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetColours(setArrayElement(state.masterData.colours, position, { ...value })));
    } else {
        dispatch(dataSetColours(addArrayElement(state.masterData.colours, { ...value })));
    }
};

export const dataSetGrade = (value : IGrade) => {
    const state = getState();
    const position = state.masterData.grades.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetGrades(setArrayElement(state.masterData.grades, position, { ...value })));
    } else {
        dispatch(dataSetGrades(addArrayElement(state.masterData.grades, { ...value })));
    }
};

export const dataSetFtpDetail = (value : IFtpDetail) => {
    const state = getState();
    const position = state.masterData.ftpDetails.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetFtpDetails(setArrayElement(state.masterData.ftpDetails, position, { ...value })));
    } else {
        dispatch(dataSetFtpDetails(addArrayElement(state.masterData.ftpDetails, { ...value })));
    }
};

export const dataSetCommodityState = (value : ICommodityState) => {
    const state = getState();
    const position = state.masterData.commodityStates.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetCommodityStates(setArrayElement(state.masterData.commodityStates, position, { ...value })));
    } else {
        dispatch(dataSetCommodityStates(addArrayElement(state.masterData.commodityStates, { ...value })));
    }
};

export const dataSetOrchard = (value : IOrchard) => {
    const state = getState();
    const position = state.masterData.orchards.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetOrchards(setArrayElement(state.masterData.orchards, position, { ...value })));
    } else {
        dispatch(dataSetOrchards(addArrayElement(state.masterData.orchards, { ...value })));
    }
};

export const dataSetCarrier = (value : ICarrier) => {
    const state = getState();
    const position = state.masterData.carriers.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetCarriers(setArrayElement(state.masterData.carriers, position, { ...value })));
    } else {
        dispatch(dataSetCarriers(addArrayElement(state.masterData.carriers, { ...value })));
    }
};

export const dataSetInventory = (value : IInventory) => {
    const state = getState();
    const position = state.masterData.inventories.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetInventories(setArrayElement(state.masterData.inventories, position, { ...value })));
    } else {
        dispatch(dataSetInventories(addArrayElement(state.masterData.inventories, { ...value })));
    }
};

export const dataSetDepartment = (value : IDepartment) => {
    const state = getState();
    const position = state.masterData.departments.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetDepartments(setArrayElement(state.masterData.departments, position, { ...value })));
    } else {
        dispatch(dataSetDepartments(addArrayElement(state.masterData.departments, { ...value })));
    }
};

export const dataSetVariety = (value : IVariety) => {
    const state = getState();
    const position = state.masterData.varieties.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetVarieties(setArrayElement(state.masterData.varieties, position, { ...value })));
    } else {
        dispatch(dataSetVarieties(addArrayElement(state.masterData.varieties, { ...value })));
    }
};

export const dataSetCountry = (value : ICountry) => {
    const state = getState();
    const position = state.masterData.countries.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetCountries(setArrayElement(state.masterData.countries, position, { ...value })));
    } else {
        dispatch(dataSetCountries(addArrayElement(state.masterData.countries, { ...value })));
    }
};

export const dataSetFarm = (value : IFarm) => {
    const state = getState();
    const position = state.masterData.farms.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetFarms(setArrayElement(state.masterData.farms, position, { ...value })));
    } else {
        dispatch(dataSetFarms(addArrayElement(state.masterData.farms, { ...value })));
    }
};

export const dataSetBarcodeRange = (value : IBarcodeRange) => {
    const state = getState();
    const position = state.masterData.barcodeRanges.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetBarcodeRanges(setArrayElement(state.masterData.barcodeRanges, position, { ...value })));
    } else {
        dispatch(dataSetBarcodeRanges(addArrayElement(state.masterData.barcodeRanges, { ...value })));
    }
};

export const dataSetContactInfo = (value : IContactInfo) => {
    const state = getState();
    const position = state.masterData.contactInfos.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetContactInfos(setArrayElement(state.masterData.contactInfos, position, { ...value })));
    } else {
        dispatch(dataSetContactInfos(addArrayElement(state.masterData.contactInfos, { ...value })));
    }
};

export const dataSetPalletBaseType = (value : IPalletBaseType) => {
    const state = getState();
    const position = state.masterData.palletBaseTypes.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetPalletBaseTypes(setArrayElement(state.masterData.palletBaseTypes, position, { ...value })));
    } else {
        dispatch(dataSetPalletBaseTypes(addArrayElement(state.masterData.palletBaseTypes, { ...value })));
    }
};

export const dataSetMarket = (value : IMarket) => {
    const state = getState();
    const position = state.masterData.markets.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetMarkets(setArrayElement(state.masterData.markets, position, { ...value })));
    } else {
        dispatch(dataSetMarkets(addArrayElement(state.masterData.markets, { ...value })));
    }
};

export const dataSetBrand = (value : IBrand) => {
    const state = getState();
    const position = state.masterData.brands.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetBrands(setArrayElement(state.masterData.brands, position, { ...value })));
    } else {
        dispatch(dataSetBrands(addArrayElement(state.masterData.brands, { ...value })));
    }
};

export const dataSetLotType = (value : ILotType) => {
    const state = getState();
    const position = state.masterData.lotTypes.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetLotTypes(setArrayElement(state.masterData.lotTypes, position, { ...value })));
    } else {
        dispatch(dataSetLotTypes(addArrayElement(state.masterData.lotTypes, { ...value })));
    }
};

export const dataSetRegion = (value : IRegion) => {
    const state = getState();
    const position = state.masterData.regions.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetRegions(setArrayElement(state.masterData.regions, position, { ...value })));
    } else {
        dispatch(dataSetRegions(addArrayElement(state.masterData.regions, { ...value })));
    }
};

export const dataSetSite = (value : ISite) => {
    const state = getState();
    const position = state.masterData.sites.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetSites(setArrayElement(state.masterData.sites, position, { ...value })));
    } else {
        dispatch(dataSetSites(addArrayElement(state.masterData.sites, { ...value })));
    }
};

export const dataSetStorageUnit = (value : IStorageUnit) => {
    const state = getState();
    const position = state.masterData.storageUnits.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetStorageUnits(setArrayElement(state.masterData.storageUnits, position, { ...value })));
    } else {
        dispatch(dataSetStorageUnits(addArrayElement(state.masterData.storageUnits, { ...value })));
    }
};

export function dataSetAllStorageUnitDashboardData(data : IStorageUnitDashboard) {
    dispatch(dataSetLots(data?.lots ? [...data.lots] : []));
    if (!self.indexedDB) {
        dispatch(dataSetStorageUnits(data?.storageUnits ? [...data.storageUnits] : []));
        dispatch(dataSetCommodities(data?.commodities ? [...data.commodities] : []));
        dispatch(dataSetVarieties(data?.varieties ? [...data.varieties] : []));
        dispatch(dataSetOrchards(data?.orchards ? [...data.orchards] : []));
    }
}

export const dataSetInspectionPoint = (value : IInspectionPoint) => {
    const state = getState();
    const position = state.masterData.inspectionPoints.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetInspectionPoints(setArrayElement(state.masterData.inspectionPoints, position, { ...value })));
    } else {
        dispatch(dataSetInspectionPoints(addArrayElement(state.masterData.inspectionPoints, { ...value })));
    }
};

export const dataSetCommodity = (value : ICommodity) => {
    const state = getState();
    const position = state.masterData.commodities.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetCommodities(setArrayElement(state.masterData.commodities, position, { ...value })));
    } else {
        dispatch(dataSetCommodities(addArrayElement(state.masterData.commodities, { ...value })));
    }
};

export const dataSetCommodityCondition = (value : ICommodityCondition) => {
    const state = getState();
    const position = state.masterData.commodityConditions.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetCommodityConditions(setArrayElement(state.masterData.commodityConditions, position, { ...value })));
    } else {
        dispatch(dataSetCommodityConditions(addArrayElement(state.masterData.commodityConditions, { ...value })));
    }
};

export const dataSetMaterial = (value : IMaterial) => {
    const state = getState();
    const position = state.masterData.materials.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetMaterials(setArrayElement(state.masterData.materials, position, { ...value })));
    } else {
        dispatch(dataSetMaterials(addArrayElement(state.masterData.materials, { ...value })));
    }
};

export const dataSetMaterialType = (value : IMaterialType) => {
    const state = getState();
    const position = state.masterData.materialTypes.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetMaterialTypes(setArrayElement(state.masterData.materialTypes, position, { ...value })));
    } else {
        dispatch(dataSetMaterialTypes(addArrayElement(state.masterData.materialTypes, { ...value })));
    }
};

export const dataSetUnitOfMeasure = (value : IUnitOfMeasure) => {
    const state = getState();
    const position = state.masterData.unitOfMeasures.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetUnitOfMeasures(setArrayElement(state.masterData.unitOfMeasures, position, { ...value })));
    } else {
        dispatch(dataSetUnitOfMeasures(addArrayElement(state.masterData.unitOfMeasures, { ...value })));
    }
};

export const dataSetUnitOfMeasureType = (value : IUnitOfMeasureType) => {
    const state = getState();
    const position = state.masterData.unitOfMeasureTypes.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetUnitOfMeasureTypes(setArrayElement(state.masterData.unitOfMeasureTypes, position, { ...value })));
    } else {
        dispatch(dataSetUnitOfMeasureTypes(addArrayElement(state.masterData.unitOfMeasureTypes, { ...value })));
    }
};

export const dataSetTruckType = (value : ITruckType) => {
    const state = getState();
    const position = state.masterData.truckTypes.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetTruckTypes(setArrayElement(state.masterData.truckTypes, position, { ...value })));
    } else {
        dispatch(dataSetTruckTypes(addArrayElement(state.masterData.truckTypes, { ...value })));
    }
};

export const dataSetDevice = (value : IDevice) => {
    const state = getState();
    const position = state.masterData.devices.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetDevices(setArrayElement(state.masterData.devices, position, { ...value })));
    } else {
        dispatch(dataSetDevices(addArrayElement(state.masterData.devices, { ...value })));
    }
};

export const dataSetProject = (value : IProject) => {
    const state = getState();
    const position = state.masterData.projects.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetProjects(setArrayElement(state.masterData.projects, position, { ...value })));
    } else {
        dispatch(dataSetProjects(addArrayElement(state.masterData.projects, { ...value })));
    }
};

export const dataSetPackLine = (value : IPackLine) => {
    const state = getState();
    const position = state.masterData.packLines.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetPackLines(setArrayElement(state.masterData.packLines, position, { ...value })));
    } else {
        dispatch(dataSetPackLines(addArrayElement(state.masterData.packLines, { ...value })));
    }
};

export const dataSetOutlet = (value : IOutlet) => {
    const state = getState();
    const position = state.masterData.outlets.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetOutlets(setArrayElement(state.masterData.outlets, position, { ...value })));
    } else {
        dispatch(dataSetOutlets(addArrayElement(state.masterData.outlets, { ...value })));
    }
};

export const dataSetPrinter = (value : IPrinter) => {
    const state = getState();
    const position = state.masterData.printers.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetPrinters(setArrayElement(state.masterData.printers, position, { ...value })));
    } else {
        dispatch(dataSetPrinters(addArrayElement(state.masterData.printers, { ...value })));
    }
};

export const dataSetPrintServer = (value : IPrintServer) => {
    const state = getState();
    const position = state.masterData.printServers.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetPrintServers(setArrayElement(state.masterData.printServers, position, { ...value })));
    } else {
        dispatch(dataSetPrintServers(addArrayElement(state.masterData.printServers, { ...value })));
    }
};

export const dataSetReport = (value : IReport) => {
    const state = getState();
    const position = state.masterData.reports.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetReports(setArrayElement(state.masterData.reports, position, { ...value })));
    } else {
        dispatch(dataSetReports(addArrayElement(state.masterData.reports, { ...value })));
    }
};

export const dataSetSiteSetting = (value : ISiteSetting) => {
    const state = getState();
    const position = state.masterData.siteSettings.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetSiteSettings(setArrayElement(state.masterData.siteSettings, position, { ...value })));
    } else {
        dispatch(dataSetSiteSettings(addArrayElement(state.masterData.siteSettings, { ...value })));
    }
};

export const dataSetAgreementCode = (value : IAgreementCode) => {
    const state = getState();
    const position = state.masterData.agreementCodes.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetAgreementCodes(setArrayElement(state.masterData.agreementCodes, position, { ...value })));
    } else {
        dispatch(dataSetAgreementCodes(addArrayElement(state.masterData.agreementCodes, { ...value })));
    }
};

export const dataSetSalesPoint = (value : ISalesPoint) => {
    const state = getState();
    const position = state.masterData.salesPoints.findIndex(x => x.id === value.id);
    if (position !== -1) {
        dispatch(dataSetSalesPoints(setArrayElement(state.masterData.salesPoints, position, { ...value })));
    } else {
        dispatch(dataSetSalesPoints(addArrayElement(state.masterData.salesPoints, { ...value })));
    }
};
