import { Button, Card, Icon, IconButton, Table, TableCell, TableRow, TextField, Typography } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import * as React from 'react';
import { connect } from 'react-redux';
import { IImportedPOFileDispatch } from '../../../../@types/model/integration/poFile/importedPOFileDispatch';
import { IImportedPOFileStock } from '../../../../@types/model/integration/poFile/importedPOFileStock';
import { IOCLine } from '../../../../@types/model/integration/poFile/ocLine';
import { IOHLine } from '../../../../@types/model/integration/poFile/ohLine';
import { IOKLine } from '../../../../@types/model/integration/poFile/okLine';
import { IOLLine } from '../../../../@types/model/integration/poFile/olLine';
import { IOPLine } from '../../../../@types/model/integration/poFile/opLine';
import { IPOBatchHeader } from '../../../../@types/model/integration/poFile/poBatchHeader';
import { IPOBatchTrailer } from '../../../../@types/model/integration/poFile/poBatchTrailer';
import { IPOFileContentData } from '../../../../@types/model/integration/poFile/poFileContentData';
import { IColour } from '../../../../@types/model/masterData/colour/colour';
import { ICommodity } from '../../../../@types/model/masterData/commodity/commodity';
import { ICountry } from '../../../../@types/model/masterData/country/country';
import { IFarm } from '../../../../@types/model/masterData/farm/farm';
import { IGrade } from '../../../../@types/model/masterData/grade/grade';
import { IInventory } from '../../../../@types/model/masterData/inventory/inventory';
import { IBrand } from '../../../../@types/model/masterData/brand/brand';
import { IMarket } from '../../../../@types/model/masterData/market/market';
import { IOrganization } from '../../../../@types/model/masterData/organization/organization';
import { IPack } from '../../../../@types/model/masterData/pack/pack';
import { IPalletBaseType } from '../../../../@types/model/masterData/palletBaseType/palletBaseType';
import { IRegion } from '../../../../@types/model/masterData/region/region';
import { ISite } from '../../../../@types/model/masterData/site/site';
import { ISize } from '../../../../@types/model/masterData/size/size';
import { IVariety } from '../../../../@types/model/masterData/variety/variety';
import { IAuthState, IRootState } from '../../../../@types/redux';
import FileSelector from '../../../../components/input/FileSelector';
import PillButton from '../../../../components/input/PillButton';
import Screen from '../../../../components/Screen';
import { addArrayElement, booleanToYesNo, removeArrayElement, setArrayElement } from '../../../../services/appFunctionsService';
import FileImportHttpService from '../../../../services/http/integration/fileImport/FileImportHttpService';
import { dataSetAllPOFileRelatedData } from '../../../../store/data/Functions';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../../store/general/Functions';
import { IUpsertImportedPOFileDispatch } from '../../../../@types/model/integration/poFile/upsertImportedPOFileDispatch';
import { IUpsertImportedPOFileStock } from '../../../../@types/model/integration/poFile/upsertImportedPOFileStock';
import { IUpsertImportedPOFileStockLine } from '../../../../@types/model/integration/poFile/upsertImportedPOFileStockLine';
import moment from 'moment';
import PackmanDialog from '../../../../components/dialog/PackmanDialog';
import { IExistingImportedPOFileData } from '../../../../@types/model/integration/poFile/existingImportedPOFileData';
import lodash from 'lodash';
import { DISPATCH_INSTRUCTION_STATUSSES } from '../../../../appConstants';
import { IDispatchInstruction } from '../../../../@types/model/dispatch/dispatchInstruction';
import { IStock } from '../../../../@types/model/stock/stock';
import { ICompliance } from '../../../../@types/model/compliance/compliance';
import ImportPOFileInfoDialog from './form/ImportPOFileInfoDialog';
import { v1 } from 'uuid';
import { IOrchard } from '../../../../@types/model/masterData/orchard/orchard';
import { ListChildComponentProps, FixedSizeList as WindowList } from 'react-window';
import AutocompleteSelect from '../../../../components/input/AutoCompleteSelect';
import { CustomChangeEvent, IOptionType } from '../../../../@types/helper';
import { IRight } from '../../../../@types/model/user/right';
import { RouteComponentProps } from 'react-router';
import { IOCLineMassSelectOptions } from '../../../../@types/model/integration/poFile/ocLineMassSelectOptions';
import CustomTooltip from '../../../../components/tooltip/tooltip';
import { syncMasterData } from '../../../../services/masterDataSyncService';

interface IImportPOFileScreenProps extends RouteComponentProps {
    auth : IAuthState;
    organizations : Array<IOrganization>;
    sites : Array<ISite>;
    markets : Array<IMarket>;
    brands : Array<IBrand>;
    regions : Array<IRegion>;
    countries : Array<ICountry>;
    palletBaseTypes : Array<IPalletBaseType>;
    inventories : Array<IInventory>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    packs : Array<IPack>;
    sizes : Array<ISize>;
    farms : Array<IFarm>;
    colours : Array<IColour>;
    grades : Array<IGrade>;
    orchards : Array<IOrchard>;
}

interface IImportPOFileScreenState {
    isLoading : boolean;
    selectedFiles : Array<File>;
    poFileContentData ?: IPOFileContentData;
    createdDispatches : Array<IImportedPOFileDispatch>;

    isWarningDialogOpen : boolean;
    existingImportedPOFileData ?: IExistingImportedPOFileData;

    isPOFileDispatchesAlreadyReceived : boolean;
    isSomeStocksLinkedToCompliance : boolean;
    hasStocksBeenMovedToDifferentDispatch : boolean;

    selectedOPLineRow ?: IOPLine;

    massColumnChangeSelectedOptions : Array<IOCLineMassSelectOptions>;
}

class ImportPOFileScreen extends React.Component<IImportPOFileScreenProps, IImportPOFileScreenState> {
    constructor(props : IImportPOFileScreenProps) {
        super(props);

        this.state = {
            isLoading: false,
            selectedFiles: [],
            createdDispatches: [],
            massColumnChangeSelectedOptions: [],

            isWarningDialogOpen: false,
            isPOFileDispatchesAlreadyReceived: false,
            isSomeStocksLinkedToCompliance: false,
            hasStocksBeenMovedToDifferentDispatch: false,
        };
    }

    public componentDidMount = async () => {
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
            this.setLoading(false);
        } else {
            try {
                const res = await FileImportHttpService.getPOFileRelatedData();

                if (res && res.data) {
                    dataSetAllPOFileRelatedData(res.data);
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading required data.');
            } finally {
                this.setLoading(false);
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    private getOrganizations = (props : IImportPOFileScreenProps) => props.organizations;
    private getSites = (props : IImportPOFileScreenProps) => props.sites;
    private getRegions = (props : IImportPOFileScreenProps) => props.regions;
    private getCountries = (props : IImportPOFileScreenProps) => props.countries;
    private getMarkets = (props : IImportPOFileScreenProps) => props.markets;
    private getBrands = (props : IImportPOFileScreenProps) => props.brands;
    private getInventories = (props : IImportPOFileScreenProps) => props.inventories;
    private getPalletBaseTypes = (props : IImportPOFileScreenProps) => props.palletBaseTypes;
    private getCommodities = (props : IImportPOFileScreenProps) => props.commodities;
    private getVarieties = (props : IImportPOFileScreenProps) => props.varieties;
    private getGrades = (props : IImportPOFileScreenProps) => props.grades;
    private getSizes = (props : IImportPOFileScreenProps) => props.sizes;
    private getColours = (props : IImportPOFileScreenProps) => props.colours;
    private getPacks = (props : IImportPOFileScreenProps) => props.packs;
    private getOrchards = (props : IImportPOFileScreenProps) => props.orchards;
    private getFarms = (props : IImportPOFileScreenProps) => props.farms;

    private getPOFileContentData = (props : IImportPOFileScreenProps, state : IImportPOFileScreenState) => state.poFileContentData;
    private getCreatedDispatches = (props : IImportPOFileScreenProps, state : IImportPOFileScreenState) => state.createdDispatches;
    private getIsPOFileDispatchesAlreadyReceived = (props : IImportPOFileScreenProps, state : IImportPOFileScreenState) => state.isPOFileDispatchesAlreadyReceived;
    private getIsSomeStocksLinkedToCompliance = (props : IImportPOFileScreenProps, state : IImportPOFileScreenState) => state.isSomeStocksLinkedToCompliance;
    private getHasStocksBeenMovedToDifferentDispatch = (props : IImportPOFileScreenProps, state : IImportPOFileScreenState) => state.hasStocksBeenMovedToDifferentDispatch;
    private getMassColumnChangeSelectedOptions = (props : IImportPOFileScreenProps, state : IImportPOFileScreenState) => state.massColumnChangeSelectedOptions;

    private preSubmitValidation = () => {
        const dispatchesToBeCreated : Array<IImportedPOFileDispatch> = this.state.createdDispatches;
        const existingImportedPOFileData = this.state.existingImportedPOFileData;

        const dispatchCodes : Array<string> = dispatchesToBeCreated.filter(x => !!x.dispatchCode && x.dispatchCode !== '').map(x => x.dispatchCode ?? '');
        if (existingImportedPOFileData) {
            // all dispatches linked to above stocks
            const existingDispatches : Array<IDispatchInstruction> = existingImportedPOFileData.dispatches ?? [];

            // dispatches already created from this PO file
            const mathingDispatches = existingDispatches.filter(x => dispatchCodes.some(code => code === x.dispatchCode));

            if (mathingDispatches.length > 0) {
                this.setState({ isWarningDialogOpen: true });
            } else {
                this.onSubmit();
            }
        }
    };

    private onSubmit = async () => {
        const dispatchesToBeCreated = this.state.createdDispatches;
        if (dispatchesToBeCreated.length > 0) {
            this.setLoading(true);
            try {
                const upsertData : Array<IUpsertImportedPOFileDispatch> = dispatchesToBeCreated.map((dispatch) => {
                    const upsertDispatch : IUpsertImportedPOFileDispatch = {
                        id: 0,
                        loadDate: moment(dispatch.loadDate).utc(true),
                        dispatchCode: dispatch.dispatchCode,
                        sourceOrganizationId: Number(dispatch.sourceOrganizationOption?.value ?? 0),
                        targetOrganizationId: Number(dispatch.targetOrganizationOption?.value ?? 0),
                        sourceSiteId: Number(dispatch.sourceSiteOption?.value ?? 0),
                        destinationSiteId: Number(dispatch.destinationSiteOption?.value ?? 0),
                        registrationNumber: dispatch.registrationNumber,
                        status: DISPATCH_INSTRUCTION_STATUSSES[5],
                        linkedStocks: [],
                    };

                    let dispatchStocksToUpsert : Array<IUpsertImportedPOFileStock> = [];

                    dispatch.linkedPOLines.forEach((opLineStock) => {
                        const matchingBarcode = dispatchStocksToUpsert.find(x => x.barcode === opLineStock.barcode);
                        const matchingBarcodeIndex = dispatchStocksToUpsert.findIndex(x => x.barcode === opLineStock.barcode);

                        const opLines : Array<IOPLine> = lodash.flatMap(this.state.poFileContentData?.ocLines, x => x.linkedOPLines);
                        const matchingOPLine = opLines.find(x => x.sscc === opLineStock.barcode && x.guid === opLineStock.guid);

                        if (matchingOPLine) {
                            if (!!matchingBarcode && matchingBarcodeIndex !== -1) {
                                let updatedStock : IUpsertImportedPOFileStock = {
                                    ... matchingBarcode,
                                    cartons: matchingBarcode.cartons + Number(opLineStock.cartons),
                                    grossWeight: matchingBarcode.grossWeight + Number(opLineStock.grossWeight),
                                    estimatedGrossWeight: matchingBarcode.estimatedGrossWeight + Number(opLineStock.estimatedGrossWeight),
                                    estimatedNettWeight: matchingBarcode.estimatedNettWeight + Number(opLineStock.estimatedNettWeight),
                                };

                                const existingStockLineIndex = updatedStock.stockLines.findIndex(line =>
                                    line.commodityId === opLineStock.commodityOption?.value
                                    && line.varietyId === opLineStock.varietyOption?.value
                                    && (line.farmId === this.getFarm(matchingOPLine)?.id ?? 0)
                                    && line.packId === opLineStock.packOption?.value
                                    && line.gradeId === opLineStock.gradeOption?.value
                                    && line.sizeId === opLineStock.sizeOption?.value
                                    && line.colourId === opLineStock.colourOption?.value
                                    && line.orchardId === this.getOrchard(matchingOPLine)?.id);

                                if (existingStockLineIndex !== -1) {
                                    const updatedStockLine : IUpsertImportedPOFileStockLine = {
                                        ...updatedStock.stockLines[existingStockLineIndex],
                                        palletSize: updatedStock.stockLines[existingStockLineIndex].palletSize + (opLineStock.palletSize !== '' ? Number(opLineStock.palletSize) : 0),
                                        cartons: updatedStock.stockLines[existingStockLineIndex].cartons + (opLineStock.cartons !== '' ? Number(opLineStock.cartons) : 0),
                                        estimatedGrossWeight: updatedStock.stockLines[existingStockLineIndex].estimatedGrossWeight + (opLineStock.estimatedGrossWeight !== '' ? Number(opLineStock.estimatedGrossWeight) : 0),
                                    };

                                    updatedStock.stockLines = setArrayElement(updatedStock.stockLines, existingStockLineIndex, updatedStockLine);
                                } else {
                                    const newStockLine : IUpsertImportedPOFileStockLine = {
                                        id: updatedStock.stockLines.length + 1,
                                        sequence: updatedStock.stockLines.length + 1,
                                        palletSize: Number(opLineStock.palletSize),
                                        commodityId: Number(opLineStock.commodityOption?.value ?? 0),
                                        varietyId: Number(opLineStock.varietyOption?.value ?? 0),
                                        farmId: Number(opLineStock.farmOption?.value ?? 0),
                                        packId: Number(opLineStock.packOption?.value ?? 0),
                                        gradeId: Number(opLineStock.gradeOption?.value ?? 0),
                                        sizeId: Number(opLineStock.sizeOption?.value ?? 0),
                                        colourId: Number(opLineStock.colourOption?.value ?? 0),
                                        orchardId: !!opLineStock.orchardOption ? Number(opLineStock.orchardOption?.value) : undefined,
                                        cartons: Number(opLineStock.cartons),
                                        estimatedGrossWeight: Number(opLineStock.estimatedGrossWeight),
                                    };

                                    updatedStock.stockLines = addArrayElement(updatedStock.stockLines, newStockLine, 'end');
                                }

                                dispatchStocksToUpsert = setArrayElement(dispatchStocksToUpsert, matchingBarcodeIndex, updatedStock);

                            } else {
                                const newStock : IUpsertImportedPOFileStock = {
                                    id: opLineStock.id,
                                    barcode: opLineStock.barcode,
                                    originalOrganizationId: Number(opLineStock.originalOrganizationOption?.value ?? 0),
                                    originalSiteId: Number(opLineStock.originalSiteOption?.value ?? 0),
                                    currentOrganizationId: Number(opLineStock.currentOrganizationOption?.value ?? 0),
                                    currentSiteId: Number(opLineStock.currentSiteOption?.value ?? 0),
                                    channel: opLineStock.channel,
                                    regionId: Number(opLineStock.regionOption?.value ?? 0),
                                    countryId: Number(opLineStock.countryOption?.value ?? 0),
                                    brandId: Number(opLineStock.brandOption?.value ?? 0),
                                    marketId: Number(opLineStock.marketOption?.value ?? 0),
                                    inventoryId: !!opLineStock.inventoryOption ? Number(opLineStock.inventoryOption?.value) : undefined,
                                    cartons: Number(opLineStock.cartons),
                                    hasMixedLoad: opLineStock.hasMixedLoad,
                                    palletBaseTypeId: Number(opLineStock.palletBaseTypeOption?.value ?? 0),
                                    grossWeight: Number(opLineStock.grossWeight),
                                    estimatedGrossWeight: Number(opLineStock.estimatedGrossWeight),
                                    estimatedNettWeight: Number(opLineStock.estimatedNettWeight),
                                    season: opLineStock.season,
                                    packDate: moment(opLineStock.packDate).utc(true),
                                    stockLines: [],
                                };

                                const firstStockLine : IUpsertImportedPOFileStockLine = {
                                    id: 1,
                                    sequence: 1,
                                    palletSize: Number(opLineStock.palletSize),
                                    commodityId: Number(opLineStock.commodityOption?.value ?? 0),
                                    varietyId: Number(opLineStock.varietyOption?.value ?? 0),
                                    farmId: Number(opLineStock.farmOption?.value ?? 0),
                                    packId: Number(opLineStock.packOption?.value ?? 0),
                                    gradeId: Number(opLineStock.gradeOption?.value ?? 0),
                                    sizeId: Number(opLineStock.sizeOption?.value ?? 0),
                                    colourId: Number(opLineStock.colourOption?.value ?? 0),
                                    orchardId: !!opLineStock.orchardOption ? Number(opLineStock.orchardOption?.value) : undefined,
                                    cartons: Number(opLineStock.cartons),
                                    estimatedGrossWeight: Number(opLineStock.estimatedGrossWeight),
                                };

                                newStock.stockLines = addArrayElement(newStock.stockLines, firstStockLine);

                                dispatchStocksToUpsert = addArrayElement(dispatchStocksToUpsert, newStock);
                            }
                        }
                    });

                    const updatedList : Array<IUpsertImportedPOFileStock> = dispatchStocksToUpsert.map((stock) => {
                        return {
                            ...stock,
                            stockLines: stock.stockLines.map((line) => {
                                return {
                                    ...line,
                                    palletSize:  1 / stock.stockLines.length,
                                };
                            }),
                        };
                    });

                    upsertDispatch.linkedStocks = updatedList;

                    return upsertDispatch;
                });

                const res = await FileImportHttpService.upsertImportedPOFileData(upsertData);

                if (res && res.data) {
                    this.setState({
                        poFileContentData: undefined,
                        createdDispatches: [],
                        isWarningDialogOpen: false,
                        existingImportedPOFileData: undefined,
                        selectedFiles: [],
                        isPOFileDispatchesAlreadyReceived: false,
                        isSomeStocksLinkedToCompliance: false,
                        hasStocksBeenMovedToDifferentDispatch: false,
                        massColumnChangeSelectedOptions: [] });
                    generalShowSuccessSnackbar('PO File imported successfully.');
                }
            } catch (ex) {
                if (!!this.state.isWarningDialogOpen) {
                    this.setState({ isWarningDialogOpen: false });
                }
                generalShowErrorSnackbar('Failed to import PO file.');
            } finally {
                this.setLoading(false);
            }
        }
    };

    private getExistingImportedFileData = async (dispatchCodes : Array<string>, stockBarcodes : Array<string>) => {
        try {
            const res = await FileImportHttpService.getExistingImportedFileData(stockBarcodes);

            if (res && res.data) {
                // stocks with barcode matching the imported stock barcodes
                const existingStocks : Array<IStock> = res.data.stocks ?? [];

                // all dispatches linked to above stocks
                const existingDispatches : Array<IDispatchInstruction> = res.data.dispatches ?? [];

                // active complainces linked to above stocks (inactive compliances gets filtered out on api)
                const linkedCompliances : Array<ICompliance> = res.data.complainces ?? [];

                // dispatches already created from this PO file
                const mathingDispatches = existingDispatches.filter(x => dispatchCodes.some(code => code === x.dispatchCode));

                // other dispatches not created from PO file but which are linked to above stocks
                const otherLinkedDispatches = existingDispatches.filter(x =>
                    mathingDispatches.some(y => y.id !== x.id)
                    && x.dispatchLines.some(line => line.isActive && existingStocks.some(stock => stock.id === line.currentStockId)));

                if (mathingDispatches.some(x => x.status === DISPATCH_INSTRUCTION_STATUSSES[6])) {
                    generalShowErrorSnackbar('The dispatches you are trying to import have already been marked as "Received". Cannot import again.');
                }
                if (linkedCompliances.length > 0) {
                    generalShowErrorSnackbar('Some stocks is already linked to a compliance.');
                }
                if (otherLinkedDispatches.length > 0) {
                    generalShowErrorSnackbar('Some stocks have already been loaded on another dispatch.');
                }
                const isSomeStocksLinkedToCompliance = linkedCompliances.length > 0;
                const isPOFileDispatchesAlreadyReceived = mathingDispatches.some(x => x.status === DISPATCH_INSTRUCTION_STATUSSES[6]);
                const hasStocksBeenMovedToDifferentDispatch = otherLinkedDispatches.length > 0;

                this.setState({ existingImportedPOFileData: res.data, isSomeStocksLinkedToCompliance, isPOFileDispatchesAlreadyReceived, hasStocksBeenMovedToDifferentDispatch });
            }
        } catch (ex) {
            generalShowErrorSnackbar('Failed to check if file has already been imported.');
        } finally {
            this.setLoading(false);
        }
    };

    private handleFileInputChange = (files : Array<File>) => {
        // Check to prevent user from trying to import more that one file at a time
        if (this.state.selectedFiles.length < 1) {

            files.forEach(x => this.setState({ selectedFiles: addArrayElement(this.state.selectedFiles, x, 'end') }));

            if (files.length > 0) {
                // this.setLoading(true);
                files.forEach((file) => {
                    const reader = new FileReader();
                    reader.onload = async () => {
                        const fileStr = reader.result;
                        if (typeof fileStr === 'string') {
                            const fileLines = fileStr.split('\r\n');
                            this.setLoading(true); // Setting isLoading to TRUE here since mapping can take a second or 2

                            let poBatchHeader : string = '';
                            let ohLine : string = '';
                            let olLines : Array<string> = [];
                            let okLine : string = '';
                            let ocLines : Array<{ ocLine : string; opLines : Array<string> }> = [];
                            let poBatchTrailerLine : string = '';

                            fileLines.forEach((line) => {
                                const recordType = line.substring(0, 2);

                                if (recordType === 'BH') {
                                    poBatchHeader = line;
                                } else if (recordType === 'OH') {
                                    ohLine = line;
                                } else if (recordType === 'OL') {
                                    olLines = addArrayElement(olLines, line, 'end');
                                } else if (recordType === 'OK') {
                                    okLine = line;
                                } else if (recordType === 'OC') {
                                    const entry = {
                                        ocLine: line,
                                        opLines: [],
                                    };
                                    ocLines = addArrayElement(ocLines, entry, 'end');
                                } else if (recordType === 'OP') {
                                    if (ocLines.length > 0) {
                                        const index = ocLines.length - 1;
                                        const opLines = ocLines[index].opLines;

                                        const updatedOCLineData = ocLines[index];

                                        updatedOCLineData.opLines = addArrayElement(opLines, line, 'end');
                                        ocLines = setArrayElement(ocLines, index, updatedOCLineData);
                                    }
                                } else if (recordType === 'BT') {
                                    poBatchTrailerLine = line;
                                }
                            });

                            // NB! Always maintain the order otherwhise values will be messed up
                            const poBatchHeaderData : IPOBatchHeader = { // All values are being trimmed to remove white spaces
                                recordType: poBatchHeader.substring(0, 2).trim(), // value character length = 2
                                loadId: poBatchHeader.substring(2, 5).trim(), // value character length = 3
                                batchNumber: poBatchHeader.substring(5, 11).trim(), // value character length = 6
                                createdOn: poBatchHeader.substring(11, 27).trim(), // value character length = 16
                                indicator: poBatchHeader.substring(27, 29).trim(), // value character length = 2
                                provider: poBatchHeader.substring(29, 59).trim(), // value character length = 30
                                versionNumber: poBatchHeader.substring(59, 89).trim(), // value character length = 30
                            };

                            // NB! Always maintain the order otherwhise values will be messed up
                            const ohLineData : IOHLine = { // All values are being trimmed to remove white spaces
                                recordType: ohLine.substring(0, 2).trim(), // value character length = 2
                                loadId: ohLine.substring(2, 12).trim(), // value character length = 10
                                truckNumber: ohLine.substring(12, 22).trim(), // value character length = 10
                                truckName: ohLine.substring(22, 47).trim(), // value character length = 25
                                transport: ohLine.substring(47, 48).trim(), // value character length = 1
                                loadType: ohLine.substring(48, 49).trim(), // value character length = 1
                                loadStatus: ohLine.substring(49, 50).trim(), // value character length = 1
                                tkDate: ohLine.substring(50, 63).trim(), // value character length = 13
                                tkUser: ohLine.substring(63, 70).trim(), // value character length = 7
                                startDate: ohLine.substring(70, 83).trim(), // value character length = 13
                                userId: ohLine.substring(83, 90).trim(), // value character length = 7
                                partnerId: ohLine.substring(90, 97).trim(), // value character length = 7
                                endDate: ohLine.substring(97, 110).trim(), // value character length = 13
                                depDate: ohLine.substring(110, 123).trim(), // value character length = 13
                                handlePoint: ohLine.substring(123, 125).trim(), // value character length = 2
                                carrier: ohLine.substring(125, 133).trim(), // value character length = 8
                                palletQuantity: ohLine.substring(133, 138).trim(), // value character length = 5
                                cartonQuantity: ohLine.substring(138, 146).trim(), // value character length = 8
                                subLoad: ohLine.substring(146, 158).trim(), // value character length = 12
                                nextType: ohLine.substring(158, 160).trim(), // value character length = 2
                                nextCode: ohLine.substring(160, 167).trim(), // value character length = 7
                                saecsVoyage: ohLine.substring(167, 173).trim(), // value character length = 6
                                phytoNo: ohLine.substring(173, 181).trim(), // value character length = 8
                                fullLoad: ohLine.substring(181, 182).trim(), // value character length = 1
                                instructionType: ohLine.substring(182, 183).trim(), // value character length = 1
                                masterOrderNumber: ohLine.substring(183, 189).trim(), // value character length = 6
                                currentLocation: ohLine.substring(189, 196).trim(), // value character length = 7
                                season: ohLine.substring(196, 200).trim(), // value character length = 4
                                clientRef: ohLine.substring(200, 206).trim(), // value character length = 6
                                tripNumber: ohLine.substring(206, 214).trim(), // value character length = 8
                                xmitFlag: ohLine.substring(214, 215).trim(), // value character length = 1
                                revision: ohLine.substring(215, 220).trim(), // value character length = 5
                                message: ohLine.substring(220, 228).trim(), // value character length = 8
                                transactionUser: ohLine.substring(228, 235).trim(), // value character length = 7
                                transactionDate: ohLine.substring(235, 248).trim(), // value character length = 13
                                temperature: ohLine.substring(248, 254).trim(), // value character length = 6
                                extSaecsVoyage: ohLine.substring(254, 269).trim(), // value character length = 15
                                truckWeight: ohLine.substring(269, 279).trim(), // value character length = 10
                                trailer1RegNum: ohLine.substring(279, 294).trim(), // value character length = 15
                                trailer2RegNum: ohLine.substring(294, 309).trim(), // value character length = 15
                            };

                            let olLinesData : Array<IOLLine> = [];

                            olLines.forEach((olLine) => {
                                // NB! Always maintain the order otherwhise values will be messed up
                                const olLineData : IOLLine = { // All values are being trimmed to remove white spaces
                                    id: olLinesData.length + 1,
                                    recordType: olLine.substring(0, 2).trim(), // value character length = 2
                                    loadId: olLine.substring(2, 12).trim(), // value character length = 10
                                    locationType: olLine.substring(12, 14).trim(), // value character length = 2
                                    locationCode: olLine.substring(14, 21).trim(), // value character length = 7
                                    sequenceNumber: olLine.substring(21, 26).trim(), // value character length = 5
                                    transactionType: olLine.substring(26, 27).trim(), // value character length = 1
                                    handlePoint: olLine.substring(27, 29).trim(), // value character length = 2
                                    arrivalDate: olLine.substring(29, 42).trim(), // value character length = 13
                                    departureDate: olLine.substring(42, 55).trim(), // value character length = 13
                                    connFlight: olLine.substring(55, 65).trim(), // value character length = 10
                                    loadStatus: olLine.substring(65, 66).trim(), // value character length = 1
                                    xmitFlag: olLine.substring(66, 67).trim(), // value character length = 1
                                    revision: olLine.substring(67, 72).trim(), // value character length = 5
                                    message: olLine.substring(72, 80).trim(), // value character length = 8
                                    transactionUser: olLine.substring(80, 87).trim(), // value character length = 7
                                    transactionDate: olLine.substring(87, 100).trim(), // value character length = 13
                                };

                                olLinesData = addArrayElement(olLinesData, olLineData, 'end');
                            });

                            let okLineData : IOKLine | undefined;

                            if (okLine !== '') {
                                // NB! Always maintain the order otherwhise values will be messed up
                                const extractedOKLineData = { // All values are being trimmed to remove white spaces
                                    recordType: okLine.substring(0, 2).trim(), // value character length = 2
                                    loadId: okLine.substring(2, 12).trim(), // value character length = 10
                                    locationCode: okLine.substring(12, 19).trim(), // value character length = 7
                                    container: okLine.substring(19, 30).trim(), // value character length = 11
                                    sealNoLength8: okLine.substring(30, 38).trim(), // value character length = 8
                                    position: okLine.substring(38, 44).trim(), // value character length = 6
                                    atetiNo: okLine.substring(44, 50).trim(), // value character length = 6
                                    proNo: okLine.substring(50, 58).trim(), // value character length = 8
                                    stuffDate: okLine.substring(58, 71).trim(), // value character length = 13
                                    tempSet: okLine.substring(71, 77).trim(), // value character length = 6
                                    dischPort: okLine.substring(77, 83).trim(), // value character length = 6
                                    ctoNoLenth8: okLine.substring(83, 91).trim(), // value character length = 8
                                    shipLineLength1: okLine.substring(91, 92).trim(), // value character length = 1
                                    docNo: okLine.substring(92, 102).trim(), // value character length = 10
                                    sender: okLine.substring(102, 104).trim(), // value character length = 2
                                    agent: okLine.substring(104, 106).trim(), // value character length = 2
                                    shipSender: okLine.substring(106, 108).trim(), // value character length = 2
                                    shipAgent: okLine.substring(108, 110).trim(), // value character length = 2
                                    organization: okLine.substring(110, 112).trim(), // value character length = 2
                                    loadDepot: okLine.substring(112, 119).trim(), // value character length = 7
                                    cartonQuantity: okLine.substring(119, 124).trim(), // value character length = 5
                                    palletQuantity: okLine.substring(124, 129).trim(), // value character length = 5
                                    tkUser: okLine.substring(129, 136).trim(), // value character length = 7
                                    inUser: okLine.substring(136, 143).trim(), // value character length = 7
                                    inPartner: okLine.substring(143, 150).trim(), // value character length = 7
                                    raynNoLength8: okLine.substring(150, 158).trim(), // value character length = 8
                                    contStatus: okLine.substring(158, 159).trim(), // value character length = 1
                                    xmitFlag: okLine.substring(159, 160).trim(), // value character length = 1
                                    revision: okLine.substring(160, 165).trim(), // value character length = 5
                                    message: okLine.substring(165, 173).trim(), // value character length = 8
                                    transactionUser: okLine.substring(173, 180).trim(), // value character length = 7
                                    transactionDate: okLine.substring(180, 193).trim(), // value character length = 13
                                    shipNumber: okLine.substring(193, 199).trim(), // value character length = 6
                                    palletBaseType: okLine.substring(199, 200).trim(), // value character length = 1
                                    containerType: okLine.substring(200, 201).trim(), // value character length = 1
                                    numVents: okLine.substring(201, 206).trim(), // value character length = 5
                                    principal: okLine.substring(206, 207).trim(), // value character length = 1
                                    containerRefLength15: okLine.substring(207, 222).trim(), // value character length = 15
                                    containerSize: okLine.substring(222, 224).trim(), // value character length = 2
                                    sealNoLength15: okLine.substring(224, 239).trim(), // value character length = 15
                                    consecNo: okLine.substring(239, 249).trim(), // value character length = 10
                                    ctoNoLength10: okLine.substring(249, 259).trim(), // value character length = 10
                                    raynNoLength10: okLine.substring(259, 269).trim(), // value character length = 10
                                    shipName: okLine.substring(269, 294).trim(), // value character length = 25
                                    voyageNo: okLine.substring(294, 304).trim(), // value character length = 10
                                    shipCallSign: okLine.substring(304, 314).trim(), // value character length = 10
                                    shipLineLength2: okLine.substring(314, 316).trim(), // value character length = 2
                                    verificationMethod: okLine.substring(316, 317).trim(), // value character length = 1
                                    containerTareWeight: okLine.substring(317, 322).trim(), // value character length = 5
                                    containerGrossMass: okLine.substring(322, 332).trim(), // value character length = 10
                                    samsaAccreditation: okLine.substring(332, 342).trim(), // value character length = 10
                                    responsibleParty: okLine.substring(342, 344).trim(), // value character length = 2
                                    containerRefLength25: okLine.substring(344, 369).trim(), // value character length = 25
                                };

                                okLineData = {
                                    ...extractedOKLineData,
                                    sealNo: (extractedOKLineData.sealNoLength8 !== '' && extractedOKLineData.sealNoLength8.length === 8)
                                        ? extractedOKLineData.sealNoLength8
                                        : extractedOKLineData.sealNoLength15,
                                    ctoNo: (extractedOKLineData.ctoNoLenth8 !== '' && extractedOKLineData.ctoNoLenth8.length === 8)
                                        ? extractedOKLineData.ctoNoLenth8
                                        : extractedOKLineData.ctoNoLength10,
                                    shipLine: (extractedOKLineData.shipLineLength1 !== '' && extractedOKLineData.shipLineLength1.length === 1)
                                        ? extractedOKLineData.shipLineLength1
                                        : extractedOKLineData.shipLineLength2,
                                    containerRef: (extractedOKLineData.containerRefLength15 !== '' && extractedOKLineData.containerRefLength15.length === 15)
                                        ? extractedOKLineData.containerRefLength15
                                        : extractedOKLineData.containerRefLength25,
                                    raynNo: (extractedOKLineData.raynNoLength8 !== '' && extractedOKLineData.raynNoLength8.length === 8)
                                        ? extractedOKLineData.raynNoLength8
                                        : extractedOKLineData.raynNoLength10,
                                };
                            };

                            let ocLinesData : Array<IOCLine> = [];

                            ocLines.forEach((line) => {
                                const ocLine = line.ocLine;
                                // NB! Always maintain the order otherwhise values will be messed up
                                const ocLineData : IOCLine = { // All values are being trimmed to remove white spaces
                                    id: ocLinesData.length + 1,
                                    recordType: ocLine.substring(0, 2).trim(), // value character length = 2
                                    loadId: ocLine.substring(2, 12).trim(), // value character length = 10
                                    locationCode: ocLine.substring(12, 19).trim(), // value character length = 7
                                    organization: ocLine.substring(19, 21).trim(), // value character length = 2
                                    consNo: ocLine.substring(21, 31).trim(), // value character length = 10
                                    consType: ocLine.substring(31, 33).trim(), // value character length = 2
                                    consDate: ocLine.substring(33, 41).trim(), // value character length = 8
                                    account: ocLine.substring(41, 47).trim(), // value character length = 6
                                    channel: ocLine.substring(47, 48).trim(), // value character length = 1
                                    proNo: ocLine.substring(48, 56).trim(), // value character length = 8
                                    growerAlloc: ocLine.substring(56, 62).trim(), // value character length = 6
                                    cartonQuantity: ocLine.substring(62, 70).trim(), // value character length = 8
                                    palletQuantity: ocLine.substring(70, 75).trim(), // value character length = 5
                                    fullPallet: ocLine.substring(75, 80).trim(), // value character length = 5
                                    incPallet: ocLine.substring(80, 85).trim(), // value character length = 5
                                    season: ocLine.substring(85, 89).trim(), // value character length = 4
                                    clientRef: ocLine.substring(89, 99).trim(), // value character length = 10
                                    allowCode: ocLine.substring(99, 103).trim(), // value character length = 4
                                    allowDel: ocLine.substring(103, 104).trim(), // value character length = 1
                                    linerBd: ocLine.substring(104, 105).trim(), // value character length = 1
                                    plasCover: ocLine.substring(105, 106).trim(), // value character length = 1
                                    spoorLoad: ocLine.substring(106, 107).trim(), // value character length = 1
                                    repackFlag: ocLine.substring(107, 108).trim(), // value character length = 1
                                    endorse1: ocLine.substring(108, 110).trim(), // value character length = 2
                                    endorse2: ocLine.substring(110, 112).trim(), // value character length = 2
                                    endorse3: ocLine.substring(112, 114).trim(), // value character length = 2
                                    endorse4: ocLine.substring(114, 116).trim(), // value character length = 2
                                    orderNumber: ocLine.substring(116, 122).trim(), // value character length = 6
                                    destinationType: ocLine.substring(122, 124).trim(), // value character length = 2
                                    destinationCode: ocLine.substring(124, 131).trim(), // value character length = 7
                                    cntsOnTruck: ocLine.substring(131, 136).trim(), // value character length = 5
                                    mixCntPals: ocLine.substring(136, 141).trim(), // value character length = 5
                                    consCount: ocLine.substring(141, 146).trim(), // value character length = 5
                                    palsUnstable: ocLine.substring(146, 151).trim(), // value character length = 5
                                    palsDamage: ocLine.substring(151, 156).trim(), // value character length = 5
                                    palsSundry: ocLine.substring(156, 161).trim(), // value character length = 5
                                    consStatus: ocLine.substring(161, 162).trim(), // value character length = 1
                                    batch: ocLine.substring(162, 167).trim(), // value character length = 5
                                    xmitFlag: ocLine.substring(167, 168).trim(), // value character length = 1
                                    revision: ocLine.substring(168, 173).trim(), // value character length = 5
                                    message: ocLine.substring(173, 181).trim(), // value character length = 8
                                    transactionUser: ocLine.substring(181, 188).trim(), // value character length = 7
                                    transactionDate: ocLine.substring(188, 201).trim(), // value character length = 13
                                    palletBaseType: ocLine.substring(201, 202).trim(), // value character length = 1
                                    temperature: ocLine.substring(202, 208).trim(), // value character length = 6
                                    principal: ocLine.substring(208, 210).trim(), // value character length = 2
                                    steriFlag: ocLine.substring(210, 212).trim(), // value character length = 2
                                    steriDestination: ocLine.substring(212, 214).trim(), // value character length = 2
                                    inspector: ocLine.substring(214, 220).trim(), // value character length = 6

                                    linkedOPLines: [],
                                };

                                line.opLines.forEach((opLine) => {
                                    // NB! Always maintain the order otherwhise values will be messed up
                                    let opLineData : IOPLine = { // All values are being trimmed to remove white spaces
                                        id: ocLineData.linkedOPLines.length + 1,
                                        guid: v1(),
                                        recordType: opLine.substring(0, 2).trim(), // value character length = 2
                                        loadId: opLine.substring(2, 12).trim(), // value character length = 10
                                        palletId: opLine.substring(12, 21).trim(), // value character length = 9
                                        sequenceNo: opLine.substring(21, 26).trim(), // value character length = 5
                                        unitType: opLine.substring(26, 27).trim(), // value character length = 1
                                        position: opLine.substring(27, 33).trim(), // value character length = 6
                                        sender: opLine.substring(33, 35).trim(), // value character length = 2
                                        agent: opLine.substring(35, 37).trim(), // value character length = 2
                                        shipSender: opLine.substring(37, 39).trim(), // value character length = 2
                                        shipAgent: opLine.substring(39, 41).trim(), // value character length = 2
                                        destinationType: opLine.substring(41, 43).trim(), // value character length = 2
                                        destinationLocation: opLine.substring(43, 50).trim(), // value character length = 7
                                        consNo: opLine.substring(50, 60).trim(), // value character length = 10
                                        container: opLine.substring(60, 71).trim(), // value character length = 11
                                        containerSplit: opLine.substring(71, 72).trim(), // value character length = 1
                                        channel: opLine.substring(72, 73).trim(), // value character length = 1
                                        organization: opLine.substring(73, 75).trim(), // value character length = 2
                                        country: opLine.substring(75, 77).trim(), // value character length = 2
                                        commodityGroup: opLine.substring(77, 79).trim(), // value character length = 2
                                        commodity: opLine.substring(79, 81).trim(), // value character length = 2
                                        varietyGroup: opLine.substring(81, 83).trim(), // value character length = 2
                                        variety: opLine.substring(83, 86).trim(), // value character length = 3
                                        subVariety: opLine.substring(86, 89).trim(), // value character length = 3
                                        actualVariety: opLine.substring(89, 92).trim(), // value character length = 3
                                        pack: opLine.substring(92, 96).trim(), // value character length = 4
                                        grade: opLine.substring(96, 100).trim(), // value character length = 4
                                        brand: opLine.substring(100, 105).trim(), // value character length = 5
                                        sizeCount: opLine.substring(105, 110).trim(), // value character length = 5
                                        inventoryCode: opLine.substring(110, 112).trim(), // value character length = 2
                                        pickRef: opLine.substring(112, 116).trim(), // value character length = 4
                                        farm: opLine.substring(116, 123).trim(), // value character length = 7
                                        productGroup: opLine.substring(123, 125).trim(), // value character length = 2
                                        productCharacteristic: opLine.substring(125, 128).trim(), // value character length = 3
                                        targetMarket: opLine.substring(128, 130).trim(), // value character length = 2
                                        cartonQuantity: opLine.substring(130, 135).trim(), // value character length = 5
                                        palletQuantity: opLine.substring(135, 144).trim(), // value character length = 9
                                        mixedIndicator: opLine.substring(144, 145).trim(), // value character length = 1
                                        rebrands: opLine.substring(145, 153).trim(), // value character length = 8
                                        reason: opLine.substring(153, 157).trim(), // value character length = 4
                                        intakeDate: opLine.substring(157, 165).trim(), // value character length = 8
                                        originalDepot: opLine.substring(165, 172).trim(), // value character length = 7
                                        originalIntake: opLine.substring(172, 180).trim(), // value character length = 8
                                        shift: opLine.substring(180, 181).trim(), // value character length = 1
                                        shiftDate: opLine.substring(181, 189).trim(), // value character length = 8
                                        orderNumber: opLine.substring(189, 195).trim(), // value character length = 6
                                        locationCode: opLine.substring(195, 202).trim(), // value character length = 7
                                        store: opLine.substring(202, 204).trim(), // value character length = 2
                                        stockPool: opLine.substring(204, 206).trim(), // value character length = 2
                                        shippedDate: opLine.substring(206, 219).trim(), // value character length = 13
                                        xmitFlag: opLine.substring(219, 220).trim(), // value character length = 1
                                        revision: opLine.substring(220, 225).trim(), // value character length = 5
                                        message: opLine.substring(225, 233).trim(), // value character length = 8
                                        transactionUser: opLine.substring(233, 240).trim(), // value character length = 7
                                        transactionDate: opLine.substring(240, 253).trim(), // value character length = 13
                                        palletBaseType: opLine.substring(253, 254).trim(), // value character length = 1
                                        originalCons: opLine.substring(254, 264).trim(), // value character length = 10
                                        shipNumber: opLine.substring(264, 270).trim(), // value character length = 6
                                        temperature: opLine.substring(270, 276).trim(), // value character length = 6
                                        comboPalletId: opLine.substring(276, 285).trim(), // value character length = 9
                                        tempDeviceId: opLine.substring(285, 305).trim(), // value character length = 20
                                        tempDeviceType: opLine.substring(305, 307).trim(), // value character length = 2
                                        billOfEntryNo: opLine.substring(307, 313).trim(), // value character length = 6
                                        principal: opLine.substring(313, 315).trim(), // value character length = 2
                                        sscc: opLine.substring(315, 333).trim(), // value character length = 18
                                        nettMass: opLine.substring(333, 342).trim(), // value character length = 9
                                        saftbin1: opLine.substring(342, 358).trim(), // value character length = 16
                                        saftbin2: opLine.substring(358, 374).trim(), // value character length = 16
                                        saftbin3: opLine.substring(374, 390).trim(), // value character length = 16
                                        originalAccount: opLine.substring(390, 396).trim(), // value character length = 6
                                        inspectionDate: opLine.substring(396, 404).trim(), // value character length = 8
                                        stackVariance: opLine.substring(404, 405).trim(), // value character length = 1
                                        storeType: opLine.substring(405, 406).trim(), // value character length = 1
                                        batchNo: opLine.substring(406, 426).trim(), // value character length = 20
                                        waybillNo: opLine.substring(426, 436).trim(), // value character length = 10
                                        gtin: opLine.substring(436, 450).trim(), // value character length = 14
                                        packhouseCode: opLine.substring(450, 457).trim(), // value character length = 7
                                        steriFlag: opLine.substring(457, 459).trim(), // value character length = 2
                                        steriDestination: opLine.substring(459, 461).trim(), // value character length = 2
                                        labelType: opLine.substring(461, 462).trim(), // value character length = 1
                                        provFlag: opLine.substring(462, 463).trim(), // value character length = 1
                                        sellbyCode: opLine.substring(463, 473).trim(), // value character length = 10
                                        comboSscc: opLine.substring(473, 491).trim(), // value character length = 18
                                        inspector: opLine.substring(491, 497).trim(), // value character length = 6
                                        inspectionPoint: opLine.substring(497, 503).trim(), // value character length = 6
                                        expiryCode: opLine.substring(503, 513).trim(), // value character length = 10
                                        orchard: opLine.substring(513, 528).trim(), // value character length = 15
                                        targetRegion: opLine.substring(528, 533).trim(), // value character length = 5
                                        targetCountry: opLine.substring(533, 535).trim(), // value character length = 2
                                        globalGapNo: opLine.substring(535, 555).trim(), // value character length = 20
                                        lotNo: opLine.substring(555, 575).trim(), // value character length = 20
                                        traceabilityCode: opLine.substring(575, 595).trim(), // value character length = 20
                                        season: opLine.substring(595, 599).trim(), // value character length = 4
                                        originalInspectionDate: opLine.substring(599, 607).trim(), // value character length = 8
                                        innerPack: opLine.substring(607, 617).trim(), // value character length = 10
                                        innerCartons: opLine.substring(617, 622).trim(), // value character length = 5
                                        productionId: opLine.substring(622, 642).trim(), // value character length = 20
                                        protocolExceptionIndicator: opLine.substring(642, 644).trim(), // value character length = 2
                                        upn: opLine.substring(644, 669).trim(), // value character length = 25
                                        palletTreatment: opLine.substring(669, 699).trim(), // value character length = 30
                                        palletGrossMass: opLine.substring(699, 709).trim(), // value character length = 10
                                        samsaAccreditation: opLine.substring(709, 719).trim(), // value character length = 10
                                        weighingLocation: opLine.substring(719, 726).trim(), // value character length = 7
                                        weighingDateTime: opLine.substring(726, 739).trim(), // value character length = 13
                                        mainArea: opLine.substring(739, 741).trim(), // value character length = 2
                                        productionArea: opLine.substring(741, 757).trim(), // value character length = 16
                                        phytoData: opLine.substring(757, 767).trim(), // value character length = 10
                                        customerOrderNo: opLine.substring(767, 807).trim(), // value character length = 40
                                        reInspectionDocumentNo: opLine.substring(807, 817).trim(), // value character length = 10
                                        eLotKey: opLine.substring(817, 827).trim(), // value character length = 10
                                        agreementCode: opLine.substring(827, 837).trim(), // value character length = 10
                                        postTreatment: opLine.substring(837, 977).trim(), // value character length = 140
                                        referenceNumber: opLine.substring(977, 997).trim(), // value character length = 20
                                    };

                                    ocLineData.linkedOPLines = addArrayElement(ocLineData.linkedOPLines, opLineData, 'end');
                                });

                                ocLinesData = addArrayElement(ocLinesData, ocLineData, 'end');
                            });

                            // NB! Always maintain the order otherwhise values will be messed up
                            const poBatchTrailerData : IPOBatchTrailer = { // All values are being trimmed to remove white spaces
                                recordType: poBatchTrailerLine.substring(0, 2).trim(), // value character length = 2
                                networkAddress: poBatchTrailerLine.substring(2, 5).trim(), // value character length = 3
                                batchNo: poBatchTrailerLine.substring(5, 11).trim(), // value character length = 6
                                recordCount: poBatchTrailerLine.substring(11, 18).trim(), // value character length = 7
                                ohCount: poBatchTrailerLine.substring(18, 23).trim(), // value character length = 5
                                olCount: poBatchTrailerLine.substring(23, 28).trim(), // value character length = 5
                                ocCount: poBatchTrailerLine.substring(28, 33).trim(), // value character length = 5
                                okCount: poBatchTrailerLine.substring(33, 38).trim(), // value character length = 5
                                opCount: poBatchTrailerLine.substring(38, 43).trim(), // value character length = 5
                                totalCartonCount: poBatchTrailerLine.substring(43, 51).trim(), // value character length = 8
                                totalPalletCount: poBatchTrailerLine.substring(51, 60).trim(), // value character length = 9
                            };

                            const poFileContentData : IPOFileContentData = {
                                batchHeader: poBatchHeaderData,
                                ohLine: ohLineData,
                                olLines: olLinesData,
                                okLine: okLineData,
                                ocLines: ocLinesData,
                                batchTrailer: poBatchTrailerData,
                            };

                            let createdDispatches : Array<IImportedPOFileDispatch> = [];

                            poFileContentData.ocLines.forEach((ocLine) => {
                                const sourceSite = this.getSite(ocLine.locationCode);
                                const linkedSourceOrganizations = this.props.organizations.filter(x => x.isActive && sourceSite?.organizationIds.some(y => y === x.id));

                                const dispatch : IImportedPOFileDispatch = {
                                    id: 0,
                                    guid: v1(),
                                    loadDate: ocLine.consDate,
                                    dispatchCode: ocLine.consNo,
                                    sourceOrganizationOption: linkedSourceOrganizations.length === 1
                                        ? { label: `(${linkedSourceOrganizations[0]?.code}) - ${linkedSourceOrganizations[0]?.name}` ?? '', value: linkedSourceOrganizations[0]?.id ?? 0 }
                                        : undefined,
                                    targetOrganizationOption: !!this.getOrganization(ocLine.organization)
                                        ? { label: `(${this.getOrganization(ocLine.organization)?.code}) - ${this.getOrganization(ocLine.organization)?.name}` ?? '', value: this.getOrganization(ocLine.organization)?.id ?? 0 }
                                        : undefined,
                                    sourceSiteOption: !!this.getSite(ocLine.locationCode)
                                        ? { label: `(${this.getSite(ocLine.locationCode)?.code}) - ${this.getSite(ocLine.locationCode)?.shortDescription}` ?? '', value: this.getSite(ocLine.locationCode)?.id ?? 0 }
                                        : undefined,
                                    destinationSiteOption: !!this.getSite(ocLine.destinationCode)
                                        ? { label: `(${this.getSite(ocLine.destinationCode)?.code}) - ${this.getSite(ocLine.destinationCode)?.shortDescription}` ?? '', value: this.getSite(ocLine.destinationCode)?.id ?? 0 }
                                        : undefined,
                                    registrationNumber: poFileContentData.ohLine.truckNumber,
                                    linkedPOLines: [],
                                };

                                let createdStocks : Array<IImportedPOFileStock> = [];

                                ocLine.linkedOPLines.forEach((opLine) => {
                                    const originalSite = this.getSite(opLine.packhouseCode);
                                    const linkedOriginalOrganizations = this.props.organizations.filter(x => x.isActive && originalSite?.organizationIds.some(y => y === x.id));
                                    const stock : IImportedPOFileStock = {
                                        id: opLine.id,
                                        guid: opLine.guid,
                                        barcode: opLine.sscc,
                                        originalOrganizationOption: linkedOriginalOrganizations.length === 1
                                            ? { label: `(${linkedOriginalOrganizations[0]?.code}) - ${linkedOriginalOrganizations[0]?.name}` ?? '', value: linkedOriginalOrganizations[0]?.id ?? 0 }
                                            : undefined,
                                        originalSiteOption: !!this.getSite(opLine.packhouseCode)
                                            ? { label: `(${this.getSite(opLine.packhouseCode)?.code}) - ${this.getSite(opLine.packhouseCode)?.shortDescription}`, value: this.getSite(opLine.packhouseCode)?.id ?? 0 }
                                            : undefined,
                                        currentOrganizationOption: !!this.getOrganization(opLine.organization)
                                            ? { label: `(${this.getOrganization(opLine.organization)?.code}) - ${this.getOrganization(opLine.organization)?.name}`, value: this.getOrganization(opLine.organization)?.id ?? 0 }
                                            : undefined,
                                        currentSiteOption: !!this.getSite(opLine.locationCode)
                                            ? { label: `(${this.getSite(opLine.locationCode)?.code}) - ${this.getSite(opLine.locationCode)?.shortDescription}`, value: this.getSite(opLine.locationCode)?.id ?? 0 }
                                            : undefined,
                                        channel: opLine.channel,
                                        regionOption: !!this.getRegion(opLine.targetRegion)
                                            ? { label: `(${this.getRegion(opLine.targetRegion)?.code}) - ${this.getRegion(opLine.targetRegion)?.name}`, value: this.getRegion(opLine.targetRegion)?.id ?? 0 }
                                            : undefined,
                                        countryOption: !!this.getCountry(opLine.targetCountry)
                                            ? { label: `(${this.getCountry(opLine.targetCountry)?.code}) - ${this.getCountry(opLine.targetCountry)?.name}`, value: this.getCountry(opLine.targetCountry)?.id ?? 0 }
                                            : undefined,
                                        brandOption: !!this.getBrand(opLine.brand)
                                            ? { label: `(${this.getBrand(opLine.brand)?.code}) - ${this.getBrand(opLine.brand)?.description}`, value: this.getBrand(opLine.brand)?.id ?? 0 }
                                            : undefined,
                                        marketOption: !!this.getMarket(opLine.targetMarket)
                                            ? { label: `(${this.getMarket(opLine.targetMarket)?.code}) - ${this.getMarket(opLine.targetMarket)?.name}`, value: this.getMarket(opLine.targetMarket)?.id ?? 0 }
                                            : undefined,
                                        inventoryOption: !!this.getInventory(opLine.inventoryCode)
                                            ? { label: `(${this.getInventory(opLine.inventoryCode)?.code}) - ${this.getInventory(opLine.inventoryCode)?.description}`, value: this.getInventory(opLine.inventoryCode)?.id ?? 0 }
                                            : undefined,
                                        cartons: opLine.cartonQuantity,
                                        hasMixedLoad: opLine.mixedIndicator === 'Y' ? true : false,
                                        palletBaseTypeOption: !!this.getPalletBaseType(opLine.palletBaseType)
                                            ? { label: `(${this.getPalletBaseType(opLine.palletBaseType)?.code}) - ${this.getPalletBaseType(opLine.palletBaseType)?.name}`, value: this.getPalletBaseType(opLine.palletBaseType)?.id ?? 0 }
                                            : undefined,
                                        grossWeight: opLine.palletGrossMass,
                                        estimatedGrossWeight: opLine.palletGrossMass,
                                        estimatedNettWeight: opLine.nettMass,
                                        season: opLine.season,
                                        packDate: opLine.intakeDate,
                                        palletSize: opLine.palletQuantity,
                                        commodityOption: !!this.getCommodity(opLine)
                                            ? { label: `(${this.getCommodity(opLine)?.code}) - ${this.getCommodity(opLine)?.name}`, value: this.getCommodity(opLine)?.id ?? 0 }
                                            : undefined,
                                        varietyOption: !!this.getVariety(opLine)
                                            ? { label: `(${this.getVariety(opLine)?.code}) - ${this.getVariety(opLine)?.name}`, value: this.getVariety(opLine)?.id ?? 0 }
                                            : undefined,
                                        farmOption: !!this.getFarm(opLine)
                                            ? { label: `(${this.getFarm(opLine)?.code}) - ${this.getFarm(opLine)?.name}`, value: this.getFarm(opLine)?.id ?? 0 }
                                            : undefined,
                                        packOption: !!this.getPack(opLine)
                                            ? { label: `(${this.getPack(opLine)?.code}) - ${this.getPack(opLine)?.description}`, value: this.getPack(opLine)?.id ?? 0 }
                                            : undefined,
                                        gradeOption: !!this.getGrade(opLine)
                                            ? { label: `(${this.getGrade(opLine)?.code}) - ${this.getGrade(opLine)?.name}`, value: this.getGrade(opLine)?.id ?? 0 }
                                            : undefined,
                                        sizeOption: !!this.getSize(opLine)
                                            ? { label: `(${this.getSize(opLine)?.code}) - ${this.getSize(opLine)?.name}`, value: this.getSize(opLine)?.id ?? 0 }
                                            : undefined,
                                        colourOption: !!this.getColour(opLine)
                                            ? { label: `(${this.getColour(opLine)?.code}) - ${this.getColour(opLine)?.name}`, value: this.getColour(opLine)?.id ?? 0 }
                                            : undefined,
                                        orchardOption: !!this.getOrchard(opLine)
                                            ? { label: `(${this.getOrchard(opLine)?.code}) - ${this.getOrchard(opLine)?.name}`, value: this.getOrchard(opLine)?.id ?? 0 }
                                            : undefined,
                                        // orchardOption: (opLine.upn !== '' || opLine.inspector !== '') && !!this.getOrchard(opLine)
                                        //     ? { label: `(${this.getOrchard(opLine)?.code}) - ${this.getOrchard(opLine)?.name}`, value: this.getOrchard(opLine)?.id ?? 0 }
                                        //     : undefined,
                                    };

                                    createdStocks = addArrayElement(createdStocks, stock, 'end');
                                });

                                dispatch.linkedPOLines = createdStocks;

                                createdDispatches = addArrayElement(createdDispatches, dispatch, 'end');
                            });

                            this.setState({ poFileContentData, createdDispatches });
                            const dispatchCodes : Array<string> = createdDispatches.filter(x => !!x.dispatchCode).map(x => x.dispatchCode ?? '');
                            const linkedStocks : Array<IImportedPOFileStock> = lodash.flatMap(createdDispatches, x => x.linkedPOLines);
                            const stockBarcodes : Array<string> = linkedStocks.map(x => x.barcode);
                            const dispatchCodesToCheck = dispatchCodes.filter(x => x !== '');
                            this.getExistingImportedFileData(dispatchCodesToCheck, stockBarcodes);
                        }
                    };
                    reader.readAsText(file);
                });
            }
        } else {
            generalShowErrorSnackbar('A file is already selected. Please remove the file before selecting a new file');
        }
    };

    private onFileRemove = (file : File) => {
        const index = this.state.selectedFiles?.findIndex(x => x.name === file.name);
        if (index > -1) {
            this.setState({
                selectedFiles: removeArrayElement(this.state.selectedFiles, index),
                poFileContentData: undefined,
                createdDispatches: [],
                existingImportedPOFileData: undefined,
                isWarningDialogOpen: false,
                isPOFileDispatchesAlreadyReceived: false,
                isSomeStocksLinkedToCompliance: false,
                hasStocksBeenMovedToDifferentDispatch: false,
                massColumnChangeSelectedOptions: [] });
        }
    };

    private getOrganization = (orgCode : string) => {
        const organization = this.props.organizations?.find(x => x.code === orgCode);

        return organization;
    };

    private getSiteById = (id : number) => {
        const site = this.props.sites?.find(x => x.id === id);

        return site;
    };

    private getSite = (siteShortDesciption : string) => {
        const site = this.props.sites?.find(x => x.shortDescription === siteShortDesciption || x.code === siteShortDesciption);

        return site;
    };

    private getPalletBaseType = (code : string) => {
        const palletBaseType = this.props.palletBaseTypes?.find(x => x.code === code);

        return palletBaseType;
    };

    private getMarket = (code : string) => {
        const market = this.props.markets?.find(x => x.code === code);

        return market;
    };

    private getBrand = (code : string) => {
        const brand = this.props.brands?.find(x => x.code === code);

        return brand;
    };

    private getRegion = (code : string) => {
        const region = this.props.regions?.find(x => x.code === code);

        return region;
    };

    private getCountry = (code : string) => {
        const country = this.props.countries?.find(x => x.code === code);

        return country;
    };

    private getInventory = (code : string) => {
        const inventory = this.props.inventories?.find(x => x.code === code);

        return inventory;
    };

    private getCommodity = (opLine : IOPLine) => {
        const commodity = this.props.commodities?.find(x => x.code === opLine.commodity);

        return commodity;
    };

    private getVariety = (opLine : IOPLine) => {
        const commodity = this.getCommodity(opLine);
        const variety = this.props.varieties?.find(x => x.code === opLine.variety && x.commodityId === commodity?.id);

        return variety;
    };

    private getFarm = (opLine : IOPLine) => {
        const farm = this.props.farms?.find(x => x.code === opLine.farm);

        return farm;
    };

    private getPack = (opLine : IOPLine) => {
        const commodity = this.getCommodity(opLine);
        const pack = this.props.packs?.find(x => x.code === opLine.pack && x.commodityId === commodity?.id);

        return pack;
    };

    private getPackById = (id : number) => {
        const pack = this.props.packs?.find(x => x.id === id);

        return pack;
    };

    private getGrade = (opLine : IOPLine) => {
        const commodity = this.getCommodity(opLine);
        const grade = this.props.grades?.find(x => x.code === opLine.grade && x.commodityIds.some(y => y === commodity?.id));

        return grade;
    };

    private getSize = (opLine : IOPLine) => {
        const pack = this.getPack(opLine);
        const size = this.props.sizes?.find(x => x.code === opLine.sizeCount && x.packIds.some(y => y === pack?.id));

        return size;
    };

    private getColour = (opLine : IOPLine) => {
        const commodity = this.getCommodity(opLine);
        const colour = this.props.colours?.find(x => x.code === opLine.varietyGroup && x.commodityIds && x.commodityIds.some(y => y === commodity?.id));

        return colour;
    };

    private getOrchard = (opLine : IOPLine) => {
        const farm = this.getFarm(opLine);
        const commodity = this.getCommodity(opLine);
        const orchard = this.props.orchards?.find(x =>
            (x.code === opLine.orchard || (!!x.complianceCode && x.complianceCode === opLine.orchard))
            && x.farmId === farm?.id
            && x.commodityId === commodity?.id);

        return orchard;
    };

    private onDispatchSourceOrganizationOptionChange = (e : CustomChangeEvent, selectedOrganization : IOptionType, dispatchCode : string) => {
        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const index = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && index !== -1) {
            const updatedValues : IImportedPOFileDispatch = {
                ...dispatchToUpdate,
                sourceOrganizationOption: selectedOrganization,
            };

            const updatedList = setArrayElement(this.state.createdDispatches, index, updatedValues);

            this.setState({ createdDispatches: updatedList });
        }
    };

    private onDispatchTargetOrganizationOptionChange = (e : CustomChangeEvent, selectedOrganization : IOptionType, dispatchCode : string) => {
        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const index = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && index !== -1) {
            const updatedValues : IImportedPOFileDispatch = {
                ...dispatchToUpdate,
                targetOrganizationOption: selectedOrganization,
                destinationSiteOption: undefined,
            };

            const updatedList = setArrayElement(this.state.createdDispatches, index, updatedValues);

            this.setState({ createdDispatches: updatedList });
        }
    };

    private onDispatchSourceSiteOptionChange = (e : CustomChangeEvent, selectedSite : IOptionType, dispatchCode : string) => {
        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const index = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && index !== -1) {
            const updatedValues : IImportedPOFileDispatch = {
                ...dispatchToUpdate,
                sourceSiteOption: selectedSite,
            };

            const updatedList = setArrayElement(this.state.createdDispatches, index, updatedValues);

            this.setState({ createdDispatches: updatedList });
        }
    };

    private onDispatchDestinationSiteOptionChange = (e : CustomChangeEvent, selectedSite : IOptionType, dispatchCode : string) => {
        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const index = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && index !== -1) {
            const updatedValues : IImportedPOFileDispatch = {
                ...dispatchToUpdate,
                destinationSiteOption: selectedSite,
            };

            const updatedList = setArrayElement(this.state.createdDispatches, index, updatedValues);

            this.setState({ createdDispatches: updatedList });
        }
    };

    private onDispatchRegistrationNumberChange = (e : CustomChangeEvent, dispatchCode : string) => {
        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const index = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && index !== -1) {
            const updatedValues : IImportedPOFileDispatch = {
                ...dispatchToUpdate,
                registrationNumber: e.target.value,
            };

            const updatedList = setArrayElement(this.state.createdDispatches, index, updatedValues);

            this.setState({ createdDispatches: updatedList });
        }
    };

    private onStockOriginalOrganizationOptionChange = (e : CustomChangeEvent, selectedOrganization : IOptionType, dispatchCode : string, stockGuid : string) => {
        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const dispatchToUpdateIndex = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && dispatchToUpdateIndex !== -1) {
            const stockToUpdate = dispatchToUpdate.linkedPOLines.find(x => x.guid === stockGuid);
            const stockToUpdateIndex = dispatchToUpdate.linkedPOLines.findIndex(x => x.guid === stockGuid);

            if (stockToUpdate && stockToUpdateIndex !== -1) {
                const updatedRowValues : IImportedPOFileStock = {
                    ...stockToUpdate,
                    originalOrganizationOption: selectedOrganization,
                };

                const updatedValues : IImportedPOFileDispatch = {
                    ...dispatchToUpdate,
                    linkedPOLines: setArrayElement(dispatchToUpdate.linkedPOLines, stockToUpdateIndex, updatedRowValues),
                };

                const updatedList = setArrayElement(this.state.createdDispatches, dispatchToUpdateIndex, updatedValues);

                this.setState({ createdDispatches: updatedList });
            }
        }
    };

    private onStockCurrentOrganizationOptionChange = (e : CustomChangeEvent, selectedOrganization : IOptionType, dispatchCode : string, stockGuid : string) => {
        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const dispatchToUpdateIndex = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && dispatchToUpdateIndex !== -1) {
            const stockToUpdate = dispatchToUpdate.linkedPOLines.find(x => x.guid === stockGuid);
            const stockToUpdateIndex = dispatchToUpdate.linkedPOLines.findIndex(x => x.guid === stockGuid);

            if (stockToUpdate && stockToUpdateIndex !== -1) {
                const updatedRowValues : IImportedPOFileStock = {
                    ...stockToUpdate,
                    currentOrganizationOption: selectedOrganization,
                    currentSiteOption: undefined,
                };

                const updatedValues : IImportedPOFileDispatch = {
                    ...dispatchToUpdate,
                    linkedPOLines: setArrayElement(dispatchToUpdate.linkedPOLines, stockToUpdateIndex, updatedRowValues),
                };

                const updatedList = setArrayElement(this.state.createdDispatches, dispatchToUpdateIndex, updatedValues);

                this.setState({ createdDispatches: updatedList });
            }
        }
    };

    private onStockOriginalSiteOptionChange = (e : CustomChangeEvent, selectedSite : IOptionType, dispatchCode : string, stockGuid : string) => {
        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const dispatchToUpdateIndex = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && dispatchToUpdateIndex !== -1) {
            const stockToUpdate = dispatchToUpdate.linkedPOLines.find(x => x.guid === stockGuid);
            const stockToUpdateIndex = dispatchToUpdate.linkedPOLines.findIndex(x => x.guid === stockGuid);

            if (stockToUpdate && stockToUpdateIndex !== -1) {
                const updatedRowValues : IImportedPOFileStock = {
                    ...stockToUpdate,
                    originalSiteOption: selectedSite,
                };

                const updatedValues : IImportedPOFileDispatch = {
                    ...dispatchToUpdate,
                    linkedPOLines: setArrayElement(dispatchToUpdate.linkedPOLines, stockToUpdateIndex, updatedRowValues),
                };

                const updatedList = setArrayElement(this.state.createdDispatches, dispatchToUpdateIndex, updatedValues);

                this.setState({ createdDispatches: updatedList });
            }
        }
    };

    private onStockCurrentSiteOptionChange = (e : CustomChangeEvent, selectedSite : IOptionType, dispatchCode : string, stockGuid : string) => {
        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const dispatchToUpdateIndex = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && dispatchToUpdateIndex !== -1) {
            const stockToUpdate = dispatchToUpdate.linkedPOLines.find(x => x.guid === stockGuid);
            const stockToUpdateIndex = dispatchToUpdate.linkedPOLines.findIndex(x => x.guid === stockGuid);

            if (stockToUpdate && stockToUpdateIndex !== -1) {
                const updatedRowValues : IImportedPOFileStock = {
                    ...stockToUpdate,
                    currentSiteOption: selectedSite,
                };

                const updatedValues : IImportedPOFileDispatch = {
                    ...dispatchToUpdate,
                    linkedPOLines: setArrayElement(dispatchToUpdate.linkedPOLines, stockToUpdateIndex, updatedRowValues),
                };

                const updatedList = setArrayElement(this.state.createdDispatches, dispatchToUpdateIndex, updatedValues);

                this.setState({ createdDispatches: updatedList });
            }
        }
    };

    private onStockPalletBaseTypeOptionChange = (e : CustomChangeEvent, selectedPalletBaseType : IOptionType, dispatchCode : string, stockGuid : string) => {
        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const dispatchToUpdateIndex = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && dispatchToUpdateIndex !== -1) {
            const stockToUpdate = dispatchToUpdate.linkedPOLines.find(x => x.guid === stockGuid);
            const stockToUpdateIndex = dispatchToUpdate.linkedPOLines.findIndex(x => x.guid === stockGuid);

            if (stockToUpdate && stockToUpdateIndex !== -1) {
                const updatedRowValues : IImportedPOFileStock = {
                    ...stockToUpdate,
                    palletBaseTypeOption: selectedPalletBaseType,
                };

                const updatedValues : IImportedPOFileDispatch = {
                    ...dispatchToUpdate,
                    linkedPOLines: setArrayElement(dispatchToUpdate.linkedPOLines, stockToUpdateIndex, updatedRowValues),
                };

                const updatedList = setArrayElement(this.state.createdDispatches, dispatchToUpdateIndex, updatedValues);

                this.setState({ createdDispatches: updatedList });
            }
        }
    };

    private onStockRegionOptionChange = (e : CustomChangeEvent, selectedRegion : IOptionType, dispatchCode : string, stockGuid : string) => {
        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const dispatchToUpdateIndex = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && dispatchToUpdateIndex !== -1) {
            const stockToUpdate = dispatchToUpdate.linkedPOLines.find(x => x.guid === stockGuid);
            const stockToUpdateIndex = dispatchToUpdate.linkedPOLines.findIndex(x => x.guid === stockGuid);

            if (stockToUpdate && stockToUpdateIndex !== -1) {
                const updatedRowValues : IImportedPOFileStock = {
                    ...stockToUpdate,
                    regionOption: selectedRegion,
                    countryOption: undefined,
                    marketOption: undefined,
                };

                const updatedValues : IImportedPOFileDispatch = {
                    ...dispatchToUpdate,
                    linkedPOLines: setArrayElement(dispatchToUpdate.linkedPOLines, stockToUpdateIndex, updatedRowValues),
                };

                const updatedList = setArrayElement(this.state.createdDispatches, dispatchToUpdateIndex, updatedValues);

                this.setState({ createdDispatches: updatedList });
            }
        }
    };

    private onStockCountryOptionChange = (e : CustomChangeEvent, selectedCountry : IOptionType, dispatchCode : string, stockGuid : string) => {
        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const dispatchToUpdateIndex = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && dispatchToUpdateIndex !== -1) {
            const stockToUpdate = dispatchToUpdate.linkedPOLines.find(x => x.guid === stockGuid);
            const stockToUpdateIndex = dispatchToUpdate.linkedPOLines.findIndex(x => x.guid === stockGuid);

            if (stockToUpdate && stockToUpdateIndex !== -1) {
                const updatedRowValues : IImportedPOFileStock = {
                    ...stockToUpdate,
                    countryOption: selectedCountry,
                };

                const updatedValues : IImportedPOFileDispatch = {
                    ...dispatchToUpdate,
                    linkedPOLines: setArrayElement(dispatchToUpdate.linkedPOLines, stockToUpdateIndex, updatedRowValues),
                };

                const updatedList = setArrayElement(this.state.createdDispatches, dispatchToUpdateIndex, updatedValues);

                this.setState({ createdDispatches: updatedList });
            }
        }
    };

    private onStockMarketOptionChange = (e : CustomChangeEvent, selectedMarket : IOptionType, dispatchCode : string, stockGuid : string) => {
        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const dispatchToUpdateIndex = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && dispatchToUpdateIndex !== -1) {
            const stockToUpdate = dispatchToUpdate.linkedPOLines.find(x => x.guid === stockGuid);
            const stockToUpdateIndex = dispatchToUpdate.linkedPOLines.findIndex(x => x.guid === stockGuid);

            if (stockToUpdate && stockToUpdateIndex !== -1) {
                const updatedRowValues : IImportedPOFileStock = {
                    ...stockToUpdate,
                    marketOption: selectedMarket,
                };

                const updatedValues : IImportedPOFileDispatch = {
                    ...dispatchToUpdate,
                    linkedPOLines: setArrayElement(dispatchToUpdate.linkedPOLines, stockToUpdateIndex, updatedRowValues),
                };

                const updatedList = setArrayElement(this.state.createdDispatches, dispatchToUpdateIndex, updatedValues);

                this.setState({ createdDispatches: updatedList });
            }
        }
    };

    private onStockBrandOptionChange = (e : CustomChangeEvent, selectedBrand : IOptionType, dispatchCode : string, stockGuid : string) => {
        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const dispatchToUpdateIndex = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && dispatchToUpdateIndex !== -1) {
            const stockToUpdate = dispatchToUpdate.linkedPOLines.find(x => x.guid === stockGuid);
            const stockToUpdateIndex = dispatchToUpdate.linkedPOLines.findIndex(x => x.guid === stockGuid);

            if (stockToUpdate && stockToUpdateIndex !== -1) {
                const updatedRowValues : IImportedPOFileStock = {
                    ...stockToUpdate,
                    brandOption: selectedBrand,
                };

                const updatedValues : IImportedPOFileDispatch = {
                    ...dispatchToUpdate,
                    linkedPOLines: setArrayElement(dispatchToUpdate.linkedPOLines, stockToUpdateIndex, updatedRowValues),
                };

                const updatedList = setArrayElement(this.state.createdDispatches, dispatchToUpdateIndex, updatedValues);

                this.setState({ createdDispatches: updatedList });
            }
        }
    };

    private onStockInventoryOptionChange = (e : CustomChangeEvent, selectedInventory : IOptionType, dispatchCode : string, stockGuid : string) => {
        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const dispatchToUpdateIndex = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && dispatchToUpdateIndex !== -1) {
            const stockToUpdate = dispatchToUpdate.linkedPOLines.find(x => x.guid === stockGuid);
            const stockToUpdateIndex = dispatchToUpdate.linkedPOLines.findIndex(x => x.guid === stockGuid);

            if (stockToUpdate && stockToUpdateIndex !== -1) {
                const updatedRowValues : IImportedPOFileStock = {
                    ...stockToUpdate,
                    inventoryOption: selectedInventory,
                };

                const updatedValues : IImportedPOFileDispatch = {
                    ...dispatchToUpdate,
                    linkedPOLines: setArrayElement(dispatchToUpdate.linkedPOLines, stockToUpdateIndex, updatedRowValues),
                };

                const updatedList = setArrayElement(this.state.createdDispatches, dispatchToUpdateIndex, updatedValues);

                this.setState({ createdDispatches: updatedList });
            }
        }
    };

    private onStockCommodityOptionChange = (e : CustomChangeEvent, selectedCommodity : IOptionType, dispatchCode : string, stockGuid : string) => {
        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const dispatchToUpdateIndex = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && dispatchToUpdateIndex !== -1) {
            const stockToUpdate = dispatchToUpdate.linkedPOLines.find(x => x.guid === stockGuid);
            const stockToUpdateIndex = dispatchToUpdate.linkedPOLines.findIndex(x => x.guid === stockGuid);

            if (stockToUpdate && stockToUpdateIndex !== -1) {
                const updatedRowValues : IImportedPOFileStock = {
                    ...stockToUpdate,
                    commodityOption: selectedCommodity,
                    varietyOption: undefined,
                    packOption: undefined,
                    sizeOption: undefined,
                    gradeOption: undefined,
                    colourOption: undefined,
                };

                const updatedValues : IImportedPOFileDispatch = {
                    ...dispatchToUpdate,
                    linkedPOLines: setArrayElement(dispatchToUpdate.linkedPOLines, stockToUpdateIndex, updatedRowValues),
                };

                const updatedList = setArrayElement(this.state.createdDispatches, dispatchToUpdateIndex, updatedValues);

                this.setState({ createdDispatches: updatedList });
            }
        }
    };

    private onStockVarietyOptionChange = (e : CustomChangeEvent, selectedVariety : IOptionType, dispatchCode : string, stockGuid : string) => {
        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const dispatchToUpdateIndex = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && dispatchToUpdateIndex !== -1) {
            const stockToUpdate = dispatchToUpdate.linkedPOLines.find(x => x.guid === stockGuid);
            const stockToUpdateIndex = dispatchToUpdate.linkedPOLines.findIndex(x => x.guid === stockGuid);

            if (stockToUpdate && stockToUpdateIndex !== -1) {
                const updatedRowValues : IImportedPOFileStock = {
                    ...stockToUpdate,
                    varietyOption: selectedVariety,
                };

                const updatedValues : IImportedPOFileDispatch = {
                    ...dispatchToUpdate,
                    linkedPOLines: setArrayElement(dispatchToUpdate.linkedPOLines, stockToUpdateIndex, updatedRowValues),
                };

                const updatedList = setArrayElement(this.state.createdDispatches, dispatchToUpdateIndex, updatedValues);

                this.setState({ createdDispatches: updatedList });
            }
        }
    };

    private onStockPackOptionChange = (e : CustomChangeEvent, selectedPack : IOptionType, dispatchCode : string, stockGuid : string) => {
        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const dispatchToUpdateIndex = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && dispatchToUpdateIndex !== -1) {
            const stockToUpdate = dispatchToUpdate.linkedPOLines.find(x => x.guid === stockGuid);
            const stockToUpdateIndex = dispatchToUpdate.linkedPOLines.findIndex(x => x.guid === stockGuid);

            if (stockToUpdate && stockToUpdateIndex !== -1) {
                const updatedRowValues : IImportedPOFileStock = {
                    ...stockToUpdate,
                    packOption: selectedPack,
                    sizeOption: undefined,
                };

                const updatedValues : IImportedPOFileDispatch = {
                    ...dispatchToUpdate,
                    linkedPOLines: setArrayElement(dispatchToUpdate.linkedPOLines, stockToUpdateIndex, updatedRowValues),
                };

                const updatedList = setArrayElement(this.state.createdDispatches, dispatchToUpdateIndex, updatedValues);

                this.setState({ createdDispatches: updatedList });
            }
        }
    };

    private onStockSizeOptionChange = (e : CustomChangeEvent, selectedSize : IOptionType, dispatchCode : string, stockGuid : string) => {
        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const dispatchToUpdateIndex = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && dispatchToUpdateIndex !== -1) {
            const stockToUpdate = dispatchToUpdate.linkedPOLines.find(x => x.guid === stockGuid);
            const stockToUpdateIndex = dispatchToUpdate.linkedPOLines.findIndex(x => x.guid === stockGuid);

            if (stockToUpdate && stockToUpdateIndex !== -1) {
                const updatedRowValues : IImportedPOFileStock = {
                    ...stockToUpdate,
                    sizeOption: selectedSize,
                };

                const updatedValues : IImportedPOFileDispatch = {
                    ...dispatchToUpdate,
                    linkedPOLines: setArrayElement(dispatchToUpdate.linkedPOLines, stockToUpdateIndex, updatedRowValues),
                };

                const updatedList = setArrayElement(this.state.createdDispatches, dispatchToUpdateIndex, updatedValues);

                this.setState({ createdDispatches: updatedList });
            }
        }
    };

    private onStockGradeOptionChange = (e : CustomChangeEvent, selectedGrade : IOptionType, dispatchCode : string, stockGuid : string) => {
        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const dispatchToUpdateIndex = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && dispatchToUpdateIndex !== -1) {
            const stockToUpdate = dispatchToUpdate.linkedPOLines.find(x => x.guid === stockGuid);
            const stockToUpdateIndex = dispatchToUpdate.linkedPOLines.findIndex(x => x.guid === stockGuid);

            if (stockToUpdate && stockToUpdateIndex !== -1) {
                const updatedRowValues : IImportedPOFileStock = {
                    ...stockToUpdate,
                    gradeOption: selectedGrade,
                };

                const updatedValues : IImportedPOFileDispatch = {
                    ...dispatchToUpdate,
                    linkedPOLines: setArrayElement(dispatchToUpdate.linkedPOLines, stockToUpdateIndex, updatedRowValues),
                };

                const updatedList = setArrayElement(this.state.createdDispatches, dispatchToUpdateIndex, updatedValues);

                this.setState({ createdDispatches: updatedList });
            }
        }
    };

    private onStockColourOptionChange = (e : CustomChangeEvent, selectedColour : IOptionType, dispatchCode : string, stockGuid : string) => {
        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const dispatchToUpdateIndex = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && dispatchToUpdateIndex !== -1) {
            const stockToUpdate = dispatchToUpdate.linkedPOLines.find(x => x.guid === stockGuid);
            const stockToUpdateIndex = dispatchToUpdate.linkedPOLines.findIndex(x => x.guid === stockGuid);

            if (stockToUpdate && stockToUpdateIndex !== -1) {
                const updatedRowValues : IImportedPOFileStock = {
                    ...stockToUpdate,
                    colourOption: selectedColour,
                };

                const updatedValues : IImportedPOFileDispatch = {
                    ...dispatchToUpdate,
                    linkedPOLines: setArrayElement(dispatchToUpdate.linkedPOLines, stockToUpdateIndex, updatedRowValues),
                };

                const updatedList = setArrayElement(this.state.createdDispatches, dispatchToUpdateIndex, updatedValues);

                this.setState({ createdDispatches: updatedList });
            }
        }
    };

    private onStockFarmOptionChange = (e : CustomChangeEvent, selectedFarm : IOptionType, dispatchCode : string, stockGuid : string) => {
        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const dispatchToUpdateIndex = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && dispatchToUpdateIndex !== -1) {
            const stockToUpdate = dispatchToUpdate.linkedPOLines.find(x => x.guid === stockGuid);
            const stockToUpdateIndex = dispatchToUpdate.linkedPOLines.findIndex(x => x.guid === stockGuid);

            if (stockToUpdate && stockToUpdateIndex !== -1) {
                const updatedRowValues : IImportedPOFileStock = {
                    ...stockToUpdate,
                    farmOption: selectedFarm,
                    orchardOption: undefined,
                };

                const updatedValues : IImportedPOFileDispatch = {
                    ...dispatchToUpdate,
                    linkedPOLines: setArrayElement(dispatchToUpdate.linkedPOLines, stockToUpdateIndex, updatedRowValues),
                };

                const updatedList = setArrayElement(this.state.createdDispatches, dispatchToUpdateIndex, updatedValues);

                this.setState({ createdDispatches: updatedList });
            }
        }
    };

    private onStockOrchardOptionChange = (e : CustomChangeEvent, selectedOrchard : IOptionType, dispatchCode : string, stockGuid : string) => {
        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const dispatchToUpdateIndex = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && dispatchToUpdateIndex !== -1) {
            const stockToUpdate = dispatchToUpdate.linkedPOLines.find(x => x.guid === stockGuid);
            const stockToUpdateIndex = dispatchToUpdate.linkedPOLines.findIndex(x => x.guid === stockGuid);

            if (stockToUpdate && stockToUpdateIndex !== -1) {
                const updatedRowValues : IImportedPOFileStock = {
                    ...stockToUpdate,
                    orchardOption: selectedOrchard,
                };

                const updatedValues : IImportedPOFileDispatch = {
                    ...dispatchToUpdate,
                    linkedPOLines: setArrayElement(dispatchToUpdate.linkedPOLines, stockToUpdateIndex, updatedRowValues),
                };

                const updatedList = setArrayElement(this.state.createdDispatches, dispatchToUpdateIndex, updatedValues);

                this.setState({ createdDispatches: updatedList });
            }
        }
    };

    private onSelectAllStockOriginalOrganizationChange = async (e : React.ChangeEvent<{}>, selectedOriginalOrganization : IOptionType, dispatchCode : string) => {
        const dispatchOPLineMassSelectedOptions = this.state.massColumnChangeSelectedOptions.find(x => x.dispatchCode === dispatchCode);
        const dispatchOPLineMassSelectedOptionsIndex = this.state.massColumnChangeSelectedOptions.findIndex(x => x.dispatchCode === dispatchCode);

        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const index = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && index !== -1) {
            const updatedDispatch : IImportedPOFileDispatch = {
                ...dispatchToUpdate,
                linkedPOLines: dispatchToUpdate.linkedPOLines.map((x) => {
                    const updatedLine : IImportedPOFileStock = {
                        ...x,
                        originalOrganizationOption: selectedOriginalOrganization,
                    };

                    return updatedLine;
                }),
            };

            const updatedList = setArrayElement(this.state.createdDispatches, index, updatedDispatch);

            let updatedMassColumnChangeSelectedOptions : Array<IOCLineMassSelectOptions> = [];

            if (dispatchOPLineMassSelectedOptions && dispatchOPLineMassSelectedOptionsIndex !== -1) {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    ...dispatchOPLineMassSelectedOptions,
                    selectedOriginalOrganization,
                };

                updatedMassColumnChangeSelectedOptions = setArrayElement(this.state.massColumnChangeSelectedOptions, dispatchOPLineMassSelectedOptionsIndex, updatedSelectedOptions);
            } else {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    dispatchCode,
                    selectedOriginalOrganization,
                };

                updatedMassColumnChangeSelectedOptions = addArrayElement(this.state.massColumnChangeSelectedOptions, updatedSelectedOptions, 'end');
            }

            this.setState({ createdDispatches: updatedList, massColumnChangeSelectedOptions: updatedMassColumnChangeSelectedOptions });
        }
    };

    private onSelectAllStockCurrentOrganizationChange = async (e : React.ChangeEvent<{}>, selectedCurrentOrganization : IOptionType, dispatchCode : string) => {
        const dispatchOPLineMassSelectedOptions = this.state.massColumnChangeSelectedOptions.find(x => x.dispatchCode === dispatchCode);
        const dispatchOPLineMassSelectedOptionsIndex = this.state.massColumnChangeSelectedOptions.findIndex(x => x.dispatchCode === dispatchCode);

        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const index = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && index !== -1) {
            const updatedDispatch : IImportedPOFileDispatch = {
                ...dispatchToUpdate,
                linkedPOLines: dispatchToUpdate.linkedPOLines.map((x) => {
                    const updatedLine : IImportedPOFileStock = {
                        ...x,
                        currentOrganizationOption: selectedCurrentOrganization,
                        currentSiteOption: undefined,
                    };

                    return updatedLine;
                }),
            };

            const updatedList = setArrayElement(this.state.createdDispatches, index, updatedDispatch);

            let updatedMassColumnChangeSelectedOptions : Array<IOCLineMassSelectOptions> = [];

            if (dispatchOPLineMassSelectedOptions && dispatchOPLineMassSelectedOptionsIndex !== -1) {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    ...dispatchOPLineMassSelectedOptions,
                    selectedCurrentOrganization,
                    selectedCurrentSite: undefined,
                };

                updatedMassColumnChangeSelectedOptions = setArrayElement(this.state.massColumnChangeSelectedOptions, dispatchOPLineMassSelectedOptionsIndex, updatedSelectedOptions);
            } else {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    dispatchCode,
                    selectedCurrentOrganization,
                    selectedCurrentSite: undefined,
                };

                updatedMassColumnChangeSelectedOptions = addArrayElement(this.state.massColumnChangeSelectedOptions, updatedSelectedOptions, 'end');
            }

            this.setState({ createdDispatches: updatedList, massColumnChangeSelectedOptions: updatedMassColumnChangeSelectedOptions });
        }
    };

    private onSelectAllStockOriginalSiteChange = async (e : React.ChangeEvent<{}>, selectedOriginalSite : IOptionType, dispatchCode : string) => {
        const dispatchOPLineMassSelectedOptions = this.state.massColumnChangeSelectedOptions.find(x => x.dispatchCode === dispatchCode);
        const dispatchOPLineMassSelectedOptionsIndex = this.state.massColumnChangeSelectedOptions.findIndex(x => x.dispatchCode === dispatchCode);

        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const index = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && index !== -1) {
            const updatedDispatch : IImportedPOFileDispatch = {
                ...dispatchToUpdate,
                linkedPOLines: dispatchToUpdate.linkedPOLines.map((x) => {
                    const updatedLine : IImportedPOFileStock = {
                        ...x,
                        originalSiteOption: selectedOriginalSite,
                    };

                    return updatedLine;
                }),
            };

            const updatedList = setArrayElement(this.state.createdDispatches, index, updatedDispatch);

            let updatedMassColumnChangeSelectedOptions : Array<IOCLineMassSelectOptions> = [];

            if (dispatchOPLineMassSelectedOptions && dispatchOPLineMassSelectedOptionsIndex !== -1) {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    ...dispatchOPLineMassSelectedOptions,
                    selectedOriginalSite,
                };

                updatedMassColumnChangeSelectedOptions = setArrayElement(this.state.massColumnChangeSelectedOptions, dispatchOPLineMassSelectedOptionsIndex, updatedSelectedOptions);
            } else {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    dispatchCode,
                    selectedOriginalSite,
                };

                updatedMassColumnChangeSelectedOptions = addArrayElement(this.state.massColumnChangeSelectedOptions, updatedSelectedOptions, 'end');
            }

            this.setState({ createdDispatches: updatedList, massColumnChangeSelectedOptions: updatedMassColumnChangeSelectedOptions });
        }
    };

    private onSelectAllStockCurrentSiteChange = async (e : React.ChangeEvent<{}>, selectedCurrentSite : IOptionType, dispatchCode : string) => {
        const dispatchOPLineMassSelectedOptions = this.state.massColumnChangeSelectedOptions.find(x => x.dispatchCode === dispatchCode);
        const dispatchOPLineMassSelectedOptionsIndex = this.state.massColumnChangeSelectedOptions.findIndex(x => x.dispatchCode === dispatchCode);

        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const index = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && index !== -1) {
            const updatedDispatch : IImportedPOFileDispatch = {
                ...dispatchToUpdate,
                linkedPOLines: dispatchToUpdate.linkedPOLines.map((x) => {
                    const updatedLine : IImportedPOFileStock = {
                        ...x,
                        currentSiteOption: selectedCurrentSite,
                    };

                    return updatedLine;
                }),
            };

            const updatedList = setArrayElement(this.state.createdDispatches, index, updatedDispatch);

            let updatedMassColumnChangeSelectedOptions : Array<IOCLineMassSelectOptions> = [];

            if (dispatchOPLineMassSelectedOptions && dispatchOPLineMassSelectedOptionsIndex !== -1) {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    ...dispatchOPLineMassSelectedOptions,
                    selectedCurrentSite,
                };

                updatedMassColumnChangeSelectedOptions = setArrayElement(this.state.massColumnChangeSelectedOptions, dispatchOPLineMassSelectedOptionsIndex, updatedSelectedOptions);
            } else {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    dispatchCode,
                    selectedCurrentSite,
                };

                updatedMassColumnChangeSelectedOptions = addArrayElement(this.state.massColumnChangeSelectedOptions, updatedSelectedOptions, 'end');
            }

            this.setState({ createdDispatches: updatedList, massColumnChangeSelectedOptions: updatedMassColumnChangeSelectedOptions });
        }
    };

    private onSelectAllPalletBaseTypeChange = async (e : React.ChangeEvent<{}>, selectedPalletBaseType : IOptionType, dispatchCode : string) => {
        const dispatchOPLineMassSelectedOptions = this.state.massColumnChangeSelectedOptions.find(x => x.dispatchCode === dispatchCode);
        const dispatchOPLineMassSelectedOptionsIndex = this.state.massColumnChangeSelectedOptions.findIndex(x => x.dispatchCode === dispatchCode);

        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const index = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && index !== -1) {
            const updatedDispatch : IImportedPOFileDispatch = {
                ...dispatchToUpdate,
                linkedPOLines: dispatchToUpdate.linkedPOLines.map((x) => {
                    const updatedLine : IImportedPOFileStock = {
                        ...x,
                        palletBaseTypeOption: selectedPalletBaseType,
                    };

                    return updatedLine;
                }),
            };

            const updatedList = setArrayElement(this.state.createdDispatches, index, updatedDispatch);

            let updatedMassColumnChangeSelectedOptions : Array<IOCLineMassSelectOptions> = [];

            if (dispatchOPLineMassSelectedOptions && dispatchOPLineMassSelectedOptionsIndex !== -1) {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    ...dispatchOPLineMassSelectedOptions,
                    selectedPalletBaseType,
                };

                updatedMassColumnChangeSelectedOptions = setArrayElement(this.state.massColumnChangeSelectedOptions, dispatchOPLineMassSelectedOptionsIndex, updatedSelectedOptions);
            } else {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    dispatchCode,
                    selectedPalletBaseType,
                };

                updatedMassColumnChangeSelectedOptions = addArrayElement(this.state.massColumnChangeSelectedOptions, updatedSelectedOptions, 'end');
            }

            this.setState({ createdDispatches: updatedList, massColumnChangeSelectedOptions: updatedMassColumnChangeSelectedOptions });
        }
    };

    private onSelectAllRegionChange = async (e : React.ChangeEvent<{}>, selectedRegion : IOptionType, dispatchCode : string) => {
        const dispatchOPLineMassSelectedOptions = this.state.massColumnChangeSelectedOptions.find(x => x.dispatchCode === dispatchCode);
        const dispatchOPLineMassSelectedOptionsIndex = this.state.massColumnChangeSelectedOptions.findIndex(x => x.dispatchCode === dispatchCode);

        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const index = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && index !== -1) {
            const updatedDispatch : IImportedPOFileDispatch = {
                ...dispatchToUpdate,
                linkedPOLines: dispatchToUpdate.linkedPOLines.map((x) => {
                    const updatedLine : IImportedPOFileStock = {
                        ...x,
                        regionOption: selectedRegion,
                    };

                    return updatedLine;
                }),
            };

            const updatedList = setArrayElement(this.state.createdDispatches, index, updatedDispatch);

            let updatedMassColumnChangeSelectedOptions : Array<IOCLineMassSelectOptions> = [];

            if (dispatchOPLineMassSelectedOptions && dispatchOPLineMassSelectedOptionsIndex !== -1) {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    ...dispatchOPLineMassSelectedOptions,
                    selectedRegion,
                };

                updatedMassColumnChangeSelectedOptions = setArrayElement(this.state.massColumnChangeSelectedOptions, dispatchOPLineMassSelectedOptionsIndex, updatedSelectedOptions);
            } else {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    dispatchCode,
                    selectedRegion,
                };

                updatedMassColumnChangeSelectedOptions = addArrayElement(this.state.massColumnChangeSelectedOptions, updatedSelectedOptions, 'end');
            }

            this.setState({ createdDispatches: updatedList, massColumnChangeSelectedOptions: updatedMassColumnChangeSelectedOptions });
        }
    };

    private onSelectAllCountryChange = async (e : React.ChangeEvent<{}>, selectedCountry : IOptionType, dispatchCode : string) => {
        const dispatchOPLineMassSelectedOptions = this.state.massColumnChangeSelectedOptions.find(x => x.dispatchCode === dispatchCode);
        const dispatchOPLineMassSelectedOptionsIndex = this.state.massColumnChangeSelectedOptions.findIndex(x => x.dispatchCode === dispatchCode);

        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const index = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && index !== -1) {
            const updatedDispatch : IImportedPOFileDispatch = {
                ...dispatchToUpdate,
                linkedPOLines: dispatchToUpdate.linkedPOLines.map((x) => {
                    const updatedLine : IImportedPOFileStock = {
                        ...x,
                        countryOption: selectedCountry,
                    };

                    return updatedLine;
                }),
            };

            const updatedList = setArrayElement(this.state.createdDispatches, index, updatedDispatch);

            let updatedMassColumnChangeSelectedOptions : Array<IOCLineMassSelectOptions> = [];

            if (dispatchOPLineMassSelectedOptions && dispatchOPLineMassSelectedOptionsIndex !== -1) {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    ...dispatchOPLineMassSelectedOptions,
                    selectedCountry,
                };

                updatedMassColumnChangeSelectedOptions = setArrayElement(this.state.massColumnChangeSelectedOptions, dispatchOPLineMassSelectedOptionsIndex, updatedSelectedOptions);
            } else {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    dispatchCode,
                    selectedCountry,
                };

                updatedMassColumnChangeSelectedOptions = addArrayElement(this.state.massColumnChangeSelectedOptions, updatedSelectedOptions, 'end');
            }

            this.setState({ createdDispatches: updatedList, massColumnChangeSelectedOptions: updatedMassColumnChangeSelectedOptions });
        }
    };

    private onSelectAllMarketChange = async (e : React.ChangeEvent<{}>, selectedMarket : IOptionType, dispatchCode : string) => {
        const dispatchOPLineMassSelectedOptions = this.state.massColumnChangeSelectedOptions.find(x => x.dispatchCode === dispatchCode);
        const dispatchOPLineMassSelectedOptionsIndex = this.state.massColumnChangeSelectedOptions.findIndex(x => x.dispatchCode === dispatchCode);

        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const index = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && index !== -1) {
            const updatedDispatch : IImportedPOFileDispatch = {
                ...dispatchToUpdate,
                linkedPOLines: dispatchToUpdate.linkedPOLines.map((x) => {
                    const updatedLine : IImportedPOFileStock = {
                        ...x,
                        marketOption: selectedMarket,
                    };

                    return updatedLine;
                }),
            };

            const updatedList = setArrayElement(this.state.createdDispatches, index, updatedDispatch);

            let updatedMassColumnChangeSelectedOptions : Array<IOCLineMassSelectOptions> = [];

            if (dispatchOPLineMassSelectedOptions && dispatchOPLineMassSelectedOptionsIndex !== -1) {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    ...dispatchOPLineMassSelectedOptions,
                    selectedMarket,
                };

                updatedMassColumnChangeSelectedOptions = setArrayElement(this.state.massColumnChangeSelectedOptions, dispatchOPLineMassSelectedOptionsIndex, updatedSelectedOptions);
            } else {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    dispatchCode,
                    selectedMarket,
                };

                updatedMassColumnChangeSelectedOptions = addArrayElement(this.state.massColumnChangeSelectedOptions, updatedSelectedOptions, 'end');
            }

            this.setState({ createdDispatches: updatedList, massColumnChangeSelectedOptions: updatedMassColumnChangeSelectedOptions });
        }
    };

    private onSelectAllBrandChange = async (e : React.ChangeEvent<{}>, selectedBrand : IOptionType, dispatchCode : string) => {
        const dispatchOPLineMassSelectedOptions = this.state.massColumnChangeSelectedOptions.find(x => x.dispatchCode === dispatchCode);
        const dispatchOPLineMassSelectedOptionsIndex = this.state.massColumnChangeSelectedOptions.findIndex(x => x.dispatchCode === dispatchCode);

        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const index = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && index !== -1) {
            const updatedDispatch : IImportedPOFileDispatch = {
                ...dispatchToUpdate,
                linkedPOLines: dispatchToUpdate.linkedPOLines.map((x) => {
                    const updatedLine : IImportedPOFileStock = {
                        ...x,
                        brandOption: selectedBrand,
                    };

                    return updatedLine;
                }),
            };

            const updatedList = setArrayElement(this.state.createdDispatches, index, updatedDispatch);

            let updatedMassColumnChangeSelectedOptions : Array<IOCLineMassSelectOptions> = [];

            if (dispatchOPLineMassSelectedOptions && dispatchOPLineMassSelectedOptionsIndex !== -1) {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    ...dispatchOPLineMassSelectedOptions,
                    selectedBrand,
                };

                updatedMassColumnChangeSelectedOptions = setArrayElement(this.state.massColumnChangeSelectedOptions, dispatchOPLineMassSelectedOptionsIndex, updatedSelectedOptions);
            } else {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    dispatchCode,
                    selectedBrand,
                };

                updatedMassColumnChangeSelectedOptions = addArrayElement(this.state.massColumnChangeSelectedOptions, updatedSelectedOptions, 'end');
            }

            this.setState({ createdDispatches: updatedList, massColumnChangeSelectedOptions: updatedMassColumnChangeSelectedOptions });
        }
    };

    private onSelectAllInventoryChange = async (e : React.ChangeEvent<{}>, selectedInventory : IOptionType, dispatchCode : string) => {
        const dispatchOPLineMassSelectedOptions = this.state.massColumnChangeSelectedOptions.find(x => x.dispatchCode === dispatchCode);
        const dispatchOPLineMassSelectedOptionsIndex = this.state.massColumnChangeSelectedOptions.findIndex(x => x.dispatchCode === dispatchCode);

        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const index = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && index !== -1) {
            const updatedDispatch : IImportedPOFileDispatch = {
                ...dispatchToUpdate,
                linkedPOLines: dispatchToUpdate.linkedPOLines.map((x) => {
                    const updatedLine : IImportedPOFileStock = {
                        ...x,
                        inventoryOption: selectedInventory,
                    };

                    return updatedLine;
                }),
            };

            const updatedList = setArrayElement(this.state.createdDispatches, index, updatedDispatch);

            let updatedMassColumnChangeSelectedOptions : Array<IOCLineMassSelectOptions> = [];

            if (dispatchOPLineMassSelectedOptions && dispatchOPLineMassSelectedOptionsIndex !== -1) {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    ...dispatchOPLineMassSelectedOptions,
                    selectedInventory,
                };

                updatedMassColumnChangeSelectedOptions = setArrayElement(this.state.massColumnChangeSelectedOptions, dispatchOPLineMassSelectedOptionsIndex, updatedSelectedOptions);
            } else {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    dispatchCode,
                    selectedInventory,
                };

                updatedMassColumnChangeSelectedOptions = addArrayElement(this.state.massColumnChangeSelectedOptions, updatedSelectedOptions, 'end');
            }

            this.setState({ createdDispatches: updatedList, massColumnChangeSelectedOptions: updatedMassColumnChangeSelectedOptions });
        }
    };

    private onSelectAllFarmChange = async (e : React.ChangeEvent<{}>, selectedFarm : IOptionType, dispatchCode : string) => {
        const dispatchOPLineMassSelectedOptions = this.state.massColumnChangeSelectedOptions.find(x => x.dispatchCode === dispatchCode);
        const dispatchOPLineMassSelectedOptionsIndex = this.state.massColumnChangeSelectedOptions.findIndex(x => x.dispatchCode === dispatchCode);

        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const index = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && index !== -1) {
            const updatedDispatch : IImportedPOFileDispatch = {
                ...dispatchToUpdate,
                linkedPOLines: dispatchToUpdate.linkedPOLines.map((x) => {
                    const updatedLine : IImportedPOFileStock = {
                        ...x,
                        farmOption: selectedFarm,
                        orchardOption: undefined,
                    };

                    return updatedLine;
                }),
            };

            const updatedList = setArrayElement(this.state.createdDispatches, index, updatedDispatch);

            let updatedMassColumnChangeSelectedOptions : Array<IOCLineMassSelectOptions> = [];

            if (dispatchOPLineMassSelectedOptions && dispatchOPLineMassSelectedOptionsIndex !== -1) {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    ...dispatchOPLineMassSelectedOptions,
                    selectedFarm,
                    selectedOrchard: undefined,
                };

                updatedMassColumnChangeSelectedOptions = setArrayElement(this.state.massColumnChangeSelectedOptions, dispatchOPLineMassSelectedOptionsIndex, updatedSelectedOptions);
            } else {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    dispatchCode,
                    selectedFarm,
                    selectedOrchard: undefined,
                };

                updatedMassColumnChangeSelectedOptions = addArrayElement(this.state.massColumnChangeSelectedOptions, updatedSelectedOptions, 'end');
            }

            this.setState({ createdDispatches: updatedList, massColumnChangeSelectedOptions: updatedMassColumnChangeSelectedOptions });
        }
    };

    private onSelectAllCommodityChange = async (e : React.ChangeEvent<{}>, selectedCommodity : IOptionType, dispatchCode : string) => {
        const dispatchOPLineMassSelectedOptions = this.state.massColumnChangeSelectedOptions.find(x => x.dispatchCode === dispatchCode);
        const dispatchOPLineMassSelectedOptionsIndex = this.state.massColumnChangeSelectedOptions.findIndex(x => x.dispatchCode === dispatchCode);

        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const index = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && index !== -1) {
            const updatedDispatch : IImportedPOFileDispatch = {
                ...dispatchToUpdate,
                linkedPOLines: dispatchToUpdate.linkedPOLines.map((x) => {
                    const updatedLine : IImportedPOFileStock = {
                        ...x,
                        commodityOption: selectedCommodity,
                        varietyOption: undefined,
                        packOption: undefined,
                        sizeOption: undefined,
                        gradeOption: undefined,
                        colourOption: undefined,
                        orchardOption: undefined,
                    };

                    return updatedLine;
                }),
            };

            const updatedList = setArrayElement(this.state.createdDispatches, index, updatedDispatch);

            let updatedMassColumnChangeSelectedOptions : Array<IOCLineMassSelectOptions> = [];

            if (dispatchOPLineMassSelectedOptions && dispatchOPLineMassSelectedOptionsIndex !== -1) {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    ...dispatchOPLineMassSelectedOptions,
                    selectedCommodity,
                    selectedVariety: undefined,
                    selectedPack: undefined,
                    selectedSize: undefined,
                    selectedGrade: undefined,
                    selectedColour: undefined,
                    selectedOrchard: undefined,
                };

                updatedMassColumnChangeSelectedOptions = setArrayElement(this.state.massColumnChangeSelectedOptions, dispatchOPLineMassSelectedOptionsIndex, updatedSelectedOptions);
            } else {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    dispatchCode,
                    selectedCommodity,
                    selectedVariety: undefined,
                    selectedPack: undefined,
                    selectedSize: undefined,
                    selectedGrade: undefined,
                    selectedColour: undefined,
                    selectedOrchard: undefined,
                };

                updatedMassColumnChangeSelectedOptions = addArrayElement(this.state.massColumnChangeSelectedOptions, updatedSelectedOptions, 'end');
            }

            this.setState({ createdDispatches: updatedList, massColumnChangeSelectedOptions: updatedMassColumnChangeSelectedOptions });
        }
    };

    private onSelectAllVarietyChange = async (e : React.ChangeEvent<{}>, selectedVariety : IOptionType, dispatchCode : string) => {
        const dispatchOPLineMassSelectedOptions = this.state.massColumnChangeSelectedOptions.find(x => x.dispatchCode === dispatchCode);
        const dispatchOPLineMassSelectedOptionsIndex = this.state.massColumnChangeSelectedOptions.findIndex(x => x.dispatchCode === dispatchCode);

        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const index = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && index !== -1) {
            const updatedDispatch : IImportedPOFileDispatch = {
                ...dispatchToUpdate,
                linkedPOLines: dispatchToUpdate.linkedPOLines.map((x) => {
                    const updatedLine : IImportedPOFileStock = {
                        ...x,
                        varietyOption: selectedVariety,
                    };

                    return updatedLine;
                }),
            };

            const updatedList = setArrayElement(this.state.createdDispatches, index, updatedDispatch);

            let updatedMassColumnChangeSelectedOptions : Array<IOCLineMassSelectOptions> = [];

            if (dispatchOPLineMassSelectedOptions && dispatchOPLineMassSelectedOptionsIndex !== -1) {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    ...dispatchOPLineMassSelectedOptions,
                    selectedVariety,
                };

                updatedMassColumnChangeSelectedOptions = setArrayElement(this.state.massColumnChangeSelectedOptions, dispatchOPLineMassSelectedOptionsIndex, updatedSelectedOptions);
            } else {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    dispatchCode,
                    selectedVariety,
                };

                updatedMassColumnChangeSelectedOptions = addArrayElement(this.state.massColumnChangeSelectedOptions, updatedSelectedOptions, 'end');
            }

            this.setState({ createdDispatches: updatedList, massColumnChangeSelectedOptions: updatedMassColumnChangeSelectedOptions });
        }
    };

    private onSelectAllPackChange = async (e : React.ChangeEvent<{}>, selectedPack : IOptionType, dispatchCode : string) => {
        const dispatchOPLineMassSelectedOptions = this.state.massColumnChangeSelectedOptions.find(x => x.dispatchCode === dispatchCode);
        const dispatchOPLineMassSelectedOptionsIndex = this.state.massColumnChangeSelectedOptions.findIndex(x => x.dispatchCode === dispatchCode);

        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const index = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && index !== -1) {
            const updatedDispatch : IImportedPOFileDispatch = {
                ...dispatchToUpdate,
                linkedPOLines: dispatchToUpdate.linkedPOLines.map((x) => {
                    const updatedLine : IImportedPOFileStock = {
                        ...x,
                        packOption: selectedPack,
                        sizeOption: undefined,
                    };

                    return updatedLine;
                }),
            };

            const updatedList = setArrayElement(this.state.createdDispatches, index, updatedDispatch);

            let updatedMassColumnChangeSelectedOptions : Array<IOCLineMassSelectOptions> = [];

            if (dispatchOPLineMassSelectedOptions && dispatchOPLineMassSelectedOptionsIndex !== -1) {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    ...dispatchOPLineMassSelectedOptions,
                    selectedPack,
                    selectedSize: undefined,
                };

                updatedMassColumnChangeSelectedOptions = setArrayElement(this.state.massColumnChangeSelectedOptions, dispatchOPLineMassSelectedOptionsIndex, updatedSelectedOptions);
            } else {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    dispatchCode,
                    selectedPack,
                    selectedSize: undefined,
                };

                updatedMassColumnChangeSelectedOptions = addArrayElement(this.state.massColumnChangeSelectedOptions, updatedSelectedOptions, 'end');
            }

            this.setState({ createdDispatches: updatedList, massColumnChangeSelectedOptions: updatedMassColumnChangeSelectedOptions });
        }
    };

    private onSelectAllSizeChange = async (e : React.ChangeEvent<{}>, selectedSize : IOptionType, dispatchCode : string) => {
        const dispatchOPLineMassSelectedOptions = this.state.massColumnChangeSelectedOptions.find(x => x.dispatchCode === dispatchCode);
        const dispatchOPLineMassSelectedOptionsIndex = this.state.massColumnChangeSelectedOptions.findIndex(x => x.dispatchCode === dispatchCode);

        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const index = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && index !== -1) {
            const updatedDispatch : IImportedPOFileDispatch = {
                ...dispatchToUpdate,
                linkedPOLines: dispatchToUpdate.linkedPOLines.map((x) => {
                    const updatedLine : IImportedPOFileStock = {
                        ...x,
                        sizeOption: selectedSize,
                    };

                    return updatedLine;
                }),
            };

            const updatedList = setArrayElement(this.state.createdDispatches, index, updatedDispatch);

            let updatedMassColumnChangeSelectedOptions : Array<IOCLineMassSelectOptions> = [];

            if (dispatchOPLineMassSelectedOptions && dispatchOPLineMassSelectedOptionsIndex !== -1) {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    ...dispatchOPLineMassSelectedOptions,
                    selectedSize,
                };

                updatedMassColumnChangeSelectedOptions = setArrayElement(this.state.massColumnChangeSelectedOptions, dispatchOPLineMassSelectedOptionsIndex, updatedSelectedOptions);
            } else {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    dispatchCode,
                    selectedSize,
                };

                updatedMassColumnChangeSelectedOptions = addArrayElement(this.state.massColumnChangeSelectedOptions, updatedSelectedOptions, 'end');
            }

            this.setState({ createdDispatches: updatedList, massColumnChangeSelectedOptions: updatedMassColumnChangeSelectedOptions });
        }
    };

    private onSelectAllGradeChange = async (e : React.ChangeEvent<{}>, selectedGrade : IOptionType, dispatchCode : string) => {
        const dispatchOPLineMassSelectedOptions = this.state.massColumnChangeSelectedOptions.find(x => x.dispatchCode === dispatchCode);
        const dispatchOPLineMassSelectedOptionsIndex = this.state.massColumnChangeSelectedOptions.findIndex(x => x.dispatchCode === dispatchCode);

        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const index = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && index !== -1) {
            const updatedDispatch : IImportedPOFileDispatch = {
                ...dispatchToUpdate,
                linkedPOLines: dispatchToUpdate.linkedPOLines.map((x) => {
                    const updatedLine : IImportedPOFileStock = {
                        ...x,
                        gradeOption: selectedGrade,
                    };

                    return updatedLine;
                }),
            };

            const updatedList = setArrayElement(this.state.createdDispatches, index, updatedDispatch);

            let updatedMassColumnChangeSelectedOptions : Array<IOCLineMassSelectOptions> = [];

            if (dispatchOPLineMassSelectedOptions && dispatchOPLineMassSelectedOptionsIndex !== -1) {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    ...dispatchOPLineMassSelectedOptions,
                    selectedGrade,
                };

                updatedMassColumnChangeSelectedOptions = setArrayElement(this.state.massColumnChangeSelectedOptions, dispatchOPLineMassSelectedOptionsIndex, updatedSelectedOptions);
            } else {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    dispatchCode,
                    selectedGrade,
                };

                updatedMassColumnChangeSelectedOptions = addArrayElement(this.state.massColumnChangeSelectedOptions, updatedSelectedOptions, 'end');
            }

            this.setState({ createdDispatches: updatedList, massColumnChangeSelectedOptions: updatedMassColumnChangeSelectedOptions });
        }
    };

    private onSelectAllColourChange = async (e : React.ChangeEvent<{}>, selectedColour : IOptionType, dispatchCode : string) => {
        const dispatchOPLineMassSelectedOptions = this.state.massColumnChangeSelectedOptions.find(x => x.dispatchCode === dispatchCode);
        const dispatchOPLineMassSelectedOptionsIndex = this.state.massColumnChangeSelectedOptions.findIndex(x => x.dispatchCode === dispatchCode);

        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const index = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && index !== -1) {
            const updatedDispatch : IImportedPOFileDispatch = {
                ...dispatchToUpdate,
                linkedPOLines: dispatchToUpdate.linkedPOLines.map((x) => {
                    const updatedLine : IImportedPOFileStock = {
                        ...x,
                        colourOption: selectedColour,
                    };

                    return updatedLine;
                }),
            };

            const updatedList = setArrayElement(this.state.createdDispatches, index, updatedDispatch);

            let updatedMassColumnChangeSelectedOptions : Array<IOCLineMassSelectOptions> = [];

            if (dispatchOPLineMassSelectedOptions && dispatchOPLineMassSelectedOptionsIndex !== -1) {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    ...dispatchOPLineMassSelectedOptions,
                    selectedColour,
                };

                updatedMassColumnChangeSelectedOptions = setArrayElement(this.state.massColumnChangeSelectedOptions, dispatchOPLineMassSelectedOptionsIndex, updatedSelectedOptions);
            } else {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    dispatchCode,
                    selectedColour,
                };

                updatedMassColumnChangeSelectedOptions = addArrayElement(this.state.massColumnChangeSelectedOptions, updatedSelectedOptions, 'end');
            }

            this.setState({ createdDispatches: updatedList, massColumnChangeSelectedOptions: updatedMassColumnChangeSelectedOptions });
        }
    };

    private onSelectAllOrchardChange = async (e : React.ChangeEvent<{}>, selectedOrchard : IOptionType, dispatchCode : string) => {
        const dispatchOPLineMassSelectedOptions = this.state.massColumnChangeSelectedOptions.find(x => x.dispatchCode === dispatchCode);
        const dispatchOPLineMassSelectedOptionsIndex = this.state.massColumnChangeSelectedOptions.findIndex(x => x.dispatchCode === dispatchCode);

        const dispatchToUpdate = this.state.createdDispatches.find(x => x.dispatchCode === dispatchCode);
        const index = this.state.createdDispatches.findIndex(x => x.dispatchCode === dispatchCode);

        if (dispatchToUpdate && index !== -1) {
            const updatedDispatch : IImportedPOFileDispatch = {
                ...dispatchToUpdate,
                linkedPOLines: dispatchToUpdate.linkedPOLines.map((x) => {
                    const updatedLine : IImportedPOFileStock = {
                        ...x,
                        orchardOption: selectedOrchard,
                    };

                    return updatedLine;
                }),
            };

            const updatedList = setArrayElement(this.state.createdDispatches, index, updatedDispatch);

            let updatedMassColumnChangeSelectedOptions : Array<IOCLineMassSelectOptions> = [];

            if (dispatchOPLineMassSelectedOptions && dispatchOPLineMassSelectedOptionsIndex !== -1) {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    ...dispatchOPLineMassSelectedOptions,
                    selectedOrchard,
                };

                updatedMassColumnChangeSelectedOptions = setArrayElement(this.state.massColumnChangeSelectedOptions, dispatchOPLineMassSelectedOptionsIndex, updatedSelectedOptions);
            } else {
                const updatedSelectedOptions : IOCLineMassSelectOptions = {
                    dispatchCode,
                    selectedOrchard,
                };

                updatedMassColumnChangeSelectedOptions = addArrayElement(this.state.massColumnChangeSelectedOptions, updatedSelectedOptions, 'end');
            }

            this.setState({ createdDispatches: updatedList, massColumnChangeSelectedOptions: updatedMassColumnChangeSelectedOptions });
        }
    };

    private rowFailedValidation = (row : IImportedPOFileStock) => {
        const opLines : Array<IOPLine> = lodash.flatMap(this.state.poFileContentData?.ocLines, x => x.linkedOPLines);
        const matchingOPLine = opLines.find(x => x.sscc === row.barcode && x.guid === row.guid);
        if (!matchingOPLine
            || !row.originalOrganizationOption
            || !row.currentOrganizationOption
            || !row.currentSiteOption
            || !row.originalSiteOption
            || !row.palletBaseTypeOption
            || !row.marketOption
            || !row.brandOption
            || !row.regionOption
            || !row.countryOption
            || !row.commodityOption
            || !row.varietyOption
            || !row.packOption
            || !row.farmOption
            || !row.sizeOption
            || !row.colourOption
            || !row.gradeOption
            || ((matchingOPLine.upn !== '' || matchingOPLine.inspector !== '') && !row.orchardOption)) {
            return true;
        } else {
            return false;
        }
    };

    private disableSubmit = createSelector(
        [this.getCreatedDispatches, this.getIsPOFileDispatchesAlreadyReceived, this.getIsSomeStocksLinkedToCompliance, this.getHasStocksBeenMovedToDifferentDispatch],
        (createdDispatches, isPOFileDispatchesAlreadyReceived : boolean, isSomeStocksLinkedToCompliance : boolean, hasStocksBeenMovedToDifferentDispatch : boolean) => {
            let disabled : boolean = false;

            // check if all master data is valid
            if (createdDispatches.some(x => !x.targetOrganizationOption
                || !x.sourceSiteOption
                || !x.destinationSiteOption
                || x.linkedPOLines.some(stock => !!this.rowFailedValidation(stock)))) {
                disabled = true;
            } else if (isPOFileDispatchesAlreadyReceived || isSomeStocksLinkedToCompliance || hasStocksBeenMovedToDifferentDispatch) {
                disabled = true;
            }

            return disabled;
        },
    );

    private onOverrideDataYesClick = () => {
        this.onSubmit();
    };

    private onOverrideDataNoClick = () => {
        this.setState({
            poFileContentData: undefined,
            createdDispatches: [],
            isWarningDialogOpen: false,
            existingImportedPOFileData: undefined,
            selectedFiles: [],
            isPOFileDispatchesAlreadyReceived: false,
            isSomeStocksLinkedToCompliance: false,
            hasStocksBeenMovedToDifferentDispatch: false });
    };

    private onStockInfoOpen = (row : IImportedPOFileStock) => {
        const opLines : Array<IOPLine> = lodash.flatMap(this.state.poFileContentData?.ocLines, x => x.linkedOPLines);
        const matchingOPLine = opLines.find(x => x.sscc === row.barcode && x.guid === row.guid);
        this.setState({ selectedOPLineRow: matchingOPLine });
    };

    private onStockInfoClose = () => {
        this.setState({ selectedOPLineRow: undefined });
    };

    private getOrganizationOptions = createSelector(
        [this.getOrganizations],
        (organizations : Array<IOrganization>) => {
            return organizations.filter(x => x.isActive).map((x) => {
                return { label: `(${x.code}) - ${x.name}`, value: x.id };
            });
        },
    );

    private getSiteOptions = createSelector(
        [this.getSites],
        (sites : Array<ISite>) => {
            return sites.filter(x => x.isActive).map((x) => {
                return { label: `(${x.code}) - ${x.shortDescription}`, value: x.id };
            });
        },
    );

    private getPalletbaseTypeOptions = createSelector(
        [this.getPalletBaseTypes],
        (palletBaseTypes : Array<IPalletBaseType>) => {
            return palletBaseTypes.filter(x => x.isActive).map((x) => {
                return { label: `(${x.code}) - ${x.name}`, value: x.id };
            });
        },
    );

    private getRegionOptions = createSelector(
        [this.getRegions],
        (regions : Array<IRegion>) => {
            return regions.filter(x => x.isActive).map((x) => {
                return { label: `(${x.code}) - ${x.name}`, value: x.id };
            });
        },
    );

    private getBrandOptions = createSelector(
        [this.getBrands],
        (brands : Array<IBrand>) => {
            return brands.filter(x => x.isActive).map((x) => {
                return { label: `(${x.code}) - ${x.description}`, value: x.id };
            });
        },
    );

    private getInventoryOptions = createSelector(
        [this.getInventories],
        (inventories : Array<IInventory>) => {
            return inventories.filter(x => x.isActive).map((x) => {
                return { label: `(${x.code}) - ${x.description}`, value: x.id };
            });
        },
    );

    private getCommodityOptions = createSelector(
        [this.getCommodities],
        (commodities : Array<ICommodity>) => {
            return commodities.filter(x => x.isActive).map((x) => {
                return { label: `(${x.code}) - ${x.name}`, value: x.id };
            });
        },
    );

    private getMassSelectVarietyOptions = createSelector(
        [this.getVarieties],
        (varieties : Array<IVariety>) => {
            return varieties.filter(x => x.isActive).map((x) => {
                return { label: `(${x.code}) - ${x.name}`, value: x.id };
            });
        },
    );

    private getMassSelectPackOptions = createSelector(
        [this.getPacks],
        (packs : Array<IPack>) => {
            return packs.filter(x => x.isActive).map((x) => {
                return { label: `(${x.code}) - ${x.description}`, value: x.id };
            });
        },
    );

    private getMassSelectGradeOptions = createSelector(
        [this.getGrades],
        (grades : Array<IGrade>) => {
            return grades.filter(x => x.isActive).map((x) => {
                return { label: `(${x.code}) - ${x.name}`, value: x.id };
            });
        },
    );

    private getMassSelectSizeOptions = createSelector(
        [this.getSizes],
        (sizes : Array<ISize>) => {
            return sizes.filter(x => x.isActive).map((x) => {
                return { label: `(${x.code}) - ${x.name}`, value: x.id };
            });
        },
    );

    private getMassSelectColourOptions = createSelector(
        [this.getColours],
        (colours : Array<IColour>) => {
            return colours.filter(x => x.isActive).map((x) => {
                return { label: `(${x.code}) - ${x.name}`, value: x.id };
            });
        },
    );

    private getRights = (props : IImportPOFileScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IImportPOFileScreenProps) => props.location.pathname;

    private hasAdminEditRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_ADMIN_EDIT')));

    private hasDispatchEditRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_DISPATCH_EDIT')));

    private hasStockEditRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_STOCK_EDIT')));

    private renderListElement = React.memo(({ data, index, style } : React.PropsWithChildren<ListChildComponentProps>) => {
        const linkedPOLine : IImportedPOFileStock = data[index];

        const ocLine = this.state.poFileContentData?.ocLines.find(x => x.linkedOPLines.some(y => y.sscc === linkedPOLine.barcode && y.guid === linkedPOLine.guid));
        const matchingOPLine = ocLine?.linkedOPLines?.find(x => x.sscc === linkedPOLine.barcode && x.guid === linkedPOLine.guid);

        const linkedDispatch = this.state.createdDispatches.find(x => x.linkedPOLines.some(y => y.barcode === linkedPOLine.barcode && y.guid === linkedPOLine.guid));

        if (!linkedPOLine || !linkedDispatch || !matchingOPLine) return <div />;

        const originalSite = this.getSiteById(Number(linkedPOLine.originalSiteOption?.value ?? 0));

        const getOriginalOrganizationOptions = this.getOrganizations(this.props).filter(x => x.isActive && (!originalSite || originalSite?.organizationIds.some(y => y === x.id))).map((x) => {
            return { label: `(${x.code}) - ${x.name}`, value: x.id };
        }) ?? [];

        const getOriginalSiteOptions = this.getSites(this.props).filter(x => x.isActive && (!linkedPOLine.originalOrganizationOption || x.organizationIds.some(orgId => orgId === linkedPOLine.originalOrganizationOption?.value))).map((x) => {
            return { label: `(${x.code}) - ${x.shortDescription}`, value: x.id };
        }) ?? [];

        const getCurrentSiteOptions = this.getSites(this.props).filter(x => x.isActive && x.organizationIds.some(orgId => orgId === linkedPOLine.currentOrganizationOption?.value)).map((x) => {
            return { label: `(${x.code}) - ${x.shortDescription}`, value: x.id };
        }) ?? [];

        const countryOptions = this.getCountries(this.props).filter(x => x.isActive && x.regionId === linkedPOLine.regionOption?.value).map((x) => {
            return { label: `(${x.code}) - ${x.name}`, value: x.id };
        }) ?? [];

        const marketOptions = this.getMarkets(this.props).filter(x => x.isActive && x.regionId === linkedPOLine.regionOption?.value).map((x) => {
            return { label: `(${x.code}) - ${x.name}`, value: x.id };
        }) ?? [];

        const varietyOptions = this.getVarieties(this.props).filter(x => x.isActive && x.commodityId === linkedPOLine.commodityOption?.value).map((x) => {
            return { label: `(${x.code}) - ${x.name}`, value: x.id };
        }) ?? [];

        const packOptions = this.getPacks(this.props).filter(x => x.isActive && x.commodityId === linkedPOLine.commodityOption?.value).map((x) => {
            return { label: `(${x.code}) - ${x.description}`, value: x.id };
        }) ?? [];

        const gradeOptions = this.getGrades(this.props).filter(x => x.isActive && x.commodityIds.some(y => y === linkedPOLine.commodityOption?.value)).map((x) => {
            return { label: `(${x.code}) - ${x.name}`, value: x.id };
        }) ?? [];

        const sizeOptions = this.getSizes(this.props).filter(x => x.isActive && x.packIds.some(y => y === linkedPOLine.packOption?.value)).map((x) => {
            return { label: `(${x.code}) - ${x.name}`, value: x.id };
        }) ?? [];

        const colourOptions = this.getColours(this.props).filter(x => x.isActive && x.commodityIds && x.commodityIds.some(y => y === linkedPOLine.commodityOption?.value)).map((x) => {
            return { label: `(${x.code}) - ${x.name}`, value: x.id };
        }) ?? [];

        const farmOptions = this.getFarms(this.props).filter(x => x.isActive).map((x) => {
            return { label: `(${x.code}) - ${x.name}`, value: x.id };
        }) ?? [];

        const orchardOptions = this.getOrchards(this.props).filter(x => x.isActive && x.commodityId === linkedPOLine.commodityOption?.value && x.farmId === linkedPOLine.farmOption?.value).map((x) => {
            return { label: `(${x.code}) - ${x.name}`, value: x.id };
        }) ?? [];

        return (
            <TableRow style={style} className={`${index % 2 === 0 ? (!!this.rowFailedValidation(linkedPOLine) ? 'bcTableRow2Warning' : 'bcTableRow2') : (!!this.rowFailedValidation(linkedPOLine) ? 'bcTableRow1Warning' : 'bcTableRow1')}`}>
                <TableCell className={'wfill jcc aic hfill fs14 p0 m0 fw500'}>
                    <div className={'w50 aic jcc bl1 hfill'}>
                        <CustomTooltip title={'Info'}>
                            <IconButton value={index} className={'w48 mnw48 cpd'} onClick={() => this.onStockInfoOpen(linkedPOLine)}>
                                <Icon>info</Icon>
                            </IconButton>
                        </CustomTooltip>
                    </div>
                    <div className={'w200 jcc aic bl1 hfill'}>{linkedPOLine.barcode}</div>
                    <div className={'w200 aic jcc bl1 hfill'}>
                        <CustomTooltip
                            title={`${!linkedPOLine.originalOrganizationOption ? 'Please select a source' : ''}`}
                            className={'w200 aic jcc hfill wfill'}
                            children={
                                <AutocompleteSelect
                                    className={'wfill'}
                                    name={'originalOrganization'}
                                    label={''}
                                    options={getOriginalOrganizationOptions}
                                    onChange={(e : CustomChangeEvent, selectedOrganization : IOptionType) => this.onStockOriginalOrganizationOptionChange(e, selectedOrganization, linkedDispatch.dispatchCode ?? '', linkedPOLine.guid)}
                                    value={linkedPOLine.originalOrganizationOption}
                                    disabled={!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props)}
                                    hasError={!linkedPOLine.originalOrganizationOption}
                                    errorMessage={'Required'}
                                />
                            }
                        />
                    </div>
                    <div className={'w200 aic jcc bl1 hfill'}>
                        <CustomTooltip
                            title={!this.getSite(matchingOPLine?.packhouseCode ?? '') ? `Code/short discription: ${matchingOPLine?.packhouseCode} could not be found`  : ''}
                            className={'w200 aic jcc hfill wfill'}
                            children={
                                <AutocompleteSelect
                                    className={'wfill'}
                                    name={'originalSite'}
                                    label={''}
                                    options={getOriginalSiteOptions}
                                    onChange={(e : CustomChangeEvent, selectedSite : IOptionType) => this.onStockOriginalSiteOptionChange(e, selectedSite, linkedDispatch.dispatchCode ?? '', linkedPOLine.guid)}
                                    value={linkedPOLine.originalSiteOption}
                                    disabled={!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props)}
                                    hasError={!linkedPOLine.originalSiteOption}
                                    errorMessage={'Required'}
                                />
                            }
                        />
                    </div>
                    <div className={'w200 aic jcc bl1 hfill'}>
                        <CustomTooltip
                            title={!this.getOrganization(matchingOPLine?.organization ?? '') ? `Code: ${matchingOPLine?.organization} could not be found`  : ''}
                            className={'w200 aic jcc hfill wfill'}
                            children={
                                <AutocompleteSelect
                                    className={'wfill'}
                                    name={'currentOrganization'}
                                    label={''}
                                    options={this.getOrganizationOptions(this.props)}
                                    onChange={(e : CustomChangeEvent, selectedOrganization : IOptionType) => this.onStockCurrentOrganizationOptionChange(e, selectedOrganization, linkedDispatch.dispatchCode ?? '', linkedPOLine.guid)}
                                    value={linkedPOLine.currentOrganizationOption}
                                    disabled={!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props)}
                                    hasError={!linkedPOLine.currentOrganizationOption}
                                    errorMessage={'Required'}
                                />
                            }
                        />
                    </div>
                    <div className={'w200 aic jcc bl1 hfill'}>
                        <CustomTooltip
                            title={!this.getSite(matchingOPLine?.locationCode ?? '') ? `Code/short discription: ${matchingOPLine?.locationCode} could not be found`  : ''}
                            className={'w200 aic jcc hfill wfill'}
                            children={
                                <AutocompleteSelect
                                    className={'wfill'}
                                    name={'currentSite'}
                                    label={''}
                                    options={getCurrentSiteOptions}
                                    onChange={(e : CustomChangeEvent, selectedSite : IOptionType) => this.onStockCurrentSiteOptionChange(e, selectedSite, linkedDispatch.dispatchCode ?? '', linkedPOLine.guid)}
                                    value={linkedPOLine.currentSiteOption}
                                    disabled={!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props)}
                                    hasError={!linkedPOLine.currentSiteOption}
                                    errorMessage={'Required'}
                                />
                            }
                        />
                    </div>
                    <div className={'w200 aic jcc bl1 hfill'}>
                        <CustomTooltip
                            title={!this.getPalletBaseType(matchingOPLine?.palletBaseType ?? '') ? `Code: ${matchingOPLine?.palletBaseType} could not be found`  : ''}
                            className={'w200 aic jcc hfill wfill'}
                            children={
                                <AutocompleteSelect
                                    className={'wfill'}
                                    name={'palletBaseType'}
                                    label={''}
                                    options={this.getPalletbaseTypeOptions(this.props)}
                                    onChange={(e : CustomChangeEvent, selectedPalletBaseType : IOptionType) => this.onStockPalletBaseTypeOptionChange(e, selectedPalletBaseType, linkedDispatch.dispatchCode ?? '', linkedPOLine.guid)}
                                    value={linkedPOLine.palletBaseTypeOption}
                                    disabled={!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props)}
                                    hasError={!linkedPOLine.palletBaseTypeOption}
                                    errorMessage={'Required'}
                                />
                            }
                        />
                    </div>
                    <div className={'w200 aic jcc bl1 hfill'}>
                        <CustomTooltip
                            title={!this.getRegion(matchingOPLine?.targetRegion ?? '') ? `Code: ${matchingOPLine?.targetRegion} could not be found`  : ''}
                            className={'w200 aic jcc hfill wfill'}
                            children={
                                !this.hasAdminEditRight(this.props)
                                    ?
                                    <TextField
                                        fullWidth
                                        inputProps={{ style: { textAlign: 'center' } }}
                                        className={'mr5 flx1'}
                                        variant='standard'
                                        label={''}
                                        name={'region'}
                                        value={matchingOPLine?.targetRegion}
                                        disabled={true}
                                        error={!this.getRegion(matchingOPLine?.targetRegion ?? '')}
                                    />
                                    :
                                    <AutocompleteSelect
                                        className={'wfill'}
                                        name={'region'}
                                        label={''}
                                        options={this.getRegionOptions(this.props)}
                                        onChange={(e : CustomChangeEvent, selectedRegion : IOptionType) => this.onStockRegionOptionChange(e, selectedRegion, linkedDispatch.dispatchCode ?? '', linkedPOLine.guid)}
                                        value={linkedPOLine.regionOption}
                                        hasError={!linkedPOLine.regionOption}
                                        errorMessage={'Required'}
                                    />
                            }
                        />
                    </div>
                    <div className={'w200 aic jcc bl1 hfill'}>
                        <CustomTooltip
                            title={!this.getCountry(matchingOPLine?.targetCountry ?? '') ? `Code: ${matchingOPLine?.targetCountry} could not be found`  : ''}
                            className={'w200 aic jcc hfill wfill'}
                            children={
                                !this.hasAdminEditRight(this.props)
                                    ?
                                    <TextField
                                        fullWidth
                                        inputProps={{ style: { textAlign: 'center' } }}
                                        className={'mr5 flx1'}
                                        variant='standard'
                                        label={''}
                                        name={'country'}
                                        value={matchingOPLine?.targetCountry}
                                        disabled={true}
                                        error={!this.getCountry(matchingOPLine?.targetCountry ?? '')}
                                    />
                                    :
                                    <AutocompleteSelect
                                        className={'wfill'}
                                        name={'country'}
                                        label={''}
                                        options={countryOptions}
                                        onChange={(e : CustomChangeEvent, selectedCountry : IOptionType) => this.onStockCountryOptionChange(e, selectedCountry, linkedDispatch.dispatchCode ?? '', linkedPOLine.guid)}
                                        value={linkedPOLine.countryOption}
                                        hasError={!linkedPOLine.countryOption}
                                        errorMessage={'Required'}
                                    />
                            }
                        />
                    </div>
                    <div className={'w200 aic jcc bl1 hfill'}>
                        <CustomTooltip
                            title={!this.getMarket(matchingOPLine?.targetMarket ?? '') ? `Code: ${matchingOPLine?.targetMarket} could not be found`  : ''}
                            className={'w200 aic jcc hfill wfill'}
                            children={
                                !this.hasAdminEditRight(this.props)
                                    ?
                                    <TextField
                                        fullWidth
                                        inputProps={{ style: { textAlign: 'center' } }}
                                        className={'mr5 flx1'}
                                        variant='standard'
                                        label={''}
                                        name={'market'}
                                        value={matchingOPLine?.targetMarket}
                                        disabled={true}
                                        error={!this.getMarket(matchingOPLine?.targetMarket ?? '')}
                                    />
                                    :
                                    <AutocompleteSelect
                                        className={'wfill'}
                                        name={'market'}
                                        label={''}
                                        options={marketOptions}
                                        onChange={(e : CustomChangeEvent, selectedMarket : IOptionType) => this.onStockMarketOptionChange(e, selectedMarket, linkedDispatch.dispatchCode ?? '', linkedPOLine.guid)}
                                        value={linkedPOLine.marketOption}
                                        hasError={!linkedPOLine.marketOption}
                                        errorMessage={'Required'}
                                    />
                            }
                        />
                    </div>
                    <div className={'w200 aic jcc bl1 hfill'}>
                        <CustomTooltip
                            title={!this.getBrand(matchingOPLine?.brand ?? '') ? `Code: ${matchingOPLine?.brand} could not be found`  : ''}
                            className={'w200 aic jcc hfill wfill'}
                            children={
                                <AutocompleteSelect
                                    className={'wfill'}
                                    name={'brand'}
                                    label={''}
                                    options={this.getBrandOptions(this.props)}
                                    onChange={(e : CustomChangeEvent, selectedBrand : IOptionType) => this.onStockBrandOptionChange(e, selectedBrand, linkedDispatch.dispatchCode ?? '', linkedPOLine.guid)}
                                    value={linkedPOLine.brandOption}
                                    disabled={!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props)}
                                    hasError={!linkedPOLine.brandOption}
                                    errorMessage={'Required'}
                                />
                            }
                        />
                    </div>
                    <div className={'w200 aic jcc bl1 hfill'}>
                        <CustomTooltip
                            title={matchingOPLine?.inventoryCode !== '' && !this.getInventory(matchingOPLine?.inventoryCode ?? '') ? `Code: ${matchingOPLine?.inventoryCode} could not be found`  : ''}
                            className={'w200 aic jcc hfill wfill'}
                            children={
                                <AutocompleteSelect
                                    className={'wfill'}
                                    name={'inventory'}
                                    label={''}
                                    options={this.getInventoryOptions(this.props)}
                                    onChange={(e : CustomChangeEvent, selectedInventory : IOptionType) => this.onStockInventoryOptionChange(e, selectedInventory, linkedDispatch.dispatchCode ?? '', linkedPOLine.guid)}
                                    value={linkedPOLine.inventoryOption}
                                    disabled={!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props)}
                                    hasError={matchingOPLine?.inventoryCode !== '' && !this.getInventory(matchingOPLine?.inventoryCode ?? '')}
                                    errorMessage={matchingOPLine?.inventoryCode !== '' && !this.getInventory(matchingOPLine?.inventoryCode ?? '') ? 'Missing'  : ''}
                                />
                            }
                        />
                    </div>
                    <div className={'w100 aic jcc bl1 hfill'}>{linkedPOLine.cartons !== '' ? Number(linkedPOLine.cartons).toFixed(3) : ''}</div>
                    <div className={'w100 aic jcc bl1 hfill'}>{Number(linkedPOLine.grossWeight).toFixed(3)}</div>
                    <div className={'w100 aic jcc bl1 hfill'}>{Number(linkedPOLine.estimatedGrossWeight).toFixed(3)}</div>
                    <div className={'w100 aic jcc bl1 hfill'}>{Number(linkedPOLine.estimatedNettWeight).toFixed(3)}</div>
                    <div className={'w100 aic jcc bl1 hfill'}>{booleanToYesNo(linkedPOLine.hasMixedLoad)}</div>
                    <div className={'w200 aic jcc bl1 hfill'}>
                        <CustomTooltip
                            title={!this.getCommodity(matchingOPLine) ? `Code: ${matchingOPLine?.commodity} could not be found`  : ''}
                            className={'w200 aic jcc hfill wfill'}
                            children={
                                <AutocompleteSelect
                                    className={'wfill'}
                                    name={'commodity'}
                                    label={''}
                                    options={this.getCommodityOptions(this.props)}
                                    onChange={(e : CustomChangeEvent, selectedCommodity : IOptionType) => this.onStockCommodityOptionChange(e, selectedCommodity, linkedDispatch.dispatchCode ?? '', linkedPOLine.guid)}
                                    value={linkedPOLine.commodityOption}
                                    disabled={!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props)}
                                    hasError={!linkedPOLine.commodityOption}
                                    errorMessage={'Required'}
                                />
                            }
                        />
                    </div>
                    <div className={'w200 aic jcc bl1 hfill'}>
                        <CustomTooltip
                            title={!this.getVariety(matchingOPLine) ? `Code: ${matchingOPLine?.variety} could not be found`  : ''}
                            className={'w200 aic jcc hfill wfill'}
                            children={
                                <AutocompleteSelect
                                    className={'wfill'}
                                    name={'variety'}
                                    label={''}
                                    options={varietyOptions}
                                    onChange={(e : CustomChangeEvent, selectedVariety : IOptionType) => this.onStockVarietyOptionChange(e, selectedVariety, linkedDispatch.dispatchCode ?? '', linkedPOLine.guid)}
                                    value={linkedPOLine.varietyOption}
                                    disabled={!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props)}
                                    hasError={!linkedPOLine.varietyOption}
                                    errorMessage={'Required'}
                                />
                            }
                        />
                    </div>
                    <div className={'w200 aic jcc bl1 hfill'}>
                        <CustomTooltip
                            title={!this.getFarm(matchingOPLine) ? `Code: ${matchingOPLine?.farm} could not be found`  : ''}
                            className={'w200 aic jcc hfill wfill'}
                            children={
                                !this.hasAdminEditRight(this.props)
                                    ?
                                    <TextField
                                        fullWidth
                                        inputProps={{ style: { textAlign: 'center' } }}
                                        className={'mr5 flx1'}
                                        variant='standard'
                                        label={''}
                                        name={'farm'}
                                        value={matchingOPLine?.farm}
                                        disabled={true}
                                        error={!this.getFarm(matchingOPLine)}
                                    />
                                    :
                                    <AutocompleteSelect
                                        className={'wfill'}
                                        name={'farm'}
                                        label={''}
                                        options={farmOptions}
                                        onChange={(e : CustomChangeEvent, selectedFarm : IOptionType) => this.onStockFarmOptionChange(e, selectedFarm, linkedDispatch.dispatchCode ?? '', linkedPOLine.guid)}
                                        value={linkedPOLine.farmOption}
                                        hasError={!linkedPOLine.farmOption}
                                        errorMessage={'Required'}
                                    />
                            }
                        />
                    </div>
                    <div className={'w200 aic jcc bl1 hfill'}>
                        <CustomTooltip
                            title={!this.getPack(matchingOPLine) ? `Code: ${matchingOPLine?.pack} could not be found`  : ''}
                            className={'w200 aic jcc hfill wfill'}
                            children={
                                <AutocompleteSelect
                                    className={'wfill'}
                                    name={'pack'}
                                    label={''}
                                    options={packOptions}
                                    onChange={(e : CustomChangeEvent, selectedPack : IOptionType) => this.onStockPackOptionChange(e, selectedPack, linkedDispatch.dispatchCode ?? '', linkedPOLine.guid)}
                                    value={linkedPOLine.packOption}
                                    disabled={!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props)}
                                    hasError={!linkedPOLine.packOption}
                                    errorMessage={'Required'}
                                />
                            }
                        />
                    </div>
                    <div className={'w200 aic jcc bl1 hfill'}>
                        <CustomTooltip
                            title={!this.getSize(matchingOPLine) ? `Code: ${matchingOPLine?.sizeCount} could not be found`  : ''}
                            className={'w200 aic jcc hfill wfill'}
                            children={
                                <AutocompleteSelect
                                    className={'wfill'}
                                    name={'size'}
                                    label={''}
                                    options={sizeOptions}
                                    onChange={(e : CustomChangeEvent, selectedSize : IOptionType) => this.onStockSizeOptionChange(e, selectedSize, linkedDispatch.dispatchCode ?? '', linkedPOLine.guid)}
                                    value={linkedPOLine.sizeOption}
                                    disabled={!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props)}
                                    hasError={!linkedPOLine.sizeOption}
                                    errorMessage={'Required'}
                                />
                            }
                        />
                    </div>
                    <div className={'w200 aic jcc bl1 hfill'}>
                        <CustomTooltip
                            title={!this.getGrade(matchingOPLine) ? `Code: ${matchingOPLine?.grade} could not be found`  : ''}
                            className={'w200 aic jcc hfill wfill'}
                            children={
                                <AutocompleteSelect
                                    className={'wfill'}
                                    name={'grade'}
                                    label={''}
                                    options={gradeOptions}
                                    onChange={(e : CustomChangeEvent, selectedGrade : IOptionType) => this.onStockGradeOptionChange(e, selectedGrade, linkedDispatch.dispatchCode ?? '', linkedPOLine.guid)}
                                    value={linkedPOLine.gradeOption}
                                    disabled={!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props)}
                                    hasError={!linkedPOLine.gradeOption}
                                    errorMessage={'Required'}
                                />
                            }
                        />
                    </div>
                    <div className={'w200 aic jcc bl1 hfill'}>
                        <CustomTooltip
                            title={!this.getColour(matchingOPLine) ? `Code: ${matchingOPLine?.varietyGroup} could not be found`  : ''}
                            className={'w200 aic jcc hfill wfill'}
                            children={
                                <AutocompleteSelect
                                    className={'wfill'}
                                    name={'colour'}
                                    label={''}
                                    options={colourOptions}
                                    onChange={(e : CustomChangeEvent, selectedColour : IOptionType) => this.onStockColourOptionChange(e, selectedColour, linkedDispatch.dispatchCode ?? '', linkedPOLine.guid)}
                                    value={linkedPOLine.colourOption}
                                    disabled={!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props)}
                                    hasError={!linkedPOLine.colourOption}
                                    errorMessage={'Required'}
                                />
                            }
                        />
                    </div>
                    <div className={'w200 aic jcc bl1 hfill'}>
                        <CustomTooltip
                            title={(matchingOPLine.upn !== '' || matchingOPLine.inspector !== '') && !this.getOrchard(matchingOPLine) ? `Code/compliance code: ${matchingOPLine?.orchard} could not be found` : ''}
                            className={'w200 aic jcc hfill wfill'}
                            children={
                                !this.hasAdminEditRight(this.props)
                                    ?
                                    <TextField
                                        fullWidth
                                        inputProps={{ style: { textAlign: 'center' } }}
                                        className={'mr5 flx1'}
                                        variant='standard'
                                        label={''}
                                        name={'orchard'}
                                        value={matchingOPLine?.orchard}
                                        disabled={true}
                                        error={(matchingOPLine.upn !== '' || matchingOPLine.inspector !== '') && !this.getOrchard(matchingOPLine)}
                                    />
                                    :
                                    <AutocompleteSelect
                                        className={'wfill'}
                                        name={'orchard'}
                                        label={''}
                                        options={orchardOptions}
                                        onChange={(e : CustomChangeEvent, selectedOrchard : IOptionType) => this.onStockOrchardOptionChange(e, selectedOrchard, linkedDispatch.dispatchCode ?? '', linkedPOLine.guid)}
                                        value={linkedPOLine.orchardOption}
                                        hasError={(matchingOPLine.upn !== '' || matchingOPLine.inspector !== '') && !linkedPOLine.orchardOption}
                                        errorMessage={'Required'}
                                    />
                            }
                        />
                    </div>
                </TableCell>
            </TableRow>
        );
    });

    private renderDispatchData = createSelector(
        [this.getCreatedDispatches, this.getMassColumnChangeSelectedOptions, this.getPOFileContentData],
        (createdDispatches : Array<IImportedPOFileDispatch>, massColumnChangeSelectedOptions : Array<IOCLineMassSelectOptions>, poFileContentData ?: IPOFileContentData) => {
            let result : Array<any> = [];

            createdDispatches.forEach((dispatch) => {
                const mathingOCLine = poFileContentData?.ocLines.find(x => x.consNo === dispatch.dispatchCode);
                const massSelectedOptions = massColumnChangeSelectedOptions.find(x => x.dispatchCode === dispatch.dispatchCode);

                // Dispatch level drop down options
                const sourceSite = this.getSiteById(Number(dispatch.sourceSiteOption?.value) ?? 0);

                const sourceOrganizationOptions = this.getOrganizations(this.props).filter(x => x.isActive && (!sourceSite || sourceSite?.organizationIds.some(y => y === x.id))).map((org) => {
                    return { label: `(${org.code}) - ${org.name}`, value: org.id };
                });
                const getSourceSiteOptions = this.getSites(this.props).filter(x => x.isActive && (!dispatch.sourceOrganizationOption || x.organizationIds.some(orgId => orgId === dispatch.sourceOrganizationOption?.value))).map((x) => {
                    return { label: `(${x.code}) - ${x.shortDescription}`, value: x.id };
                }) ?? [];
                const getDestinationSiteOptions = this.getSites(this.props).filter(x => x.isActive && (!dispatch.targetOrganizationOption || x.organizationIds.some(orgId => orgId === dispatch.targetOrganizationOption?.value))).map((x) => {
                    return { label: `(${x.code}) - ${x.shortDescription}`, value: x.id };
                }) ?? [];

                // stock level select all drop down options
                const linkedOPLines = dispatch.linkedPOLines;
                const filteredOriginalSites = linkedOPLines.filter(x => !!x.originalSiteOption).map(x => this.getSiteById(Number(x.originalSiteOption?.value ?? 0)));
                const originalSites = lodash.chain(filteredOriginalSites).filter(x => !!x).uniqBy(x => x?.id).value();

                const originalOrganizationOptions = this.getOrganizations(this.props).filter(x => x.isActive && (originalSites.length < 1 || originalSites.some(site => site?.organizationIds.some(y => y === x.id)))).map((org) => {
                    return { label: `(${org.code}) - ${org.name}`, value: org.id };
                });

                const availableOriginalSiteSelectAllOptions = lodash.chain(linkedOPLines).filter(x => !!x.originalSiteOption).map(x => x.originalSiteOption as IOptionType).uniqBy(x => x?.value).value();
                const availableCurrentOrganizationSelectAllOptions = lodash.chain(linkedOPLines).filter(x => !!x.currentOrganizationOption).map(x => x.currentOrganizationOption as IOptionType).uniqBy(x => x?.value).value();
                const availableCurrentSiteSelectAllOptions = lodash.chain(linkedOPLines).filter(x => !!x.currentSiteOption).map(x => x.currentSiteOption as IOptionType).uniqBy(x => x?.value).value();
                const availableRegionSelectAllOptions = lodash.chain(linkedOPLines).filter(x => !!x.regionOption).map(x => x.regionOption as IOptionType).uniqBy(x => x?.value).value();
                const availableCountrySelectAllOptions = lodash.chain(linkedOPLines).filter(x => !!x.countryOption).map(x => x.countryOption as IOptionType).uniqBy(x => x?.value).value();
                const availableMarketSelectAllOptions = lodash.chain(linkedOPLines).filter(x => !!x.marketOption).map(x => x.marketOption as IOptionType).uniqBy(x => x?.value).value();
                const availableBrandSelectAllOptions = lodash.chain(linkedOPLines).filter(x => !!x.brandOption).map(x => x.brandOption as IOptionType).uniqBy(x => x?.value).value();
                const availablePalletBaseTypeSelectAllOptions = lodash.chain(linkedOPLines).filter(x => !!x.palletBaseTypeOption).map(x => x.palletBaseTypeOption as IOptionType).uniqBy(x => x?.value).value();
                const availableInventorySelectAllOptions = lodash.chain(linkedOPLines).filter(x => !!x.inventoryOption).map(x => x.inventoryOption as IOptionType).uniqBy(x => x?.value).value();
                const availableFarmSelectAllOptions = lodash.chain(linkedOPLines).filter(x => !!x.farmOption).map(x => x.farmOption as IOptionType).uniqBy(x => x?.value).value();
                const availableCommoditySelectAllOptions = lodash.chain(linkedOPLines).filter(x => !!x.commodityOption).map(x => x.commodityOption as IOptionType).uniqBy(x => x?.value).value();
                const availableVarietySelectAllOptions = lodash.chain(linkedOPLines).filter(x => !!x.varietyOption).map(x => x.varietyOption as IOptionType).uniqBy(x => x?.value).value();
                const availablePackSelectAllOptions = lodash.chain(linkedOPLines).filter(x => !!x.packOption).map(x => x.packOption as IOptionType).uniqBy(x => x?.value).value();
                const availableSizeSelectAllOptions = lodash.chain(linkedOPLines).filter(x => !!x.sizeOption).map(x => x.sizeOption as IOptionType).uniqBy(x => x?.value).value();
                const availableGradeSelectAllOptions = lodash.chain(linkedOPLines).filter(x => !!x.gradeOption).map(x => x.gradeOption as IOptionType).uniqBy(x => x?.value).value();
                const availableColourSelectAllOptions = lodash.chain(linkedOPLines).filter(x => !!x.colourOption).map(x => x.colourOption as IOptionType).uniqBy(x => x?.value).value();
                const availableOrchardSelectAllOptions = lodash.chain(linkedOPLines).filter(x => !!x.orchardOption).map(x => x.orchardOption as IOptionType).uniqBy(x => x?.value).value();

                const availablePacks = this.getPacks(this.props).filter(x => availablePackSelectAllOptions.some(y => y.value === x.id));

                const originalSiteOptions = this.getSites(this.props).filter(x => x.isActive
                    && (!massSelectedOptions?.selectedOriginalOrganization || x.organizationIds.some(y => y === massSelectedOptions.selectedOriginalOrganization?.value))
                    && (availableOriginalSiteSelectAllOptions.length < 1 || availableOriginalSiteSelectAllOptions.some(y => y.value === x.id)))
                    .map((x) => {
                        return { label: `(${x.code}) - ${x.shortDescription}`, value: x.id };
                    });

                const currentOrganizationOptions = this.getOrganizations(this.props).filter(x => x.isActive
                    && (availableCurrentOrganizationSelectAllOptions.length < 1 || availableCurrentOrganizationSelectAllOptions.some(y => y.value === x.id)))
                    .map((x) => {
                        return { label: `(${x.code}) - ${x.name}`, value: x.id };
                    });
                const currentSiteOptions = this.getSites(this.props).filter(x => x.isActive
                    && (!massSelectedOptions?.selectedCurrentOrganization || x.organizationIds.some(y => y === massSelectedOptions.selectedCurrentOrganization?.value))
                    && (availableCurrentSiteSelectAllOptions.length < 1 || availableCurrentSiteSelectAllOptions.some(y => y.value === x.id)))
                    .map((x) => {
                        return { label: `(${x.code}) - ${x.shortDescription}`, value: x.id };
                    });

                const palletBaseTypeOptions = this.getPalletBaseTypes(this.props).filter(x => x.isActive && (availablePalletBaseTypeSelectAllOptions.length < 1 || availablePalletBaseTypeSelectAllOptions.some(y => y.value === x.id))).map((x) => {
                    return { label: `(${x.code}) - ${x.name}`, value: x.id };
                });
                const regionOptions = this.getRegions(this.props).filter(x => x.isActive && (availableRegionSelectAllOptions.length < 1 || availableRegionSelectAllOptions.some(y => y.value === x.id))).map((x) => {
                    return { label: `(${x.code}) - ${x.name}`, value: x.id };
                });
                const countryOptions = this.getCountries(this.props).filter(x => x.isActive
                    && (availableCountrySelectAllOptions.length < 1 || availableCountrySelectAllOptions.some(y => y.value === x.id))
                    && (!!massSelectedOptions?.selectedRegion ? x.regionId === massSelectedOptions.selectedRegion.value : availableRegionSelectAllOptions.every(y => y.value === x.regionId)))
                    .map((x) => {
                        return { label: `(${x.code}) - ${x.name}`, value: x.id };
                    });
                const marketOptions = this.getMarkets(this.props).filter(x => x.isActive
                    && (availableMarketSelectAllOptions.length < 1 || availableMarketSelectAllOptions.some(y => y.value === x.id))
                    && (!!massSelectedOptions?.selectedRegion ? x.regionId === massSelectedOptions.selectedRegion.value : availableRegionSelectAllOptions.every(y => y.value === x.regionId)))
                    .map((x) => {
                        return { label: `(${x.code}) - ${x.name}`, value: x.id };
                    });
                const brandOptions = this.getBrands(this.props).filter(x => x.isActive && (availableBrandSelectAllOptions.length < 1 || availableBrandSelectAllOptions.some(y => y.value === x.id))).map((x) => {
                    return { label: `(${x.code}) - ${x.description}`, value: x.id };
                });
                const inventoryOptions = this.getInventories(this.props).filter(x => x.isActive && (availableInventorySelectAllOptions.length < 1 || availableInventorySelectAllOptions.some(y => y.value === x.id))).map((x) => {
                    return { label: `(${x.code}) - ${x.description}`, value: x.id };
                });

                const farmOptions = this.getFarms(this.props).filter(x => x.isActive && (availableFarmSelectAllOptions.length < 1 || availableFarmSelectAllOptions.some(y => y.value === x.id))).map((x) => {
                    return { label: `(${x.code}) - ${x.name}`, value: x.id };
                });
                const commodityOptions = this.getCommodities(this.props).filter(x => x.isActive && (availableCommoditySelectAllOptions.length < 1 || availableCommoditySelectAllOptions.some(y => y.value === x.id))).map((x) => {
                    return { label: `(${x.code}) - ${x.name}`, value: x.id };
                });
                const varietyOptions = this.getVarieties(this.props).filter(x => x.isActive
                    && (availableVarietySelectAllOptions.length < 1 || availableVarietySelectAllOptions.some(y => y.value === x.id))
                    && (!!massSelectedOptions?.selectedCommodity ? x.commodityId === massSelectedOptions.selectedCommodity.value : availableCommoditySelectAllOptions.every(y => y.value === x.commodityId)))
                    .map((x) => {
                        return { label: `(${x.code}) - ${x.name}`, value: x.id };
                    });
                const packOptions = this.getPacks(this.props).filter(x => x.isActive
                    && (availablePackSelectAllOptions.length < 1 || availablePackSelectAllOptions.some(y => y.value === x.id))
                    && (!!massSelectedOptions?.selectedCommodity ? x.commodityId === massSelectedOptions.selectedCommodity.value : availableCommoditySelectAllOptions.every(y => y.value === x.commodityId)))
                    .map((x) => {
                        return { label: `(${x.code}) - ${x.description}`, value: x.id };
                    });
                const sizeOptions = this.getSizes(this.props).filter(x => x.isActive
                    && (availableSizeSelectAllOptions.length < 1 || availableSizeSelectAllOptions.some(y => y.value === x.id))
                    && (!!massSelectedOptions?.selectedPack
                        ? x.packIds.some(sizePackId => sizePackId === massSelectedOptions.selectedPack?.value)
                        : availablePacks.every(pack => pack.sizeIds.some(sizeId => sizeId === x.id))))
                    .map((x) => {
                        return { label: `(${x.code}) - ${x.name}`, value: x.id };
                    });

                const gradeOptions = this.getGrades(this.props).filter(x => x.isActive
                    && (availableGradeSelectAllOptions.length < 1 || availableGradeSelectAllOptions.some(y => y.value === x.id))
                    && (!!massSelectedOptions?.selectedCommodity
                        ? x.commodityIds.some(y => y === massSelectedOptions.selectedCommodity?.value)
                        : availableCommoditySelectAllOptions.every(y => x.commodityIds.some(z => z === y.value))))
                    .map((x) => {
                        return { label: `(${x.code}) - ${x.name}`, value: x.id };
                    });
                const colourOptions = this.getColours(this.props).filter(x => x.isActive
                    && (availableColourSelectAllOptions.length < 1 || availableColourSelectAllOptions.some(y => y.value === x.id))
                    && !!x.commodityIds
                    && (!!massSelectedOptions?.selectedCommodity
                        ? x.commodityIds.some(y => y === massSelectedOptions.selectedCommodity?.value)
                        : availableCommoditySelectAllOptions.every(y => x.commodityIds && x.commodityIds.some(z => z === y.value))))
                    .map((x) => {
                        return { label: `(${x.code}) - ${x.name}`, value: x.id };
                    });

                const orchardOptions = this.getOrchards(this.props).filter(x => x.isActive
                    && (availableOrchardSelectAllOptions.length < 1 || availableOrchardSelectAllOptions.some(y => y.value === x.id))
                    && (!!massSelectedOptions?.selectedCommodity ? x.commodityId === massSelectedOptions.selectedCommodity.value : availableCommoditySelectAllOptions.some(y => y.value === x.commodityId))
                    && (!!massSelectedOptions?.selectedFarm ? x.commodityId === massSelectedOptions.selectedFarm.value : availableFarmSelectAllOptions.some(y => y.value === x.farmId)))
                    .map((x) => {
                        return { label: `(${x.code}) - ${x.name}`, value: x.id };
                    });

                const renderItem = <Card id={`Card_key_${dispatch.dispatchCode}`} key={`Card_key_${dispatch.dispatchCode}`} className={'mnh420 h420 fdc wfill mt10 p10'} elevation={3}>
                    <div key={`key_${dispatch.dispatchCode}`} className={'fdc wfill'}>
                        <div className={'fdr wfill'}>
                            <TextField
                                fullWidth
                                className={'mr5 flx1 mt5'}
                                variant='standard'
                                label={'Dispatch Code'}
                                value={dispatch.dispatchCode}
                                disabled={true}
                            />
                            <TextField
                                fullWidth
                                className={'mr5 flx1 mt5'}
                                variant='standard'
                                label={'Load Date'}
                                value={dispatch.loadDate}
                                disabled={true}
                            />
                            <CustomTooltip
                                title={`${!dispatch.sourceOrganizationOption ? 'Please provide a source' : ''}`}
                                className={'fdr mr5 flx1'}
                                children={
                                    <AutocompleteSelect
                                        className={'flx1'}
                                        name={'sourceOrganization'}
                                        label={'Source Organization'}
                                        options={sourceOrganizationOptions}
                                        onChange={(e : CustomChangeEvent, selectedOrganization : any) => this.onDispatchSourceOrganizationOptionChange(e, selectedOrganization, mathingOCLine?.consNo ?? '')}
                                        value={dispatch.sourceOrganizationOption}
                                        disabled={!this.hasDispatchEditRight(this.props) && !this.hasAdminEditRight(this.props)}
                                        hasError={!dispatch.sourceOrganizationOption}
                                        errorMessage={'Required'}
                                    />
                                }/>
                            <CustomTooltip
                                title={!this.getSite(mathingOCLine?.locationCode ?? '') ? `Code/short discription: ${mathingOCLine?.locationCode} could not be found`  : ''}
                                className={'fdr mr5 flx1'}
                                children={
                                    <AutocompleteSelect
                                        className={'flx1'}
                                        name={'sourceSite'}
                                        label={'Source Site'}
                                        options={getSourceSiteOptions}
                                        onChange={(e : CustomChangeEvent, selectedSite : any) => this.onDispatchSourceSiteOptionChange(e, selectedSite, mathingOCLine?.consNo ?? '')}
                                        value={dispatch.sourceSiteOption}
                                        disabled={(!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props)) || !dispatch.sourceOrganizationOption}
                                        hasError={!dispatch.sourceSiteOption}
                                        errorMessage={'Required'}
                                    />
                                }/>
                            <CustomTooltip
                                title={!this.getOrganization(mathingOCLine?.organization ?? '') ? `Code: ${mathingOCLine?.organization} could not be found`  : ''}
                                className={'fdr mr5 flx1'}
                                children={
                                    <AutocompleteSelect
                                        className={'flx1 mb3'}
                                        name={'targetOrganization'}
                                        label={'Target Organization'}
                                        options={this.getOrganizationOptions(this.props)}
                                        onChange={(e : CustomChangeEvent, selectedOrganization : any) => this.onDispatchTargetOrganizationOptionChange(e, selectedOrganization, mathingOCLine?.consNo ?? '')}
                                        value={dispatch.targetOrganizationOption}
                                        disabled={!this.hasDispatchEditRight(this.props) && !this.hasAdminEditRight(this.props)}
                                        hasError={!dispatch.targetOrganizationOption}
                                        errorMessage={'Required'}
                                    />
                                }/>
                            <CustomTooltip
                                title={!this.getSite(mathingOCLine?.destinationCode ?? '') ? `Code/short discription: ${mathingOCLine?.destinationCode} could not be found`  : ''}
                                className={'fdr mr5 flx1'}
                                children={
                                    <AutocompleteSelect
                                        className={'flx1 mb3'}
                                        name={'destinationSite'}
                                        label={'Destination Site'}
                                        options={getDestinationSiteOptions}
                                        onChange={(e : CustomChangeEvent, selectedSite : any) => this.onDispatchDestinationSiteOptionChange(e, selectedSite, mathingOCLine?.consNo ?? '')}
                                        value={dispatch.destinationSiteOption}
                                        disabled={(!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props)) || !dispatch.targetOrganizationOption}
                                        hasError={!dispatch.destinationSiteOption}
                                        errorMessage={'Required'}
                                    />
                                }/>
                            <TextField
                                fullWidth
                                className={'mr5 flx1 mt5'}
                                variant='standard'
                                label={'Registration Number'}
                                value={dispatch.registrationNumber}
                                onChange={(e : React.ChangeEvent<HTMLInputElement>) => this.onDispatchRegistrationNumberChange(e, mathingOCLine?.consNo ?? '')}
                                disabled={!this.hasDispatchEditRight(this.props) && !this.hasAdminEditRight(this.props)}
                            />
                        </div>
                        <div className={'wfill oxs'}>
                            <Table className= {'PaperBorder'}>
                                <TableRow className={'fdr p0 m0 h48'}>
                                    {/* Table Headings */}
                                    <TableCell className={'flx1 fs14 p0 m0'}>
                                        <div className={'w50 aic jcc mr15'}/> {/* place holder */}
                                        <div className={'mnw200 w200 aic jcc'}></div> {/* place holder */}
                                        <div className={'mnw200 w200 aic jcc'}>
                                            <AutocompleteSelect
                                                className={'flx1'}
                                                name={'selectAllOriginalOrganization'}
                                                label={'Select All'}
                                                options={originalOrganizationOptions}
                                                onChange={(e : React.ChangeEvent<{}>, selectedOrganization : IOptionType) => this.onSelectAllStockOriginalOrganizationChange(e, selectedOrganization, dispatch.dispatchCode ?? '')}
                                                value={massSelectedOptions?.selectedOriginalOrganization}
                                                disabled={dispatch.linkedPOLines.length < 1 || (!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props))}
                                            />
                                        </div>
                                        <div className={'mnw200 w200 aic jcc'}>
                                            <AutocompleteSelect
                                                className={'flx1'}
                                                name={'selectAllOriginalSite'}
                                                label={'Select All'}
                                                options={originalSiteOptions}
                                                onChange={(e : React.ChangeEvent<{}>, selectedSite : IOptionType) => this.onSelectAllStockOriginalSiteChange(e, selectedSite, dispatch.dispatchCode ?? '')}
                                                value={massSelectedOptions?.selectedOriginalSite}
                                                disabled={dispatch.linkedPOLines.length < 1 || (!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props))}
                                            />
                                        </div>
                                        <div className={'mnw200 w200 aic jcc'}>
                                            <AutocompleteSelect
                                                className={'flx1'}
                                                name={'selectAllCurrentOrganization'}
                                                label={'Select All'}
                                                options={currentOrganizationOptions}
                                                onChange={(e : React.ChangeEvent<{}>, selectedOrganization : IOptionType) => this.onSelectAllStockCurrentOrganizationChange(e, selectedOrganization, dispatch.dispatchCode ?? '')}
                                                value={massSelectedOptions?.selectedBrand}
                                                disabled={dispatch.linkedPOLines.length < 1 || (!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props))}
                                            />
                                        </div>
                                        <div className={'mnw200 w200 aic jcc'}>
                                            <AutocompleteSelect
                                                className={'flx1'}
                                                name={'selectAllCurrentSite'}
                                                label={'Select All'}
                                                options={currentSiteOptions}
                                                onChange={(e : React.ChangeEvent<{}>, selectedSite : IOptionType) => this.onSelectAllStockCurrentSiteChange(e, selectedSite, dispatch.dispatchCode ?? '')}
                                                value={massSelectedOptions?.selectedBrand}
                                                disabled={dispatch.linkedPOLines.length < 1 || (!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props))}
                                            />
                                        </div>
                                        <div className={'mnw200 w200 aic jcc'}>
                                            <AutocompleteSelect
                                                className={'flx1'}
                                                name={'selectAllPalletBaseType'}
                                                label={'Select All'}
                                                options={palletBaseTypeOptions}
                                                onChange={(e : React.ChangeEvent<{}>, selectedPalletBaseType : IOptionType) => this.onSelectAllPalletBaseTypeChange(e, selectedPalletBaseType, dispatch.dispatchCode ?? '')}
                                                value={massSelectedOptions?.selectedBrand}
                                                disabled={dispatch.linkedPOLines.length < 1 || (!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props))}
                                            />
                                        </div>
                                        <div className={'mnw200 w200 aic jcc'}>
                                            <AutocompleteSelect
                                                className={!this.hasAdminEditRight(this.props) ? 'dn' : 'flx1'}
                                                name={'selectAllRegion'}
                                                label={'Select All'}
                                                options={regionOptions}
                                                onChange={(e : React.ChangeEvent<{}>, selectedRegion : IOptionType) => this.onSelectAllRegionChange(e, selectedRegion, dispatch.dispatchCode ?? '')}
                                                value={massSelectedOptions?.selectedRegion}
                                                disabled={dispatch.linkedPOLines.length < 1}
                                            />
                                        </div>
                                        <div className={'mnw200 w200 aic jcc'}>
                                            <AutocompleteSelect
                                                className={!this.hasAdminEditRight(this.props) ? 'dn' : 'flx1'}
                                                name={'selectAllCountry'}
                                                label={'Select All'}
                                                options={countryOptions}
                                                onChange={(e : React.ChangeEvent<{}>, selectedCountry : IOptionType) => this.onSelectAllCountryChange(e, selectedCountry, dispatch.dispatchCode ?? '')}
                                                value={massSelectedOptions?.selectedCountry}
                                                disabled={dispatch.linkedPOLines.length < 1}
                                            />
                                        </div>
                                        <div className={'mnw200 w200 aic jcc'}>
                                            <AutocompleteSelect
                                                className={!this.hasAdminEditRight(this.props) ? 'dn' : 'flx1'}
                                                name={'selectAllMarket'}
                                                label={'Select All'}
                                                options={marketOptions}
                                                onChange={(e : React.ChangeEvent<{}>, selectedMarket : IOptionType) => this.onSelectAllMarketChange(e, selectedMarket, dispatch.dispatchCode ?? '')}
                                                value={massSelectedOptions?.selectedMarket}
                                                disabled={dispatch.linkedPOLines.length < 1}
                                            />
                                        </div>
                                        <div className={'mnw200 w200 aic jcc'}>
                                            <AutocompleteSelect
                                                className={'flx1'}
                                                name={'selectAllBrand'}
                                                label={'Select All'}
                                                options={brandOptions}
                                                onChange={(e : React.ChangeEvent<{}>, selectedBatch : IOptionType) => this.onSelectAllBrandChange(e, selectedBatch, dispatch.dispatchCode ?? '')}
                                                value={massSelectedOptions?.selectedBrand}
                                                disabled={dispatch.linkedPOLines.length < 1 || (!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props))}
                                            />
                                        </div>
                                        <div className={'mnw200 w200 aic jcc'}>
                                            <AutocompleteSelect
                                                className={'flx1'}
                                                name={'selectAllInventory'}
                                                label={'Select All'}
                                                options={inventoryOptions}
                                                onChange={(e : React.ChangeEvent<{}>, selectedInventory : IOptionType) => this.onSelectAllInventoryChange(e, selectedInventory, dispatch.dispatchCode ?? '')}
                                                value={massSelectedOptions?.selectedInventory}
                                                disabled={dispatch.linkedPOLines.length < 1 || (!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props))}
                                            />
                                        </div>
                                        <div className={'w100 aic jcc'}></div> {/* place holder */}
                                        <div className={'w100 aic jcc'}></div> {/* place holder */}
                                        <div className={'w100 aic jcc'}></div> {/* place holder */}
                                        <div className={'w100 aic jcc'}></div> {/* place holder */}
                                        <div className={'w100 aic jcc'}></div> {/* place holder */}
                                        <div className={'mnw200 w200 aic jcc'}>
                                            <AutocompleteSelect
                                                className={'flx1'}
                                                name={'selectAllCommodity'}
                                                label={'Select All'}
                                                options={commodityOptions}
                                                onChange={(e : React.ChangeEvent<{}>, selectedCommodity : IOptionType) => this.onSelectAllCommodityChange(e, selectedCommodity, dispatch.dispatchCode ?? '')}
                                                value={massSelectedOptions?.selectedCommodity}
                                                disabled={dispatch.linkedPOLines.length < 1 || (!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props))}
                                            />
                                        </div>
                                        <div className={'mnw200 w200 aic jcc'}>
                                            <AutocompleteSelect
                                                className={'flx1'}
                                                name={'selectAllVariety'}
                                                label={'Select All'}
                                                options={varietyOptions}
                                                onChange={(e : React.ChangeEvent<{}>, selectedVariety : IOptionType) => this.onSelectAllVarietyChange(e, selectedVariety, dispatch.dispatchCode ?? '')}
                                                value={massSelectedOptions?.selectedVariety}
                                                disabled={dispatch.linkedPOLines.length < 1 || (!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props))}
                                            />
                                        </div>
                                        <div className={'mnw200 w200 aic jcc'}>
                                            <AutocompleteSelect
                                                className={!this.hasAdminEditRight(this.props) ? 'dn' : 'flx1'}
                                                name={'selectAllFarm'}
                                                label={'Select All'}
                                                options={farmOptions}
                                                onChange={(e : React.ChangeEvent<{}>, selectedFarm : IOptionType) => this.onSelectAllFarmChange(e, selectedFarm, dispatch.dispatchCode ?? '')}
                                                value={massSelectedOptions?.selectedFarm}
                                                disabled={dispatch.linkedPOLines.length < 1}
                                            />
                                        </div>
                                        <div className={'mnw200 w200 aic jcc'}>
                                            <AutocompleteSelect
                                                className={'flx1'}
                                                name={'selectAllPack'}
                                                label={'Select All'}
                                                options={packOptions}
                                                onChange={(e : React.ChangeEvent<{}>, selectedPack : IOptionType) => this.onSelectAllPackChange(e, selectedPack, dispatch.dispatchCode ?? '')}
                                                value={massSelectedOptions?.selectedPack}
                                                disabled={dispatch.linkedPOLines.length < 1 || (!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props))}
                                            />
                                        </div>
                                        <div className={'mnw200 w200 aic jcc'}>
                                            <AutocompleteSelect
                                                className={'flx1'}
                                                name={'selectAllSize'}
                                                label={'Select All'}
                                                options={sizeOptions}
                                                onChange={(e : React.ChangeEvent<{}>, selectedSize : IOptionType) => this.onSelectAllSizeChange(e, selectedSize, dispatch.dispatchCode ?? '')}
                                                value={massSelectedOptions?.selectedSize}
                                                disabled={dispatch.linkedPOLines.length < 1 || (!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props))}
                                            />
                                        </div>
                                        <div className={'mnw200 w200 aic jcc'}>
                                            <AutocompleteSelect
                                                className={'flx1'}
                                                name={'selectAllGrade'}
                                                label={'Select All'}
                                                options={gradeOptions}
                                                onChange={(e : React.ChangeEvent<{}>, selectedGrade : IOptionType) => this.onSelectAllGradeChange(e, selectedGrade, dispatch.dispatchCode ?? '')}
                                                value={massSelectedOptions?.selectedGrade}
                                                disabled={dispatch.linkedPOLines.length < 1 || (!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props))}
                                            />
                                        </div>
                                        <div className={'mnw200 w200 aic jcc'}>
                                            <AutocompleteSelect
                                                className={'flx1'}
                                                name={'selectAllColour'}
                                                label={'Select All'}
                                                options={colourOptions}
                                                onChange={(e : React.ChangeEvent<{}>, selectedColour : IOptionType) => this.onSelectAllColourChange(e, selectedColour, dispatch.dispatchCode ?? '')}
                                                value={massSelectedOptions?.selectedColour}
                                                disabled={dispatch.linkedPOLines.length < 1 || (!this.hasStockEditRight(this.props) && !this.hasAdminEditRight(this.props))}
                                            />
                                        </div>
                                        <div className={'mnw200 w200 aic jcc'}>
                                            <AutocompleteSelect
                                                className={!this.hasAdminEditRight(this.props) ? 'dn' : 'flx1'}
                                                name={'selectAllOrchard'}
                                                label={'Select All'}
                                                options={orchardOptions}
                                                onChange={(e : React.ChangeEvent<{}>, selectedOrchard : IOptionType) => this.onSelectAllOrchardChange(e, selectedOrchard, dispatch.dispatchCode ?? '')}
                                                value={massSelectedOptions?.selectedOrchard}
                                                disabled={dispatch.linkedPOLines.length < 1}
                                            />
                                        </div>
                                    </TableCell>
                                </TableRow>
                                {/* Table Headings */}
                                <TableRow className={'fdr bcTableHead'}>
                                    <TableCell className={'flx3 jcc aic bcTableHead h50 cw fs14 p0 m0'}>
                                        <div className={'w50 aic jcc'}/>
                                        <div className={'w200 aic jcc'}>Barcode</div>
                                        <div className={'w200 aic jcc'}>Original Organization</div>
                                        <div className={'w200 aic jcc'}>Original Site</div>
                                        <div className={'w200 aic jcc'}>Current Organization</div>
                                        <div className={'w200 aic jcc'}>Current Site</div>
                                        <div className={'w200 aic jcc'}>Pallet Base Type</div>
                                        <div className={'w200 aic jcc'}>Region</div>
                                        <div className={'w200 aic jcc'}>Country</div>
                                        <div className={'w200 aic jcc'}>Market</div>
                                        <div className={'w200 aic jcc'}>Brand</div>
                                        <div className={'w200 aic jcc'}>Inventory</div>
                                        <div className={'w100 aic jcc'}>Total Cartons</div>
                                        <div className={'w100 aic jcc'}>{'Gross Weight (kg)'}</div>
                                        <div className={'w100 aic jcc'}>{'Est. Gross Weight (kg)'}</div>
                                        <div className={'w100 aic jcc'}>{'Est. Nett Weight (kg)'}</div>
                                        <div className={'w100 aic jcc'}>Has Mixed Load?</div>
                                        <div className={'w200 aic jcc'}>Commodity</div>
                                        <div className={'w200 aic jcc'}>Variety</div>
                                        <div className={'w200 aic jcc'}>Farm</div>
                                        <div className={'w200 aic jcc'}>Pack</div>
                                        <div className={'w200 aic jcc'}>Size</div>
                                        <div className={'w200 aic jcc'}>Grade</div>
                                        <div className={'w200 aic jcc'}>Colour</div>
                                        <div className={'w200 aic jcc'}>Orchard</div>
                                    </TableCell>
                                </TableRow>
                                {/* body */}
                                <WindowList
                                    itemCount={dispatch.linkedPOLines.length}
                                    width={4400}
                                    height={200}
                                    itemSize={70}
                                    itemData={dispatch.linkedPOLines}
                                >
                                    {this.renderListElement}
                                </WindowList>
                            </Table>
                        </div>
                    </div>
                </Card>;

                result = addArrayElement(result, renderItem, 'end');
            });

            return result;
        },
    );

    public render() {
        return (
            <Screen isLoading={this.state.isLoading} isScrollable={false}>
                <div className={'wfill hfill aic fdc p20'}>
                    <div className={'wfill'}>
                        <FileSelector
                            accept={'.C88, .000'}
                            files={this.state.selectedFiles ? this.state.selectedFiles : []}
                            disabled={this.state.isLoading || this.state.selectedFiles.length > 0}
                            onFileSelected={this.handleFileInputChange}
                            onFileRemoved={this.onFileRemove}
                        />
                    </div>
                    <div className={'fdc oya wfill hfill'}>
                        {this.renderDispatchData(this.props, this.state)}
                    </div>
                    <div className={'flx1'}/>
                    <div className={'fdr jcfe pt10 pb10'}>
                        <CustomTooltip title={this.state.selectedFiles.length === 0 || this.disableSubmit(this.props, this.state) ? 'Check validation' : ''}>
                            <PillButton
                                disabled={this.state.selectedFiles.length === 0 || this.disableSubmit(this.props, this.state)}
                                className={'ml15 pl20 pr20 h35'}
                                text={'SUBMIT'}
                                color={'secondary'}
                                onClick={this.preSubmitValidation}
                            />
                        </CustomTooltip>
                    </div>
                </div>
                <PackmanDialog
                    title={'Warning'}
                    maxWidth={'lg'}
                    isInfo
                    isWarning
                    isLoading={this.state.isLoading}
                    isOpen={!!this.state.isWarningDialogOpen}
                    onClose={this.onOverrideDataNoClick}>
                    <div className={'fdc wfill hfill p10'}>
                        <Typography variant={'body1'}>This file was already imported.</Typography>
                        <Typography variant={'body1'}>By importing again you are going to override the previous imported data.</Typography>
                        <Typography className={'fwb'} variant={'body1'}>Are you sure?</Typography>
                        <div className={'fdr pt10 pb10 wfill jcfe'}>
                            <Button
                                className={'fwb h35'}
                                variant='text'
                                color='primary'
                                onClick={this.onOverrideDataNoClick}>
                                    Cancel
                            </Button>
                            <PillButton
                                disabled={this.state.isLoading}
                                className={'ml15 pl20 pr20 h35'}
                                text={'Submit'}
                                color={'secondary'}
                                onClick={this.onOverrideDataYesClick}
                            />
                        </div>
                    </div>
                </PackmanDialog>
                {this.state.selectedOPLineRow &&
                    <PackmanDialog
                        title={'OP Line Original Values'}
                        maxWidth={'xl'}
                        isInfo
                        isLoading={this.state.isLoading}
                        isOpen={!!this.state.selectedOPLineRow}
                        onClose={this.onStockInfoClose}>
                        <ImportPOFileInfoDialog selectedOPLine={this.state.selectedOPLineRow}/>
                    </PackmanDialog>
                }
            </Screen>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        organizations: state.masterData.organizations,
        sites: state.masterData.sites,
        farms: state.masterData.farms,
        markets: state.masterData.markets,
        brands: state.masterData.brands,
        regions: state.masterData.regions,
        countries: state.masterData.countries,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        packs: state.masterData.packs,
        sizes: state.masterData.sizes,
        grades: state.masterData.grades,
        colours: state.masterData.colours,
        orchards: state.masterData.orchards,
        inventories: state.masterData.inventories,
        palletBaseTypes: state.masterData.palletBaseTypes,
    };
};

export default connect(
    mapStateToProps,
)(ImportPOFileScreen);
