import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState, RootAction } from '../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Typography, Button, TextField, Table, TableRow, TableCell, IconButton, Icon } from '@mui/material';
import { formatDateTime, compareDate, addkg, addea, addArrayElement, formatDateTimeToDateOnly } from '../../services/appFunctionsService';
import CustomTable from '../../components/datagrid/CustomTable';
import { dataSetBatch, dataSetBatchSummaryPopupData, dataSetBatchView, dataSetLot } from '../../store/data/Functions';
import { generalShowSuccessSnackbar, generalShowErrorSnackbar } from '../../store/general/Functions';
import { CONSTANT_LOT_TYPE_GUIDS, DATE_FORMAT_DEFAULT } from '../../appConstants';
import { ILot } from '../../@types/model/lot/lot';
import { ILotType } from '../../@types/model/masterData/lotType/lotType';
import { ILotLine } from '../../@types/model/lot/lotLine';
import LotHttpService from '../../services/http/lot/lotHttpService';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IOrchard } from '../../@types/model/masterData/orchard/orchard';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { ISite } from '../../@types/model/masterData/site/site';
import { IFarm } from '../../@types/model/masterData/farm/farm';
import { ICommodityState } from '../../@types/model/masterData/commodityState/commodityState';
import PillButton from '../../components/input/PillButton';
import Screen from '../../components/Screen';
import PackmanDialog from '../../components/dialog/PackmanDialog';
import { createSelector } from 'reselect';
import ConfirmationPrompt from '../../components/dialog/ConfirmationPrompt';
import BatchHttpService from '../../services/http/batch/batchHttpService';
import { ILotLineView } from '../../@types/model/lot/LotLineView';
import { ILotLineEdit } from '../../@types/model/lot/lotLineEdit';
import { ListChildComponentProps, FixedSizeList as WindowList } from 'react-window';
import { IBatchView } from '../../@types/model/batch/batchView';
import { IDocumentProps } from '../../@types/other';
import { Font, PDFDownloadLink, StyleSheet, Document, Page, View, Image, Text } from '@react-pdf/renderer';
import FileSaver from 'file-saver';
import ReactDOM from 'react-dom';
import Code39 from '../../fonts/code39.ttf';
import moment from 'moment';
import { getState } from '../../store/Index';
import GetAppIcon from '@mui/icons-material/GetApp';
import { PackmanLink } from '../../components/link/packmanLink';
import { PackmanLabel } from '../../components/label/PackmanLabel';
import lodash from 'lodash';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { IStock } from '../../@types/model/stock/stock';
import { IIntake } from '../../@types/model/intake/intake';
import { syncMasterData } from '../../services/masterDataSyncService';
import CustomTooltip from '../../components/tooltip/tooltip';
import { BooleanFlag } from '../../components/label/BooleanFlag';
import materialTheme from '../../styles/materialTheme';
import { IReportFileFormValues, ReportFileFormValues } from '../../@types/model/reportFile/reportFileFormValues';
import { Formik, FormikHelpers } from 'formik';
import ReportFileForm from '../../components/reportFile/ReportFileForm';
import { IPrintServer } from '../../@types/model/masterData/printServer/printServer';
import { IReport } from '../../@types/model/masterData/report/report';
import PrintServerReportHttpService from '../../services/http/printServerReport/printServerReportHttpService';
import AutocompleteSelect from '../../components/input/AutoCompleteSelect';
import { IDepartment } from '../../@types/model/masterData/department/department';
import { IOptionType } from '../../@types/helper';

const styles = StyleSheet.create({
    section: {
        margin: 10,
        padding: 10,
        flex: 1,
    },
    page: {
        paddingTop: 25,
        paddingBottom: 65,
        marginBottom: 65,
    },
    pageNumber: {
        fontSize: 11,
        position: 'absolute',
        bottom: 35,
        right: 25,
    },
    pageMargin: {
        marginLeft: 25,
        marginRight: 25,
        flex: 1,
    },
    dn: { display: 'none' },
    fdc: { flexDirection: 'column' },
    fdr: { flexDirection: 'row' },
    fs6: { fontSize: 6 },
    fs8: { fontSize: 8 },
    fs9: { fontSize: 9 },
    fs10: { fontSize: 10 },
    fs11: { fontSize: 11 },
    fs12: { fontSize: 12 },
    fs13: { fontSize: 13 },
    fs14: { fontSize: 14 },
    fs15: { fontSize: 15 },
    bold: { fontWeight: 'bold' },
    pt2: { paddingTop: 2 },
    pt5: { paddingTop: 5 },
    pt10: { paddingTop: 10 },
    pt35: { paddingTop: 35 },
    pt50: { paddingTop: 50 },
    pb2: { paddingBottom: 2 },
    pb5: { paddingBottom: 5 },
    pb10: { paddingBottom: 10 },
    pb35: { paddingBottom: 35 },
    pl2: { paddingLeft: 2 },
    pl4: { paddingLeft: 4 },
    pl5: { paddingLeft: 5 },
    pl20: { paddingLeft: 20 },
    pl40: { paddingLeft: 40 },
    pl100: { paddingLeft: 100 },
    pl150: { paddingLeft: 150 },
    pl170: { paddingLeft: 170 },
    pl190: { paddingLeft: 190 },
    pl200: { paddingLeft: 200 },
    pr0: { paddingRight: 0 },
    pr2: { paddingRight: 2 },
    pr5: { paddingRight: 5 },
    pr10: { paddingRight: 10 },
    pr20: { paddingRight: 20 },
    pr80: { paddingRight: 80 },
    pr100: { paddingRight: 100 },
    mt5: { marginTop: 5 },
    mt10: { marginTop: 10 },
    mt15: { marginTop: 15 },
    mt20: { marginTop: 20 },
    mt35: { marginTop: 35 },
    mb5: { marginBottom: 5 },
    mb10: { marginBottom: 10 },
    mb35: { marginBottom: 35 },
    mb50: { marginBottom: 50 },
    mr0: { marginRight: 0 },
    mr10: { marginRight: 10 },
    flx1: { flex: 1 },
    flx2: { flex: 2 },
    blw1: { borderLeft: '1pt solid black' },
    brw1: { borderRight: '1pt solid black' },
    btw1: { borderTop: '1pt solid black' },
    bbw1: { borderBottom: '1pt solid black' },
    blw2: { borderLeft: '2pt solid black' },
    brw2: { borderRight: '2pt solid black' },
    btw2: { borderTop: '2pt solid black' },
    bbw2: { borderBottom: '2pt solid black' },
    jcc: { justifyContent: 'center' },
    ail: { alignItems: 'left' },
    aic: { alignItems: 'center' },
    aife: { alignItems: 'flex-end' },
    tac: { textAlign: 'center' },
    posa: { position: 'absolute' },
    w35: { width: 35 },
    w50: { width: 50 },
    w70: { width: 70 },
    w75: { width: 75 },
    w80: { width: 80 },
    w90: { width: 90 },
    w100: { width: 100 },
    w135: { width: 135 },
    w140: { width: 140 },
    w150: { width: 150 },
    w200: { width: 200 },
    w280: { width: 280 },
    h40: { height: 40 },
    h80: { height: 80 },
    h90: { height: 90 },
    t5: { top: 5 },
});

Font.register({
    family: 'code39',
    src: Code39,
});

const getOrganization = (id : number) => {
    const state = getState();
    const organization = state.masterData.organizations.find(x => x.id === id);
    return organization ? organization?.code + ' - ' + organization?.name : '';
};

const getSite = (id : number) => {
    const state = getState();
    const site = state.masterData.sites.find(x => x.id === id);
    return site ? site?.code + ' - ' + site?.description : '';
};

const getLotType = (id : number) => {
    const state = getState();
    const lotType = state.masterData.lotTypes.find(x => x.id === id);
    return lotType;
};

const getLotLineLotTypeGuid = (lineId : number, lots : Array<ILot>) => {
    const lot = lots.find(y => y.lotLines.some(z => z.id === lineId));
    const lotType = getLotType(lot?.lotTypeId ?? 0);
    return lotType ? lotType.guid : '';
};

const getStockPackedDocumentLines = (batches : Array<IBatchView>, stocks : Array<IStock>, packs : Array<IPack>, lotData : Array<ILot>) => {
    const lots = lotData.filter(x => x.isActive && batches.map(y => y.lotId).some(y =>y === x.id));
    const fromStockLotLines = lodash.flatten(lots?.map(x => x.lotLines)).filter(x => x.isActive && !!x.fromStockLineId);
    const toStocklotLines = lodash.flatten(lots?.map(x => x.lotLines)).filter(x => x.isActive && !!x.toStockLineId);

    const stockLines = lodash.flatMap(stocks, x => x.stockLines);

    const filteredFromStockLines = stockLines.filter(stockLine => stockLine.isActive && fromStockLotLines?.some(lotLine => stockLine.id === lotLine.fromStockLineId));
    const filteredToStockLines = stockLines.filter(stockLine => stockLine.isActive && toStocklotLines?.some(lotLine => stockLine.id === lotLine.toStockLineId));
    let packedStocks : any = [];

    const groupedToStockLines = lodash.groupBy(filteredToStockLines, x => x.packId);

    const intakeTotalNettWeight = getBatchLotIntakeTotalNettWeight(batches, lotData);

    lodash.forEach(groupedToStockLines, (packGrouping, key) => {
        const linkedLotLines = lodash.flatten(lots?.map(x => x.lotLines)).filter(lotline => lotline.isActive && packGrouping.some(stockLine => stockLine.id === lotline.toStockLineId));
        const stockLinesLinkedToPack = filteredFromStockLines.filter(x => x.packId === Number(key));
        const incomingStockLineLotLines = lodash.flatten(lots?.map(x => x.lotLines)).filter(lotline => lotline.isActive && stockLinesLinkedToPack.some(stockLine => stockLine.id === lotline.fromStockLineId));

        const pack = packs.find(x => x.id === Number(key));

        const totalIncomingNumberOfUnits = lodash.sumBy(incomingStockLineLotLines, x => x.amountOfUnits) ?? 0;
        const totalOutGoingNumberOfUnits = lodash.sumBy(linkedLotLines, x => x.amountOfUnits) ?? 0;

        const totalUnits = totalOutGoingNumberOfUnits - totalIncomingNumberOfUnits;

        const totalWeight = totalUnits * (pack?.nettWeight ?? 0); // Note: using pack NETT weight to calculate expected weight

        const percentage = intakeTotalNettWeight > 0 ? ((totalWeight / intakeTotalNettWeight) * 100) : 0;
        const row = (
            <View key={`pack_id_${key}`} style={[styles.fdr, styles.mt5]}>
                <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl5, styles.w100]}>{pack?.code ?? ''}</Text>
                <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl5, styles.w100]}>{pack?.description ?? ''}</Text>
                <View style={[styles.fdr, styles.w75]}/>
                <View style={[styles.fdr, styles.w70, styles.aic, styles.jcc, styles.blw1, styles.btw1, styles.brw1, styles.bbw1, styles.mr10]}>
                    <Text style={[styles.fs10, styles.pt2, styles.pb2]}>{totalUnits}</Text>
                </View>
                <View style={[styles.fdr, styles.w90, styles.aic, styles.jcc, styles.blw1, styles.btw1, styles.brw1, styles.bbw1, styles.mr10]}>
                    <Text style={[styles.fs10, styles.pt2, styles.pb2]}>{totalWeight.toFixed(3)}</Text>
                </View>
                <View style={[styles.fdr, styles.w90, styles.aic, styles.jcc, styles.blw1, styles.btw1, styles.brw1, styles.bbw1]}>
                    <Text style={[styles.fs10, styles.pt2, styles.pb2]}>{(Math.abs(percentage).toFixed(2) ?? 0) + '%'}</Text>
                </View>
            </View>
        );

        packedStocks = addArrayElement(packedStocks, row, 'end');
    });

    return packedStocks;
};

const getStockPackedTotalWeight = (batches : Array<IBatchView>, stocks : Array<IStock>, packs : Array<IPack>, lotData : Array<ILot>) => {
    const linkedStocks = stocks.filter(stock => stock.isActive && stock.stockLines.some(line => line.isActive && batches.some(z => z.id === line.batchId)));
    const lots = lotData.filter(x => x.isActive && batches.map(y => y.lotId).some(y =>y === x.id));

    const linkedStockLines = lodash.flatMap(linkedStocks, x => x.stockLines.filter(line => line.isActive && batches.some(z => z.id === line.batchId)));
    let packedStocksTotalWeight = 0;

    const groupedStockLines = lodash.groupBy(linkedStockLines, x => x.packId);

    lodash.forEach(groupedStockLines, (packGrouping, key) => {
        const linkedLotLines = lodash.flatten(lots?.map(x => x.lotLines)).filter(lotline => lotline.isActive && packGrouping.some(stockLine => stockLine.id === lotline.toStockLineId));
        const returnedLotLines = lodash.flatten(lots?.map(x => x.lotLines)).filter(lotline => lotline.isActive && packGrouping.some(stockLine => stockLine.id === lotline.fromStockLineId));

        const pack = packs.find(x => x.id === Number(key));

        const totalIncomingNumberOfUnits = lodash.sumBy(returnedLotLines, x => x.amountOfUnits) ?? 0;
        const totalOutGoingNumberOfUnits = lodash.sumBy(linkedLotLines, x => x.amountOfUnits) ?? 0;

        const totalUnits = totalOutGoingNumberOfUnits - totalIncomingNumberOfUnits;
        packedStocksTotalWeight += totalUnits * (pack?.nettWeight ?? 0);
    });

    return packedStocksTotalWeight;
};

const getReturnedStocksDocumentLines = (batches : Array<IBatchView>, lotData : Array<ILot>) => {
    const intakeTotalNettWeight = getBatchLotIntakeTotalNettWeight(batches, lotData);

    const lots = lotData.filter(x => x.isActive && batches.map(y => y.lotId).some(y => y === x.id));

    const returnedLines = lodash.chain(lots)
        .flatMap(x => x.lotLines)
        .filter(line => line.isActive && !line.isIncoming && !!line.toLotLineId && getLotLineLotTypeGuid(line.toLotLineId, lotData) === CONSTANT_LOT_TYPE_GUIDS[2]).value();

    const totalWeight = lodash.sumBy(returnedLines, x => x.nettWeight);

    const percentage = (totalWeight / intakeTotalNettWeight) * 100;

    const row = (
        <View style={[styles.fdr, styles.mt20]}>
            <Text style={[styles.fs10]}>{'Returned:'}</Text>
            <View style={[styles.fdr, styles.flx1]}/>
            <View style={[styles.fdr, styles.w90, styles.aic, styles.jcc, styles.blw1, styles.btw1, styles.brw1, styles.bbw1, styles.mr10]}>
                <Text style={[styles.fs10, styles.pt2, styles.pb2]}>{totalWeight.toFixed(3) ?? 0}</Text>
            </View>
            <View style={[styles.fdr, styles.w90, styles.aic, styles.jcc, styles.blw1, styles.btw1, styles.brw1, styles.bbw1]}>
                <Text style={[styles.fs10, styles.pt2, styles.pb2]}>{percentage.toFixed(2) + '%'}</Text>
            </View>
        </View>
    );

    return totalWeight > 0 && percentage > 0 ? row : undefined;
};

const getReturnedTotalWeight = (batches : Array<IBatchView>, lotData : Array<ILot>) => {
    const lots = lotData.filter(x => x.isActive && batches.map(y => y.lotId).some(y => y === x.id));

    const returnedLines = lodash.chain(lots)
        .flatMap(x => x.lotLines)
        .filter(line => line.isActive && !line.isIncoming && !!line.toLotLineId && getLotLineLotTypeGuid(line.toLotLineId, lotData) === CONSTANT_LOT_TYPE_GUIDS[2]).value();

    const totalWeight = lodash.sumBy(returnedLines, x => x.nettWeight);

    return totalWeight;
};

const getReturnedPercentage = (batches : Array<IBatchView>, lotData : Array<ILot>) => {
    const totalWeight = getReturnedTotalWeight(batches, lotData);
    const intakeTotalNettWeight = getBatchLotIntakeTotalNettWeight(batches, lotData);

    const percentage = (totalWeight / intakeTotalNettWeight) * 100;

    return intakeTotalNettWeight > 0 ? Math.abs(percentage) : 0;
};

const getStockPackedAndWasteTotalWeight = (batches : Array<IBatchView>, stocks : Array<IStock>, packs : Array<IPack>, lots : Array<ILot>) => {
    const packedStockTotalWeight = getStockPackedTotalWeight(batches, stocks, packs, lots);
    const returnedTotalWeight = getReturnedTotalWeight(batches, lots);
    const waste = calculateTotalByLotType(batches.map(x => x.lotId), CONSTANT_LOT_TYPE_GUIDS[1], 'nett', false, lots);

    return packedStockTotalWeight + waste + returnedTotalWeight;
};

const getBatchLotIntakes = (batches : Array<IBatchView>, intakes : Array<IIntake>, lotData : Array<ILot>) => {
    const lots = lotData.filter(x => x.isActive && batches.map(y => y.lotId).some(y =>y === x.id));
    const fromLotLineIds = lodash.flatten(lots?.map(x => x.lotLines)).filter(x => x.isActive && x.isIncoming && !!x.fromLotLineId).map(x => x.fromLotLineId) ?? [];
    const fromLots = lotData.filter(x => x.isActive && x.lotLines.some(line => line.isActive && fromLotLineIds?.some(fromLineId => fromLineId === line.id)));
    const fromLotLines = lodash.flatMap(fromLots, fromLot => fromLot.lotLines);
    const activeFromLotLines = fromLotLines.filter(x => x.isActive && fromLotLineIds?.some(fromLineId => fromLineId === x.id));

    const groupedFromLotLines = lodash.groupBy(activeFromLotLines, line => line.lotId);
    let rows : any = [];

    const intakeTotalNettWeight = getBatchLotIntakeTotalNettWeight(batches, lotData);

    lodash.forEach(groupedFromLotLines, (groupedLotLines, key) => {
        const fromLot = fromLots.find(x => x.id === Number(key));

        const fromLotIntake = intakes.find(intake => intake.isActive && intake.intakeLines.some(line => line.isActive && line.intakeLineLayers.some(layer => layer.isActive && layer.lotId === fromLot?.id)));

        const totalNumberOfUnits = lodash.sumBy(groupedLotLines, line => line.amountOfUnits);
        const totalWeight = lodash.sumBy(groupedLotLines, line => line.nettWeight);
        const percentage = intakeTotalNettWeight > 0 ? ((totalWeight / intakeTotalNettWeight) * 100): 0;

        const row = (
            <View key={`from_lot_id_${key}`} style={[styles.fdr, styles.mt5]}>
                <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl5, styles.w100]}>{formatDateTimeToDateOnly(fromLot?.createdOn ?? '') ?? ''}</Text>
                <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl5, styles.w100]}>{fromLot?.name ?? ''}</Text>
                <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl5, styles.w75]}>{fromLotIntake?.shipmentGuid ?? ''}</Text>
                <View style={[styles.fdr, styles.w70, styles.aic, styles.jcc, styles.blw1, styles.btw1, styles.brw1, styles.bbw1, styles.mr10]}>
                    <Text style={[styles.fs10, styles.pt2, styles.pb2]}>{totalNumberOfUnits}</Text>
                </View>
                <View style={[styles.fdr, styles.w90, styles.aic, styles.jcc, styles.blw1, styles.btw1, styles.brw1, styles.bbw1, styles.mr10]}>
                    <Text style={[styles.fs10, styles.pt2, styles.pb2]}>{totalWeight.toFixed(3)}</Text>
                </View>
                <View style={[styles.fdr, styles.w90, styles.aic, styles.jcc, styles.blw1, styles.btw1, styles.brw1, styles.bbw1]}>
                    <Text style={[styles.fs10, styles.pt2, styles.pb2]}>{(Math.abs(percentage).toFixed(2) ?? 0) + '%'}</Text>
                </View>
            </View>
        );

        rows = addArrayElement(rows, row, 'end');
    });

    return rows;
};

const getBatchLotIntakeTotal = (batches : Array<IBatchView>, lotData : Array<ILot>) => {
    const lots = lotData.filter(x => x.isActive && batches.map(y => y.lotId).some(y =>y === x.id));
    const fromLotLineIds = lodash.flatten(lots?.map(x => x.lotLines)).filter(x => x.isActive && x.isIncoming && !!x.fromLotLineId).map(x => x.fromLotLineId) ?? [];
    const fromLots = lotData.filter(x => x.isActive && x.lotLines.some(line => line.isActive && fromLotLineIds?.some(fromLineId => fromLineId === line.id)));
    const fromLotLines = lodash.flatMap(fromLots, fromLot => fromLot.lotLines);
    const activeFromLotLines = fromLotLines.filter(x => x.isActive && fromLotLineIds?.some(fromLineId => fromLineId === x.id));

    const groupedFromLotLines = lodash.groupBy(activeFromLotLines, line => line.lotId);
    let totalIntakeNumberOfUnits = lodash.chain(groupedFromLotLines).flatMap(grouping => grouping).sumBy(line => line.amountOfUnits).value();
    let totalIntakeNettWeight = lodash.chain(groupedFromLotLines).flatMap(grouping => grouping).sumBy(line => line.nettWeight).value();

    return (
        <View style={[styles.fdr, styles.mt20]}>
            <Text style={[styles.fs12, styles.pl5]}>{'Total:'}</Text>
            <View style={[styles.fdr, styles.flx1]}/>
            <View style={[styles.fdr, styles.w70, styles.aic, styles.jcc, styles.blw1, styles.btw1, styles.brw1, styles.bbw1, styles.mr10]}>
                <Text style={[styles.fs10, styles.pt2, styles.pb2]}>{totalIntakeNumberOfUnits}</Text>
            </View>
            <View style={[styles.fdr, styles.w90, styles.aic, styles.jcc, styles.blw1, styles.btw1, styles.brw1, styles.bbw1, styles.mr10]}>
                <Text style={[styles.fs10, styles.pt2, styles.pb2]}>{totalIntakeNettWeight.toFixed(3) ?? 0}</Text>
            </View>
            <View style={[styles.fdr, styles.w90, styles.aic, styles.jcc, styles.blw1, styles.btw1, styles.brw1, styles.bbw1]}>
                <Text style={[styles.fs10, styles.pt2, styles.pb2]}>{(totalIntakeNettWeight > 0 ? (totalIntakeNettWeight / totalIntakeNettWeight) * 100 : 0).toFixed(2) + '%'}</Text>
            </View>
        </View>
    );
};

const getBatchLotIntakeTotalNettWeight = (batches : Array<IBatchView>, lotData : Array<ILot>) => {
    const lots = lotData.filter(x => x.isActive && batches.map(y => y.lotId).some(y =>y === x.id));
    const fromLotLineIds = lodash.flatten(lots?.map(x => x.lotLines)).filter(x => x.isActive && x.isIncoming && !!x.fromLotLineId).map(x => x.fromLotLineId) ?? [];
    const fromLots = lotData.filter(x => x.isActive && x.lotLines.some(line => line.isActive && fromLotLineIds?.some(fromLineId => fromLineId === line.id)));
    const fromLotLines = lodash.flatMap(fromLots, fromLot => fromLot.lotLines);
    const activeFromLotLines = fromLotLines.filter(x => x.isActive && fromLotLineIds?.some(fromLineId => fromLineId === x.id));

    const groupedFromLotLines = lodash.groupBy(activeFromLotLines, line => line.lotId);
    let totalIntakeNettWeight = lodash.chain(groupedFromLotLines).flatMap(grouping => grouping).sumBy(line => line.nettWeight).value();

    return totalIntakeNettWeight;
};

const calculateTotal = (lotIds : Array<number>, type : 'gross' | 'nett' | 'unit', lotData : Array<ILot>, incoming ?: boolean) => {
    let total = 0;
    const lots = lotData.filter(x => lotIds.some(y => y === x.id));
    const lotLines = lodash.flatten(lots?.map(x => x.lotLines))?.filter(x => x.isActive);

    if (lotLines) {
        lotLines.forEach((x) => {
            if ((incoming === undefined && !x.isIncoming) || (incoming === x.isIncoming)) {
                switch (type) {
                    case 'gross':
                        total += x.grossWeight;
                        break;
                    case 'nett':
                        total += x.nettWeight;
                        break;
                    case 'unit':
                        total += x.amountOfUnits;
                        break;
                }
            }
        });
    }

    return total;
};

const calculateTotalByLotType = (lotIds : Array<number>, lotType : string, type : 'gross' | 'nett' | 'unit', incoming : boolean, lotData : Array<ILot>) => {
    let total = 0;
    const lots = lotData.filter(x => lotIds.some(y => y === x.id));
    const lotLines = lodash.flatten(lots?.map(x => x.lotLines))?.filter(x => x.isActive);

    if (lotLines) {
        lotLines.forEach((x) => {
            if (((incoming && !!x.fromLotLineId && getLotLineLotTypeGuid(x.fromLotLineId, lotData)) || (!incoming && !!x.toLotLineId && getLotLineLotTypeGuid(x.toLotLineId, lotData))) === lotType) {
                switch (type) {
                    case 'gross':
                        total += x.grossWeight;
                        break;
                    case 'nett':
                        total += x.nettWeight;
                        break;
                    case 'unit':
                        total += x.amountOfUnits;
                        break;
                }
            }
        });
    }

    return total;
};

const calculateVariance = (batches : Array<IBatchView>, stocks : Array<IStock>, packs : Array<IPack>, lots : Array<ILot>) => {
    const returnedTotalWeight = getReturnedTotalWeight(batches, lots); // will be 0 if there is no returned lines
    const variance = (calculateIntakeTotalWeight(batches[0], lots) - getStockPackedTotalWeight(batches, stocks, packs, lots) - calculateTotalByLotType(lodash.flatten(batches?.map(x => x.lotId)), CONSTANT_LOT_TYPE_GUIDS[1], 'nett', false, lots) - returnedTotalWeight) ?? 0;

    return variance;
};

const calculateIntakeTotalWeight = (batch : IBatchView, lotData : Array<ILot>) => {
    return calculateTotalByLotType([batch.lotId], CONSTANT_LOT_TYPE_GUIDS[2], 'nett', true, lotData) - calculateTotalByLotType([batch.lotId], CONSTANT_LOT_TYPE_GUIDS[2], 'nett', false, lotData);
};

const calculateIntakeTotalPercentage = (batch : IBatchView, lots : Array<ILot>) => {
    return calculateIntakeTotalWeight(batch, lots) > 0 ? (calculateIntakeTotalWeight(batch, lots) / calculateTotal([batch.lotId], 'nett', lots, true)) * 100 : 0;
};

const calculatePackedStockTotalPercentage = (batches : Array<IBatchView>, stocks : Array<IStock>, packs : Array<IPack>, lots : Array<ILot>) => {
    const packedStockTotalWeight = getStockPackedTotalWeight(batches, stocks, packs, lots);
    const intakeTotalNettWeight = calculateTotal(batches.map(x => x.lotId), 'nett', lots, true);

    const percentage = (packedStockTotalWeight / intakeTotalNettWeight) * 100;

    return intakeTotalNettWeight > 0 ? Math.abs(percentage) : 0;
};

const calculateWastePercentage = (batches : Array<IBatchView>, lots : Array<ILot>) => {
    const waste = calculateTotalByLotType(batches.map(x => x.lotId), CONSTANT_LOT_TYPE_GUIDS[1], 'nett', false, lots);
    const intakeTotalNettWeight = calculateTotal(batches.map(x => x.lotId), 'nett', lots, true);

    const percentage = (waste / intakeTotalNettWeight) * 100;

    return intakeTotalNettWeight > 0 ? Math.abs(percentage) : 0;
};

const calculatePackedStockAndWasteTotalPercentage = (batches : Array<IBatchView>, stocks : Array<IStock>, packs : Array<IPack>, lots : Array<ILot>) => {
    const packedStockTotalWeight = getStockPackedTotalWeight(batches, stocks, packs, lots);
    const waste = calculateTotalByLotType(batches.map(x => x.lotId), CONSTANT_LOT_TYPE_GUIDS[1], 'nett', false, lots);
    const returnedTotalWeight = getReturnedTotalWeight(batches, lots);
    const intakeTotalNettWeight = calculateTotal(batches.map(x => x.lotId), 'nett', lots, true);

    const totalWeight = packedStockTotalWeight + waste + returnedTotalWeight;

    const percentage = (totalWeight / intakeTotalNettWeight) * 100;

    return intakeTotalNettWeight > 0 ? Math.abs(percentage) : 0;
};

const calculateVariancePercentage = (batches : Array<IBatchView>, stocks : Array<IStock>, packs : Array<IPack>, lots : Array<ILot>) => {
    const variance = calculateVariance(batches, stocks, packs, lots);
    const intakeWeight = calculateTotal(batches.map(x => x.lotId), 'nett', lots, true);

    const percentage = (variance / intakeWeight) * 100;

    return intakeWeight > 0 ? Math.abs(percentage) : 0;
};

const createAndDownloadPDF = async (pdfContent : () => React.ReactElement<IDocumentProps>, filename : string, divId : string, callback : () => void) => {
    setTimeout(
        () => {
            const link = (
                <div id={ divId }>
                    <PDFDownloadLink document={ pdfContent() } fileName={ filename }>{
                        ({ blob, loading }) => {
                            if (!loading) {
                                setTimeout(
                                    () => {
                                        if (blob) {
                                            FileSaver.saveAs(blob, filename);
                                        } else {
                                            generalShowErrorSnackbar('Could not download file.');
                                        }
                                    },
                                    1);
                            }
                        }}</PDFDownloadLink>
                </div>
            );
            const elem = document.createElement('div');
            const doc = document.getElementById('root');
            if (doc) {
                doc.appendChild(elem);
                ReactDOM.render(link, elem);
                setTimeout(
                    () => {
                        elem.remove();
                        callback();
                    },
                    1);
            }
        },
        1);
};

export const generateBatchReportPdf = async (stocks : Array<IStock>, packs : Array<IPack>, batches : Array<IBatchView>, intakes : Array<IIntake>, lots : Array<ILot>, callback ?: () => void) => buildBatchReportPdf(batchReportPdf, batches.length > 1 ? `Multi Batch Report - ${moment.now()}.pdf` : `Batch - ${batches[0]?.batchCode} - Report.pdf`, batches, stocks, packs, intakes, lots, callback);

export const batchReportPdf = (batches : Array<IBatchView>, stocks : Array<IStock>, packs : Array<IPack>, intakes : Array<IIntake>, lots : Array<ILot>) => {
    return (
        <Document>
            <Page size='A4' style={styles.page}>
                <View style={styles.pageMargin}>
                    <View style={styles.fdr}>
                        <Image style={{ height: 42 }} source={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`}/>
                        <Text style={[styles.fs13, styles.bold, styles.mt15, styles.pl20]}>PACKMAN - BATCH REPORT</Text>
                        <View style={styles.flx1} />
                    </View>
                    <View style={[styles.fdr, styles.pb10]} fixed>
                        <View style={styles.flx1} />
                        <Text style={styles.fs8} fixed render={({ pageNumber, totalPages }) => (
                            `PAGE ${pageNumber} OF ${totalPages}`
                        )}> </Text>
                    </View>
                    <View style={[styles.fdr, styles.aic, styles.pt5, styles.pb5]}>
                        { batches.length === 1 ?
                            <Text style={styles.fs13}>{`BATCH NO: ${batches[0]?.batchCode}`}</Text>
                            :
                            <View style={[styles.fdc, styles.flx2]}>
                                <View style={[styles.fdr, styles.bbw2, styles.btw2]}>
                                    <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl5, styles.brw2, styles.blw2, styles.flx1]}>{'BATCHES'}</Text>
                                </View>
                                <View style={[styles.fdr, styles.bbw2]}>
                                    <Text style={[styles.fs8, styles.pt5, styles.pb5, styles.pl5, styles.brw2, styles.blw2, styles.flx1]}>{lodash.uniq(batches.map(batch => batch.batchCode)).map((x, i, arr) => `${x}${i < (arr.length - 1) ? ',\n' : ''}`) ?? ''}</Text>
                                </View>
                            </View>
                        }
                        <View style={styles.flx1} />
                        <Text style={[styles.fs8, styles.flx1]}>{`DATE: ${moment().local().format(DATE_FORMAT_DEFAULT)}`}</Text>
                    </View>
                    <View style={[styles.fdr, styles.bbw1]}/>
                    <View style={[styles.fdc]}>
                        <View style={[styles.fdr]}>
                            <Text style={[styles.fs11, styles.pt10, styles.w280, styles.pr20]}>{`ORGANIZATION: ${getOrganization(batches[0]?.organizationId)}`}</Text>
                            <Text style={[styles.fs11, styles.pt10, styles.w280, styles.pr20]}>{`SITE: ${getSite(batches[0]?.siteId)}`}</Text>
                        </View>
                        <View style={[styles.fdr]}>
                            <Text style={[styles.fs11, styles.pt10, styles.pb10, styles.w280]}>{`START DATE: ${formatDateTime(lodash.max(batches.map(x => x.startedOn))?.toString() ?? '')}`}</Text>
                            <Text style={[styles.fs11, styles.pt10, styles.pb10, styles.w280]}>{`END DATE: ${formatDateTime(lodash.min(batches.map(x => x.endedOn))?.toString() ?? '')}`}</Text>
                        </View>
                    </View>
                    <View style={[styles.fdr, styles.bbw2, styles.btw2]}>
                        <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl5, styles.brw2, styles.blw2, styles.flx1]}>{'COMMODITIES' }</Text>
                        <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl5, styles.brw2, styles.flx1]}>{'VARIETIES'}</Text>
                        <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl5, styles.brw2, styles.flx1]}>{'FARMS'}</Text>
                        <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl5, styles.brw2, styles.flx1]}>{'ORCHARDS'}</Text>
                    </View>
                    <View style={[styles.fdr, styles.bbw2]}>
                        <Text style={[styles.fs8, styles.pt5, styles.pb5, styles.pl5, styles.brw2, styles.blw2, styles.flx1]}>{lodash.uniq(lodash.flatten(batches.map(x => x.commodityCodes))).map((x, i, arr) => `${x}${i < (arr.length - 1) ? ',\n' : ''}`) ?? ''}</Text>
                        <Text style={[styles.fs8, styles.pt5, styles.pb5, styles.pl5, styles.brw2, styles.flx1]}>{lodash.uniq(lodash.flatten(batches.map(x => x.varietyCodes))).map((x, i, arr) => `${x}${i < (arr.length - 1) ? ',\n' : ''}`) ?? ''}</Text>
                        <Text style={[styles.fs8, styles.pt5, styles.pb5, styles.pl5, styles.brw2, styles.flx1]}>{lodash.uniq(lodash.flatten(batches.map(x => x.farmCodes))).map((x, i, arr) => `${x}${i < (arr.length - 1) ? ',\n' : ''}`) ?? ''}</Text>
                        <Text style={[styles.fs8, styles.pt5, styles.pb5, styles.pl5, styles.brw2, styles.flx1]}>{lodash.uniq(lodash.flatten(batches.map(x => x.orchardCodes))).map((x, i, arr) => `${x}${i < (arr.length - 1) ? ',\n' : ''}`) ?? ''}</Text>
                    </View>
                    <View style={[styles.fdr, styles.mt10]}>
                        <Text style={[styles.fs13]}>{'Intakes:'}</Text>
                    </View>
                    <View style={[styles.fdr, styles.mt5]}>
                        <Text style={[styles.fs12, styles.bold, styles.pl5, styles.pt5, styles.pb5, styles.w100]}>{'Intake Date:' }</Text>
                        <Text style={[styles.fs12, styles.bold, styles.pl5, styles.pt5, styles.pb5, styles.w100]}>{'Lot:'}</Text>
                        <Text style={[styles.fs12, styles.bold, styles.pl5, styles.pt5, styles.pb5, styles.w80]}>{'Ship Ref:'}</Text>
                        <View style={[styles.fdr, styles.w70, styles.aic, styles.jcc, styles.mr10]}>
                            <Text style={[styles.fs12, styles.bold, styles.pl5, styles.pt5, styles.pb5]}>{'Units:'}</Text>
                        </View>
                        <View style={[styles.fdr, styles.w90, styles.aic, styles.jcc, styles.mr10]}>
                            <Text style={[styles.fs12, styles.bold, styles.pl5, styles.pt5, styles.pb5]}>{'Kilograms:'}</Text>
                        </View>
                        <View style={[styles.fdr, styles.w90, styles.aic, styles.jcc]}>
                            <Text style={[styles.fs12, styles.bold, styles.pl5, styles.pt5, styles.pb5]}>{'Percentage:'}</Text>
                        </View>
                    </View>
                    <View style={[styles.fdc]}>
                        {getBatchLotIntakes(batches, intakes, lots)}
                    </View>
                    {getBatchLotIntakeTotal(batches, lots)}
                    <View style={[styles.fdr, styles.bbw2, styles.mt10]}/>
                    <View style={[styles.fdr, styles.mt10]}>
                        <Text style={[styles.fs13]}>{'Stock Packed:'}</Text>
                    </View>
                    <View style={[styles.fdr, styles.mt5]}>
                        <Text style={[styles.fs12, styles.bold, styles.pl5, styles.pt5, styles.pb5, styles.w100]}>{'Code:' }</Text>
                        <Text style={[styles.fs12, styles.bold, styles.pl5, styles.pt5, styles.pb5, styles.w100]}>{'Description:'}</Text>
                        <View style={[styles.fdr, styles.w80]}/>
                        <View style={[styles.fdr, styles.w70, styles.aic, styles.jcc, styles.mr10]}>
                            <Text style={[styles.fs12, styles.bold, styles.pl5, styles.pt5, styles.pb5]}>{'Units:'}</Text>
                        </View>
                        <View style={[styles.fdr, styles.w90, styles.aic, styles.jcc, styles.mr10]}>
                            <Text style={[styles.fs12, styles.bold, styles.pl5, styles.pt5, styles.pb5]}>{'Kilograms:'}</Text>
                        </View>
                        <View style={[styles.fdr, styles.w90, styles.aic, styles.jcc]}>
                            <Text style={[styles.fs12, styles.bold, styles.pl5, styles.pt5, styles.pb5]}>{'Percentage:'}</Text>
                        </View>
                    </View>
                    <View style={[styles.fdc]}>
                        {getStockPackedDocumentLines(batches, stocks, packs, lots)}
                    </View>
                    <View style={[styles.fdr, styles.mt20]}>
                        <Text style={[styles.fs12, styles.pl5]}>{'Sub-Total:'}</Text>
                        <View style={[styles.fdr, styles.flx1]}/>
                        <View style={[styles.fdr, styles.w90, styles.aic, styles.jcc, styles.blw1, styles.btw1, styles.brw1, styles.bbw1, styles.mr10]}>
                            <Text style={[styles.fs10, styles.pt2, styles.pb2]}>{getStockPackedTotalWeight(batches, stocks, packs, lots).toFixed(3) ?? 0}</Text>
                        </View>
                        <View style={[styles.fdr, styles.w90, styles.aic, styles.jcc, styles.blw1, styles.btw1, styles.brw1, styles.bbw1]}>
                            <Text style={[styles.fs10, styles.pt2, styles.pb2]}>{(calculatePackedStockTotalPercentage(batches, stocks, packs, lots).toFixed(2) ?? 0) + '%'}</Text>
                        </View>
                    </View>
                    <View style={getReturnedStocksDocumentLines(batches, lots) ? [styles.fdr, styles.bbw2, styles.mt10] : [styles.fdr, styles.bbw2, styles.mt10, styles.dn]}/>
                    {getReturnedStocksDocumentLines(batches, lots) &&
                        getReturnedStocksDocumentLines(batches, lots)
                    }
                    <View style={[styles.fdr, styles.bbw2, styles.mt10]}/>
                    <View style={[styles.fdr, styles.mt20]}>
                        <Text style={[styles.fs10]}>{'Waste:'}</Text>
                        <View style={[styles.fdr, styles.flx1]}/>
                        <View style={[styles.fdr, styles.w90, styles.aic, styles.jcc, styles.blw1, styles.btw1, styles.brw1, styles.bbw1, styles.mr10]}>
                            <Text style={[styles.fs10, styles.pt2, styles.pb2]}>{calculateTotalByLotType(batches.map(z => z.lotId), CONSTANT_LOT_TYPE_GUIDS[1], 'nett', false, lots).toFixed(3) ?? 0}</Text>
                        </View>
                        <View style={[styles.fdr, styles.w90, styles.aic, styles.jcc, styles.blw1, styles.btw1, styles.brw1, styles.bbw1]}>
                            <Text style={[styles.fs10, styles.pt2, styles.pb2]}>{(calculateWastePercentage(batches, lots).toFixed(2) ?? 0) + '%'}</Text>
                        </View>
                    </View>
                    <View style={[styles.fdr, styles.bbw2, styles.mt10]}/>
                    <View style={[styles.fdr, styles.mt20]}>
                        <Text style={[styles.fs12, styles.pr5]}>{'Total:'}</Text>
                        <View style={[styles.fdr, styles.flx1]}/>
                        <View style={[styles.fdr, styles.w90, styles.aic, styles.jcc, styles.blw1, styles.btw1, styles.brw1, styles.bbw1, styles.mr10]}>
                            <Text style={[styles.fs10, styles.pt2, styles.pb2]}>{(getStockPackedTotalWeight(batches, stocks, packs, lots) + calculateTotalByLotType(batches.map(z => z.lotId), CONSTANT_LOT_TYPE_GUIDS[1], 'nett', false, lots) + getReturnedTotalWeight(batches, lots)).toFixed(3) ?? 0}</Text>
                        </View>
                        <View style={[styles.fdr, styles.w90, styles.aic, styles.jcc, styles.blw1, styles.btw1, styles.brw1, styles.bbw1]}>
                            <Text style={[styles.fs10, styles.pt2, styles.pb2]}>{(calculatePackedStockAndWasteTotalPercentage(batches, stocks, packs, lots).toFixed(2) ?? 0) + '%'}</Text>
                        </View>
                    </View>
                    <View style={[styles.fdr, styles.mt10]}>
                        <Text style={[styles.fs10]}>{'Variance:'}</Text>
                        <View style={[styles.fdr, styles.flx1]}/>
                        <View style={[styles.fdr, styles.w90, styles.aic, styles.jcc, styles.blw1, styles.btw1, styles.brw1, styles.bbw1, styles.mr10]}>
                            <Text style={[styles.fs10, styles.pt2, styles.pb2]}>{calculateVariance(batches, stocks, packs, lots).toFixed(3)}</Text>
                        </View>
                        <View style={[styles.fdr, styles.w90, styles.aic, styles.jcc, styles.blw1, styles.btw1, styles.brw1, styles.bbw1]}>
                            <Text style={[styles.fs10, styles.pt2, styles.pb2]}>{(calculateVariancePercentage(batches, stocks, packs, lots).toFixed(2) ?? 0) + '%'}</Text>
                        </View>
                    </View>
                    <View style={[styles.fdr, styles.mt35]}>
                        <View style={[styles.fdc, styles.mt10, styles.aic]}>
                            <Text style={[styles.fs8, styles.w100, styles.bbw2]}>{''}</Text>
                            <Text style={[styles.fs8, styles.flx1]}>{'Signature'}</Text>
                        </View>
                        <View style={[styles.fdr, styles.flx1]}/>
                        <View style={[styles.fdc, styles.mt10, styles.aic]}>
                            <Text style={[styles.fs9, styles.w100, styles.bbw2]}>{''}</Text>
                            <Text style={[styles.fs9, styles.flx1]}>{'Date'}</Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export const buildBatchReportPdf = (document : any, fileName : string, batches : Array<IBatchView>, stocks : Array<IStock>, packs : Array<IPack>, intakes : Array<IIntake> , lots : Array<ILot>, callback ?: () => void) => {
    const doc : () => React.ReactElement<IDocumentProps> = () => document(batches, stocks, packs, intakes, lots);

    createAndDownloadPDF(
        doc,
        fileName,
        'pdf-creator-link',
        // tslint:disable-next-line: no-empty
        callback ? callback : () => {},
    );
};

interface IBatchSummaryPopupProps {
    batchView : IBatchView;
    setLoading : (loading : boolean) => void;
    refreshData : () => void;
    endBatchPressed : (batch : IBatchView) => void;
    onEndCancelled : () => void;
    updateBatch : (batch : IBatchView) => void;
    isLoading : boolean;
    hasEditingRights : boolean;
    hasBatchLotFinalizeRight : boolean;
    lots : Array<ILot>;
    lotTypes : Array<ILotType>;
    organizations : Array<IOrganization>;
    selectedSiteIds : Array<number>;
    sites : Array<ISite>;
    printServers : Array<IPrintServer>;
    reports : Array<IReport>;
    farms : Array<IFarm>;
    varieties : Array<IVariety>;
    orchards : Array<IOrchard>;
    departments : Array<IDepartment>;
    commodities : Array<ICommodity>;
    commodityStates : Array<ICommodityState>;
    packs : Array<IPack>;
}

interface IBatchSummaryPopupState {
    lotLineViews : Array<ILotLineView>;
    showDialog : boolean;
    editingLotLine ?: ILotLine;
    newLotLineWeight ?: number;
    newLotLineNoOfUnits ?: number;
    showConfirmationPrompt : boolean;
    revertLotLines : Array<number>;
    showEditLotLineConfirmation : boolean;
    editLotLineWeight : boolean;
    isFinalizeBatchLotDialogOpen : boolean;
    isDownloadReportFormOpen : boolean;
    isAddOrchardsDialogOpen : boolean;
    isAddDepartmentsDialogOpen : boolean;
    intakes : Array<IIntake>;
    stocks : Array<IStock>;
    packs : Array<IPack>;
    selectedOrchards : Array<IOptionType>;
    selectedDepartments : Array<IOptionType>;
}

class BatchSummaryPopup extends React.Component<IBatchSummaryPopupProps, IBatchSummaryPopupState> {
    constructor(props : IBatchSummaryPopupProps) {
        super(props);

        this.state = {
            lotLineViews: [],
            showDialog: false,
            showConfirmationPrompt: false,
            revertLotLines: [],
            showEditLotLineConfirmation: false,
            editLotLineWeight: false,
            isFinalizeBatchLotDialogOpen: false,
            isDownloadReportFormOpen: false,
            isAddOrchardsDialogOpen: false,
            isAddDepartmentsDialogOpen: false,
            intakes: [],
            stocks: [],
            selectedOrchards: [],
            selectedDepartments: [],
            packs: this.props.packs,
        };
    }

    public componentDidMount = async () => {
        this.props.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            // NOTE: only call syncMasterData here. It is not needed to call it on every get call in the summary
            await syncMasterData(false);
        }

        try {
            const res = await BatchHttpService.getBatchSummaryRelatedData(this.props.batchView.id, !isIndexedDBAvailable);

            if (res) {
                this.setState({ lotLineViews: res.data.lotLineViews });
                dataSetBatchSummaryPopupData(res.data);
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred while loading stock lines.');
        } finally {
            this.props.setLoading(false);
        }

        this.getBatchReportData();
    };

    public getBatchReportData = async () => {
        try {
            this.props.setLoading(true);
            // checks if indexedDB is available.
            const isIndexedDBAvailable = !!self.indexedDB ? true : false;

            const res = await BatchHttpService.getBatchReportRelatedData(this.props.batchView.id, !isIndexedDBAvailable);

            if (res.data) {
                if (isIndexedDBAvailable) {
                    this.setState({
                        intakes: res.data.intakes,
                        stocks: res.data.stocks,
                    });
                } else {
                    this.setState({
                        intakes: res.data.intakes,
                        stocks: res.data.stocks,
                        packs: res.data.packs,
                    });
                }
            }
        } catch (ex) {
            generalShowErrorSnackbar('An error occurred while loading batch report related data.');
        } finally {
            this.props.setLoading(false);
        }
    };

    // Lot Names

    private getLotTypeName = (id : number) => this.props.lotTypes.find(x => x.id === id)?.name ?? '';
    private getOrganizationName = (id : number) => this.props.organizations.find(x => x.id === id)?.name ?? '';
    private getSiteDescription = (id : number) => this.props.sites.find(x => x.id === id)?.description ?? '';
    private getFarmName = (id : number) => this.props.farms.find(x => x.id === id)?.name ?? '';
    private getVarietyName = (id : number) => this.props.varieties.find(x => x.id === id)?.name ?? '';
    private getOrchardName = (id : number) => this.props.orchards.find(x => x.id === id)?.name ?? '';
    private getCommodityName = (id : number) => this.props.commodities.find(x => x.id === id)?.name ?? '';
    private getCommodityStateName = (id : number) => this.props.commodityStates.find(x => x.id === id)?.name ?? '';

    private getLotLotTypeGuid = (id : number) => {
        const lot = this.props.lots.find(x => x.id === id);
        const lotType = lot && this.props.lotTypes.find(x => x.id === lot.lotTypeId);
        return lotType ? lotType.guid : '';
    };

    private getLotLineLotId = (lineId : number) => this.props.lots.find(x => x.lotLines.some(y => y.id === lineId))?.id ?? '';
    private getLotLineLotName = (lineId : number) => this.props.lots.find(x => x.lotLines.some(y => y.id === lineId))?.name ?? '';

    private getLotLineLotTypeGuid = (id : number) => {
        const lotId = this.getLotLineLotId(id);
        return lotId ? this.getLotLotTypeGuid(lotId) : '';
    };

    private getLots = (props : IBatchSummaryPopupProps) => props.lots;
    private getBatch = (props : IBatchSummaryPopupProps) => props.batchView;
    private getLotLineViews = (props : IBatchSummaryPopupProps, state : IBatchSummaryPopupState) => state.lotLineViews;
    private getEditLotLine = (props : IBatchSummaryPopupProps, state : IBatchSummaryPopupState) => state.editingLotLine;

    private renderCodes = (value : string) => {
        if (!value) return '';
        const stringList : Array<string> = value?.split(', ');
        return stringList.map(x => <div className={'ml5 fdr'}>{x}</div>);
    };

    private getOrchards = (props : IBatchSummaryPopupProps) => props.orchards;
    private getOrchardIds = (props : IBatchSummaryPopupProps) => props.batchView.orchardIds;
    private getCommodityIds = (props : IBatchSummaryPopupProps) => props.batchView.commodityIds;
    private getFarmIds = (props : IBatchSummaryPopupProps) => props.batchView.farmIds;
    private getVarietyIds = (props : IBatchSummaryPopupProps) => props.batchView.varietyIds;
    private getDepartments = (props : IBatchSummaryPopupProps) => props.departments;
    private getDepartmentIds = (props : IBatchSummaryPopupProps) => props.batchView.departmentIds;

    private getOrchardOptions = createSelector([this.getOrchards, this.getOrchardIds, this.getCommodityIds, this.getFarmIds, this.getVarietyIds],
        (orchards, orchardIds, commodityIds, farmIds, varietyIds) => {
            const orchardIdsArray = orchardIds?.split(', ')?.map(x => Number(x));
            const commodityIdsArray = commodityIds?.split(', ')?.map(x => Number(x));
            const farmIdsArray = farmIds?.split(', ')?.map(x => Number(x));
            const varietyIdsArray = varietyIds?.split(', ')?.map(x => Number(x));
            return orchards?.filter(x => x.isActive
                && commodityIdsArray?.some(y => y === x.commodityId)
                && farmIdsArray?.some(y => y === x.farmId)
                && varietyIdsArray?.some(y => y === x.defaultVarietyId)
                && !orchardIdsArray?.some(y => y === x.id))?.map((x) => {
                return { value: x.id, label: `${x.code} - ${x.name}` };
            }) ?? [];
        }
    );

    private getDepartmentOptions = createSelector([this.getDepartments, this.getDepartmentIds],
        (departments, departmentIds) => {
            const departmentIdsArray = departmentIds?.split(', ')?.map(x => Number(x));
            return departments?.filter(x => x.isActive
                && !departmentIdsArray?.some(y => y === x.id))?.map((x) => {
                return { value: x.id, label: `${x.code} - ${x.description}` };
            }) ?? [];
        }
    );

    private getLotLines = createSelector([this.getLots, this.getBatch],
        (lots ?: Array<ILot>, batch ?: IBatchView) =>
            lots?.find(x => x.id === batch?.lotId)?.lotLines ?? []);

    private getRows = createSelector([this.getLotLineViews],
        (lotLineViews ?: Array<ILotLineView>) =>
            lotLineViews ?? []);

    private getBatchLotName = createSelector([this.getLots, this.getBatch],
        (lots ?: Array<ILot>, batch ?: IBatchView) =>
            lots?.find(x => x.id === batch?.lotId)?.name ?? '');

    private getBatchLot = createSelector([this.getLots, this.getBatch],
        (lots ?: Array<ILot>, batch ?: IBatchView) =>
            lots?.find(x => x.id === batch?.lotId));

    private calculateTotal = (type : 'gross' | 'nett' | 'unit', incoming ?: boolean) => {
        let total = 0;
        const lotLines = this.getLotLines(this.props).filter(x => x.isActive);

        if (lotLines) {
            lotLines.forEach((x) => {
                if ((incoming === undefined && !x.isIncoming) || (incoming === x.isIncoming)) {
                    switch (type) {
                        case 'gross':
                            total += x.grossWeight;
                            break;
                        case 'nett':
                            total += x.nettWeight;
                            break;
                        case 'unit':
                            total += x.amountOfUnits;
                            break;
                    }
                }
            });
        }

        return total;
    };

    private calculateTotalByLotType = (lotType : string, type : 'gross' | 'nett' | 'unit', incoming : boolean) => {
        let total = 0;
        const lotLines = this.getLotLines(this.props).filter(x => x.isActive);

        if (lotLines) {
            lotLines.forEach((x) => {
                if (((incoming && !!x.fromLotLineId && this.getLotLineLotTypeGuid(x.fromLotLineId)) || (!incoming && !!x.toLotLineId && this.getLotLineLotTypeGuid(x.toLotLineId))) === lotType) {
                    switch (type) {
                        case 'gross':
                            total += x.grossWeight;
                            break;
                        case 'nett':
                            total += x.nettWeight;
                            break;
                        case 'unit':
                            total += x.amountOfUnits;
                            break;
                    }
                }
            });
        }

        return total;
    };

    private calculateStockWeight = (type : 'gross' | 'nett' | 'unit', incoming : boolean) => {
        let total = 0;
        const lotLines = this.getLotLines(this.props).filter(x => x.isActive);

        if (lotLines) {
            lotLines.forEach((x) => {
                switch (type) {
                    case 'gross':
                        if ((incoming && !!x.fromStockLineId) || (!incoming && !!x.toStockLineId)) total += x.grossWeight;
                        break;
                    case 'nett':
                        if ((incoming && !!x.fromStockLineId) || (!incoming && !!x.toStockLineId)) total += x.nettWeight;
                        break;
                    case 'unit':
                        if ((incoming && !!x.fromStockLineId) || (!incoming && !!x.toStockLineId)) total += x.amountOfUnits;
                        break;
                }
            });
        }

        return total;
    };

    public onReportFileReset = async (formValues : IReportFileFormValues, formikHelpers : FormikHelpers<IReportFileFormValues>) => {
        formikHelpers.resetForm();
        this.closeDownloadReportPopup();
    };

    private generateReportForm = () => new ReportFileFormValues();

    private showDownloadReportPopup = async () => {
        this.setState({ isDownloadReportFormOpen: true });
    };

    private downloadReportFile = async (values : IReportFileFormValues) => {
        if (this.props.batchView && values.printServer && values.site && values.report) {
            try {
                const res = await BatchHttpService.getBatchAppView(this.props.batchView.guid);

                const printServer = this.props.printServers.find(x => x.id === values.printServer?.value);
                const report = this.props.reports.find(x => x.id === values.report?.value);

                if (printServer && res && report) {
                    const res2 = await PrintServerReportHttpService.getBatchReportPreview(printServer, res.data, this.props.batchView.guid, report.guid);
                    if (res2 && res2.data) {
                        const data = Buffer.from(res2.data).toString('base64');
                        const base64String = 'data:document/pdf;base64,' + data; // Base64 string
                        const byteString = atob(base64String?.split(',')[1]);

                        // separate out the mime component
                        const mimeString = base64String?.split(',')[0]?.split(':')[1]?.split(';')[0];
                        // write the bytes of the string to an ArrayBuffer
                        const ab = new ArrayBuffer(byteString.length);
                        // create a view into the buffer
                        const ia = new Uint8Array(ab);
                        // set the bytes of the buffer to the correct values
                        for (let i = 0; i < byteString.length; i++) {
                            ia[i] = byteString.charCodeAt(i);
                        }

                        const blob = new Blob([ab], { type: mimeString }); // Create a BLOB object

                        saveAs(blob, 'Batch Report.png');
                    }
                }

            } catch (e) {
                generalShowErrorSnackbar('Downloading batch report failed!');
            } finally {
                this.props.setLoading(false);
            }
        }
    };

    private closeDownloadReportPopup = () => {
        this.setState({ isDownloadReportFormOpen: false });
    };

    private submitAddOrchards = async () => {
        if (this.props.batchView && this.state.selectedOrchards.length > 0) {
            this.props.setLoading(true);
            try {
                const res = await BatchHttpService.addOrchardsToBatch(this.props.batchView.id, this.state.selectedOrchards.map(x => Number(x.value)));

                if (res && res.data) {
                    dataSetBatchView(res.data);
                    this.props.updateBatch(res.data);
                    this.closeAddOrchardsDialog();
                    this.props.setLoading(false);
                }

            } catch (e) {
                generalShowErrorSnackbar('Failed to add orchards!');
                this.props.setLoading(false);
            } finally {
                this.props.setLoading(false);
            }
        }
    };

    private submitAddDepartments = async () => {
        if (this.props.batchView && this.state.selectedDepartments.length > 0) {
            this.props.setLoading(true);
            try {
                const res = await BatchHttpService.addDepartmentsToBatch(this.props.batchView.id, this.state.selectedDepartments.map(x => Number(x.value)));

                if (res && res.data) {
                    dataSetBatchView(res.data);
                    this.props.updateBatch(res.data);
                    this.closeAddDepartmentsDialog();
                    this.props.setLoading(false);
                }

            } catch (e) {
                generalShowErrorSnackbar('Failed to add orchards!');
                this.props.setLoading(false);
            } finally {
                this.props.setLoading(false);
            }
        }
    };

    private openConfirmLotLineEditSubmit = () => {
        this.setState({ showEditLotLineConfirmation: true });
    };

    private closeConfirmLotLineEditSubmit = () => {
        this.setState({ showEditLotLineConfirmation: false });
    };

    private openAddOrchardsDialog = () => {
        this.setState({ isAddOrchardsDialogOpen: true });
    };

    private closeAddOrchardsDialog = () => {
        this.setState({ isAddOrchardsDialogOpen: false });
    };

    private setSelectedOrchards = (e : React.ChangeEvent<{}> | undefined, selectedOrchards : Array<IOptionType>) => {
        this.setState({ selectedOrchards });
    };

    private openAddDepartmentsDialog = () => {
        this.setState({ isAddDepartmentsDialogOpen: true });
    };

    private closeAddDepartmentsDialog = () => {
        this.setState({ isAddDepartmentsDialogOpen: false });
    };

    private setSelectedDepartments = (e : React.ChangeEvent<{}> | undefined, selectedDepartments : Array<IOptionType>) => {
        this.setState({ selectedDepartments });
    };

    private submitEdit = async () => {
        const newGrossWeight = this.state.newLotLineWeight;
        const newNoOfUnits = this.state.newLotLineNoOfUnits;
        const lotLineId = this.state.editingLotLine && this.state.editingLotLine.id;
        if (lotLineId && newGrossWeight && newNoOfUnits) {
            // checks if indexedDB is available.
            const isIndexedDBAvailable = !!self.indexedDB ? true : false;

            this.props.setLoading(true);
            const data : ILotLineEdit = {
                lotLineId,
                newGrossWeight,
                newNoOfUnits,
            };
            try {
                const res = await LotHttpService.editBatchLotLine(data);
                const res2 = await BatchHttpService.getBatchSummaryRelatedData(this.props.batchView.id, !isIndexedDBAvailable);

                res.data.forEach((x) => {
                    dataSetLot(x);
                });
                if (res2) {
                    this.setState({ lotLineViews: res2.data.lotLineViews });
                }
                this.cancelEdit();
                this.props.setLoading(false);
                generalShowSuccessSnackbar('Lot line has been updated!');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred updating the lot line.');
                this.props.setLoading(false);
            }
        } else {
            generalShowErrorSnackbar('Lot Line weight or no. of units was not specified');
        }
    };

    private EditLotLineWeight = async () => {
        const newGrossWeight = this.state.newLotLineWeight;
        const lotLineId = this.state.editingLotLine && this.state.editingLotLine.id;
        if (lotLineId && newGrossWeight) {
            // checks if indexedDB is available.
            const isIndexedDBAvailable = !!self.indexedDB ? true : false;

            this.props.setLoading(true);
            try {
                const res = await LotHttpService.changeLotLineWeight({ lotLineId, newGrossWeight });
                const res2 = await BatchHttpService.getBatchSummaryRelatedData(this.props.batchView.id, !isIndexedDBAvailable);

                res.data.forEach((x) => {
                    dataSetLot(x);
                });
                if (res2) {
                    this.setState({ lotLineViews: res2.data.lotLineViews });
                }
                this.cancelWeightChangeEdit();
                this.props.setLoading(false);
                generalShowSuccessSnackbar('Lot line has been updated!');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred updating the lot line.');
                this.props.setLoading(false);
            }
        } else {
            generalShowErrorSnackbar('Lot Line weight or no. of units was not specified');
        }
    };

    private cancelEdit = () => this.setState({ showDialog: false, editingLotLine : undefined, newLotLineWeight: undefined, newLotLineNoOfUnits: undefined, showEditLotLineConfirmation: false });
    private cancelWeightChangeEdit = () => this.setState({ editLotLineWeight: false, editingLotLine : undefined, newLotLineWeight: undefined });

    private onRevertLotLinesClick = () => {
        this.setState({ showConfirmationPrompt: true });
    };

    private submitRevertLotLines = async () => {
        const lotLineIds = [...this.state.revertLotLines];
        if (lotLineIds.length !== 0) {
            // checks if indexedDB is available.
            const isIndexedDBAvailable = !!self.indexedDB ? true : false;

            this.props.setLoading(true);
            try {
                const res = await LotHttpService.revertLotLines(lotLineIds);

                if (res && res.data) {
                    const res2 = await BatchHttpService.getBatchSummaryRelatedData(this.props.batchView.id, !isIndexedDBAvailable);

                    if (res2) {
                        this.setState({ lotLineViews: res2.data.lotLineViews });
                        dataSetBatch(res2.data.batch);
                    }
                    this.setState({ revertLotLines: [], showConfirmationPrompt: false });
                    res.data.forEach((lot) => {
                        dataSetLot(lot);
                    });
                }
                this.props.setLoading(false);
                generalShowSuccessSnackbar('Lot lines reverted successfully');
            } catch (e) {
                this.setState({ revertLotLines: [], showConfirmationPrompt: false });
                generalShowErrorSnackbar('An error occurred reverting the lot lines');
                this.props.setLoading(false);
            }
        } else {
            generalShowErrorSnackbar('Lot Line was not specified');
        }
    };

    private isChecked = (id : number) => this.state.revertLotLines.some(x => x === id);

    private handleRevertCheckboxChecked = (revertLotLines : Array<number>) =>  this.setState({ revertLotLines });

    private onRevertLotLineCancel = () => {
        this.setState({ showConfirmationPrompt: false, revertLotLines: [] });
    };

    private getFromLotLotLines = createSelector(
        [this.getLots, this.getEditLotLine],
        (lots, editingLotLine) => {
            const lot = lots.find(x => x.lotLines.some(y => y.id === editingLotLine?.fromLotLineId));

            return lot ? lot.lotLines : [];
        },
    );

    private renderTable = () => {
        return (
            <div className={'fdr flx1 p20'}>
                <Table style={{ backgroundColor: materialTheme.custom.table.background }} className={'PaperBorder'}>
                    {/* Headings */}
                    <TableRow className={'fdr'}>
                        <TableCell className={'flx1 jcc aic p0'}>
                            {' '}
                        </TableCell>
                        <TableCell className={'flx3 jcc aic p5 lotSummaryDarkBlue h27 cw fw500'}>
                            {'INCOMING'}
                        </TableCell>
                        <TableCell className={'flx3 jcc aic p5 lotSummaryDarkGreen h27 cw fw500'}>
                            {'OUTGOING'}
                        </TableCell>
                    </TableRow>
                    <TableRow className={'fdr'}>
                        <TableCell className={'flx1 jcc aic p0'}>
                            {' '}
                        </TableCell>
                        <TableCell className={'flx3 jcc aic p5 lotSummaryLightBlue h23 cw fs14'}>
                            <div className={'flx1 aic jcc'}>Units</div>
                            <div className={'flx1 aic jcc'}>Gross Weight</div>
                            <div className={'flx1 aic jcc'}>Nett Weight</div>
                        </TableCell>
                        <TableCell className={'flx3 jcc aic p5 lotSummaryLightGreen h23 cw fs14'}>
                            <div className={'flx1 aic jcc'}>Units</div>
                            <div className={'flx1 aic jcc'}>Gross Weight</div>
                            <div className={'flx1 aic jcc'}>Nett Weight</div>
                        </TableCell>
                    </TableRow>
                    {/* Calculated Values */}
                    <TableRow  style={{ backgroundColor: materialTheme.custom.table.row1.default }} className={'fdr'}>
                        <TableCell className={'flx1 jcc aic p0 fw500'}>
                            {'Total'}
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 p0 fw500'}>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.calculateTotal('unit', true)}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotal('gross', true))}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotal('nett', true))}</div>
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 bl1 bocg3 p0 fw500'}>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.calculateTotal('unit', false)}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotal('gross', false))}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotal('nett', false))}</div>
                        </TableCell>
                    </TableRow>
                    <TableRow style={{ backgroundColor: materialTheme.custom.table.row2.default }} className={'fdr'}>
                        <TableCell className={'flx1 jcc aic p0 fw500'}>
                            {'Intake'}
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 p0'}>
                            <div className={'flx1 aic jcc hfill'}>{this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[2], 'unit', true)}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[2], 'gross', true))}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[2], 'nett', true))}</div>
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 bl1 bocg3 p0'}>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[2], 'unit', false)}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[2], 'gross', false))}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[2], 'nett', false))}</div>
                        </TableCell>
                    </TableRow>
                    <TableRow style={{ backgroundColor: materialTheme.custom.table.row1.default }} className={'fdr'}>
                        <TableCell className={'flx1 jcc aic p0 fw500'}>
                            {'Batch'}
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 p0'}>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[0], 'unit', true)}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[0], 'gross', true))}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[0], 'nett', true))}</div>
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 bl1 bocg3 p0'}>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[0], 'unit', false)}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[0], 'gross', false))}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[0], 'nett', false))}</div>
                        </TableCell>
                    </TableRow>
                    <TableRow style={{ backgroundColor: materialTheme.custom.table.row2.default }} className={'fdr'}>
                        <TableCell className={'flx1 jcc aic p0 fw500'}>
                            {'Stock'}
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 p0'}>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.calculateStockWeight('unit', true)}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateStockWeight('gross', true))}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateStockWeight('nett', true))}</div>
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 bl1 bocg3 p0'}>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.calculateStockWeight('unit', false)}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateStockWeight('gross', false))}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateStockWeight('nett', false))}</div>
                        </TableCell>
                    </TableRow>
                    <TableRow style={{ backgroundColor: materialTheme.custom.table.row1.default }} className={'fdr'}>
                        <TableCell className={'flx1 jcc aic p0 fw500'}>
                            {'Waste'}
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 p0'}>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[1], 'unit', true)}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[1], 'gross', true))}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[1], 'nett', true))}</div>
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 bl1 bocg3 p0'}>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[1], 'unit', false)}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[1], 'gross', false))}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[1], 'nett', false))}</div>
                        </TableCell>
                    </TableRow>
                    <TableRow style={{ backgroundColor: materialTheme.custom.table.row2.default }} className={'fdr'}>
                        <TableCell className={'flx1 jcc aic p0 fw500'}>
                            {'Variance'}
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 p0'}>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[3], 'unit', true)}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[3], 'gross', true))}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[3], 'nett', true))}</div>
                        </TableCell>
                        <TableCell className={'flx3 jcc aic h33 fs14 bl1 bocg3 p0'}>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[3], 'unit', false)}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[3], 'gross', false))}</div>
                            <div className={'flx1 aic jcc bl1 bocg3 hfill'}>{this.formatWeight(this.calculateTotalByLotType(CONSTANT_LOT_TYPE_GUIDS[3], 'nett', false))}</div>
                        </TableCell>
                    </TableRow>
                </Table>
            </div>
        );
    };

    private renderListElement = React.memo(({ data, index, style } : React.PropsWithChildren<ListChildComponentProps>) => {
        const lotLine : ILotLine = data[index];
        if (!lotLine) return <div />;
        const editingLotLine = this.state.editingLotLine;
        return (
            <TableRow style={style} className={`${index % 2 === 0 ? ((lotLine.id === editingLotLine?.id || lotLine.id === editingLotLine?.fromLotLineId) ? 'bcTableRow2Warning' : 'bcTableRow2') : ((lotLine.id === editingLotLine?.id || lotLine.id === editingLotLine?.fromLotLineId) ? 'bcTableRow1Warning' : 'bcTableRow1')}`}>
                <TableCell className={'jcc aic h40 fs14 p0 fw500'}>
                    <div style={{ width: 110, minWidth: 110, maxWidth: 110 }}  className={'aic jcc hfill'}>{lotLine.id}</div>
                    <div style={{ width: 110, minWidth: 110, maxWidth: 110 }}  className={'aic jcc bl1 hfill'}>
                        {(lotLine.amountOfUnits.toString() === '0 ea' ? '0 ea' : lotLine.isIncoming ? <div className={'cp'} style={{ paddingLeft: 3 }}>{`+${lotLine.amountOfUnits}`}</div> :
                            <div className={'cred'} style={{ paddingLeft: 3 }}>{`-${lotLine.amountOfUnits}`}</div>)}
                    </div>
                    <div style={{ width: 110, minWidth: 110, maxWidth: 110 }}  className={'aic jcc bl1 hfill'}>
                        {(lotLine.grossWeight.toString() === '0 kg' ? '0 kg' : lotLine.isIncoming ? <div className={'cp'} style={{ paddingLeft: 3 }}>{`+${lotLine.grossWeight}`}</div> :
                            <div className={'cred'} style={{ paddingLeft: 3 }}>{`-${lotLine.grossWeight}`}</div>)}
                    </div>
                    <div style={{ width: 110, minWidth: 110, maxWidth: 110 }}  className={'aic jcc bl1 hfill'}>
                        {(lotLine.nettWeight.toString() === '0 kg' ? '0 kg' : lotLine.isIncoming ? <div className={'cp'} style={{ paddingLeft: 3 }}>{`+${lotLine.nettWeight}`}</div> :
                            <div className={'cred'} style={{ paddingLeft: 3 }}>{`-${lotLine.nettWeight}`}</div>)}
                    </div>
                </TableCell>
            </TableRow>
        );
    });

    private formatWeight = (weight : number) => weight.toFixed(3) + ' kg';

    private onLotLineEdit = (row : ILotLineView) => {
        const lotLines = this.props.lots?.find(x => x.id === this.props.batchView?.lotId)?.lotLines;
        const selectedLotLine = lotLines?.find(x => x.id === row.id);
        if (selectedLotLine?.fromLotLineId) {
            this.setState({ editingLotLine: selectedLotLine, showDialog: true }, async () => {
                try {
                    if (selectedLotLine && selectedLotLine.fromLotLineId) {
                        this.props.setLoading(true);
                        const res = await LotHttpService.getLotLineLotData(selectedLotLine?.fromLotLineId);

                        if (res && res.data) {
                            dataSetLot(res.data);
                        }
                        this.props.setLoading(false);
                    }
                } catch (ex) {
                    generalShowErrorSnackbar('Failed to load load data');
                    this.props.setLoading(false);
                }
            });
        } else {
            this.setState({ editingLotLine: selectedLotLine, editLotLineWeight: true });
        }
    };

    private onBatchLotFinalize = async () => {
        if (!!this.props.batchView.lotId && this.getBatchLot(this.props)) {
            this.setState({ isFinalizeBatchLotDialogOpen: true });
        } else {
            generalShowErrorSnackbar('Batch lot not found.');
        }
    };

    public closeBatchLotFinalizeDialog = () => {
        this.setState({ isFinalizeBatchLotDialogOpen: false });
    };

    private submitFinalizeBatchLot = async () => {
        this.props.setLoading(true);
        const batchLot = this.getBatchLot(this.props);
        if (batchLot) {
            try {
                const res = await LotHttpService.doLotAdjustment(batchLot.id);

                if (res.data) {
                    res.data.map(x => dataSetLot(x));

                    this.setState({ isFinalizeBatchLotDialogOpen: false });
                    generalShowSuccessSnackbar('Lot finalized!');
                } else {
                    generalShowErrorSnackbar('An error occurred finalizing the batch lot.');
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred finalizing the batch lot.');
            } finally {
                this.props.setLoading(false);
            }
        }
    };

    private getBatchEndButtonTooltipTitle = createSelector(
        [this.getBatch, this.getBatchLot],
        (batch : IBatchView, batchLot ?: ILot) => {
            let title = '';

            if (!batch.isActive && !batch.endedOn) {
                title = 'Cannot end an inactive batch.';
            } else if (!batch.isActive && !!batch.endedOn) {
                title = 'Cannot resume an inactive batch.';
            } else if (batchLot?.isFinalized) {
                title = 'Cannot resume batch because lot is finalized.';
            }

            return title;
        },
    );

    private formatFromLotLink = (row : ILotLineView, lotName : string) => {
        return <PackmanLink
            type={'transactions'}
            targetPage={'lot'}
            id={Number(row.fromLotId)}
            text={lotName} />;
    };

    private formatToLotLink = (row : ILotLineView, lotName : string) => {
        if (row.toLotTypeName === 'Waste') {
            return <PackmanLink
                type={'transactions'}
                targetPage={'lot'}
                id={Number(row.toLotId)}
                text={`Waste Lot ${row.toLotId}`} />;
        } else {
            return <PackmanLink
                type={'transactions'}
                targetPage={'lot'}
                id={Number(row.toLotId)}
                text={lotName} />;
        }
    };

    private formatFromStockLink = (row : ILotLineView, barcode : string) => {
        return <PackmanLink
            type={'transactions'}
            targetPage={'stock'}
            id={Number(row.fromStockId)}
            text={barcode} />;
    };

    private formatToStockLink = (row : ILotLineView, barcode : string) => {
        return <PackmanLink
            type={'transactions'}
            targetPage={'stock'}
            id={Number(row.toStockId)}
            text={barcode} />;
    };

    public render() {
        const batchLot = this.getBatchLot(this.props);
        const hasBatchLotFinalizeRight = this.props.hasBatchLotFinalizeRight;

        return (
            <Screen isPadded={false} isScrollable={false} isLoading={this.props.isLoading}>
                <div className={'fdc pl20 pr20 pt10'}>
                    <div className={'fdr'}>
                        <PackmanLabel
                            label={'ID'}
                            value={this.props.batchView.id ?? ''}
                        />
                        <PackmanLabel
                            label={'Batch Code'}
                            value={this.props.batchView.batchCode ?? ''}
                        />
                        <PackmanLabel
                            label={'Lot Name'}
                            value={this.props.batchView.lotName}
                        />
                        <PackmanLabel
                            label={'Started On'}
                            value={formatDateTime(this.props.batchView.startedOn?.toString() ?? '')}
                        />
                        <PackmanLabel
                            label={'Ended On'}
                            value={formatDateTime(this.props.batchView.endedOn?.toString() ?? '')}
                        />
                        <PackmanLabel
                            label={'Organization'}
                            value={this.getOrganizationName(this.props.batchView?.organizationId)}
                        />
                        <PackmanLabel
                            label={'Site'}
                            value={this.getSiteDescription(this.props.batchView.siteId)}
                        />
                        <PackmanLabel
                            label={'Comment'}
                            value={this.props.batchView.batchComment ?? ''}
                        />
                    </div>
                    <div className={'fdr aifs'}>
                        <PackmanLabel
                            label={'Created By'}
                            value={this.props.batchView.createdByName ?? ''}
                        />
                        <PackmanLabel
                            label={'Created On'}
                            value={formatDateTime(this.props.batchView.createdOn ?? '')}
                        />
                        <PackmanLabel
                            label={'Updated By'}
                            value={this.props.batchView.updatedByName ?? ''}
                        />
                        <PackmanLabel
                            label={'Updated On'}
                            value={formatDateTime(this.props.batchView.updatedOn ?? '')}
                        />
                        <div className={'fdr flx1 aic'}>
                            <Typography className={'fs14 mr5'}>Has Ended?</Typography>
                            <BooleanFlag value={!!this.props.batchView.endedOn}/>
                        </div>
                        <div className={'fdr aic flx1'}>
                            <Typography className={'fs14 mr5'}>Active?</Typography>
                            <BooleanFlag value={!!this.props.batchView.isActive}/>
                        </div>
                        <div className={'fdr aic flx2'} style={{ maxWidth: 'calc(100% * 2/8)' }}>
                            {!this.props.batchView?.isActive ? <PackmanLabel
                                label={'Delete Reason'}
                                maxWidth={'100%'}
                                className={'wrap2line mxwfill oh'}
                                value={this.props.batchView?.deleteReason ?? ''}
                            /> : <></>}
                        </div>
                    </div>
                    <div className={'fdr'}>
                        <div className={'fdc flx1'}>
                            <Typography className={'fdr bcp fw600 cw mb10 p10 mt10 h50 aic'} variant='subtitle2'>
                                COMMODITIES
                            </Typography>
                            {this.renderCodes(this.props.batchView.commodityCodes)}
                        </div>
                        <span className={'w20'} />
                        <div className={'fdc flx1'}>
                            <Typography className={'fdr bcp fw600 cw mb10 p10 mt10 h50 aic'} variant='subtitle2'>
                                VARIETIES
                            </Typography>
                            {this.renderCodes(this.props.batchView.varietyCodes)}
                        </div>
                        <span className={'w20'} />
                        <div className={'fdc flx1'}>
                            <Typography className={'fdr bcp fw600 cw mb10 p10 mt10 h50 aic'} variant='subtitle2'>
                                FARMS
                            </Typography>
                            {this.renderCodes(this.props.batchView.farmCodes)}
                        </div>
                        <span className={'w20'} />
                        <div className={'fdc flx1'}>
                            <Typography className={'fdr bcp fw600 cw mb10 p10 mt10 h50 aic'} variant='subtitle2'>
                                <div>ORCHARDS</div>
                                <div className='flx1'/>
                                <IconButton size='small' onClick={this.openAddOrchardsDialog}>
                                    <Icon fontSize='small'>add</Icon>
                                </IconButton>
                            </Typography>
                            {this.renderCodes(this.props.batchView.orchardCodes)}
                        </div>
                        <span className={'w20'} />
                        <div className={'fdc flx1'}>
                            <Typography className={'fdr bcp fw600 cw mb10 p10 mt10 h50 aic'} variant='subtitle2'>
                                <div>DEPARTMENTS</div>
                                <div className='flx1'/>
                                <IconButton size='small' onClick={this.openAddDepartmentsDialog}>
                                    <Icon fontSize='small'>add</Icon>
                                </IconButton>
                            </Typography>
                            {this.renderCodes(this.props.batchView.departmentCodes)}
                        </div>
                    </div>
                    <Typography className={'fdr bcp fw600 cw p10 mt10'} variant='subtitle2'>
                        LOT INFORMATION
                    </Typography>
                    <div className={'fdr aic pt10'}>
                        <PackmanLabel
                            label={'ID'}
                            value={this.getBatchLot(this.props)?.id ?? ''}
                        />
                        <PackmanLabel
                            label={'Name'}
                            value={this.getBatchLotName(this.props)}
                        />
                        <PackmanLabel
                            label={'Type'}
                            value={this.getLotTypeName(this.getBatchLot(this.props)?.lotTypeId ?? 0)}
                        />
                        <PackmanLabel
                            label={'Gross Weight (kg)'}
                            value={this.getBatchLot(this.props)?.grossWeight.toFixed(3) ?? ''}
                        />
                        <PackmanLabel
                            label={'Nett Weight (kg)'}
                            value={this.getBatchLot(this.props)?.nettWeight.toFixed(3) ?? ''}
                        />
                        <PackmanLabel
                            label={'Commodity State'}
                            value={this.getCommodityStateName(this.getBatchLot(this.props)?.commodityStateId ?? 0)}
                        />
                        <div className={'flx1'}/>
                    </div>
                    <div className={'fdr aic pt10'}>
                        <PackmanLabel
                            label={'Created By'}
                            value={this.getBatchLot(this.props)?.createdByName ?? ''}
                        />
                        <PackmanLabel
                            label={'Created On'}
                            value={this.getBatchLot(this.props)?.createdOn ?? ''}
                        />
                        <PackmanLabel
                            label={'Updated By'}
                            value={this.getBatchLot(this.props)?.updatedByName ?? ''}
                        />
                        <PackmanLabel
                            label={'Updated On'}
                            value={this.getBatchLot(this.props)?.updatedOn ?? ''}
                        />
                        <div className={'fdr aic flx1'}>
                            <Typography className={'fs14 mr5'}>Finalized?</Typography>
                            <BooleanFlag value={this.getBatchLot(this.props)?.isFinalized ?? false}/>
                        </div>
                        <div className={'fdr aic flx1'}>
                            <Typography className={'fs14 mr5'}>Active?</Typography>
                            <BooleanFlag value={this.getBatchLot(this.props)?.isActive ?? false}/>
                        </div>
                        <div className={'flx1'}></div>
                    </div>
                </div>
                <div className={'flx1 fdr h600 mnh200 p20'}>
                    <CustomTable<ILotLineView>
                        enableEditing={(editingLotLine : ILotLineView) => (this.props.hasEditingRights && editingLotLine.toLotTypeName === 'Waste')}
                        editFunction={(editingLotLine : ILotLineView) => this.onLotLineEdit(editingLotLine)}
                        enableSelection
                        onSelectChange={this.handleRevertCheckboxChecked}
                        disableRowSelect={row => !row.isActive}
                        columns={[
                            { title: 'ID', field: 'id', width: 60, enableSorting: true },
                            {
                                title: 'No. Units (ea)', field: 'amountOfUnits', formatFunction: addea, enableSorting: true,
                                containerComponent: (row : ILotLineView, value : any) => <div className={'fdr'}>
                                    {(value === '0 ea' ? '0 ea' : row.isIncoming ? <div className={'cpl'} style={{ paddingLeft: 3 }}>{`+${value}`}</div> :
                                        <div className={'cred'} style={{ paddingLeft: 3 }}>{`-${value}`}</div>)}
                                </div>,
                            },
                            {
                                title: 'Gross Weight (kg)', field: 'grossWeight', formatFunction: addkg, enableSorting: true,
                                containerComponent: (row : ILotLineView, value : any) => <div className={'fdr'}>
                                    {(value === '0 kg' ? '0 kg' : row.isIncoming ? <div className={'cpl'} style={{ paddingLeft: 3 }}>{`+${value}`}</div> :
                                        <div className={'cred'} style={{ paddingLeft: 3 }}>{`-${value}`}</div>)}
                                </div>,
                            },
                            {
                                title: 'Nett Weight (kg)', field: 'nettWeight', formatFunction: addkg, enableSorting: true,
                                containerComponent: (row : ILotLineView, value : any) => <div className={'fdr'}>
                                    {(value === '0 kg' ? '0 kg' : row.isIncoming ? <div className={'cpl'} style={{ paddingLeft: 3 }}>{`+${value}`}</div> :
                                        <div className={'cred'} style={{ paddingLeft: 3 }}>{`-${value}`}</div>)}
                                </div>,
                            },
                            {
                                title: 'Unit Gross Weight (kg)', field: 'unitGrossWeight', formatFunction: addkg, enableSorting: true,
                                containerComponent: (row : ILotLineView, value : any) => <div className={'fdr'}>
                                    {(value === '0 kg' ? '0 kg' : row.isIncoming ? <div className={'cpl'} style={{ paddingLeft: 3 }}>{`+${value}`}</div> :
                                        <div className={'cred'} style={{ paddingLeft: 3 }}>{`-${value}`}</div>)}
                                </div>,
                            },
                            {
                                title: 'Unit Nett Weight (kg)', field: 'unitNettWeight', formatFunction: addkg, enableSorting: true,
                                containerComponent: (row : ILotLineView, value : any) => <div className={'fdr'}>
                                    {(value === '0 kg' ? '0 kg' : row.isIncoming ? <div className={'cpl'} style={{ paddingLeft: 3 }}>{`+${value}`}</div> :
                                        <div className={'cred'} style={{ paddingLeft: 3 }}>{`-${value}`}</div>)}
                                </div>,
                            },
                            { title: 'Unit Barcode', field: 'unitGuid', enableSorting: true },
                            { title: 'From Stock', containerComponent: this.formatFromStockLink, field: 'fromStockBarcode', enableSorting: true, width: 180 },
                            { title: 'To Stock', containerComponent: this.formatToStockLink, field: 'toStockBarcode', enableSorting: true, width: 180 },
                            { title: 'Pack', field: 'packCode', enableSorting: true },
                            { title: 'From Lot Type', field: 'fromLotTypeName', enableSorting: true },
                            { title: 'To Lot Type', field: 'toLotTypeName', enableSorting: true },
                            { title: 'From Lot', containerComponent: this.formatFromLotLink, field: 'fromLotName', enableSorting: true },
                            { title: 'To Lot', containerComponent: this.formatToLotLink, field: 'toLotName', enableSorting: true },
                            { title: 'Created By', field: 'createdByName', enableSorting: true },
                            { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate, enableSorting: true },
                            { title: 'Updated By', field: 'updatedByName', enableSorting: true },
                            { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableSorting: true },
                            { title: 'Active?', field: 'isActive', type: 'boolean', enableSorting: true },
                        ]}
                        rows={this.getRows(this.props, this.state)}
                        enableSorting
                        initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                        enablePagination
                        pageHeight={450}
                        pageSizes={[20, 50, 150, 250, 500, 1000]}
                        isActive = {(row : ILotLineView) => row.isActive}
                    />
                </div>
                {(this.getBatchLot(this.props) && !this.getBatchLot(this.props)?.isFinalized) &&
                    <PillButton
                        className={'ml10 pl10 pr10 h35 w300 reducedPillButtonShadow'}
                        text={'Revert Selected Lot Lines'}
                        disabled={this.props.isLoading || this.state.revertLotLines.length < 1}
                        type={'submit'}
                        color={'secondary'}
                        onClick={() => this.onRevertLotLinesClick()}
                    />
                }
                {this.renderTable()}
                <div className='wfill fdr pb20 aife'>
                    <PillButton
                        disabled={this.props.isLoading}
                        className={'mb10 ml20 h35 w250'}
                        text={'Print Batch Report'}
                        color={'secondary'}
                        icon={<GetAppIcon/>}
                        iconPosition={'start'}
                        onClick={() => generateBatchReportPdf(this.state.stocks, this.state.packs, [this.props.batchView], this.state.intakes, this.props.lots)}
                    />
                    <PillButton
                        disabled={this.props.isLoading}
                        className={'mb10 ml20 h35 w250'}
                        text={'Download Batch Report'}
                        color={'secondary'}
                        icon={<GetAppIcon/>}
                        iconPosition={'start'}
                        onClick={this.showDownloadReportPopup}
                    />
                    <div className='flx1 aic'>
                        <div className='flx1 asfe tac'>
                            <PackmanLabel
                                className='fwb'
                                label={'Intakes'}
                                value={(calculateIntakeTotalWeight(this.props.batchView, this.props.lots).toFixed(3) + ' kg (' +  calculateIntakeTotalPercentage(this.props.batchView, this.props.lots).toFixed(2) + '%)') ?? ''}
                            />
                        </div>
                        <div className='flx1 fdc tac'>
                            <PackmanLabel
                                label={'Stock Packed'}
                                value={(getStockPackedTotalWeight([this.props.batchView], this.state.stocks, this.state.packs, this.props.lots).toFixed(3) + ' kg (' + calculatePackedStockTotalPercentage([this.props.batchView], this.state.stocks, this.state.packs, this.props.lots).toFixed(2) + '%)') ?? ''}
                            />
                            {(getReturnedTotalWeight([this.props.batchView], this.props.lots) > 0 && getReturnedPercentage([this.props.batchView], this.props.lots) > 0) &&
                                <PackmanLabel
                                    label={'Returned'}
                                    value={(getReturnedTotalWeight([this.props.batchView], this.props.lots).toFixed(3) + ' kg (' + getReturnedPercentage([this.props.batchView], this.props.lots).toFixed(2) + '%)') ?? ''}
                                />
                            }
                            <PackmanLabel
                                label={'Waste'}
                                value={(calculateTotalByLotType([this.props.batchView.lotId], CONSTANT_LOT_TYPE_GUIDS[1], 'nett', false, this.props.lots).toFixed(3) + ' kg (' + calculateWastePercentage([this.props.batchView], this.props.lots).toFixed(2) + '%)') ?? ''}
                            />
                            <PackmanLabel
                                className='fwb'
                                label={'Total'}
                                value={(getStockPackedAndWasteTotalWeight([this.props.batchView], this.state.stocks, this.state.packs, this.props.lots).toFixed(3) + ' kg (' + calculatePackedStockAndWasteTotalPercentage([this.props.batchView], this.state.stocks, this.state.packs, this.props.lots).toFixed(2) + '%)') ?? ''}
                            />
                        </div>
                        <div className='flx1 asfe tac'>
                            <PackmanLabel
                                className='fwb'
                                label={'Variance'}
                                value={(calculateVariance([this.props.batchView], this.state.stocks, this.state.packs, this.props.lots).toFixed(3) + ' kg (' + calculateVariancePercentage([this.props.batchView], this.state.stocks, this.state.packs, this.props.lots).toFixed(2) + '%)') ?? ''}
                            />
                        </div>
                    </div>
                    <div className='fdr ml10'>
                        <Button
                            className={'fwb h35 pt10'}
                            variant='text'
                            color='primary'
                            onClick={this.props.onEndCancelled}>
                            Cancel
                        </Button>
                        <CustomTooltip title={this.getBatchEndButtonTooltipTitle(this.props)}>
                            <PillButton
                                className={'pl10 pr10 h35 w140 mr20 ml10'}
                                fontSize={14}
                                text={`${!!this.props.batchView.endedOn ? 'Resume' : 'End'} Batch`}
                                type={'submit'}
                                color={'secondary'}
                                disabled={this.props.isLoading || !this.props.batchView.isActive || batchLot?.isFinalized}
                                onClick={() => this.props.endBatchPressed(this.props.batchView)}
                            />
                        </CustomTooltip>
                        {!!hasBatchLotFinalizeRight &&
                            <CustomTooltip title={`${this.props.batchView.isActive && !this.props.batchView.endedOn
                                ? 'Cannot finalize lot of a batch that has not been ended.'
                                : (!this.props.batchView.isActive)
                                    ? 'Cannot finalize lot of an inactive batch.'
                                    : ''}`}>
                                <PillButton
                                    text={'Finalize Batch Lot'}
                                    className={'pl30 pr30 h35 mr20'}
                                    disabled={this.props.isLoading || !this.props.batchView.endedOn || !this.props.batchView.isActive || batchLot?.isFinalized}
                                    onClick={this.onBatchLotFinalize}
                                    color={'secondary'}
                                    size={'small'}
                                ></PillButton>
                            </CustomTooltip>
                        }
                    </div>
                </div>
                {!!this.state.isAddOrchardsDialogOpen &&
                    <PackmanDialog
                        title='Add Orchards'
                        isInfo
                        maxWidth={'sm'}
                        fullWidth
                        isLoading={this.props.isLoading}
                        isOpen={this.state.isAddOrchardsDialogOpen}
                        onClose={this.closeAddOrchardsDialog}
                    >
                        <div className='p10'>
                            <AutocompleteSelect
                                name={'orchards'}
                                label={'Orchards'}
                                options={this.getOrchardOptions(this.props)}
                                value={this.state.selectedOrchards}
                                isMulti
                                onChange={this.setSelectedOrchards} />
                            <div className={'fdr ml10 ais jcfe pt20 pb10'}>
                                <Button
                                    className={'fwb h35'}
                                    variant='text' color='primary'
                                    onClick={this.closeAddOrchardsDialog}
                                    type={'reset'}>
                                    Cancel
                                </Button>
                                <PillButton
                                    disabled={this.props.isLoading}
                                    className={'ml15 pl20 pr20 h35'}
                                    text={'Submit'}
                                    onClick={this.submitAddOrchards}
                                    type={'submit'}
                                    color={'secondary'}
                                />
                            </div>
                        </div>
                    </PackmanDialog >}
                {!!this.state.isAddDepartmentsDialogOpen &&
                    <PackmanDialog
                        title='Add Departments'
                        isInfo
                        maxWidth={'sm'}
                        fullWidth
                        isLoading={this.props.isLoading}
                        isOpen={this.state.isAddDepartmentsDialogOpen}
                        onClose={this.closeAddDepartmentsDialog}
                    >
                        <div className='p10'>
                            <AutocompleteSelect
                                name={'orchards'}
                                label={'Departments'}
                                options={this.getDepartmentOptions(this.props)}
                                value={this.state.selectedDepartments}
                                isMulti
                                onChange={this.setSelectedDepartments} />
                            <div className={'fdr ml10 ais jcfe pt20 pb10'}>
                                <Button
                                    className={'fwb h35'}
                                    variant='text' color='primary'
                                    onClick={this.closeAddDepartmentsDialog}
                                    type={'reset'}>
                                    Cancel
                                </Button>
                                <PillButton
                                    disabled={this.props.isLoading}
                                    className={'ml15 pl20 pr20 h35'}
                                    text={'Submit'}
                                    onClick={this.submitAddDepartments}
                                    type={'submit'}
                                    color={'secondary'}
                                />
                            </div>
                        </div>
                    </PackmanDialog >}
                {!!this.state.isDownloadReportFormOpen &&
                    <PackmanDialog
                        title='Download Report File'
                        isInfo
                        isLoading={this.props.isLoading}
                        isOpen={this.state.isDownloadReportFormOpen}
                        onClose={this.closeDownloadReportPopup}>
                        <Formik
                            initialValues={this.generateReportForm()}
                            isInitialValid={ReportFileFormValues.formSchema.isValidSync(this.generateReportForm())}
                            onSubmit={this.downloadReportFile}
                            onReset={this.onReportFileReset}
                            enableReinitialize
                            validationSchema={ReportFileFormValues.formSchema}
                            component={ReportFileForm} />
                    </PackmanDialog >}
                {/* Edit lot line noOfunits and grossWeight */}
                <PackmanDialog
                    title={`Lot Line ${this.state.editingLotLine?.id}`}
                    isInfo
                    maxWidth={'md'}
                    isOpen={this.state.showDialog}
                    onClose={this.cancelEdit}>
                    <div className={'fdc p20'}>
                        <div className={'fdr'}>
                            <div className={'fdc aic'}>
                                <div className={'fdr aic jcc pb5'}>
                                    <div>{'From lot:'}</div>
                                    <div className={'fw600 ml10'}>{`${this.state.editingLotLine && this.state.editingLotLine?.fromLotLineId ? this.getLotLineLotName(this.state.editingLotLine?.fromLotLineId) : ''}`}</div>
                                </div>
                                <Table className= {'PaperBorder'}>
                                    {/* Headings */}
                                    <TableRow style={{ width: 455, height: 30 }} className={'fdr aic bcTableHead'}>
                                        <TableCell style={{ width: 455, height: 30 }} className={'jcfs aic jcc p0 bcTableHead h23 cw'}>
                                            <div style={{ width: 110, minWidth: 110, maxWidth: 110 }} className={'aic jcc p0 m0'}>Id</div>
                                            <div style={{ width: 110, minWidth: 110, maxWidth: 110 }} className={'aic jcc p0 m0'}>No. Units(ea)</div>
                                            <div style={{ width: 110, minWidth: 110, maxWidth: 110 }} className={'aic jcc p0 m0'}>Gross Weight(kg)</div>
                                            <div style={{ width: 110, minWidth: 110, maxWidth: 110 }} className={'aic jcc p0 m0'}>Nett Weight(kg)</div>
                                        </TableCell>
                                    </TableRow>
                                    {/* body */}
                                    {
                                        <WindowList
                                            itemCount={this.getFromLotLotLines(this.props, this.state).length}
                                            width={455}
                                            height={200}
                                            itemSize={40}
                                            itemData={this.getFromLotLotLines(this.props, this.state)}
                                        >
                                            {this.renderListElement}
                                        </WindowList>
                                    }
                                </Table>
                            </div>
                            <div className={'w10'}/>
                            <div className={'fdc aic'}>
                                <div className={'fdr aic jcc pb5'}>
                                    <div>{'To lot:'}</div>
                                    <div className={'fw600 ml10'}>{`${this.state.editingLotLine ? this.getLotLineLotName(this.state.editingLotLine?.id) : ''}`}</div>
                                </div>
                                <Table className= {'PaperBorder'}>
                                    {/* Headings */}
                                    <TableRow style={{ width: 455, height: 30 }} className={'fdr aic bcTableHead'}>
                                        <TableCell style={{ width: 455, height: 30 }} className={'jcfs aic jcc p0 bcTableHead h23 cw'}>
                                            <div style={{ width: 110, minWidth: 110, maxWidth: 110 }} className={'aic jcc p0 m0'}>Id</div>
                                            <div style={{ width: 110, minWidth: 110, maxWidth: 110 }} className={'aic jcc p0 m0'}>No. Units(ea)</div>
                                            <div style={{ width: 110, minWidth: 110, maxWidth: 110 }} className={'aic jcc p0 m0'}>Gross Weight(kg)</div>
                                            <div style={{ width: 110, minWidth: 110, maxWidth: 110 }} className={'aic jcc p0 m0'}>Nett Weight(kg)</div>
                                        </TableCell>
                                    </TableRow>
                                    {/* body */}
                                    {
                                        <WindowList
                                            itemCount={this.getRows(this.props, this.state).length}
                                            width={450}
                                            height={200}
                                            itemSize={40}
                                            itemData={this.getRows(this.props, this.state)}
                                        >
                                            {this.renderListElement}
                                        </WindowList>
                                    }
                                </Table>
                            </div>
                        </div>
                        <div className={'fdr aic jcc pb5'}>
                            <TextField
                                className={'w250'}
                                label={'New No. Of Units'}
                                value={this.state.newLotLineNoOfUnits}
                                type={'number'}
                                onChange={(e : React.ChangeEvent<HTMLInputElement>) => this.setState({ newLotLineNoOfUnits: Number(e.target.value) })}
                            />
                            <div className={'w10'}/>
                            <TextField
                                className={'w250'}
                                label={'New Gross Weight of tipped lot line'}
                                value={this.state.newLotLineWeight}
                                type={'number'}
                                onChange={(e : React.ChangeEvent<HTMLInputElement>) => this.setState({ newLotLineWeight: Number(e.target.value) })}
                            />
                        </div>
                        <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                            <Button
                                className={'fwb h35'}
                                variant='text' color='primary'
                                onClick={this.cancelEdit}
                            >
                                Clear
                            </Button>
                            <PillButton
                                className={'ml15 pl20 pr20 h35'}
                                text={'Save'}
                                color={'secondary'}
                                onClick={this.openConfirmLotLineEditSubmit}
                            />
                        </div>
                    </div>
                </PackmanDialog>
                {/* Edit lot line grossWeight */}
                <PackmanDialog
                    title={`Lot Line ${this.state.editingLotLine?.id}`}
                    isInfo
                    isOpen={this.state.editLotLineWeight}
                    onClose={this.cancelWeightChangeEdit}>
                    <div className={'fdc p20'}>
                        <div className={'fdr aic jcc pb5'}>{'This lot line may have had an incorrect weight tipped \n'}</div>
                        <div className={'fdr aic jcc pb5'}>{`Current Weight: ${this.state.editingLotLine ? this.state.editingLotLine.grossWeight : ''} kg`}</div>
                        <div className={'fdr aic jcc pb5'}>
                            <TextField
                                className={'w250'}
                                label={'New Weight'}
                                value={this.state.newLotLineWeight}
                                type={'number'}
                                onChange={(e : React.ChangeEvent<HTMLInputElement>) => this.setState({ newLotLineWeight: Number(e.target.value) })}
                            />
                        </div>
                        <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                            <Button
                                className={'fwb h35'}
                                variant='text' color='primary'
                                onClick={this.cancelWeightChangeEdit}
                            >
                                Clear
                            </Button>
                            <PillButton
                                className={'ml15 pl20 pr20 h35'}
                                text={'Save'}
                                color={'secondary'}
                                onClick={this.EditLotLineWeight}
                            />
                        </div>
                    </div>
                </PackmanDialog>
                {/* Finalize Batch Lot Dialog */}
                <PackmanDialog
                    title='Finalize Batch Lot'
                    isInfo={true}
                    isOpen={this.state.isFinalizeBatchLotDialogOpen}
                    onClose={this.closeBatchLotFinalizeDialog}>
                    {
                        (batchLot?.nettWeight === 0 && batchLot?.grossWeight === 0) ?
                            <div className={'fdc h90 jcsb'}>
                                <Typography className={'pl25 pt5'}>Are you sure you want to finalize this lot?</Typography>
                                <div className={'fdr jcfe pr30 pb10'}>
                                    <Button
                                        className={'fwb mr5 h35'}
                                        variant='text'
                                        color='primary'
                                        onClick={this.closeBatchLotFinalizeDialog}>
                                    Cancel
                                    </Button>
                                    <PillButton
                                        text={'Ok'}
                                        className={'pl30 pr30 h35 cpd'}
                                        onClick={this.submitFinalizeBatchLot}
                                        color={'secondary'}
                                        size={'small'}
                                    ></PillButton>
                                </div>
                            </div>
                            :
                            <div>
                                <Typography className={'pl25 pt5 pr15 pb10'}>Are you sure you want to make the following adjustments and finalize this lot?</Typography>
                                {batchLot && batchLot?.grossWeight !== 0 && this.getLotLotTypeGuid(batchLot.id) === CONSTANT_LOT_TYPE_GUIDS[2] &&
                                <div className={'fdr pl25 pt5'}>
                                    Initial Gross Weight :
                                    {
                                        <div className={'fdr'}>
                                            <div className={'w20'}/>
                                            {`  + ${Math.abs(Number(batchLot.lotLines.map(x => x.grossWeight).reverse().pop()))} kg`}
                                        </div>
                                    }
                                </div>
                                }
                                {batchLot && batchLot?.nettWeight !== 0 && this.getLotLotTypeGuid(batchLot.id) === CONSTANT_LOT_TYPE_GUIDS[2] &&
                                <div className={'fdr pl25'}>
                                    Initial Nett Weight :
                                    {
                                        <div className={'fdr'}>
                                            <div className={'w20'}/>
                                            {`  + ${Math.abs(Number(batchLot.lotLines.map(x => x.nettWeight).reverse().pop()))} kg`}
                                        </div>
                                    }
                                </div>
                                }
                                {batchLot && batchLot?.grossWeight !== 0 &&
                                <div className={'fdr pl25 pt5'}>
                                    Current Gross Weight :
                                    {
                                        batchLot?.grossWeight > 0
                                            ?   <div className={'fdr'}>
                                                <div className={'w20'}/>
                                                {`  + ${Math.abs(batchLot?.grossWeight)} kg`}
                                            </div>
                                            :   <div className={'fdr'}>
                                                <div className={'w20'}/>
                                                {`  - ${Math.abs(batchLot?.grossWeight)} kg`}
                                            </div>
                                    }
                                </div>
                                }
                                {batchLot && batchLot?.nettWeight !== 0 &&
                                <div className={'fdr pl25'}>
                                    Current Nett Weight :
                                    {
                                        batchLot?.grossWeight > 0
                                            ?   <div className={'fdr'}>
                                                <div className={'w20'}/>
                                                {`  + ${Math.abs(batchLot?.nettWeight)} kg`}
                                            </div>
                                            :   <div className={'fdr'}>
                                                <div className={'w20'}/>
                                                {`  - ${Math.abs(batchLot?.nettWeight)} kg`}
                                            </div>
                                    }
                                </div>
                                }
                                { batchLot && batchLot?.grossWeight !== 0 &&
                                <div className={'fdr pl25 pt5'}>
                                    Adjustment Gross Weight :
                                    {batchLot?.grossWeight > 0 ?
                                        <div className={'fdr cred'}>
                                            <div className={'w20'}/>
                                            {`  - ${batchLot?.grossWeight} kg`}
                                        </div>
                                        :
                                        <div className={'fdr cp'}>
                                            <div className={'w20'}/>
                                            {`  + ${Math.abs(batchLot?.grossWeight)} kg`}
                                        </div>
                                    }
                                </div>
                                }
                                {batchLot && batchLot?.nettWeight !== 0 &&
                                <div className={'fdr pl25'}>
                                    Adjustment Nett Weight :
                                    {batchLot?.nettWeight > 0 ?
                                        <div className={'fdr cred'}>
                                            <div className={'w20'}/>
                                            {`- ${batchLot?.nettWeight} kg`}
                                        </div>
                                        :
                                        <div className={'fdr cp'}>
                                            <div className={'w20'}/>
                                            {`  + ${Math.abs(batchLot?.nettWeight)} kg`}
                                        </div>
                                    }
                                </div>
                                }
                                <div className={'fdr jcfe pr30 pb10'}>
                                    <Button
                                        className={'fwb mr5 h35'}
                                        variant='text'
                                        color='primary'
                                        onClick={this.closeBatchLotFinalizeDialog}>
                                    Cancel
                                    </Button>
                                    <PillButton
                                        text={'Ok'}
                                        className={'pl30 pr30 h35 cpd'}
                                        onClick={this.submitFinalizeBatchLot}
                                        color={'secondary'}
                                        size={'small'}
                                    ></PillButton>
                                </div>
                            </div>
                    }
                </PackmanDialog>
                <ConfirmationPrompt isLoading={this.props.isLoading} open={this.state.showConfirmationPrompt} message={'Are you sure you want to revert the selected lot lines' }
                    onOkClicked={this.submitRevertLotLines} onCancelClicked={this.onRevertLotLineCancel} disableYes={this.props.isLoading} disableNo={this.props.isLoading}/>
                <ConfirmationPrompt isLoading={this.props.isLoading} open={this.state.showEditLotLineConfirmation} message={'You are about to change the tipped lot line No. Of Units and Gross Weight \n which will also update the selected lot line values.\n Are you Sure?' }
                    onOkClicked={this.submitEdit} onCancelClicked={this.closeConfirmLotLineEditSubmit} disableYes={this.props.isLoading} disableNo={this.props.isLoading}/>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        lots: state.data.lots,
        lotTypes: state.masterData.lotTypes,
        organizations: state.masterData.organizations,
        sites: state.masterData.sites,
        printServers: state.masterData.printServers,
        reports: state.masterData.reports,
        selectedSiteIds: state.data.selectedSiteIds,
        farms: state.masterData.farms,
        varieties: state.masterData.varieties,
        orchards: state.masterData.orchards,
        departments: state.masterData.departments,
        commodities: state.masterData.commodities,
        commodityStates: state.masterData.commodityStates,
        packs: state.masterData.packs,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    {}, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BatchSummaryPopup);
