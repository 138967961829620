import * as React from 'react';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetRegions } from '../../../store/masterData/Actions';
import { formatDateTime, isNullOrWhiteSpace, upsertArrayElement } from '../../../services/appFunctionsService';
import { IOrganization } from '../../../@types/model/masterData/organization/organization';
import { IRegion, Region } from '../../../@types/model/masterData/region/region';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import RegionHttpService from '../../../services/http/masterData/regionHttpService';
import Screen from '../../../components/Screen';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikHelpers } from 'formik';
import { RegionFormValues, IRegionFormValues } from '../../../@types/model/masterData/region/regionFormValues';
import RegionForm from './form/RegionForm';
import { createSelector } from 'reselect';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import { ConfirmationDialog, CustomTable } from '@zz2/zz2-ui';
import { v4 as uuidv4 } from 'uuid';
import { setRegionMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface IRegionScreenProps extends RouteComponentProps {
    dataSetRegions : DispatchCall<Array<IRegion>>;
    regions : Array<IRegion>;
    selectedOrganizationIds : Array<number>;
    organizations : Array<IOrganization>;
    auth : IAuthState;
}

interface IRegionScreenState {
    rows : Array<IRegion>;
    isLoading : boolean;
    selectedRegion ?: IRegion;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    formGuid ?: string;
    isDeletePopupOpen : boolean;
    deletingRegion ?: IRegion;
}

class RegionScreen extends React.Component<IRegionScreenProps, IRegionScreenState> {
    constructor(props : IRegionScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedRegion: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await RegionHttpService.getRegionData();

                this.props.dataSetRegions(res.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading regions.');
                this.setLoading(false);
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    private formatOrganizations = (organizationIds : Array<number>) => this.props.organizations
        .filter(x => organizationIds.some(y => y === x.id))
        .map(x => x.code).toString().replace(/,/g, ', ');

    private getRows = () => this.props.regions.filter(x => x.organizationIds.some(y => this.props.selectedOrganizationIds?.some(z => z === y)));

    public onSubmit = async (values : IRegionFormValues) => {
        this.setLoading(true);

        let payload = { ...values };
        if (isNullOrWhiteSpace(payload.guid)) {
            payload.guid = this.state.formGuid ?? '';
        }

        try {
            const res = await RegionHttpService.addOrUpdateRegion(new Region(payload));

            const newRegionList = upsertArrayElement(this.props.regions, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetRegions(newRegionList);
            await setRegionMasterDataIndexedDB(newRegionList);

            if (this.state.selectedRegion) {
                generalShowSuccessSnackbar('Region updated successfully.');
            } else {
                generalShowSuccessSnackbar('Region added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating region data.');
        } finally {
            this.setLoading(false);
        }
    };

    public onReset = async (formValues : IRegionFormValues, formikHelpers : FormikHelpers<IRegionFormValues>) => {
        formikHelpers.resetForm();
        this.closeDialog();
    };

    private openDeleteConfirmationPopup = (region : IRegion) => {
        this.setState({ isDeletePopupOpen: true, deletingRegion: region });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingRegion: undefined });
    };

    private removeRegion = async () => {
        const newRegion = this.state.deletingRegion;
        if (newRegion) {
            newRegion.isActive = false;
            this.setLoading(true);

            try {
                const res = await RegionHttpService.deleteRegion(newRegion.id);

                const newRegionList = upsertArrayElement(this.props.regions, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetRegions(newRegionList);
                await setRegionMasterDataIndexedDB(newRegionList);

                generalShowSuccessSnackbar('Region updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting region.');
                newRegion.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    public getSelectedRegion = (props : IRegionScreenProps, state : IRegionScreenState) => state.selectedRegion;
    public getOrganizations = (props : IRegionScreenProps) => props.organizations;

    public getInitialFormValues = createSelector(
        [this.getSelectedRegion, this.getOrganizations],
        (region : IRegion, organizations : Array<IOrganization>) => {
            return new RegionFormValues(region, organizations);
        },
    );

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            formGuid: undefined,
            selectedRegion: undefined,
        });
    };

    public openDialog = () => {
        this.setState({
            formGuid: uuidv4(),
            isDialogOpen: true,
        });
    };

    private onRegionEdit = (row : IRegion) => {
        this.setState({
            isDialogOpen: true,
            selectedRegion: row,
        });
    };

    private getRights = (props : IRegionScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IRegionScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.getRows();
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Region'
                    isEdit={!!this.state.selectedRegion}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={RegionFormValues.formSchema}
                        component={RegionForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IRegion>
                            enableAdding={this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.onRegionEdit}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(region : IRegion) => region.isActive && this.hasEditingRight(this.props)}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            fitWidthToPage
                            enableFiltering
                            enablePagination
                            columns={[
                                { title: 'Code', field: 'code', enableFiltering: true, enableSorting: true },
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Organizations', field: 'organizationIds', formatFunction: this.formatOrganizations, width: 200, enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IRegion) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationDialog
                    title={'Delete Region'}
                    open={this.state.isDeletePopupOpen}
                    description ={'Are you sure you want to delete this this region?'}
                    onAccept ={this.removeRegion}
                    onClose ={this.closeDeleteConfirmationPopup}
                    dialogType='orange'
                    isLoading={this.state.isLoading}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        regions: state.masterData.regions,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        organizations: state.masterData.organizations,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetRegions }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RegionScreen);
