import { IBaseModel } from '../../../model';
import { IBrandFormValues } from './brandFormValues';

export interface IBrand extends IBaseModel {
    code : string;
    description : string;
}

export class Brand implements IBrand {
    public id : number;
    public guid : string;
    public createdBy ?: number;
    public createdByName ?: string;
    public createdOn ?: string;
    public updatedBy ?: number;
    public updatedByName ?: string;
    public updatedOn ?: string;
    public isActive : boolean;

    public code : string;
    public description : string;

    public constructor(formValues : IBrandFormValues) {
        this.id = formValues.id;
        this.guid = formValues.guid;
        this.isActive = formValues.isActive;

        this.code = formValues.code;
        this.description = formValues.description;
    }
}
