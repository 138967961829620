import * as React from 'react';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetSizes } from '../../../../store/masterData/Actions';
import { formatDateTime, isNullOrWhiteSpace, upsertArrayElement } from '../../../../services/appFunctionsService';
import { ISize, Size } from '../../../../@types/model/masterData/size/size';
import { ICommodity } from '../../../../@types/model/masterData/commodity/commodity';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../../store/general/Functions';
import SizeHttpService from '../../../../services/http/masterData/sizeHttpService';
import { ISizeFormValues, SizeFormValues } from '../../../../@types/model/masterData/size/sizeFormValues';
import { FormikHelpers, Formik } from 'formik';
import { createSelector } from 'reselect';
import PackmanDialog from '../../../../components/dialog/PackmanDialog';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../../@types/model/user/right';
import SizeForm from './form/SizeForm';
import PackRelatedDataHttpService from '../../../../services/http/masterData/packRelatedDataHttpService';
import { dataSetAllPackRelatedData } from '../../../../store/masterData/Functions';
import { IPack } from '../../../../@types/model/masterData/pack/pack';
import { ConfirmationDialog, CustomTable } from '@zz2/zz2-ui';
import { v4 as uuidv4 } from 'uuid';
import { setSizeMasterDataIndexedDB, syncMasterData } from '../../../../services/masterDataSyncService';

interface ISizeScreenProps extends RouteComponentProps {
    dataSetSizes : DispatchCall<Array<ISize>>;
    sizeData : Array<ISize>;
    selectedCommodity ?: ICommodity;
    auth : IAuthState;
    packs : Array<IPack>;
}

interface ISizeScreenState {
    rows : Array<ISize>;
    isLoading : boolean;
    selectedSize ?: ISize;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    formGuid ?: string;
    isDeletePopupOpen : boolean;
    deletingSize ?: ISize;
}

class SizeScreen extends React.Component<ISizeScreenProps, ISizeScreenState> {
    constructor(props : ISizeScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedSize: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await PackRelatedDataHttpService.getAllPackRelatedData();

                dataSetAllPackRelatedData(res.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading sizes.');
                this.setLoading(false);
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading : loading });
    };

    public onSubmit = async (values : ISizeFormValues) => {
        this.setLoading(true);

        let payload = { ...values };
        if (isNullOrWhiteSpace(payload.guid)) {
            payload.guid = this.state.formGuid ?? '';
        }

        try {
            const res = await SizeHttpService.addOrUpdateSize(new Size(payload));

            const newSizeList = upsertArrayElement(this.props.sizeData, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetSizes(newSizeList);
            await setSizeMasterDataIndexedDB(newSizeList);

            if (this.state.selectedSize) {
                generalShowSuccessSnackbar('Size updated successfully.');
            } else {
                generalShowSuccessSnackbar('Size added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating size data.');
        } finally {
            this.setLoading(false);
        }
    };

    public onReset = async (formValues : ISizeFormValues, formikHelpers : FormikHelpers<ISizeFormValues>) => {
        formikHelpers.resetForm();
        this.closeDialog();
    };

    private openDeleteConfirmationPopup = (size : ISize) => {
        this.setState({ isDeletePopupOpen: true, deletingSize: size });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingSize: undefined });
    };

    private removeSize = async () => {
        const newSize = this.state.deletingSize;
        if (newSize) {
            newSize.isActive = false;
            this.setLoading(true);

            try {
                const res = await SizeHttpService.deleteSize(newSize.id);

                const newSizeList = upsertArrayElement(this.props.sizeData, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetSizes(newSizeList);
                await setSizeMasterDataIndexedDB(newSizeList);

                generalShowSuccessSnackbar('Size updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting size.');
                newSize.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    public getPacks = (props : ISizeScreenProps) => props.packs;
    public getSizes = (props : ISizeScreenProps) => props.sizeData;
    public getSelectedCommodity = (props : ISizeScreenProps) => props.selectedCommodity;

    public getSelectedSize = (props : ISizeScreenProps, state : ISizeScreenState) => state.selectedSize;

    public getInitialFormValues = createSelector(
        [this.getSelectedSize, this.getPacks],
        (size : ISize, packs : Array<IPack>) => {
            return new SizeFormValues(size, packs);
        },
    );

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            formGuid: undefined,
            selectedSize: undefined,
        });
    };

    public openDialog = () => {
        this.setState({
            formGuid: uuidv4(),
            isDialogOpen: true,
        });
    };

    private onSizeEdit = (row : ISize) => {
        this.setState({
            isDialogOpen: true,
            selectedSize: row,
        });
    };

    private getRows = createSelector(
        [this.getSizes, this.getPacks, this.getSelectedCommodity],
        (sizes : Array<ISize>, packs : Array<IPack>, selectedCommodity ?: ICommodity) => {
            if (!!selectedCommodity) {
                const filteredPacks = packs.filter(x => x.commodityId === selectedCommodity.id);

                return sizes.filter(x => x.packIds.some(y => filteredPacks.some(z => z.id === y)));
            } else {
                return sizes;
            }
        },
    );

    private getRights = (props : ISizeScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : ISizeScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.getRows(this.props);
        const initialValues = this.getInitialFormValues(this.props, this.state);

        return (
            <>
                <PackmanDialog
                    title={'Size' + (this.props.selectedCommodity ? ' - (' + this.props.selectedCommodity?.code + ')' : '')}
                    isEdit={!!this.state.selectedSize}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={SizeFormValues.formSchema}
                        component={SizeForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<ISize>
                            enableAdding={!!this.props.selectedCommodity && this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.onSizeEdit}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(size : ISize) => size.isActive && this.hasEditingRight(this.props)}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            enablePagination
                            columns={[
                                { title: 'Code', field: 'code', enableFiltering: true, enableSorting: true },
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            fitWidthToPage
                            initialSortOrder={[{ columnName: 'id_Id', direction : 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={340}
                            isActive={(row : ISize) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationDialog
                    title={'Delete Size'}
                    open={this.state.isDeletePopupOpen}
                    description={'Are you sure you want to delete this size?'}
                    onAccept ={this.removeSize}
                    onClose ={this.closeDeleteConfirmationPopup}
                    dialogType='orange'
                    isLoading={this.state.isLoading}
                />
            </>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        sizeData: state.masterData.sizes,
        commodities: state.masterData.commodities,
        packs: state.masterData.packs,
        auth: state.auth,
    };
};

const mapDispatchToProps  = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetSizes }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SizeScreen);
