import * as React from 'react';
import { Card } from '@mui/material';
import { DispatchCall, IAuthState, IRootState, RootAction } from '../../../@types/redux';
import { dataSetOutlets, dataSetPackLines, dataSetPrintServers, dataSetPrinters, dataSetReports, dataSetInventories } from '../../../store/masterData/Actions';
import { bindActionCreators, Dispatch } from 'redux';
import { formatDateTime, isNullOrWhiteSpace, upsertArrayElement } from '../../../services/appFunctionsService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import Screen from '../../../components/Screen';
import { connect } from 'react-redux';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikHelpers } from 'formik';
import { createSelector } from 'reselect';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import { ConfirmationDialog, CustomTable } from '@zz2/zz2-ui';
import { v4 as uuidv4 } from 'uuid';
import { IOutlet, Outlet } from '../../../@types/model/masterData/outlet/outlet';
import { IPackLine } from '../../../@types/model/masterData/packLine/packLine';
import OutletHttpService from '../../../services/http/masterData/outletHttpService';
import PackLineHttpService from '../../../services/http/masterData/packLineHttpService';
import { IOutletFormValues, OutletFormValues } from '../../../@types/model/masterData/outlet/outletFormValues';
import OutletForm from './form/OutletForm';
import { IPrintServer } from '../../../@types/model/masterData/printServer/printServer';
import { IPrinter } from '../../../@types/model/masterData/printer/printer';
import { IReport } from '../../../@types/model/masterData/report/report';
import { ISite } from '../../../@types/model/masterData/site/site';
import PrintServerHttpService from '../../../services/http/masterData/printServerHttpService';
import PrinterHttpService from '../../../services/http/masterData/printerHttpService';
import ReportHttpService from '../../../services/http/masterData/reportHttpService';
import { IInventory } from '../../../@types/model/masterData/inventory/inventory';
import InventoryHttpService from '../../../services/http/masterData/inventoryHttpService';
import { setOutletMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface IOutletScreenProps extends RouteComponentProps {
    dataSetOutlets : DispatchCall<Array<IOutlet>>;
    dataSetPackLines : DispatchCall<Array<IPackLine>>;
    dataSetPrintServers : DispatchCall<Array<IPrintServer>>;
    dataSetPrinters : DispatchCall<Array<IPrinter>>;
    dataSetReports : DispatchCall<Array<IReport>>;
    dataSetInventories : DispatchCall<Array<IInventory>>;
    outletData : Array<IOutlet>;
    packLines : Array<IPackLine>;
    sites : Array<ISite>;
    printServers : Array<IPrintServer>;
    printers : Array<IPrinter>;
    reports : Array<IReport>;
    inventories : Array<IInventory>;
    auth : IAuthState;
}

interface IOutletScreenState {
    rows : Array<IOutlet>;
    isLoading : boolean;
    selectedOutlet ?: IOutlet;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    formGuid ?: string;
    isDeletePopupOpen : boolean;
    deletingOutlet ?: IOutlet;
}

class OutletScreen extends React.Component<IOutletScreenProps, IOutletScreenState> {
    constructor(props : IOutletScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedOutlet: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res1 = await OutletHttpService.getOutletData();

                const outletData = res1.data;
                if (outletData) {
                    const res2 = await PackLineHttpService.getPackLineData();
                    const res3 = await PrintServerHttpService.getPrintServerData();
                    const res4 = await PrinterHttpService.getPrinterData();
                    const res5 = await ReportHttpService.getReportData();
                    const res6 = await InventoryHttpService.getInventoryData();

                    const packLineData = res2.data;
                    const printServerData = res3.data;
                    const printerData = res4.data;
                    const reportData = res5.data;
                    const inventoryData = res6.data;
                    this.props.dataSetPackLines(packLineData);
                    this.props.dataSetPrintServers(printServerData);
                    this.props.dataSetPrinters(printerData);
                    this.props.dataSetReports(reportData);
                    this.props.dataSetInventories(inventoryData);
                    this.props.dataSetOutlets(outletData);
                }

                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading outlets.');
                this.setLoading(false);
            }
        }
    };

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            formGuid: undefined,
            selectedOutlet: undefined,
        });
    };

    public openDialog = () => {
        this.setState({
            formGuid: uuidv4(),
            isDialogOpen: true,
        });
    };

    public editOutlet = (selectedOutlet : IOutlet) => {
        this.setState({
            isDialogOpen: true,
            selectedOutlet,
        });
        this.openDialog();
    };

    public onSubmit = async (values : IOutletFormValues) => {
        this.setLoading(true);

        let payload = { ...values };
        if (isNullOrWhiteSpace(payload.guid)) {
            payload.guid = this.state.formGuid ?? '';
        }

        try {
            const res = await OutletHttpService.addOrUpdateOutlet(new Outlet(payload));

            const newOutLetList = upsertArrayElement(this.props.outletData, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetOutlets(newOutLetList);
            await setOutletMasterDataIndexedDB(newOutLetList);

            if (this.state.selectedOutlet) {
                generalShowSuccessSnackbar('Outlet updated successfully.');
            } else {
                generalShowSuccessSnackbar('Outlet added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating outlet data.');
        } finally {
            this.setLoading(false);
        }
    };

    private openDeleteConfirmationPopup = (outlet : IOutlet) => {
        this.setState({ isDeletePopupOpen: true, deletingOutlet: outlet });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingOutlet: undefined });
    };

    private removeOutlet = async () => {
        const newOutlet = this.state.deletingOutlet;
        if (newOutlet) {
            newOutlet.isActive = false;
            this.setLoading(true);

            try {
                const res = await OutletHttpService.deleteOutlet(newOutlet.id);

                const newOutletList = upsertArrayElement(this.props.outletData, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetOutlets(newOutletList);
                await setOutletMasterDataIndexedDB(newOutletList);

                generalShowSuccessSnackbar('Outlet updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting outlet.');
                newOutlet.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    public onReset = async (formValues : IOutletFormValues, formikHelpers : FormikHelpers<IOutletFormValues>) => {
        formikHelpers.resetForm();
        this.closeDialog();
    };

    public getPackLines = (props : IOutletScreenProps) => props.packLines;
    public getPrintServers = (props : IOutletScreenProps) => props.printServers;
    public getprinters = (props : IOutletScreenProps) => props.printers;
    public getReports = (props : IOutletScreenProps) => props.reports;
    public getSites = (props : IOutletScreenProps) => props.sites;
    public getInventories = (props : IOutletScreenProps) => props.inventories;
    public getSelectedOutlet = (props : IOutletScreenProps, state : IOutletScreenState) => state.selectedOutlet;

    public getInitialFormValues = createSelector(
        [this.getSelectedOutlet, this.getPackLines, this.getPrintServers, this.getprinters, this.getReports, this.getSites, this.getInventories],
        (outlet : IOutlet, packLines : Array<IPackLine>, printServers : Array<IPrintServer>, printers : Array<IPrinter>, reports : Array<IReport>, sites : Array<ISite>, inventories : Array<IInventory>) => {
            return new OutletFormValues(outlet, packLines, printServers, printers, reports, sites, inventories);
        },
    );

    private getPackLineCode = (packLineId : number) => {
        const packLine = this.props.packLines?.find(x => x.id === packLineId);
        return packLine?.code ?? '';
    };

    private getPrintServerName = (printServerId : number) => {
        const printServer = this.props.printServers?.find(x => x.id === printServerId);
        return printServer?.name ?? '';
    };

    private getPrinterName = (printerId : number) => {
        const printer = this.props.printers?.find(x => x.id === printerId);
        return printer?.name ?? '';
    };

    private getReportName = (reportId : number) => {
        const report = this.props.reports?.find(x => x.id === reportId);
        return report?.name ?? '';
    };

    private getSiteShortDescription = (siteId : number) => {
        const site = this.props.sites?.find(x => x.id === siteId);
        return site?.shortDescription ?? '';
    };

    private getInventoryDescription = (inventoryId : number) => {
        const inventory = this.props.inventories?.find(x => x.id === inventoryId);
        return inventory?.description ?? '';
    };

    private getRights = (props : IOutletScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IOutletScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.props.outletData ? this.props.outletData : [];
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Outlet'
                    maxWidth={'xs'}
                    fullWidth
                    isEdit={!!this.state.selectedOutlet}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={OutletFormValues.formSchema}
                        component={OutletForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IOutlet>
                            enableAdding={this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.editOutlet}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(outlet : IOutlet) => outlet.isActive && this.hasEditingRight(this.props)}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            fitWidthToPage
                            enablePagination
                            columns={[
                                { title: 'Code', field: 'code', enableFiltering: true, enableSorting: true },
                                { title: 'Device MAC Address', field: 'deviceMacAddress', enableFiltering: true, enableSorting: true },
                                { title: 'Pack Line', field: 'packLineId', formatFunction: this.getPackLineCode, enableFiltering: true, enableSorting: true },
                                { title: 'Default Print Server', field: 'defaultPrintServerId', formatFunction: this.getPrintServerName, enableFiltering: true, enableSorting: true },
                                { title: 'Default Printer', field: 'defaultPrinterId', formatFunction: this.getPrinterName, width: 200, enableFiltering: true, enableSorting: true },
                                { title: 'Default Report', field: 'defaultReportId', formatFunction: this.getReportName, width: 200, enableFiltering: true, enableSorting: true },
                                { title: 'Default Exporter Site', field: 'defaultExporterSiteId', formatFunction: this.getSiteShortDescription, enableFiltering: true, enableSorting: true },
                                { title: 'Default Inventory', field: 'defaultInventoryId', formatFunction: this.getInventoryDescription, enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IOutlet) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationDialog
                    title={'Delete Outlet'}
                    open={this.state.isDeletePopupOpen}
                    description={'Are you sure you want to delete this outlet?'}
                    onAccept ={this.removeOutlet}
                    onClose ={this.closeDeleteConfirmationPopup}
                    dialogType='orange'
                    isLoading={this.state.isLoading}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        outletData: state.masterData.outlets,
        packLines: state.masterData.packLines,
        printServers: state.masterData.printServers,
        printers: state.masterData.printers,
        reports: state.masterData.reports,
        sites: state.masterData.sites,
        inventories: state.masterData.inventories,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetOutlets, dataSetPackLines, dataSetPrintServers, dataSetPrinters, dataSetReports, dataSetInventories }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OutletScreen);
