import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../../appConstants';
import { IPOFileEmail } from '../../../../@types/model/dispatch/poFileEmail';
import { IPIFileEmail } from '../../../../@types/model/compliance/piFileEmail';

export default class EmailHttpService {
    // email PO file to CORE
    public static emailPOFile(data : IPOFileEmail) : AxiosPromise<boolean> {
        return axios.post(`${PACKMAN_API_URL}Dispatch/EmailPOFile`, data);
    }

    // email PI file to CORE
    public static emailPIFile(data : IPIFileEmail) : AxiosPromise<boolean> {
        return axios.post(`${PACKMAN_API_URL}Compliance/EmailPIFile`, data);
    }
}
