import * as React from 'react';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetBarcodeRanges } from '../../../store/masterData/Actions';
import { formatDateTime, isNullOrWhiteSpace, upsertArrayElement } from '../../../services/appFunctionsService';
import { IBarcodeRange, BarcodeRange } from '../../../@types/model/masterData/barcodeRange/barcodeRange';
import BarcodeRangeHttpService from '../../../services/http/masterData/barcodeRangeHttpService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import Screen from '../../../components/Screen';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikHelpers } from 'formik';
import BarcodeRangeForm from './form/BarcodeRangeForm';
import { BarcodeRangeFormValues, IBarcodeRangeFormValues } from '../../../@types/model/masterData/barcodeRange/barcodeRangeFormValues';
import { createSelector } from 'reselect';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import { ISite } from '../../../@types/model/masterData/site/site';
import { v4 as uuidv4 } from 'uuid';
import { setBarcodeRangeMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';
import { ConfirmationDialog, CustomTable } from '@zz2/zz2-ui';

interface IBarcodeRangeScreenProps extends RouteComponentProps {
    dataSetBarcodeRanges : DispatchCall<Array<IBarcodeRange>>;
    barcodeRangeData : Array<IBarcodeRange>;
    sites : Array<ISite>;
    auth : IAuthState;
}

interface IBarcodeRangeScreenState {
    rows : Array<IBarcodeRange>;
    isLoading : boolean;
    selectedBarcodeRange ?: IBarcodeRange;
    isDialogOpen : boolean;
    formGuid ?: string;
    isDeletePopupOpen : boolean;
    deletingBarcodeRange ?: IBarcodeRange;
}

class BarcodeRangeScreen extends React.Component<IBarcodeRangeScreenProps, IBarcodeRangeScreenState> {
    constructor(props : IBarcodeRangeScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedBarcodeRange: undefined,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);

            try {
                const res = await BarcodeRangeHttpService.getBarcodeRangeData();

                const barcodeRangeData = res.data;
                if (barcodeRangeData) {
                    this.props.dataSetBarcodeRanges(barcodeRangeData);
                } else {
                    this.props.dataSetBarcodeRanges([]);
                }

                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading barcode ranges.');
                this.setLoading(false);
            }
        }
    };

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            formGuid: undefined,
            selectedBarcodeRange: undefined,
        });
    };

    public openDialog = () => {
        this.setState({
            formGuid: uuidv4(),
            isDialogOpen: true,
        });
    };

    public editBarcodeRange = (barcodeRange : IBarcodeRange) => {
        this.setState({
            isDialogOpen: true,
            selectedBarcodeRange: barcodeRange,
        });
        this.openDialog();
    };

    public onSubmit = async (values : IBarcodeRangeFormValues) => {
        this.setLoading(true);

        let payload = { ...values };
        if (isNullOrWhiteSpace(payload.guid)) {
            payload.guid = this.state.formGuid ?? '';
        }

        try {
            const res = await BarcodeRangeHttpService.addOrUpdateBarcodeRange(new BarcodeRange(payload));

            const newBarcodeRangeList = upsertArrayElement(this.props.barcodeRangeData, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetBarcodeRanges(newBarcodeRangeList);
            await setBarcodeRangeMasterDataIndexedDB(newBarcodeRangeList);

            if (this.state.selectedBarcodeRange) {
                generalShowSuccessSnackbar('Barcode range updated successfully.');
            } else {
                generalShowSuccessSnackbar('Barcode range added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar(e?.data?.Message ?? 'An error occurred updating barcode range data.');
        } finally {
            this.setLoading(false);
        }
    };

    private formatSites = (siteIds : Array<number>) => this.props.sites
        .filter(x => siteIds.some(y => y === x.id))
        .map(x => x.shortDescription).toString().replace(/,/g, ', ');

    private openDeleteConfirmationPopup = (barcodeRange : IBarcodeRange) => {
        this.setState({ isDeletePopupOpen: true, deletingBarcodeRange: barcodeRange });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingBarcodeRange: undefined });
    };

    private removeBarcodeRange = async () => {
        const newBarcodeRange = this.state.deletingBarcodeRange;
        if (newBarcodeRange) {
            newBarcodeRange.isActive = false;
            this.setLoading(true);

            try {
                const res = await BarcodeRangeHttpService.deleteBarcodeRange(newBarcodeRange.id);

                const newBarcodeRangeList = upsertArrayElement(this.props.barcodeRangeData, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetBarcodeRanges(newBarcodeRangeList);
                await setBarcodeRangeMasterDataIndexedDB(newBarcodeRangeList);

                generalShowSuccessSnackbar('Barcode range updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting barcode range.');
                newBarcodeRange.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    private getRows = () => this.props.barcodeRangeData;

    public onReset = async (formValues : IBarcodeRangeFormValues, formikHelpers : FormikHelpers<IBarcodeRangeFormValues>) => {
        formikHelpers.resetForm();
        this.closeDialog();
    };

    public getSelectedBarcodeRange = (props : IBarcodeRangeScreenProps, state : IBarcodeRangeScreenState) => state.selectedBarcodeRange;
    public getSites = (props : IBarcodeRangeScreenProps) => props.sites;

    public getInitialFormValues = createSelector(
        [this.getSelectedBarcodeRange, this.getSites],
        (barcodeRange : IBarcodeRange, sites ?: Array<ISite>) => {
            return new BarcodeRangeFormValues(barcodeRange, sites);
        },
    );

    private getRights = (props : IBarcodeRangeScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IBarcodeRangeScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.getRows();
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Barcode Range'
                    isEdit={!!this.state.selectedBarcodeRange}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    maxWidth={'md'}
                    fullWidth
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={BarcodeRangeFormValues.formSchema}
                        component={BarcodeRangeForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IBarcodeRange>
                            enableAdding={this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.editBarcodeRange}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(barcodeRange : IBarcodeRange) => barcodeRange.isActive && this.hasEditingRight(this.props)}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            enablePagination
                            columns={[
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Indicator Digit', field: 'indicatorDigit', enableFiltering: true, enableSorting: true },
                                { title: 'Range Start', field: 'rangeStart', enableFiltering: true, enableSorting: true },
                                { title: 'Range End', field: 'rangeEnd', enableFiltering: true, enableSorting: true },
                                { title: 'Latest', field: 'latest', enableFiltering: true, enableSorting: true },
                                { title: 'Import Code', field: 'importCode', enableFiltering: true, enableSorting: true },
                                { title: 'Amount Remaining', field: 'numRemaining', enableFiltering: true, enableSorting: true },
                                { title: 'Export?', field: 'isExport', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Sites', field: 'siteIds', formatFunction: this.formatSites, width: 200, enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            fitWidthToPage
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IBarcodeRange) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationDialog
                    title={'Delete Barcode Range'}
                    open={this.state.isDeletePopupOpen}
                    description ={'Are you sure you want to delete this barcode range?'}
                    onAccept ={this.removeBarcodeRange}
                    onClose ={this.closeDeleteConfirmationPopup}
                    dialogType='orange'
                    isLoading={this.state.isLoading}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        barcodeRangeData: state.masterData.barcodeRanges,
        sites: state.masterData.sites,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetBarcodeRanges }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BarcodeRangeScreen);
