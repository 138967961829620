import * as React from 'react';
import { addArrayElement, removeArrayElement, formatDateTimeToDateOnly, compareDate, getCSVStockLineSummary, formatDateTime, upsertArrayElement, setArrayElement } from '../../services/appFunctionsService';
import lodash from 'lodash';
import moment from 'moment';
import { Card, Checkbox, Stepper, Step, StepLabel, Dialog, AppBar, Toolbar, Typography, Button, CircularProgress } from '@mui/material';
import { Form } from 'informed';
import validationService from '../../services/validationService';
import CustomAutoSuggest from '../../components/input/CustomAutoSuggest';
import { IDropDownOptions } from '../../@types/other';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { ISite } from '../../@types/model/masterData/site/site';
import { IRootState, IAuthState, DispatchCall, RootAction } from '../../@types/redux';
import { connect } from 'react-redux';
import PillButton from '../../components/input/PillButton';
import CustomTable from '../../components/datagrid/CustomTable';
import { IStock } from '../../@types/model/stock/stock';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar, generalShowWarningSnackbar } from '../../store/general/Functions';
import DispatchHttpService from '../../services/http/dispatch/dispatchHttpService';
import { ICreateInstructedDispatch } from '../../@types/model/dispatch/createInstructedDispatch';
import { DATEPICKER_FORMAT_DEFAULT } from '../../appConstants';
import { ITrip, Trip } from '../../@types/model/dispatch/trip';
import { v1 } from 'uuid';
import { IStockLine } from '../../@types/model/stock/stockLine';
import DateInput from '../../components/input/DateInput';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { createSelector } from 'reselect';
import Screen from '../../components/Screen';
import PopupActionButton from '../../components/button/PopupActionButton';
import { Link, RouteComponentProps } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { dataClearAllDispatchViewRelatedData, dataSetDispatchInstruction, dataSetDispatchWizardStockData, dataSetDispatchWizardFinalStepData, dataSetDispatchWizardMasterDataData, dataSetStock } from '../../store/data/Functions';
import { IDispatchInstruction } from '../../@types/model/dispatch/dispatchInstruction';
import { IFarm } from '../../@types/model/masterData/farm/farm';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { ISize } from '../../@types/model/masterData/size/size';
import { IColour } from '../../@types/model/masterData/colour/colour';
import { IGrade } from '../../@types/model/masterData/grade/grade';
import { ICompliance } from '../../@types/model/compliance/compliance';
import { ICarrier } from '../../@types/model/masterData/carrier/carrier';
import { IMarket } from '../../@types/model/masterData/market/market';
import randomColor from 'randomcolor';
import { getUniqueStockLines } from '../../services/mappingFunctionsService';
import { ITruckType } from '../../@types/model/masterData/truckType/truckType';
import { IPalletBaseType } from '../../@types/model/masterData/palletBaseType/palletBaseType';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { IDispatchInstructionLine } from '../../@types/model/dispatch/dispatchInstructionLine';
import { ITripFormValues, TripFormValues } from '../../@types/model/dispatch/tripFormValues';
import { Formik, FormikHelpers } from 'formik';
import TripForm from './trip/form/TripForm';
import { dataSetEditingTrip, dataSetDispatchInstructionStocks, dataSetCompliances, dataSetTrips } from '../../store/data/Actions';
import { Dispatch, bindActionCreators } from 'redux';
import { IComplianceLine } from '../../@types/model/compliance/complianceLine';
import ConfirmationPrompt from '../../components/dialog/ConfirmationPrompt';
import { IStorageUnit } from '../../@types/model/masterData/storageUnit/storageUnit';
import SalesPointHttpService from '../../services/http/masterData/salesPointHttpService';
import { ISalesPoint } from '../../@types/model/masterData/salesPoint/salesPoint';
import { dataSetSalesPoints } from '../../store/masterData/Actions';
import { IBrand } from '../../@types/model/masterData/brand/brand';
import Tooltip from '../../components/tooltip/tooltip';
import { syncMasterData } from '../../services/masterDataSyncService';
import materialTheme from '../../styles/materialTheme';
import { IInventory } from '../../@types/model/masterData/inventory/inventory';

interface IDispatchInstructionWizardProps extends RouteComponentProps {
    dataSetEditingTrip : DispatchCall<boolean>;
    dataSetTrips : DispatchCall<Array<ITrip>>;
    dataSetSalesPoints : DispatchCall<Array<ISalesPoint>>;
    dataSetDispatchInstructionStocks : DispatchCall<Array<IStock>>;
    dataSetCompliances : DispatchCall<Array<ICompliance>>;
    organizations : Array<IOrganization>;
    sites : Array<ISite>;
    auth : IAuthState;
    selectedOrganizationIds : Array<number>;
    selectedSiteIds : Array<number>;
    farms : Array<IFarm>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    packs : Array<IPack>;
    sizes : Array<ISize>;
    storageUnits : Array<IStorageUnit>;
    stocks : Array<IStock>;
    colours : Array<IColour>;
    grades : Array<IGrade>;
    compliances : Array<ICompliance>;
    dispatchInstructions : Array<IDispatchInstruction>;
    carriers : Array<ICarrier>;
    trips : Array<ITrip>;
    markets : Array<IMarket>;
    brands : Array<IBrand>;
    inventories : Array<IInventory>;
    truckTypes : Array<ITruckType>;
    palletBaseTypes : Array<IPalletBaseType>;
    salesPoints : Array<ISalesPoint>;
}

interface IDispatchInstructionWizardState {
    selectedSourceOrganization ?: number;
    selectedSourceSite ?: number;
    selectedStorageUnit ?: number;
    selectedTargetOrganization ?: number;
    selectedTargetSite ?: number;
    selectedLoadDate : moment.Moment;
    selectedStocks : Array<{stockId : number; isSubstitutable : boolean}>;
    selectedStock ?: IStock;
    currentPage : number;
    activeDialog ?: number;
    rows : Array<IStock>;
    isLoading : boolean;
    createdDispatchCode ?: string;
    createdComplianceCode ?: string;
    createdTripId ?: number;
    trips : Array<ITrip>;
    newTrip ?: ITrip;
    selectedTripId ?: number;
    createCompliance ?: boolean;
    isEditingTrip : boolean;
    clearStorageUnit : boolean;
    firstStockFarm ?: { farm : IFarm; pallets : number };
    mixedFarm ?: { farm : IFarm; pallets : number };
    filteredStockRows : Array<IStock>;
    isTruckTypeValidationPopupOpen : boolean;
}

class DispatchInstructionWizard extends React.Component<IDispatchInstructionWizardProps, IDispatchInstructionWizardState> {

    constructor(props : IDispatchInstructionWizardProps) {
        super(props);
        this.state = {
            selectedStocks: [],
            selectedLoadDate: moment(),
            currentPage: 1,
            rows: [],
            isLoading: false,
            activeDialog: undefined,
            trips: [],
            isEditingTrip: false,
            filteredStockRows: [],
            isTruckTypeValidationPopupOpen: false,
            clearStorageUnit: false,
        };
    }

    public componentDidMount = async () => {
        dataClearAllDispatchViewRelatedData();
        const nextProps = this.props;
        if (nextProps.selectedSiteIds?.length === 1 && nextProps.selectedOrganizationIds?.length === 1) {
            const selectedOrganization = nextProps.selectedOrganizationIds[0];
            const selectedSite = nextProps.selectedSiteIds[0];
            this.setState({ selectedSourceOrganization: selectedOrganization },
                () => this.setState({ selectedSourceSite: selectedSite, selectedStorageUnit: undefined, clearStorageUnit: true }));
        }
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        } else {
            try {
                this.setLoading(true);
                const res = await DispatchHttpService.getDispatchWizardMasterData();

                if (res && res.data) {
                    dataSetDispatchWizardMasterDataData(res.data);
                }
            } catch (e) {
                generalShowErrorSnackbar('Error while loading wizard master data');
            } finally {
                this.setLoading(false);
            }
        }
    };

    public componentDidUpdate = async (prevProps : IDispatchInstructionWizardProps, prevState : IDispatchInstructionWizardState) => {
        const nextProps = this.props;
        const nextState = this.state;
        if (nextProps) {
            const selectedOrganizationId = nextProps.selectedOrganizationIds?.length === 1 ? nextProps.selectedOrganizationIds[0] : undefined;
            const selectedSiteId = nextProps.selectedSiteIds?.length === 1 ? nextProps.selectedSiteIds[0] : undefined;
            if (selectedOrganizationId && selectedSiteId && (this.state.selectedSourceSite !== selectedSiteId || this.state.selectedSourceOrganization !== selectedOrganizationId)) {
                const selectedOrganization = selectedOrganizationId;
                const selectedSite = selectedSiteId;
                this.setState({ selectedSourceOrganization: selectedOrganization },
                    () => this.setState({ selectedSourceSite: selectedSite, selectedStorageUnit: undefined, clearStorageUnit: true }));
            }
        }

        if (nextState) {
            if (nextState.currentPage === 2 && prevState.currentPage !== 2) {
                try {
                    this.setLoading(true);
                    // checks if indexedDB is available.
                    const isIndexedDBAvailable = !!self.indexedDB ? true : false;

                    const res = await DispatchHttpService.getDispatchWizardStockData(undefined, undefined, this.getDate('trip'), this.state.selectedSourceSite, this.state.selectedStorageUnit, !isIndexedDBAvailable);

                    const selectedTargetSite = nextProps.sites.find(x => x.id === nextState.selectedTargetSite);

                    if (selectedTargetSite && !selectedTargetSite.canMixSalesPoint) {
                        const res2 = await SalesPointHttpService.getSalesPointData();

                        nextProps.dataSetSalesPoints(res2.data);
                    }

                    dataSetDispatchWizardStockData(res.data);
                    this.setLoading(false);
                } catch (e) {
                    generalShowErrorSnackbar('An error occurred retrieving dispatch wizard data.');
                    this.setLoading(false);
                }
            }
            if (nextState.currentPage === 3 && prevState.currentPage !== 3) {
                try {
                    this.setLoading(true);
                    const res = await DispatchHttpService.getDispatchWizardFinalStepRelatedData(this.state.selectedStocks.map(x => x.stockId), this.props.trips.map(x => x.id));

                    if (res && res.data) {
                        dataSetDispatchWizardFinalStepData(res.data);
                    }
                } catch (e) {
                    generalShowErrorSnackbar('Error while loading related compliances');
                } finally {
                    this.setLoading(false);
                }
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading : loading });
    };

    private getSelectStockCheckboxTooltipTitle = (stock : IStock) => {
        let title = '';

        const firstStockLine = stock.stockLines.filter(x => x.isActive)[0];
        const firstStockLineFarm = this.props.farms.find(x => x.id === firstStockLine.farmId);
        const firstStockLineSalesPoint = this.props.salesPoints.find(x => x.farmIds?.some(y => y === firstStockLineFarm?.id));

        const hasMixedSalesPoints : boolean = stock.stockLines
            .filter(x => x.isActive && x.id !== firstStockLine.id)
            .some(x => !firstStockLineSalesPoint?.farmIds?.find(y => y === x.farmId));

        if (!!hasMixedSalesPoints && (stock.channel !== 'E')) {
            title = 'Not allowed to select a stock that is mixing sales points.';
        }

        return title;
    };

    private isCheckboxDisabled = (stock : IStock) => {
        const selectedTargetSite = this.props.sites.find(x => x.id === this.state.selectedTargetSite);

        if (this.state.isLoading || (!!this.state.selectedStorageUnit && stock.currentStorageUnitId !== this.state.selectedStorageUnit)) {
            return true;
        }
        if (!selectedTargetSite?.canMixSalesPoint) {
            const firstStockLine = stock.stockLines.filter(x => x.isActive)[0];
            const firstStockLineFarm = this.props.farms.find(x => x.id === firstStockLine.farmId);
            const firstStockLineSalesPoint = this.props.salesPoints.find(x => x.farmIds?.some(y => y === firstStockLineFarm?.id));

            const hasMixedSalesPoints : boolean = stock.stockLines
                .filter(x => x.isActive && x.id !== firstStockLine.id)
                .some(x => !firstStockLineSalesPoint?.farmIds?.find(y => y === x.farmId));

            if (!!hasMixedSalesPoints) {
                return true;
            }
        }

        return false;
    };

    private isChecked = (id : number) => this.state.selectedStocks?.some(x => x.stockId === id);

    private handleStockCheckboxChecked = (row : IStock, checked : boolean) => {
        const selectedTargetSite = this.props.sites.find(x => x.id === this.state.selectedTargetSite);
        if (checked) {
            const index = this.state.selectedStocks.findIndex(x => x.stockId === row.id);
            const value = {
                stockId: row.id,
                isSubstitutable: false,
            };

            const firstStockLine = row.stockLines.filter(x => x.isActive)[0];
            const firstStockLineSalesPoint = this.props.salesPoints.find(x => x.farmIds?.some(y => y === firstStockLine.farmId));

            const hasMixedSalesPoints : boolean = row.stockLines
                .filter(x => x.isActive && x.id !== firstStockLine.id)
                .some(x => !firstStockLineSalesPoint?.farmIds?.find(y => y === x.farmId));

            if (index === -1) {
                if (!selectedTargetSite?.canMixSalesPoint && !!hasMixedSalesPoints) {
                    this.setState({ activeDialog: 5, selectedStock: row });
                } else {
                    this.setState(prevState => ({ selectedStocks: addArrayElement(prevState.selectedStocks, value) }));
                }
            }
        } else {
            const index = this.state.selectedStocks.findIndex(x => x.stockId === row.id);
            if (index !== -1) {
                this.setState(prevState => ({ selectedStocks: removeArrayElement(prevState.selectedStocks, index) }));
            }
        }
    };

    private isTripChecked = (id : number) => id === this.state.selectedTripId;

    private handleTripCheckBoxChecked = async (row : ITrip, checked : boolean) => {
        this.setLoading(true);
        if (checked) {
            if (!this.state.newTrip || row.id === 0) {
                try {
                    const res = await DispatchHttpService.getDispatchesLinkedToTrip(row.id);
                    if (res && res.data) {
                        res.data.dispatches.forEach((x) => {
                            dataSetDispatchInstruction(x);
                        });
                        res.data.stocks.forEach((x) => {
                            dataSetStock(x);
                        });
                    }
                } catch (e) {
                    generalShowErrorSnackbar('Failed To load dispatches linked to selected trip.');
                }
                this.setState({ selectedTripId: row.id, newTrip: row }, () => {
                    const trip = this.props.trips.find(x => x.id === row.id);

                    const dispatchesLinkedToTrip = this.props.dispatchInstructions.filter(x => x.isActive && x.tripId === trip?.id);

                    let dispatchLines : Array<IDispatchInstructionLine> = [];

                    dispatchesLinkedToTrip.forEach((x) => {
                        x.dispatchLines.filter(y => y.isActive).forEach((y) => {
                            dispatchLines = addArrayElement(dispatchLines, y, 'end');
                        });
                    });

                    const currentStocks = this.props.stocks.filter(x => dispatchLines.some(y => y.currentStockId === x.id));

                    const currentStockTotalWeight = currentStocks.length > 0 ? currentStocks.map(x => x.grossWeight).reduce((sum, current) => sum + current, 0) : 0;

                    const selectedStockIds = this.state.selectedStocks.map(x => x.stockId);
                    const selectedStocks = this.props.stocks.filter(x => selectedStockIds.some(y => y === x.id));
                    const selectedStockTotalWeight = selectedStocks.length > 0 ? selectedStocks.map(x => x.grossWeight).reduce((sum, current) => sum + current, 0) : 0;

                    const totalWeight = currentStockTotalWeight + selectedStockTotalWeight;

                    if (trip && trip.truckTypeId) {
                        const truckType = this.props.truckTypes.find(x => trip?.truckTypeId === x.id);
                        if (truckType && truckType?.maxPayload && truckType?.percentageWarning && (totalWeight > truckType?.maxPayload)) {
                            const maxPayloadPlusPercentageValue = truckType?.maxPayload + (truckType?.maxPayload * (truckType?.percentageWarning / 100));
                            if (totalWeight > maxPayloadPlusPercentageValue) {
                                generalShowWarningSnackbar('Pallets total weight exceeds the trucks max payload and percentage buffer'); // TODO: Change to error and block process in future if error is displayed
                            } else {
                                generalShowWarningSnackbar('Pallets total weight is more then the truck max payload');
                            }
                        }
                    }
                    this.setLoading(false);
                });
            } else {
                this.setLoading(false);
            }
        } else {
            if (this.state.selectedTripId === 0) {
                this.setState({ activeDialog: 6 });
            } else {
                this.setState({ selectedTripId: undefined, newTrip: undefined, isLoading: false });
            }
        }
    };

    private removeNewTrip = () => {
        this.setLoading(true);
        const index = this.state.trips.findIndex(x => x.id === 0);
        if (index !== -1) {
            this.setState(prevState => ({ selectedTripId: undefined, newTrip: undefined, trips: removeArrayElement(prevState.trips, index), activeDialog: undefined }), this.setLoading);
        } else {
            this.setLoading();
        }
    };

    private getSelectedStocks = (props : IDispatchInstructionWizardProps, state : IDispatchInstructionWizardState) => state.selectedStocks;
    private getStock = (props : IDispatchInstructionWizardProps) => props.stocks ?? [];
    private getIsLoading = (props : IDispatchInstructionWizardProps, state : IDispatchInstructionWizardState) => state.isLoading;
    private getFilteredStockRows = (props : IDispatchInstructionWizardProps, state : IDispatchInstructionWizardState) => state.filteredStockRows;

    private getStockRows = createSelector([this.getStock], stockData =>
        stockData
            .filter(x => !x.isTemporary && x.isActive && x.status === 'In Stock')
            .filter(x => x.stockLines.some(y => y.isActive)));

    private getFilteredStocks = createSelector([this.getFilteredStockRows], stockData =>
        stockData
            .filter(x => !x.isTemporary && x.isActive && x.status === 'In Stock')
            .filter(x => x.stockLines.some(y => y.isActive)));

    private handleSelectAllCheckBox = (checked : boolean) => {
        let selectedStocks : Array<{ stockId : number; isSubstitutable : boolean }> = [];
        if (checked) {
            this.getFilteredStocks(this.props, this.state).forEach((x) => {
                const disabled = this.isCheckboxDisabled(x);
                const index = selectedStocks.findIndex(y => y.stockId === x.id);

                if (!disabled && index === -1) {
                    selectedStocks = addArrayElement(selectedStocks, { stockId: x.id, isSubstitutable: false });
                }
            });

            this.setState({ selectedStocks });
        }

        this.setState({ selectedStocks });
    };

    private getAgeInDays = (id : number, row : IStock) => row.packDate ? moment({ hours: 0 }).diff(row.packDate, 'days') : -1;

    private getPalletBaseTypeCode = (id : number) => this.props.palletBaseTypes.find(x => x.id === id)?.code ?? 'UNK?';

    private getWaybill = (stockId : number) => {
        const compliance = this.props.compliances.find(x => x.isActive && x.complianceLines.some(y => y.isActive && y.stockId === stockId));

        return compliance ? compliance.waybill : '';
    };

    private getComplianceStatus = (stockId : number) => {
        const compliance = this.props.compliances.find(x => x.isActive && x.complianceLines.some(y => y.isActive && y.stockId === stockId));

        return compliance ? compliance.status : '';
    };

    private formatInners = (id : number, row : IStock) => {
        return `${this.getTotalInnersFromStockLines(row.stockLines)} ${this.getTotalInners(row.stockLines)}`;
    };

    private formatCartons = (id : number, row : IStock) => {
        return `${this.getCartonsFromStockLines(row.stockLines)} ${this.getCartonsTotal(row.stockLines)}`;
    };

    private columns =  createSelector([this.getSelectedStocks, this.getStockRows, this.getIsLoading], (selectedStocks, stocks, isLoading) => [
        /*
            TODO: Kept the old select checkboxes on this one for now since the salespoint checking works a little different, ideally we want
            to move this over to the datagrid's select but that isn't currently returning the selected items in the way we want.
        */
        { title: 'Select', field: 'id', width: 100,
            titleContainerComponent: (value : string) => {
                const rows = stocks.filter(x => x.isActive && !this.isCheckboxDisabled(x));
                return <div className={'fdr aic'}>
                    <Checkbox
                        className={'p0 mr10'}
                        indeterminate={(selectedStocks.length !== 0) && (selectedStocks.length !== rows.length)}
                        disabled={isLoading || (selectedStocks.length === 0) || (rows.length === 0)}
                        checked={selectedStocks.length === rows.length}
                        onChange={(event, checked) => this.handleSelectAllCheckBox(checked)}
                        onClick={e => e.stopPropagation()}
                    />
                    {value}
                </div>;
            },
            containerComponent: (row : IStock) => {
                const disabled = this.isCheckboxDisabled(row);
                return (
                    <Tooltip title={this.getSelectStockCheckboxTooltipTitle(row)}>
                        <Checkbox
                            checked={!!this.isChecked(row.id)}
                            disabled={disabled}
                            onChange={(event, checked) => this.handleStockCheckboxChecked(row, checked)}
                        />
                    </Tooltip>
                );
            },
        },
        { title: 'Barcode', field: 'barcode', width: 170, enableFiltering: true, enableSorting: true, isNumberString: true },
        { title: 'Cartons', field: 'id', width: 130, enableFiltering: true, enableSorting: true, formatFunction: this.formatCartons },
        { title: 'Inners', field: 'id', width: 110, enableFiltering: true, enableSorting: true, formatFunction: this.formatInners },
        { title: 'Farms', field: 'id', formatFunction: this.getFarmsFromStockLines, enableFiltering: true, enableSorting: true },
        { title: 'Commodities', field: 'id', formatFunction: this.getCommoditiesFromStockLines, enableFiltering: true, enableSorting: true },
        { title: 'Varieties', field: 'id', width: 140, formatFunction: this.getVarietiesFromStockLines, enableFiltering: true, enableSorting: true },
        { title: 'Brand', field: 'brandId', width: 140, formatFunction: this.getBrandCode, enableFiltering: true, enableSorting: true },
        { title: 'Inventory', field: 'inventoryId', width: 140, formatFunction: this.getInventoryName, enableFiltering: true, enableSorting: true },
        { title: 'Packs', field: 'id', width: 110, formatFunction: this.getPacksFromStockLines, enableFiltering: true, enableSorting: true },
        { title: 'Grades', field: 'id', width: 125, formatFunction: this.getGradesFromStockLines, enableFiltering: true, enableSorting: true },
        { title: 'Sizes', field: 'id', width: 110, formatFunction: this.getSizesFromStockLines, enableFiltering: true, enableSorting: true },
        { title: 'Colours', field: 'id', width: 125, formatFunction: this.getColoursFromStockLines, enableFiltering: true, enableSorting: true },
        { title: 'Current Organization', field: 'currentOrganizationId', formatFunction: this.getOrganizationName, enableFiltering: true, enableSorting: true },
        { title: 'Current Storage Unit', field: 'currentStorageUnitId', formatFunction: this.getStorageUnitDescription, enableFiltering: true, enableSorting: true },
        { title: 'Target Market', field: 'marketId', width: 160, formatFunction: this.getMarketName, enableFiltering: true, enableSorting: true },
        { title: 'Compliance Status', field: 'id', formatFunction: this.getComplianceStatus, enableFiltering: true, enableSorting: true },
        { title: 'Pallet Base Type', field: 'palletBaseTypeId', formatFunction: this.getPalletBaseTypeCode, width: 180, enableFiltering: true, enableSorting: true },
        { title: 'Gross Weight (kg)', field: 'grossWeight', width: 190, enableFiltering: true, enableSorting: true },
        { title: 'Age', field: 'id', width: 90, formatFunction: this.getAgeInDays, enableFiltering: true, enableSorting: true },
        { title: 'Pack Date', field: 'packDate', formatFunction: formatDateTimeToDateOnly, sortFunction: compareDate, type: 'date', enableFiltering: true, enableSorting: true },
        { title: 'Channel', field: 'channel', enableFiltering: true, enableSorting: true },
        { title: 'Waybill', field: 'id', formatFunction: this.getWaybill, enableFiltering: true, enableSorting: true },
    ]);

    private getCartonsTotal = (stockLines : Array<IStockLine>) => {
        const cartons = getCSVStockLineSummary(stockLines.filter(x => x.isActive && x.cartons > 0)).map(x => x.cartons);

        if (cartons && cartons.length > 1) {
            return '(' + cartons.reduce((a, b) => a + b, 0).toString() + ')';
        } else {
            return '';
        }
    };

    private getCartonsFromStockLines = (stockLines : Array<IStockLine>) => getCSVStockLineSummary(stockLines.filter(x => x.isActive && x.cartons > 0))
        .map(x => x.cartons).toString().replace(/,/g, ', ');

    private getTotalInners = (stockLines : Array<IStockLine>) => {
        const innners = getCSVStockLineSummary(stockLines.filter(x => x.isActive && x.totalInners && x.totalInners > 0)).map(x => x.totalInners ?? 0);

        if (innners && innners.length > 1) {
            return '(' + innners.reduce((a, b) => a + b, 0).toString() + ')';
        } else {
            return '';
        }
    };

    private getTotalInnersFromStockLines = (stockLines : Array<IStockLine>) => getCSVStockLineSummary(stockLines.filter(x => x.isActive && x.totalInners && x.totalInners > 0))
        .map(x => x.totalInners).toString().replace(/,/g, ', ');

    private getCommoditiesFromStockLines = (id : number, row : IStock) => lodash.uniq(row.stockLines.filter(x => x.isActive && x.cartons > 0)
        .map(x => this.getCommodityCode(x.commodityId))).toString().replace(/,/g, ', ');

    private getCommodityCode = (commodityId : number) => this.props.commodities.find(x => x.id === commodityId)?.code || '';

    private getVarietiesFromStockLines = (id : number, row : IStock) => lodash.uniq(row.stockLines.filter(x => x.isActive && x.cartons > 0)
        .map(x => this.getVarietyCode(x.varietyId))).toString().replace(/,/g, ', ');

    private getVarietyCode = (varietyId : number) => this.props.varieties.find(x => x.id === varietyId)?.code || '';

    private getColoursFromStockLines = (id : number, row : IStock) => lodash.uniq(row.stockLines.filter(x => x.isActive && x.cartons > 0)
        .map(x => this.getColourCode(x.colourId))).toString().replace(/,/g, ', ');

    private getColourCode = (colourId ?: number) => this.props.colours.find(x => !!colourId && (x.id === colourId))?.code || '';

    private getGradesFromStockLines = (id : number, row : IStock) => lodash.uniq(row.stockLines.filter(x => x.isActive && x.cartons > 0)
        .map(x => this.getGradeCode(x.gradeId))).toString().replace(/,/g, ', ');

    private getGradeCode = (gradeId : number) => this.props.grades.find(x => x.id === gradeId)?.code || '';

    private getPacksFromStockLines = (id : number, row : IStock) => lodash.uniq(row.stockLines.filter(x => x.isActive && x.cartons > 0)
        .map(x => this.getPackCode(x.packId))).toString().replace(/,/g, ', ');

    private getPackCode = (packId : number) => this.props.packs.find(x => x.id === packId)?.code || '';

    private getFarmsFromStockLines = (id : number, row : IStock) => lodash.uniq(row.stockLines.filter(x => x.isActive && x.cartons > 0)
        .map(x => this.getFarmCode(x.farmId))).toString().replace(/,/g, ', ');

    private getFarmCode = (farmId : number) => this.props.farms.find(x => x.id === farmId)?.code || '';

    private getSizesFromStockLines = (id : number, row : IStock) => lodash.uniq(row.stockLines.filter(x => x.isActive && x.cartons > 0)
        .map(x => this.getSizeCode(x.sizeId))).toString().replace(/,/g, ', ');

    private getSizeCode = (sizeId : number) => this.props.sizes.find(x => x.id === sizeId)?.code || '';

    private tripColumns = () => [
        /*
            TODO: Kept the old select checkboxes on this one for now since the salespoint checking works a little different, ideally we want
            to move this over to the datagrid's select but that isn't currently returning the selected items in the way we want.
        */
        { title: 'Select', field: 'id', width: 80,
            containerComponent: (row : ITrip) => {
                const disabled = !this.state.newTrip ? false : row.id !== this.state.newTrip?.id;
                return <Checkbox
                    disabled={disabled}
                    checked={!!this.isTripChecked(row.id)}
                    onChange={(event, checked) => this.handleTripCheckBoxChecked(row, checked)}
                />;
            },
        },
        { title: 'Id', field: 'id', enableFiltering: true, enableSorting: true },
        { title: 'Load Date', field: 'loadDate', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
        { title: 'Description', field: 'description', enableFiltering: true, enableSorting: true },
        { title: 'Driver', field: 'driver', enableFiltering: true, enableSorting: true },
        { title: 'Registration Number', field: 'registrationNumber', enableFiltering: true, enableSorting: true },
        { title: 'Container', field: 'container', enableFiltering: true, enableSorting: true },
        { title: 'Container Tare Weight', field: 'containerTareWeight', enableFiltering: true, enableSorting: true },
        { title: 'Carrier', field: 'carrierId', formatFunction: this.getCarrierName, enableFiltering: true, enableSorting: true },
        { title: 'Truck Type', field: 'truckTypeId', formatFunction: this.getTruckTypeCode, enableFiltering: true, enableSorting: true },
        { title: 'Fleet Number', field: 'fleetNumber', enableFiltering: true, enableSorting: true },
        { title: 'Seal Number', field: 'sealNumber', enableFiltering: true, enableSorting: true },
    ];

    private getCarrierName = (id : number) => this.props.carriers.find(x => x.id === id)?.name || '';

    private getTruckTypeCode = (id : number) => this.props.truckTypes.find(x => x.id === id)?.code || '';

    private getMarketName = (marketId : number) => {
        const markets = this.props.markets;
        const market = markets && markets.find(x => x.id === marketId);
        return market ? market.name : '';
    };

    private getBrandCode = (brandId : number) => {
        const brands = this.props.brands;
        const brand = brands && brands.find(x => x.id === brandId);
        return brand ? brand.code : '';
    };

    private getInventoryName = (inventoryId : number) => {
        const inventories = this.props.inventories;
        const inventory = inventories && inventories.find(x => x.id === inventoryId);
        return inventory ? inventory.code : '';
    };

    private siteOptions = (type : 'source' | 'destination') : Array<IDropDownOptions> => {
        const selectedOrganization = type === 'source' ? this.state.selectedSourceOrganization : this.state.selectedTargetOrganization;
        if (!this.props.sites || !selectedOrganization || (type === 'destination' && (!this.state.selectedSourceSite || !this.state.selectedSourceOrganization))) {
            return [];
        }
        const returnValue : Array<IDropDownOptions> = [];
        returnValue.push({ value: '', label: 'Select Site', disabled: true });
        this.props.sites.filter(x => x.organizationIds?.some(y => y === selectedOrganization)
            && ((type === 'destination') || this.props.selectedSiteIds?.some(y => y === x.id))
            && ((type === 'source') || (x.id !== this.state.selectedSourceSite))
            && x.isActive)
            .forEach(x => returnValue.push({ value: x.id, label: `(${x.code}) ${x.description}` }));
        return returnValue;
    };

    private storageUnitOptions = () : Array<IDropDownOptions> => {
        const returnValue : Array<IDropDownOptions> = [];
        returnValue.push({ value: '', label: 'Select Site', disabled: true });
        this.props.storageUnits?.filter(x => (x.siteId === this.state.selectedSourceSite)
            && (this.props.auth.session?.user?.siteIds?.some(y => y === x.siteId))
            && x.hasPackedStock
            && x.isActive)
            ?.forEach(x => returnValue.push({ value: x.id, label: `(${x.code}) ${x.description}` }));
        return returnValue;
    };

    private organizationOptions = (type : 'source' | 'destination') => {
        if (!this.props.organizations) {
            return [];
        }
        const returnValue : Array<IDropDownOptions> = [];
        returnValue.push({ value: '', label: 'Select Organization', disabled: true });
        this.props.organizations.filter(x => x.isActive
            && ((type === 'destination') || this.props.selectedOrganizationIds?.some(y => y === x.id)))
            ?.forEach(x => returnValue.push({ value: x.id, label: `(${x.code}) ${x.name}` }));
        return returnValue;

    };

    private filterTripRows = () => this.setState({ trips: this.props.trips.filter(x => x.isActive) });

    private renderPage = () => {
        switch (this.state.currentPage) {
            case 1:
                return this.renderStartPage();
            case 2:
                return this.renderStockSelectPage();
            case 3:
                return this.renderPreviewPage();
        }
    };

    private nextPageFromStockScreen = () => {
        const selectedTargetSite = this.props.sites.find(x => x.id === this.state.selectedTargetSite);
        const selectedStockIds = this.state.selectedStocks.map(x => x.stockId);
        const farms = this.props.farms;
        let stocks : Array<IStock> = [];
        let firstStockFarmSalesPointPallets : Array<IStock> = [];
        let mixedFarms : Array<IFarm> = [];
        let mixedSalesPoints : Array<ISalesPoint> = [];
        let palletsWithMixedFarmSalesPoint : Array<IStock> = [];

        if (!selectedTargetSite?.canMixSalesPoint) {
            selectedStockIds.forEach((x) => {
                const stock = this.props.stocks.find(y => y.id === x);
                if (stock) {
                    stocks = addArrayElement(stocks, stock);
                }
            });

            const firstStock = stocks[0];
            const firstStockMainStockLine = this.getMainStockLine(firstStock);
            const firstStockFarm = farms.find(y => y.id === firstStockMainStockLine?.farmId);
            const firstStockFarmSalesPoint = this.props.salesPoints.find(x => x.farmIds?.some(y => y === firstStockFarm?.id));

            stocks.forEach((x) => {
                const mainStockLine = this.getMainStockLine(x);
                const stockFarm = farms.find(y => y.id === mainStockLine?.farmId);
                const stockFarmSalesPoint = this.props.salesPoints.find(y => y.farmIds?.some(z => z === stockFarm?.id));

                if (stockFarm
                    && ((firstStockFarmSalesPoint && stockFarmSalesPoint && firstStockFarmSalesPoint?.id !== stockFarmSalesPoint?.id)
                        || (firstStockFarmSalesPoint && !stockFarmSalesPoint)
                        || (stockFarmSalesPoint && !firstStockFarmSalesPoint))) {
                    palletsWithMixedFarmSalesPoint = addArrayElement(palletsWithMixedFarmSalesPoint, x);
                    mixedFarms = addArrayElement(mixedFarms, stockFarm);

                    if (stockFarmSalesPoint) {
                        mixedSalesPoints = addArrayElement(mixedSalesPoints, stockFarmSalesPoint);
                    }
                } else {
                    firstStockFarmSalesPointPallets = addArrayElement(firstStockFarmSalesPointPallets, x);
                }
            });

            if (firstStockFarm && palletsWithMixedFarmSalesPoint.length > 0) {
                const firstStockMainStockLineFarm = {
                    farm: firstStockFarm,
                    pallets: firstStockFarmSalesPointPallets.length,
                };

                const mixedFarm = {
                    farm: mixedFarms[0],
                    pallets: palletsWithMixedFarmSalesPoint.length,
                };

                this.setState({ activeDialog: 3, firstStockFarm: firstStockMainStockLineFarm, mixedFarm });
            } else {
                this.nextPage();
            }
        } else {
            this.nextPage();
        }
    };

    private nextPage = () => this.setState(prevState => ({ currentPage: prevState.currentPage + 1 }), () => {
        if (this.state.currentPage === 3) {
            this.filterTripRows();
        }
    });

    private previousPage = () => this.setState(prevState => ({ currentPage: prevState.currentPage - 1 }), () => {
        switch (this.state.currentPage) {
            case 1:
                this.setState({ selectedStocks: [] });
                break;
        }
    });

    public onLoadDateChange = (selectedDate : moment.Moment) => {
        this.setState({ selectedLoadDate: selectedDate });
    };

    private renderStartPage = () =>  <Form>
        <Card className={'mb20'} style={{ height: `${this.state.currentPage === 1 ? 'calc(100% + 133px)' : ''}` }}>
            <AppBar position='static' elevation={0}>
                <Toolbar variant='dialog' className={'fdr'}>
                    <Typography className={'cw'} variant='h6'>{'Select Dispatch Details'}</Typography>
                </Toolbar>
            </AppBar>
            <div className={'fdc p20'}>
                <div className={'fdr wfill mb10'}>
                    <div className={'fdc flx1 mb10'}>
                        <CustomAutoSuggest
                            className={'wfill m5'}
                            options={this.organizationOptions('source')}
                            label={'Source Organization'}
                            initialValue={this.state.selectedSourceOrganization}
                            hasInitialValue={!!this.state.selectedSourceOrganization}
                            validate={validationService.required}
                            disabled={this.state.isLoading}
                            validateOnBlur
                            validateOnChange
                            field={'sourceOrganization'}
                            value={this.state.selectedSourceOrganization ?? 0}
                            onChange={(e : any) => this.setState({ selectedSourceOrganization: e, selectedStorageUnit: undefined, clearStorageUnit: true })}
                            enableDropDownIcon
                        />
                        <div className={'h10'}/>
                        <CustomAutoSuggest
                            className={'wfill m5'}
                            options={this.siteOptions('source')}
                            label={'Source Site'}
                            initialValue={this.state.selectedSourceSite}
                            hasInitialValue={!!this.state.selectedSourceOrganization && !!this.state.selectedSourceSite}
                            validate={validationService.required}
                            disabled={this.state.isLoading}
                            validateOnBlur
                            validateOnChange
                            field={'sourceSite'}
                            value={this.state.selectedSourceSite ?? 0}
                            onChange={e => this.setState({ selectedSourceSite: e, selectedStorageUnit: undefined, clearStorageUnit: true })}
                            enableDropDownIcon
                        />
                        <div className={'h10'}/>
                        <CustomAutoSuggest
                            className={'wfill m5'}
                            options={this.storageUnitOptions()}
                            label={'Storage Unit'}
                            initialValue={this.state.selectedStorageUnit}
                            hasInitialValue={!!this.state.selectedSourceOrganization && !!this.state.selectedSourceSite && !!this.state.selectedStorageUnit}
                            disabled={this.state.isLoading}
                            validateOnBlur
                            validateOnChange
                            clearValue={this.state.clearStorageUnit}
                            resetClearValue={() => this.setState({ clearStorageUnit: false })}
                            field={'storageUnit'}
                            value={!!this.state.selectedSourceSite && (this.state.selectedStorageUnit ?? 0)}
                            onChange={(e : any) => this.setState({ selectedStorageUnit: e })}
                            enableDropDownIcon
                        />
                    </div>
                    <div className={'flx1 fdr aic jcc'}>
                        <TrendingFlatIcon color={'primary'} fontSize={'inherit'} style={{ minHeight: 80, height: 80, maxHeight: 80 }} className={'w200'}/>
                    </div>
                    <div className={'fdc flx1 mb10'}>
                        <CustomAutoSuggest
                            className={'wfill m5'}
                            options={this.organizationOptions('destination')}
                            label={'Target Organization'}
                            initialValue={this.state.selectedTargetOrganization}
                            hasInitialValue={!!this.state.selectedTargetOrganization}
                            validate={validationService.required}
                            disabled={this.state.isLoading}
                            validateOnBlur
                            validateOnChange
                            field={'targetOrganization'}
                            value={this.state.selectedTargetOrganization ?? 0}
                            onChange={(e : any) => this.setState({ selectedTargetOrganization: e })}
                            enableDropDownIcon
                        />
                        <div className={'h10'}/>
                        <CustomAutoSuggest
                            className={'wfill m5'}
                            options={this.siteOptions('destination')}
                            label={'Target Site'}
                            initialValue={this.state.selectedTargetSite}
                            hasInitialValue={!!this.state.selectedTargetSite && !!this.state.selectedTargetSite}
                            validate={validationService.required}
                            disabled={this.state.isLoading}
                            validateOnBlur
                            validateOnChange
                            field={'targetSite'}
                            value={this.state.selectedTargetSite ?? 0}
                            onChange={(e : any) => this.setState({ selectedTargetSite: e })}
                            enableDropDownIcon
                        />
                        <div className={'h10'}/>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateInput
                                className={'wfill m5'}
                                label={'Load Date'}
                                name={'loadDate'}
                                variant={'standard'}
                                disabled={this.state.isLoading}
                                value={this.state.selectedLoadDate}
                                onDateChange={this.onLoadDateChange}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
            </div>
        </Card>
        <div className={'fdr flx1'}>
            <div className={'flx1'}/>
            <PillButton color={'secondary'}
                className={'ml15 pl20 pr20 h35'}
                type={'submit'}
                text={'Next'}
                onClick={this.nextPage}
                disabled={this.state.isLoading || this.state.currentPage !== 1 || !this.state.selectedLoadDate
                    || !this.state.selectedSourceOrganization || !this.state.selectedSourceSite
                    || !this.state.selectedTargetOrganization || !this.state.selectedTargetSite} />
        </div>
    </Form>;

    private getDate = (type : 'from' | 'to' | 'trip') => {
        switch (type) {
        // case 'from':
        //     return this.state.selectedFromDate.utc().unix() * 1000;
        // case 'to':
        //     return this.state.selectedToDate.utc().unix() * 1000;
            case 'trip':
                return moment(this.state.selectedLoadDate, DATEPICKER_FORMAT_DEFAULT).utc().unix() * 1000;
        }
    };

    private refreshStockTableData = async () => {
        try {
            this.setLoading(true);
            // checks if indexedDB is available.
            const isIndexedDBAvailable = !!self.indexedDB ? true : false;

            const res = await DispatchHttpService.getDispatchWizardStockData(undefined, undefined, this.getDate('trip'), this.state.selectedSourceSite, this.state.selectedStorageUnit, !isIndexedDBAvailable);
            dataSetDispatchWizardStockData(res.data);
            this.setLoading(false);
        } catch (e) {
            generalShowErrorSnackbar('An error occurred retrieving dispatch wizard data.');
            this.setLoading(false);
        }
    };

    private getFilterTypeColunmObject = () => {
        const stocklines = lodash.flatten(this.props.stocks.map(x => x.stockLines));
        const farmIds : Array<number> = lodash.uniq(stocklines.map(x => x.farmId));
        const sizeIds : Array<number> = lodash.uniq(stocklines.map(x => x.sizeId));
        const commodityIds : Array<number> = lodash.uniq(stocklines.map(x => x.commodityId));
        const varietyIds : Array<number> = lodash.uniq(stocklines.map(x => x.varietyId));
        const colourIds : Array<number | undefined> = lodash.uniq(stocklines.map(x => x.colourId)).filter(x => x !== undefined);
        const gradeIds : Array<number> = lodash.uniq(stocklines.map(x => x.gradeId));
        const packIds : Array<number> = lodash.uniq(stocklines.map(x => x.packId));
        const marketIds : Array<number> = lodash.uniq(this.props.stocks.map(x => x.marketId));
        const organizationsIds : Array<number> = lodash.uniq(this.props.stocks.map(x => x.currentOrganizationId));
        const channels : Array<string> = lodash.uniq(this.props.stocks.map(x => x.channel));

        return [
            { column: 'Farms', data: this.props.farms.filter(x => farmIds.some(y => y === x.id)).map(x => x.code) },
            { column: 'Sizes', data: this.props.sizes.filter(x => sizeIds.some(y => y === x.id)).map(x => x.code) },
            { column: 'Commodities', data: this.props.commodities.filter(x => commodityIds.some(y => y === x.id)).map(x => x.code) },
            { column: 'Varieties', data: this.props.varieties.filter(x => varietyIds.some(y => y === x.id)).map(x => x.code) },
            { column: 'Colours', data: this.props.colours.filter(x => colourIds.some(y => y === x.id)).map(x => x.code) },
            { column: 'Grades', data: this.props.grades.filter(x => gradeIds.some(y => y === x.id)).map(x => x.code) },
            { column: 'Packs', data: this.props.packs.filter(x => packIds.some(y => y === x.id)).map(x => x.code) },
            { column: 'Channel', data: channels ?? [] },
            { column: 'Target Market', data: this.props.markets.filter(x => marketIds.some(y => y === x.id)).map(x => x.name) },
            { column: 'Current Organization', data: this.props.organizations.filter(x => organizationsIds.some(y => y === x.id)).map(x => x.name) },
        ];
    };

    private getMainStockLine = (stock : IStock) => getUniqueStockLines().find(x => x.stockId === stock.id);

    private getRowColors = (row : IStock) => {
        const selectedTargetSite = this.props.sites.find(x => x.id === this.state.selectedTargetSite);
        const farms = this.props.farms;
        const mainStockLine = this.getMainStockLine(row);
        const stockFarm = farms.find(x => x.id === mainStockLine?.farmId);
        const salesPoint = this.props.salesPoints.find(x => x.farmIds?.some(y => y === stockFarm?.id));
        let farmColor = '';
        if (stockFarm && salesPoint && !selectedTargetSite?.canMixSalesPoint) {
            farmColor = randomColor({ hue: 'random', format: 'rgba', alpha: materialTheme.custom.randomColor.alphaValue, seed: salesPoint.id * 1000000000 });
        }

        return farmColor;
    };

    private setFilteredRows = (filteredStocks : Array<IStock>) => {
        if (this.state.filteredStockRows.length !== filteredStocks.length) {
            this.setState({ filteredStockRows: filteredStocks });
        }
    };

    private getNumberOfCartons = (selectedStockIds : Array<number>) => {
        const selectedStocks = this.props.stocks.filter(x => selectedStockIds.some(y => y === x.id));

        return selectedStocks.length > 0 ? selectedStocks.map(x => x.cartons).reduce((sum, current) => sum + current, 0) : 0;
    };

    private getNumberOfInners = (selectedStockIds : Array<number>) => {
        const selectedStocks = this.props.stocks.filter(x => selectedStockIds.some(y => y === x.id));

        let stockLines : Array<IStockLine> = [];

        selectedStocks.forEach((x) => {
            x.stockLines.filter(line => line.isActive).forEach((line) => {
                const index = stockLines.findIndex(y => y.id === line.id);

                if (index === -1) {
                    stockLines = addArrayElement(stockLines, line);
                }
            });
        });

        const inners : Array<number> = stockLines.filter(x => !!x.totalInners).map(x => x.totalInners ?? 0);

        return selectedStocks.length > 0 && stockLines.length > 0 ? inners.reduce((sum, current) => sum + current, 0) : 0;
    };

    private getStorageUnitDescription = (storageUnitId ?: number) => this.props.storageUnits.find(x => x.id === storageUnitId)?.description ?? '';

    private renderStockSelectPage = () => <div className={'fdc hfill pb40'}>
        <Card className={'fdc mb20 selectTextOnly hfill'}>
            <CustomTable<IStock>
                enableSorting
                enableFiltering
                enableClearFilterButton
                enablePagination
                enableRefresh
                enableCSVExport
                csvExportPathname={this.props.location.pathname}
                filterType={'MultiCheckbox'}
                filterTypeColumn={this.getFilterTypeColunmObject()}
                refreshFunction={this.refreshStockTableData}
                columns={this.columns(this.props, this.state)}
                rows={this.getStockRows(this.props)}
                initialSortOrder={[{ columnName: 'packDate_Pack Date', direction : 'desc' }, { columnName: 'barcode_Barcode', direction : 'asc' }]}
                pageSizes={[150, 250, 500, 1000]}
                isActive={(row : IStock) => row.isActive}
                pageHeight={350}
                rowColor={(row : IStock) => this.getRowColors(row)}
                filteredRows={this.setFilteredRows}
            />
        </Card>
        <div className={'fdr flx1 mt20 pl20'}>
            <div className={'fdr aic'}>
                {'Selected:'}
                <div className={'w10'}/>
                <Typography variant={'h6'} className={'aic'}>
                    {this.state.selectedStocks.length}
                </Typography>
            </div>
            <div className={'w100'}/>
            <div className={'fdr aic'}>
                {'No. Cartons:'}
                <div className={'w10'}/>
                <Typography variant={'h6'} className={'aic fdr'}>
                    {this.getNumberOfCartons(this.state.selectedStocks.map(x => x.stockId))}
                </Typography>
            </div>
            <div className={'w100'}/>
            <div className={'fdr aic'}>
                {'No. Inners:'}
                <div className={'w10'}/>
                <Typography variant={'h6'} className={'aic fdr'}>
                    {this.getNumberOfInners(this.state.selectedStocks.map(x => x.stockId))}
                </Typography>
            </div>
            <div className={'w100'}/>
            { !!this.state.selectedStorageUnit &&
                <div className={'fdr aic'}>
                    {'Selected storage unit:'}
                    <div className={'w10'}/>
                    <Typography variant={'h6'} className={'aic fdr'}>
                        {this.getStorageUnitDescription(this.state.selectedStorageUnit)}
                    </Typography>
                </div>
            }
            <div className={'flx1'}/>
            <Button
                className={'fwb h35'}
                variant='text'
                color='primary'
                disabled={this.state.isLoading || this.state.currentPage !== 2}
                onClick={this.previousPage}>
                Back
            </Button>
            <PillButton color={'secondary'}
                className={'ml15 pl20 pr20 h35'}
                type={'submit'}
                text={'Next'}
                onClick={this.nextPageFromStockScreen}
                disabled={this.state.isLoading || this.state.currentPage !== 2 || !this.state.selectedLoadDate
                    || !this.state.selectedSourceOrganization || !this.state.selectedSourceSite
                    || !this.state.selectedTargetOrganization || !this.state.selectedTargetSite
                    || this.state.selectedStocks.length === 0} />
        </div>
    </div>;

    private getSiteDescription = (siteId ?: number) => {
        const sites = this.props.sites;
        const site = sites && sites.find(x => x.id === siteId);
        return site ? site.description : '';
    };

    private getOrganizationName = (orgId ?: number) => {
        const organizations = this.props.organizations;
        const organization = organizations && organizations.find(x => x.id === orgId);
        return organization ? organization.name : '';
    };

    private getStockBarcode = (stockId : number) => this.props.stocks.find(x => x.id === stockId)?.barcode || '';
    private getStockCartons = (stockId : number) => this.props.stocks.find(x => x.id === stockId)?.cartons || 0;

    private getTotalCartons = () => {
        let total = 0;
        this.state.selectedStocks.forEach(x => total += this.getStockCartons(x.stockId));
        return total;
    };

    private showTripDialog = () => {
        const selectedStocks = this.props.stocks.filter(x => this.state.selectedStocks.some(y => y.stockId === x.id));

        this.props.dataSetDispatchInstructionStocks(selectedStocks);

        this.setState({ activeDialog: 0, newTrip: {
            loadDate: moment(this.state.selectedLoadDate).startOf('day').format(DATEPICKER_FORMAT_DEFAULT),
            id: 0,
            guid: v1(),
            isActive: true,
            temperatureUnits: [],
        } });
        this.props.dataSetEditingTrip(true);
    };

    private addTrip = (value : ITripFormValues) => {
        const trip = new Trip(value);
        if (trip) {
            if (trip && trip.truckTypeId) {
                const selectedStockIds = this.state.selectedStocks.map(x => x.stockId);
                const selectedStocks = this.props.stocks.filter(x => selectedStockIds.some(y => y === x.id));
                const totalWeight = selectedStocks.map(x => x.grossWeight).reduce((sum, current) => sum + current, 0);
                const truckType = this.props.truckTypes.find(x => trip.truckTypeId === x.id);

                if (truckType && totalWeight && truckType?.maxPayload && truckType?.percentageWarning && (totalWeight > truckType?.maxPayload)) {
                    const maxPayloadPlusPercentageValue = truckType?.maxPayload + (truckType?.maxPayload * (truckType?.percentageWarning / 100));
                    if (totalWeight > maxPayloadPlusPercentageValue) {
                        generalShowWarningSnackbar('Pallets total weight exceeds the trucks max payload and percentage buffer'); // TODO: Change to error and block process in future if error is displayed
                    } else {
                        generalShowWarningSnackbar('Pallets total weight is more then the truck max payload');
                    }
                }
            }
            this.setState(prevState => ({ activeDialog: undefined, selectedTripId: 0, trips: addArrayElement(prevState.trips, trip, 'end'), newTrip: trip }));
            this.props.dataSetEditingTrip(false);
            this.props.dataSetDispatchInstructionStocks([]);
        }
    };

    private editTrip = (value : ITripFormValues) => {
        if (this.state.isEditingTrip) {
            const trip = new Trip(value);
            if (trip) {
                if (trip.truckTypeId) {
                    const selectedStockIds = this.state.selectedStocks.map(x => x.stockId);
                    const selectedStocks = this.props.stocks.filter(x => selectedStockIds.some(y => y === x.id));
                    const totalWeight = selectedStocks.map(x => x.grossWeight).reduce((sum, current) => sum + current, 0);
                    const truckType = this.props.truckTypes.find(x => trip.truckTypeId === x.id);

                    if (truckType && totalWeight && truckType?.maxPayload && truckType?.percentageWarning && (totalWeight > truckType?.maxPayload)) {
                        const maxPayloadPlusPercentageValue = truckType?.maxPayload + (truckType?.maxPayload * (truckType?.percentageWarning / 100));
                        if (totalWeight > maxPayloadPlusPercentageValue) {
                            generalShowWarningSnackbar('Pallets total weight exceeds the trucks max payload and percentage buffer'); // TODO: Change to error and block process in future if error is displayed
                        } else {
                            generalShowWarningSnackbar('Pallets total weight is more then the truck max payload');
                        }
                    }
                }
                this.setState(prevState => ({ activeDialog: undefined, selectedTripId: trip.id, trips: upsertArrayElement(prevState.trips, trip, x => x.id === trip.id) ?? [], isEditingTrip: false, newTrip: trip }));
                this.props.dataSetEditingTrip(false);
                this.props.dataSetDispatchInstructionStocks([]);
            }
        }
    };

    private cancelTripDialog = () => this.setState({ activeDialog: undefined, isEditingTrip: false });

    private setEditTrip = (row : ITrip) => {
        this.setLoading(true);
        const selectedStocks = this.props.stocks.filter(x => this.state.selectedStocks.some(y => y.stockId === x.id));

        this.props.dataSetDispatchInstructionStocks(selectedStocks);
        this.props.dataSetEditingTrip(true);
        this.setState({
            activeDialog: 0,
            newTrip: { ...row },
            isEditingTrip: true,
        }, async () => {
            try {
                const res = await DispatchHttpService.getDispatchesLinkedToTrip(row.id);
                if (res && res.data) {
                    res.data.dispatches.forEach((x) => {
                        dataSetDispatchInstruction(x);
                    });
                    res.data.stocks.forEach((x) => {
                        dataSetStock(x);
                    });
                }
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('Failed To load dispatches linked to selected trip.');
                this.setLoading(false);
            }
        });
    };

    private getTrips = (state : IDispatchInstructionWizardState) => state.trips;
    private getSelectedLoadDate = (state : IDispatchInstructionWizardState) => state.selectedLoadDate;

    private getTripRows = createSelector([this.getTrips, this.getSelectedLoadDate], (trips, selectedLoadDate) =>
        trips.filter(x => moment(x.loadDate).local().date() === moment(selectedLoadDate).local().date()));

    private toggleStockSubstitutable = (stockId : number, isStockSubstitutable : boolean) => {
        const selectedStocks = this.state.selectedStocks;
        const index = selectedStocks.findIndex(x => x.stockId === stockId);

        if (index !== -1) {
            const data = {
                stockId,
                isSubstitutable: !isStockSubstitutable,
            };

            this.setState(prevState => ({ selectedStocks: setArrayElement(prevState.selectedStocks, index, data) }));
        }
    };

    private toggleAllSubstitutable = (checked : boolean) => {
        let selectedStocks = [...this.state.selectedStocks];
        if (checked) {
            selectedStocks = selectedStocks.map((x) => {
                return { stockId: x.stockId, isSubstitutable: true };
            });
        } else {
            selectedStocks = selectedStocks.map((x) => {
                return { stockId: x.stockId, isSubstitutable: false };
            });
        }
        this.setState({ selectedStocks });
    };

    private refreshTrips = async () => {
        this.setLoading(true);
        try {
            const res = await DispatchHttpService.getDispatchWizardTrips(this.getDate('trip'));
            if (res && res.data) {
                this.props.dataSetTrips(res.data);
                this.setState({ newTrip: undefined, selectedTripId: undefined });
                this.filterTripRows();
            }
        } catch (ex) {
            generalShowErrorSnackbar('Error occured while refreshing trips');
        } finally {
            this.setLoading(false);
        }
    };

    private renderPreviewPage = () => <div className={'hfill pb40'}>
        <Card className={'mb20'}>
            <AppBar position='static' elevation={0}>
                <Toolbar variant='dialog' className={'fdr'}>
                    <Typography color='inherit' variant='h6'>{'Summary'}</Typography>
                    <div className={'flx1'}/>
                    <img src={'../../../assets/icons/carton.svg'} color={'white'} height={30} className={'mr20'}/>
                    {this.getTotalCartons()}
                    <span className={'w30'}/>
                    <img src={'../../../assets/icons/pallet.svg'} color={'white'} height={30} className={'mr20'}/>
                    {this.state.selectedStocks.length}
                </Toolbar>
            </AppBar>
            <div className={'p10 fdr'}>
                <div className={'fdc'}>
                    <div className={'fdr p10'}>
                        <div className={'fdc'}>
                            <span className={'fw600 mr5'}>Source Organization:</span>{this.getOrganizationName(this.state.selectedSourceOrganization)}
                            <span className={'fw600 mr5'}>Source Site: </span>{this.getSiteDescription(this.state.selectedSourceSite)}
                            { !!this.state.selectedStorageUnit && <span className={'fw600 mr5'}>Source Storage Unit: </span>}{this.getStorageUnitDescription(this.state.selectedStorageUnit)}
                        </div>
                        <div className={'fdr aic jcc'}>
                            <TrendingFlatIcon color={'primary'} fontSize={'inherit'} style={{ minHeight: 40, height: 40, maxHeight: 40 }} className={'w200'}/>
                        </div>
                        <div className={'fdc'}>
                            <span className={'fw600 mr5'}>Destination Organization: </span>{this.getOrganizationName(this.state.selectedTargetOrganization)}
                            <span className={'fw600 mr5'}>Destination Site: </span>{this.getSiteDescription(this.state.selectedTargetSite)}
                        </div>
                    </div>
                    <div className={'flx1'}/>
                </div>
                <div className={'flx1'}/>
                <div className={'fdc p10 mxh250 oya'}>
                    <div className={'fdr'}>
                        <div className={'fdr fw600 mb10 mr20 aic'}>
                            <Checkbox
                                className={'mr10 p5'}
                                checked={!this.state.selectedStocks.some(x => !x.isSubstitutable)}
                                indeterminate={this.state.selectedStocks.some(x => !x.isSubstitutable) && this.state.selectedStocks.some(x => x.isSubstitutable)}
                                onChange={(e, checked) => this.toggleAllSubstitutable(checked)}/>
                                Substitutable?
                        </div>
                        <div className={'fdr fw600 mb10 aic'}>Selected Pallets:</div>
                    </div>
                    {this.state.selectedStocks.map(x => <div className={'fdr aic'}>
                        <Checkbox
                            className={'mr10 p5'}
                            checked={!!x.isSubstitutable}
                            onChange={() => this.toggleStockSubstitutable(x.stockId, x.isSubstitutable)}/>
                        <div className={'w110'}/>
                        {`${this.getStockBarcode(x.stockId)} (${this.getStockCartons(x.stockId)})`}
                    </div>)}
                </div>
            </div>

        </Card>
        <Card className={'mb20 mxh300 selectTextOnly hfill'}>
            <div className={'pt10 pb10 pl20 bcp'}>
                <Typography className={'cw'} variant='h6'>{'Assign Trip'}</Typography>
            </div>
            <div className='hfill pb50'>
                <CustomTable<ITrip>
                    editFunction={this.setEditTrip}
                    enableEditing={(row : ITrip) => !this.state.newTrip || row.id === this.state.newTrip?.id}
                    enableAdding={!this.state.newTrip}
                    addFunction={this.showTripDialog}
                    enableSorting
                    enableFiltering
                    enableClearFilterButton
                    enablePagination
                    fitWidthToPage
                    enableRefresh
                    pageHeight={800}
                    refreshFunction={this.refreshTrips}
                    columns={this.tripColumns()}
                    rows={this.getTripRows(this.state)}
                    initialSortOrder={[{ columnName: 'id_Id', direction : 'desc' }]}
                    warning={(row : ITrip) => row.id === this.state.selectedTripId || row.id === this.state.newTrip?.id}
                    pageSizes={[50, 150, 250, 500, 1000]}
                />
            </div>
        </Card>
        <div className={'fdr flx1 pt10 pb20'}>
            <div className={'flx1'}/>
            <Button
                className={'fwb h35'}
                variant='text'
                color='primary'
                disabled={this.state.isLoading || this.state.currentPage !== 3}
                onClick={this.previousPage}>
                Back
            </Button>
            <PillButton color={'secondary'}
                className={'ml15 pl20 pr20 h35'}
                type={'submit'}
                text={'Create Dispatch'}
                onClick={this.truckTypeValidation}
                disabled={this.state.isLoading || this.state.currentPage !== 3 || !this.state.selectedLoadDate
                    || !this.state.selectedSourceOrganization || !this.state.selectedSourceSite
                    || !this.state.selectedTargetOrganization || !this.state.selectedTargetSite
                    || this.state.selectedStocks.length === 0} />
        </div>
    </div>;

    private truckTypeValidation = () => {
        if (this.state.selectedSourceOrganization && this.state.selectedTargetOrganization && this.state.selectedSourceSite
            && this.state.selectedLoadDate && this.state.selectedTargetSite && this.state.selectedStocks.length > 0) {
            if (this.state.newTrip || this.state.selectedTripId) {
                const trip = this.state.trips.find(x => x.id === this.state.selectedTripId);

                const dispatchesLinkedToTrip = this.props.dispatchInstructions.filter(x => x.isActive && x.tripId === trip?.id);

                let dispatchLines : Array<IDispatchInstructionLine> = [];

                dispatchesLinkedToTrip.forEach((x) => {
                    x.dispatchLines.filter(y => y.isActive).forEach((y) => {
                        dispatchLines = addArrayElement(dispatchLines, y, 'end');
                    });
                });

                const currentStocks = this.props.stocks.filter(x => dispatchLines.some(y => y.currentStockId === x.id));

                const currentStockTotalWeight = currentStocks.length > 0 ? currentStocks.map(x => x.grossWeight).reduce((sum, current) => sum + current, 0) : 0;

                const selectedStockIds = this.state.selectedStocks.map(x => x.stockId);
                const selectedStocks = this.props.stocks.filter(x => selectedStockIds.some(y => y === x.id));
                const selectedStockTotalWeight = selectedStocks.length > 0 ? selectedStocks.map(x => x.grossWeight).reduce((sum, current) => sum + current, 0) : 0;

                const totalWeight = currentStockTotalWeight + selectedStockTotalWeight;

                if (trip && trip.truckTypeId && totalWeight) {
                    const truckType = this.props.truckTypes.find(x => trip?.truckTypeId === x.id);
                    if (truckType && truckType?.maxPayload && truckType?.percentageWarning && (totalWeight > truckType?.maxPayload)) {
                        this.setState({ activeDialog: 4 });
                    } else {
                        this.showComplianceDialog();
                    }
                } else {
                    this.showComplianceDialog();
                }
            } else {
                this.showComplianceDialog();
            }
        }
    };

    // checks whether the dispatch's stock needs to be inspected and whether they can go on one compliance
    private showComplianceDialog = () => {
        let complianceLines : Array<IComplianceLine> = [];

        this.props.compliances.forEach((compliance) => {
            if (compliance.isActive) {
                compliance.complianceLines.forEach((line) => {
                    if (line.isActive) {
                        complianceLines = addArrayElement(complianceLines, line);
                    }
                });
            }
        });

        let needsCompliance = true;
        this.props.stocks.filter(x => this.state.selectedStocks.some(y => x.id === y.stockId)).forEach((x) => {
            const compliancelineLinkedToStock = complianceLines.find(line => line.stockId === x.id);
            if (x.channel !== 'E' || !!compliancelineLinkedToStock && (compliancelineLinkedToStock.isInspected || compliancelineLinkedToStock.isRejected || compliancelineLinkedToStock.isReplaced)) {
                needsCompliance = false;
            }
        });
        if (needsCompliance) {
            this.setState({ activeDialog: 1 });
        } else {
            this.setState({ createCompliance: false }, this.createDispatch);
        }
    };

    private createDispatch = async () => {
        this.setLoading(true);
        if (this.state.selectedSourceOrganization && this.state.selectedTargetOrganization && this.state.selectedSourceSite
            && this.state.selectedLoadDate && this.state.selectedTargetSite && this.state.selectedStocks.length > 0) {
            const loadDate = moment(this.state.selectedLoadDate).startOf('day').format(DATEPICKER_FORMAT_DEFAULT);
            const newTrip = this.state.newTrip ? { ...this.state.newTrip } : undefined;

            if (newTrip) {
                newTrip.loadDate = loadDate;
            }

            const data : ICreateInstructedDispatch = {
                loadDate,
                sourceSiteId: this.state.selectedSourceSite,
                destinationSiteId: this.state.selectedTargetSite,
                organizationId: this.state.selectedSourceOrganization,
                targetOrganizationId: this.state.selectedTargetOrganization,
                stocks: this.state.selectedStocks,
                createCompliance: this.state.createCompliance ?? false,
                existingTripId: this.state.selectedTripId ?? undefined,
                newTrip,
            };

            try {
                const res = await DispatchHttpService.createInstructedDispatch(data);

                if (res && res.data) {
                    this.setState({ activeDialog: 2, createdDispatchCode: res.data?.dispatchCode, createdTripId: res.data?.tripId, createdComplianceCode: res.data?.complianceCode });
                    generalShowSuccessSnackbar('Dispatch successfully created.');
                    this.setLoading(false);
                } else {
                    generalShowErrorSnackbar('An error occurred creating the dispatch.');
                    this.setLoading(false);
                }
            } catch (ex) {
                if (!!ex && ex.status === 400 && !!ex?.data?.message) {
                    generalShowErrorSnackbar(ex?.data?.message);
                } else {
                    generalShowErrorSnackbar('An error occurred creating the dispatch.');
                }
                this.setLoading(false);
            }
        }

    };

    private createAnother = () => {
        this.setState({
            activeDialog: undefined,
            createCompliance: undefined,
            createdTripId: undefined,
            trips: [],
            newTrip: undefined,
            selectedTripId: undefined,
            createdComplianceCode: undefined,
            createdDispatchCode: undefined,
            currentPage: 1,
            selectedSourceOrganization: undefined,
            selectedSourceSite: undefined,
            selectedTargetOrganization: undefined,
            selectedTargetSite: undefined,
            selectedLoadDate: moment(),
            selectedStocks: [],
        });
    };

    private dialogTitles = [
        'Add Trip',
        'Add Dispatch to Compliance',
        'Dispatch Created',
        'Error!',
        'Are you sure?',
        'Error!',
    ];

    private renderDialog = () => <Dialog
        open={this.state.activeDialog !== undefined}>
        <AppBar className={`${this.state.activeDialog === 3 || this.state.activeDialog === 5 ? 'bcr' : ''}`} position='static' elevation={0}>
            <Toolbar variant='dense' className={'fdr'}>
                <Typography color='inherit' variant='h6'>{this.state.isEditingTrip ? 'Edit Trip' : this.dialogTitles[this.state.activeDialog ?? -1]}</Typography>
            </Toolbar>
        </AppBar>
        {this.state.isLoading &&
            <div className={'posa post0 posr0 posb0 posl0 aic jcc zi1000'}>
                <div className={'posr aic jcc h50 w50'}>
                    <div className={'posa post0 posr0 posb0 posl0 aic jcc'}>
                        <img height={40} src={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`} />
                    </div>
                    <CircularProgress color={'primary'} className={'posa post0 posr0 posb0 posl0'} size={50} />
                </div>
            </div>
        }
        {this.renderInnerDialog()}
    </Dialog>;

    private renderInnerDialog = () => {
        switch (this.state.activeDialog) {
            case 0:
                return this.renderTripDialog();
            case 1:
                return this.renderComplianceDialog();
            case 2:
                return this.renderNextActionDialog();
            case 3:
                return this.renderMixingSalesPointErrorDialog();
            case 4:
                return this.renderTripTruckTypeValidationPopup();
            case 5:
                return this.renderPalletMixedFarmWarningPopup();
            case 6:
                return this.renderNewTripRemovalConfirmation();
        }
    };

    private getCarriers = (props : IDispatchInstructionWizardProps) => props.carriers;
    private getDispatches = (props : IDispatchInstructionWizardProps) => props.dispatchInstructions;
    private getStocks = (props : IDispatchInstructionWizardProps) => props.stocks;
    private getTruckTypes = (props : IDispatchInstructionWizardProps) => props.truckTypes;
    private getSelectedTripToEdit = (props : IDispatchInstructionWizardProps, state : IDispatchInstructionWizardState) => state.newTrip;

    public getInitialFormValues = createSelector(
        [this.getSelectedTripToEdit, this.getCarriers, this.getTruckTypes, this.getDispatches, this.getStocks],
        (trip, carriers, truckTypes, dispatches, stocks) => {
            return new TripFormValues(trip, carriers, truckTypes, undefined, undefined, dispatches, stocks);
        },
    );

    public onReset = async (formValues : ITripFormValues, formikHelpers : FormikHelpers<ITripFormValues>) => {
        formikHelpers.resetForm();
        this.cancelTripDialog();
        this.props.dataSetEditingTrip(false);
    };

    private renderTripDialog = () => {
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <div className={'p20 fdc'}>
                <Formik
                    initialValues={initialValues}
                    onSubmit={this.state.isEditingTrip ? this.editTrip : this.addTrip}
                    onReset={this.onReset}
                    enableReinitialize
                    validationSchema={TripFormValues.formSchema}
                    component={TripForm}/>
            </div>
        );
    };

    private renderMixingSalesPointErrorDialog = () => {
        return (
            <div className={'fdc p10'}>
                <div className={'fs16 fw550'}>{'You are not allowed to mix farms for this Dispatch destination.'}</div>
                <div className={'fdr flx1 mt20'}>
                    <div className={'flx1'}/>
                    <Button
                        className={'fwb h35'}
                        variant='text'
                        color='primary'
                        disabled={this.state.isLoading}
                        onClick={this.mixingCurrentOrgWarningDialogNoClicked}>
                        OK
                    </Button>
                </div>
            </div>
        );
    };

    private mixingCurrentOrgWarningDialogNoClicked = () => this.setState({ activeDialog: undefined, firstStockFarm: undefined, mixedFarm: undefined });

    private renderPalletMixedFarmWarningPopup = () => {
        return (
            <div className={'fdc p10'}>
                <div className={'fs16 fw550'}>{'The selected destination does not allow for mixing of sales points on this dispatch.'}</div>
                <div className={'fs16 fw550'}>{'The selected pallet has farms that are not linked to the same sales point.'}</div>
                <div className={'fdr flx1 mt20'}>
                    <div className={'flx1'}/>
                    <PillButton color={'secondary'}
                        className={'ml15 pl20 pr20 h35'}
                        text={'OK'}
                        onClick={this.palletMultipleFarmWarningDialogNoClicked}
                        disabled={this.state.isLoading} />
                </div>
            </div>
        );
    };

    private palletMultipleFarmWarningDialogNoClicked = () => this.setState({ activeDialog: undefined, selectedStock: undefined });

    private renderComplianceDialog = () => <div className={'fdc p10'}>
        <div className={'fs16 fw550'}>Would you like to also add the stock on this dispatch to a compliance?</div>
        <div className={'fdr flx1 mt20'}>
            <Tooltip title={'Created dispatch but does not add pallets to compliance'}>
                <PillButton color={'secondary'}
                    className={'ml15 pl20 pr20 h35'}
                    text={'Skip'}
                    onClick={this.complianceSkipClicked}
                    disabled={this.state.isLoading} />
            </Tooltip>
            <div className={'flx1'}/>
            <Button
                className={'fwb h35'}
                variant='text'
                color='primary'
                disabled={this.state.isLoading}
                onClick={this.complianceNoClicked}>
                Cancel
            </Button>
            <Tooltip title={'Created dispatch and adds pallets to compliance'}>
                <PillButton color={'secondary'}
                    className={'ml15 pl20 pr20 h35'}
                    type={'submit'}
                    text={'Yes'}
                    onClick={this.complianceYesClicked}
                    disabled={this.state.isLoading} />
            </Tooltip>
        </div>
    </div>;

    private complianceSkipClicked = () => this.setState({ activeDialog: undefined, createCompliance: false }, this.createDispatch);
    private complianceNoClicked = () => this.setState({ activeDialog: undefined, createCompliance: false });
    private complianceYesClicked = () => this.setState({ activeDialog: undefined, createCompliance: true }, this.createDispatch);

    private renderNextActionDialog = () => {
        return (
            <div className={'p20 aic fdc w300 h300'}>
                <div className={'fdc mb20 wfill aic jcc'}>
                    <div className={'fs16 fw550 fdr'}>{'Dispatch Code: '} <div className={'pl5 fs18 fw700'}>{this.state.createdDispatchCode}</div></div>
                    { this.state.createdComplianceCode && <div className={'fs16 fw550'}>{`Compliance Code: ${this.state.createdComplianceCode}`}</div>}
                    { this.state.createdTripId && <div className={'fs16 fw550'}>{`Trip Id: #${this.state.createdTripId}`}</div>}
                </div>
                <div className = {'fdr flx1 aic jcc'}>
                    <Link to='/dispatch/dashboard' className={'pl15 pr15 mt10 mb10 w120 h150 mr15'} style={{ textDecoration: 'unset', color: 'unset' }}>
                        <PopupActionButton
                            text={'GO TO DISPATCH DASHBOARD'}
                            icon={<DashboardIcon/>}
                            disabled={this.state.isLoading}
                            styleButton={'w120 h150'}/>
                    </Link>
                    <PopupActionButton
                        text={'CREATE ANOTHER DISPATCH'}
                        icon={<AddIcon/>}
                        disabled={this.state.isLoading || this.state.activeDialog !== 2}
                        onClick={this.createAnother}
                        styleButton={'pl15 pr15 mt15 mb10 w120 mr20 ml20 h150'}/>
                </div>
            </div>
        );
    };

    private truckTypeValidationNoClicked = () => this.setState({ activeDialog: undefined });
    private truckTypeValidationYesClicked = () => this.setState({ activeDialog: undefined }, this.showComplianceDialog);

    private renderTripTruckTypeValidationPopup = () => {
        if (this.state.selectedStocks.length > 0) {
            if (this.state.newTrip || this.state.selectedTripId) {
                const trip = this.state.trips.find(x => this.state.newTrip ? this.state.newTrip.id === x.id : x.id === this.state.selectedTripId);

                const dispatchesLinkedToTrip = this.props.dispatchInstructions.filter(x => x.isActive && x.tripId === trip?.id);

                let dispatchLines : Array<IDispatchInstructionLine> = [];

                dispatchesLinkedToTrip.forEach((x) => {
                    x.dispatchLines.filter(y => y.isActive).forEach((y) => {
                        dispatchLines = addArrayElement(dispatchLines, y, 'end');
                    });
                });

                const currentStocks = this.props.stocks.filter(x => dispatchLines.some(y => y.currentStockId === x.id));

                const currentStockTotalWeight = currentStocks.length > 0 ? currentStocks.map(x => x.grossWeight).reduce((sum, current) => sum + current, 0) : 0;

                const selectedStockIds = this.state.selectedStocks.map(x => x.stockId);
                const selectedStocks = this.props.stocks.filter(x => selectedStockIds.some(y => y === x.id));
                const selectedStockTotalWeight = selectedStocks.length > 0 ? selectedStocks.map(x => x.grossWeight).reduce((sum, current) => sum + current, 0) : 0;

                const totalWeight = currentStockTotalWeight + selectedStockTotalWeight;
                const totalPallets = dispatchLines.length + selectedStockIds.length;

                if (trip && trip.truckTypeId) {
                    const truckType = this.props.truckTypes.find(x => trip?.truckTypeId === x.id);
                    if (truckType && truckType?.maxPayload && truckType?.percentageWarning && (totalWeight > truckType?.maxPayload)) {
                        return (
                            <div className={'fdc p10 w300'}>
                                <div className={'fdc wfill mt10'}>
                                    <div className={'fdr jcfs fw500'}>Trip:</div>
                                    <div>{'Current total weight and pallets on trip'}</div>
                                    <div className={'fdr'}>
                                        <div className={'mr5'}>{'Total Weight:'}</div>
                                        <div className={'fw500'}>{`${currentStocks.length > 0 ? currentStocks.map(x => x.grossWeight).reduce((sum, current) => sum + current, 0) : ''} kg`}</div>
                                        <div className={'flx1'}/>
                                        <div className={'mr5'}>{'Pallets:'}</div>
                                        <div className={'fw500'}>{dispatchLines.length}</div>
                                    </div>
                                </div>
                                <div className={'fdc wfill mt10'}>
                                    <div className={'fdr jcfs fw500'}>Dispatch:</div>
                                    <div>{'Weight and pallets that will be added to trip'}</div>
                                    <div className={'fdr'}>
                                        <div className={'mr5'}>{'Total Weight:'}</div>
                                        <div className={'fw500'}>{`${selectedStockTotalWeight} kg`}</div>
                                        <div className={'flx1'}/>
                                        <div className={'mr5'}>{'Pallets:'}</div>
                                        <div className={'fw500'}>{selectedStockIds.length}</div>
                                    </div>
                                </div>
                                <div className={'fdc wfill mt10'}>
                                    <div className={'fdr jcfs fw500'}>Truck Type:</div>
                                    <div className={'fdr'}>
                                        <div>{`${truckType.code} - ${truckType.description}`}</div>
                                        <div className={'flx1'}/>
                                        <div className={'mr5'}>{'Max Payload:'}</div>
                                        <div className={'fw500'}>{`${truckType.maxPayload} kg`}</div>
                                    </div>
                                </div>
                                <div className={'fdc wfill mt10'}>
                                    <div className={'fdr jcfs fw500'}>Total All Together:</div>
                                    <div className={'fdr'}>
                                        <div className={'mr5'}>{'Total Weight:'}</div>
                                        <div className={'fw500'}>{`${totalWeight} kg`}</div>
                                        <div className={'flx1'}/>
                                        <div className={'mr5'}>{'Pallets:'}</div>
                                        <div className={'fw500'}>{totalPallets}</div>
                                    </div>
                                </div>
                                <div className={'fdr flx1 mt20'}>
                                    <div className={'flx1'}/>
                                    <Button
                                        className={'fwb h35'}
                                        variant='text'
                                        color='primary'
                                        disabled={this.state.isLoading}
                                        onClick={this.truckTypeValidationNoClicked}>
                                        No
                                    </Button>
                                    <PillButton color={'secondary'}
                                        className={'ml15 pl20 pr20 h35'}
                                        type={'submit'}
                                        text={'Yes'}
                                        onClick={this.truckTypeValidationYesClicked}
                                        disabled={this.state.isLoading} />
                                </div>
                            </div>
                        );
                    }
                }
            }
        }
    };

    private renderNewTripRemovalConfirmation = () => {
        return (
            <ConfirmationPrompt
                title={'Remove New Trip'}
                open={this.state.activeDialog === 6}
                message={'Deselecting this trip will remove it completely. Are you sure?'}
                onOkClicked={this.removeNewTrip}
                onCancelClicked={() => this.setState({ activeDialog: undefined, isLoading: false })}
            />
        );
    };

    private steps = [
        'Select Dispatch Details',
        'Select Stock',
        'Create Dispatch',
    ];

    public render() {
        return (
            <Screen isPadded={false} isScrollable={false} isLoading={this.state.isLoading}>
                <div className={`fdc flx1 disableSelect posr p20 ${this.state.currentPage === 3 ? '' : 'oyh'}`}>
                    <Stepper activeStep={this.state.currentPage - 1} className={'pb20'}>
                        {
                            this.steps.map(label => <Step key={label}>
                                <StepLabel style={{ transform: 'scale(1.2)' }} className={'pr15 pl15'}>{label}</StepLabel>
                            </Step>)
                        }
                    </Stepper>
                    {this.renderPage()}
                    {this.renderDialog()}
                </div>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        organizations: state.masterData.organizations,
        sites: state.masterData.sites,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        selectedSiteIds: state.data.selectedSiteIds,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        packs: state.masterData.packs,
        sizes: state.masterData.sizes,
        colours: state.masterData.colours,
        grades: state.masterData.grades,
        farms: state.masterData.farms,
        inventories: state.masterData.inventories,
        compliances: state.data.compliances,
        dispatchInstructions: state.data.dispatchInstructions,
        stocks: state.data.stocks,
        storageUnits: state.masterData.storageUnits,
        carriers: state.masterData.carriers,
        trips: state.data.trips,
        palletBaseTypes: state.masterData.palletBaseTypes,
        markets: state.masterData.markets,
        brands: state.masterData.brands,
        truckTypes: state.masterData.truckTypes,
        salesPoints: state.masterData.salesPoints,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    {
        dataSetEditingTrip,
        dataSetTrips,
        dataSetDispatchInstructionStocks,
        dataSetCompliances,
        dataSetSalesPoints,
    },
    dispatcher,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DispatchInstructionWizard);
