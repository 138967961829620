import * as React from 'react';
import { Card, Icon } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetSites, dataSetInspectionPoints, dataSetContactInfos, dataSetFtpDetails } from '../../../store/masterData/Actions';
import { formatDateTime, getGeomLat, getGeomLong, isNullOrWhiteSpace, upsertArrayElement } from '../../../services/appFunctionsService';
import { IOrganization } from '../../../@types/model/masterData/organization/organization';
import { ISite, Site } from '../../../@types/model/masterData/site/site';
import { IInspectionPoint } from '../../../@types/model/masterData/inspectionPoint/inspectionPoint';
import SiteHttpService from '../../../services/http/masterData/siteHttpService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import InspectionPointHttpService from '../../../services/http/masterData/inspectionPointHttpService';
import Screen from '../../../components/Screen';
import { ISiteFormValues, SiteFormValues } from '../../../@types/model/masterData/site/siteFormValues';
import { FormikHelpers, Formik } from 'formik';
import { createSelector } from 'reselect';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import SiteForm from './form/SiteForm';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import { Link } from 'react-router-dom';
import FloatingActionButton from '../../../components/input/FloatingActionButton';
import { ConfirmationDialog, CustomTable } from '@zz2/zz2-ui';
import { v4 as uuidv4 } from 'uuid';
import { IContactInfo } from '../../../@types/model/masterData/contactInfo/contactInfo';
import ContactInfoHttpService from '../../../services/http/masterData/contactInfoHttpService';
import CustomTooltip from '../../../components/tooltip/tooltip';
import { setSiteMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';
import { IFtpDetail } from '../../../@types/model/masterData/ftpDetail/ftpDetail';
import FtpDetailHttpService from '../../../services/http/masterData/ftpDetailHttpService';

interface ISiteScreenProps extends RouteComponentProps {
    dataSetSites : DispatchCall<Array<ISite>>;
    dataSetContactInfos : DispatchCall<Array<IContactInfo>>;
    dataSetInspectionPoints : DispatchCall<Array<IInspectionPoint>>;
    dataSetFtpDetails : DispatchCall<Array<IFtpDetail>>;
    sites : Array<ISite>;
    inspectionPoints : Array<IInspectionPoint>;
    selectedOrganizationIds : Array<number>;
    organizations : Array<IOrganization>;
    contactInfos : Array<IContactInfo>;
    ftpDetails : Array<IFtpDetail>;
    auth : IAuthState;
}

interface ISiteScreenState {
    rows : Array<ISite>;
    isLoading : boolean;
    selectedSite ?: ISite;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    formGuid ?: string;
    isDeletePopupOpen : boolean;
    deletingSite ?: ISite;
}

class SiteScreen extends React.Component<ISiteScreenProps, ISiteScreenState> {
    constructor(props : ISiteScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedSite: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await SiteHttpService.getSiteData();
                const res2 = await ContactInfoHttpService.getContactInfoData();
                const res3 = await FtpDetailHttpService.getFtpDetailData();

                this.props.dataSetSites(res.data);
                this.props.dataSetContactInfos(res2.data);
                this.props.dataSetFtpDetails(res3.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading sites.');
                this.setLoading(false);
            }

            try {
                const res = await InspectionPointHttpService.getInspectionPointData();

                this.props.dataSetInspectionPoints(res.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading inspection points.');
                this.setLoading(false);
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    private getInspectionPointCode = (id : number) => {
        const item = this.props.inspectionPoints.find(x => x.id === id);
        return item ? item.code : '';
    };

    private formatOrganizations = (organizationIds : Array<number>) => this.props.organizations
        .filter(x => organizationIds.some(y => y === x.id))
        .map(x => x.code).toString().replace(/,/g, ', ');

    private formatContactInfos = (contactInfoIds : Array<number>) => this.props.contactInfos
        .filter(x => contactInfoIds.some(y => y === x.id))
        .map(x => x.name).toString().replace(/,/g, ', ');

    private formatFtpDetails = (ftpDetailIds : Array<number>) => this.props.ftpDetails
        .filter(x => ftpDetailIds?.some(y => y === x.id))
        .map(x => x.name).toString().replace(/,/g, ', ');

    private getRows = () => this.props.sites.filter(x => x.organizationIds.some(y => this.props.selectedOrganizationIds?.some(z => z === y)));

    public onReset = async (formValues : ISiteFormValues, formikHelpers : FormikHelpers<ISiteFormValues>) => {
        formikHelpers.resetForm();
        this.closeDialog();
    };

    public getSelectedSites = (props : ISiteScreenProps, state : ISiteScreenState) => state.selectedSite;
    public getInspectionPoints = (props : ISiteScreenProps) => props.inspectionPoints;
    public getOrganizations = (props : ISiteScreenProps) => props.organizations;
    public getContactInfos = (props : ISiteScreenProps) => props.contactInfos;
    public getFtpDetails = (props : ISiteScreenProps) => props.ftpDetails;

    public getInitialFormValues = createSelector(
        [this.getSelectedSites, this.getInspectionPoints, this.getOrganizations, this.getContactInfos, this.getFtpDetails],
        (site, inspectionPoints, organizations, contactInfos, ftpDetails) => {
            return new SiteFormValues(site, inspectionPoints, organizations, contactInfos, ftpDetails);
        },
    );

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            formGuid: undefined,
            selectedSite: undefined,
        });
    };

    public openDialog = () => {
        this.setState({
            formGuid: uuidv4(),
            isDialogOpen: true,
        });
    };

    public editSite = (site : ISite) => {
        this.setState({
            isDialogOpen: true,
            selectedSite: site,
        });
        this.openDialog();
    };

    public onSubmit = async (values : ISiteFormValues) => {
        this.setLoading(true);

        let payload = { ...values };
        if (isNullOrWhiteSpace(payload.guid)) {
            payload.guid = this.state.formGuid ?? '';
        }

        try {
            const res = await SiteHttpService.addOrUpdateSite(new Site(payload));

            const newSiteList = upsertArrayElement(this.props.sites, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetSites(newSiteList);
            await setSiteMasterDataIndexedDB(newSiteList);

            if (this.state.selectedSite) {
                generalShowSuccessSnackbar('Site updated successfully.');
            } else {
                generalShowSuccessSnackbar('Site added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating site data.');
        } finally {
            this.setLoading(false);
        }
    };

    private openDeleteConfirmationPopup = (site : ISite) => {
        this.setState({ isDeletePopupOpen: true, deletingSite: site });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingSite: undefined });
    };

    private removeSite = async () => {
        const newSite = this.state.deletingSite;
        if (newSite) {
            newSite.isActive = false;
            this.setLoading(true);

            try {
                const res = await SiteHttpService.deleteSite(newSite.id);

                const newSiteList = upsertArrayElement(this.props.sites, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetSites(newSiteList);
                await setSiteMasterDataIndexedDB(newSiteList);

                generalShowSuccessSnackbar('Site updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting site.');
                newSite.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    private getRights = (props : ISiteScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : ISiteScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.getRows();
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading} isScrollable={false}>
                <PackmanDialog
                    title='Site'
                    isEdit={!!this.state.selectedSite}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}
                    fullScreen>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={SiteFormValues.formSchema}
                        component={SiteForm} />
                </PackmanDialog >
                <div style={{ right: 310, bottom: 25 }} className={`${this.state.isLoading ? 'dn' : 'fdr posa zi999'}`}>
                    <CustomTooltip title={'Map of Sites'}>
                        <Link to={'/overview/sitesMap'} style={{ textDecoration: 'unset', color: 'unset' }}>
                            <FloatingActionButton
                                color={'secondary'}
                                size={'medium'}
                                className={'mr5 p0 aic jcc'}
                            >
                                <Icon className={'cpd'} fontSize={'medium'} >room</Icon>
                            </FloatingActionButton>
                        </Link>
                    </CustomTooltip>
                </div>
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<ISite>
                            enableAdding={this.hasEditingRight(this.props) && this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.editSite}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(site : ISite) => site.isActive}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            enablePagination
                            columns={[
                                { title: 'Code', field: 'code', enableFiltering: true, enableSorting: true },
                                { title: 'Description', field: 'description', enableFiltering: true, enableSorting: true },
                                { title: 'Short Description', field: 'shortDescription', enableFiltering: true, enableSorting: true },
                                { title: 'Organizations', field: 'organizationIds', formatFunction: this.formatOrganizations, width: 200, enableFiltering: true, enableSorting: true },
                                { title: 'Contact Info', field: 'contactInfoIds', formatFunction: this.formatContactInfos, width: 200, enableFiltering: true, enableSorting: true },
                                { title: 'Packhouse?', field: 'isPackhouse', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'BRC Compliant?', field: 'isBRCCompliant', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Is Simple?', field: 'isSimple', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Delivery Point?', field: 'isDeliveryPoint', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Handles Export?', field: 'handlesExport', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Can Mix Sales Point?', field: 'canMixSalesPoint', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Exporter Name', field: 'exporterName', enableFiltering: true, enableSorting: true },
                                { title: 'Exporter Email', field: 'exporterEmail', enableFiltering: true, enableSorting: true },
                                { title: 'Global Gap Number', field: 'globalGapNumber', enableFiltering: true, enableSorting: true },
                                { title: 'Address', field: 'address', enableFiltering: true, enableSorting: true },
                                { title: 'Tel Number', field: 'telNumber', enableFiltering: true, enableSorting: true },
                                { title: 'Fax Number', field: 'faxNumber', enableFiltering: true, enableSorting: true },
                                { title: 'Packhouse Date Code', field: 'packhouseDateCode', enableFiltering: true, enableSorting: true },
                                { title: 'Latitude', field: 'geom', formatFunction: getGeomLat, enableFiltering: true, enableSorting: true },
                                { title: 'Longitude', field: 'geom', formatFunction: getGeomLong, enableFiltering: true, enableSorting: true },
                                { title: 'Inspection Point', field: 'inspectionPointId', formatFunction: this.getInspectionPointCode, enableFiltering: true, enableSorting: true },
                                { title: 'FtpDetails', field: 'ftpDetailIds', formatFunction: this.formatFtpDetails, width: 200, enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : ISite) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationDialog
                    title={'Delete Site'}
                    open={this.state.isDeletePopupOpen}
                    description ={'Are you sure you want to delete this site?'}
                    onAccept ={this.removeSite}
                    onClose ={this.closeDeleteConfirmationPopup}
                    dialogType='orange'
                    isLoading={this.state.isLoading}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        sites: state.masterData.sites,
        inspectionPoints: state.masterData.inspectionPoints,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        organizations: state.masterData.organizations,
        contactInfos: state.masterData.contactInfos,
        ftpDetails: state.masterData.ftpDetails,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetSites, dataSetInspectionPoints, dataSetContactInfos, dataSetFtpDetails }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SiteScreen);
