import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState, RootAction } from '../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IStock } from '../../@types/model/stock/stock';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { ISite } from '../../@types/model/masterData/site/site';
import { IMarket } from '../../@types/model/masterData/market/market';
import { IBrand } from '../../@types/model/masterData/brand/brand';
import { IRegion } from '../../@types/model/masterData/region/region';
import { ICountry } from '../../@types/model/masterData/country/country';
import { CustomChangeEvent, IOptionType } from '../../@types/helper';
import { createSelector } from 'reselect';
import { addArrayElement, removeArrayElement, setArrayElement } from '../../services/appFunctionsService';
import AutocompleteSelect from '../../components/input/AutoCompleteSelect';
import { Button, Icon, IconButton, TextField } from '@mui/material';
import PillButton from '../../components/input/PillButton';
import { IAllocateMaterialStock } from '../../@types/model/stock/allocateMaterialsToStock';
import { IMaterialStock } from '../../@types/model/materialStock/materialStock';
import { IMaterialStockLine } from '../../@types/model/materialStock/materialStockLine';
import moment from 'moment';
import { IAllocateMaterials } from '../../@types/model/stock/allocateMaterials';

interface IAllocateMaterialsToStockDialogProps {
    selectedStock : IStock;
    materialsAlreadyOnStock ?: Array<IAllocateMaterials>;
    submit : (allocatedMaterials : Array<IAllocateMaterials>) => void;
    closeMaterialAllocationToStockDialog : () => void;
    isLoading : boolean;
    organizations : Array<IOrganization>;
    sites : Array<ISite>;
    stockData : Array<IStock>;
    markets : Array<IMarket>;
    brands : Array<IBrand>;
    regions : Array<IRegion>;
    countries : Array<ICountry>;
    materialStockData : Array<IMaterialStock>;
    selectedSiteIds : Array<number> ;
}

interface IAllocateMaterialsToStockDialogState {
    allocatedMaterialsToStock : Array<IAllocateMaterialStock>;
    materialsToBeAllocatedToStock : Array<IAllocateMaterialStock>;
    selectedMaterialStock ?: IOptionType;
    materialStockAmount ?: number;
}

class AllocateMaterialsToStockDialog extends React.Component<IAllocateMaterialsToStockDialogProps, IAllocateMaterialsToStockDialogState> {
    constructor(props : IAllocateMaterialsToStockDialogProps) {
        super(props);

        this.state = {
            allocatedMaterialsToStock: [],
            materialsToBeAllocatedToStock: [],
        };
    }

    public componentDidMount = () => {
        if (this.props.selectedStock) {
            this.setState({ allocatedMaterialsToStock: this.getAllocatedMaterials() ?? [] });
        }
    };

    private getAllocatedMaterials = () => {
        const selectedStock = this.props.selectedStock;
        const materialStocks = this.props.materialStockData;

        if (selectedStock && materialStocks) {
            let lines : Array<IMaterialStockLine> = [];
            materialStocks.filter(x => x.isActive).forEach((x) => {
                x.materialStockLines.forEach((y) => {
                    if (y.stockId === selectedStock.id) {
                        lines = addArrayElement(lines, y);
                    }
                });
            });

            let materials : Array<IAllocateMaterialStock> = [];

            if (lines.length > 0) {
                materials = lines.map((x) => {
                    return { stockId: x.stockId, amount: x.amount, materialStock: this.getMaterialStock(x.materialStockId) ?? { label: '', value: 0 } };
                });
            }

            if (this.props.materialsAlreadyOnStock && this.props.materialsAlreadyOnStock.length > 0) {

                this.props.materialsAlreadyOnStock.forEach((x) => {
                    const material : IAllocateMaterialStock = {
                        materialStock: this.getMaterialStock(x.materialStockId) ?? { label: '', value: 0 },
                        amount: x.amount,
                        submittedOn: x.submittedOn,
                    };

                    materials.push(material);
                });
            }

            return materials;
        }
    };

    private getMaterialStock = (materialStockId : number) => {
        const materialStocks = this.props.materialStockData;
        const materialStock = materialStocks.find(x => x.id === materialStockId);

        if (materialStock) {
            return { label: materialStock.dateCode, value: materialStock.id };
        }
    };

    private getMaterialStocks = (props : IAllocateMaterialsToStockDialogProps) => props.materialStockData;
    private getSelectedSites = (props : IAllocateMaterialsToStockDialogProps) => props.selectedSiteIds;

    private getMaterialStockOptions = createSelector(
        [this.getMaterialStocks, this.getSelectedSites],
        (materialStocks, selectedSiteIds) => {
            if (!materialStocks) return [];

            return materialStocks.filter(x => x.isActive && selectedSiteIds?.some(y => y === x.siteId)).map((x) => {
                return { label: `(${x.id}) ` + x.dateCode, value: x.id };
            });
        },
    );

    private onExistingMaterialStockChange = (id : number, e : CustomChangeEvent, selectedMaterialStock : IOptionType) => {
        const index = this.state.allocatedMaterialsToStock.findIndex(x => Number(x.materialStock.value) === id);
        const materialStock = this.state.allocatedMaterialsToStock.find(x => Number(x.materialStock.value) === id);
        const newMaterial : IAllocateMaterialStock = {
            materialStock: selectedMaterialStock,
            amount: materialStock?.amount ?? 0,
        };
        this.setState({ allocatedMaterialsToStock: setArrayElement(this.state.allocatedMaterialsToStock, index, newMaterial) });
    };

    private onNewMaterialStockChange = (id : number, e : CustomChangeEvent, selectedMaterialStock : IOptionType) => {
        const index = this.state.materialsToBeAllocatedToStock.findIndex(x => Number(x.materialStock.value) === id);
        const materialStock = this.state.materialsToBeAllocatedToStock.find(x => Number(x.materialStock.value) === id);
        const newMaterial : IAllocateMaterialStock = {
            materialStock: selectedMaterialStock,
            amount: materialStock?.amount ?? 0,
        };
        this.setState({ materialsToBeAllocatedToStock: setArrayElement(this.state.materialsToBeAllocatedToStock, index, newMaterial) });
    };

    private onMaterialStockAmountChange = (e : React.ChangeEvent<HTMLInputElement>, id : number) => {
        const index = this.state.allocatedMaterialsToStock.findIndex(x => Number(x.materialStock.value) === id);
        const materialStock = this.state.allocatedMaterialsToStock.find(x => Number(x.materialStock.value) === id)?.materialStock;
        if (materialStock) {
            const newMaterial : IAllocateMaterialStock = {
                materialStock,
                amount: Number(e.target.value),
            };
            this.setState({ allocatedMaterialsToStock: setArrayElement(this.state.allocatedMaterialsToStock, index, newMaterial) });
        }
    };

    private onNewMaterialStockAmountChange = (e : React.ChangeEvent<HTMLInputElement>, id : number) => {
        const index = this.state.materialsToBeAllocatedToStock.findIndex(x => Number(x.materialStock.value) === id);
        const materialStock = this.state.materialsToBeAllocatedToStock.find(x => Number(x.materialStock.value) === id)?.materialStock;
        if (materialStock) {
            const newMaterial : IAllocateMaterialStock = {
                materialStock,
                amount: Number(e.target.value),
            };
            this.setState({ materialsToBeAllocatedToStock: setArrayElement(this.state.materialsToBeAllocatedToStock, index, newMaterial) });
        }
    };

    private removeMaterialStock = (materialStock : IOptionType) => {
        const index = this.state.allocatedMaterialsToStock.findIndex(x => Number(x.materialStock.value) === materialStock.value);
        this.setState({ allocatedMaterialsToStock: removeArrayElement(this.state.allocatedMaterialsToStock, index) });
    };

    private onMaterialStockChange = (e : CustomChangeEvent, selectedMaterialStock : IOptionType) => {
        this.setState({ selectedMaterialStock });
    };

    private addMaterialStock = () => {
        if (this.state.selectedMaterialStock) {
            const newMaterial : IAllocateMaterialStock = {
                materialStock: this.state.selectedMaterialStock,
                amount: this.state.materialStockAmount ?? 0,
                submittedOn: moment().local().startOf('day'),
            };
            this.setState({ materialsToBeAllocatedToStock: addArrayElement(this.state.materialsToBeAllocatedToStock, newMaterial, 'end') }, () => {
                this.setState({ selectedMaterialStock: undefined, materialStockAmount: undefined });
            });
        }
    };

    private onAllocateMaterialStockToStockSubmit = () => {
        const materialsToBeAllocatedToStock = this.state.materialsToBeAllocatedToStock;
        let allocatedMaterialsToStock = this.state.allocatedMaterialsToStock;

        materialsToBeAllocatedToStock.forEach((x) => {
            const index = this.state.allocatedMaterialsToStock.findIndex(y => y.materialStock.value === Number(x.materialStock.value));
            if (index === -1) {
                allocatedMaterialsToStock = addArrayElement(allocatedMaterialsToStock, x);
            } else {
                allocatedMaterialsToStock = setArrayElement(allocatedMaterialsToStock, index, x);
            }
        });

        const materials = Array<IAllocateMaterials>();

        materialsToBeAllocatedToStock.forEach((x) => {
            const material : IAllocateMaterials = {
                materialStockId: Number(x.materialStock.value),
                amount: x.amount,
                submittedOn: x.submittedOn,
            };

            materials.push(material);
        });

        this.setState({ allocatedMaterialsToStock, materialsToBeAllocatedToStock: [] });
        this.props.submit(materials);

    };

    public render() {
        return (
            <div className={'p20 fdc aic jcc'}>
                {this.state.allocatedMaterialsToStock.length >= 1 ?
                    this.state.allocatedMaterialsToStock.map((x) => {
                        return (
                            <div className={'mb10 fdr aic jcc'}>
                                <AutocompleteSelect
                                    className={'wfill'}
                                    name={'materialStockId'}
                                    label={'Material Stock'}
                                    options={this.getMaterialStockOptions(this.props)}
                                    onChange={(e : CustomChangeEvent, v : any) => this.onExistingMaterialStockChange(Number(x.materialStock.value), e, v)}
                                    value={x.materialStock}
                                />
                                <TextField
                                    style={{ width: 227 }}
                                    value={x.amount}
                                    label={'Amount'}
                                    type={'number'}
                                    onChange={(e : React.ChangeEvent<HTMLInputElement>) => this.onMaterialStockAmountChange(e, Number(x.materialStock.value))}
                                />
                                <div className={'pl10'}>
                                    <IconButton className={'cpd bcw p0 m0'} onClick={() => {
                                        return this.removeMaterialStock(x.materialStock);
                                    }}>
                                        <Icon fontSize={'large'}>delete</Icon>
                                    </IconButton>
                                </div>
                            </div>
                        );
                    })
                    :
                    <div/>
                }
                {this.state.materialsToBeAllocatedToStock.length >= 1 ?
                    this.state.materialsToBeAllocatedToStock.map((x) => {
                        return (
                            <div className={'mb10 fdr aic jcc'}>
                                <AutocompleteSelect
                                    className={'wfill'}
                                    name={'materialStockId'}
                                    label={'Material Stock'}
                                    options={this.getMaterialStockOptions(this.props)}
                                    onChange={(e : CustomChangeEvent, v : any) => this.onNewMaterialStockChange(Number(x.materialStock.value), e, v)}
                                    value={x.materialStock}
                                />
                                <TextField
                                    style={{ width: 227 }}
                                    value={x.amount}
                                    label={'Amount'}
                                    type={'number'}
                                    onChange={(e : React.ChangeEvent<HTMLInputElement>) => this.onNewMaterialStockAmountChange(e, Number(x.materialStock.value))}
                                />
                                <div className={'pl10'}>
                                    <IconButton className={'cpd bcw p0 m0'} onClick={() => {
                                        return this.removeMaterialStock(x.materialStock);
                                    }}>
                                        <Icon fontSize={'large'}>delete</Icon>
                                    </IconButton>
                                </div>
                            </div>
                        );
                    })
                    :
                    <div/>
                }
                <div className={'mb10 fdr aic jcc'}>
                    <AutocompleteSelect
                        className={'wfill'}
                        name={'materialStockId'}
                        label={'Material Stock'}
                        options={this.getMaterialStockOptions(this.props)}
                        onChange={this.onMaterialStockChange}
                        value={this.state.selectedMaterialStock}
                    />
                    <TextField
                        style={{ width: 227 }}
                        value={this.state.selectedMaterialStock !== undefined ? this.state.materialStockAmount : undefined}
                        label={'Amount'}
                        type={'number'}
                        onChange={(e : React.ChangeEvent<HTMLInputElement>) => this.setState({ materialStockAmount: Number(e.target.value) })}
                    />
                    <div className={'pl10'}>
                        <PillButton
                            text={''}
                            icon={<Icon className={'cpd p0 m0'} fontSize={'large'}>
                                    add_circle_outline
                            </Icon>}
                            color={'secondary'}
                            size={'small'}
                            className={'p0'}
                            disabled={(!this.state.selectedMaterialStock && !!(this.state.materialStockAmount === undefined || this.state.materialStockAmount < 1))}
                            onClick={() => this.addMaterialStock()}/>
                    </div>
                </div>
                <div className={'fdr aife jcfe wfill '}>
                    <Button
                        className={'fwb h35'}
                        variant='text'
                        color='primary'
                        onClick={this.props.closeMaterialAllocationToStockDialog}>
                        Cancel
                    </Button>
                    <PillButton
                        disabled={this.state.materialsToBeAllocatedToStock.length < 1}
                        color={'secondary'}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Submit'}
                        onClick={this.onAllocateMaterialStockToStockSubmit}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        stockData: state.data.stocks,
        organizations: state.masterData.organizations,
        sites: state.masterData.sites,
        markets: state.masterData.markets,
        brands: state.masterData.brands,
        regions: state.masterData.regions,
        countries: state.masterData.countries,
        materialStockData: state.data.materialStocks,
        selectedSiteIds: state.data.selectedSiteIds,
    };
};

const mapDispatchToProps  = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AllocateMaterialsToStockDialog);
