import * as React from 'react';
import {
    dataSetIntakes,
    dataSetIntakeViews,
} from '../../store/data/Actions';
import { dataSetCommodityStates } from '../../store/masterData/Actions';
import { RootAction, IRootState, IAuthState, DispatchCall } from '../../@types/redux';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CustomTable from '../../components/datagrid/CustomTable';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import { Typography, Paper } from '@mui/material';
import { dataSetIntakeSummaryRelatedData, dataSetIntakeView, dataSetIntakeViewRelatedData } from '../../store/data/Functions';
import { formatDateTime, compareDate, addArrayElement } from '../../services/appFunctionsService';
import { ILot } from '../../@types/model/lot/lot';
import { ILotType } from '../../@types/model/masterData/lotType/lotType';
import { IIntake, Intake } from '../../@types/model/intake/intake';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IFarm } from '../../@types/model/masterData/farm/farm';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IOrchard } from '../../@types/model/masterData/orchard/orchard';
import { ISite } from '../../@types/model/masterData/site/site';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import Screen from '../../components/Screen';
import materialTheme from '../../styles/materialTheme';
import IntakeLineSummary from './IntakeLineSummary';
import { Formik, FormikHelpers } from 'formik';
import { IntakeFormValues, IIntakeFormValues } from '../../@types/model/intake/form/intakeFormValues';
import IntakeForm from './form/IntakeForm';
import { createSelector } from 'reselect';
import PackmanDialog from '../../components/dialog/PackmanDialog';
import IntakeHttpService from '../../services/http/intake/intakeHttpService';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { IPalletBaseType } from '../../@types/model/masterData/palletBaseType/palletBaseType';
import moment from 'moment';
import TransactionFilter from '../../components/filters/BasicTransactionScreenFilter';
import { IRight } from '../../@types/model/user/right';
import { RouteComponentProps } from 'react-router';
import { IStorageUnit } from '../../@types/model/masterData/storageUnit/storageUnit';
import { IIntakeLine } from '../../@types/model/intake/intakeLine';
import lodash from 'lodash';
import { ICommodityState } from '../../@types/model/masterData/commodityState/commodityState';
import PillButton from '../../components/input/PillButton';
import {  PDFDownloadLink, Document, Page, View, Image, Text, StyleSheet, Font } from '@react-pdf/renderer';
import Code39 from '../../fonts/code39.ttf';
import { IDocumentProps } from '../../@types/other';
import ReactDOM from 'react-dom';
import { DATE_FORMAT_DEFAULT } from '../../appConstants';
import { getState } from '../../store/Index';
import { IIntakeLineLayer } from '../../@types/model/intake/intakeLineLayer';
import { IIntakeView } from '../../@types/model/intake/intakeView';
import { getSiteLocalStorage } from '../../services/localStorageService';
import FileSaver from 'file-saver';
import { navIntake, navPath } from '../../store/nav/Actions';
import { PackmanMultiLink } from '../../components/link/packmanMultiLink';
import { PackmanLabel } from '../../components/label/PackmanLabel';
import { syncMasterData } from '../../services/masterDataSyncService';
import { IProject } from '../../@types/model/masterData/project/project';
import { BooleanFlag } from '../../components/label/BooleanFlag';
import DeleteConfirmationDialog from '../../components/dialog/DeleteConfirmationDialog';
import { IReportFileFormValues, ReportFileFormValues } from '../../@types/model/reportFile/reportFileFormValues';
import PrintServerReportHttpService from '../../services/http/printServerReport/printServerReportHttpService';
import { IReport } from '../../@types/model/masterData/report/report';
import { IPrintServer } from '../../@types/model/masterData/printServer/printServer';
import ReportFileForm from '../../components/reportFile/ReportFileForm';

const styles = StyleSheet.create({
    section: {
        margin: 10,
        padding: 10,
        flex: 1,
    },
    page: {
        paddingTop: 25,
        paddingBottom: 65,
        marginBottom: 65,
    },
    pageNumber: {
        fontSize: 11,
        position: 'absolute',
        bottom: 35,
        right: 25,
    },
    pageMargin: {
        marginLeft: 25,
        marginRight: 25,
        flex: 1,
    },
    tableRow: {
        borderBottom: '1pt solid black',
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: 8,
        flexDirection: 'row',
    },
    findingSheetColumn: {
        fontSize: 8,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 5,
        paddingTop: 5,
        borderRight: '1pt solid black',
        alignItems: 'center',
    },
    fdc: { flexDirection: 'column' },
    fdr: { flexDirection: 'row' },
    verticalText: {
        transform: 'rotate(90deg)',
        width: 80,
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    barcode: {
        fontFamily: 'code39',
        fontSize: 20,
        transform: 'scaleY(2)',
        top: 10,
    },
    barcodeLabel: {
        fontSize: 9,
        letterSpacing: 2.5,
        top: 20,
    },
    fs6: { fontSize: 6 },
    fs8: { fontSize: 8 },
    fs10: { fontSize: 10 },
    fs11: { fontSize: 11 },
    fs12: { fontSize: 12 },
    fs13: { fontSize: 13 },
    fs14: { fontSize: 14 },
    fs15: { fontSize: 15 },
    bold: { fontWeight: 'bold' },
    pt5: { paddingTop: 5 },
    pt10: { paddingTop: 10 },
    pt35: { paddingTop: 35 },
    pt50: { paddingTop: 50 },
    pb5: { paddingBottom: 5 },
    pb10: { paddingBottom: 10 },
    pb35: { paddingBottom: 35 },
    pl2: { paddingLeft: 2 },
    pl4: { paddingLeft: 4 },
    pl5: { paddingLeft: 5 },
    pl20: { paddingLeft: 20 },
    pl40: { paddingLeft: 40 },
    pr2: { paddingRight: 2 },
    pr20: { paddingRight: 20 },
    pr80: { paddingRight: 80 },
    pr100: { paddingRight: 100 },
    mt5: { marginTop: 5 },
    mt10: { marginTop: 10 },
    mt15: { marginTop: 15 },
    mt20: { marginTop: 20 },
    mt35: { marginTop: 35 },
    mb5: { marginBottom: 5 },
    mb10: { marginBottom: 10 },
    mb35: { marginBottom: 35 },
    mb50: { marginBottom: 50 },
    flx1: { flex: 1 },
    blw1: { borderLeft: '1pt solid black' },
    brw1: { borderRight: '1pt solid black' },
    btw1: { borderTop: '1pt solid black' },
    bbw1: { borderBottom: '1pt solid black' },
    jcc: { justifyContent: 'center' },
    ail: { alignItems: 'left' },
    aic: { alignItems: 'center' },
    aife: { alignItems: 'flex-end' },
    tac: { textAlign: 'center' },
    posa: { position: 'absolute' },
    w50: { width: 50 },
    w100: { width: 100 },
    w140: { width: 140 },
    w200: { width: 200 },
    w280: { width: 280 },
    h40: { height: 40 },
    h80: { height: 80 },
    h90: { height: 90 },
    t5: { top: 5 },
    certCol1: {
        left: 0,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 1,
        position: 'absolute',
    },
    certCol2: {
        left: 35,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 2,
        position: 'absolute',
    },
    certCol3: {
        left: 70,
        width: 30,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 3,
        position: 'absolute',
    },
    certCol4: {
        left: 100,
        width: 25,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 4,
        position: 'absolute',
    },
    certCol5: {
        left: 125,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 5,
        position: 'absolute',
    },
    certCol6: {
        left: 160,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 6,
        position: 'absolute',
    },
    certCol7: {
        left: 195,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 7,
        position: 'absolute',
    },
    certCol8: {
        left: 230,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 8,
        position: 'absolute',
    },
    certCol9: {
        left: 265,
        width: 25,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 9,
        position: 'absolute',
    },
    certCol10: {
        left: 290,
        width: 30,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 10,
        position: 'absolute',
    },
    certCol11: {
        left: 320,
        width: 70,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 11,
        position: 'absolute',
    },
    certCol12: {
        left: 390,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 12,
        position: 'absolute',
    },
    certCol13: {
        left: 425,
        width: 85,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 13,
        position: 'absolute',
    },
    certCol14: {
        left: 510,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 14,
        position: 'absolute',
    },
});

Font.register({
    family: 'code39',
    src: Code39,
});

const getCommodity = (id : number) => {
    const state = getState();
    const commodity = state.masterData.commodities.find(x => x.id === id);
    return commodity ? commodity?.code + ' - ' + commodity?.name : '';
};

const getVariety = (id : number) => {
    const state = getState();
    const variety = state.masterData.varieties.find(x => x.id === id);
    return variety ? variety?.code + ' - ' + variety.name : '';
};

const getIntakeLineLayerVariety = (intake : IIntake, layerId : number) => {
    let layers : Array<IIntakeLineLayer> = [];

    intake.intakeLines.filter(x => x.isActive).forEach((line) => {
        line.intakeLineLayers.filter(x => x.isActive).forEach((layer) => {
            const index = layers.findIndex(x => x.id === layer.id);

            if (index === -1) {
                layers = addArrayElement(layers, layer, 'end');
            }
        });
    });

    const varietyId = layers.find(x => x.id === layerId)?.varietyId;
    return getVariety(varietyId ?? 0);
};

const getFarm = (id : number) => {
    const state = getState();
    const commodity = state.masterData.farms.find(x => x.id === id);
    return commodity ? commodity?.code + ' - ' + commodity?.name : '';
};

const getOrchard = (id : number) => {
    const state = getState();
    const commodity = state.masterData.orchards.find(x => x.id === id);
    return commodity ? commodity?.code + ' - ' + commodity?.name : '';
};

const getIntakeLineLayerOrchard = (intake : IIntake, layerId : number) => {
    let layers : Array<IIntakeLineLayer> = [];

    intake.intakeLines.filter(x => x.isActive).forEach((line) => {
        line.intakeLineLayers.filter(x => x.isActive).forEach((layer) => {
            const index = layers.findIndex(x => x.id === layer.id);

            if (index === -1) {
                layers = addArrayElement(layers, layer, 'end');
            }
        });
    });

    const orchardId = layers.find(x => x.id === layerId)?.orchardId;
    return getOrchard(orchardId ?? 0);
};

const getTotalNoUnits = (intakeLines : Array<IIntakeLine>) => {
    let totalUnits = 0;
    intakeLines.filter(x => x.isActive).forEach((line) => {
        line.intakeLineLayers.filter(x => x.isActive).forEach((layer) => {
            layer.intakeLineLayerUnits.filter(x => x.isActive).forEach((unit) => {
                totalUnits = totalUnits + unit.noOfUnits;
            });
        });
    });
    return totalUnits;
};

const getUnitTotalWeight = (intakeLines : Array<IIntakeLine>) => {
    let totalWeight = 0;
    intakeLines.filter(x => x.isActive).forEach((line) => {
        line.intakeLineLayers.filter(x => x.isActive).forEach((layer) => {
            layer.intakeLineLayerUnits.filter(x => x.isActive).forEach((unit) => {
                totalWeight = totalWeight + unit.nettWeight;
            });
        });
    });
    return totalWeight;
};

const getTableLines = (intake : IIntake) => {
    let rows : any = [];
    intake.intakeLines.filter(x => x.isActive).forEach((line) => {
        line.intakeLineLayers.filter(x => x.isActive).forEach((layer) => {
            layer.intakeLineLayerUnits.filter(x => x.isActive).forEach((unit) => {
                rows = addArrayElement(rows,
                    <View style={[styles.fdr, styles.bbw1]}>
                        <Text style={[styles.fs8, styles.pt5, styles.pb5, styles.pl5, styles.brw1, styles.blw1, styles.flx1]}>{`${getCommodity(intake.commodityId)}`}</Text>
                        <Text style={[styles.fs8, styles.pt5, styles.pb5, styles.pl5, styles.brw1, styles.flx1]}>{getIntakeLineLayerVariety(intake, unit.intakeLineLayerId)}</Text>
                        <Text style={[styles.fs8, styles.pt5, styles.pb5, styles.pl5, styles.brw1, styles.flx1]}>{getFarm(intake.farmId)}</Text>
                        <Text style={[styles.fs8, styles.pt5, styles.pb5, styles.pl5, styles.brw1, styles.flx1]}>{getIntakeLineLayerOrchard(intake, unit.intakeLineLayerId)}</Text>
                        <Text style={[styles.fs8, styles.pt5, styles.pb5, styles.pl5, styles.brw1, { width: 160 }]}>{unit.unitGuid}</Text>
                        <Text style={[styles.fs8, styles.pt5, styles.pb5, styles.pl5, styles.brw1, styles.w50]}>{unit.noOfUnits}</Text>
                        <Text style={[styles.fs8, styles.pt5, styles.pb5, styles.pl5, styles.brw1, styles.w50]}>{unit.nettWeight.toFixed(3)}</Text>
                    </View>, 'end');
            });
        });
    });
    return rows;
};

const createAndDownloadPDF = (pdfContent : () => React.ReactElement<IDocumentProps>, filename : string, divId : string, callback : () => void) => {
    setTimeout(
        () => {
            const link = (
                <div id={ divId }>
                    <PDFDownloadLink document={ pdfContent() } fileName={ filename }>{
                        ({ blob, loading }) => {
                            if (!loading) {
                                setTimeout(
                                    () => {
                                        if (blob) {
                                            FileSaver.saveAs(blob, filename);
                                        } else {
                                            generalShowErrorSnackbar('Could not download file.');
                                        }
                                    },
                                    1);
                            }
                        }}</PDFDownloadLink>
                </div>
            );
            const elem = document.createElement('div');
            const doc = document.getElementById('root');
            if (doc) {
                doc.appendChild(elem);
                ReactDOM.render(link, elem);
                setTimeout(
                    () => {
                        elem.remove();
                        callback();
                    },
                    1);
            }
        },
        1);
};

export const generateIntakeSlip = async (intake ?: IIntake, siteCode ?: string, callback ?: () => void) => buildIntakeSlipPDF(intakeSlipPDF, `${siteCode} - ${intake?.id} - Info Slip.pdf`, intake, callback);

export const intakeSlipPDF = (intake : IIntake) => {
    return <Document>
        <Page size='A4' style={styles.page}>
            <View style={styles.pageMargin}>
                <View style={styles.fdr}>
                    <Image style={{ height: 42 }} source={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`}/>
                    <Text style={[styles.fs13, styles.bold, styles.mt15, styles.pl20]}>PACKMAN</Text>
                    <View style={styles.flx1} />
                </View>
                <View style={[styles.fdr, styles.pb10]} fixed>
                    <View style={styles.flx1} />
                    <Text style={styles.fs8} fixed render={({ pageNumber, totalPages }) => (
                        `PAGE ${pageNumber} OF ${totalPages}`
                    )}> </Text>
                </View>
                <View style={[styles.fdr, styles.aic, styles.pt5, styles.pb5]}>
                    <Text style={styles.fs13}>{`INTAKE - ${intake.id}`}</Text>
                    <View style={styles.flx1} />
                    <Text style={styles.fs8}>{`DATE: ${moment().local().format(DATE_FORMAT_DEFAULT)}`}</Text>
                </View>
                <View style={[styles.fdr, styles.bbw1]}/>
                <View style={[styles.fdr]}>
                    <View style={[styles.fdc, styles.pr20]}>
                        <Text style={[styles.fs10, styles.pt10, styles.w280]}>{`INTAKE DATE: ${formatDateTime(intake.intakeDate ?? '')}`}</Text>
                        <Text style={[styles.fs10, styles.pb10, styles.pt10, styles.w280]}>{`SHIPMENT REFERENCE: ${intake.shipmentGuid ?? ''}`}</Text>
                    </View>
                    <View style={[styles.fdc]}>
                        <Text style={[styles.fs10, styles.pb10, styles.pt10, styles.w280, styles.pr20]}>{`Total No. Units: ${getTotalNoUnits(intake.intakeLines)}`}</Text>
                    </View>
                </View>
                <View style={[styles.fdr, styles.bbw1, styles.btw1]}>
                    <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl5, styles.brw1, styles.blw1, styles.flx1]}>{'COMMODITY' }</Text>
                    <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl5, styles.brw1, styles.flx1]}>{'VARIETY'}</Text>
                    <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl5, styles.brw1, styles.flx1]}>{'FARM'}</Text>
                    <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl5, styles.brw1, styles.flx1]}>{'ORCHARD'}</Text>
                    <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl5, styles.brw1, { width: 160 }]}>{'BARCODES'}</Text>
                    <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl5, styles.brw1, styles.w50]}>{'UNITS'}</Text>
                    <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl5, styles.brw1, styles.w50]}>{'NETT WEIGHT (kg)'}</Text>
                </View>
                {getTableLines(intake)}
                <View style={[styles.fdr]}>
                    <View style={[styles.flx1]}/>
                    <View style={[styles.fdc, styles.bbw1, styles.btw1, styles.blw1, styles.brw1, styles.w50, styles.aic, styles.jcc]}>
                        <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.flx1]}>{'Total:'}</Text>
                        <View style={[styles.bbw1, styles.pl5, { width: '100%' }]}/>
                        <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.flx1]}>{getTotalNoUnits(intake.intakeLines)}</Text>
                    </View>
                    <View style={[styles.fdc, styles.bbw1, styles.btw1, styles.brw1, styles.w50, styles.aic, styles.jcc]}>
                        <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.flx1]}>{'Total:'}</Text>
                        <View style={[styles.bbw1, styles.pl5, { width: '100%' }]}/>
                        <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.flx1]}>{getUnitTotalWeight(intake.intakeLines).toFixed(3)}</Text>
                    </View>
                </View>
            </View>
        </Page>
    </Document>;
};

export const buildIntakeSlipPDF = (document : any, fileName : string, intake ?: IIntake, callback ?: () => void) => {
    const doc : () => React.ReactElement<IDocumentProps> = () => document(intake);

    createAndDownloadPDF(
        doc,
        fileName,
        'pdf-creator-link',
        // tslint:disable-next-line: no-empty
        callback ? callback : () => {},
    );
};

interface IIntakeScreenProps extends RouteComponentProps {
    dataSetIntakes : DispatchCall<Array<IIntake>>;
    dataSetIntakeViews : DispatchCall<Array<IIntakeView>>;
    dataSetCommodityStates : DispatchCall<Array<ICommodityState>>;
    lots : Array<ILot>;
    lotTypes : Array<ILotType>;
    intakeViews : Array<IIntakeView>;
    varieties : Array<IVariety>;
    farms : Array<IFarm>;
    commodities : Array<ICommodity>;
    orchards : Array<IOrchard>;
    selectedLoadDate : string;
    sites : Array<ISite>;
    printServers : Array<IPrintServer>;
    reports : Array<IReport>;
    storageUnits : Array<IStorageUnit>;
    organizations : Array<IOrganization>;
    packs : Array<IPack>;
    projects : Array<IProject>;
    palletBaseTypes : Array<IPalletBaseType>;
    auth : IAuthState;
    selectedDispatchIndex ?: number;
    showOnlyMyDispatches : boolean;
    selectedOrganizationIds : Array<number> ;
    selectedSiteIds : Array<number> ;
}

interface IIntakeScreenState {
    isLoading : boolean;
    dataFetched : boolean;
    rows : Array<IIntakeView>;
    editingIntake ?: IIntake;
    isDialogOpen : boolean;
    isFormDialogOpen : boolean;
    selectedIntake ?: IIntake;
    selectedFromDate : moment.Moment;
    selectedToDate : moment.Moment;
    isDeleting : boolean;
    isDownloadReportFormOpen : boolean;
    deleteItem ?: IIntakeView;
}

class IntakeTable extends React.Component<IIntakeScreenProps, IIntakeScreenState> {
    constructor(props : IIntakeScreenProps) {
        super(props);

        this.state = {
            isLoading: false,
            dataFetched: false,
            rows: [],
            isDialogOpen: false,
            isFormDialogOpen: false,
            selectedIntake: undefined,
            isDeleting: false,
            deleteItem: undefined,
            editingIntake: undefined,
            isDownloadReportFormOpen: false,
            selectedFromDate: moment().local().startOf('day').subtract(7, 'days'),
            selectedToDate: moment().local().endOf('day'),
        };
    }

    public componentDidMount = async () => {
        this.setLoading(true);
        if (this.props.location.pathname?.includes(':')) {
            this.showSummaryDialog(Number(this.props.location.pathname.split(':')[1].split('/')[0]));
        } else {
            const selectedSiteIds = getSiteLocalStorage();

            if (!this.props.selectedSiteIds) return;

            if (!!self.indexedDB) {
                await syncMasterData(false);
            }

            try {
                // checks if indexedDB is available.
                const loadMasterData = !!self.indexedDB ? false : true;

                const res = await IntakeHttpService.getIntakeTransactionViewData(this.getDate('from'), this.getDate('to'), selectedSiteIds, loadMasterData);

                dataSetIntakeViewRelatedData(res.data);
                this.setState({ rows: this.filterData() }, () => {
                    this.setLoading(false);
                });
            } catch (e) {
                generalShowErrorSnackbar('An error occurred retrieving intake data.');
                this.setLoading(false);
            }
        }
    };

    public componentDidUpdate = async (prevProps : IIntakeScreenProps) => {
        const nextProps = this.props;
        if (prevProps && nextProps) {
            /* prop changes go here */
            if (prevProps.location.pathname?.includes(':') && !nextProps.location.pathname?.includes(':')) {
                this.closeSummaryDialog();
            }
            if (!prevProps.location.pathname?.includes(':') && nextProps.location.pathname?.includes(':')) {
                this.showSummaryDialog(Number(nextProps.location.pathname.split(':')[1].split('/')[0]));
            }
            if (prevProps.location.pathname?.includes(':') && nextProps.location.pathname?.includes(':')) {
                const prevId = prevProps.location.pathname.split(':')[1].split('/')[0];
                const nextId = nextProps.location.pathname.split(':')[1].split('/')[0];
                if (prevId !== nextId) {
                    this.showSummaryDialog(Number(nextId));
                }
            }
            if (prevProps.selectedSiteIds !== nextProps.selectedSiteIds) {
                this.refreshData();
            }
            if (prevProps.intakeViews !== nextProps.intakeViews) {
                this.setState({ rows: this.filterData() });
            }
        }
    };

    private filterData = () => {
        const rows = [...this.props.intakeViews];
        return rows;
    };

    private setLoading = (isLoading : boolean = false) => {
        this.setState({ isLoading });
    };

    private getOrganizationName = (id : number) => {
        const rows = this.props.organizations;
        const obj = rows && rows.find(x => x.id === id);
        return obj ? obj.name : '';
    };

    private getCommodityName = (id : number) => {
        const rows = this.props.commodities;
        const obj = rows && rows.find(x => x.id === id);
        return obj ? obj.name : '';
    };

    private getFarmName = (id : number) => {
        const rows = this.props.farms;
        const obj = rows && rows.find(x => x.id === id);
        return obj ? obj.name : '';
    };

    private getSiteDescription = (id : number) => {
        const rows = this.props.sites;
        const obj = rows && rows.find(x => x.id === id);
        return obj ? obj.description : '';
    };

    private getStorageUnitDescription = (id ?: number) => {
        const rows = this.props.storageUnits;
        const obj = rows && rows.find(x => x.id === id);
        return obj ? obj.description : '';
    };

    private getDate = (type : 'from' | 'to') => {
        switch (type) {
            case 'from':
                return this.state.selectedFromDate.startOf('day').utc().unix() * 1000;
            case 'to':
                return this.state.selectedToDate.endOf('day').utc().unix() * 1000;
        }
    };

    private refreshData = async () => {
        this.setLoading(true);

        if (!!self.indexedDB) {
            await syncMasterData(false);
        }

        try {
            // checks if indexedDB is available.
            const loadMasterData = !!self.indexedDB ? false : true;

            const res = await IntakeHttpService.getIntakeTransactionViewData(this.getDate('from'), this.getDate('to'), this.props.selectedSiteIds, loadMasterData);

            dataSetIntakeViewRelatedData(res.data);
            this.setState({ rows: this.filterData() }, () => {
                this.setLoading(false);
            });
        } catch (e) {
            generalShowErrorSnackbar('An error occurred retrieving intake data.');
            this.setLoading(false);
        }
    };

    public onSubmit = async (values : IIntakeFormValues) => {
        this.setLoading(true);
        try {
            const res = await IntakeHttpService.addOrUpdateIntake(new Intake(values));

            if (res && res.data) {
                dataSetIntakeView(res.data);
            }

            if (this.state.editingIntake) {
                generalShowSuccessSnackbar('Intake updated successfully.');
            } else {
                generalShowSuccessSnackbar('Intake added successfully.');
            }

            this.closeFormDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating intake data.');
        } finally {
            this.setLoading(false);
        }
    };

    public onReset = async (formValues : IIntakeFormValues, formikHelpers : FormikHelpers<IIntakeFormValues>) => {
        formikHelpers.resetForm();
        this.closeFormDialog();
    };

    private setDeleteItem = (item ?: IIntakeView) => {
        this.setState({
            deleteItem: item,
            isDeleting: true,
        });
    };

    private removeIntake = async (deleteReason : string) => {
        const newIntake = this.state.deleteItem;

        if (newIntake) {
            if (deleteReason !== '' && deleteReason.length >= 200) {
                newIntake.isActive = false;
                this.setLoading(true);

                try {
                    const res = await IntakeHttpService.deleteIntake(newIntake.id, deleteReason);

                    if (res && res.data) {
                        dataSetIntakeView(newIntake);
                        generalShowSuccessSnackbar('Intake deleted successfully.');
                    };
                } catch (e) {
                    if (e.status === 400) {
                        generalShowErrorSnackbar(e.data?.Message);
                    } else {
                        generalShowErrorSnackbar('An error occurred deleting intake.');
                    }
                    newIntake.isActive = true;
                    dataSetIntakeView(newIntake);
                } finally {
                    this.closeDeleteConfirmationPopup();
                    this.setLoading(false);
                }
            } else {
                generalShowErrorSnackbar('Reason for deleting this intake must be at least 200 characters.');
            }
        }
    };

    private closeDeleteConfirmationPopup = () => this.setState({ isDeleting: false, deleteItem: undefined });

    public showSummaryDialog = async (id : number) => {
        this.setState({
            isDialogOpen: true,
        }, async () => {
            this.setLoading(true);

            if (!!self.indexedDB) {
                await syncMasterData(false);
            }

            try {
                // checks if indexedDB is available.
                const loadMasterData = !!self.indexedDB ? false : true;

                const res = await IntakeHttpService.getIntakeSummaryData(id, loadMasterData);

                if (res && res.data) {
                    dataSetIntakeSummaryRelatedData(res.data);
                    this.setState({ selectedIntake: res.data.intake });
                }
            } catch (e) {
                generalShowErrorSnackbar('Error occured while loading intake summary data');
            } finally {
                this.setLoading(false);
            }
        });
    };

    public closeSummaryDialog = () => {
        this.setState({
            isDialogOpen: false,
            selectedIntake: undefined,
        }, () => navPath(this.props.location?.pathname?.split('/:')[0]));
    };

    private onIntakeEdit = (intake : IIntakeView) => {
        this.setLoading(true);
        this.setState({
            isFormDialogOpen: true,
        }, async () => {
            try {
                const res = await IntakeHttpService.getIntakeSummaryData(intake.id);
                if (res && res.data) {
                    dataSetIntakeSummaryRelatedData(res.data);
                    this.setState({ editingIntake: res.data.intake });
                }
            } catch (e) {
                generalShowErrorSnackbar('Error occured while loading intake edit data');
            } finally {
                this.setLoading(false);
            }
        });
    };

    public closeFormDialog = () => {
        this.setState({
            isFormDialogOpen: false,
            editingIntake: undefined,
        });
    };

    public getSelectedIntake = (props : IIntakeScreenProps, state : IIntakeScreenState) => state.selectedIntake;
    public getEditingIntake = (props : IIntakeScreenProps, state : IIntakeScreenState) => state.editingIntake;
    public getOrganizations = (props : IIntakeScreenProps) => props.organizations;
    public getCommodities = (props : IIntakeScreenProps) => props.commodities;
    public getFarms = (props : IIntakeScreenProps) => props.farms;
    public getSites = (props : IIntakeScreenProps) => props.sites;
    public getStorageUnits = (props : IIntakeScreenProps) => props.storageUnits;
    public getPalletBaseTypes = (props : IIntakeScreenProps) => props.palletBaseTypes;
    public getVarieties = (props : IIntakeScreenProps) => props.varieties;
    public getOrchards = (props : IIntakeScreenProps) => props.orchards;
    public getPacks = (props : IIntakeScreenProps) => props.packs;
    public getLotData = (props : IIntakeScreenProps) => props.lots;
    public getProjects = (props : IIntakeScreenProps) => props.projects;
    public getSelectedSiteIds = (props : IIntakeScreenProps) => props.selectedSiteIds;

    public getInitialFormValues = createSelector(
        [this.getEditingIntake, this.getSelectedSiteIds, this.getOrganizations, this.getCommodities, this.getFarms, this.getSites, this.getStorageUnits, this.getPalletBaseTypes, this.getVarieties, this.getOrchards, this.getPacks, this.getLotData, this.getProjects],
        (intake, selectedSiteIds, organizations, commodities, farms, sites, storageUnits, palletBaseTypes, varieties, orchards, packs, lots, projects) => {
            const site = selectedSiteIds?.length === 1 ? sites.find(x => x.id === selectedSiteIds[0]) : undefined;
            return new IntakeFormValues(intake, site, organizations, commodities, farms, sites, storageUnits, palletBaseTypes, varieties, orchards, packs, lots, projects);
        },
    );

    private handleDateRangeChange = async (start : moment.Moment, end : moment.Moment, changedBy ?: 'start' | 'end') => {
        const selectedFromDate = changedBy === 'start' ? moment(start).startOf('day') : end < start ? moment(end).startOf('day') : moment(start).startOf('day');
        const selectedToDate = changedBy === 'end' ? moment(end).endOf('day') : start > end ? moment(start).endOf('day') : moment(end).endOf('day');
        this.setState({ selectedFromDate, selectedToDate });
    };

    private onApplyClick = async () => {
        await this.refreshData();
    };

    private getLots = (intakeLines : Array<IIntakeLine>) => lodash.uniq(intakeLines.filter(x => x.isActive)
        .map(x => x.intakeLineLayers.map(y => y.lotName))).toString().replace(/,/g, ', ');

    private onPrintIntakeSlipClick = () => {
        const sites = this.props.sites;
        const siteCode = sites.find(x => x.id === this.state.selectedIntake?.siteId)?.code;
        generateIntakeSlip(this.state.selectedIntake, siteCode);
    };

    private canIntakeBeDeleted = (intake : IIntakeView) => {
        let result = false;
        if (intake.isActive && this.hasAdminEditingRight(this.props)) {
            result = true;
        } else if (intake.isActive && !intake.isTipped && this.hasEditingRight(this.props)) {
            result = true;
        }
        return result;
    };

    private formatLotLinks = (lotNames : string, row : IIntakeView) => {
        const lotNamesArray = lotNames?.split(',');
        const ids = row.lotIds?.split(',');
        return <PackmanMultiLink
            type={'transactions'}
            targetPage={'lot'}
            ids={ids}
            texts={lotNamesArray} />;
    };

    public onReportFileReset = async (formValues : IReportFileFormValues, formikHelpers : FormikHelpers<IReportFileFormValues>) => {
        formikHelpers.resetForm();
        this.closeDownloadReportPopup();
    };

    private generateReportForm = () => new ReportFileFormValues();

    private showDownloadReportPopup = async () => {
        this.setState({ isDownloadReportFormOpen: true });
    };

    private downloadReportFile = async (values : IReportFileFormValues) => {
        if (this.state.selectedIntake && values.printServer && values.site && values.report) {
            try {
                const res = await IntakeHttpService.getIntakeAppView(this.state.selectedIntake.guid);

                const printServer = this.props.printServers.find(x => x.id === values.printServer?.value);
                const report = this.props.reports.find(x => x.id === values.report?.value);

                if (printServer && res && report) {
                    const res2 = await PrintServerReportHttpService.getIntakeReportPreview(printServer, res.data, this.state.selectedIntake.guid, report.guid);
                    if (res2 && res2.data) {
                        const data = Buffer.from(res2.data).toString('base64');
                        const base64String = 'data:document/pdf;base64,' + data; // Base64 string
                        const byteString = atob(base64String.split(',')[1]);

                        // separate out the mime component
                        const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0];
                        // write the bytes of the string to an ArrayBuffer
                        const ab = new ArrayBuffer(byteString.length);
                        // create a view into the buffer
                        const ia = new Uint8Array(ab);
                        // set the bytes of the buffer to the correct values
                        for (let i = 0; i < byteString.length; i++) {
                            ia[i] = byteString.charCodeAt(i);
                        }

                        const blob = new Blob([ab], { type: mimeString }); // Create a BLOB object

                        saveAs(blob, 'Intake Report.png');
                    }
                }

            } catch (e) {
                generalShowErrorSnackbar('Downloading intake report failed!');
            } finally {
                this.setLoading(false);
            }
        }
    };

    private closeDownloadReportPopup = () => {
        this.setState({ isDownloadReportFormOpen: false });
    };

    private getRights = (props : IIntakeScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IIntakeScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    private hasAdminEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_ADMIN_EDIT')));

    public render() {
        const initialValues = this.getInitialFormValues(this.props, this.state);

        return (
            <Screen isLoading={this.state.isLoading} isScrollable={false} isPadded={false}>
                <div className={'fdc hfill'}>
                    <TransactionFilter className={'pt10 mt10 mr20 mb10'} selectedFromDate={this.state.selectedFromDate} selectedToDate={this.state.selectedToDate} handleDateRangeChange={this.handleDateRangeChange} onApplyClick={this.onApplyClick} />
                    {/* Info Dialog */}
                    <PackmanDialog
                        title='Intake Summary'
                        isInfo={true}
                        fullScreen={true}
                        isOpen={this.state.isDialogOpen}
                        isLoading={this.state.isLoading}
                        onClose={this.closeSummaryDialog}>
                        {this.state.selectedIntake &&
                                <Screen isLoading={this.state.isLoading} isScrollable={false} isPadded={false}>
                                    <div className={'fdr aic pt10 pl10 pr10'}>
                                        <PackmanLabel
                                            label={'ID'}
                                            value={this.state.selectedIntake.id}
                                        />
                                        <PackmanLabel
                                            label={'Intake Date'}
                                            value={formatDateTime(this.state.selectedIntake.intakeDate ?? '')}
                                        />
                                        <PackmanLabel
                                            label={'Organization'}
                                            value={this.getSiteDescription(this.state.selectedIntake.siteId)}
                                        />
                                        <PackmanLabel
                                            label={'Site'}
                                            value={this.getSiteDescription(this.state.selectedIntake.siteId)}
                                        />
                                        <PackmanLabel
                                            label={'Storage Unit'}
                                            value={this.getStorageUnitDescription(this.state.selectedIntake.storageUnitId)}
                                        />
                                        <PackmanLabel
                                            label={'Commodity'}
                                            value={this.getCommodityName(this.state.selectedIntake.commodityId)}
                                        />
                                        <PackmanLabel
                                            label={'Farm'}
                                            value={this.getFarmName(this.state.selectedIntake.farmId)}
                                        />
                                        <PackmanLabel
                                            label={'Shipment Ref.'}
                                            value={this.state.selectedIntake.shipmentGuid ?? ''}
                                        />
                                    </div>
                                    <div className={'fdr aic pt10 pl10 pr10'}>
                                        <PackmanLabel
                                            label={'Created By'}
                                            value={this.state.selectedIntake.createdByName ?? ''}
                                        />
                                        <PackmanLabel
                                            label={'Created On'}
                                            value={this.state.selectedIntake.createdOn ?? ''}
                                        />
                                        <PackmanLabel
                                            label={'Updated By'}
                                            value={this.state.selectedIntake.updatedByName ?? ''}
                                        />
                                        <PackmanLabel
                                            label={'Updated On'}
                                            value={this.state.selectedIntake.updatedOn ?? ''}
                                        />
                                        <div className={'fdr aic flx1'}>
                                            <Typography className={'fs14 mr5'}>Tipped?</Typography>
                                            <BooleanFlag value={this.state.selectedIntake?.isTipped ?? false}/>
                                        </div>
                                        <div className={'fdr aic flx1'}>
                                            <Typography className={'fs14 mr5'}>Active?</Typography>
                                            <BooleanFlag value={this.state.selectedIntake?.isActive ?? false}/>
                                        </div>
                                        <div className={'fdr aic flx2'} style={{ maxWidth: 'calc(100% * 2/8)' }}>
                                            {!this.state.selectedIntake?.isActive ? <PackmanLabel
                                                label={'Delete Reason'}
                                                maxWidth={'100%'}
                                                className={'wrap2line mxwfill oh'}
                                                value={this.state.selectedIntake?.deleteReason ?? ''}
                                            /> : <></>}
                                        </div>
                                    </div>
                                    <IntakeLineSummary
                                        setLoading={this.setLoading}
                                        intake={this.state.selectedIntake}
                                        lines={this.state.selectedIntake.intakeLines}
                                        refreshData={this.refreshData}
                                        isLoading={this.state.isLoading}
                                    />
                                    <div className={'fdr jcfe pt10 pr10'}>
                                        <PillButton
                                            disabled={this.state.isLoading}
                                            className={'mb10 mr10 h35'}
                                            text={'Download Report File'}
                                            color={'secondary'}
                                            onClick={this.showDownloadReportPopup}
                                        />
                                        <PillButton
                                            disabled={this.state.isLoading}
                                            className={'mb10 mr10 h35'}
                                            text={'Print Slip'}
                                            color={'secondary'}
                                            onClick={this.onPrintIntakeSlipClick}
                                        />
                                    </div>
                                </Screen>
                        }
                    </PackmanDialog>
                    {!!this.state.isDownloadReportFormOpen &&
                        <PackmanDialog
                            title='Download Report File'
                            isInfo
                            isLoading={this.state.isLoading}
                            isOpen={this.state.isDownloadReportFormOpen}
                            onClose={this.closeDownloadReportPopup}>
                            <Formik
                                initialValues={this.generateReportForm()}
                                isInitialValid={ReportFileFormValues.formSchema.isValidSync(this.generateReportForm())}
                                onSubmit={this.downloadReportFile}
                                onReset={this.onReportFileReset}
                                enableReinitialize
                                validationSchema={ReportFileFormValues.formSchema}
                                component={ReportFileForm} />
                        </PackmanDialog >}
                    {/* Delete Dialog */}
                    {!!this.state.isDeleting &&
                        <DeleteConfirmationDialog
                            isLoading={this.state.isLoading}
                            onSubmit={this.removeIntake}
                            onclose={this.closeDeleteConfirmationPopup}
                            isOpen={!!this.state.isDeleting}
                            title={'Intake'}/>
                    }
                    {/* Form Dialog */}
                    <PackmanDialog
                        title={'Intake'}
                        isEdit={!!this.state.editingIntake}
                        isLoading={this.state.isLoading}
                        isOpen={this.state.isFormDialogOpen}
                        onClose={this.closeFormDialog}
                        fullScreen>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={this.onSubmit}
                            onReset={this.onReset}
                            enableReinitialize
                            validationSchema={IntakeFormValues.formSchema}
                            component={IntakeForm} />
                    </PackmanDialog >
                    <Paper className={'hfill mr20 mt10 mb10 ml20 oh'}>
                        <CustomTable<IIntakeView>
                            enableSorting
                            enableFiltering
                            enablePagination
                            isActive={(row : IIntakeView) => row.isActive}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            enableTotalRow
                            enableRefresh
                            autoRowHeight
                            enableEditing={this.hasEditingRight(this.props)}
                            // enableEditing={(row : IIntakeView) => !row.isTipped && this.hasEditingRight(this.props)}
                            editFunction={this.onIntakeEdit}
                            enableDetails={true}
                            detailIcon={'info'}
                            detailTooltip={'Intake Summary'}
                            detailFunction={row => navIntake(row.id)}
                            detailsColor={materialTheme.palette.primary.main}
                            disableRefreshButton={this.state.isLoading}
                            refreshFunction={this.refreshData}
                            enableDeleting={(intake : IIntakeView) => this.canIntakeBeDeleted(intake)}
                            deleteFunction={this.setDeleteItem}
                            columns={[
                                { title: 'Id', field: 'id', width: 65, enableFiltering: true, enableSorting: true },
                                { title: 'Intake Date', field: 'intakeDate', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
                                { title: 'Organization', field: 'organizationId', formatFunction: this.getOrganizationName, enableFiltering: true, enableSorting: true },
                                { title: 'Commodity', field: 'commodityName', enableFiltering: true, enableSorting: true },
                                { title: 'Site', field: 'siteId', formatFunction: this.getSiteDescription, enableFiltering: true, enableSorting: true },
                                { title: 'Farm', field: 'farmName', enableFiltering: true, enableSorting: true },
                                { title: 'Shipment Ref', field: 'shipmentGuid', enableFiltering: true, enableSorting: true },
                                { title: 'Tipped?', field: 'isTipped', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Lots', field: 'lotNames', containerComponent: (row : IIntakeView, value : string) => this.formatLotLinks(value, row), width: 150, enableFiltering: true, enableSorting: true },
                                { title: 'Varieties', field: 'varieties', width: 150, enableFiltering: true, enableSorting: true },
                                { title: 'Orchards', field: 'orchards', width: 150, enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            fitWidthToPage
                            rows={this.state.rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction : 'desc' }]}
                            pageHeight={250}
                        />
                    </Paper>
                </div>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        lots: state.data.lots,
        lotTypes: state.masterData.lotTypes,
        intakes: state.data.intakes,
        intakeViews: state.data.intakeViews,
        farms: state.masterData.farms,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        orchards: state.masterData.orchards,
        organizations: state.masterData.organizations,
        sites: state.masterData.sites,
        reports: state.masterData.reports,
        printServers: state.masterData.printServers,
        storageUnits: state.masterData.storageUnits,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        selectedSiteIds: state.data.selectedSiteIds,
        palletBaseTypes: state.masterData.palletBaseTypes,
        projects: state.masterData.projects,
        packs: state.masterData.packs.filter((x : IPack) => x.isIntakePack),
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetIntakeViews, dataSetIntakes, dataSetCommodityStates }, dispatcher,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(IntakeTable);
