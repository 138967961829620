import * as React from 'react';
import { connect } from 'react-redux';
import { DispatchCall, IRootState, RootAction } from '../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Typography, Paper, Button } from '@mui/material';
import { formatDateTime, booleanToYesNo, compareDate, addArrayElement, setArrayElement } from '../../services/appFunctionsService';
import CustomTable from '../../components/datagrid/CustomTable';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { IPalletBaseType } from '../../@types/model/masterData/palletBaseType/palletBaseType';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IOrchard } from '../../@types/model/masterData/orchard/orchard';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { ISite } from '../../@types/model/masterData/site/site';
import { IBatch } from '../../@types/model/batch/batch';
import { IFarm } from '../../@types/model/masterData/farm/farm';
import { ICommodityState } from '../../@types/model/masterData/commodityState/commodityState';
import Screen from '../../components/Screen';
import { ILot } from '../../@types/model/lot/lot';
import { IColour } from '../../@types/model/masterData/colour/colour';
import { ISize } from '../../@types/model/masterData/size/size';
import { IGrade } from '../../@types/model/masterData/grade/grade';
import { IMarket } from '../../@types/model/masterData/market/market';
import { IBrand } from '../../@types/model/masterData/brand/brand';
import { IRegion } from '../../@types/model/masterData/region/region';
import { ICountry } from '../../@types/model/masterData/country/country';
import { IOrderHeader } from '../../@types/model/order/orderHeader';
import { IInventory } from '../../@types/model/masterData/inventory/inventory';
import { IMaterialStock } from '../../@types/model/materialStock/materialStock';
import { IMaterial } from '../../@types/model/masterData/material/material';
import { IUnitOfMeasure } from '../../@types/model/masterData/unitOfMeasure/unitOfMeasure';
import { createSelector } from 'reselect';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import { dataSetMaterials, dataSetUnitOfMeasures } from '../../store/masterData/Actions';
import { dataSetMaterialStocks } from '../../store/data/Actions';
import { IStockView } from '../../@types/model/stock/stockView';
import StockHttpService from '../../services/http/stock/stockHttpService';
import { IStockLineView } from '../../@types/model/stock/stockLineView';
import { IMaterialStockLine } from '../../@types/model/materialStock/materialStockLine';
import PillButton from '../../components/input/PillButton';
import { dataSetStockView } from '../../store/data/Functions';
import { IStockAudit } from '../../@types/model/stock/stockAudit';
import { PackmanLink } from '../../components/link/packmanLink';
import { PackmanLabel } from '../../components/label/PackmanLabel';
import { PackmanMultiLink } from '../../components/link/packmanMultiLink';
import PackmanDialog from '../../components/dialog/PackmanDialog';
import lodash from 'lodash';
import { IStorageUnit } from '../../@types/model/masterData/storageUnit/storageUnit';
import { syncMasterData } from '../../services/masterDataSyncService';
import CustomTooltip from '../../components/tooltip/tooltip';
import { BooleanFlag } from '../../components/label/BooleanFlag';
import { IReportFileFormValues, ReportFileFormValues } from '../../@types/model/reportFile/reportFileFormValues';
import PrintServerReportHttpService from '../../services/http/printServerReport/printServerReportHttpService';
import { IPrintServer } from '../../@types/model/masterData/printServer/printServer';
import { IReport } from '../../@types/model/masterData/report/report';
import { Formik, FormikHelpers } from 'formik';
import ReportFileForm from '../../components/reportFile/ReportFileForm';

interface IStockSummaryProps {
    dataSetUnitOfMeasures : DispatchCall<Array<IUnitOfMeasure>>;
    dataSetMaterials : DispatchCall<Array<IMaterial>>;
    dataSetMaterialStocks : DispatchCall<Array<IMaterialStock>>;
    closeSummary : () => void;
    setStockBarcode : (barcode : string) => void;

    hasEditingRight : boolean;
    hasSuperAdminEditRight : boolean;
    hasStockBreakDownRight : boolean;
    hasStockBreakDownIntoLotsRight : boolean;
    selectedStockViewId : number;
    packs : Array<IPack>;
    palletBaseTypes : Array<IPalletBaseType>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    orchards : Array<IOrchard>;
    organizations : Array<IOrganization>;
    sites : Array<ISite>;
    printServers : Array<IPrintServer>;
    reports : Array<IReport>;
    storageUnits : Array<IStorageUnit>;
    batches : Array<IBatch>;
    farms : Array<IFarm>;
    commodityStates : Array<ICommodityState>;
    sizes : Array<ISize>;
    colours : Array<IColour>;
    grades : Array<IGrade>;
    markets : Array<IMarket>;
    brands : Array<IBrand>;
    regions : Array<IRegion>;
    countries : Array<ICountry>;
    selectedSiteIds : Array<number>;
    showAdvanced : boolean;
    orderHeaders : Array<IOrderHeader>;
    inventoryData : Array<IInventory>;
    materialStockData : Array<IMaterialStock>;
    materials : Array<IMaterial>;
    unitOfMeasures : Array<IUnitOfMeasure>;
}

interface IStockSummaryState {
    isLoading : boolean;
    stockLines : Array<IStockLineView>;
    materialStockLines : Array<IMaterialStockLine>;
    selectedStockLatestAudit ?: IStockAudit;
    selectedStockView ?: IStockView;
    stockAudits : Array<IStockAudit>;

    // One of the lots that was created if the stock was broken down
    linkedLotAfterBreakDown ?: ILot;
    isBreakDownIntoLotsDialogOpen : boolean;
    isAuditFormOpen : boolean;
    isDownloadReportFormOpen : boolean;
}

class StockSummary extends React.Component<IStockSummaryProps, IStockSummaryState> {
    constructor(props : IStockSummaryProps) {
        super(props);

        this.state = {
            isLoading: false,
            stockLines: [],
            materialStockLines: [],
            stockAudits: [],

            isBreakDownIntoLotsDialogOpen: false,
            isAuditFormOpen: false,
            isDownloadReportFormOpen: false,
        };
    }

    public componentDidMount = async () => {
        this.setLoading(true);

        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;
        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        try {
            const res = await StockHttpService.getStockSummaryData(this.props.selectedStockViewId, !isIndexedDBAvailable);

            if (res && res.data) {
                const materialStockLines = lodash.flatMap(res.data.materialStocks, x => x.materialStockLines);
                this.props.setStockBarcode(res.data.stock.barcode);

                if (!isIndexedDBAvailable && (res.data.materials.length > 0 || res.data.unitOfMeasures.length > 0)) {
                    let updatedMaterialData = this.props.materials;
                    let updatedUnitOfMeasureData = this.props.unitOfMeasures;

                    res.data.materials.forEach((x) => {
                        const index = updatedMaterialData.findIndex(y => y.id === x.id);
                        if (index === -1) {
                            updatedMaterialData = addArrayElement(updatedMaterialData, x);
                        } else {
                            updatedMaterialData = setArrayElement(updatedMaterialData, index, x);
                        }
                    });

                    res.data.unitOfMeasures.forEach((x) => {
                        const index = updatedUnitOfMeasureData.findIndex(y => y.id === x.id);
                        if (index === -1) {
                            updatedUnitOfMeasureData = addArrayElement(updatedUnitOfMeasureData, x);
                        } else {
                            updatedUnitOfMeasureData = setArrayElement(updatedUnitOfMeasureData, index, x);
                        }
                    });

                    this.props.dataSetMaterials(updatedMaterialData);
                    this.props.dataSetUnitOfMeasures(updatedUnitOfMeasureData);
                }

                this.setState({ selectedStockView: res.data.stock,
                    stockLines: res.data.stockLines,
                    materialStockLines: materialStockLines.filter(x => x.stockId === this.props.selectedStockViewId) ?? [],
                    selectedStockLatestAudit: res.data.latestStockAudit,
                    linkedLotAfterBreakDown: res.data.linkedLot });
                this.props.dataSetMaterialStocks(res.data.materialStocks);
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred while loading stock lines.');
        } finally {
            this.setLoading(false);
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    private getSiteCodeAndDescription = (siteId : number) => {
        const sites = this.props.sites;
        const site = sites && sites.find(x => x.id === siteId);
        return site ? `(${site.code}) - ${site.description}` : '';
    };

    private getStorageUnitCodeAndDescription = (storageUnitId : number) => {
        const storageUnits = this.props.storageUnits;
        const storageUnit = storageUnits && storageUnits.find(x => x.id === storageUnitId);
        return storageUnit ? `(${storageUnit?.code}) - ${storageUnit?.description}` : '';
    };

    private getOrganizationCodeAndName = (orgId : number) => {
        const organizations = this.props.organizations;
        const organization = organizations && organizations.find(x => x.id === orgId);
        return organization ? `(${organization.code}) - ${organization.name}` : '';
    };

    private getMarketCodeAndName = (marketId : number) => {
        const markets = this.props.markets;
        const market = markets && markets.find(x => x.id === marketId);
        return market ? `(${market.code}) - ${market.name}` : '';
    };

    private getCountryCodeAndName = (countryId : number) => {
        const countries = this.props.countries;
        const country = countries && countries.find(x => x.id === countryId);
        return country ? `(${country.code}) - ${country.name}` : '';
    };

    private getPalletBaseTypeCodeAndName = (palletBaseTypeId : number) => {
        const palletBaseTypes = this.props.palletBaseTypes;
        const palletBaseType = palletBaseTypes && palletBaseTypes.find(x => x.id === palletBaseTypeId);
        return palletBaseType ? `(${palletBaseType.code}) - ${palletBaseType.name}` : '';
    };

    private getInventoryCodeAndName = (inventoryId : number) => {
        const inventories = this.props.inventoryData;
        const inventory = inventories && inventories.find(x => x.id === inventoryId);
        return inventory ? `(${inventory.code}) - ${inventory.description}` : '';
    };

    private getBrandCodeAndDescription = (brandId : number) => {
        const brands = this.props.brands;
        const brand = brands && brands.find(x => x.id === brandId);
        return brand ? `(${brand.code}) - ${brand.description}` : '';
    };

    private getMaterialCode = (materialId : number) => {
        const material = this.props.materials.find(x => x.id === materialId);
        return material ? `(${material.code}) - ${material.name}` : '';
    };

    private getUnitOfMeasureCode = (unitId : number) => {
        const unitOfMeasure = this.props.unitOfMeasures.find(x => x.id === unitId);
        return unitOfMeasure ? `(${unitOfMeasure.code}) - ${unitOfMeasure.name}` : '';
    };

    private getCommodityCodeAndName = (rowId : number) => {
        const stockLine = this.state.stockLines.find(x => x.id === rowId);
        return stockLine ? `(${stockLine.commodityCode}) - ${stockLine.commodityName}` : '';
    };

    private getVarietyCodeAndName = (rowId : number) => {
        const stockLine = this.state.stockLines.find(x => x.id === rowId);
        return stockLine ? `(${stockLine.varietyCode}) - ${stockLine.varietyName}` : '';
    };

    private getFarmCodeAndName = (rowId : number) => {
        const stockLine = this.state.stockLines.find(x => x.id === rowId);
        return stockLine ? `(${stockLine.farmCode}) - ${stockLine.farmName}` : '';
    };

    private getGradeCodeAndName = (rowId : number) => {
        const stockLine = this.state.stockLines.find(x => x.id === rowId);
        return stockLine ? `(${stockLine.gradeCode}) - ${stockLine.gradeName}` : '';
    };

    private getSizeCodeAndName = (rowId : number) => {
        const stockLine = this.state.stockLines.find(x => x.id === rowId);
        return stockLine ? `(${stockLine.sizeCode}) - ${stockLine.sizeName}` : '';
    };

    private getColourCodeAndName = (rowId : number) => {
        const stockLine = this.state.stockLines.find(x => x.id === rowId);
        return stockLine ? `(${stockLine.colourCode}) - ${stockLine.colourName}` : '';
    };

    private getOrchardCodeAndName = (rowId : number) => {
        const stockLine = this.state.stockLines.find(x => x.id === rowId);
        return stockLine ? `(${stockLine.orchardCode}) - ${stockLine.orchardName}` : '';
    };

    private getPackCodeAndDescription = (rowId : number) => {
        const stockLine = this.state.stockLines.find(x => x.id === rowId);
        return stockLine ? `(${stockLine.packCode}) - ${stockLine.packDescription}` : '';
    };

    private formatBatchLink = (row : IStockLineView, code : string) => {
        return <PackmanLink
            type={'transactions'}
            targetPage={'batch'}
            id={Number(row.batchId)}
            text={code} />;
    };

    private formatMaterialStockLink = (row : IMaterialStockLine, id : number) => {
        return <PackmanLink
            type={'transactions'}
            targetPage={'materialStock'}
            id={Number(id)}
            text={id} />;
    };

    private formatDispatches = (idString ?: string, codeString ?: string) => {
        const codes = codeString?.split(',');
        const ids = idString?.split(',');
        return <PackmanMultiLink
            type={'transactions'}
            targetPage={'dispatch'}
            ids={ids}
            texts={codes} />;
    };

    private formatCompliance = (waybill ?: string, id ?: string, isRejected ?: boolean) => {
        return <PackmanLink
            type={'transactions'}
            targetPage={'compliance'}
            id={Number(id)}
            text={waybill}
            color={isRejected ? 'red' : undefined} />;
    };

    public onReportFileReset = async (formValues : IReportFileFormValues, formikHelpers : FormikHelpers<IReportFileFormValues>) => {
        formikHelpers.resetForm();
        this.closeDownloadReportPopup();
    };

    private generateReportForm = () => new ReportFileFormValues();

    private showDownloadReportPopup = async () => {
        this.setState({ isDownloadReportFormOpen: true });
    };

    private downloadReportFile = async (values : IReportFileFormValues) => {
        if (this.state.selectedStockView && values.printServer && values.site && values.report) {
            try {
                const res = await StockHttpService.getStockAppView(this.state.selectedStockView.guid);

                const printServer = this.props.printServers.find(x => x.id === values.printServer?.value);
                const report = this.props.reports.find(x => x.id === values.report?.value);

                if (printServer && res && report) {
                    const res2 = await PrintServerReportHttpService.getStockReportPreview(printServer, res.data, this.state.selectedStockView.guid, report.guid);
                    if (res2 && res2.data) {
                        const data = Buffer.from(res2.data).toString('base64');
                        const base64String = 'data:application/pdf;base64,' + data; // Base64 string
                        const byteString = atob(base64String.split(',')[1]);

                        // separate out the mime component
                        const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0];
                        // write the bytes of the string to an ArrayBuffer
                        const ab = new ArrayBuffer(byteString.length);
                        // create a view into the buffer
                        const ia = new Uint8Array(ab);
                        // set the bytes of the buffer to the correct values
                        for (let i = 0; i < byteString.length; i++) {
                            ia[i] = byteString.charCodeAt(i);
                        }

                        const blob = new Blob([ab], { type: mimeString }); // Create a BLOB object

                        saveAs(blob, 'Intake Report.png');
                    }
                }

            } catch (e) {
                generalShowErrorSnackbar('Downloading intake report failed!');
            } finally {
                this.setLoading(false);
            }
        }
    };

    private closeDownloadReportPopup = () => {
        this.setState({ isDownloadReportFormOpen: false });
    };

    private getColumns = () => [
        { title: 'Sequence', field: 'sequence', enableSorting: true },
        { title: 'Commodity', field: 'id', formatFunction: this.getCommodityCodeAndName, enableSorting: true },
        { title: 'Variety', field: 'id', formatFunction: this.getVarietyCodeAndName, enableSorting: true },
        { title: 'Farm', field: 'id', formatFunction: this.getFarmCodeAndName, enableSorting: true },
        { title: 'Pack', field: 'id', formatFunction: this.getPackCodeAndDescription, enableSorting: true },
        { title: 'Size', field: 'id', formatFunction: this.getSizeCodeAndName, enableSorting: true },
        { title: 'Grade', field: 'id', formatFunction: this.getGradeCodeAndName, enableSorting: true },
        { title: 'Colour', field: 'id', formatFunction: this.getColourCodeAndName, enableSorting: true },
        { title: 'Orchard', field: 'id', formatFunction: this.getOrchardCodeAndName, enableSorting: true },
        { title: 'Pallet Size', field: 'palletSize', enableSorting: true },
        { title: 'No. Outers', field: 'cartons', enableSorting: true },
        { title: 'No. Inners', field: 'totalInners', enableSorting: true },
        { title: 'Est. Gross Weight (kg)', field: 'estimatedGrossWeight', enableSorting: true },
        { title: 'Batch', containerComponent: this.formatBatchLink, field: 'batchCode', enableSorting: true },
        { title: 'Project No.', field: 'projectNumber', enableSorting: true },
        { title: 'Imported From PO?', field: 'isImportedFromPO', formatFunction: booleanToYesNo, type: 'boolean', enableFiltering: true, enableSorting: true },
        { title: 'Created By', field: 'createdByName', enableSorting: true },
        { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate, enableSorting: true },
        { title: 'Updated By', field: 'updatedByName', enableSorting: true },
        { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableSorting: true },
        { title: 'Active?', field: 'isActive', formatFunction: booleanToYesNo, type: 'boolean', enableSorting: true },
    ];

    private getAllocatedMaterialColumns = () => [
        { title: 'Material Stock Line Id', field: 'id' },
        { title: 'Material Stock Id', containerComponent: this.formatMaterialStockLink, field: 'materialStock.id' },
        { title: 'Material', field: 'materialStock.materialId', formatFunction: this.getMaterialCode },
        { title: 'Date Code', field: 'materialStock.dateCode' },
        { title: 'Amount', field: 'amount', enableSorting: true,
            containerComponent: (row : IMaterialStockLine, value : any) => <div className={'fdr'}>
                {(value === '0' ? '0' : row.isIncoming ? <div className={'cred'} style={{ paddingLeft: 3 }}>{`-${value}`}</div> :
                    <div className={'cp'} style={{ paddingLeft: 3 }}>{`+${value}`}</div>)}
            </div> },
        { title: 'Unit of Measure', field: 'materialStock.unitOfMeasureId', formatFunction: this.getUnitOfMeasureCode },
        { title: 'Created By', field: 'createdByName' },
        { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate },
        { title: 'Updated By', field: 'updatedByName' },
        { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate },
        { title: 'Active?', field: 'isActive', formatFunction: booleanToYesNo, type: 'boolean' },
    ];

    private getStockLines = (props : IStockSummaryProps, state : IStockSummaryState) => state.stockLines;

    private getRows = createSelector(
        [this.getStockLines],
        (stockLines : Array<IStockLineView>) => {
            return stockLines;
        },
    );

    private getSelectedStock = (props : IStockSummaryProps, state : IStockSummaryState) => state.selectedStockView;
    private getHasSuperAdminEditRight = (props : IStockSummaryProps) => props.hasSuperAdminEditRight;
    private getHasStockBreakDownRight = (props : IStockSummaryProps) => props.hasStockBreakDownRight;
    private getHasStockBreakDownIntoLotsRight = (props : IStockSummaryProps) => props.hasStockBreakDownIntoLotsRight;
    private getMaterialStockData = (props : IStockSummaryProps) => props.materialStockData;
    private getMaterials = (props : IStockSummaryProps) => props.materials;
    private getUnitOfMeasures = (props : IStockSummaryProps) => props.unitOfMeasures;

    private getIsLoading = (props : IStockSummaryProps, state : IStockSummaryState) => state.isLoading;
    private getMaterialStockLineData = (props : IStockSummaryProps, state : IStockSummaryState) => state.materialStockLines;
    private getStockAudits = (props : IStockSummaryProps, state : IStockSummaryState) => state.stockAudits;

    private onStockBreakDown = async () => {
        if (!!this.state.selectedStockView) {
            this.setLoading(true);
            try {
                const res = await StockHttpService.breakDownStock(this.state.selectedStockView);

                if (res && res.data) {
                    dataSetStockView(res.data);
                    generalShowSuccessSnackbar('Stock updated successfully');
                    this.props.closeSummary();
                }
            } catch (e) {
                generalShowErrorSnackbar('Error occured while updating stock');
            } finally {
                this.setLoading(false);
            }
        } else {
            generalShowErrorSnackbar('Stock not found');
        }
    };

    private onStockBreakDownIntoLotsClick = async () => {
        this.setState({ isBreakDownIntoLotsDialogOpen: true });
    };

    private onGenerateStockAudits = async () => {
        this.setState({ isAuditFormOpen: true });
        this.setLoading(true);

        try {
            const res = await StockHttpService.getStockAudits(this.state.selectedStockView?.id);

            if (res && res.data) {
                this.setState({ stockAudits: res.data });
            }
        } catch (e) {
            generalShowErrorSnackbar('Failed to load stock audit data');
        } finally {
            this.setLoading(false);
        }
    };

    private onCloseStockAuditDialog = async () => {
        this.setState({ isAuditFormOpen: false });
    };

    private onBreakDownConfirmationYesClick = async () => {
        if (!!this.state.selectedStockView) {
            this.setLoading(true);
            try {
                const res = await StockHttpService.breakDownStockIntoLots(this.state.selectedStockView);

                if (res && res.data) {
                    dataSetStockView(res.data);
                    generalShowSuccessSnackbar('Stock updated successfully');
                    this.props.closeSummary();
                }
            } catch (e) {
                generalShowErrorSnackbar('Error occured while updating stock');
            } finally {
                this.setLoading(false);
            }
        } else {
            generalShowErrorSnackbar('Stock not found');
        }
    };

    private onBreakDownConfirmationNoClick = async () => {
        this.setState({ isBreakDownIntoLotsDialogOpen: false });
    };

    private onRevertStockBreakDown = async () => {
        if (!!this.state.selectedStockView) {
            this.setLoading(true);
            try {
                const res = await StockHttpService.revertStockBreakDown(this.state.selectedStockView);

                if (res && res.data) {
                    dataSetStockView(res.data);
                    generalShowSuccessSnackbar('Stock updated successfully');
                    this.props.closeSummary();
                }
            } catch (e) {
                generalShowErrorSnackbar('Error occured while updating stock');
            } finally {
                this.setLoading(false);
            }
        } else {
            generalShowErrorSnackbar('Stock not found');
        }
    };

    private renderBreakDownButton = createSelector(
        [this.getSelectedStock, this.getIsLoading, this.getHasSuperAdminEditRight, this.getHasStockBreakDownRight, this.getHasStockBreakDownIntoLotsRight],
        (selectedStockView : IStockView, isLoading : boolean, hasSuperAdminEditRight : boolean, hasStockBreakDownRight : boolean, hasStockBreakDownIntoLotsRight : boolean) => {
            if (selectedStockView && selectedStockView?.status !== 'Broken Down') {
                let breakDownTitle = '';
                let breakDownIntoLotsTitle = '';

                if (!hasStockBreakDownIntoLotsRight || !hasStockBreakDownRight) {
                    if (!hasStockBreakDownIntoLotsRight) {
                        breakDownIntoLotsTitle = 'You do not have permission to perform this action';
                    }
                    if (!hasStockBreakDownRight) {
                        breakDownTitle = 'You do not have permission to perform this action';
                    }
                }

                if (selectedStockView?.status !== 'In Stock') {
                    breakDownTitle = 'Stock status must be In Stock to perform this action';
                    breakDownIntoLotsTitle = 'Stock status must be In Stock to perform this action';
                } else if (selectedStockView?.complianceStatus === 'Approved') {
                    breakDownTitle = 'Cannot break down stock linked to an approved compliance';
                    breakDownIntoLotsTitle = 'Cannot break down stock linked to an approved compliance';
                }

                return (
                    <div className={'fdr'}>
                        <CustomTooltip title={breakDownIntoLotsTitle}>
                            <PillButton
                                color={'secondary'}
                                className={'h30'}
                                text={'Break Down Into Lots'}
                                onClick={this.onStockBreakDownIntoLotsClick}
                                disabled={!!isLoading || selectedStockView?.status !== 'In Stock' || !hasStockBreakDownIntoLotsRight || !!(selectedStockView?.complianceStatus === 'Approved')}/>
                        </CustomTooltip>
                        <CustomTooltip title={breakDownTitle}>
                            <PillButton
                                color={'secondary'}
                                className={'h30 ml10'}
                                text={'Break Down'}
                                onClick={this.onStockBreakDown}
                                disabled={!!isLoading || selectedStockView?.status !== 'In Stock' || !hasStockBreakDownRight || !!(selectedStockView?.complianceStatus === 'Approved')}/>
                        </CustomTooltip>
                    </div>
                );
            }
            if (selectedStockView && selectedStockView?.status === 'Broken Down') {
                let revertBreakDownTitle = '';

                if (!hasSuperAdminEditRight) {
                    revertBreakDownTitle = 'You do not have permission to perform this action';
                } else if (!!this.state.linkedLotAfterBreakDown) {
                    revertBreakDownTitle = 'Break down can not be reverted. Stock is broken down into lots.';
                } else if (selectedStockView.userComment !== '' && selectedStockView.userComment.includes('Stock has been broken down and combined into stock')) {
                    revertBreakDownTitle = 'Break down can not be reverted. This stock has been combined into another stock.';
                }

                return (
                    <CustomTooltip title={revertBreakDownTitle}>
                        <PillButton
                            color={'secondary'}
                            className={'h30'}
                            text={'Revert Break Down'}
                            onClick={this.onRevertStockBreakDown}
                            disabled={!!isLoading || !hasSuperAdminEditRight || !!this.state.linkedLotAfterBreakDown || selectedStockView.userComment.includes('Stock has been broken down and combined into stock')}/>
                    </CustomTooltip>
                );
            }
        },
    );

    private getAllocatedMaterialRows = createSelector(
        [this.getMaterialStockData, this.getMaterialStockLineData, this.getMaterials, this.getUnitOfMeasures],
        (materialStockData, materialStockLines, materials, unitOfMeasures) => {
            if (!materialStockData && !materials && !unitOfMeasures) return [];

            return materialStockLines.map((x) => {
                return { ...x,
                    materialStock: materialStockData.find(y => y.id === x.materialStockId),
                };
            }).filter(x => x.isActive && x.materialStock?.isActive);
        },
    );

    private getStockAuditRows = createSelector(
        [this.getStockAudits],
        (stockAudits : Array<IStockAudit>) => {
            return stockAudits ?? [];
        }
    );

    private checkOrchardComplianceCode = (row : IStockLineView) => {
        const currentMarket = this.props.markets.find(x => x.id === this.state.selectedStockView?.marketId);

        if (currentMarket?.channel === 'E' && (!row?.orchardComplianceCode || row?.orchardComplianceCode === '')) {
            return true;
        } else {
            return false;
        }
    };

    public render() {
        return (
            <Screen isPadded={false} isScrollable={false} isLoading={this.state.isLoading}>
                <PackmanDialog
                    title={`Stock Audit - ${this.state.selectedStockView?.barcode}`}
                    className='mnw100'
                    maxWidth='lg'
                    isInfo={true}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isAuditFormOpen}
                    onClose={this.onCloseStockAuditDialog}>
                    <div className={'p10'}>
                        <CustomTable<IStockAudit>
                            enableEditing={false}
                            enableSorting
                            enableFiltering
                            fitWidthToPage
                            enablePagination
                            columns={[
                                { title: 'Barcode', field: 'barcode', enableFiltering: true, enableSorting: true },
                                { title: 'Status', field: 'status', enableFiltering: true, enableSorting: true },
                                { title: 'Gross Weight (kg)', field: 'grossWeight', enableFiltering: true, enableSorting: true },
                                { title: 'Cartons', field: 'cartons', enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
                            ]}
                            rows={this.getStockAuditRows(this.props, this.state)}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IStockAudit) => row.stockIsActive}
                        />
                    </div>
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <div className={'fdc pl10 pr10 pt10'}>
                        <div className={'fdr pt10'}>
                            <PackmanLabel
                                label={'Original Organization'}
                                value={this.getOrganizationCodeAndName(this.state.selectedStockView?.originalOrganizationId ?? 0)}
                            />
                            <PackmanLabel
                                label={'Current Organization'}
                                value={this.getOrganizationCodeAndName(this.state.selectedStockView?.currentOrganizationId ?? 0)}
                            />
                            <PackmanLabel
                                label={'Original Site'}
                                value={this.getSiteCodeAndDescription(this.state.selectedStockView?.originalSiteId ?? 0)}
                            />
                            <PackmanLabel
                                label={'Current Site'}
                                value={this.getSiteCodeAndDescription(this.state.selectedStockView?.currentSiteId ?? 0)}
                            />
                            <PackmanLabel
                                label={'Original StorageUnit'}
                                value={this.getStorageUnitCodeAndDescription(this.state.selectedStockView?.originalStorageUnitId ?? 0)}
                            />
                            <PackmanLabel
                                label={'Current StorageUnit'}
                                value={this.getStorageUnitCodeAndDescription(this.state.selectedStockView?.currentStorageUnitId ?? 0)}
                            />
                            <PackmanLabel
                                label={'Barcode'}
                                value={this.state.selectedStockView?.barcode ?? ''}
                            />
                        </div>
                        <div className={'fdr pt10'}>
                            <PackmanLabel
                                label={'Market'}
                                value={this.getMarketCodeAndName(this.state.selectedStockView?.marketId ?? 0)}
                            />
                            <PackmanLabel
                                label={'Country'}
                                value={this.getCountryCodeAndName(this.state.selectedStockView?.countryId ?? 0)}
                            />
                            <PackmanLabel
                                label={'Pallet Base Type'}
                                value={this.getPalletBaseTypeCodeAndName(this.state.selectedStockView?.palletBaseTypeId ?? 0)}
                            />
                            <PackmanLabel
                                label={'Brand'}
                                value={this.getBrandCodeAndDescription(this.state.selectedStockView?.brandId ?? 0)}
                            />
                            <PackmanLabel
                                label={'Gross Weight (kg)'}
                                value={this.state.selectedStockView?.grossWeight.toFixed(3) ?? 0}
                            />
                            <PackmanLabel
                                label={'Pack Date'}
                                value={formatDateTime(this.state.selectedStockView?.packDate ?? '')}
                            />
                            <PackmanLabel
                                label={'Status'}
                                value={this.state.selectedStockView?.status ?? ''}
                            />
                        </div>
                        <div className={'fdr pt10'}>
                            <PackmanLabel
                                label={'Inventory'}
                                value={this.getInventoryCodeAndName(this.state.selectedStockView?.inventoryId ?? 0)}
                            />
                            <PackmanLabel
                                label={'Order'}
                                value={this.state.selectedStockView?.orderNumber ?? ''}
                            />
                            <PackmanLabel
                                label={'Created By'}
                                value={this.state.selectedStockView?.createdByName ?? ''}
                            />
                            <PackmanLabel
                                label={'Created On'}
                                value={formatDateTime(this.state.selectedStockView?.createdOn ?? '')}
                            />
                            <PackmanLabel
                                label={'Updated By'}
                                value={this.state.selectedStockView?.updatedByName ?? ''}
                            />
                            <PackmanLabel
                                label={'Updated On'}
                                value={formatDateTime(this.state.selectedStockView?.updatedOn ?? '')}
                            />
                            <PackmanLabel
                                label={'Report File'}
                                value={this.state.selectedStockView?.reportFile ?? ''}
                            />
                        </div>
                        <div className={'fdr pt10'}>
                            <PackmanLabel
                                label={'Comment'}
                                value={this.state.selectedStockView?.userComment ?? ''}
                            />
                            <PackmanLabel
                                label={'Dispatches'}
                                value={this.formatDispatches(this.state.selectedStockView?.dispatchIds ?? '', this.state.selectedStockView?.dispatchCode ?? '') ?? ''}
                            />
                            <PackmanLabel
                                label={'Compliance'}
                                value={this.formatCompliance(this.state.selectedStockView?.waybill, this.state.selectedStockView?.complianceId, this.state.selectedStockView?.isRejected) ?? ''}
                            />
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>Inspected?</Typography>
                                <BooleanFlag value={this.state.selectedStockView?.isInspected ?? false}/>
                            </div>
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>Rejected?</Typography>
                                <BooleanFlag value={this.state.selectedStockView?.isRejected ?? false}/>
                            </div>
                            <div className={'fdr aic flx1'}>
                                <Typography className={'fs14 mr5'}>Replaced?</Typography>
                                <BooleanFlag value={this.state.selectedStockView?.isReplaced ?? false}/>
                            </div>
                            <div className={'aic flx1'}>
                                <Typography className={'fs14 mr5'}>Temporary?</Typography>
                                <BooleanFlag value={this.state.selectedStockView?.isTemporary ?? false}/>
                            </div>
                        </div>
                        <div className={'fdr pt10 aifs'}>
                            <div className={'aic flx1'}>
                                <Typography className={'fs14 mr5'}>Imported From PO?</Typography>
                                <BooleanFlag value={this.state.selectedStockView?.isImportedFromPO ?? false}/>
                            </div>
                            <div className={'aic flx1'}>
                                <Typography className={'fs14 mr5'}>Active?</Typography>
                                <BooleanFlag value={this.state.selectedStockView?.isActive ?? false}/>
                            </div>
                            <div className={'aic flx2'} style={{ maxWidth: 'calc(100% * 2/7)' }}>
                                {!this.state.selectedStockView?.isActive ? <PackmanLabel
                                    label={'Delete Reason'}
                                    maxWidth={'100%'}
                                    className={'wrap2line mxwfill oh'}
                                    value={this.state.selectedStockLatestAudit?.deleteReason ?? ''}
                                /> : <></>}
                            </div>
                            <div className={'aic flx3'}/>
                        </div>
                    </div>
                    <Typography variant={'h5'} className={'mt20 ml20'}>Stock Lines</Typography>
                    <Paper className={'mr20 ml20 mb20'}>
                        <CustomTable<IStockLineView>
                            columns={this.getColumns()}
                            rows={this.getRows(this.props, this.state)}
                            enableSorting
                            warning={(row : IStockLineView) => this.checkOrchardComplianceCode(row)}
                            initialSortOrder={[{ columnName: 'sequence_Sequence', direction : 'asc' }]}
                            isActive={(row : IStockLineView) => row.isActive}
                        />
                    </Paper>
                    <Typography variant={'h5'} className={'ml20'}>Allocated Material Stock</Typography>
                    <Paper className={'mr20 ml20'}>
                        <CustomTable<IMaterialStockLine>
                            columns={this.getAllocatedMaterialColumns()}
                            rows={this.getAllocatedMaterialRows(this.props, this.state)}
                            isActive={(row : IMaterialStockLine) => row.isActive}
                        />
                    </Paper>
                    <div className={'fdr'}>
                        <div className={'pt10 pl20'}>
                            <PillButton
                                color={'secondary'}
                                className={'h30'}
                                text={'Audits'}
                                onClick={this.onGenerateStockAudits}
                                disabled={!!this.state.isLoading}/>
                        </div>
                        <div className={'pt10 pl20'}>
                            <PillButton
                                color={'secondary'}
                                className={'h30'}
                                text={'Download Report File'}
                                onClick={this.showDownloadReportPopup}
                                disabled={!!this.state.isLoading}/>
                        </div>
                        <div className={'asfe fdr jcfe pt10 pr20 wfill'}>
                            {this.renderBreakDownButton(this.props, this.state)}
                        </div>
                    </div>
                </div>
                {!!this.state.isDownloadReportFormOpen &&
                    <PackmanDialog
                        title='Download Report File'
                        isInfo
                        isLoading={this.state.isLoading}
                        isOpen={this.state.isDownloadReportFormOpen}
                        onClose={this.closeDownloadReportPopup}>
                        <Formik
                            initialValues={this.generateReportForm()}
                            isInitialValid={ReportFileFormValues.formSchema.isValidSync(this.generateReportForm())}
                            onSubmit={this.downloadReportFile}
                            onReset={this.onReportFileReset}
                            enableReinitialize
                            validationSchema={ReportFileFormValues.formSchema}
                            component={ReportFileForm} />
                    </PackmanDialog >}
                <PackmanDialog
                    title={'Stock Break Down Into Lots'}
                    isInfo
                    isWarning
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isBreakDownIntoLotsDialogOpen}
                    onClose={this.onBreakDownConfirmationNoClick}>
                    <div className={'fdc p20'}>
                        <div className={'fdr aic jcc pb5 fs16'}>{'Are you sure you want to break this stock down into lots?'}</div>
                        <div className={'fdr aic jcc pb5 fwb fs16'}>{'This action can not be undone.'}</div>
                        <div className={'fdr ml10 ais jcfe mt20'}>
                            <Button
                                className={'fwb h35'}
                                variant='text' color='primary'
                                onClick={this.onBreakDownConfirmationNoClick}
                            >
                                Cancel
                            </Button>
                            <PillButton
                                className={'ml15 pl20 pr20 h35'}
                                text={'OK'}
                                color={'secondary'}
                                onClick={this.onBreakDownConfirmationYesClick}
                            />
                        </div>
                    </div>
                </PackmanDialog>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        packs: state.masterData.packs,
        palletBaseTypes: state.masterData.palletBaseTypes,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        orchards: state.masterData.orchards,
        organizations: state.masterData.organizations,
        sites: state.masterData.sites,
        reports: state.masterData.reports,
        printServers: state.masterData.printServers,
        selectedSiteIds: state.data.selectedSiteIds,
        storageUnits: state.masterData.storageUnits,
        batches: state.data.batches,
        farms: state.masterData.farms,
        commodityStates: state.masterData.commodityStates,
        sizes: state.masterData.sizes,
        colours: state.masterData.colours,
        grades: state.masterData.grades,
        markets: state.masterData.markets,
        brands: state.masterData.brands,
        regions: state.masterData.regions,
        countries: state.masterData.countries,
        orderHeaders: state.data.orderHeaders,
        inventoryData: state.masterData.inventories,
        materialStockData: state.data.materialStocks,
        materials: state.masterData.materials,
        unitOfMeasures: state.masterData.unitOfMeasures,
    };
};

const mapDispatchToProps  = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetUnitOfMeasures, dataSetMaterials, dataSetMaterialStocks }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StockSummary);
