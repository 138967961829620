import * as React from 'react';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetUnitOfMeasureTypes } from '../../../store/masterData/Actions';
import { formatDateTime, isNullOrWhiteSpace, upsertArrayElement } from '../../../services/appFunctionsService';
import { IUnitOfMeasureType, UnitOfMeasureType } from '../../../@types/model/masterData/unitOfMeasureType/unitOfMeasureType';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import UnitOfMeasureTypeHttpService from '../../../services/http/masterData/unitOfMeasureTypeHttpService';
import Screen from '../../../components/Screen';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikHelpers } from 'formik';
import { IUnitOfMeasureTypeFormValues, UnitOfMeasureTypeFormValues } from '../../../@types/model/masterData/unitOfMeasureType/unitOfMeasureTypeFormValues';
import { createSelector } from 'reselect';
import UnitOfMeasureTypeForm from './form/UnitOfMeasureTypeForm';
import { ConfirmationDialog, CustomTable } from '@zz2/zz2-ui';
import { v4 as uuidv4 } from 'uuid';
import { setUnitOfMeasureTypeMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface IUnitOfMeasureTypeScreenProps {
    dataSetUnitOfMeasureTypes : DispatchCall<Array<IUnitOfMeasureType>>;
    unitOfMeasureTypeData : Array<IUnitOfMeasureType>;
}

interface IUnitOfMeasureTypeScreenState {
    rows : Array<IUnitOfMeasureType>;
    isLoading : boolean;
    selectedUnitOfMeasureType ?: IUnitOfMeasureType;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    formGuid ?: string;
    isDeletePopupOpen : boolean;
    deletingUnitOfMeasureType ?: IUnitOfMeasureType;
}

class UnitOfMeasureTypeScreen extends React.Component<IUnitOfMeasureTypeScreenProps, IUnitOfMeasureTypeScreenState> {
    constructor(props : IUnitOfMeasureTypeScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedUnitOfMeasureType: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await UnitOfMeasureTypeHttpService.getUnitOfMeasureTypeData();

                this.props.dataSetUnitOfMeasureTypes(res.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading unit of measure types.');
                this.setLoading(false);
            }
        }
    };

    private onUnitOfMeasureTypeAdd = () => {
        this.setState({ isAdding: true });
    };

    private onUnitOfMeasureTypeEdit = (row : IUnitOfMeasureType) => {
        this.setState({ isEditing: true, selectedUnitOfMeasureType: row });
    };

    private onFormClose = () => {
        this.setState({
            isAdding: false,
            isEditing: false,
            selectedUnitOfMeasureType: undefined,
        });
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    public openDialog = () => {
        this.setState({
            formGuid: uuidv4(),
            isDialogOpen: true,
        });
    };

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            formGuid: undefined,
            selectedUnitOfMeasureType: undefined,
        });
    };

    public onSubmit = async (values : IUnitOfMeasureTypeFormValues) => {
        this.setLoading(true);

        let payload = { ...values };
        if (isNullOrWhiteSpace(payload.guid)) {
            payload.guid = this.state.formGuid ?? '';
        }

        try {
            const res = await UnitOfMeasureTypeHttpService.addOrUpdateUnitOfMeasureType(new UnitOfMeasureType(payload));

            const newUnitOfMeasureTypeList = upsertArrayElement(this.props.unitOfMeasureTypeData, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetUnitOfMeasureTypes(newUnitOfMeasureTypeList);
            await setUnitOfMeasureTypeMasterDataIndexedDB(newUnitOfMeasureTypeList);

            if (this.state.selectedUnitOfMeasureType) {
                generalShowSuccessSnackbar('Unit Of Measure Type updated successfully.');
            } else {
                generalShowSuccessSnackbar('Unit Of Measure Type added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating unit of measure type data.');
        } finally {
            this.setLoading(false);
        }
    };

    private openDeleteConfirmationPopup = (unitOfMeasureType : IUnitOfMeasureType) => {
        this.setState({ isDeletePopupOpen: true, deletingUnitOfMeasureType: unitOfMeasureType });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingUnitOfMeasureType: undefined });
    };

    private removeUnitOfMeasureType = async () => {
        const newUnitOfMeasureType = this.state.deletingUnitOfMeasureType;
        if (newUnitOfMeasureType) {
            newUnitOfMeasureType.isActive = false;
            this.setLoading(true);

            try {
                const res = await UnitOfMeasureTypeHttpService.deleteUnitOfMeasureType(newUnitOfMeasureType.id);

                const newUnitOfMeasureTypeList = upsertArrayElement(this.props.unitOfMeasureTypeData, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetUnitOfMeasureTypes(newUnitOfMeasureTypeList);
                await setUnitOfMeasureTypeMasterDataIndexedDB(newUnitOfMeasureTypeList);

                generalShowSuccessSnackbar('Unit Of Measure Type updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting Unit Of Measure Type.');
                newUnitOfMeasureType.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    public onReset = async (formValues : IUnitOfMeasureTypeFormValues, formikHelpers : FormikHelpers<IUnitOfMeasureTypeFormValues>) => {
        formikHelpers.resetForm();
        this.closeDialog();
    };

    public editUnitOfMeasureType = (unitOfMeasureType : IUnitOfMeasureType) => {
        this.setState({
            formGuid: uuidv4(),
            isDialogOpen: true,
            selectedUnitOfMeasureType: unitOfMeasureType,
        });
        this.openDialog();
    };

    public getSelectedUnitOfMeasureType = (props : IUnitOfMeasureTypeScreenProps, state : IUnitOfMeasureTypeScreenState) => state.selectedUnitOfMeasureType;

    public getInitialFormValues = createSelector(
        [this.getSelectedUnitOfMeasureType],
        (unitOfMeasureType : IUnitOfMeasureType) => {
            return new UnitOfMeasureTypeFormValues(unitOfMeasureType);
        },
    );

    public render() {
        const rows = this.props.unitOfMeasureTypeData ? this.props.unitOfMeasureTypeData : [];
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Unit Of Measure Type'
                    isEdit={!!this.state.selectedUnitOfMeasureType}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={UnitOfMeasureTypeFormValues.formSchema}
                        component={UnitOfMeasureTypeForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IUnitOfMeasureType>
                            enableAdding
                            addFunction={this.openDialog}
                            editFunction={this.editUnitOfMeasureType}
                            enableEditing={() => true}
                            enableDeleting={(unitOfMeasureType : IUnitOfMeasureType) => unitOfMeasureType.isActive}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            fitWidthToPage
                            enablePagination
                            columns={[
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IUnitOfMeasureType) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationDialog
                    title={'Delete Unit Of Measure Type'}
                    open={this.state.isDeletePopupOpen}
                    description={'Are you sure you want to delete this unit of measure type?'}
                    onAccept ={this.removeUnitOfMeasureType}
                    onClose ={this.closeDeleteConfirmationPopup}
                    dialogType='orange'
                    isLoading={this.state.isLoading}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        unitOfMeasureTypeData: state.masterData.unitOfMeasureTypes,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetUnitOfMeasureTypes }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UnitOfMeasureTypeScreen);
