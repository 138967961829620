import * as React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './app.scss';
import { Provider } from 'react-redux';

import { SnackbarProvider } from 'notistack';
import store, { history } from './store/Index';
import App from './App';
import red from '@mui/material/colors/red';
import orange from '@mui/material/colors/orange';
import blue from '@mui/material/colors/blue';
import { ClearCacheProvider } from './services/clearCache/clearCacheService';
import { Router } from 'react-router-dom';
import materialTheme from './styles/materialTheme';

if (process.env.NODE_ENV === 'production') {
    // eslint-disable-next-line no-console
    console.log('Production?', process.env.NODE_ENV);
}

const AppRoot = () => {
    return (
        <Provider store={store}>
            <Router history={history}>
                <SnackbarProvider maxSnack={4}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    classes={{
                        variantSuccess: materialTheme.palette.primary.main,
                        variantError: red[600],
                        variantWarning: orange[600],
                        variantInfo: blue[600],
                    }}
                >
                    <App/>
                </SnackbarProvider>
            </Router>
        </Provider>
    );
};

const rootDomElement = document.getElementById('root');

if (rootDomElement) {
    ReactDOM.render(<ClearCacheProvider><AppRoot /></ClearCacheProvider>, rootDomElement);
}
