import * as React from 'react';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { addkg, addea, formatDateTime, upsertArrayElement, isNullOrWhiteSpace } from '../../../../services/appFunctionsService';
import { dataSetAllPackRelatedData } from '../../../../store/masterData/Functions';
import { IPack, Pack } from '../../../../@types/model/masterData/pack/pack';
import { IPackCategory } from '../../../../@types/model/masterData/pack/packCategory';
import { ICommodity } from '../../../../@types/model/masterData/commodity/commodity';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../../store/general/Functions';
import PackRelatedDataHttpService from '../../../../services/http/masterData/packRelatedDataHttpService';
import { IPackFormValues, PackFormValues } from '../../../../@types/model/masterData/pack/packFormValues';
import PackHttpService from '../../../../services/http/masterData/packHttpService';
import { FormikHelpers, Formik } from 'formik';
import { createSelector } from 'reselect';
import PackmanDialog from '../../../../components/dialog/PackmanDialog';
import PackForm from './form/PackForm';
import { dataSetPacks } from '../../../../store/masterData/Actions';
import { ISize } from '../../../../@types/model/masterData/size/size';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../../@types/model/user/right';
import PackSummary from './PackSummary';
import { ConfirmationDialog, CustomTable } from '@zz2/zz2-ui';
import { v4 as uuidv4 } from 'uuid';
import { setPackMasterDataIndexedDB, syncMasterData } from '../../../../services/masterDataSyncService';

interface IPackScreenProps extends RouteComponentProps {
    packs : Array<IPack>;
    packCategories : Array<IPackCategory>;
    selectedCommodity ?: ICommodity;
    commodities : Array<ICommodity>;
    sizes : Array<ISize>;
    dataSetPacks : DispatchCall<Array<IPack>>;
    auth : IAuthState;
}

interface IPackScreenState {
    rows : Array<IPack>;
    isLoading : boolean;
    selectedPack ?: IPack;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    formGuid ?: string;
    isSummaryOpen : boolean;
    isDeletePopupOpen : boolean;
    deletingPack ?: IPack;
}

class PackScreen extends React.Component<IPackScreenProps, IPackScreenState> {
    constructor(props : IPackScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedPack: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isSummaryOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await PackRelatedDataHttpService.getAllPackRelatedData();

                dataSetAllPackRelatedData(res.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred retrieving pack related data.');
                this.setLoading(false);
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading : loading });
    };

    private formatSizes = (sizeIds : Array<number>) => this.props.sizes
        .filter(x => sizeIds.some(y => y === x.id))
        .map(x => x.code).toString().replace(/,/g, ', ');

    private formatCategory = (catId : number) => {
        const category = this.props.packCategories.find(x => x.id === catId);
        return category ? category.category : '';
    };

    public onSubmit = async (values : IPackFormValues) => {
        this.setLoading(true);

        let payload = {
            ...values,
            billOfMaterials: values.billOfMaterials.filter(x => !!x.isActive),
        };

        if (isNullOrWhiteSpace(payload.guid)) {
            payload.guid = this.state.formGuid ?? '';
        }

        try {
            const res = await PackHttpService.addOrUpdatePack(new Pack(payload));

            const newPackList = upsertArrayElement(this.props.packs, res.data, x => x.id === res.data.id) ?? [];

            this.props.dataSetPacks(newPackList);
            await setPackMasterDataIndexedDB(newPackList);

            if (this.state.selectedPack) {
                generalShowSuccessSnackbar('Pack updated successfully.');
            } else {
                generalShowSuccessSnackbar('Pack added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating pack data.');
        } finally {
            this.setLoading(false);
        }
    };

    public onReset = async (formValues : IPackFormValues, formikHelpers : FormikHelpers<IPackFormValues>) => {
        formikHelpers.resetForm();
        this.closeDialog();
    };

    private openDeleteConfirmationPopup = (pack : IPack) => {
        this.setState({ isDeletePopupOpen: true, deletingPack: pack });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingPack: undefined });
    };

    private removePack = async () => {
        const newPack = this.state.deletingPack;
        if (newPack) {
            newPack.isActive = false;
            this.setLoading(true);

            try {
                const res = await PackHttpService.deletePack(newPack.id);

                const newPackList = upsertArrayElement(this.props.packs, res.data, x => x.id === res.data.id) ?? [];

                this.props.dataSetPacks(newPackList);
                await setPackMasterDataIndexedDB(newPackList);

                generalShowSuccessSnackbar('Pack updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting pack.');
                newPack.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    public getSelectedPack = (props : IPackScreenProps, state : IPackScreenState) => state.selectedPack;
    public getSelectedCommodity = (props : IPackScreenProps) => props.selectedCommodity;
    public getSizes = (props : IPackScreenProps) => props.sizes;
    public getCommodities = (props : IPackScreenProps) => props.commodities;
    public getPackCategories = (props : IPackScreenProps) => props.packCategories;

    public getInitialFormValues = createSelector(
        [this.getSelectedPack, this.getSelectedCommodity, this.getCommodities, this.getPackCategories, this.getSizes],
        (pack : IPack, commodity ?: ICommodity, commodities ?: Array<ICommodity>, packCategories ?: Array<IPackCategory>, sizes ?: Array<ISize>) => {
            return new PackFormValues(pack, commodity, commodities, packCategories, sizes);
        },
    );

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            formGuid: undefined,
            selectedPack: undefined,
        });
    };

    public openDialog = () => {
        this.setState({
            formGuid: uuidv4(),
            isDialogOpen: true,
        });
    };

    private onPackEdit = (row : IPack) => {
        this.setState({
            isDialogOpen: true,
            selectedPack: row,
        });
    };

    private getRights = (props : IPackScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IPackScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    private showSummaryDialog = (pack : IPack) => {
        this.setState({ isSummaryOpen: true, selectedPack: pack });
    };

    private closeSummaryDialog = () => {
        this.setState({ isSummaryOpen: false, selectedPack: undefined });
    };

    public render() {
        const selectedCommodity = this.props.selectedCommodity;
        const rows = selectedCommodity && this.props.packs ? this.props.packs.filter(x => x.commodityId === selectedCommodity?.id) : [];
        const initialValues = this.getInitialFormValues(this.props, this.state);

        return (
            <>
                <PackmanDialog
                    title={'Pack' + (this.props.selectedCommodity ? ' - (' + this.props.selectedCommodity?.code + ')' : '')}
                    isEdit={!!this.state.selectedPack}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    fullScreen
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={PackFormValues.formSchema}
                        component={PackForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IPack>
                            enableDetails={true}
                            detailIcon={'info'}
                            detailTooltip={'Pack Summary'}
                            detailFunction={this.showSummaryDialog}
                            enableAdding={!!this.props.selectedCommodity && this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.onPackEdit}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(pack : IPack) => pack.isActive && this.hasEditingRight(this.props)}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            enablePagination
                            columns={[
                                { title: 'Code', field: 'code', enableFiltering: true, enableSorting: true },
                                { title: 'Description', field: 'description', enableFiltering: true, enableSorting: true },
                                { title: 'No. Cartons on Pallet (ea)', field: 'noCartons', formatFunction: addea, enableFiltering: true, enableSorting: true },
                                { title: 'No. Stacked Units (ea)', field: 'numberOfStackedUnits', formatFunction: addea, enableFiltering: true, enableSorting: true },
                                { title: 'Outer Packaging Weight (kg)', field: 'packagingWeight', formatFunction: addkg, enableFiltering: true, enableSorting: true },
                                { title: 'Outer Gross Weight (kg)', field: 'grossWeight', formatFunction: addkg, enableFiltering: true, enableSorting: true },
                                { title: 'Product Nett Weight In An Outer (kg)', field: 'nettWeight', formatFunction: addkg, enableFiltering: true, enableSorting: true },
                                { title: 'No. Inner Units (ea)', field: 'noUnitsPerCarton', formatFunction: addea, enableFiltering: true, enableSorting: true },
                                { title: 'Inner Packaging Weight (kg)', field: 'unitPackagingWeight', formatFunction: addkg, enableFiltering: true, enableSorting: true },
                                { title: 'Inner Gross Weight (kg)', field: 'unitGrossWeight', formatFunction: addkg, enableFiltering: true, enableSorting: true },
                                { title: 'Inner Nett Weight (kg)', field: 'unitNettWeight', formatFunction: addkg, enableFiltering: true, enableSorting: true },
                                { title: 'Category', field: 'categoryId', formatFunction: this.formatCategory, enableFiltering: true, enableSorting: true },
                                { title: 'Has Inner Pack?', field: 'hasInnerPack', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Intake Pack?', field: 'isIntakePack', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Sizes', field: 'sizeIds', formatFunction: this.formatSizes, width: 300, enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction : 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={340}
                            isActive={(row : IPack) => row.isActive}
                        />
                    </Card>
                </div>
                {/* Summary Dialog */}
                <PackmanDialog
                    title='Pack Summary'
                    isInfo={true}
                    fullWidth
                    maxWidth={'md'}
                    isOpen={this.state.isSummaryOpen}
                    onClose={this.closeSummaryDialog}>
                    {this.state.selectedPack &&
                        <div className={'p20'}>
                            <PackSummary selectedPack={this.state.selectedPack}/>
                        </div>
                    }
                </PackmanDialog >
                <ConfirmationDialog
                    title={'Delete Pack'}
                    open={this.state.isDeletePopupOpen}
                    description={'Are you sure you want to delete this pack?'}
                    onAccept ={this.removePack}
                    onClose ={this.closeDeleteConfirmationPopup}
                    dialogType='orange'
                    isLoading={this.state.isLoading}
                />
            </>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        packs: state.masterData.packs,
        sizes: state.masterData.sizes,
        commodities: state.masterData.commodities,
        packCategories: state.masterData.packCategories,
        auth: state.auth,
    };
};

const mapDispatchToProps  = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetPacks }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PackScreen);
