import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
import React from 'react';
import { useAppDispatch } from '../../@types/redux';
import { authSetLoggedIn, authSetLoggingIn } from '../../store/auth/Actions';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import { authSignInGoogle } from '../../store/auth/Functions';
import PillButton from '../input/PillButton';

const GoogleSignInButton = () => {
    const dispatch = useAppDispatch();

    const onSuccess = async (response : Omit<CodeResponse, 'error' | 'error_description' | 'error_uri'>) => {
        if (!('code' in response)) {
            dispatch(authSetLoggingIn(false));
            return;
        }

        const result = await authSignInGoogle(response.code);
        dispatch(authSetLoggingIn(false));
        if (result) {
            dispatch(authSetLoggedIn(true));
            generalShowSuccessSnackbar('Success');
        }
    };

    const onFailure = () => {
        generalShowErrorSnackbar('Login Error');
        dispatch(authSetLoggingIn(false));
    };

    const login = useGoogleLogin({
        onSuccess,
        onError: onFailure,
        flow: 'auth-code',
        scope: 'profile email openid',
        // enable_serial_consent: false,
    });

    const onLoginClick = () => {
        login();
    };

    return (
        <PillButton
            onClick={onLoginClick}
            text={'Sign in with Google'}
            className={'w250'}
            icon={<img className='pl40' src={`${ASSET_BASE}/assets/icons/google_icon.svg`}/>}
        />
    );
};

export default GoogleSignInButton;
