import * as React from 'react';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetColours } from '../../../../store/masterData/Actions';
import { formatDateTime, isNullOrWhiteSpace, upsertArrayElement } from '../../../../services/appFunctionsService';
import { IColour, Colour } from '../../../../@types/model/masterData/colour/colour';
import { ICommodity } from '../../../../@types/model/masterData/commodity/commodity';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../../store/general/Functions';
import ColourHttpService from '../../../../services/http/masterData/colourHttpService';
import { IColourFormValues, ColourFormValues } from '../../../../@types/model/masterData/colour/colourFormValues';
import { FormikHelpers, Formik } from 'formik';
import { createSelector } from 'reselect';
import PackmanDialog from '../../../../components/dialog/PackmanDialog';
import ColourForm from './form/ColourForm';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../../@types/model/user/right';
import { v4 as uuidv4 } from 'uuid';
import { setColourMasterDataIndexedDB, syncMasterData } from '../../../../services/masterDataSyncService';
import { ConfirmationDialog, CustomTable } from '@zz2/zz2-ui';

interface IColourScreenProps extends RouteComponentProps {
    dataSetColours : DispatchCall<Array<IColour>>;
    colours : Array<IColour>;
    selectedCommodity ?: ICommodity;
    commodities : Array<ICommodity>;
    auth : IAuthState;
}

interface IColourScreenState {
    rows : Array<IColour>;
    isLoading : boolean;
    selectedColour ?: IColour;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    formGuid ?: string;
    isDeletePopupOpen : boolean;
    deletingColour ?: IColour;
}

class ColourScreen extends React.Component<IColourScreenProps, IColourScreenState> {
    constructor(props : IColourScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedColour: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await ColourHttpService.getColourData();

                this.props.dataSetColours(res.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading colours.');
                this.setLoading(false);
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading : loading });
    };

    private formatCommodities = (commodityIds : Array<number>) => {
        let string = this.props.commodities
            .filter(x => commodityIds.some(y => y === x.id))
            .map(x => x.code).toString().replace(/,/g, ', ');
        if (string.length > 85) {
            string = string.substr(0, 86) + '...';
        }
        return string;
    };

    public onSubmit = async (values : IColourFormValues) => {
        this.setLoading(true);

        let payload = { ...values };
        if (isNullOrWhiteSpace(payload.guid)) {
            payload.guid = this.state.formGuid ?? '';
        }

        try {
            const res = await ColourHttpService.addOrUpdateColour(new Colour(payload));

            const newColourList = upsertArrayElement(this.props.colours, res.data, x => x.id === res.data.id) ?? [];

            this.props.dataSetColours(newColourList);
            await setColourMasterDataIndexedDB(newColourList);

            if (this.state.selectedColour) {
                generalShowSuccessSnackbar('Colour updated successfully.');
            } else {
                generalShowSuccessSnackbar('Colour added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating colour data.');
        } finally {
            this.setLoading(false);
        }
    };

    public onReset = async (formValues : IColourFormValues, formikHelpers : FormikHelpers<IColourFormValues>) => {
        formikHelpers.resetForm();
        this.closeDialog();
    };

    private openDeleteConfirmationPopup = (colour : IColour) => {
        this.setState({ isDeletePopupOpen: true, deletingColour: colour });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingColour: undefined });
    };

    private removeColour = async () => {
        const newColour = this.state.deletingColour;
        if (newColour) {
            newColour.isActive = false;
            this.setLoading(true);

            try {
                const res = await ColourHttpService.deleteColour(newColour.id);

                const newColourList = upsertArrayElement(this.props.colours, res.data, x => x.id === res.data.id) ?? [];

                this.props.dataSetColours(newColourList);
                await setColourMasterDataIndexedDB(newColourList);

                generalShowSuccessSnackbar('Colour updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting colour.');
                newColour.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    public getSelectedColour = (props : IColourScreenProps, state : IColourScreenState) => state.selectedColour;
    public getSelectedCommodity = (props : IColourScreenProps) => props.selectedCommodity;
    public getCommodities = (props : IColourScreenProps) => props.commodities;

    public getInitialFormValues = createSelector(
        [this.getSelectedColour, this.getSelectedCommodity, this.getCommodities],
        (colour : IColour, commodity ?: ICommodity, commodities ?: Array<ICommodity>) => {
            return new ColourFormValues(colour, commodity, commodities);
        },
    );

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            formGuid: undefined,
            selectedColour: undefined,
        });
    };

    public openDialog = () => {
        this.setState({
            formGuid: uuidv4(),
            isDialogOpen: true,
        });
    };

    private onColourEdit = (row : IColour) => {
        this.setState({
            isDialogOpen: true,
            selectedColour: row,
        });
    };

    private getRights = (props : IColourScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IColourScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const selectedCommodity = this.props.selectedCommodity;
        const rows = selectedCommodity && this.props.colours ? this.props.colours.filter(x => x.commodityIds?.some(y => y === selectedCommodity?.id)) : this.props.colours;
        const initialValues = this.getInitialFormValues(this.props, this.state);

        return (
            <>
                <PackmanDialog
                    title={'Colour' + (this.props.selectedCommodity ? ' - (' + this.props.selectedCommodity?.code + ')' : '')}
                    isEdit={!!this.state.selectedColour}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={ColourFormValues.formSchema}
                        component={ColourForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IColour>
                            enableAdding={!!this.props.selectedCommodity && this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.onColourEdit}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(colour : IColour) => colour.isActive && this.hasEditingRight(this.props)}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            enablePagination
                            columns={[
                                { title: 'Code', field: 'code' , enableFiltering: true, enableSorting: true },
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Commodities', field: 'commodityIds', formatFunction: this.formatCommodities, width: 300, enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            fitWidthToPage
                            initialSortOrder={[{ columnName: 'id_Id', direction : 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={340}
                            isActive={(row : IColour) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationDialog
                    title={'Delete Colour'}
                    open={this.state.isDeletePopupOpen}
                    description ={'Are you sure you want to delete this colour?'}
                    onAccept ={this.removeColour}
                    onClose ={this.closeDeleteConfirmationPopup}
                    dialogType='orange'
                    isLoading={this.state.isLoading}
                />
            </>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        colours: state.masterData.colours,
        commodities: state.masterData.commodities,
        auth: state.auth,
    };
};

const mapDispatchToProps  = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetColours }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ColourScreen);
