import * as React from 'react';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetBrands } from '../../../store/masterData/Actions';
import { formatDateTime, isNullOrWhiteSpace, upsertArrayElement } from '../../../services/appFunctionsService';
import { Brand, IBrand } from '../../../@types/model/masterData/brand/brand';
import MarkHttpService from '../../../services/http/masterData/markHttpService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import Screen from '../../../components/Screen';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikHelpers } from 'formik';
import { createSelector } from 'reselect';
import { BrandFormValues, IBrandFormValues } from '../../../@types/model/masterData/brand/brandFormValues';
import BrandForm from './form/BrandForm';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import { v4 as uuidv4 } from 'uuid';
import { setBrandsMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';
import { ConfirmationDialog, CustomTable } from '@zz2/zz2-ui';

interface IBrandScreenProps extends RouteComponentProps {
    dataSetBrands : DispatchCall<Array<IBrand>>;
    markData : Array<IBrand>;
    auth : IAuthState;
}

interface IBrandScreenState {
    rows : Array<IBrand>;
    isLoading : boolean;
    selectedBrand ?: IBrand;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    formGuid ?: string;
    isDeletePopupOpen : boolean;
    deletingBrand ?: IBrand;
}

class BrandScreen extends React.Component<IBrandScreenProps, IBrandScreenState> {
    constructor(props : IBrandScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedBrand: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);

            try {
                const res = await MarkHttpService.getMarkData();

                this.props.dataSetBrands(res.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading brands.');
                this.setLoading(false);
            }
        }
    };

    public editBrand = (brand : IBrand) => {
        this.setState({
            isDialogOpen: true,
            selectedBrand: brand,
        });
        this.openDialog();
    };

    public openDialog = () => {
        this.setState({
            formGuid: uuidv4(),
            isDialogOpen: true,
        });
    };

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            formGuid: undefined,
            selectedBrand: undefined,
        });
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    public onSubmit = async (values : IBrandFormValues) => {
        this.setLoading(true);

        let payload = { ...values };
        if (isNullOrWhiteSpace(payload.guid)) {
            payload.guid = this.state.formGuid ?? '';
        }

        try {
            const res = await MarkHttpService.addOrUpdateBrand(new Brand(payload));

            const newMarkList = upsertArrayElement(this.props.markData, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetBrands(newMarkList);
            await setBrandsMasterDataIndexedDB(newMarkList);

            if (this.state.selectedBrand) {
                generalShowSuccessSnackbar('Brand updated successfully.');
            } else {
                generalShowSuccessSnackbar('Brand added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating Brand data.');
        } finally {
            this.setLoading(false);
        }
    };

    private openDeleteConfirmationPopup = (brand : IBrand) => {
        this.setState({ isDeletePopupOpen: true, deletingBrand: brand });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingBrand: undefined });
    };

    private removeBrand = async () => {
        const newBrand = this.state.deletingBrand;
        if (newBrand) {
            newBrand.isActive = false;
            this.setLoading(true);

            try {
                const res = await MarkHttpService.deleteBrand(newBrand.id);

                const newMarkList = upsertArrayElement(this.props.markData, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetBrands(newMarkList);
                await setBrandsMasterDataIndexedDB(newMarkList);

                generalShowSuccessSnackbar('Brand updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting Brand.');
                newBrand.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    public onReset = async (formValues : IBrandFormValues, formikHelpers : FormikHelpers<IBrandFormValues>) => {
        formikHelpers.resetForm();
        this.closeDialog();
    };

    public getSelectedBrand = (props : IBrandScreenProps, state : IBrandScreenState) => state.selectedBrand;

    public getInitialFormValues = createSelector(
        [this.getSelectedBrand],
        (brand ?: IBrand) => {
            return new BrandFormValues(brand);
        },
    );

    private getRights = (props : IBrandScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IBrandScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.props.markData ? this.props.markData : [];
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Brand'
                    isEdit={!!this.state.selectedBrand}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={BrandFormValues.formSchema}
                        component={BrandForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IBrand>
                            enableAdding={this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.editBrand}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(brand : IBrand) => brand.isActive && this.hasEditingRight(this.props)}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            fitWidthToPage
                            columns={[
                                { title: 'Code', field: 'code', enableFiltering: true, enableSorting: true },
                                { title: 'Description', field: 'description', enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IBrand) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationDialog
                    title={'Delete Brand'}
                    open={this.state.isDeletePopupOpen}
                    description ={'Are you sure you want to delete this brand?'}
                    onAccept ={this.removeBrand}
                    onClose ={this.closeDeleteConfirmationPopup}
                    dialogType='orange'
                    isLoading={this.state.isLoading}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        markData: state.masterData.brands,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetBrands }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BrandScreen);
