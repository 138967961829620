import { createAction } from 'typesafe-actions';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { IPackCategory } from '../../@types/model/masterData/pack/packCategory';
import { ISize } from '../../@types/model/masterData/size/size';
import { IColour } from '../../@types/model/masterData/colour/colour';
import { IGrade } from '../../@types/model/masterData/grade/grade';
import { ICommodityState } from '../../@types/model/masterData/commodityState/commodityState';
import { IOrchard } from '../../@types/model/masterData/orchard/orchard';
import { IFarm } from '../../@types/model/masterData/farm/farm';
import { ICountry } from '../../@types/model/masterData/country/country';
import { IMarket } from '../../@types/model/masterData/market/market';
import { IBrand } from '../../@types/model/masterData/brand/brand';
import { ILotType } from '../../@types/model/masterData/lotType/lotType';
import { IRegion } from '../../@types/model/masterData/region/region';
import { ISite } from '../../@types/model/masterData/site/site';
import { IInspectionPoint } from '../../@types/model/masterData/inspectionPoint/inspectionPoint';
import { IPalletBaseType } from '../../@types/model/masterData/palletBaseType/palletBaseType';
import { ICarrier } from '../../@types/model/masterData/carrier/carrier';
import { IMaterial } from '../../@types/model/masterData/material/material';
import { IMaterialType } from '../../@types/model/masterData/materialType/materialType';
import { IUnitOfMeasure } from '../../@types/model/masterData/unitOfMeasure/unitOfMeasure';
import { IUnitOfMeasureType } from '../../@types/model/masterData/unitOfMeasureType/unitOfMeasureType';
import { ICommodityCondition } from '../../@types/model/masterData/commodityCondition/commodityCondition';
import { ISeason } from '../../@types/model/masterData/season/season';
import { IStorageUnit } from '../../@types/model/masterData/storageUnit/storageUnit';
import { IInventory } from '../../@types/model/masterData/inventory/inventory';
import { IDomain } from '../../@types/model/user/domain';
import { IDepartment } from '../../@types/model/masterData/department/department';
import { IContactInfo } from '../../@types/model/masterData/contactInfo/contactInfo';
import { ITruckType } from '../../@types/model/masterData/truckType/truckType';
import { IDevice } from '../../@types/model/masterData/device/device';
import { IProject } from '../../@types/model/masterData/project/project';
import { IBarcodeRange } from '../../@types/model/masterData/barcodeRange/barcodeRange';
import { IAccreditation } from '../../@types/model/masterData/accreditation/accreditation';
import { IPackLine } from '../../@types/model/masterData/packLine/packLine';
import { IOutlet } from '../../@types/model/masterData/outlet/outlet';
import { IPrinter } from '../../@types/model/masterData/printer/printer';
import { IPrintServer } from '../../@types/model/masterData/printServer/printServer';
import { IReport } from '../../@types/model/masterData/report/report';
import { IAgreementCode } from '../../@types/model/masterData/agreementCode/agreementCode';
import { ISalesPoint } from '../../@types/model/masterData/salesPoint/salesPoint';
import { IMasterDataSyncData } from '../../@types/model/masterData/sync/MasterDataSyncData';
import { IFtpDetail } from '../../@types/model/masterData/ftpDetail/ftpDetail';
import { ISiteSetting } from '../../@types/model/masterData/siteSetting/siteSetting';

/* Master Data */
export const dataSetMasterDataSyncIsLoading = createAction('SET_MASTER_DATA_SYNC_IS_LOADING', resolve => (data : boolean) => resolve(data));
export const dataSetMasterDataSyncData = createAction('SET_MASTER_DATA_SYNC_DATA', resolve => (data : IMasterDataSyncData) => resolve(data));
export const dataSetSelectedCommodity = createAction('SET_SELECTED_COMMODITY', resolve => (data : ICommodity | undefined) => resolve(data));

export const dataSetOrganizations = createAction('SET_ORGANIZATIONS', resolve => (data : Array<IOrganization>) => resolve(data ? data : []));
export const dataSetCommodities = createAction('SET_COMMODITIES', resolve => (data : Array<ICommodity>) => resolve(data ? data : []));
export const dataSetCommodityConditions = createAction('SET_COMMODITY_CONDITIONS', resolve => (data : Array<ICommodityCondition>) => resolve(data ? data : []));
export const dataSetVarieties = createAction('SET_VARIETIES', resolve => (data : Array<IVariety>) => resolve(data ? data : []));
export const dataSetPacks = createAction('SET_PACKS', resolve => (data : Array<IPack>) => resolve(data ? data : []));
export const dataSetPackCategories = createAction('SET_PACK_CATEGORIES', resolve => (data : Array<IPackCategory>) => resolve(data ? data : []));
export const dataSetSizes = createAction('SET_SIZES', resolve => (data : Array<ISize>) => resolve(data ? data : []));
export const dataSetAccreditations = createAction('SET_ACCREDITATIONS', resolve => (data : Array<IAccreditation>) => resolve(data ? data : []));
export const dataSetColours = createAction('SET_COLOURS', resolve => (data : Array<IColour>) => resolve(data ? data : []));
export const dataSetGrades = createAction('SET_GRADES', resolve => (data : Array<IGrade>) => resolve(data ? data : []));
export const dataSetCommodityStates = createAction('SET_COMMODITYSTATES', resolve => (data : Array<ICommodityState>) => resolve(data ? data : []));
export const dataSetOrchards = createAction('SET_ORCHARDS', resolve => (data : Array<IOrchard>) => resolve(data ? data : []));
export const dataSetFarms = createAction('SET_FARMS', resolve => (data : Array<IFarm>) => resolve(data ? data : []));
export const dataSetContactInfos = createAction('SET_CONTACT_INFOS', resolve => (data : Array<IContactInfo>) => resolve(data ? data : []));
export const dataSetCountries = createAction('SET_COUNTRIES', resolve => (data : Array<ICountry>) => resolve(data ? data : []));
export const dataSetMarkets = createAction('SET_MARKETS', resolve => (data : Array<IMarket>) => resolve(data ? data : []));
export const dataSetBrands = createAction('SET_MARKS', resolve => (data : Array<IBrand>) => resolve(data ? data : []));
export const dataSetLotTypes = createAction('SET_LOT_TYPES', resolve => (data : Array<ILotType>) => resolve(data ? data : []));
export const dataSetRegions = createAction('SET_REGIONS', resolve => (data : Array<IRegion>) => resolve(data ? data : []));
export const dataSetSites = createAction('SET_SITES', resolve => (data : Array<ISite>) => resolve(data ? data : []));
export const dataSetStorageUnits = createAction('SET_STORAGE_UNITS', resolve => (data : Array<IStorageUnit>) => resolve(data ? data : []));
export const dataSetInspectionPoints = createAction('SET_INSPECTION_POINTS', resolve => (data : Array<IInspectionPoint>) => resolve(data ? data : []));
export const dataSetPalletBaseTypes = createAction('SET_PALLET_BASE_TYPES', resolve => (data : Array<IPalletBaseType>) => resolve(data ? data : []));
export const dataSetCarriers = createAction('SET_CARRIERS', resolve => (data : Array<ICarrier>) => resolve(data ? data : []));
export const dataSetBarcodeRanges = createAction('SET_BARCODE_RANGES', resolve => (data : Array<IBarcodeRange>) => resolve(data ? data : []));
export const dataSetSeasons = createAction('SET_SEASONS', resolve => (data : Array<ISeason>) => resolve(data ? data : []));
export const dataSetInventories = createAction('SET_IVENTORIES', resolve => (data : Array<IInventory>) => resolve(data ? data : []));
export const dataSetDepartments = createAction('SET_DEPARTMENTS', resolve => (data : Array<IDepartment>) => resolve(data ? data : []));
export const dataSetDomains = createAction('SET_DOMAINS', resolve => (domains : Array<IDomain>) => resolve(domains));
export const dataSetMaterials = createAction('SET_MATERIALS', resolve => (data : Array<IMaterial>) => resolve(data ? data : []));
export const dataSetMaterialTypes = createAction('SET_MATERIAL_TYPES', resolve => (data : Array<IMaterialType>) => resolve(data ? data : []));
export const dataSetUnitOfMeasures = createAction('SET_UNIT_OF_MEASURES', resolve => (data : Array<IUnitOfMeasure>) => resolve(data ? data : []));
export const dataSetUnitOfMeasureTypes = createAction('SET_UNIT_OF_MEASURE_TYPES', resolve => (data : Array<IUnitOfMeasureType>) => resolve(data ? data : []));
export const dataSetTruckTypes = createAction('SET_TRUCK_TYPES', resolve => (data : Array<ITruckType>) => resolve(data ? data : []));
export const dataSetDevices = createAction('SET_DEVICES', resolve => (data : Array<IDevice>) => resolve(data ? data : []));
export const dataSetProjects = createAction('SET_PROJECTS', resolve => (data : Array<IProject>) => resolve(data ? data : []));
export const dataSetPrinters = createAction('SET_PRINTERS', resolve => (data : Array<IPrinter>) => resolve(data ? data : []));
export const dataSetPrintServers = createAction('SET_PRINT_SERVERS', resolve => (data : Array<IPrintServer>) => resolve(data ? data : []));
export const dataSetReports = createAction('SET_REPORT', resolve => (data : Array<IReport>) => resolve(data ? data : []));
export const dataSetSiteSettings = createAction('SET_SITE_SETTINGS', resolve => (data : Array<ISiteSetting>) => resolve(data ? data : []));
export const dataSetPackLines = createAction('SET_PACK_LINES', resolve => (data : Array<IPackLine>) => resolve(data ? data : []));
export const dataSetOutlets = createAction('SET_OUTLETS', resolve => (data : Array<IOutlet>) => resolve(data ? data : []));
export const dataSetAgreementCodes = createAction('SET_AGREEMENT_CODES', resolve => (data : Array<IAgreementCode>) => resolve(data ? data : []));
export const dataSetSalesPoints = createAction('SET_SALES_POINTS', resolve => (data : Array<ISalesPoint>) => resolve(data ? data : []));
export const dataSetFtpDetails = createAction('SET_FTP_DETAILS', resolve => (data : Array<IFtpDetail>) => resolve(data ? data : []));
