import { ICompliance } from '../../@types/model/compliance/compliance';
import { COMPLIANCE_CSV_HEADINGS, DATE_FORMAT_CSV, TIME_FORMAT_CSV, DATE_FORMAT_TIMESTAMP_FROM_API, DATEPICKER_FORMAT_DEFAULT, DATE_FORMAT_DEFAULT } from '../../appConstants';
import ReactDOM from 'react-dom';
import { IDocumentProps } from '../../@types/other';
import { generalShowErrorSnackbar, generalShowInfoSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import { IStockLine } from '../../@types/model/stock/stockLine';
import { getState } from '../../store/Index';
import { addArrayElement, formatDateTimeToDateOnly, formatMomentToDatePicker, getCertificateStockLineSummary, getMaterialStock, getStock, uppercase, zeroFill } from '../appFunctionsService';
import { PDFDownloadLink, Document, Page, View, Image, Text, StyleSheet, Font } from '@react-pdf/renderer';
import moment from 'moment';
import React from 'react';
import Code39 from '../../fonts/code39.ttf';
import { ICommodityConditionRel } from '../../@types/model/masterData/commodityConditionRel/commodityConditionRel';
import lodash, { orderBy, uniq } from 'lodash';
import { dataSetCompliance } from '../../store/data/Functions';
import ComplianceHttpService from '../http/compliance/complianceHttpService';
import { IConsignmentCSVJson } from '../../@types/model/compliance/titan-TUR/consignmentCSVJson';
import { IConsignmentResponse } from '../../@types/model/compliance/titan-TUR/consignmentResponse';
import { IConsignmentStorage } from '../../@types/model/compliance/titan-TUR/consignmentStorage';
import ConsignmentHttpService from '../http/consignment/consignmentHttpService';
import { getConsignmentResult, upsertELot, validateConsignment, uploadConsignment, getAgreementCode } from '../inspectionService';
import PapaParse from 'papaparse';
import { IConsignment } from '../../@types/model/compliance/titan-TUR/consignment';
import { ITrackingUnit } from '../../@types/model/compliance/titan-TUR/trackingUnit';
import { IELot } from '../../@types/model/compliance/titan-TUR/eLot';
import { v1 } from 'uuid';
import { IComplianceLine } from '../../@types/model/compliance/complianceLine';
import { IDispatchInstruction } from '../../@types/model/dispatch/dispatchInstruction';
import FileSaver from 'file-saver';
import { IMaterialDispatch } from '../../@types/model/materialDispatch/materialDispatch';
import { IStock } from '../../@types/model/stock/stock';
import { IStockLineCertificateSummary } from '../../@types/model/stock/stockLineCertificateSummary';

export const MAX_COMMODITY_CONDITION_COLUMNS = 14;

const styles = StyleSheet.create({
    section: {
        margin: 10,
        padding: 10,
        flex: 1,
    },
    page: {
        paddingTop: 25,
        paddingBottom: 65,
        marginBottom: 65,
    },
    pageNumber: {
        fontSize: 11,
        position: 'absolute',
        bottom: 35,
        right: 25,
    },
    pageMargin: {
        marginLeft: 25,
        marginRight: 25,
        flex: 1,
    },
    tableRow: {
        borderBottom: '1pt solid black',
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: 8,
        flexDirection: 'row',
    },
    findingSheetColumn: {
        fontSize: 6,
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: 2,
        paddingTop: 2,
        borderRight: '1pt solid black',
    },
    fdc: { flexDirection: 'column' },
    fdr: { flexDirection: 'row' },
    verticalText: {
        transform: 'rotate(270deg)',
        transformOrigin: 'center center',
        width: 80,
    },
    barcode: {
        fontFamily: 'code39',
        fontSize: 20,
        transform: 'scaleY(2)',
        top: 10,
    },
    barcodeLabel: {
        fontSize: 9,
        letterSpacing: 2.5,
        top: 20,
    },
    fs4: { fontSize: 4 },
    fs5: { fontSize: 5 },
    fs6: { fontSize: 6 },
    fs8: { fontSize: 8 },
    fs10: { fontSize: 10 },
    fs11: { fontSize: 11 },
    fs12: { fontSize: 12 },
    fs13: { fontSize: 13 },
    fs14: { fontSize: 14 },
    fs15: { fontSize: 15 },
    bold: { fontWeight: 'bold' },
    pt2: { paddingTop: 2 },
    pt5: { paddingTop: 5 },
    pt10: { paddingTop: 10 },
    pt35: { paddingTop: 35 },
    pt50: { paddingTop: 50 },
    pb2: { paddingBottom: 2 },
    pb5: { paddingBottom: 5 },
    pb10: { paddingBottom: 10 },
    pb35: { paddingBottom: 35 },
    pl2: { paddingLeft: 2 },
    pl4: { paddingLeft: 4 },
    pl5: { paddingLeft: 5 },
    pl20: { paddingLeft: 20 },
    pl40: { paddingLeft: 40 },
    pr2: { paddingRight: 2 },
    pr20: { paddingRight: 20 },
    pr80: { paddingRight: 80 },
    pr100: { paddingRight: 100 },
    mt5: { marginTop: 5 },
    mt10: { marginTop: 10 },
    mt15: { marginTop: 15 },
    mt20: { marginTop: 20 },
    mt35: { marginTop: 35 },
    mb5: { marginBottom: 5 },
    mb10: { marginBottom: 10 },
    mb30: { marginBottom: 30 },
    mb35: { marginBottom: 35 },
    mb40: { marginBottom: 40 },
    mb50: { marginBottom: 50 },
    flx1: { flex: 1 },
    flx2: { flex: 2 },
    flx3: { flex: 3 },
    flx4: { flex: 4 },
    flx5: { flex: 5 },
    bcg: { backgroundColor: '#bcbcbc' },
    blw1: { borderLeft: '1pt solid black' },
    brw1: { borderRight: '1pt solid black' },
    btw1: { borderTop: '1pt solid black' },
    bbw1: { borderBottom: '1pt solid black' },
    jcc: { justifyContent: 'center' },
    ail: { alignItems: 'left' },
    aic: { alignItems: 'center' },
    air: { alignItems: 'right' },
    aife: { alignItems: 'flex-end' },
    tac: { textAlign: 'center' },
    tar: { textAlign: 'right' },
    posa: { position: 'absolute' },
    posr: { position: 'relative' },
    w8: { width: 8 },
    w30: { width: 30 },
    w50: { width: 50 },
    w100: { width: 100 },
    w140: { width: 140 },
    w174: { width: 174 },
    w175: { width: 175 },
    w180: { width: 180 },
    w190: { width: 190 },
    w200: { width: 200 },
    w280: { width: 280 },
    h8: { height: 8 },
    h40: { height: 40 },
    h80: { height: 80 },
    h90: { height: 90 },
    t2: { top: 2 },
    t5: { top: 5 },
    certCol1: {
        left: 0,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 1,
        position: 'absolute',
    },
    certCol2: {
        left: 35,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 2,
        position: 'absolute',
    },
    certCol3: {
        left: 70,
        width: 30,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 3,
        position: 'absolute',
    },
    certCol4: {
        left: 100,
        width: 25,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 4,
        position: 'absolute',
    },
    certCol5: {
        left: 125,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 5,
        position: 'absolute',
    },
    certCol6: {
        left: 160,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 6,
        position: 'absolute',
    },
    certCol7: {
        left: 195,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 7,
        position: 'absolute',
    },
    certCol8: {
        left: 230,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 8,
        position: 'absolute',
    },
    certCol9: {
        left: 265,
        width: 25,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 9,
        position: 'absolute',
    },
    certCol10: {
        left: 290,
        width: 30,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 10,
        position: 'absolute',
    },
    certCol11: {
        left: 320,
        width: 70,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 11,
        position: 'absolute',
    },
    certCol12: {
        left: 390,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 12,
        position: 'absolute',
    },
    certCol13: {
        left: 425,
        width: 85,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 13,
        position: 'absolute',
    },
    certCol14: {
        left: 510,
        width: 35,
        top: 5,
        paddingLeft: 3,
        backgroundColor: 'white',
        zIndex: 14,
        position: 'absolute',
    },
    onionCertCols: {
        certCol1: {
            left: 0,
            width: 30,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 1,
            position: 'absolute',
        },
        certCol2: {
            left: 30,
            width: 30,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 2,
            position: 'absolute',
        },
        certCol3: {
            left: 60,
            width: 25,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 3,
            position: 'absolute',
        },
        certCol4: {
            left: 85,
            width: 30,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 4,
            position: 'absolute',
        },
        certCol5: {
            left: 115,
            width: 30,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 5,
            position: 'absolute',
        },
        certCol6: {
            left: 145,
            width: 35,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 6,
            position: 'absolute',
        },
        certCol7: {
            left: 180,
            width: 35,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 7,
            position: 'absolute',
        },
        certCol8: {
            left: 215,
            width: 25,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 8,
            position: 'absolute',
        },
        certCol9: {
            left: 240,
            width: 30,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 9,
            position: 'absolute',
        },
        certCol10: {
            left: 270,
            width: 70,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 10,
            position: 'absolute',
        },
        certCol11: {
            left: 345,
            width: 45,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 11,
            position: 'absolute',
        },
        certCol12: {
            left: 390,
            width: 35,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 12,
            position: 'absolute',
        },
        certCol13: {
            left: 425,
            width: 85,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 13,
            position: 'absolute',
        },
        certCol14: {
            left: 510,
            width: 35,
            top: 5,
            paddingLeft: 3,
            backgroundColor: 'white',
            zIndex: 14,
            position: 'absolute',
        },
    },
});

Font.register({
    family: 'code39',
    src: Code39,
});

const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
};

// #region getters
const getCommodityCode = (id ?: number) => {
    const state = getState();
    return state.masterData.commodities.find(x => x.id === id)?.code ?? '';
};

const getCommodityName = (id ?: number) => {
    const state = getState();
    return state.masterData.commodities.find(x => x.id === id)?.name ?? '';
};

const getVarietyCode = (id ?: number) => {
    const state = getState();
    return state.masterData.varieties.find(x => x.id === id)?.code ?? '';
};

const getPackCode = (id ?: number) => {
    const state = getState();
    return state.masterData.packs.find(x => x.id === id)?.code ?? '';
};

const getPackCategoryCode = (id ?: number) => {
    const state = getState();
    const pack = state.masterData.packs.find(x => x.id === id);
    const packCategory = state.masterData.packCategories.find(x => x.id === pack?.categoryId);
    return packCategory ? packCategory.code : '';
};

const getSizeCode = (id ?: number) => {
    const state = getState();
    return state.masterData.sizes.find(x => x.id === id)?.code ?? '';
};

const getColourCode = (id ?: number) => {
    const state = getState();
    return state.masterData.colours.find(x => x.id === id)?.code ?? '';
};

const getCommodityExportCode = (id : number) => {
    const state = getState();
    return state.masterData.commodities.find(x => x.id === id)?.exportCode ?? '';
};

const getGradeCode = (id ?: number) => {
    const state = getState();
    return state.masterData.grades.find(x => x.id === id)?.code ?? '';
};

const getFarmCode = (id ?: number) => {
    const state = getState();
    return state.masterData.farms.find(x => x.id === id)?.code ?? '';
};

const getRegionCode = (id ?: number) => {
    const state = getState();
    return state.masterData.regions.find(x => x.id === id)?.code ?? '';
};

const getCountryCode = (id ?: number) => {
    const state = getState();
    return state.masterData.countries.find(x => x.id === id)?.code ?? '';
};

const getOrchardComplianceCode = (id ?: number) => {
    const state = getState();
    return state.masterData.orchards.find(x => x.id === id)?.complianceCode ?? '';
};

const getSiteBookingRefNr = (siteId ?: number) => {
    const state = getState();
    const inspectionPointId = state.masterData.sites?.find(x => x.id === siteId)?.inspectionPointId;
    return state.masterData.inspectionPoints.find(x => x.id === inspectionPointId)?.bookingRefNr ?? '';
};

const getOrganizationExporter = (id : number) => {
    const state = getState();
    return state.masterData.organizations.find(x => x.id === id)?.exporter ?? '';
};

const getOrganizationBillingParty = (id : number) => {
    const state = getState();
    return state.masterData.organizations.find(x => x.id === id)?.billingParty ?? '';
};

const getSiteCode = (id : number) => {
    const state = getState();
    return state.masterData.sites.find(x => x.id === id)?.code ?? '';
};

const getSiteInspectionPointCode = (siteId : number) => {
    const state = getState();
    const inspectionPointId = state.masterData.sites.find(x => x.id === siteId)?.inspectionPointId;
    return state.masterData.inspectionPoints.find(x => x.id === inspectionPointId)?.code ?? '';
};

export const get202FileName = (compliance ?: ICompliance) => {
    if (!compliance) return;

    const state = getState();
    const date = moment(compliance.createdOn, DATE_FORMAT_TIMESTAMP_FROM_API).local().format(DATE_FORMAT_CSV);
    const compliances = state.data.compliances;
    const index = orderBy(compliances.filter(x => (x.createdOn && formatDateTimeToDateOnly(x.createdOn)) === (compliance.createdOn && formatDateTimeToDateOnly(compliance.createdOn))), x => x.createdOn).findIndex(x => x.id === compliance.id);
    const inspPointCode = compliance.siteId && getSiteInspectionPointCode(compliance.siteId);
    let indexString = index.toString();
    while (indexString.length < 3) {
        indexString = '0' + indexString;
    }
    return `202_${inspPointCode}_${date}_${indexString}.csv`;
};

const getComplianceFarmCodes = (compliance : ICompliance) => {
    const state = getState();
    const farmIds : Array<number> = [];
    const stockIds = compliance.complianceLines.filter(x => x.isActive).map(x => x.stockId);

    const stocks = state.data.stocks.filter(x => x.isActive && stockIds.some(y => y === x.id));

    stocks.forEach(x => x.stockLines.forEach((y) => {
        if (!farmIds.some(z => z === y.farmId)) {
            farmIds.push(y.farmId);
        }
    }));

    return uniq(state.masterData.farms.filter(x => farmIds.some(y => y === x.id)).map(x => x.code)).toString().replace(/,/g, ', ');
};

const getComplianceFarmGGN = (compliance : ICompliance) => {
    const state = getState();
    const farmIds : Array<number> = [];
    const stockIds = compliance.complianceLines.filter(x => x.isActive).map(x => x.stockId);

    const stocks = state.data.stocks.filter(x => x.isActive && stockIds.some(y => y === x.id));

    stocks.forEach(x => x.stockLines.forEach((y) => {
        if (!farmIds.some(z => z === y.farmId)) {
            farmIds.push(y.farmId);
        }
    }));

    return uniq(state.masterData.farms.filter(x => farmIds.some(y => y === x.id)).map(x => x.globalGapNumber)).toString().replace(/,/g, ', ');
};

const getOrganizationName = (id : number) => {
    const state = getState();
    return state.masterData.organizations.find(x => x.id === id)?.name;
};

const getComplianceGrossWeight = (compliance : ICompliance) => {
    const state = getState();
    let weight = 0;
    compliance.complianceLines.filter(x => x.isActive).forEach((x) => {
        const stock = state.data.stocks.find(y => y.id === x.stockId && y.isActive);
        if (stock) {
            weight += stock.grossWeight;
        }
    });
    return weight;
};

const getComplianceCartonCount = (compliance : ICompliance) => {
    const state = getState();
    let cartons = 0;
    compliance.complianceLines.filter(x => x.isActive).forEach((x) => {
        const stock = state.data.stocks.find(y => y.id === x.stockId && y.isActive);
        if (stock) {
            cartons += stock.cartons;
        }
    });
    return cartons;
};

const getSiteShortDescription = (id : number) => {
    const state = getState();
    return state.masterData.sites.find(x => x.id === id)?.shortDescription;
};

const getOrchardName = (id ?: number) => {
    const state = getState();
    return state.masterData.orchards.find(x => x.id === id)?.name;
};

const getMarketCode = (id : number) => {
    const state = getState();
    return state.masterData.markets.find(x => x.id === id)?.code;
};

const getOrganizationCode = (id : number) => {
    const state = getState();
    return state.masterData.organizations.find(x => x.id === id)?.code;
};

const getInventoryCode = (id : number) => {
    const state = getState();
    return state.masterData.inventories.find(x => x.id === id)?.code;
};

// #endregion

const formatPackDate = (date : number | string) => {
    const datestr = date.toString();
    return datestr.substr(0, 4) + '-' + datestr.substr(4, 2) + '-' + datestr.substr(6, 2);
};

export const complianceCertificatePage = (compliance : ICompliance, rejected : boolean, selectedExporter : 'PPECB' | 'AMTA' | unknown) => {
    const firstActiveComplianceLine = compliance.complianceLines.filter(x => x.isActive)[0];
    const inspectedOn = firstActiveComplianceLine.inspectedOn;

    const state = getState();

    const complianceStock = state.data?.stocks.find(x => x.id === firstActiveComplianceLine.stockId);
    const firstActiveStockLine = complianceStock?.stockLines.filter(x => x.isActive)[0];
    let firstActiveStockLineCommodityCode = '';

    if (firstActiveStockLine) {
        firstActiveStockLineCommodityCode = getCommodityCode(firstActiveStockLine.commodityId);
    }

    return <Page size='A4' style={styles.page}>
        <View style={styles.pageMargin}>
            <View style={[styles.jcc, styles.aic, styles.fs15]}>
                <Text>{`${selectedExporter} Inspection Certificate`}</Text>
            </View>
            <View style={[styles.jcc, styles.aic, styles.bbw1, styles.pt5, styles.pb5, styles.mb5]}>
                <Image style={{ height: 40 }} source={`${ASSET_BASE}/assets/images/zz2-logo.jpg`} />
            </View>
            <View style={styles.fdr}>
                <View style={[styles.fdc, styles.fs12]}>
                    <Text>From: Bertie van Zyl (Edms) Bpk</Text>
                    <Text style={styles.pl40}>Posbus 19</Text>
                    <Text style={styles.pl40}>Mooketsi 0825</Text>
                    <Text>Tel   : 015 395 2040</Text>
                    <Text>Fax  : 015 395 2052</Text>
                </View>
                <View style={styles.flx1} />
                <View style={[styles.fdc, styles.pr100, styles.fs12]}>
                    <Text>{`FOR: ${getOrganizationName(compliance.organizationId)}`}</Text>
                </View>
            </View>
            <View style={[styles.bbw1, styles.pt2, styles.pb5, styles.mb5, styles.fs12, styles.pr80, styles.aife]}>
                <Text>{`Inspection Date: ${inspectedOn ? formatDateTimeToDateOnly(inspectedOn) : ''}`}</Text>
            </View>
            <View style={[styles.fdr, styles.fs10, styles.pt2, styles.pb2]}>
                <View>
                    <Text>Inspector:</Text>
                </View>
                <View style={[styles.t2, styles.posa, { left: 130 }]}>
                    <Text>{compliance.inspectorId}</Text>
                </View>
                <View style={[styles.t2, styles.posa, { left: 240 }]}>
                    <Text>Intake Waybill:</Text>
                </View>
                <View style={[styles.t2, styles.posa, { left: 340 }]}>
                    <Text>{compliance.waybill}</Text>
                </View>
            </View>
            <View style={[styles.fdr, styles.fs10, styles.pt2, styles.pb2]}>
                <View>
                    <Text>Inspection Point:</Text>
                </View>
                <View style={[styles.t2, styles.posa, { left: 130 }]}>
                    <Text>{compliance.siteId ? getSiteInspectionPointCode(compliance.siteId) : ''}</Text>
                </View>
                <View style={[styles.t2, styles.posa, { left: 240 }]}>
                    <Text>Packhouse Code:</Text>
                </View>
                <View style={[styles.t2, styles.posa, { left: 340 }]}>
                    <Text>{compliance.siteId ? getSiteCode(compliance.siteId) : ''}</Text>
                </View>
            </View>
            <View style={[styles.fdr, styles.fs10, styles.pt2, styles.pb2]}>
                <View>
                    <Text>PUC(s):</Text>
                </View>
                <View style={[styles.t2, styles.posa, { left: 50 }]}>
                    <Text>{getComplianceFarmCodes(compliance)}</Text>
                </View>
                <View style={[styles.t2, styles.posa, { left: 240 }]}>
                    <Text>UPN:</Text>
                </View>
                <View style={[styles.t2, styles.posa, { left: 340 }]}>
                    <Text>{compliance.upn}</Text>
                </View>
            </View>
            <View style={[styles.fdr, styles.fs10, styles.pt2, styles.pb5, styles.bbw1]}>
                <View>
                    <Text>Global Gap Number(s):</Text>
                </View>
                <View style={[styles.t2, styles.posa, { left: 130 }]}>
                    <Text>{getComplianceFarmGGN(compliance)}</Text>
                </View>
            </View>
            {firstActiveStockLineCommodityCode !== 'ON' // Temporarily removed variety column and added gross weight for Onions Issue No: 772
                ?
                <View style={styles.tableRow}>
                    <Text style={styles.certCol1}>Comm</Text>
                    <Text style={styles.certCol2}>Variety</Text>
                    <Text style={styles.certCol3}>Pack</Text>
                    <Text style={styles.certCol4}>Grd</Text>
                    <Text style={styles.certCol5}>Cnt</Text>
                    <Text style={styles.certCol6}>Inven</Text>
                    <Text style={styles.certCol7}>Mark</Text>
                    <Text style={styles.certCol8}>Farm</Text>
                    <Text style={styles.certCol9}>TM</Text>
                    <Text style={styles.certCol10}>Rgn</Text>
                    <Text style={styles.certCol11}>Orchard</Text>
                    <Text style={styles.certCol12}>Crtn</Text>
                    <Text style={styles.certCol13}>Barcode</Text>
                    <Text style={styles.certCol14}>Accept</Text>
                </View>
                :
                <View style={styles.tableRow}>
                    <Text style={styles.onionCertCols.certCol1}>Comm</Text>
                    <Text style={styles.onionCertCols.certCol2}>Pack</Text>
                    <Text style={styles.onionCertCols.certCol3}>Grd</Text>
                    <Text style={styles.onionCertCols.certCol4}>Cnt</Text>
                    <Text style={styles.onionCertCols.certCol5}>Inven</Text>
                    <Text style={styles.onionCertCols.certCol6}>Mark</Text>
                    <Text style={styles.onionCertCols.certCol7}>Farm</Text>
                    <Text style={styles.onionCertCols.certCol8}>TM</Text>
                    <Text style={styles.onionCertCols.certCol9}>Rgn</Text>
                    <Text style={styles.onionCertCols.certCol10}>Orchard</Text>
                    <Text style={styles.onionCertCols.certCol11}>Gross</Text>
                    <Text style={styles.onionCertCols.certCol12}>Crtn</Text>
                    <Text style={styles.onionCertCols.certCol13}>Barcode</Text>
                    <Text style={styles.onionCertCols.certCol14}>Accept</Text>
                </View>
            }
            {firstActiveStockLineCommodityCode !== 'ON'
                ? compliance.complianceLines.filter(x => x.isActive).filter(x => x.isRejected === rejected).map(x => getTableRow(x))
                : compliance.complianceLines.filter(x => x.isActive).filter(x => x.isRejected === rejected).map(x => getTableRowOnionSpecific(x))
            }
            <View style={styles.fdr}>
                <Text style={[styles.fs11, styles.pt5, styles.posa, { left: 310 }]}>
                        Total Cartons:    {getComplianceCartonCount(compliance)}
                </Text>
            </View>
            { getCommodityCode(compliance.commodityId) === 'ON' ?
                <View style={styles.fdr}>
                    <Text style={[styles.fs11, styles.pt5, styles.posa, { top: 15, left: 310 }]}>
                            Total Gross Weight:    {getComplianceGrossWeight(compliance)}
                    </Text>
                </View>
                : <View/>
            }
            <View style={styles.flx1} />
            <View style={[styles.fdc, styles.mt5]}>
                <Text style={styles.fs11}> {`Approved by Inspector:   ${compliance.inspectorId}`} </Text>
                <View style={[styles.fdr, styles.mt35]}>
                    <Text style={styles.fs11}> Signature: </Text>
                    <View style={styles.flx1} />
                </View>
            </View>
        </View>
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `Page ${pageNumber} of ${totalPages}`
        )} fixed> </Text>
    </Page>;
};

const getTableRow = (row : IComplianceLine) => {
    const state = getState();
    const stock = state.data?.stocks.find(x => x.id === row.stockId);
    const stockLineSummary = getCertificateStockLineSummary(stock && stock.isActive ? stock.stockLines.filter(x => x.isActive) : []);
    return <View style={styles.fdc} key={'pdfcert_comp_line' + row.id}>
        {stock && stockLineSummary.map((line, index) => <View wrap={false} key={'pdfcert_comp_line' + row.id + '_' + stock.id + '_' + index} style={styles.tableRow}>
            <Text style={styles.certCol1}>{getCommodityCode(line.commodityId)}</Text>
            <Text style={styles.certCol2}>{getVarietyCode(line.varietyId)}</Text>
            <Text style={styles.certCol3}>{getPackCode(line.packId)}</Text>
            <Text style={styles.certCol4}>{getGradeCode(line.gradeId)}</Text>
            <Text style={styles.certCol5}>{getSizeCode(line.sizeId)}</Text>
            <Text style={styles.certCol6}>{getInventoryCode(stock.inventoryId)}</Text>
            <Text style={styles.certCol7}>{getOrganizationCode(stock.currentOrganizationId)}</Text>
            <Text style={styles.certCol8}>{getSiteShortDescription(stock.currentSiteId)}</Text>
            <Text style={styles.certCol9}>{getMarketCode(stock.marketId)}</Text>
            <Text style={styles.certCol10}>{getRegionCode(stock.regionId)}</Text>
            <Text style={styles.certCol11}>{line.orchardId ? `${getOrchardComplianceCode(line.orchardId)} - ${getOrchardName(line.orchardId)}`.substr(0, 14) : ''}</Text>
            <Text style={styles.certCol12}>{line.cartons}</Text>
            <Text style={styles.certCol13}>{stock.barcode}</Text>
            <Text style={styles.certCol14}>{row.isRejected ? 'N' : 'Y'}</Text>
        </View>)}
    </View>;
};

const getTableRowOnionSpecific = (row : IComplianceLine) => { // Temporarily removed variety column and added gross weight for Onions Issue No: 772
    const state = getState();
    const stock = state.data?.stocks.find(x => x.id === row.stockId);
    const stockLineSummary = getCertificateStockLineSummary(stock && stock.isActive ? stock.stockLines.filter(x => x.isActive) : []);
    return <View style={styles.fdc} key={'pdfcert_comp_line' + row.id}>
        {stock && stockLineSummary.map((line, index) => <View wrap={false} key={'pdfcert_comp_line' + row.id + '_' + stock.id + '_' + index} style={styles.tableRow}>
            <Text style={styles.onionCertCols.certCol1}>{getCommodityCode(line.commodityId)}</Text>
            <Text style={styles.onionCertCols.certCol2}>{getPackCode(line.packId)}</Text>
            <Text style={styles.onionCertCols.certCol3}>{getGradeCode(line.gradeId)}</Text>
            <Text style={styles.onionCertCols.certCol4}>{getSizeCode(line.sizeId)}</Text>
            <Text style={styles.onionCertCols.certCol5}>{getInventoryCode(stock.inventoryId)}</Text>
            <Text style={styles.onionCertCols.certCol6}>{getOrganizationCode(stock.currentOrganizationId)}</Text>
            <Text style={styles.onionCertCols.certCol7}>{getSiteShortDescription(stock.currentSiteId)}</Text>
            <Text style={styles.onionCertCols.certCol8}>{getMarketCode(stock.marketId)}</Text>
            <Text style={styles.onionCertCols.certCol9}>{getRegionCode(stock.regionId)}</Text>
            <Text style={styles.onionCertCols.certCol10}>{line.orchardId ? `${getOrchardComplianceCode(line.orchardId)} - ${getOrchardName(line.orchardId)}`.substr(0, 14) : ''}</Text>
            <Text style={styles.onionCertCols.certCol11}>{getTableRowGrossWeight(stock, line)}</Text>
            <Text style={styles.onionCertCols.certCol12}>{line.cartons}</Text>
            <Text style={styles.onionCertCols.certCol13}>{stock.barcode}</Text>
            <Text style={styles.onionCertCols.certCol14}>{row.isRejected ? 'N' : 'Y'}</Text>
        </View>)}
    </View>;
};

const getTableRowGrossWeight = (stock : IStock, line : IStockLineCertificateSummary) => {
    return (stock.grossWeight / stock.cartons) * line.cartons;
};

const trimSSCC = (sscc : string) => {
    if (sscc[0] === '\'') {
        return sscc.substr(1, 18);
    }
    return sscc;
};

export const onComplianceExport = async (exportedCompliance ?: ICompliance) => {
    if (exportedCompliance) {
        const oldCompliance = { ...exportedCompliance };
        const newCompliance = { ...exportedCompliance };
        newCompliance.status = 'Inspecting';
        dataSetCompliance(newCompliance);
        newCompliance.updatedOn = undefined;
        try {
            const res = await ComplianceHttpService.addOrUpdateCompliance(newCompliance);

            if (res && res.data) {
                dataSetCompliance(res.data);
                generalShowSuccessSnackbar('Compliance successfully exported.');
            } else {
                generalShowErrorSnackbar('An error occurred exporting the compliance.');
                dataSetCompliance(oldCompliance);
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred exporting the compliance.');
            dataSetCompliance(oldCompliance);
        }
    }
};

const csvObjToString = (data : Array<IConsignmentCSVJson>) => {
    let string = '';
    const firstObject = data[0];
    if (!firstObject) {
        return '';
    }
    const keys = lodash.keys(firstObject);
    string += keys.toString() + '\n';

    data.forEach((x) => {
        keys.forEach((y, i) => string += (x[y] ? x[y] : '') + ((i === keys.length - 1) ? '' : ','));
        string += '\n';
    });
    return string;
};

const renderConditions = (renderfunction : (condition : ICommodityConditionRel, index : number) => JSX.Element, renderSpacer : (value : number, index : number) => JSX.Element, commodityId ?: number, commodityConditions ?: Array<ICommodityConditionRel>) => {
    /* ensures that only MAX_COMMODITY_CONDITION_COLUMNS rows get rendered */
    const state = getState();
    const commodity =  state.masterData.commodities.find(x => x.id === commodityId);
    const conditions = (commodityId ? orderBy(commodity?.conditions, x => x.order) : commodityConditions) ?? [];
    if (getConditionTotalRows(conditions) <= MAX_COMMODITY_CONDITION_COLUMNS) {
        const conditionRows = [...conditions].map(renderfunction);
        const spacers = [...Array.from(Array(getNeededSpacers([...conditions])).keys())].map(renderSpacer);
        return [...conditionRows, ...spacers];
    }

    let total = 0;
    const newConditions = [...conditions.filter((x) => {
        total += getConditionRows(x);
        return total > MAX_COMMODITY_CONDITION_COLUMNS;
    })];
    const newConditionRows = [...newConditions].map(renderfunction);
    const newSpacers = [...Array.from(Array(getNeededSpacers([...newConditions])).keys())].map(renderSpacer);
    return [...newConditionRows, ...newSpacers];
};

const renderCommodityCondition = (condition : ICommodityConditionRel, index : number) => {
    const state = getState();
    const value = condition.isSpacer ? '' : state.masterData.commodityConditions.find(x => x.id === condition.conditionId)?.condition;
    const rows = value?.split(/\r\n|\r|\n/)?.length ?? 1;
    return <View key={'condition_title_' + index} style={[styles.findingSheetColumn, styles.aic, styles.jcc, { width: 15 * rows }]}><Text style={styles.verticalText}>{value}</Text></View>;
};

const getConditionRows = (condition : ICommodityConditionRel) => {
    const state = getState();
    const value = condition.isSpacer ? '' : state.masterData.commodityConditions.find(x => x.id === condition.conditionId)?.condition;
    return value?.split(/\r\n|\r|\n/)?.length ?? 1;
};

const getConditionTotalRows = (conditions : Array<ICommodityConditionRel>) => {
    const newConditions = [...conditions];
    let total = 0;
    newConditions?.forEach(x => total += getConditionRows(x));
    return total;
};

export const createAndDownloadPDF = (pdfContent : () => React.ReactElement<IDocumentProps>, filename : string, divId : string, callback : () => void) => {
    setTimeout(
        () => {
            const link = (
                <div id={ divId }>
                    <PDFDownloadLink document={ pdfContent() } fileName={ filename }>{
                        ({ blob, loading }) => {
                            if (!loading) {
                                setTimeout(
                                    () => {
                                        if (blob) {
                                            FileSaver.saveAs(blob, filename);
                                        } else {
                                            generalShowErrorSnackbar('Could not download file.');
                                        }
                                    },
                                    1);
                            }
                        }}</PDFDownloadLink>
                </div>
            );
            const elem = document.createElement('div');
            const doc = document.getElementById('root');
            if (doc) {
                doc.appendChild(elem);
                ReactDOM.render(link, elem);
                setTimeout(
                    () => {
                        elem.remove();
                        callback();
                    },
                    1);
            }
        },
        1);
};

const getNeededSpacers = (conditions : Array<ICommodityConditionRel>) => MAX_COMMODITY_CONDITION_COLUMNS - getConditionTotalRows(conditions);

const getComplianceFarms = (compliance : ICompliance) => {
    const state = getState();
    const stockIds = compliance.complianceLines.filter(x => x.isActive).map(x => x.stockId);
    const farmIds : Array<number> = [];
    state.data.stocks.filter(x => x.isActive && stockIds.some(y => y === x.id)).forEach((x) => {
        x.stockLines.filter(y => y.isActive).forEach((y) => {
            if (!farmIds.some(z => z === y.farmId)) {
                farmIds.push(y.farmId);
            }
        });
    });
    return state.masterData.farms.filter(x => farmIds.some(y => x.id === y)).map(x => x.code).toString().replace(/,/g, ',\n');
};

const renderFindingSheetTopCell = (value : ICommodityConditionRel, index : number) => <Text key={'top_cell' + index} style={[styles.findingSheetColumn, styles.aic, { width: 15 * getConditionRows(value) }]}></Text>;
const renderFindingSheetTopCellSpacer = (value : number, index : number) => <Text key={'top_cell_spacer' + index} style={[styles.findingSheetColumn, styles.aic, { width: 15 }]}></Text>;
const renderFindingSheetAvoLowerCell = (value : ICommodityConditionRel, index : number) => <Text key={'lower_cell' + index} style={[styles.findingSheetColumn, styles.aic, { width: 15 * getConditionRows(value) }, { borderRight: `${index % 2 ? '1pt solid black' : ''}` }]}></Text>;
const renderFindingSheetStoneLowerCell = (value : ICommodityConditionRel, index : number) => <Text key={'lower_cell' + index} style={[styles.findingSheetColumn, styles.aic, { width: 15 * getConditionRows(value) }, { borderRight: `${index % 2 ? '1pt solid black' : ''}` }]}></Text>;
const renderFindingSheetLowerCell = (value : ICommodityConditionRel, index : number) => <Text key={'lower_cell' + index} style={[styles.findingSheetColumn, styles.aic, { width: 15 * getConditionRows(value) }, { borderRight: '1pt solid black' }]}></Text>;
const renderFindingSheetLowerCellSpacer = (value : number, index : number) => <Text key={'lower_cell_spacer' + index} style={[styles.findingSheetColumn, styles.aic, { width: 15 }]}></Text>;
const findingSheet = (compliance : ICompliance, varieties : Array<number>) => {
    const state = getState();
    const commodityExportCode = compliance.commodityId ? getCommodityExportCode(compliance.commodityId) : '';
    switch (commodityExportCode) {
        case 'APS05A': // AVO FINDING SHEET
            return <Document>
                {
                    varieties.map(varietyId => <Page size='A4' style={styles.page}>
                        <View style={styles.pageMargin}>
                            <View style={[styles.fdr, styles.btw1, styles.blw1, styles.brw1, styles.pl2]}>
                                <Image style={{ height: 30 }} source={`${ASSET_BASE}/assets/images/ppecb-logo.jpg`}/>
                                <View style={styles.flx1} />
                                <View style={[styles.fdc, styles.pt2, styles.pr2]}>
                                    <Text style={styles.fs8}>{commodityExportCode}</Text>
                                    <Text style={styles.fs6}>{'Date: 03 November 2022'}</Text>
                                    <Text style={styles.fs6}>{'Revision: 05'}</Text>

                                </View>
                            </View>
                            <View style={[styles.fdc, styles.btw1, styles.blw1, styles.brw1, styles.bbw1]}>
                                <View style={[styles.fdr, styles.pb10]} fixed>
                                    <View style={styles.flx1} />
                                    <Text style={[styles.fs8, styles.pt2, styles.pr2]} fixed render={({ pageNumber, totalPages }) => (
                                        `PAGE ${pageNumber} OF ${totalPages}`
                                    )}> </Text>
                                </View>
                                <View style={[styles.jcc, styles.aic, styles.pb5]}>
                                    <Text style={[styles.fs13, styles.bold]}>PERISHABLE PRODUCTS EXPORT CONTROL BOARD</Text>
                                </View>
                            </View>
                            <View style={[styles.jcc, styles.aic, styles.pt5, styles.pb5, styles.blw1, styles.brw1, styles.bbw1]}>
                                <Text style={styles.fs11}>{`INSPECTION FINDINGS SHEET - ${compliance.commodityId ? uppercase(getCommodityName(compliance.commodityId)) : '' }`}</Text>
                            </View>
                            <View style={[styles.fdr]}>
                                <View style={[styles.fdr, styles.pt5, styles.pb5, styles.blw1, styles.bbw1, styles.flx5]}>
                                    <View style={[styles.fdr, styles.pl2]}>
                                        <Text style={styles.fs6}>
                                                MARK APPLICABLE OPTION WITH A "     "</Text>
                                        <Image style={[styles.pl2, styles.pr2, styles.h8, styles.w8, { left: 107 }, styles.posr]} source={`${ASSET_BASE}/assets/images/checkbrand.png`}/>
                                    </View>
                                </View>
                                <View style={[styles.pl2, styles.fdr, styles.pt5, styles.pb5, styles.blw1, styles.bbw1, styles.flx2, styles.bcg]}>
                                    <Text style={styles.fs6}> *Min Standard: </Text>
                                </View>
                                <View style={[styles.fdr, styles.pt5, styles.pb5, styles.blw1, styles.bbw1, styles.flx1]}>
                                </View>
                                <View style={[styles.pl2, styles.fdr, styles.pt5, styles.pb5, styles.blw1, styles.bbw1, styles.flx2]}>
                                    <Text style={styles.fs6}> </Text>
                                </View>
                                <View style={[styles.fdr, styles.pt5, styles.pb5, styles.blw1, styles.bbw1, styles.flx1]}>
                                </View>
                                <View style={[styles.pl2, styles.fdr, styles.pt5, styles.pb5, styles.blw1, styles.bbw1, styles.flx2, styles.bcg]}>
                                    <Text style={styles.fs6}> *Special Market: </Text>
                                </View>
                                <View style={[styles.fdr, styles.pt5, styles.pb5, styles.blw1, styles.bbw1, styles.flx1]}>
                                </View>
                                <View style={[styles.pl2, styles.fdr, styles.pt5, styles.pb5, styles.blw1, styles.bbw1, styles.flx2, styles.bcg]}>
                                    <Text style={styles.fs6}> *Dispensation: </Text>
                                </View>
                                <View style={[styles.fdr, styles.pt5, styles.pb5, styles.bbw1, styles.blw1, styles.brw1, styles.flx1]}>
                                </View>
                            </View>
                            <View style={[styles.fdr]}>
                                <View style={[styles.fdc, styles.flx2]}>
                                    <View style={[styles.fdc, styles.flx1, styles.blw1, styles.bbw1]}>
                                        <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl2]}>{'INSPECTOR & NO:' }</Text>
                                    </View>
                                    <View style={[styles.fdc, styles.flx2, styles.blw1, styles.bbw1, styles.jcc]}>
                                        <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl2]}>{'SIGNATURE:' }</Text>
                                    </View>
                                </View>
                                <View style={[styles.fdc, styles.flx1, styles.blw1]}>
                                    <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl2, styles.bbw1]}>{`EXPORTER: ${getOrganizationExporter(compliance.organizationId)}`}</Text>
                                    <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl2, styles.bbw1]}>{`INSP POINT:  ${compliance.siteId ? getSiteInspectionPointCode(compliance.siteId) : '' }`}</Text>
                                    <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl2, styles.bbw1]}>{`CULTIVAR: ${varietyId ? getVarietyCode(varietyId) : '' }`}</Text>
                                </View>
                                <View style={[styles.fdc, styles.flx1, styles.blw1, styles.bbw1]}>
                                    <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl2, styles.bbw1]}>{`PHC: ${compliance.siteId ? getSiteCode(compliance.siteId) : '' }`}</Text>
                                    <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl2, styles.bbw1]}>{'DATE:'}</Text>
                                    <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl2]}></Text>
                                </View>
                                <View style={[styles.fdc, styles.flx1, styles.blw1, styles.bbw1, styles.brw1, styles.jcc]}>
                                    <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl2, styles.pr2]}>{`PUC/s:  ${getComplianceFarms(compliance)}`}</Text>
                                </View>
                            </View>
                            <View style={[styles.fdr, styles.blw1, styles.brw1]}>
                                <Text style={[styles.fs10, styles.pb10, styles.pt10, styles.pl2, styles.flx3]}>{`CONSIGNMENT NOTE NUMBERS: ${compliance.waybill}`}</Text>
                                <Text style={[styles.fs10, styles.pb10, styles.pt10, styles.pl2, styles.pr2, styles.flx2, styles.blw1]}>{'VESSEL + ETD:'}</Text>
                            </View>
                            {renderCommodityConditionsAvoHeader(compliance.commodityId)}
                            {compliance.complianceLines.filter((x) => {
                                const stock = getStock(x.stockId);
                                const hasVar = stock?.isActive && stock.stockLines.some(y => y.isActive && y.varietyId === varietyId);
                                return x.isActive && hasVar;
                            }).map((x, stockIndex) => {
                                const stock = getStock(x.stockId);
                                const sizeCodes : Array<string> = [];
                                const gradeCodes : Array<string> = [];
                                const dayOfWeek = zeroFill(moment(stock?.packDate).isoWeekday(), 2);
                                const weekOfYear = zeroFill(moment(stock?.packDate).isoWeek(), 2);
                                const dateCode = `${weekOfYear[0]}${dayOfWeek}${weekOfYear[1]}`.split('').reverse().join('');
                                let regionCode = '';
                                if (stock) {
                                    const region = state.masterData.regions.find(z => z.id === stock.regionId);
                                    regionCode = region ? region.code : '';
                                    stock.stockLines.filter(y => y.isActive).forEach((z) => {
                                        const size = state.masterData.sizes.find(s => s.id === z.sizeId);
                                        if (size && !sizeCodes.some(s => s === size.code)) {
                                            sizeCodes.push(size.code);
                                        }
                                        const grade = state.masterData.grades.find(s => s.id === z.gradeId);
                                        if (grade && !gradeCodes.some(s => s === grade.code)) {
                                            gradeCodes.push(grade.code);
                                        }
                                    });
                                }
                                return <View wrap={false} key={'findingSheet_comp_line' + x.id} style={[styles.fdr, styles.bbw1, styles.h80]}>
                                    <View style={[styles.fdc, styles.pb5, styles.pt5, styles.brw1, styles.blw1, styles.aic, { width: 174 }]}>
                                        <View style={styles.fdr}>{stock ? <Text style={[styles.fs6, styles.flx1, styles.aife]}>{stockIndex + 1}</Text> : <Text />}</View>
                                        {stock ? <Text style={styles.barcode}>*{stock.barcode}*</Text> : <Text />}
                                        {stock ? <Text style={styles.barcodeLabel}>*{stock.barcode}*</Text> : <Text />}
                                    </View>
                                    <View style={[{ width: 30 }, styles.fdc]}>
                                        <Text style={[styles.flx1, styles.findingSheetColumn, styles.jcc, styles.tac, styles.bbw1]}>{sizeCodes.filter((y, i) => i <= 6).map((z, i) => i === 0 ? z : '\n' + z).toString()}</Text>
                                        <Text style={[styles.brw1, styles.flx1]}>{}</Text>
                                    </View>
                                    <Text style={[styles.findingSheetColumn, { width: 20 }, styles.jcc, styles.aic, styles.tac]}>{gradeCodes.filter((y, i) => i <= 6).map((z, i) => i === 0 ? z : '\n' + z).toString() }</Text>
                                    <View style={[styles.brw1, { width: 51 }, styles.fdc, styles.jcc, styles.tac]}>
                                        <Text style={[styles.flx1, styles.bbw1, styles.fs6, styles.jcc, styles.aic, styles.tac]}>{regionCode}</Text>
                                        <Text style={[styles.flx1, styles.fs6, styles.jcc, styles.aic, styles.tac]}>{'Carton mass'}</Text>
                                    </View>
                                    <View style={[styles.fdc]}>
                                        <View style={[styles.fdr, styles.bbw1, styles.h40]}>
                                            {compliance.commodityId && renderConditions(renderFindingSheetTopCell, renderFindingSheetTopCellSpacer, compliance.commodityId)}
                                            <Text style={[styles.findingSheetColumn, styles.jcc, styles.aic, styles.tac, { width: 30 }]}>{dateCode}</Text>
                                            <Text style={[styles.findingSheetColumn, { width: 30 }, styles.bcg]}></Text>
                                        </View>
                                        <View style={[styles.fdr, styles.h40]}>
                                            {compliance.commodityId && renderConditions(renderFindingSheetAvoLowerCell, renderFindingSheetLowerCellSpacer, compliance.commodityId)}
                                            <Text style={[styles.findingSheetColumn, { width: 30 }]}></Text>
                                            <Text style={[styles.findingSheetColumn, { width: 30 }, styles.bcg, styles.bbw1]}></Text>
                                        </View>
                                    </View>
                                </View>;
                            })}
                            <View wrap={false}>
                                <View style={[styles.fdr, styles.bbw1]}>
                                    <Text style={[styles.blw1, styles.brw1, styles.fs8, styles.pl2, styles.pt5, styles.pb5, { width: 174 }]}>Marking Requirements</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Product</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 20 }]}>PUC</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 51 }]}>Class</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Mass Range</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Count / size code</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Origin</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Address</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Cultivar</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Date code</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 60 }]}>Jumble pack when applicable</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }, styles.bcg, styles.bbw1]}></Text>
                                </View>
                                <View style={[styles.fdr, styles.bbw1]}>
                                    <Text style={[styles.blw1, styles.brw1, styles.fs8, styles.pl2, styles.pt5, styles.pb5, { width: 174 }]}>Pallet Requirements</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Clean</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 20 }]}>Fungi</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 51 }]}>Bark</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>{'Undamaged' }</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 60 }]}>Same dimensions on the same layers</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>{'Arthropoda'}</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Containers Square</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Secured Covered</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 60 }]}>ISPM 15 Requirements</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }, styles.bcg]}></Text>
                                </View>
                                <View style={[styles.fdr, styles.bbw1]}>
                                    <Text style={[styles.blw1, styles.brw1, styles.fs8, styles.pl2, styles.pt5, styles.pb5, { width: 174 }]}>Traceability</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>PUC</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 71 }]}>Date Code</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Pallet ID</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Address</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, styles.fdc, { width: 45 }]}>{'Consignment \n Note/s'}</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 135 }]}></Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }, styles.bcg, styles.bbw1]}></Text>
                                </View>
                                <View style={[styles.fdr, styles.bbw1]}>
                                    <Text style={[styles.blw1, styles.brw1, styles.fs8, styles.pl2, styles.pt5, styles.pb5, { width: 174 }]}>Packing Requirements</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Pre-pack</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 71 }]}>Same Cultivar</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Same Size</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Same Quality</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, styles.fdc, { width: 45 }]}>Same Ripeness</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 135 }]}></Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }, styles.bcg, styles.bbw1]}></Text>
                                </View>
                                <View style={[styles.fdr, styles.bbw1]}>
                                    <Text style={[styles.blw1, styles.brw1, styles.fs8, styles.pl2, styles.pt5, styles.pb5, { width: 174 }]}>Passed for Export Stickers:</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 206 }]}>From:</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 165 }]}>To:</Text>
                                </View>
                                <View style={[styles.fdr, styles.bbw1, styles.btw1, styles.mt10]}>
                                    <Text style={[styles.blw1, styles.brw1, styles.fs8, styles.pl2, styles.pt5, styles.pb5, { width: 380 }]}>Rebrands:</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, styles.fdc, { width: 165 }, styles.bcg]}>
                                        {'Dry matter where applicable: \n PUC: \n Orchard Number:'}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </Page>,
                    )}
            </Document>;
        case 'APS05S': // STONE FRUIT FINDING SHEET
            return <Document>
                {
                    varieties.map(varietyId => <Page size='A4' style={styles.page}>
                        <View style={styles.pageMargin}>
                            <View style={[styles.fdr, styles.btw1, styles.blw1, styles.brw1, styles.pl2]}>
                                <View style={styles.flx1} />
                                <Image style={{ height: 30 }} source={`${ASSET_BASE}/assets/images/ppecb-logo.jpg`}/>
                                <View style={styles.flx1} />
                                <View style={[styles.fdc, styles.pt2, styles.pr2]} fixed>
                                    <Text style={styles.fs6}>PPECB FINDINGS</Text>
                                    <Text style={styles.fs6}>{`${commodityExportCode}      Rev : 2`}</Text>
                                    <Text style={[styles.fs6, styles.pt2, styles.pr2]} fixed render={({ pageNumber, totalPages }) => (
                                        `PAGE ${pageNumber} OF ${totalPages}      Date: 13/03/2023`
                                    )}> </Text>

                                </View>
                                <View style={[styles.fdr, styles.pb10]} fixed>
                                    <View style={styles.flx1} />
                                </View>
                            </View>
                            <View style={[styles.jcc, styles.bbw1, styles.blw1, styles.brw1, styles.aic, styles.pb5]}>
                                <Text style={[styles.fs13, styles.bold]}>PERISHABLE PRODUCTS EXPORT CONTROL BOARD</Text>
                            </View>
                            <View style={[styles.jcc, styles.aic, styles.pt5, styles.pb5, styles.blw1, styles.brw1, styles.bbw1]}>
                                <Text style={styles.fs11}>{'INSPECTION FINDINGS SHEET - STONE'}</Text>
                            </View>
                            <View style={[styles.fdr]}>
                                <View style={[styles.fdr, styles.pt5, styles.pb5, styles.blw1, styles.bbw1, styles.flx5]}>
                                    <View style={[styles.fdr, styles.pl2]}>
                                        <Text style={styles.fs6}>
                                                MARK APPLICABLE OPTION WITH A "     "</Text>
                                        <Image style={[styles.pl2, styles.pr2, styles.h8, styles.w8, { left: 107 }, styles.posr]} source={`${ASSET_BASE}/assets/images/checkbrand.png`}/>
                                    </View>
                                </View>
                                <View style={[styles.pl2, styles.fdr, styles.pt5, styles.pb5, styles.blw1, styles.bbw1, styles.flx2]}>
                                    <Text style={styles.fs6}> *Min Standard: </Text>
                                </View>
                                <View style={[styles.fdr, styles.pt5, styles.pb5, styles.blw1, styles.bbw1, styles.flx1]}>
                                </View>
                                <View style={[styles.pl2, styles.fdr, styles.pt5, styles.pb5, styles.blw1, styles.bbw1, styles.flx2]}>
                                    <Text style={styles.fs6}> </Text>
                                </View>
                                <View style={[styles.fdr, styles.pt5, styles.pb5, styles.blw1, styles.bbw1, styles.flx1]}>
                                </View>
                                <View style={[styles.pl2, styles.fdr, styles.pt5, styles.pb5, styles.blw1, styles.bbw1, styles.flx2]}>
                                    <Text style={styles.fs6}> *Special Market: </Text>
                                </View>
                                <View style={[styles.fdr, styles.pt5, styles.pb5, styles.blw1, styles.bbw1, styles.flx1]}>
                                </View>
                                <View style={[styles.pl2, styles.fdr, styles.pt5, styles.pb5, styles.blw1, styles.bbw1, styles.flx2]}>
                                    <Text style={styles.fs6}> *Dispensation: </Text>
                                </View>
                                <View style={[styles.fdr, styles.pt5, styles.pb5, styles.bbw1, styles.blw1, styles.brw1, styles.flx1]}>
                                </View>
                            </View>
                            <View style={[styles.fdc]}>
                                <View style={[styles.fdr]}>
                                    <View style={[styles.fdc, styles.flx2, styles.blw1, styles.bbw1]}>
                                        <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl2]}>{'INSPECTOR & NO:' }</Text>
                                    </View>
                                    <View style={[styles.fdc, styles.flx3, styles.blw1, styles.bbw1, styles.jcc]}>
                                        <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl2]}>{'SIGNATURE:' }</Text>
                                    </View>
                                    <View style={[styles.fdc, styles.flx1, styles.blw1, styles.bbw1, styles.brw1, styles.jcc]}>
                                        <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl2]}>{'DATE:'}</Text>
                                    </View>
                                </View>
                                <View style={[styles.fdr]}>
                                    <View style={[styles.fdc, styles.flx1, styles.blw1, styles.bbw1]}>
                                        <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl2]}>{`EXPORTER: ${getOrganizationExporter(compliance.organizationId)}`}</Text>
                                    </View>
                                    <View style={[styles.fdc, styles.flx1, styles.blw1, styles.bbw1, styles.jcc]}>
                                        <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl2]}>{'PRODUCT:' }</Text>
                                    </View>
                                    <View style={[styles.fdc, styles.flx1, styles.blw1, styles.bbw1, styles.brw1, styles.jcc]}>
                                        <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl2]}>{`CULTIVAR: ${varietyId ? getVarietyCode(varietyId) : '' }`}</Text>
                                    </View>
                                </View>
                                <View style={[styles.fdr]}>
                                    <View style={[styles.fdc, styles.flx1, styles.blw1, styles.bbw1]}>
                                        <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl2]}>{`INSP POINT:  ${compliance.siteId ? getSiteInspectionPointCode(compliance.siteId) : '' }`}</Text>
                                    </View>
                                    <View style={[styles.fdc, styles.flx1, styles.blw1, styles.bbw1, styles.jcc]}>
                                        <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl2]}>{`PHC: ${compliance.siteId ? getSiteCode(compliance.siteId) : '' }`}</Text>
                                    </View>
                                    <View style={[styles.fdc, styles.flx1, styles.blw1, styles.bbw1, styles.brw1, styles.jcc]}>
                                        <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl2]}></Text>
                                    </View>
                                </View>
                            </View>
                            <View style={[styles.fdr, styles.blw1, styles.brw1]}>
                                <Text style={[styles.fs10, styles.pb10, styles.pt10, styles.pl2, styles.flx1]}>{`CONSIGNMENT NOTE/S: ${compliance.waybill}`}</Text>
                            </View>
                            {renderCommodityConditionsStoneHeader(compliance.commodityId)}
                            {compliance.complianceLines.filter((x) => {
                                const stock = getStock(x.stockId);
                                const hasVar = stock?.isActive && stock.stockLines.some(y => y.isActive && y.varietyId === varietyId);
                                return x.isActive && hasVar;
                            }).map((x, stockIndex) => {
                                const stock = getStock(x.stockId);
                                const sizeCodes : Array<string> = [];
                                const gradeCodes : Array<string> = [];
                                const orchardCodes : Array<string> = [];
                                const farmCodes : Array<string> = [];
                                let regionCode = '';
                                if (stock) {
                                    const region = state.masterData.regions.find(z => z.id === stock.regionId);
                                    regionCode = region ? region.code : '';
                                    orderBy(stock.stockLines.filter(y => y.isActive), sl => sl.farmId).forEach((z) => {
                                        const size = state.masterData.sizes.find(s => s.id === z.sizeId);
                                        if (size && !sizeCodes.some(s => s === size.code)) {
                                            sizeCodes.push(size.code);
                                        }
                                        const grade = state.masterData.grades.find(s => s.id === z.gradeId);
                                        if (grade && !gradeCodes.some(s => s === grade.code)) {
                                            gradeCodes.push(grade.code);
                                        }
                                        const orchard = state.masterData.orchards.find(s => (s.id === z.orchardId) && (s.defaultVarietyId === varietyId));
                                        const farm = state.masterData.farms.find(s => (s.id === z.farmId));
                                        if (orchard && !orchardCodes.some(s => s === orchard.complianceCode)) {
                                            if (orchard.complianceCode) {
                                                orchardCodes.push(orchard.complianceCode ?? '');
                                                /* moved farm into orchard block to make farms and orchards line up properly on the sheet */
                                                if (farm && (orchard?.defaultVarietyId === varietyId) && !farmCodes.some(s => s === farm.code)) {
                                                    farmCodes.push(farm.code);
                                                } else if (farm && (orchard?.defaultVarietyId === varietyId) && farmCodes.some(s => s === farm.code)) {
                                                    farmCodes.push(' ');
                                                }
                                            }
                                        }
                                    });
                                }
                                return <View wrap={false} key={'findingSheet_comp_line' + x.id} style={[styles.fdr, styles.bbw1, styles.h80]}>
                                    <View style={[styles.fdc, styles.pb5, styles.pt5, styles.brw1, styles.blw1, styles.aic, { width: 170 }]}>
                                        <View style={styles.fdr}>{stock ? <Text style={[styles.fs6, styles.flx1, styles.aife]}>{stockIndex + 1}</Text> : <Text />}</View>
                                        {stock ? <Text style={styles.barcode}>*{stock.barcode}*</Text> : <Text />}
                                        {stock ? <Text style={styles.barcodeLabel}>*{stock.barcode}*</Text> : <Text />}
                                    </View>
                                    <View style={[{ width: 20 }, styles.fdc]}>
                                        <Text style={[styles.flx1, styles.findingSheetColumn, styles.jcc, styles.tac, styles.bbw1]}>{sizeCodes.filter((y, i) => i <= 6).map((z, i) => i === 0 ? z : '\n' + z).toString().replace(/,/g, '')}</Text>
                                        <Text style={[styles.brw1, styles.flx1]}>{}</Text>
                                    </View>
                                    <Text style={[styles.findingSheetColumn, { width: 20 }, styles.jcc, styles.aic, styles.tac]}>{gradeCodes.filter((y, i) => i <= 6).map((z, i) => i === 0 ? z : '\n' + z).toString().replace(/,/g, '') }</Text>
                                    <Text style={[styles.findingSheetColumn, { width: 20 }, styles.jcc, styles.aic, styles.tac]}>{regionCode}</Text>
                                    <Text style={[styles.findingSheetColumn, { width: 25 }, styles.jcc, styles.aic, styles.tac]}>{farmCodes.filter((y, i) => i <= 6).map((z, i) => i === 0 ? z : '\n' + z).toString().replace(/,/g, '')}</Text>
                                    <Text style={[styles.findingSheetColumn, { width: 20 }, styles.jcc, styles.aic, styles.tac]}>{orchardCodes.filter((y, i) => i <= 6).map((z, i) => i === 0 ? z : '\n' + z).toString().replace(/,/g, '')}</Text>
                                    <Text style={[styles.findingSheetColumn, { width: 30 }, styles.jcc, styles.aic, styles.tac]}></Text>
                                    <View style={[styles.fdc]}>
                                        <View style={[styles.fdr, styles.bbw1, styles.h40]}>
                                            {compliance.commodityId && renderConditions(renderFindingSheetTopCell, renderFindingSheetTopCellSpacer, compliance.commodityId)}
                                            <Text style={[styles.findingSheetColumn, { width: 30 }]}></Text>
                                        </View>
                                        <View style={[styles.fdr, styles.h40]}>
                                            {compliance.commodityId && renderConditions(renderFindingSheetStoneLowerCell, renderFindingSheetLowerCellSpacer, compliance.commodityId)}
                                            <Text style={[styles.findingSheetColumn, { width: 30 }, styles.bbw1]}></Text>
                                        </View>
                                    </View>
                                </View>;
                            })}
                            <View wrap={false}>
                                <View style={[styles.fdr, styles.bbw1]}>
                                    <Text style={[styles.blw1, styles.brw1, styles.fs8, styles.pl2, styles.pt5, styles.pb5, { width: 170 }]}>Marking Requirements</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 60 }]}>Product</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 45 }]}>Cultivar</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 45 }]}>Class</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>{'Size Code /\nDiameter'}</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Origin</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Address</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>PUC</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Date code</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Flesh Colour</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 25 }]}></Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 20 }]}></Text>
                                </View>
                                <View style={[styles.fdr, styles.bbw1]}>
                                    <Text style={[styles.blw1, styles.brw1, styles.fs8, styles.pl2, styles.pt5, styles.pb5, { width: 170 }]}>Containers</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 60 }]}>Clean</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 45 }]}>Dry</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 45 }]}>Undamaged</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Suitable</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>No odour</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Artropoda</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Fungi</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}></Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}></Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 25 }]}></Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 20 }]}></Text>
                                </View>
                                <View style={[styles.fdr, styles.bbw1]}>
                                    <Text style={[styles.blw1, styles.brw1, styles.fs8, styles.pl2, styles.pt5, styles.pb5, { width: 170 }]}>Packing Requirements</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 60 }]}>Pre-pack</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 45 }]}>Same Cultivar</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 45 }]}>Same size</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Same quality</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Same ripeness</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Capacity</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}></Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}></Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}></Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 25 }]}></Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 20 }]}></Text>
                                </View>
                                <View style={[styles.fdr, styles.bbw1]}>
                                    <Text style={[styles.blw1, styles.brw1, styles.fs8, styles.pl2, styles.pt5, styles.pb5, { width: 170 }]}>Pallet Requirements</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 60 }]}>Clean</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 45 }]}>No Bark</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 45 }]}>No Fungus</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 60 }]}>Same dimentions on the same layers</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>No Arthropoda</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Containers square</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>{'Secured/\ncovered'}</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Undamaged</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 25 }]}>ISPM</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 20 }]}></Text>
                                </View>
                                <View style={[styles.fdr, styles.bbw1]}>
                                    <Text style={[styles.blw1, styles.brw1, styles.fs8, styles.pl2, styles.pt5, styles.pb5, { width: 170 }]}>Traceability</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 60 }]}>Date Code</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 45 }]}>PUC</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 45 }]}>Pallet ID</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Address</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 60 }]}>{'Consignment \n note'}</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}></Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}></Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}></Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 25 }]}></Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 20 }]}></Text>
                                </View>
                                <View style={[styles.fdr, styles.bbw1]}>
                                    <Text style={[styles.blw1, styles.brw1, styles.fs8, styles.pl2, styles.pt5, styles.pb5, { width: 170 }]}>Pass for Export Stickers:</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 205 }]}>From:</Text>
                                    <Text style={[styles.brw1, styles.fs4, styles.pl2, styles.pt5, styles.pb5, { width: 170 }]}>To:</Text>
                                </View>
                                <View style={[styles.fdr, styles.bbw1]}>
                                    <Text style={[styles.blw1, styles.brw1, styles.fs8, styles.pl2, styles.pt5, styles.pb5, { width: 545 }]}>{'Rebrands:\n\n\n'}</Text>
                                </View>
                            </View>
                        </View>
                    </Page>,
                    )}
            </Document>;
        default: // GENERAL FINDING SHEET (everything other than avo's and stone fruit currently)
            return <Document>
                {
                    varieties.map(varietyId => <Page size='A4' style={styles.page}>
                        <View style={styles.pageMargin}>

                            <View style={[styles.fdr, styles.btw1, styles.blw1, styles.brw1, styles.pl2]}>
                                <Image style={{ height: 30 }} source={`${ASSET_BASE}/assets/images/ppecb-logo.jpg`}/>
                                <View style={styles.flx1} />
                                <Text style={[styles.fs10, styles.pl20]}>{'PPECB FINDINGS'}</Text>
                            </View>
                            <View style={[styles.fdc, styles.blw1, styles.brw1, styles.bbw1]}>
                                <View style={[styles.fdr, styles.pt2, styles.pr2]}>
                                    <View style={styles.flx1} />
                                    <Text style={styles.fs6}>{commodityExportCode}</Text>
                                    <Text style={[styles.fs6, styles.w30]}>{''}</Text>
                                    <Text style={styles.fs6}>{'10/8/2022'}</Text>
                                </View>
                                <View style={[styles.fdr, styles.pb10]} fixed>
                                    <View style={styles.flx1} />
                                    <Text style={[styles.fs8, styles.pt2, styles.pr2]} fixed render={({ pageNumber, totalPages }) => (
                                        `PAGE ${pageNumber} OF ${totalPages}`
                                    )}> </Text>
                                    <Text style={[styles.fs6, styles.w30]}>{''}</Text>
                                    <Text style={styles.fs6}>{'Rev: 04'}</Text>
                                    <Text style={[styles.fs6, styles.w30]}>{''}</Text>
                                </View>
                                <View style={[styles.jcc, styles.aic, styles.pb5]}>
                                    <Text style={[styles.fs13, styles.bold]}>PERISHABLE PRODUCTS EXPORT CONTROL BOARD</Text>
                                </View>
                            </View>
                            <View style={[styles.jcc, styles.aic, styles.pt5, styles.pb5, styles.blw1, styles.brw1, styles.bbw1]}>
                                <Text style={styles.fs11}>{'INSPECTION FINDINGS SHEET'}</Text>
                            </View>
                            <View style={[styles.fdr]}>
                                <View style={[styles.fdr, styles.pt5, styles.pb5, styles.blw1, styles.bbw1, styles.flx5]}>
                                    <View style={[styles.fdr, styles.pl2]}>
                                        <Text style={styles.fs6}>
                                                MARK APPLICABLE OPTION WITH A "     "</Text>
                                        <Image style={[styles.pl2, styles.pr2, styles.h8, styles.w8, { left: -10 }, styles.posr]} source={`${ASSET_BASE}/assets/images/checkbrand.png`}/>
                                    </View>
                                </View>
                                <View style={[styles.pl2, styles.fdr, styles.pt5, styles.pb5, styles.blw1, styles.bbw1, styles.flx2, styles.bcg]}>
                                    <Text style={styles.fs6}> *Min Standard: </Text>
                                </View>
                                <View style={[styles.fdr, styles.pt5, styles.pb5, styles.blw1, styles.bbw1, styles.flx1]}>
                                </View>
                                <View style={[styles.pl2, styles.fdr, styles.pt5, styles.pb5, styles.blw1, styles.bbw1, styles.flx2, styles.bcg]}>
                                    <Text style={styles.fs6}> *Special Market: </Text>
                                </View>
                                <View style={[styles.fdr, styles.pt5, styles.pb5, styles.blw1, styles.bbw1, styles.flx1]}>
                                </View>
                                <View style={[styles.pl2, styles.fdr, styles.pt5, styles.pb5, styles.blw1, styles.bbw1, styles.flx2, styles.bcg]}>
                                    <Text style={styles.fs6}> *Dispensation: </Text>
                                </View>
                                <View style={[styles.fdr, styles.pt5, styles.pb5, styles.bbw1, styles.blw1, styles.brw1, styles.flx1]}>
                                </View>
                            </View>
                            <View style={[styles.fdr]}>
                                <View style={[styles.fdc, styles.flx1, styles.blw1]}>
                                    <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl2, styles.bbw1]}>{'INSPECTOR & NO:'}</Text>
                                    <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl2, styles.bbw1]}>{`EXPORTER: ${getOrganizationExporter(compliance.organizationId)}`}</Text>
                                    <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl2, styles.bbw1]}>{`INSPECTION POINT:  ${compliance.siteId ? getSiteInspectionPointCode(compliance.siteId) : '' }`}</Text>
                                </View>
                                <View style={[styles.fdc, styles.flx1, styles.blw1]}>
                                    <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl2, styles.bbw1]}>{'SIGNATURE:'}</Text>
                                    <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl2, styles.bbw1]}>{'PRODUCT:'}</Text>
                                    <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl2, styles.bbw1]}>{`CULTIVAR: ${varietyId ? (getCommodityCode(compliance.commodityId) !== 'ON' ? getVarietyCode(varietyId) : '')  : '' }`}</Text>{/* Temporarily removed variety for Onions Issue No: 771 */}
                                </View>
                                <View style={[styles.fdc, styles.flx1, styles.blw1, styles.brw1]}>
                                    <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl2, styles.bbw1]}>{'DATE:'}</Text>
                                    <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl2, styles.bbw1]}>{`PUC/FBO: ${getComplianceFarms(compliance) }`}</Text>
                                    <Text style={[styles.fs10, styles.pt5, styles.pb5, styles.pl2, styles.bbw1]}>{`PHC: ${compliance.siteId ? getSiteCode(compliance.siteId) : '' }`}</Text>
                                </View>
                            </View>
                            <View style={[styles.fdr, styles.blw1, styles.brw1]}>
                                <Text style={[styles.fs10, styles.pb10, styles.pt10, styles.pl2, styles.flx3]}>{`CONSIGNMENT NOTE/S: ${compliance.waybill}`}</Text>
                            </View>
                            {renderCommodityConditionsHeader(compliance.commodityId)}
                            {compliance.complianceLines.filter((x) => {
                                const stock = getStock(x.stockId);
                                const hasVar = stock?.isActive && stock.stockLines.some(y => y.isActive && y.varietyId === varietyId);
                                return x.isActive && hasVar;
                            }).map((x, stockIndex) => {
                                const stock = getStock(x.stockId);
                                const sizeCodes : Array<string> = [];
                                const gradeCodes : Array<string> = [];
                                let regionCode = '';
                                if (stock) {
                                    const region = state.masterData.regions.find(z => z.id === stock.regionId);
                                    regionCode = region ? region.code : '';
                                    stock.stockLines.filter(y => y.isActive).forEach((z) => {
                                        const size = state.masterData.sizes.find(s => s.id === z.sizeId);
                                        if (size && !sizeCodes.some(s => s === size.code)) {
                                            sizeCodes.push(size.code);
                                        }
                                        const grade = state.masterData.grades.find(s => s.id === z.gradeId);
                                        if (grade && !gradeCodes.some(s => s === grade.code)) {
                                            gradeCodes.push(grade.code);
                                        }
                                    });
                                }
                                return <View wrap={false} key={'findingSheet_comp_line' + x.id} style={[styles.fdr, styles.bbw1, styles.h80]}>
                                    <View style={[styles.fdc, styles.pb5, styles.pt5, styles.brw1, styles.blw1, styles.aic, { width: 185 }]}>
                                        <View style={styles.fdr}>{stock ? <Text style={[styles.fs6, styles.flx1, styles.aife]}>{stockIndex + 1}</Text> : <Text />}</View>
                                        {stock ? <Text style={styles.barcode}>*{stock.barcode}*</Text> : <Text />}
                                        {stock ? <Text style={styles.barcodeLabel}>*{stock.barcode}*</Text> : <Text />}
                                    </View>
                                    <Text style={[styles.findingSheetColumn, { width: 30 }, styles.jcc, styles.aic, styles.tac]}>{gradeCodes.filter((y, i) => i <= 6).map((z, i) => i === 0 ? z : '\n' + z).toString() }</Text>
                                    <Text style={[styles.findingSheetColumn, { width: 30 }, styles.jcc, styles.aic, styles.tac]}>{regionCode}</Text>
                                    <Text style={[styles.findingSheetColumn, { width: 30 }, styles.jcc, styles.aic, styles.tac]}></Text>
                                    <View style={[styles.fdc]}>
                                        <View style={[styles.fdr, styles.bbw1, styles.h40]}>
                                            {compliance.commodityId && renderConditions(renderFindingSheetTopCell, renderFindingSheetTopCellSpacer, compliance.commodityId)}
                                            <Text style={[styles.findingSheetColumn, { width: 15 }]}></Text>
                                            <Text style={[styles.findingSheetColumn, { width: 15 }]}></Text>
                                            <Text style={[styles.findingSheetColumn, { width: 30 }, styles.bcg]}></Text>
                                        </View>
                                        <View style={[styles.fdr, styles.h40]}>
                                            {compliance.commodityId && renderConditions(renderFindingSheetLowerCell, renderFindingSheetLowerCellSpacer, compliance.commodityId)}
                                            <Text style={[styles.findingSheetColumn, { width: 15 }]}></Text>
                                            <Text style={[styles.findingSheetColumn, { width: 15 }]}></Text>
                                            <Text style={[styles.findingSheetColumn, { width: 30 }, styles.bcg, styles.bbw1]}></Text>
                                        </View>
                                    </View>
                                </View>;
                            })}
                            <View wrap={false}>
                                <View style={[styles.fdr, styles.bbw1]}>
                                    <Text style={[styles.blw1, styles.brw1, styles.fs8, styles.pl2, styles.pt5, styles.pb5, { width: 185 }]}>Marking Requirements</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Product</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>{'Cultivar'}</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Class</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 45 }]}>{'Diameter / \n Weight'}</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Count</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Origin</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Address</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>PUC</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Date code</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 45 }]}></Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }, styles.bcg, styles.bbw1]}></Text>
                                </View>
                                <View style={[styles.fdr, styles.bbw1]}>
                                    <Text style={[styles.blw1, styles.brw1, styles.fs8, styles.pl2, styles.pt5, styles.pb5, { width: 185 }]}>Pallet Requirements</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Clean</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Fungi</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Bark</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 45 }]}>{'Undamaged' }</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 60 }]}>Same dimensions on the same layers</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>{'Arthropoda'}</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>{'Containers \n square'}</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>{'Secured \n Covered'}</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 45 }]}>{'ISPM\n requirements'}</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }, styles.bcg]}></Text>
                                </View>
                                <View style={[styles.fdr, styles.bbw1]}>
                                    <Text style={[styles.blw1, styles.brw1, styles.fs8, styles.pl2, styles.pt5, styles.pb5, { width: 185 }]}>Traceability</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 60 }]}>Date Code</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>PUC</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 45 }]}>Pallet ID</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Address</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, styles.fdc, { width: 45 }]}>{'Consignment \n Note'}</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 120 }]}></Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }, styles.bcg, styles.bbw1]}></Text>
                                </View>
                                <View style={[styles.fdr, styles.bbw1]}>
                                    <Text style={[styles.blw1, styles.brw1, styles.fs8, styles.pl2, styles.pt5, styles.pb5, { width: 185 }]}>Packing Requirements</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Pre-pack</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 60 }]}>Same Cultivar</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 45 }]}>Same size</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }]}>Same quality</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, styles.fdc, { width: 45 }]}>Same ripeness</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 120 }]}></Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 30 }, styles.bcg, styles.bbw1]}></Text>
                                </View>
                                <View style={[styles.fdr, styles.bbw1]}>
                                    <Text style={[styles.blw1, styles.brw1, styles.fs8, styles.pl2, styles.pt5, styles.pb5, { width: 185 }]}>Passed for Export Stickers:</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 195 }]}>From:</Text>
                                    <Text style={[styles.brw1, styles.fs6, styles.pl2, styles.pt5, styles.pb5, { width: 165 }]}>To:</Text>
                                </View>
                                <View style={[styles.fdr, styles.bbw1]}>
                                    <Text style={[styles.blw1, styles.brw1, styles.fs8, styles.pl2, styles.pt5, styles.pb35, { width: 545 }]}>Rebrands:</Text>
                                </View>
                            </View>
                        </View>
                    </Page>,
                    )}
            </Document>;
    }
};

const dispatchLineRows = (barcodes : Array<string>) => {
    let barcodeRows : any = [];
    for (let i = 0; i < barcodes.length; i = i + 3) {
        barcodeRows = addArrayElement(barcodeRows,
            <View style={[styles.fdr, styles.mb30]}>
                <View style={[styles.fdc, styles.pb5, styles.pt5, styles.brw1, styles.blw1, styles.bbw1, styles.btw1, styles.aic, styles.h80, styles.w174]}>
                    {barcodes[i] ? <Text style={styles.barcode}>*{barcodes[i]}*</Text> : <Text />}
                    {barcodes[i] ? <Text style={styles.barcodeLabel}>*{barcodes[i]}*</Text> : <Text />}
                </View>
                <View style={styles.flx1} />
                <View style={[styles.fdc, styles.pb5, styles.pt5, styles.brw1, styles.blw1, styles.bbw1, styles.btw1, styles.aic, styles.h80, styles.w174]}>
                    {barcodes[i + 1] ? <Text style={styles.barcode}>*{barcodes[i + 1]}*</Text> : <Text />}
                    {barcodes[i + 1] ? <Text style={styles.barcodeLabel}>*{barcodes[i + 1]}*</Text> : <Text />}
                </View>
                <View style={styles.flx1} />
                <View style={[styles.fdc, styles.pb5, styles.pt5, styles.brw1, styles.blw1, styles.bbw1, styles.btw1, styles.aic, styles.h80, styles.w174]}>
                    {barcodes[i + 2] ? <Text style={styles.barcode}>*{barcodes[i + 2]}*</Text> : <Text />}
                    {barcodes[i + 2] ? <Text style={styles.barcodeLabel}>*{barcodes[i + 2]}*</Text> : <Text />}
                </View>
            </View>, 'end');
    }
    return barcodeRows;
};

const materialDispatchStockBarcodesPDF = (dispatch : IMaterialDispatch) => {
    return <Document>
        <Page size='A4' style={styles.page}>
            <View style={styles.pageMargin}>
                <View style={styles.fdr}>
                    <Image style={{ height: 42 }} source={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`}/>
                    <Text style={[styles.fs13, styles.bold, styles.mt15, styles.pl20]}>PACKMAN</Text>
                    <View style={styles.flx1} />
                </View>
                <View style={[styles.fdr, styles.pb10]} fixed>
                    <View style={styles.flx1} />
                    <Text style={styles.fs8} fixed render={({ pageNumber, totalPages }) => (
                        `PAGE ${pageNumber} OF ${totalPages}`
                    )}> </Text>
                </View>
                <View style={[styles.fdr, styles.aic, styles.pt5, styles.pb5]}>
                    <Text style={styles.fs13}>{`MATERIAL DISPATCH CODE - ${dispatch.materialDispatchCode}`}</Text>
                    <View style={styles.flx1} />
                    <Text style={styles.fs8}>{`Date: ${moment().local().format(DATE_FORMAT_DEFAULT)}`}</Text>
                </View>
                <View style={[styles.fdr]}>
                    <Text style={[styles.fs10, styles.pb10, styles.pt10, styles.w280]}>{'BARCODES'}</Text>
                </View>
                {dispatchLineRows(dispatch.materialDispatchLines.filter(x => x.isActive).map(x => getMaterialStock(x.sourceMaterialStockId)?.dateCode ?? ''))}
                {dispatchLineRows(dispatch.materialDispatchLines.filter(x => x.isActive).map(x => getMaterialStock(x.destinationMaterialStockId ?? 0)?.dateCode ?? ''))}
            </View>
        </Page>
    </Document>;
};

const dispatchStockBarcodesPDF = (dispatch : IDispatchInstruction) => {
    return <Document>
        <Page size='A4' style={styles.page}>
            <View style={styles.pageMargin}>
                <View style={styles.fdr}>
                    <Image style={{ height: 42 }} source={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`}/>
                    <Text style={[styles.fs13, styles.bold, styles.mt15, styles.pl20]}>PACKMAN</Text>
                    <View style={styles.flx1} />
                </View>
                <View style={[styles.fdr, styles.pb10]} fixed>
                    <View style={styles.flx1} />
                    <Text style={styles.fs8} fixed render={({ pageNumber, totalPages }) => (
                        `PAGE ${pageNumber} OF ${totalPages}`
                    )}> </Text>
                </View>
                <View style={[styles.fdr, styles.aic, styles.pt5, styles.pb5]}>
                    <Text style={styles.fs13}>{`DISPATCH CODE - ${dispatch.dispatchCode}`}</Text>
                    <View style={styles.flx1} />
                    <Text style={styles.fs8}>{`Date: ${moment().local().format(DATE_FORMAT_DEFAULT)}`}</Text>
                </View>
                <View style={[styles.fdr]}>
                    <Text style={[styles.fs10, styles.pb10, styles.pt10, styles.w280]}>{'BARCODES'}</Text>
                </View>
                {dispatchLineRows(dispatch.dispatchLines.filter(x => x.isActive).map(x => getStock(x.currentStockId)?.barcode ?? ''))}
            </View>
        </Page>
    </Document>;
};

const renderEmptyConditionLine = (value : number, index : number) => <View key={'spacer_title_' + index} style={[styles.findingSheetColumn, styles.jcc, { width: 15 }]}><Text style={styles.verticalText}></Text></View>;

const mapCsvToConsignment = (consignments : Array<IConsignmentCSVJson>) : Array<IConsignment> => {
    const result : Array<IConsignment> = [];

    consignments.forEach((x) => {
        const existingConsignment = result.find(y =>
            y.consignmentNumber === x.CONS_NO &&
            y.transactionType === x.TRAN_TYPE &&
            y.exporter === x.EXPORTER &&
            y.billingParty === x.BILLING_PARTY &&
            y.inspectionPoint === x.INSP_POINT);
        if (existingConsignment) {
            const existingHeader = existingConsignment.consignmentLines.find(y =>
                y.phc === x.PHC &&
                y.sscc === trimSSCC(x.SSCC) &&
                y.commodity === x.COMMODITY &&
                y.variety === x.VARIETY &&
                y.class.toString() === x.CLASS.toString() &&
                y.inspectionSampleWeight === x.INSP_SAMPLE_WEIGHT &&
                y.grossWeightPack === x.GROSS_WEIGHT_PACK &&
                y.nettWeightPack === x.NETT_WEIGHT_PACK &&
                y.carton === x.UNIT_TYPE &&
                y.targetRegion === x.TARG_REGION &&
                y.targetCountry === (x.TARG_COUNTRY || '00') &&
                y.protocolExceptionIndicator === (x.PROTOCOL_EXCEPTION_IND || 'N'));

            if (existingHeader) {
                existingHeader.cartonQty += x.CARTONS;
                existingHeader.consignmentLinePallets.push({
                    ssccReference: x.SSCC_REF || 'N',
                    ssccSequenceNumber: x.SEQ_NO_SSCC,
                    palletQty: x.CARTONS,
                    puc: x.PUC,
                    orchard: x.ORCHARD ? x.ORCHARD : '',
                    phytoData: x.PHYTO_IND || 'N',
                    packCode: x.PACK,
                    packDate: formatPackDate(x.PACK_DATE),
                    prePackingTreatment: x.PRE_PACK_TREATMENT || 'N',
                    sizeCount: x.SIZE_COUNT,
                    inventoryCode: '', // colour code, currently not on 202
                    packagingTypeCode: x.PACKAGE_CATEGORY,
                });
            } else {
                existingConsignment.consignmentLines.push({
                    phc: x.PHC,
                    sscc: trimSSCC(x.SSCC),
                    commodity: x.COMMODITY,
                    variety: x.VARIETY,
                    class: x.CLASS.toString(),
                    inspectionSampleWeight: x.INSP_SAMPLE_WEIGHT,
                    grossWeightPack: x.GROSS_WEIGHT_PACK,
                    nettWeightPack: x.NETT_WEIGHT_PACK,
                    carton: x.UNIT_TYPE,
                    cartonQty: x.CARTONS,
                    targetRegion: x.TARG_REGION,
                    targetCountry: x.TARG_COUNTRY || '00', // currently has no value in koldstor, but needs to be a string of 2 characters
                    protocolExceptionIndicator: x.PROTOCOL_EXCEPTION_IND || 'N',
                    consignmentLinePallets: [{
                        ssccReference: x.SSCC_REF || 'N',
                        ssccSequenceNumber: x.SEQ_NO_SSCC,
                        palletQty: x.CARTONS,
                        puc: x.PUC,
                        orchard: x.ORCHARD ? x.ORCHARD : '',
                        phytoData: x.PHYTO_IND || 'N',
                        packCode: x.PACK,
                        packDate: formatPackDate(x.PACK_DATE),
                        prePackingTreatment: x.PRE_PACK_TREATMENT || 'N',
                        sizeCount: x.SIZE_COUNT,
                        inventoryCode: '', // colour code, currently not on 202
                        packagingTypeCode: x.PACKAGE_CATEGORY,
                    }],
                });
            }
        } else {
            result.push({
                consignmentNumber: x.CONS_NO,
                transactionType: x.TRAN_TYPE,
                bookingRef: Number(x.BOOKING_REFERENCE),
                exporter: x.EXPORTER,
                billingParty: x.BILLING_PARTY,
                inspectionPoint: x.INSP_POINT,
                inspectionDate: x.INSP_DATE ? formatPackDate(x.INSP_DATE) : moment(moment.now()).format(DATEPICKER_FORMAT_DEFAULT),
                consignmentLines: [{
                    phc: x.PHC,
                    sscc: trimSSCC(x.SSCC),
                    commodity: x.COMMODITY,
                    variety: x.VARIETY,
                    class: x.CLASS.toString(),
                    inspectionSampleWeight: x.INSP_SAMPLE_WEIGHT,
                    grossWeightPack: x.GROSS_WEIGHT_PACK,
                    nettWeightPack: x.NETT_WEIGHT_PACK,
                    carton: x.UNIT_TYPE,
                    cartonQty: x.CARTONS,
                    targetRegion: x.TARG_REGION,
                    targetCountry: x.TARG_COUNTRY || '00', // currently has no value in koldstor, but needs to be a string of 2 characters
                    protocolExceptionIndicator: x.PROTOCOL_EXCEPTION_IND || 'N',
                    consignmentLinePallets: [{
                        ssccReference: x.SSCC_REF || 'N',
                        ssccSequenceNumber: x.SEQ_NO_SSCC,
                        palletQty: x.CARTONS,
                        puc: x.PUC,
                        orchard: x.ORCHARD ? x.ORCHARD : '',
                        phytoData: x.PHYTO_IND || 'N',
                        packCode: x.PACK,
                        packDate: formatPackDate(x.PACK_DATE),
                        prePackingTreatment: x.PRE_PACK_TREATMENT || 'N',
                        sizeCount: x.SIZE_COUNT,
                        inventoryCode: '', // colour code, currently not on 202
                        packagingTypeCode: x.PACKAGE_CATEGORY,
                    }],
                }],
            });
        }
    });

    return result;
};

export const mapComplianceToConsignment = (compliance : ICompliance, agreementCode : string) : IConsignment => {
    const state = getState();
    const result : IConsignment = {
        // inspectionMessageId: compliance.inspectionMessageId,
        consignmentNumber: compliance.waybill ?? '',
        transactionType: 202,
        bookingRef: Number(getSiteBookingRefNr(compliance.siteId)),
        exporter: getOrganizationExporter(compliance.organizationId),
        billingParty: getOrganizationBillingParty(compliance.organizationId),
        inspectionPoint: Number(getSiteInspectionPointCode(compliance.siteId ?? 0)),
        inspectionDate: formatPackDate(moment(moment.now()).format(DATE_FORMAT_CSV)),
        consignmentLines: [],
    };

    compliance.complianceLines.forEach((complianceLine) => {
        const stock = state.data.stocks.find(y => y.id === complianceLine.stockId && y.isActive);
        if (!stock) return;
        const batchLessStockLines : Array<IStockLine> = [];
        const oldStockLines = stock ? [...stock.stockLines.filter(y => y.isActive)] : [];

        oldStockLines.forEach((y) => {
            y.batchId = undefined; // consignment does not have batch
            const existingIndex = batchLessStockLines.findIndex(z => (z.commodityId === y.commodityId)
                && (z.varietyId === y.varietyId)
                && (z.gradeId === y.gradeId)
                && (z.farmId === y.farmId)
                && (z.packId === y.packId)
                && (z.sizeId === y.sizeId)
                && (z.orchardId === y.orchardId)
                && (z.colourId === y.colourId)
                // && (z.batchId === y.batchId) // currently not in consignment
                && (z.stockId === y.stockId));
            if (existingIndex === -1) {
                batchLessStockLines.push({ ...y });
            } else {
                batchLessStockLines[existingIndex].cartons += y.cartons;
            }
        });

        batchLessStockLines.forEach((y, ind) => {
            y.sequence = ind + 1;
        });
        batchLessStockLines.forEach((stockLine) => {
            const pack = state.masterData.packs.find(z => z.id === stockLine.packId);
            const existingLineIndex = result.consignmentLines.findIndex(line => (line.phc === getSiteCode(compliance.siteId ?? 0))
                && (line.sscc === stock.barcode)
                && (line.commodity === getCommodityCode(compliance.commodityId ?? 0))
                && (line.variety === getVarietyCode(stockLine.varietyId ?? 0))
                && (line.class.toString() === getGradeCode(stockLine.gradeId ?? 0))
                && (line.grossWeightPack === Number(pack?.grossWeight))
                && (line.nettWeightPack === Number(pack?.nettWeight))
                && (line.targetRegion === getRegionCode(stock.regionId))
                && (line.targetCountry === getCountryCode(stock.countryId)));
            if (existingLineIndex === -1) {
                result.consignmentLines.push({
                    agreementCode,
                    phc: getSiteCode(compliance.siteId ?? 0),
                    sscc: stock?.barcode,
                    commodity: getCommodityCode(compliance.commodityId ?? 0),
                    variety: getVarietyCode(stockLine.varietyId ?? 0),
                    class: getGradeCode(stockLine.gradeId ?? 0),
                    inspectionSampleWeight: 0,
                    nettWeightPack: Number(pack?.nettWeight),
                    grossWeightPack: Number(pack?.grossWeight),
                    carton: 'C',
                    cartonQty: stockLine.cartons,
                    targetRegion: getRegionCode(stock.regionId),
                    targetCountry: getCountryCode(stock.countryId),
                    protocolExceptionIndicator: 'N',
                    consignmentLinePallets: [{
                        ssccReference: 'N',
                        ssccSequenceNumber: stockLine.sequence,
                        palletQty: stockLine.cartons,
                        puc: getFarmCode(stockLine.farmId),
                        orchard: getOrchardComplianceCode(stockLine.orchardId),
                        phytoData: (getRegionCode(stock.regionId) === 'UKI' || getRegionCode(stock.regionId) === 'EUN') && getCommodityName(stockLine.commodityId) === 'CHERRIES' ? 'EUNAFY' : '',
                        packCode: getPackCode(stockLine.packId),
                        packDate: formatPackDate(moment(stock.packDate).format(DATE_FORMAT_CSV)),
                        prePackingTreatment: 'N',
                        sizeCount: getSizeCode(stockLine.sizeId),
                        inventoryCode: getColourCode(stockLine.colourId),
                        packagingTypeCode: getPackCategoryCode(stockLine.packId),
                    }],
                });
            } else {
                result.consignmentLines[existingLineIndex].cartonQty += stockLine.cartons;
                result.consignmentLines[existingLineIndex].consignmentLinePallets.push({
                    ssccReference: 'N',
                    ssccSequenceNumber: stockLine.sequence,
                    palletQty: stockLine.cartons,
                    puc: getFarmCode(stockLine.farmId),
                    orchard: getOrchardComplianceCode(stockLine.orchardId),
                    phytoData: (getRegionCode(stock.regionId) === 'UKI' || getRegionCode(stock.regionId) === 'EUN') && getCommodityName(stockLine.commodityId) === 'CHERRIES' ? 'EUNAFY' : '',
                    packCode: getPackCode(stockLine.packId),
                    packDate: formatPackDate(moment(stock.packDate).format(DATE_FORMAT_CSV)),
                    prePackingTreatment: 'N',
                    sizeCount: getSizeCode(stockLine.sizeId),
                    inventoryCode: getColourCode(stockLine.colourId),
                    packagingTypeCode: getPackCategoryCode(stockLine.packId),
                });
            }
        });
    });

    return result;
};

export const mapComplianceToELot = (compliance : ICompliance, agreementCode : string, turToken : string) : IELot => {
    return {
        BusinessID: '384',
        Industry: 'FRUIT',
        AgreementCode: agreementCode,
        IsTest: false,
        IsUpdate: false,
        TrackingUnits: mapComplianceToTrackingUnits(compliance),
        Token: turToken,
    };
};

const mapComplianceToTrackingUnits = (compliance : ICompliance) : Array<ITrackingUnit> => {
    const trackingUnits : Array<ITrackingUnit> = [];
    const state = getState();
    compliance?.complianceLines.forEach((line) => {
        const stock = line.stock;

        stock?.stockLines.forEach((stockLine) => {
            const pack = state.masterData.packs.find(z => z.id === stockLine.packId);
            const index = trackingUnits.findIndex(x => x.TrackingUnitID === stock.barcode);
            if (index === -1) {
                trackingUnits.push({
                    TrackingUnitID: stock.barcode,
                    ExportDate: formatMomentToDatePicker(moment().utc().startOf('day')),
                    NumberOfPackageItems: stock?.cartons,
                    Weight: stock.estimatedGrossWeight,
                    WeightUnitCode: 'KG',
                    Reference1: compliance.waybill,
                    TrackingUnitDetails: [{
                        OperatorCode: getFarmCode(stockLine.farmId ?? 0), // PUC // Farm Code
                        OriginLocation: getOrchardComplianceCode(stockLine.orchardId ?? 0), // Orchard Code
                        SPSStatus: 'N', // Phyto Status (N)
                        PackOperatorCode: getSiteCode(compliance.siteId ?? 0), // PHC // Site Code
                        CommodityCode: getCommodityCode(compliance.commodityId ?? 0),
                        MarketingIndicationCode: getVarietyCode(stockLine.varietyId ?? 0), // variety code
                        ClassCategory: getGradeCode(stockLine.orchardId ?? 0), // I or II or P (most likely P)
                        NumberOfPackagedItems: stockLine?.cartons, // carton count // cartons
                        PackageType: 'CT', // CT
                        Weight: stockLine.cartons * (pack?.grossWeight ?? 0),
                        WeightUnitCode: 'KG', // KG
                    }],
                });
            } else {
                trackingUnits[index].TrackingUnitDetails.push({
                    OperatorCode: getFarmCode(stockLine.farmId ?? 0), // PUC // Farm Code
                    OriginLocation: getOrchardComplianceCode(stockLine.orchardId ?? 0), // Orchard Code
                    SPSStatus: 'N', // Phyto Status (N)
                    PackOperatorCode: getSiteCode(compliance.siteId ?? 0), // PHC // Site Code
                    CommodityCode: getCommodityCode(compliance.commodityId ?? 0),
                    MarketingIndicationCode: getVarietyCode(stockLine.varietyId ?? 0), // variety code
                    ClassCategory: getGradeCode(stockLine.orchardId ?? 0), // I or II or P (most likely P)
                    NumberOfPackagedItems: stockLine?.cartons, // carton count // cartons
                    PackageType: 'CT', // CT
                    Weight: stockLine.cartons * (pack?.grossWeight ?? 0),
                    WeightUnitCode: 'KG', // KG
                });
            }
        });
    });
    trackingUnits.forEach((x) => {
        let weight = 0;
        let cartons = 0;
        x.TrackingUnitDetails.forEach((y) => {
            weight += y.Weight;
            cartons += y.NumberOfPackagedItems;
        });
        x.Weight = weight;
        x.NumberOfPackageItems = cartons;
    });
    return trackingUnits;
};

export const mapConsignmentsToELot = (consignments : Array<IConsignment>, agreementCode : string, turToken : string) : IELot => {
    return {
        BusinessID: '384',
        Industry: 'FRUIT',
        AgreementCode: agreementCode,
        IsTest: false,
        IsUpdate: false,
        TrackingUnits: mapConsignmentsToTrackingUnits(consignments),
        Token: turToken,
    };
};

const mapConsignmentsToTrackingUnits = (consignments : Array<IConsignment>) : Array<ITrackingUnit> => {
    const trackingUnits : Array<ITrackingUnit> = [];
    consignments.forEach((cons) => {
        cons.consignmentLines.forEach((line) => {
            line.consignmentLinePallets.forEach((linePallet) => {
                const index = trackingUnits.findIndex(x => x.TrackingUnitID === line.sscc);
                if (index === -1) {
                    trackingUnits.push({
                        TrackingUnitID: line.sscc,
                        ExportDate: formatMomentToDatePicker(moment().utc().startOf('day')),
                        NumberOfPackageItems: line.cartonQty,
                        Weight: line.cartonQty * line.grossWeightPack,
                        WeightUnitCode: 'KG',
                        Reference1: cons.consignmentNumber,
                        TrackingUnitDetails: [{
                            OperatorCode: linePallet.puc, // PUC // Farm Code
                            OriginLocation: linePallet.orchard || '', // Orchard Code
                            SPSStatus: 'N', // Phyto Status (N)
                            PackOperatorCode: line.phc, // PHC // Site Code
                            CommodityCode: line.commodity,
                            MarketingIndicationCode: line.variety, // variety code
                            ClassCategory: line.class, // I or II or P (most likely P)
                            NumberOfPackagedItems: linePallet.palletQty, // carton count // cartons
                            PackageType: 'CT', // CT
                            Weight: linePallet.palletQty * line.grossWeightPack,
                            WeightUnitCode: 'KG', // KG
                        }],
                    });
                } else {
                    trackingUnits[index].TrackingUnitDetails.push({
                        OperatorCode: linePallet.puc, // PUC // Farm Code
                        OriginLocation: linePallet.orchard || '', // Orchard Code
                        SPSStatus: 'N', // Phyto Status (N)
                        PackOperatorCode: line.phc, // PHC // Site Code
                        CommodityCode: line.commodity,
                        MarketingIndicationCode: line.variety, // variety code
                        ClassCategory: line.class, // I or II or P (most likely P)
                        NumberOfPackagedItems: linePallet.palletQty, // carton count // cartons
                        PackageType: 'CT', // CT
                        Weight: linePallet.palletQty * line.grossWeightPack,
                        WeightUnitCode: 'KG', // KG
                    });
                }
            });
        });
    });
    trackingUnits.forEach((x) => {
        let weight = 0;
        let cartons = 0;
        x.TrackingUnitDetails.forEach((y) => {
            weight += y.Weight;
            cartons += y.NumberOfPackagedItems;
        });
        x.Weight = weight;
        x.NumberOfPackageItems = cartons;
    });
    return trackingUnits;
};

export const onFileLoaded = (csvData : Array<IConsignmentCSVJson>, file : Blob, fileName : string, versionFallback : boolean = false, setLoading : (loading : boolean) => void, setMessage : (message : string) => void) => {
    const state = getState();
    const auth = state.auth;
    setLoading(true);
    const consignments = mapCsvToConsignment(csvData);
    const consignment = consignments[0];
    const consignmentNumber = consignment?.consignmentNumber;
    const transactionType = consignment?.transactionType;
    if (!consignment || transactionType.toString() !== '202' || consignmentNumber === ''  || !consignmentNumber) {
        generalShowErrorSnackbar('This is not a valid 202 file');
        setLoading(false);
        return;
    }

    const token = auth.ppecbToken?.token;
    const turToken = auth.turToken?.access_token;

    if (!token) {
        generalShowErrorSnackbar('Could not find PPECB token');
        setLoading(false);
        return;
    }

    if (!turToken) {
        generalShowErrorSnackbar('Could not find TUR token');
        setLoading(false);
        return;
    }

    const firstLine = consignment.consignmentLines[0];
    getAgreementCode(firstLine?.commodity, firstLine?.targetCountry ?? 'NL').then((agreementCodeResult) => {
        const agreementCode = agreementCodeResult.data;
        afterAgreementOnFileLoaded(consignments, consignment, agreementCode, token, consignmentNumber, file, fileName, turToken, setLoading, setMessage);
    }).catch(() => {
        generalShowErrorSnackbar('Agreement Code could not be obtained.');
        if (versionFallback) {
            afterAgreementOnFileLoaded(consignments, consignment, 'AGM0102', token, consignmentNumber, file, fileName, turToken, setLoading, setMessage);
        } else {
            setLoading(false);
            return;
        }
    });

};

const afterAgreementOnFileLoaded = (consignments : Array<IConsignment>, consignment : IConsignment, agreementCode : string, token : string, consignmentNumber : string, file : Blob, fileName : string, turToken : string, setLoading : (loading : boolean) => void, setMessage : (message : string) => void) => {
    if (agreementCode === null || agreementCode === '') {
        generalShowErrorSnackbar('Agreement Code could not be obtained.');
        setLoading(false);
        return;
    }

    const eLot = mapConsignmentsToELot(consignments, agreementCode, turToken);

    upsertELot(eLot).then((eLotResult) => {
        // eslint-disable-next-line no-console
        console.log(eLotResult);
        let eLotKey = eLotResult.data?.eLotKey;

        if (!eLotResult.data.isSuccessful) {
            if (eLotResult.data.message.includes('already')) {
                eLot.IsUpdate = true;
                upsertELot(eLot).then((eLotUpdResult) => {
                    // eslint-disable-next-line no-console
                    console.log(eLotUpdResult);
                    eLotKey = eLotUpdResult.data?.eLotKey;
                    afterELot(consignment, agreementCode, token, consignmentNumber, file, fileName, setLoading, setMessage, eLotKey);
                }).catch(() => {
                    generalShowErrorSnackbar('ELot could not be uploaded.');
                    afterELot(consignment, agreementCode, token, consignmentNumber, file, fileName, setLoading, setMessage, eLotKey);
                    return;
                });
            } else {
                generalShowErrorSnackbar('ELot could not be uploaded.');
                afterELot(consignment, agreementCode, token, consignmentNumber, file, fileName, setLoading, setMessage, eLotKey);
                return;
            }
        } else {
            afterELot(consignment, agreementCode, token, consignmentNumber, file, fileName, setLoading, setMessage, eLotKey);
        }

    }).catch(() => {
        generalShowErrorSnackbar('ELot could not be uploaded.');
        afterELot(consignment, agreementCode, token, consignmentNumber, file, fileName, setLoading, setMessage, undefined);
        return;
    });

};

const afterELot = (consignment : IConsignment, agreementCode : string, token : string, consignmentNumber : string, file : Blob, fileName : string, setLoading : (loading : boolean) => void, setMessage : (message : string) => void, eLotKey ?: string) => {
    consignment.consignmentLines.forEach(x => x.agreementCode = agreementCode);

    uploadConsignment(consignment).then((ppecbResult) => {
        // eslint-disable-next-line no-console
        console.log(JSON.stringify(consignment));

        // eslint-disable-next-line no-console
        console.log(ppecbResult);

        if (ppecbResult.status === 200) {
            const messageId = ppecbResult.data?.inspectionMessageId;
            if (messageId) {
                validateConsignment(messageId).then((validationResult) => {
                    if (validationResult?.data?.errors && validationResult?.data?.errors.length > 0) {
                        setMessage(JSON.stringify(validationResult?.data?.errors));
                        generalShowErrorSnackbar('File could not be submitted to PPECB');
                        setLoading(false);
                        return;
                    } else if (validationResult?.data && validationResult.status === 200) {
                        generalShowSuccessSnackbar('File submitted to PPECB');

                        const reader = new FileReader();
                        reader.onload = async () => {
                            const fileStr = reader.result;
                            if (typeof fileStr === 'string') {
                                const consignmentStorage : IConsignmentStorage = {
                                    id : 0,
                                    guid: v1(),
                                    consignmentNo: consignmentNumber,
                                    fileName,
                                    eLotKey,
                                    fileUpload: fileStr,
                                    inspectionMessageId: messageId,
                                    is202: true,
                                    isActive: true,
                                };
                                try {
                                    const res = await ConsignmentHttpService.addOrUpdateConsignment(consignmentStorage);

                                    if (res && res.data) {
                                        setLoading(false);
                                        generalShowSuccessSnackbar('Consignment successfully uploaded to database.');
                                        return;
                                    } else {
                                        generalShowErrorSnackbar('An error occurred saving the consignment to database.');
                                        setLoading(false);
                                        return;
                                    }
                                } catch (e) {
                                    generalShowErrorSnackbar('An error occurred saving the consignment to database.');
                                    setLoading(false);
                                    return;
                                }
                            }
                        };
                        reader.readAsDataURL(file);
                    } else {
                        setMessage(JSON.stringify(validationResult?.data));
                        generalShowErrorSnackbar('Could not validate consignment');
                    }
                });
            } else {
                generalShowErrorSnackbar('No message id was returned, could not submit consignment to PPECB');
            }
        } else {
            setMessage(JSON.stringify(ppecbResult?.data));
            generalShowErrorSnackbar('File could not be submitted to PPECB');
        }
        setLoading(false);

    }).catch(() => {
        generalShowErrorSnackbar('File could not be submitted to PPECB');
        setLoading(false);
        return;
    });
};

export const renderCommodityConditionsAvoHeader = (commodityId ?: number, commodityConditions ?: Array<ICommodityConditionRel>) => <View style={[styles.fdr, styles.btw1, styles.bbw1, styles.h90]} fixed>
    <View style={[styles.fs6, styles.jcc, { width: 174 }, styles.blw1, styles.brw1]}><Text style={[]}>{'PALLET NO/ PALLET BARCODE ID'}</Text></View>
    <View style={[styles.findingSheetColumn, styles.jcc, { width: 30 }]}><Text style={[styles.aic, styles.jcc, styles.tac]}>{'Code'}</Text></View>
    <View style={[styles.findingSheetColumn, styles.jcc, { width: 20 }]}><Text style={[styles.aic, styles.jcc, styles.tac]}>{'Class'}</Text></View>
    <View style={[styles.findingSheetColumn, styles.jcc, { width: 51 }]}><Text style={[styles.aic, styles.jcc, styles.tac]}>{'Target Region or Country / Target Market'}</Text></View>
    {(commodityId || commodityConditions) && renderConditions(renderCommodityCondition, renderEmptyConditionLine, commodityId, commodityConditions)}
    <View style={[styles.findingSheetColumn, styles.aic, styles.jcc, { width: 30 }]}><Text style={styles.verticalText}>{'Date code' }</Text></View>
    <View style={[styles.findingSheetColumn, styles.aic, styles.jcc, { width: 30 }]}><Text style={styles.verticalText}>{'C - COMPLY \n R - REJECTED' }</Text></View>
</View>;

export const renderCommodityConditionsStoneHeader = (commodityId ?: number, commodityConditions ?: Array<ICommodityConditionRel>) => <View style={[styles.fdr, styles.btw1, styles.bbw1, styles.h90]} fixed>
    <View style={[styles.fs6, styles.jcc, { width: 170 }, styles.blw1, styles.brw1]}><Text style={[]}>{'PALLET NO/ PALLET BARCODE ID'}</Text></View>
    <View style={[styles.findingSheetColumn, styles.aic, styles.jcc, { width: 20 }]}><Text style={[styles.aic, styles.jcc, styles.verticalText]}>{'COUNT/SIZE GROUP'}</Text></View>
    <View style={[styles.findingSheetColumn, styles.aic, styles.jcc, { width: 20 }]}><Text style={[styles.aic, styles.jcc, styles.verticalText]}>{'CLASS'}</Text></View>
    <View style={[styles.findingSheetColumn, styles.aic, styles.jcc, { width: 20 }]}><Text style={[styles.aic, styles.jcc, styles.verticalText]}>{'TARGET MARKET'}</Text></View>
    <View style={[styles.findingSheetColumn, styles.aic, styles.jcc, { width: 25 }]}><Text style={[styles.aic, styles.jcc, styles.verticalText]}>{'PUC'}</Text></View>
    <View style={[styles.findingSheetColumn, styles.aic, styles.jcc, { width: 20 }]}><Text style={[styles.aic, styles.jcc, styles.verticalText]}>{'ORCHARD NUMBER'}</Text></View>
    <View style={[styles.findingSheetColumn, styles.aic, styles.jcc, { width: 30 }]}><Text style={[styles.aic, styles.jcc, styles.verticalText]}>{'CARTON MASS/REPACKED UNIT MASS'}</Text></View>
    {(commodityId || commodityConditions) && renderConditions(renderCommodityCondition, renderEmptyConditionLine, commodityId, commodityConditions)}
    <View style={[styles.findingSheetColumn, styles.aic, styles.jcc, { width: 30 }]}><Text style={[styles.aic, styles.jcc, styles.verticalText]}>{'C - COMPLY \n R - REJECTED' }</Text></View>
</View>;

export const renderCommodityConditionsHeader = (commodityId ?: number, commodityConditions ?: Array<ICommodityConditionRel>) => <View style={[styles.fdr, styles.btw1, styles.bbw1, styles.h90]} fixed>
    <View style={[styles.fs6, styles.jcc, { width: 185 }, styles.blw1, styles.brw1]}><Text style={[]}>{'PALLET NO/ PALLET BARCODE ID'}</Text></View>
    <View style={[styles.findingSheetColumn, styles.aic, styles.jcc, { width: 30 }]}><Text style={styles.verticalText}>{'Class'}</Text></View>
    <View style={[styles.findingSheetColumn, styles.aic, styles.jcc, styles.tac, { width: 30 }]}><Text style={styles.verticalText}>{'Target \n Region/Country'}</Text></View>
    <View style={[styles.findingSheetColumn, styles.aic, styles.jcc, styles.tac, { width: 30 }]}><Text style={styles.verticalText}>{'Size/ Count / \nDiameter'}</Text></View>
    {(commodityId || commodityConditions) && renderConditions(renderCommodityCondition, renderEmptyConditionLine, commodityId, commodityConditions)}
    <View style={[styles.findingSheetColumn, styles.aic, styles.jcc, { width: 15 }]}><Text style={styles.verticalText}>{}</Text></View>
    <View style={[styles.findingSheetColumn, styles.aic, styles.jcc, { width: 15 }]}><Text style={styles.verticalText}>{}</Text></View>
    <View style={[styles.findingSheetColumn, styles.aic, styles.jcc, styles.tac, { width: 30 }]}><Text style={styles.verticalText}>{'C - COMPLY \n R - REJECTED' }</Text></View>
</View>;

export const exportFindingSheet = async (compliance ?: ICompliance, siteCode ?: string, callback ?: () => void) => buildPDF(findingSheet, `${siteCode} - ${compliance?.waybill} - PPECB Finding Sheet.pdf`, compliance, callback);

export const buildPDF = (document : any, fileName : string, compliance ?: ICompliance, callback ?: () => void) => {
    const varieties : Array<number> = [];

    compliance?.complianceLines.filter(x => x.isActive).forEach((x) => {
        const stock = getStock(x.stockId);
        stock?.stockLines.filter(y => y.isActive).forEach((y) => {
            if (!varieties.some(z => z === y.varietyId)) {
                varieties.push(y.varietyId);
            }
        });
    });

    const doc : () => React.ReactElement<IDocumentProps> = () => document(compliance, varieties);

    createAndDownloadPDF(
        doc,
        fileName,
        'pdf-creator-link',
        // tslint:disable-next-line: no-empty
        callback ? callback : () => {},
    );
};

export const generateBarcodesFromMaterialDispatch = async (dispatch ?: IMaterialDispatch, siteCode ?: string, callback ?: () => void) => buildMaterialDispatchBarcodePDF(materialDispatchStockBarcodesPDF, `${siteCode} - ${dispatch?.materialDispatchCode} - Material Stock Date Codes.pdf`, dispatch, callback);

export const generateBarcodesFromDispatch = async (dispatch ?: IDispatchInstruction, siteCode ?: string, callback ?: () => void) => buildDispatchBarcodePDF(dispatchStockBarcodesPDF, `${siteCode} - ${dispatch?.dispatchCode} - Stock Barcodes.pdf`, dispatch, callback);

export const buildDispatchBarcodePDF = (document : any, fileName : string, dispatch ?: IDispatchInstruction, callback ?: () => void) => {
    const doc : () => React.ReactElement<IDocumentProps> = () => document(dispatch);

    createAndDownloadPDF(
        doc,
        fileName,
        'pdf-creator-link',
        // tslint:disable-next-line: no-empty
        callback ? callback : () => {},
    );
};

export const buildMaterialDispatchBarcodePDF = (document : any, fileName : string, dispatch ?: IMaterialDispatch, callback ?: () => void) => {
    const doc : () => React.ReactElement<IDocumentProps> = () => document(dispatch);

    createAndDownloadPDF(
        doc,
        fileName,
        'pdf-creator-link',
        // tslint:disable-next-line: no-empty
        callback ? callback : () => {},
    );
};

export const exportCompliance = (exportedCompliance ?: ICompliance) => {
    const returnData : Array<Array<string>> = [];
    const state = getState();
    returnData.push(COMPLIANCE_CSV_HEADINGS);

    if (!exportedCompliance || !exportedCompliance.isActive) {
        return [];
    }

    exportedCompliance.complianceLines.filter(x => x.isActive).forEach((x) => {
        const stock = state.data.stocks.find(y => y.id === x.stockId && y.isActive);
        if (!stock) {
            return null;
        }

        const batchLessStockLines : Array<IStockLine> = [];
        const oldStockLines = [...stock.stockLines.filter(y => y.isActive)];
        // console.log(batchLessStockLines);

        oldStockLines.forEach((y) => {
            y.batchId = undefined; // 202 does not have batch
            y.colourId = undefined; // 202 does not have colour
            const existingIndex = batchLessStockLines.findIndex(z => (z.commodityId === y.commodityId)
                && (z.varietyId === y.varietyId)
                && (z.gradeId === y.gradeId)
                && (z.farmId === y.farmId)
                && (z.packId === y.packId)
                && (z.sizeId === y.sizeId)
                && (z.orchardId === y.orchardId)
                // && (z.colourId === y.colourId) // currently not in 202
                // && (z.batchId === y.batchId) // currently not in 202 (main reason why this is here)
                && (z.stockId === y.stockId));
            if (existingIndex === -1) {
                batchLessStockLines.push({ ...y });
            } else {
                // console.log(stock.barcode, batchLessStockLines[existingIndex].cartons, y.cartons);
                batchLessStockLines[existingIndex].cartons += y.cartons;
                // console.log(stock.barcode, batchLessStockLines[existingIndex].cartons, y.cartons);
            }
        });

        batchLessStockLines.forEach((y, ind) => {
            y.sequence = ind + 1;
        });

        batchLessStockLines.forEach((y) => {
            const pack = state.masterData.packs.find(z => z.id === y.packId && x.isActive);
            returnData.push([
                getFarmCode(y.farmId), // 'EDI_PARTNER_ID',
                '1', // 'EDI_FILE_VERSION',
                moment.utc().format(DATE_FORMAT_CSV), // 'FILE_CREATION_DATE', yyyymmdd
                moment.utc().format(TIME_FORMAT_CSV), // 'FILE_CREATION_TIME', hh:mm
                '1', // 'REVISION',
                '202', // 'TRAN_TYPE',
                exportedCompliance.waybill ? exportedCompliance.waybill : '', // 'CONS_NO',
                getSiteBookingRefNr(exportedCompliance.siteId), // 'BOOKING_REFERENCE',
                getOrganizationBillingParty(exportedCompliance.organizationId), // 'BILLING_PARTY',
                getOrganizationExporter(exportedCompliance.organizationId), // 'EXPORTER',
                '', // 'UPN',
                getSiteCode(stock.originalSiteId), // 'PHC',
                stock.barcode ? `'${stock.barcode}` : '', // 'SSCC',
                y ? y.sequence.toString() : '', // 'SEQ_NO_SSCC',
                '0', // 'SSCC_REV',
                '', // 'CARTON_ID',
                '', // 'SSCC_REF',
                getFarmCode(y.farmId), // 'PUC',
                getOrchardComplianceCode(y.orchardId), // 'ORCHARD',
                getCommodityCode(y.commodityId), // 'COMMODITY',
                getVarietyCode(y.varietyId), // 'VARIETY',
                getGradeCode(y.gradeId), // 'CLASS',
                getSizeCode(y.sizeId), // 'SIZE_COUNT',
                getPackCode(y.packId), // 'PACK',
                stock.packDate ? moment(stock.packDate).format(DATE_FORMAT_CSV) : '', // 'PACK_DATE',
                'N', // 'PRE_PACK_TREATMENT',
                '0', // 'INSP_SAMPLE_WEIGHT',
                pack ? pack.nettWeight.toString() : '', // 'NETT_WEIGHT_PACK',
                pack ? pack.grossWeight.toString() : '', // 'GROSS_WEIGHT_PACK',
                'C', // 'UNIT_TYPE',
                y.cartons ? y.cartons.toString() : '', // 'CARTONS',
                y.palletSize ? y.palletSize.toString() : '', // 'PLT_QTY',
                getRegionCode(stock.regionId), // 'TARG_REGION',
                getCountryCode(stock.countryId), // 'TARG_COUNTRY',
                'N', // 'PHYTO_IND', N
                '', // 'PROTOCOL_EXCEPTION_IND',
                'N', // 'INSP_STATUS', N
                '', // 'INSP_RESULT', // will be incoming data
                '', // 'REJECT_REASON1', // will be incoming data
                '', // 'REJECT_REASON2', // will be incoming data
                '', // 'REJECT_REASON3', // will be incoming data
                getSiteInspectionPointCode(stock.currentSiteId), // 'INSP_POINT',
                '', // 'INSPECTOR', // will be incoming data
                '', // 'INSP_DATE', // will be incoming data
                '', // 'INSP_TIME', // will be incoming data
                getPackCategoryCode(y.packId), // 'PACKAGE CATEGORY CODE',
            ]);
        });
    });
    return returnData;
};

export const getStatus = async (compliance : ICompliance | undefined, setLoading : (loading : boolean) => void, setMessage : (message : string) => void, requestExportCertificate ?: (compliance : ICompliance) => void) => {
    if (!compliance) return;
    const state = getState();
    const auth = state.auth;

    let storages : Array<IConsignmentStorage> = [];
    setLoading(true);

    try {
        const res = await ConsignmentHttpService.getConsignmentData();
        storages = res.data;
    } catch (e) {
        generalShowErrorSnackbar('Could not fetch consignment data.');
    }
    const consignment = storages.find(x => x.consignmentNo === compliance.waybill);
    const filestr = consignment?.fileBase64;
    const consNo = consignment?.consignmentNo;
    const token = auth.ppecbToken?.token;
    const fileName = consignment?.fileName;

    if (!token) {
        generalShowErrorSnackbar('Could not find PPECB token');
        setLoading(false);
        return;
    }

    if (!consignment || !consNo) {
        generalShowErrorSnackbar('Could not find consignment');
        setLoading(false);
        return;
    }

    getConsignmentResult(consNo).then((result) => {
        if (result.status === 200) {
            const consignmentResponse : IConsignmentResponse = result.data;

            const reader = new FileReader();
            // @ts-ignore
            reader.onload = (event : { target : { result : string } | null}) => {
                const csvData = PapaParse.parse(
                    // @ts-ignore
                    event.target.result,
                    Object.assign({}, papaparseOptions),
                );
                const data : Array<IConsignmentCSVJson> = csvData.data;
                data.forEach((x) => {
                    const matchingLine = consignmentResponse.consignmentLines.find(y => y.sscc.replace('\'', '') === x.SSCC.replace('\'', ''));
                    x.UPN = consignmentResponse.upn;
                    x.INSP_STATUS = 'Y';
                    x.INSP_RESULT =  matchingLine?.result === 'Pass' ? 'Y' : 'N';
                    x.REJECT_REASON1 = matchingLine?.rejectionReasons && matchingLine.rejectionReasons[0] ? matchingLine.rejectionReasons[0].reason : '';
                    x.REJECT_REASON2 = matchingLine?.rejectionReasons && matchingLine.rejectionReasons[1] ? matchingLine.rejectionReasons[1].reason : '';
                    x.REJECT_REASON3 = matchingLine?.rejectionReasons && matchingLine.rejectionReasons[2] ? matchingLine.rejectionReasons[2].reason : '';
                    x.INSP_DATE =  Number(moment(moment.now()).format(DATEPICKER_FORMAT_DEFAULT).replace(/-/g, ''));
                    x.INSP_TIME = moment(moment.now()).format(TIME_FORMAT_CSV);
                    x.TRAN_TYPE = 206;
                    x.INSPECTOR = consignmentResponse.inspectorCode;
                });
                const csvString = csvObjToString(data);

                const blob = new Blob([csvString]);

                const newFilename = fileName?.replace('202', '206');
                // FileSaver.saveAs(blob, newFilename);
                const newConsignment = { ...consignment };
                newConsignment.fileName = newFilename || '206.csv';
                newConsignment.is202 = false;
                newConsignment.is206 = true;
                const newReader = new FileReader();
                newReader.onload = async () => {
                    const fileStr = newReader.result;

                    if (typeof fileStr === 'string') {
                        newConsignment.fileUpload = fileStr;
                        try {
                            const res = await ConsignmentHttpService.addOrUpdateConsignment(newConsignment);

                            await updateCompliance(data, setLoading, requestExportCertificate);

                            if (res && res.data) {
                                generalShowSuccessSnackbar('Consignment successfully uploaded to database.');
                            } else {
                                generalShowErrorSnackbar('An error occurred saving the consignment to database.');
                            }
                        } catch (e) {
                            generalShowErrorSnackbar('An error occurred saving the consignment to database.');
                        } finally {
                            setLoading(false);
                        }
                    }
                };
                newReader.readAsDataURL(blob);
            };

            if (filestr) {
                const byteCharacters = atob(filestr.split('base64,')[1]);
                const byteNumbers = lodash.map(byteCharacters, (x, i) => byteCharacters.charCodeAt(i));
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'text/csv' });
                reader.readAsText(blob);
                setLoading(false);
            }

        } else if (result.status === 204) {
            generalShowInfoSnackbar('This consignment has not been inspected yet.');
            setLoading(false);
        } else {
            setMessage(JSON.stringify(result.data));
            setLoading(false);
            generalShowErrorSnackbar('Could not get consignment status.');
        }

    }).catch(() => {
        setLoading(false);
        generalShowErrorSnackbar('Could not get consignment status.');
    });
};

export const updateCompliance = async (data : Array<IConsignmentCSVJson>, setLoading : (loading : boolean) => void, requestExportCertificate ?: (compliance : ICompliance) => void) => {
    const state = getState();
    const compliances = state.data.compliances;
    const stockData = state.data.stocks;

    let compliance : ICompliance | undefined;
    let oldCompliance : ICompliance | undefined;
    if (data.some(x => x.TRAN_TYPE.toString() !== '206')) {
        generalShowErrorSnackbar('This is not a valid 206 file, some of the lines don\'t have a transaction type of 206');
        return;
    }
    if (data.some(x => (x.INSP_RESULT !== 'Y') && (x.INSP_RESULT !== 'N'))) {
        generalShowErrorSnackbar('This is not a valid 206 file, some of the lines have no inspection result');
        return;
    }

    data.forEach((x) => {
        if (!compliance) {
            compliance = compliances.find(y => y.waybill === x.CONS_NO);
            oldCompliance = compliance;
            if (compliance) {
                compliance.upn = x.UPN;
                compliance.inspectorId = x.INSPECTOR?.toString();
            }
        }
        if (compliance) {
            const stock = stockData.find(z => (z.barcode === x.SSCC.replace('\'', '')) || (z.barcode === `'${x.SSCC}`));
            if (stock) {
                const line = compliance.complianceLines.filter(y => y.isActive).find(y => y.stockId === stock.id);
                if (line) {
                    const date = moment(x.INSP_DATE + ' ' + x.INSP_TIME, DATE_FORMAT_CSV + ' ' + TIME_FORMAT_CSV);
                    const inspectedOn = moment(date).utc().format(DATE_FORMAT_DEFAULT);
                    line.inspectedOn = inspectedOn;
                    line.isInspected = x.INSP_STATUS === 'Y';
                    line.isRejected = x.INSP_RESULT !== 'Y';
                    line.rejectReason1 = x.REJECT_REASON1;
                    line.rejectReason2 = x.REJECT_REASON2;
                    line.rejectReason3 = x.REJECT_REASON3;
                    line.updatedOn = undefined;
                }
            }
        }
    });
    if (compliance && oldCompliance) {
        compliance.updatedOn = undefined;
        compliance.status = data.some(x => x.INSP_RESULT !== 'Y') ? 'Partially Rejected' : 'Approved';
        try {
            const res = await ComplianceHttpService.addOrUpdateCompliance(compliance);

            if (res && res.data) {
                dataSetCompliance(res.data);
                generalShowSuccessSnackbar('Compliance successfully imported.');
                setLoading(false);
            } else {
                generalShowErrorSnackbar('An error occurred importing the compliance.');
                if (oldCompliance) dataSetCompliance(oldCompliance);
                setLoading(false);
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred importing the compliance.');
            if (oldCompliance) dataSetCompliance(oldCompliance);
            setLoading(false);
        }
    }
    if (compliance && requestExportCertificate) {
        requestExportCertificate(compliance);
    }
};
