import * as React from 'react';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetContactInfos } from '../../../store/masterData/Actions';
import { formatDateTime, isNullOrWhiteSpace, upsertArrayElement } from '../../../services/appFunctionsService';
import { IContactInfo, ContactInfo } from '../../../@types/model/masterData/contactInfo/contactInfo';
import ContactInfoHttpService from '../../../services/http/masterData/contactInfoHttpService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import Screen from '../../../components/Screen';
import { Formik, FormikHelpers } from 'formik';
import ContactInfoForm from './form/ContactInfoForm';
import { ContactInfoFormValues, IContactInfoFormValues } from '../../../@types/model/masterData/contactInfo/contactInfoFormValues';
import { createSelector } from 'reselect';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import { ConfirmationDialog, CustomTable } from '@zz2/zz2-ui';
import { v4 as uuidv4 } from 'uuid';
import { setContactInfoMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface IContactInfoScreenProps extends RouteComponentProps {
    dataSetContactInfos : DispatchCall<Array<IContactInfo>>;
    contactInfos : Array<IContactInfo>;
    auth : IAuthState;
}

interface IContactInfoScreenState {
    rows : Array<IContactInfo>;
    isLoading : boolean;
    selectedContactInfo ?: IContactInfo;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    formGuid ?: string;
    isDeletePopupOpen : boolean;
    deletingContactInfo ?: IContactInfo;
}

class ContactInfoScreen extends React.Component<IContactInfoScreenProps, IContactInfoScreenState> {
    constructor(props : IContactInfoScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedContactInfo: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await ContactInfoHttpService.getContactInfoData();

                this.props.dataSetContactInfos(res.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading contactInfos.');
                this.setLoading(false);
            }
        }
    };

    private onContactInfoEdit = (row : IContactInfo) => {
        this.setState({
            isDialogOpen: true,
            selectedContactInfo: row,
        });
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    private getRows = () => this.props.contactInfos;

    public onSubmit = async (values : IContactInfoFormValues) => {
        this.setLoading(true);

        let payload = { ...values };
        if (isNullOrWhiteSpace(payload.guid)) {
            payload.guid = this.state.formGuid ?? '';
        }

        try {
            const res = await ContactInfoHttpService.addOrUpdateContactInfo(new ContactInfo(payload));

            const newContactInfoList = upsertArrayElement(this.props.contactInfos, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetContactInfos(newContactInfoList);
            await setContactInfoMasterDataIndexedDB(newContactInfoList);

            if (this.state.selectedContactInfo) {
                generalShowSuccessSnackbar('ContactInfo updated successfully.');
            } else {
                generalShowSuccessSnackbar('ContactInfo added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating contact info data.');
        } finally {
            this.setLoading(false);
        }
    };

    public onReset = async (formValues : IContactInfoFormValues, formikHelpers : FormikHelpers<IContactInfoFormValues>) => {
        formikHelpers.resetForm();
        this.closeDialog();
    };

    private openDeleteConfirmationPopup = (contactInfo : IContactInfo) => {
        this.setState({ isDeletePopupOpen: true, deletingContactInfo: contactInfo });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingContactInfo: undefined });
    };

    private removeContactInfo = async () => {
        const newContactInfo = this.state.deletingContactInfo;
        if (newContactInfo) {
            newContactInfo.isActive = false;
            this.setLoading(true);

            try {
                const res = await ContactInfoHttpService.deleteContactInfo(newContactInfo.id);

                const newContactInfoList = upsertArrayElement(this.props.contactInfos, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetContactInfos(newContactInfoList);
                await setContactInfoMasterDataIndexedDB(newContactInfoList);

                generalShowSuccessSnackbar('ContactInfo updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting contactInfo.');
                newContactInfo.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    public getSelectedContactInfo = (props : IContactInfoScreenProps, state : IContactInfoScreenState) => state.selectedContactInfo;

    public getInitialFormValues = createSelector(
        [this.getSelectedContactInfo],
        (contactInfo : IContactInfo) => {
            return new ContactInfoFormValues(contactInfo);
        },
    );

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            formGuid: undefined,
            selectedContactInfo: undefined,
        });
    };

    public openDialog = () => {
        this.setState({
            formGuid: uuidv4(),
            isDialogOpen: true,
        });
    };

    private getRights = (props : IContactInfoScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IContactInfoScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.getRows();
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='ContactInfo'
                    isEdit={!!this.state.selectedContactInfo}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}
                    fullScreen={true}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={ContactInfoFormValues.formSchema}
                        component={ContactInfoForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IContactInfo>
                            enableAdding={this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.onContactInfoEdit}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(contactInfo : IContactInfo) => contactInfo.isActive && this.hasEditingRight(this.props)}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            enablePagination
                            columns={[
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Address', field: 'address', enableFiltering: true, enableSorting: true },
                                { title: 'Tel Number', field: 'telNumber', enableFiltering: true, enableSorting: true },
                                { title: 'Fax Number', field: 'faxNumber', enableFiltering: true, enableSorting: true },
                                { title: 'Email', field: 'email', enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IContactInfo) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationDialog
                    title={'Delete Contact Info'}
                    open={this.state.isDeletePopupOpen}
                    description ={'Are you sure you want to delete this contact info?'}
                    onAccept ={this.removeContactInfo}
                    onClose ={this.closeDeleteConfirmationPopup}
                    dialogType='orange'
                    isLoading={this.state.isLoading}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        contactInfos: state.masterData.contactInfos,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetContactInfos }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ContactInfoScreen);
