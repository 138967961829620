import * as React from 'react';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetDepartments } from '../../../store/masterData/Actions';
import { formatDateTime, isNullOrWhiteSpace, upsertArrayElement } from '../../../services/appFunctionsService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import Screen from '../../../components/Screen';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikHelpers } from 'formik';
import { createSelector } from 'reselect';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import { Department, IDepartment } from '../../../@types/model/masterData/department/department';
import DepartmentHttpService from '../../../services/http/masterData/departmentHttpService';
import { DepartmentFormValues, IDepartmentFormValues } from '../../../@types/model/masterData/department/departmentFormValues';
import DepartmentForm from './form/DepartmentForm';
import { ConfirmationDialog, CustomTable } from '@zz2/zz2-ui';
import { v4 as uuidv4 } from 'uuid';
import { setDepartmentMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface IDepartmentScreenProps extends RouteComponentProps {
    dataSetDepartments : DispatchCall<Array<IDepartment>>;
    departmentData : Array<IDepartment>;
    auth : IAuthState;
}

interface IDepartmentScreenState {
    rows : Array<IDepartment>;
    isLoading : boolean;
    selectedDepartment ?: IDepartment;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    formGuid ?: string;
    isDeletePopupOpen : boolean;
    deletingDepartment ?: IDepartment;
}

class DepartmentScreen extends React.Component<IDepartmentScreenProps, IDepartmentScreenState> {
    constructor(props : IDepartmentScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedDepartment: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await DepartmentHttpService.getDepartmentData();

                this.props.dataSetDepartments(res.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading departments.');
                this.setLoading(false);
            }
        }
    };

    public editDepartment = (department : IDepartment) => {
        this.setState({
            isDialogOpen: true,
            selectedDepartment: department,
        });
        this.openDialog();
    };

    public openDialog = () => {
        this.setState({
            formGuid: uuidv4(),
            isDialogOpen: true,
        });
    };

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            formGuid: undefined,
            selectedDepartment: undefined,
        });
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    public getSelectedDepartment = (props : IDepartmentScreenProps, state : IDepartmentScreenState) => state.selectedDepartment;

    public getInitialFormValues = createSelector(
        [this.getSelectedDepartment],
        (department : IDepartment) => {
            return new DepartmentFormValues(department);
        },
    );

    public onReset = async (formValues : IDepartmentFormValues, formikHelpers : FormikHelpers<IDepartmentFormValues>) => {
        formikHelpers.resetForm();
        this.closeDialog();
    };

    public onSubmit = async (values : IDepartmentFormValues) => {
        this.setLoading(true);

        let payload = { ...values };
        if (isNullOrWhiteSpace(payload.guid)) {
            payload.guid = this.state.formGuid ?? '';
        }

        try {
            const res = await DepartmentHttpService.addOrUpdateDepartment(new Department(payload));

            const newDepartmentList = upsertArrayElement(this.props.departmentData, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetDepartments(newDepartmentList);
            await setDepartmentMasterDataIndexedDB(newDepartmentList);

            if (this.state.selectedDepartment) {
                generalShowSuccessSnackbar('Department updated successfully.');
            } else {
                generalShowSuccessSnackbar('Department added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating department data.');
        } finally {
            this.setLoading(false);
        }
    };

    private openDeleteConfirmationPopup = (department : IDepartment) => {
        this.setState({ isDeletePopupOpen: true, deletingDepartment: department });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingDepartment: undefined });
    };

    private removeDepartment = async () => {
        const newDepartment = this.state.deletingDepartment;
        if (newDepartment) {
            newDepartment.isActive = false;
            this.setLoading(true);

            try {
                const res = await DepartmentHttpService.deleteDepartment(newDepartment.id);

                const newDepartmentList = upsertArrayElement(this.props.departmentData, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetDepartments(newDepartmentList);
                await setDepartmentMasterDataIndexedDB(newDepartmentList);

                generalShowSuccessSnackbar('Department updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting department.');
                newDepartment.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    private getRights = (props : IDepartmentScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IDepartmentScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.props.departmentData ? this.props.departmentData : [];
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Department'
                    isEdit={!!this.state.selectedDepartment}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={DepartmentFormValues.formSchema}
                        component={DepartmentForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IDepartment>
                            enableAdding={this.hasEditingRight(this.props)}
                            enableDeleting={(row : IDepartment) => row.isActive && this.hasEditingRight(this.props) && !row.isImported}
                            addFunction={this.openDialog}
                            editFunction={this.editDepartment}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableSorting
                            enableFiltering
                            fitWidthToPage
                            enablePagination
                            columns={[
                                { title: 'Code', field: 'code', enableFiltering: true, enableSorting: true },
                                { title: 'Short Description', field: 'shortDescription', enableFiltering: true, enableSorting: true },
                                { title: 'Description', field: 'description', enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Imported?', field: 'isImported', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IDepartment) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationDialog
                    title={'Delete Department'}
                    open={this.state.isDeletePopupOpen}
                    description ={'Are you sure you want to delete this this department?'}
                    onAccept ={this.removeDepartment}
                    onClose ={this.closeDeleteConfirmationPopup}
                    dialogType='orange'
                    isLoading={this.state.isLoading}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        departmentData: state.masterData.departments,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetDepartments }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DepartmentScreen);
