import * as React from 'react';
import { IRootState, DispatchCall, RootAction, IAuthState } from '../../@types/redux';
import { connect } from 'react-redux';
import { Paper, IconButton, Button } from '@mui/material';
import { formatDateTime, formatDateTimeToDateOnly, compareDate, upsertArrayElement, addkg } from '../../services/appFunctionsService';
import CustomTable, { ICustomTableColumn } from '../../components/datagrid/CustomTable';
import StockForm from './StockForm';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import materialTheme from '../../styles/materialTheme';
import { dataSetAllStockViewRelatedData, dataSetStockView, mapDashboardDispatchLines } from '../../store/data/Functions';
import moment from 'moment';
import {
    dataSetDashboardDispatchSelectedLoadDate,
    dataSetDashboardDispatchSelectedDispatchInstructionId,
    dataSetDashboardShowOnlyMyDispatches,
    dataSetStocks,
    dataSetMaterialStocks,
    dataSetSelectedInventory,
    dataSetStockDataViews,
} from '../../store/data/Actions';
import { Dispatch, bindActionCreators } from 'redux';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { ISize } from '../../@types/model/masterData/size/size';
import { IFarm } from '../../@types/model/masterData/farm/farm';
import { IColour } from '../../@types/model/masterData/colour/colour';
import { IGrade } from '../../@types/model/masterData/grade/grade';
import { IStock } from '../../@types/model/stock/stock';
import { ISite } from '../../@types/model/masterData/site/site';
import { IMarket } from '../../@types/model/masterData/market/market';
import { IBrand } from '../../@types/model/masterData/brand/brand';
import { IRegion } from '../../@types/model/masterData/region/region';
import { ICountry } from '../../@types/model/masterData/country/country';
import { IPalletBaseType } from '../../@types/model/masterData/palletBaseType/palletBaseType';
import { IDispatchInstruction } from '../../@types/model/dispatch/dispatchInstruction';
import { IDashboardDispatchInstructionLine } from '../../@types/model/dispatch/dashboardDispatchInstructionLine';
import { IStockLine } from '../../@types/model/stock/stockLine';
import { IDispatchInstructionLine } from '../../@types/model/dispatch/dispatchInstructionLine';
import StockHttpService from '../../services/http/stock/stockHttpService';
import PackmanDialog from '../../components/dialog/PackmanDialog';
import StockSummary from './StockSummary';
import Screen from '../../components/Screen';
import TransactionFilter from '../../components/filters/BasicTransactionScreenFilter';
import { RouteComponentProps } from 'react-router';
import { createSelector } from 'reselect';
import { IRight } from '../../@types/model/user/right';
import { IMaterialStock } from '../../@types/model/materialStock/materialStock';
import { IInventory } from '../../@types/model/masterData/inventory/inventory';
import { getSelectedInventoryLocalStorage } from '../../services/localStorageService';
import { ICompliance } from '../../@types/model/compliance/compliance';
import { IOptionType } from '../../@types/helper';
import StockUpgradeDialog from './StockUpgradeDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPallet } from '@fortawesome/free-solid-svg-icons';
import { IStockView } from '../../@types/model/stock/stockView';
import { uniq } from 'lodash';
import PillButton from '../../components/input/PillButton';
import validationService from '../../services/validationService';
import CustomAutoSuggest from '../../components/input/CustomAutoSuggest';
import { IStorageUnit } from '../../@types/model/masterData/storageUnit/storageUnit';
import { IDropDownOptions } from '../../@types/other';
import { navPath, navStock } from '../../store/nav/Actions';
import { PackmanMultiLink } from '../../components/link/packmanMultiLink';
import { PackmanLink } from '../../components/link/packmanLink';
import CombineStockDialog from './CombineStockDialog';
import CustomTooltip from '../../components/tooltip/tooltip';
import { syncMasterData } from '../../services/masterDataSyncService';
import DeleteConfirmationDialog from '../../components/dialog/DeleteConfirmationDialog';

interface IStockScreenProps extends RouteComponentProps {
    dataSetDashboardDispatchSelectedLoadDate : DispatchCall<string>;
    dataSetDashboardDispatchSelectedDispatchInstructionId : DispatchCall<number | undefined>;
    dataSetDashboardShowOnlyMyDispatches : DispatchCall<boolean>;
    dataSetStocks : DispatchCall<Array<IStock>>;
    dataSetMaterialStocks : DispatchCall<Array<IMaterialStock>>;
    dataSetSelectedInventory : DispatchCall<IInventory | undefined>;
    dataSetStockDataViews : DispatchCall<Array<IStockView>>;
    selectedDispatchId ?: number;
    showOnlyMyDispatches : boolean;
    selectedLoadDate : string;
    organizations : Array<IOrganization>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    packs : Array<IPack>;
    sizes : Array<ISize>;
    farms : Array<IFarm>;
    colours : Array<IColour>;
    grades : Array<IGrade>;
    stockData : Array<IStock>;
    sites : Array<ISite>;
    storageUnits : Array<IStorageUnit>;
    markets : Array<IMarket>;
    brands : Array<IBrand>;
    regions : Array<IRegion>;
    countries : Array<ICountry>;
    auth : IAuthState;
    selectedOrganizationIds : Array<number> ;
    selectedSiteIds : Array<number> ;
    palletBaseTypes : Array<IPalletBaseType>;
    dispatchInstructions : Array<IDispatchInstruction>;
    compliances : Array<ICompliance>;
    dashboardDispatchInstructionLines : Array<IDashboardDispatchInstructionLine>;
    selectedExporterOrganization ?: IOrganization;
    inventoryData : Array<IInventory>;
    selectedInventory ?: IInventory;

    StockDataViews : Array<IStockView>;
}

interface IStockScreenState {
    isLoading : boolean;
    isFiltering : boolean;
    searchValue : string;
    selectedStockId ?: number;
    selectedStockBarcode ?: string;
    checkedStockIds : Array<number>;
    editingStock ?: IStockView;
    editingStockLine ?: IStockLine;
    isAdding : boolean;
    isEditingStock : boolean;
    isEditingStockLine : boolean;
    isShowingDetails : boolean;
    isShowingStockSummary : boolean;
    collapseDraftColumn : boolean;
    showAdvanced : boolean;
    selectedLoadDate : string;
    checkedStock ?: IStock;
    selectedOrganization ?: number;
    selectedDestinationSite ?: number;
    dispatchInstructions : Array<IDispatchInstruction>;
    showRemoveDispatchLineConfirmationPrompt : boolean;
    showRemoveDashboardDispatchLineConfirmationPrompt : boolean;
    expandedLines : Array<{ instructionId : number; index : number }>;
    lineToRemove ?: IDispatchInstructionLine;
    dashboardLineToRemove ?: IDashboardDispatchInstructionLine;
    columns : Array<ICustomTableColumn>;
    isDeletePopupOpen : boolean;
    isStorageUnitPopupOpen : boolean;
    isMutliStockBreakDownIntoLotsDialogOpen : boolean;
    stockToDelete ?: IStockView;
    stockToUpgrade ?: IStockView;
    selectedUpgradeStockOrganization ?: IOptionType;
    selectedStorageUnit ?: number;
    selectedUpgradeStockSite ?: IOptionType;
    selectedUpgradeStockBrand ?: IOptionType;
    selectedUpgradeStockTargetMarket ?: IOptionType;
    selectedUpgradeStockTargetCountry ?: IOptionType;
    upgradeStockGrossWeight : number;
    deleteReason : string;
    isCombineStockDialogOpen : boolean;

    open : boolean;
    selectedFromDate : moment.Moment;
    selectedToDate : moment.Moment;
}

class StockScreen extends React.Component<IStockScreenProps, IStockScreenState> {
    private anchorEl : any;

    constructor(props : IStockScreenProps) {
        super(props);

        this.state = {
            isLoading: false,
            isFiltering: false,
            searchValue: '',
            selectedStockId: undefined,
            selectedStockBarcode: '',
            editingStock: undefined,
            editingStockLine: undefined,
            isAdding: false,
            isEditingStock: false,
            collapseDraftColumn : true,
            isEditingStockLine: false,
            selectedLoadDate: this.props.selectedLoadDate,
            isShowingDetails: false,
            isShowingStockSummary: false,
            isMutliStockBreakDownIntoLotsDialogOpen: false,
            showAdvanced: true,
            dispatchInstructions: [],
            showRemoveDispatchLineConfirmationPrompt: false,
            showRemoveDashboardDispatchLineConfirmationPrompt: false,
            checkedStockIds: [],
            columns: this.refreshColumns(),
            expandedLines: [],
            isDeletePopupOpen: false,
            isStorageUnitPopupOpen: false,
            upgradeStockGrossWeight: 0,
            deleteReason: '',
            isCombineStockDialogOpen: false,

            open: false,
            selectedFromDate: moment().local().startOf('day').subtract(7, 'days'),
            selectedToDate: moment().local().endOf('day'),
        };
    }

    private getDate = (type : 'from' | 'to') => {
        switch (type) {
            case 'from':
                return this.state.selectedFromDate.startOf('day').utc().unix() * 1000;
            case 'to':
                return this.state.selectedToDate.endOf('day').utc().unix() * 1000;
        }
    };

    public componentDidMount = async () => {
        if (this.props.location.pathname?.includes(':')) {
            this.showSummaryDialog(Number(this.props.location.pathname.split(':')[1].split('/')[0]));
        } else {
            if (!this.props.selectedSiteIds) return;

            this.setLoading(true);

            // checks if indexedDB is available.
            const isIndexedDBAvailable = !!self.indexedDB ? true : false;

            if (isIndexedDBAvailable) {
                await syncMasterData(false);
            }

            try {
                const selectedInventory = getSelectedInventoryLocalStorage();

                this.props.dataSetSelectedInventory(selectedInventory);

                const res = await StockHttpService.getStockScreenData(this.getDate('from'), this.getDate('to'), this.props.selectedSiteIds, !isIndexedDBAvailable);
                dataSetAllStockViewRelatedData(res.data);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred retrieving stock data.');
            } finally {
                this.setLoading(false);
            }
        }
    };

    public componentDidUpdate = (prevProps : IStockScreenProps) => {
        const nextProps = this.props;
        if (prevProps && nextProps) {
            if (prevProps.location.pathname?.includes(':') && !nextProps.location.pathname?.includes(':')) {
                this.closeSummaryDialog();
            }
            if (!prevProps.location.pathname?.includes(':') && nextProps.location.pathname?.includes(':')) {
                this.showSummaryDialog(Number(nextProps.location.pathname.split(':')[1].split('/')[0]));
            }
            if (prevProps.location.pathname?.includes(':') && nextProps.location.pathname?.includes(':')) {
                const prevId = prevProps.location.pathname.split(':')[1].split('/')[0];
                const nextId = nextProps.location.pathname.split(':')[1].split('/')[0];
                if (prevId !== nextId) {
                    this.showSummaryDialog(Number(nextId));
                }
            }
            if (nextProps.selectedSiteIds !== undefined && prevProps.selectedSiteIds !== nextProps.selectedSiteIds) {
                this.refreshData(true);
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading : loading });
    };

    private getSiteDescription = (siteId : number) => {
        const sites = this.props.sites;
        const site = sites && sites.find(x => x.id === siteId);
        return site ? site.description : '';
    };

    private getStorageUnitDescription = (storageUnitId : number) => {
        const storageUnits = this.props.storageUnits;
        const storageUnit = storageUnits && storageUnits.find(x => x.id === storageUnitId);
        return storageUnit ? storageUnit.description : '';
    };

    private getOrganizationName = (orgId : number) => {
        const organizations = this.props.organizations;
        const organization = organizations && organizations.find(x => x.id === orgId);
        return organization ? organization.name : '';
    };

    private setSelectedStockBarcode = async (barcode : string) => {
        this.setState({ selectedStockBarcode: barcode });
    };

    private onStockAdd = () => {
        this.setState({ isAdding : true });
    };

    private openDeleteConfirmationPopup = (stock : IStockView) => {
        this.setState({ isDeletePopupOpen: true, stockToDelete: stock });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, stockToDelete: undefined });
    };

    private removeStock = async (deleteReason : string) => {
        const newStock = this.state.stockToDelete;

        if (newStock) {
            if (deleteReason !== '' && deleteReason.length >= 200) {
                try {
                    this.setLoading(true);
                    const res = await StockHttpService.stockDelete(newStock.id, deleteReason);

                    if (res) {
                        newStock.isActive = false;
                        newStock.updatedByName = res.data.updatedByName;
                        newStock.updatedOn = res.data.updatedOn;
                        const newStockViewList = upsertArrayElement(this.props.StockDataViews, newStock, x => x.id === newStock.id) ?? [];

                        this.props.dataSetStockDataViews(newStockViewList);
                    }

                    generalShowSuccessSnackbar('Stock deleted successfully.');
                } catch (e) {
                    generalShowErrorSnackbar('An error occurred deleting stock.');
                    generalShowErrorSnackbar(e.data?.Message);
                } finally {
                    this.closeDeleteConfirmationPopup();
                    this.setLoading(false);
                }
            } else {
                generalShowErrorSnackbar('Reason for deleting this stock must be at least 200 characters.');
            }
        }
    };

    public showSummaryDialog = (id : number) => {
        this.setState({
            isShowingStockSummary: true,
            selectedStockId: id,
        });
    };

    public closeSummaryDialog = () => {
        this.setState({
            isShowingStockSummary: false,
            selectedStockId: undefined,
            selectedStockBarcode: '',
        }, () => navPath(this.props.location?.pathname?.split('/:')[0]));
    };

    private onStockEdit = (row : IStockView) => {
        this.setState({ isEditingStock: true, editingStock: row });
    };

    private onFormClose = () => {
        this.setState({
            isAdding: false,
            isEditingStock: false,
            editingStock: undefined,
        });
    };

    private refreshData = async (noAnnounce ?: boolean) => {
        try {
            this.setLoading(true);

            // checks if indexedDB is available.
            const isIndexedDBAvailable = !!self.indexedDB ? true : false;

            if (isIndexedDBAvailable) {
                await syncMasterData(false);
            }

            const res = await StockHttpService.getStockScreenData(this.getDate('from'), this.getDate('to'), this.props.selectedSiteIds, !isIndexedDBAvailable);

            dataSetAllStockViewRelatedData(res.data);
            if (!noAnnounce) {
                generalShowSuccessSnackbar('Data Refreshed');
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred retrieving stock related data.');
        } finally {
            this.setLoading(false);
        }
    };

    private getStocks = (props : IStockScreenProps) => props.StockDataViews;
    private getSelectedSites = (props : IStockScreenProps) => props.selectedSiteIds;

    private getRights = (props : IStockScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IStockScreenProps) => props.location.pathname;

    private getCheckedStockIds = (props : IStockScreenProps, state : IStockScreenState) => state.checkedStockIds;
    private getStorageUnits = (props : IStockScreenProps) => props.storageUnits;

    private getStockRows = createSelector(
        [this.getStocks, this.getSelectedSites],
        (stockData, selectedSiteIds : Array<number>) => {
            return stockData.filter(x => selectedSiteIds?.some(y => y === x.currentSiteId));
        },
    );

    private isChecked = (id : number) => this.state.checkedStockIds.some(x => x === id);

    private setCheckedStockIds = (checkedStockIds : Array<number>) => this.setState({ checkedStockIds });

    private openStockUpgradeDialog = (row : IStockView) => {
        this.setState({ stockToUpgrade: row });
    };

    private closeStockUpgradeDialog = () => {
        this.setState({ stockToUpgrade: undefined });
    };

    private getTotalCartons = (id : number) => {
        const stock = this.props.StockDataViews.find(x => x.id === id);

        return stock && !!stock.totalCartons ? stock?.totalCartons : '0';
    };

    private getTotalInners = (id : number) => {
        const stock = this.props.StockDataViews.find(x => x.id === id);

        return stock && !!stock.totalInners ? stock?.totalInners : '0';
    };

    private checkCodes = (value : string) => {
        const stringList : Array<string> = value?.split(', ');

        const filtered = stringList?.filter(x => !x.endsWith('_inactive')) ?? [];

        return filtered?.join(', ');
    };

    private formatDispatchCodes = (dispatchCode : string, row : IStockView) => {
        const codes = dispatchCode.split(', ');
        const ids = row.dispatchIds.split(', ');
        return <PackmanMultiLink
            type={'transactions'}
            targetPage={'dispatch'}
            ids={ids}
            texts={codes} />;
    };

    private formatWaybill = (waybill : string, row : IStockView) => {
        return <PackmanLink
            type={'transactions'}
            targetPage={'compliance'}
            id={Number(row.complianceId)}
            text={waybill}
            color={row.isRejected ? 'red' : undefined} />;
    };

    private refreshColumns = () => {
        const columns : Array<ICustomTableColumn> = [];
        columns.push(
            { title: 'Upgrade', field: 'id', formatFunction: this.isChecked, width: 100, disableExport: true,
                containerComponent: (row : IStockView) => {
                    return (
                        (!row.isActive || (!row.isTemporary && row.barcode))
                            ?
                            <div></div>
                            :
                            <CustomTooltip title={'Upgrade stock'}>
                                <div className={'wfill hfill aic'}>
                                    <IconButton onClick={() => this.openStockUpgradeDialog(row)}>
                                        <FontAwesomeIcon icon={faPallet} className={'cpd'} size={'sm'}/>
                                    </IconButton>
                                </div>
                            </CustomTooltip>
                    );
                },
            },
            { title: 'Pack Date', field: 'packDate', width: 140, formatFunction: formatDateTimeToDateOnly, sortFunction: compareDate, type : 'Date', enableFiltering: true, enableSorting: true },
            { title: 'Created On', field: 'createdOn', width: 150, formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
            { title: 'Barcode', field: 'barcode', width: 200, enableFiltering: true, enableSorting: true },
            { title: 'Dispatch Code(s)', field: 'dispatchCode', containerComponent: (row : IStockView, value : string) => this.formatDispatchCodes(value, row), width: 200, enableFiltering: true, enableSorting: true },
            { title: 'Cartons', field: 'id', width: 120, formatFunction: this.getTotalCartons, enableFiltering: true, enableSorting: true },
            { title: 'Inners', field: 'id', width: 120, formatFunction: this.getTotalInners, enableFiltering: true, enableSorting: true },
            { title: 'Temp Barcode', field: 'tempBarcode', enableFiltering: true, enableSorting: true },
            { title: 'SSCC', field: 'sscc', enableFiltering: true, enableSorting: true },
            { title: 'Original Organization', field: 'originalOrganizationId', formatFunction: this.getOrganizationName, enableFiltering: true, enableSorting: true },
            { title: 'Original Site', field: 'originalSiteId', formatFunction: this.getSiteDescription, enableFiltering: true, enableSorting: true },
            { title: 'Original Storage Unit', field: 'originalStorageUnitId', formatFunction: this.getStorageUnitDescription, enableFiltering: true, enableSorting: true },
            { title: 'Current Organization', field: 'currentOrganizationId', width: 150 , formatFunction: this.getOrganizationName, enableFiltering: true, enableSorting: true },
            { title: 'Current Site', field: 'currentSiteId', formatFunction: this.getSiteDescription, enableFiltering: true, enableSorting: true },
            { title: 'Current Storage Unit', field: 'currentStorageUnitId', formatFunction: this.getStorageUnitDescription, enableFiltering: true, enableSorting: true },
            { title: 'Commodities', field: 'commodityCodes', width: 150, formatFunction: this.checkCodes, enableFiltering: true, enableSorting: true },
            { title: 'Varieties', field: 'varietyCodes', width: 150, formatFunction: this.checkCodes, enableFiltering: true, enableSorting: true },
            { title: 'Colours', field: 'colourCodes', width: 110, formatFunction: this.checkCodes, enableFiltering: true, enableSorting: true },
            { title: 'Grades', field: 'gradeCodes', width: 110, formatFunction: this.checkCodes, enableFiltering: true, enableSorting: true },
            { title: 'Packs', field: 'packCodes', width: 150, formatFunction: this.checkCodes, enableFiltering: true, enableSorting: true },
            { title: 'Sizes', field: 'sizeCodes', width: 150, formatFunction: this.checkCodes, enableFiltering: true, enableSorting: true },
            { title: 'Pallet Base Type', field: 'palletBaseTypeName', enableFiltering: true, enableSorting: true },
            { title: 'Target Market', field: 'marketName', enableFiltering: true, enableSorting: true },
            { title: 'Farm(s)', field: 'farmCodes', formatFunction: this.checkCodes, enableFiltering: true, enableSorting: true },
            { title: 'Status', field: 'status', enableFiltering: true, enableSorting: true },
            { title: 'Waybill', field: 'waybill', containerComponent: (row : IStockView, value : string) => this.formatWaybill(value, row), enableSorting: true, enableFiltering: true },
            { title: 'Rejected', field: 'isRejected', type: 'boolean', enableFiltering: true, enableSorting: true },
            { title: 'Brand', field: 'markDescription', enableFiltering: true, enableSorting: true },
            { title: 'Target Region', field: 'regionName', enableFiltering: true, enableSorting: true },
            { title: 'Target Country', field: 'countryName', enableFiltering: true, enableSorting: true },
            { title: 'Report File Id', field: 'reportFile', enableFiltering: true, enableSorting: true },
            { title: 'Gross Weight (kg)', field: 'grossWeight', formatFunction: addkg, enableFiltering: true, enableSorting: true },
            { title: 'Estimated Nett Weight (kg)', field: 'estimatedNettWeight', formatFunction: addkg, enableFiltering: true, enableSorting: true },
            { title: 'Estimated Gross Weight (kg)', field: 'estimatedGrossWeight', formatFunction: addkg, enableFiltering: true, enableSorting: true },
            { title: 'Has Mixed Load?', field: 'hasMixedLoad', type: 'boolean', enableFiltering: true, enableSorting: true },
            { title: 'Inventory Code', field: 'inventoryCode', width: 200, enableFiltering: true, enableSorting: true },
            { title: 'User Comment', field: 'userComment', enableFiltering: true, enableSorting: true },
            { title: 'Location Code', field: 'locationCode', enableFiltering: true, enableSorting: true },
            { title: 'Channel', field: 'channel', enableFiltering: true, enableSorting: true },
            { title: 'Time Created', field: 'timeCreated', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
            { title: 'Time Uploaded', field: 'timeUploaded', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
            { title: 'Time Printed', field: 'timePrinted', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
            { title: 'Inspected', field: 'isInspected', type: 'boolean', enableFiltering: true, enableSorting: true },
            { title: 'Replaced', field: 'isReplaced', type: 'boolean', enableFiltering: true, enableSorting: true },
            { title: 'Reject Reason 1', field: 'rejectReason1', enableFiltering: true, enableSorting: true },
            { title: 'Reject Reason 2', field: 'rejectReason2', enableFiltering: true, enableSorting: true },
            { title: 'Reject Reason 3', field: 'rejectReason3', enableFiltering: true, enableSorting: true },
            { title: 'Inspector Id', field: 'inspectorId', enableFiltering: true, enableSorting: true },
            { title: 'Inspected On', field: 'inspectedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
            { title: 'Upn', field: 'upn', enableFiltering: true, enableSorting: true },
            { title: 'Season', field: 'season', enableFiltering: true, enableSorting: true },
            { title: 'Imported From PO?', field: 'isImportedFromPO', type: 'boolean', enableFiltering: true, enableSorting: true },
            { title: 'Created In', field: 'createdIn', enableFiltering: true, enableSorting: true },
            { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
            { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
            { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
            { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true });

        return columns;
    };

    private handleDateRangeChange = (start : moment.Moment, end : moment.Moment, changedBy ?: 'start' | 'end') => {
        const selectedFromDate = changedBy === 'start' ? moment(start).startOf('day') : end < start ? moment(end).startOf('day') : moment(start).startOf('day');
        const selectedToDate = changedBy === 'end' ? moment(end).endOf('day') : start > end ? moment(start).endOf('day') : moment(end).endOf('day');
        this.setState({ selectedFromDate, selectedToDate });
    };

    private onApplyClick = async () => {
        await this.refreshData(true);

        this.setState({
            open: false,
            isLoading: false,
        });
    };

    private isStockEditDisabled = (row : IStockView) => {
        if (!this.props.selectedSiteIds?.some(x => x === row.currentSiteId)
        || (!!row.isStockLinkedToDispatch && !this.hasSuperAdminEditingRight(this.props))
        || (row.status === 'Broken Down' && !this.hasSuperAdminEditingRight(this.props))) {
            return true;
        } else if (!!(row.isStockLinkedToCompliance) && !row.isRejected) {
            if (row.complianceStatus !== 'Planned') {
                return true;
            } else if (row.complianceStatus === 'Planned' && row.isInspected) {
                return true;
            } else if (row.complianceStatus === 'Planned' && !row.isInspected && !!this.hasSuperAdminEditingRight(this.props)) {
                return false;
            } else {
                return true;
            }
        } else if (!!(row.isStockLinkedToCompliance) && row.isRejected && !this.hasSuperAdminEditingRight(this.props)) {
            return true;
        } else {
            return false;
        }
    };

    private addTooltipTitle = () => {
        let message : string | undefined = '';
        const selectedOrganizationIds = this.props.selectedOrganizationIds;
        const selectedSiteIds = this.props.selectedSiteIds;
        if (selectedSiteIds?.length === 0) {
            message = 'No site selected';
        } else if (selectedSiteIds?.length !== 1) {
            message = 'Please select only one site.';
        } else if (selectedOrganizationIds?.length !== 1) {
            message = 'please select only one organization.';
        } else {
            message = '';
        }
        return message;
    };

    private editTooltipTitle = (row : IStockView) => {
        let message : string | undefined = '';
        const selectedSiteIds = this.props.selectedSiteIds;
        if (selectedSiteIds?.length === 0) {
            message = 'No site selected';
        } else if (!selectedSiteIds?.some(x => x === row.currentSiteId)) {
            message = 'Stocks current site does not match selected site.';
        } else if (!!row.isStockLinkedToDispatch && !this.hasSuperAdminEditingRight(this.props)) {
            message = 'Stock is linked to a dispatch';
        } else if (row.status === 'Broken Down' && !this.hasSuperAdminEditingRight(this.props)) {
            message = 'Cannot edit Broken Down stock';
        } else if (!!row.isStockLinkedToCompliance && !row.isRejected) {
            if (row.complianceStatus !== 'Planned') {
                message = `Stock is linked to compliance ${row.waybill} and is not rejected`;
            } else if (row.complianceStatus === 'Planned' && !row.isInspected && !!this.hasSuperAdminEditingRight(this.props)) {
                message = undefined;
            } else {
                message = `Stock is linked to compliance ${row.waybill} and is not rejected`;
            }
        } else if (!!row.isStockLinkedToCompliance && row.isRejected && !this.hasSuperAdminEditingRight(this.props)) {
            message = `Stock is linked to compliance ${row.waybill}`;
        } else {
            message = undefined;
        }
        return message;
    };

    private deleteTooltipTitle = (row : IStockView) => {
        let message : string | undefined = '';
        const selectedSiteIds = this.props.selectedSiteIds;
        if (selectedSiteIds?.length === 0) {
            message = 'No site selected';
        } else if (!selectedSiteIds?.some(x => x === row.currentSiteId)) {
            message = 'Stocks current site does not match selected site';
        } else if (!!row.isStockLinkedToDispatch && !this.hasSuperAdminEditingRight(this.props)) {
            message = 'Stock is linked to a dispatch.';
        } else if (!!row.isStockLinkedToCompliance && !this.hasSuperAdminEditingRight(this.props)) {
            message = 'Stock is linked to a compliance.';
        } else {
            message = undefined;
        }
        return message;
    };

    private isStockDeleteDisabled = (row : IStockView) => {
        return !this.props.selectedSiteIds?.some(x => x === row.currentSiteId)
            || (!!row.isStockLinkedToDispatch && !this.hasSuperAdminEditingRight(this.props))
            || (!!row.isStockLinkedToCompliance && !this.hasSuperAdminEditingRight(this.props));
    };

    private setSelectedInventory = (inventory ?: IInventory) => {
        this.props.dataSetSelectedInventory(inventory);
    };

    private onBreakDownConfirmationYesClick = async () => {
        const stockIds = [...this.state.checkedStockIds];

        if (stockIds.length > 0) {
            try {
                this.setLoading(true);
                const res = await StockHttpService.breakDownMultipleStocksIntoLots(stockIds);

                if (res && res.data) {
                    res.data.forEach((stock) => {
                        dataSetStockView(stock);
                    });
                    this.setState({ checkedStockIds: [] });
                    generalShowSuccessSnackbar('Stock broken down successfully.');
                }
            } catch (e) {
                if (e.status === 400) {
                    generalShowErrorSnackbar(e.data?.Message);
                } else {
                    generalShowErrorSnackbar('An error breaking down the stocks into lots.');
                }
            } finally {
                this.closeMultiStockBreakDownIntoLotsPopup();
                this.setLoading(false);
            }
        } else {
            generalShowErrorSnackbar('Selected stock not found.');
        }
    };

    private onMultiStockBreakDownIntoLotsConfirmationNoClick = async () => {
        this.setState({ isMutliStockBreakDownIntoLotsDialogOpen: false });
    };

    private onMutliStockBreakDownIntoLotsClick = async () => {
        this.setState({ isMutliStockBreakDownIntoLotsDialogOpen: true });
    };

    private closeMultiStockBreakDownIntoLotsPopup = () => this.setState({ isMutliStockBreakDownIntoLotsDialogOpen: false });

    private disableMultiStockBreakDownIntoLotsButton = createSelector(
        [this.getCheckedStockIds, this.getStocks],
        (checkedStockIds : Array<number>, stocks) => {
            const checkedStocks = stocks.filter(x => checkedStockIds.some(y => y === x.id));

            if (checkedStocks.some(x => x.status !== 'In Stock')) {
                return true;
            }
            if (checkedStocks.some(x => x.complianceStatus === 'Approved')) {
                return true;
            }

            return false;
        },
    );

    private openStorageUnitPopup = () => this.setState({ isStorageUnitPopupOpen: true });
    private closeStorageUnitPopup = () => this.setState({ isStorageUnitPopupOpen: false });

    private sendStockToStorageUnit = async () => {
        const stockIds = [...this.state.checkedStockIds];
        const newStorageUnitId = this.state.selectedStorageUnit ?? 0;
        this.setLoading(true);
        if ((stockIds.length > 0) && !!this.state.selectedStorageUnit) {
            try {
                const res = await StockHttpService.sendStocksToStorageUnit(stockIds, newStorageUnitId);

                if (res && res.data) {
                    const newStocks = res.data;
                    let stockData = [...this.props.stockData];
                    stockData.forEach((stock) => {
                        const newStock = newStocks.find(x => x.id === stock.id);
                        if (newStock) {
                            stock.currentStorageUnitId = newStorageUnitId;
                            stock.updatedByName = newStock?.updatedByName;
                            stock.updatedOn = newStock?.updatedOn;
                        }
                        stockData = upsertArrayElement(stockData, stock, x => x.id === stock.id) ?? [];
                    });

                    let stockViews = [...this.props.StockDataViews];
                    stockViews.forEach((stock) => {
                        const newStock = newStocks.find(x => x.id === stock.id);
                        if (newStock) {
                            stock.currentStorageUnitId = newStorageUnitId;
                            stock.updatedByName = newStock?.updatedByName;
                            stock.updatedOn = newStock?.updatedOn;
                        }
                        stockViews = upsertArrayElement(stockViews, stock, x => x.id === stock.id) ?? [];
                    });
                    this.props.dataSetStocks(stockData);
                    this.props.dataSetStockDataViews(stockViews);
                    this.setState({ checkedStockIds: [] });
                    generalShowSuccessSnackbar('Stock moved successfully.');
                } else {
                    generalShowErrorSnackbar('Selected stock or storage unit not found');
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred moving the stock to a storage unit.');
            } finally {
                this.closeStorageUnitPopup();
                this.setLoading(false);
            }
        } else {
            generalShowErrorSnackbar('Selected stock or storage unit not found');
            this.setLoading(false);
        }
    };

    private openCombineStockDialog = () => {
        this.setState({ isCombineStockDialogOpen: true });
    };

    private closeCombineStockDialog = () => {
        this.setState({ isCombineStockDialogOpen: false });
    };

    private storageUnitOptions = createSelector([this.getStocks, this.getCheckedStockIds, this.getStorageUnits],
        (stockData, checkedStockIds, storageUnits) => {
            const returnValue : Array<IDropDownOptions> = [];
            const siteIds = uniq(stockData.filter(x => checkedStockIds.some(y => y === x.id)).map(x => x.currentSiteId));
            returnValue.push({ value: '', label: 'Select Storage Unit', disabled: true });
            storageUnits.filter(x => siteIds.some(y => x.siteId === y)
                && x.hasPackedStock
                && x.isActive)
                .forEach(x => returnValue.push({ value: x.id, label: `(${x.code}) ${x.description}` }));
            return returnValue;
        });

    private onlyOneSiteSelected = createSelector([this.getStocks, this.getCheckedStockIds],
        (stockData, checkedStockIds) => uniq(stockData.filter(x => checkedStockIds.some(y => y === x.id)).map(x => x.currentSiteId)).length === 1);

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    private hasSuperAdminEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT_SUPER_ADMIN')));

    private hasStockBreakDownRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => {
            return rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_BREAK_DOWN'));
        });

    private hasStockBreakDownIntoLotsRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => {
            return rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_BREAK_DOWN_INTO_LOTS'));
        });

    private hasStockCombineRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => {
            return rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_COMBINE_STOCK'));
        });
    private getCheckedStockBarcodes = createSelector(
        [this.getStocks, this.getCheckedStockIds],
        (stocks, checked) => {
            return checked.map(x => <div>{stocks.find(y => y.id === x)?.barcode}</div>);
        }
    );

    public render() {
        const addTooltip : string = this.addTooltipTitle();
        const hasEditingRight = this.hasEditingRight(this.props);
        const hasSuperAdminEditRight = this.hasSuperAdminEditingRight(this.props);
        const hasStockBreakDownRight = this.hasStockBreakDownRight(this.props);
        const hasStockBreakDownIntoLotsRight = this.hasStockBreakDownIntoLotsRight(this.props);
        return (
            <Screen isPadded={false} isLoading={this.state.isLoading} isScrollable={false}>
                <div className={'fdc hfill'}>
                    <div className={'fdr jcsb aic ml20'}>
                        <CustomTooltip title={this.props.selectedSiteIds?.length !== 1 ? 'Please select only one site' : ''}>
                            <PillButton
                                color={'secondary'}
                                className={'h30 mt20'}
                                text={'Combine'}
                                onClick={this.openCombineStockDialog}
                                disabled={!!this.state.isLoading || !this.hasStockCombineRight(this.props) || !this.props.selectedSiteIds || this.props.selectedSiteIds.length !== 1}
                            />
                        </CustomTooltip>
                        <TransactionFilter className={'pt10 mt10 mr20 mb10'} selectedFromDate={this.state.selectedFromDate} selectedToDate={this.state.selectedToDate} handleDateRangeChange={this.handleDateRangeChange} onApplyClick={this.onApplyClick} />
                    </div>
                    {/* Stock Table */}
                    <Paper className={'mr20 ml20 mt10 mb10 hfill oxh'}>
                        <CustomTable<IStockView>
                            editFunction={this.onStockEdit}
                            enableAdding={!this.state.isLoading && (this.props.selectedSiteIds?.length > 0) && hasEditingRight}
                            disableAddButton={(this.props.selectedSiteIds?.length !== 1 || this.props.selectedOrganizationIds?.length !== 1)}
                            addTooltip={addTooltip}
                            addFunction={this.onStockAdd}
                            enableEditing={hasEditingRight}
                            disableEditButton={this.isStockEditDisabled}
                            editTooltip={this.editTooltipTitle}
                            editColor={materialTheme.palette.primary.main}
                            enableSorting
                            enableFiltering
                            enableClearFilterButton
                            enablePagination
                            enableHorizontalVirtualize
                            enableRefresh
                            enableDetails={true}
                            detailIcon={'info'}
                            detailTooltip={'Stock Summary'}
                            detailFunction={row => navStock(row.id)}
                            detailsColor={materialTheme.palette.primary.main}
                            enableDeleting={(row : IStockView) => row.isActive && hasEditingRight && (this.props.selectedSiteIds?.length > 0)}
                            deleteColor={materialTheme.palette.primary.main}
                            deleteTooltip={this.deleteTooltipTitle}
                            disableDeleteButton={this.isStockDeleteDisabled}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            disableRefreshButton={this.state.isLoading}
                            refreshFunction={this.refreshData}
                            columns={this.state.columns}
                            rows={this.getStockRows(this.props)}
                            initialSortOrder={[{ columnName: 'createdOn_Created On', direction : 'desc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            isActive={(row : IStockView) => row.isActive}
                            warning={(row : IStockView) => row.status === 'Broken Down'}
                            pageHeight={300}
                            autoRowHeight
                            enableSelection
                            onSelectChange={this.setCheckedStockIds}
                            disableRowSelect={row => !row.isActive}
                        />
                    </Paper>
                    <div className='flx1'/>
                    <div className='fdr ml20 mr20 mb10 mt10'>
                        <div className={'flx1'} />
                        {hasStockBreakDownIntoLotsRight &&
                            <CustomTooltip title={this.disableMultiStockBreakDownIntoLotsButton(this.props, this.state) || this.state.checkedStockIds.length === 0 ? 'Must have stocks selected that are In Stock and not yet approved.' : ''}>
                                <PillButton
                                    className={'ml15 pl20 pr20 h35'}
                                    text={'Break down selected stocks into lots'}
                                    color={'secondary'}
                                    onClick={this.onMutliStockBreakDownIntoLotsClick}
                                    disabled={this.state.isLoading || this.disableMultiStockBreakDownIntoLotsButton(this.props, this.state) ||  this.state.checkedStockIds.length === 0}
                                />
                            </CustomTooltip>
                        }
                        <PillButton
                            disabled={this.state.isLoading || !hasEditingRight || (this.state.checkedStockIds.length === 0) || !this.onlyOneSiteSelected(this.props, this.state) || (this.storageUnitOptions(this.props, this.state).length === 1)}
                            className={'ml15 pl20 pr20 h35'}
                            text={'Send selected stock to storage unit'}
                            color={'secondary'}
                            onClick={this.openStorageUnitPopup}
                        />
                    </div>
                    {(!!this.state.isAdding || !!this.state.isEditingStock) && <StockForm
                        selectedStockView={this.state.editingStock}
                        setLoading={this.setLoading}
                        refreshData={this.refreshData}
                        isAdding={this.state.isAdding}
                        isEditing={this.state.isEditingStock}
                        setSelectedInventory={this.setSelectedInventory}
                        filterFromDate={this.state.selectedFromDate}
                        filterToDate={this.state.selectedToDate}
                        onClose={this.onFormClose}
                        hasSuperAdminRight={hasSuperAdminEditRight}/>}

                    {/* Summary Dialog */}
                    {this.state.isShowingStockSummary &&
                        <PackmanDialog
                            title={`Stock Summary - ${this.state.selectedStockBarcode}`}
                            isInfo={true}
                            fullScreen={true}
                            isOpen={this.state.isShowingStockSummary}
                            onClose={this.closeSummaryDialog}>
                            {this.state.selectedStockId &&
                                <StockSummary
                                    selectedStockViewId={this.state.selectedStockId}
                                    showAdvanced={this.state.showAdvanced}
                                    closeSummary={this.closeSummaryDialog}
                                    setStockBarcode={this.setSelectedStockBarcode}
                                    hasEditingRight={hasEditingRight}
                                    hasSuperAdminEditRight={hasSuperAdminEditRight}
                                    hasStockBreakDownRight={hasStockBreakDownRight}
                                    hasStockBreakDownIntoLotsRight={hasStockBreakDownIntoLotsRight}
                                />
                            }
                        </PackmanDialog >
                    }
                </div>
                <PackmanDialog
                    title={'Multiple Stock Break Down Into Lots'}
                    isInfo
                    isWarning
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isMutliStockBreakDownIntoLotsDialogOpen}
                    onClose={this.onMultiStockBreakDownIntoLotsConfirmationNoClick}>
                    <div className={'fdc p20'}>
                        <div className={'fdr aic jcc pb5 fs16'}>{'Are you sure you want to break the selected stocks down into lots?'}</div>
                        <div className={'fdr aic jcc pb5 fwb fs16'}>{'This action can not be undone.'}</div>
                        <div className={'fdr ml10 ais jcfe mt20'}>
                            <Button
                                className={'fwb h35'}
                                variant='text' color='primary'
                                onClick={this.onMultiStockBreakDownIntoLotsConfirmationNoClick}
                            >
                                Cancel
                            </Button>
                            <PillButton
                                className={'ml15 pl20 pr20 h35'}
                                text={'OK'}
                                color={'secondary'}
                                onClick={this.onBreakDownConfirmationYesClick}
                            />
                        </div>
                    </div>
                </PackmanDialog>
                <PackmanDialog
                    title={'Upgrade Stock'}
                    maxWidth={'lg'}
                    isInfo
                    isLoading={this.state.isLoading}
                    isOpen={!!this.state.stockToUpgrade}
                    onClose={this.closeStockUpgradeDialog}>
                    {!!this.state.stockToUpgrade &&
                            <StockUpgradeDialog
                                stockToUpgrade={this.state.stockToUpgrade}
                                setLoading={isLoading => this.setLoading(isLoading)}
                                isLoading={this.state.isLoading}
                                closeStockUpgradeDialog={this.closeStockUpgradeDialog}
                                refreshData={() => this.refreshData(true)}/>
                    }
                </PackmanDialog>
                <PackmanDialog
                    title={'Send to Storage Unit'}
                    maxWidth={'lg'}
                    isInfo
                    isLoading={this.state.isLoading}
                    isOpen={!!this.state.isStorageUnitPopupOpen}
                    onClose={this.closeStorageUnitPopup}>
                    <div className={'fdc wfill hfill p10'}>
                        <CustomAutoSuggest
                            className={'wfill m5'}
                            options={this.storageUnitOptions(this.props, this.state)}
                            label={'Storage Unit'}
                            initialValue={this.state.selectedStorageUnit}
                            hasInitialValue={!!this.state.selectedStorageUnit}
                            validate={validationService.required}
                            disabled={this.state.isLoading}
                            validateOnBlur
                            validateOnChange
                            field={'storageUnit'}
                            value={this.state.selectedStorageUnit ?? 0}
                            onChange={(e : any) => this.setState({ selectedStorageUnit: e })}
                            enableDropDownIcon
                        />
                        <div className='fwb pt10'>Selected Stock:</div>
                        <div className='fdc pt10 pb10'>
                            {this.getCheckedStockBarcodes(this.props, this.state)}
                        </div>
                        <div className={'fdr pt10 pb10 wfill jcfe'}>
                            <Button
                                className={'fwb h35'}
                                variant='text'
                                color='primary'
                                onClick={this.closeStorageUnitPopup}>
                                    Cancel
                            </Button>
                            <PillButton
                                disabled={this.state.isLoading || !this.state.selectedStorageUnit}
                                className={'ml15 pl20 zi0 pr20 h35'}
                                text={'Submit'}
                                color={'secondary'}
                                onClick={this.sendStockToStorageUnit}
                            />
                        </div>
                    </div>
                </PackmanDialog>
                <PackmanDialog
                    title={'Combine Stock'}
                    maxWidth={'xl'}
                    isInfo
                    isLoading={this.state.isLoading}
                    isOpen={!!this.state.isCombineStockDialogOpen}
                    onClose={this.closeCombineStockDialog}>
                    <CombineStockDialog setLoading={this.setLoading} isLoading={this.state.isLoading} closeDialog={this.closeCombineStockDialog}/>
                </PackmanDialog>
                {!!this.state.isDeletePopupOpen &&
                    <DeleteConfirmationDialog
                        isLoading={this.state.isLoading}
                        onSubmit={this.removeStock}
                        onclose={this.closeDeleteConfirmationPopup}
                        isOpen={!!this.state.isDeletePopupOpen}
                        title={'Stock'}/>
                }
            </Screen>
        );
    }
}

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    {
        dataSetDashboardDispatchSelectedLoadDate,
        dataSetDashboardDispatchSelectedDispatchInstructionId,
        dataSetDashboardShowOnlyMyDispatches,
        dataSetStocks,
        dataSetMaterialStocks,
        dataSetSelectedInventory,
        dataSetStockDataViews,
    },
    dispatcher,
);

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        organizations: state.masterData.organizations,
        dispatchInstructions: state.data.dispatchInstructions,
        dashboardDispatchInstructionLines: mapDashboardDispatchLines(state),
        stockData: state.data.stocks,
        compliances: state.data.compliances,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        packs: state.masterData.packs,
        farms: state.masterData.farms,
        sizes: state.masterData.sizes,
        colours: state.masterData.colours,
        grades: state.masterData.grades,
        sites: state.masterData.sites,
        storageUnits: state.masterData.storageUnits,
        markets: state.masterData.markets,
        brands: state.masterData.brands,
        regions: state.masterData.regions,
        countries: state.masterData.countries,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        selectedSiteIds: state.data.selectedSiteIds,
        palletBaseTypes: state.masterData.palletBaseTypes,
        selectedLoadDate: state.data.dashboardDispatchSelectedLoadDate,
        selectedDispatchId: state.data.dashboardDispatchSelectedDispatchInstructionId,
        showOnlyMyDispatches: state.data.dashboardShowOnlyMyDispatches,
        selectedExporterOrganization: state.data.selectedExporterOrganization,
        inventoryData: state.masterData.inventories,
        selectedInventory: state.data.selectedInventory,

        StockDataViews: state.data.stockDataViews,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StockScreen);
