import * as React from 'react';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetProjects, dataSetCommodities } from '../../../store/masterData/Actions';
import { formatDateTime, isNullOrWhiteSpace, upsertArrayElement } from '../../../services/appFunctionsService';
import { ICommodity } from '../../../@types/model/masterData/commodity/commodity';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikHelpers } from 'formik';
import { createSelector } from 'reselect';
import { RouteComponentProps } from 'react-router';
import { ConfirmationDialog, CustomTable } from '@zz2/zz2-ui';
import { v4 as uuidv4 } from 'uuid';
import { IProject, Project } from '../../../@types/model/masterData/project/project';
import ProjectHttpService from '../../../services/http/masterData/projectHttpService';
import { IProjectFormValues, ProjectFormValues } from '../../../@types/model/masterData/project/projectFormValues';
import { IRight } from '../../../@types/model/user/right';
import ProjectForm from './form/ProjectForm';
import Screen from '../../../components/Screen';
import CommodityHttpService from '../../../services/http/masterData/commodityHttpService';
import { setProjectMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface IProjectScreenProps extends RouteComponentProps {
    dataSetProjects : DispatchCall<Array<IProject>>;
    dataSetCommodities : DispatchCall<Array<ICommodity>>;
    projectData : Array<IProject>;
    commodities ?: Array<ICommodity>;
    auth : IAuthState;
}

interface IProjectScreenState {
    rows : Array<IProject>;
    isLoading : boolean;
    selectedProject ?: IProject;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    formGuid ?: string;
    isDeletePopupOpen : boolean;
    deletingProject ?: IProject;
}

class ProjectScreen extends React.Component<IProjectScreenProps, IProjectScreenState> {
    constructor(props : IProjectScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedProject: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);

            try {
                const res = await ProjectHttpService.getProjectData();
                const res2 = await CommodityHttpService.getCommodityData();

                this.props.dataSetProjects(res.data);
                this.props.dataSetCommodities(res2.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading projects.');
                this.setLoading(false);
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading : loading });
    };

    public onSubmit = async (values : IProjectFormValues) => {
        this.setLoading(true);

        let payload = { ...values };
        if (isNullOrWhiteSpace(payload.guid)) {
            payload.guid = this.state.formGuid ?? '';
        }

        try {
            const res = await ProjectHttpService.addOrUpdateProject(new Project(payload));

            const newProjectList = upsertArrayElement(this.props.projectData, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetProjects(newProjectList);
            await setProjectMasterDataIndexedDB(newProjectList);

            if (this.state.selectedProject) {
                generalShowSuccessSnackbar('Project updated successfully.');
            } else {
                generalShowSuccessSnackbar('Project added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating project data.');
        } finally {
            this.setLoading(false);
        }
    };

    public onReset = async (formValues : IProjectFormValues, formikHelpers : FormikHelpers<IProjectFormValues>) => {
        formikHelpers.resetForm();
        this.closeDialog();
    };

    private openDeleteConfirmationPopup = (project : IProject) => {
        this.setState({ isDeletePopupOpen: true, deletingProject: project });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingProject: undefined });
    };

    private removeProject = async () => {
        const newProject = this.state.deletingProject;
        if (newProject) {
            newProject.isActive = false;
            this.setLoading(true);

            try {
                const res = await ProjectHttpService.deleteProject(newProject.id);

                const newProjectList = upsertArrayElement(this.props.projectData, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetProjects(newProjectList);
                await setProjectMasterDataIndexedDB(newProjectList);

                generalShowSuccessSnackbar('Project updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting project.');
                newProject.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    public getSelectedProject = (props : IProjectScreenProps, state : IProjectScreenState) => state.selectedProject;
    public getCommodities = (props : IProjectScreenProps) => props.commodities;

    public getInitialFormValues = createSelector(
        [this.getSelectedProject, this.getCommodities],
        (project : IProject, commodities ?: Array<ICommodity>) => {
            return new ProjectFormValues(project, commodities);
        },
    );

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            formGuid: undefined,
            selectedProject: undefined,
        });
    };

    public openDialog = () => {
        this.setState({
            formGuid: uuidv4(),
            isDialogOpen: true,
        });
    };

    private onProjectEdit = (row : IProject) => {
        this.setState({
            isDialogOpen: true,
            selectedProject: row,
        });
    };

    private getCommodityCode = (id : number) => {
        const commodity = this.props.commodities?.find(x => x.id === id);

        return commodity ? commodity.code : '';
    };

    private getRights = (props : IProjectScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IProjectScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const initialValues = this.getInitialFormValues(this.props, this.state);

        return (
            <Screen isScrollable={false} isLoading={this.state.isLoading}>
                <PackmanDialog
                    title={'Project'}
                    isEdit={!!this.state.selectedProject}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={ProjectFormValues.formSchema}
                        component={ProjectForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IProject>
                            enableAdding={this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.onProjectEdit}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(project : IProject) => project.isActive && this.hasEditingRight(this.props) && !project.isImported}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            enablePagination
                            warning={row => !!row.endedOn}
                            columns={[
                                { title: 'Number', field: 'number', enableFiltering: true, enableSorting: true },
                                { title: 'Description', field: 'description', enableFiltering: true, enableSorting: true },
                                { title: 'Commodity', field: 'commodityId', formatFunction: this.getCommodityCode, enableFiltering: true, enableSorting: true },
                                { title: 'Ended On', field: 'endedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Imported?', field: 'isImported', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={this.props.projectData}
                            fitWidthToPage
                            initialSortOrder={[{ columnName: 'id_Id', direction : 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={340}
                            isActive={(row : IProject) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationDialog
                    title={'Delete Project'}
                    open={this.state.isDeletePopupOpen}
                    description={'Are you sure you want to delete this project?'}
                    onAccept ={this.removeProject}
                    onClose ={this.closeDeleteConfirmationPopup}
                    dialogType='orange'
                    isLoading={this.state.isLoading}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        projectData: state.masterData.projects,
        commodities: state.masterData.commodities,
        auth: state.auth,
    };
};

const mapDispatchToProps  = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetProjects, dataSetCommodities }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProjectScreen);
