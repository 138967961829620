import * as React from 'react';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetCountries, dataSetRegions } from '../../../store/masterData/Actions';
import { formatDateTime, isNullOrWhiteSpace, upsertArrayElement } from '../../../services/appFunctionsService';
import { ICountry, Country } from '../../../@types/model/masterData/country/country';
import { IRegion } from '../../../@types/model/masterData/region/region';
import CountryHttpService from '../../../services/http/masterData/countryHttpService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import RegionHttpService from '../../../services/http/masterData/regionHttpService';
import Screen from '../../../components/Screen';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikHelpers } from 'formik';
import CountryForm from './form/CountryForm';
import { CountryFormValues, ICountryFormValues } from '../../../@types/model/masterData/country/countryFormValues';
import { createSelector } from 'reselect';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import { ConfirmationDialog, CustomTable } from '@zz2/zz2-ui';
import { v4 as uuidv4 } from 'uuid';
import { setCountryMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface ICountryScreenProps extends RouteComponentProps {
    dataSetCountries : DispatchCall<Array<ICountry>>;
    dataSetRegions : DispatchCall<Array<IRegion>>;
    countryData : Array<ICountry>;
    regions : Array<IRegion>;
    selectedOrganizationIds : Array<number>;
    auth : IAuthState;
}

interface ICountryScreenState {
    rows : Array<ICountry>;
    isLoading : boolean;
    selectedCountry ?: ICountry;
    isDialogOpen : boolean;
    formGuid ?: string;
    isDeletePopupOpen : boolean;
    deletingCountry ?: ICountry;
}

class CountryScreen extends React.Component<ICountryScreenProps, ICountryScreenState> {
    constructor(props : ICountryScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedCountry: undefined,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);

            try {
                const res = await CountryHttpService.getCountryData();

                const countryData = res.data;
                if (countryData) {
                    const res2 = await RegionHttpService.getRegionData();

                    const regions = res2.data;
                    this.props.dataSetRegions(regions);
                    this.props.dataSetCountries(countryData);
                } else {
                    this.props.dataSetCountries([]);
                }

                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading countries.');
                this.setLoading(false);
            }
        }
    };

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            formGuid: undefined,
            selectedCountry: undefined,
        });
    };

    public openDialog = () => {
        this.setState({
            formGuid: uuidv4(),
            isDialogOpen: true,
        });
    };

    public editCountry = (country : ICountry) => {
        this.setState({
            isDialogOpen: true,
            selectedCountry: country,
        });
        this.openDialog();
    };

    public onSubmit = async (values : ICountryFormValues) => {
        this.setLoading(true);

        let payload = { ...values };
        if (isNullOrWhiteSpace(payload.guid)) {
            payload.guid = this.state.formGuid ?? '';
        }

        try {
            const res = await CountryHttpService.addOrUpdateCountry(new Country(payload));

            const newCountryList = upsertArrayElement(this.props.countryData, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetCountries(newCountryList);
            await setCountryMasterDataIndexedDB(newCountryList);

            if (this.state.selectedCountry) {
                generalShowSuccessSnackbar('Country updated successfully.');
            } else {
                generalShowSuccessSnackbar('Country added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating country data.');
        } finally {
            this.setLoading(false);
        }
    };

    private openDeleteConfirmationPopup = (country : ICountry) => {
        this.setState({ isDeletePopupOpen: true, deletingCountry: country });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingCountry: undefined });
    };

    private removeCountry = async () => {
        const newCountry = this.state.deletingCountry;
        if (newCountry) {
            newCountry.isActive = false;
            this.setLoading(true);

            try {
                const res = await CountryHttpService.deleteCountry(newCountry.id);

                const newCountryList = upsertArrayElement(this.props.countryData, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetCountries(newCountryList);
                await setCountryMasterDataIndexedDB(newCountryList);

                generalShowSuccessSnackbar('Country updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting country.');
                newCountry.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    private getRows = () => {
        return this.props.countryData.filter((x) => {
            const region = this.props.regions.find(y => y.id === x.regionId && y.isActive);
            return region && region.organizationIds.some(y => this.props.selectedOrganizationIds?.some(z => z === y));
        });
    };

    public onReset = async (formValues : ICountryFormValues, formikHelpers : FormikHelpers<ICountryFormValues>) => {
        formikHelpers.resetForm();
        this.closeDialog();
    };

    public getSelectedCountry = (props : ICountryScreenProps, state : ICountryScreenState) => state.selectedCountry;
    public getRegions = (props : ICountryScreenProps) => props.regions;

    public getInitialFormValues = createSelector(
        [this.getSelectedCountry, this.getRegions],
        (country : ICountry, regions ?: Array<IRegion>) => {
            return new CountryFormValues(country, regions);
        },
    );

    private getRegionName = (regionId : number) => {
        const region = this.props.regions?.find(x => x.id === regionId);
        return region?.name ?? '';
    };

    private getRights = (props : ICountryScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : ICountryScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.getRows();
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Country'
                    isEdit={!!this.state.selectedCountry}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={CountryFormValues.formSchema}
                        component={CountryForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<ICountry>
                            enableAdding={this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.editCountry}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(country : ICountry) => country.isActive && this.hasEditingRight(this.props)}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            enablePagination
                            columns={[
                                { title: 'Code', field: 'code', enableFiltering: true, enableSorting: true },
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Region', field: 'regionId', formatFunction: this.getRegionName, enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            fitWidthToPage
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : ICountry) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationDialog
                    title={'Delete Country'}
                    open={this.state.isDeletePopupOpen}
                    description ={'Are you sure you want to delete this this country?'}
                    onAccept ={this.removeCountry}
                    onClose ={this.closeDeleteConfirmationPopup}
                    dialogType='orange'
                    isLoading={this.state.isLoading}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        countryData: state.masterData.countries,
        regions: state.masterData.regions,
        selectedOrganizationIds: state.data.selectedOrganizationIds,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetCountries, dataSetRegions }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CountryScreen);
