import { ActionType } from 'typesafe-actions';
import * as generalActions from '../store/general/Actions';
import * as logActions from '../store/logs/Actions';
import * as authActions from '../store/auth/Actions';
import * as navActions from '../store/nav/Actions';
import * as dataActions from '../store/data/Actions';
import * as masterDataActions from '../store/masterData/Actions';
import { AnyAction } from 'redux';
import { OptionsObject } from 'notistack';
import { IUserToken } from './model/user/userToken';
import { IConsignmentTokenResponse } from './model/compliance/titan-TUR/consignmentTokenResponse';
import { ITURTokenResponse } from './model/compliance/titan-TUR/turTokenResponse';
import { IOrganization } from './model/masterData/organization/organization';
import { ICommodity } from './model/masterData/commodity/commodity';
import { IGrade } from './model/masterData/grade/grade';
import { IColour } from './model/masterData/colour/colour';
import { IPackCategory } from './model/masterData/pack/packCategory';
import { IPack } from './model/masterData/pack/pack';
import { ISize } from './model/masterData/size/size';
import { ICommodityState } from './model/masterData/commodityState/commodityState';
import { IVariety } from './model/masterData/variety/variety';
import { ICountry } from './model/masterData/country/country';
import { IFarm } from './model/masterData/farm/farm';
import { IMarket } from './model/masterData/market/market';
import { IBrand } from './model/masterData/brand/brand';
import { ICarrier } from './model/masterData/carrier/carrier';
import { ILotType } from './model/masterData/lotType/lotType';
import { IRegion } from './model/masterData/region/region';
import { ISite } from './model/masterData/site/site';
import { IInspectionPoint } from './model/masterData/inspectionPoint/inspectionPoint';
import { IOrchard } from './model/masterData/orchard/orchard';
import { IStock } from './model/stock/stock';
import { ITempStockUnit } from './model/stock/tempStockUnit';
import { IDispatchInstruction } from './model/dispatch/dispatchInstruction';
import { ITrip } from './model/dispatch/trip';
import { IPalletBaseType } from './model/masterData/palletBaseType/palletBaseType';
import { IOrderHeader } from './model/order/orderHeader';
import { ICompliance } from './model/compliance/compliance';
import { IRight } from './model/user/right';
import { IRole } from './model/user/role';
import { ILot } from './model/lot/lot';
import { IIntake } from './model/intake/intake';
import { IBatch } from './model/batch/batch';
import { IMaterial } from './model/masterData/material/material';
import { IMaterialType } from './model/masterData/materialType/materialType';
import { IUnitOfMeasure } from './model/masterData/unitOfMeasure/unitOfMeasure';
import { IUnitOfMeasureType } from './model/masterData/unitOfMeasureType/unitOfMeasureType';
import { IMaterialStock } from './model/materialStock/materialStock';
import { IMaterialDispatch } from './model/materialDispatch/materialDispatch';
import { IUniqueCodeObject } from './model';
import { ICommodityCondition } from './model/masterData/commodityCondition/commodityCondition';
import { IFaq } from './model/frequentlyAskedQuestions/faq';
import { TypedUseSelectorHook, useDispatch, useSelector as useReduxSelector } from 'react-redux';
import { ISeason } from './model/masterData/season/season';
import { IStorageUnit } from './model/masterData/storageUnit/storageUnit';
import { IInventory } from './model/masterData/inventory/inventory';
import { IDomain } from './model/user/domain';
import { IDepartment } from './model/masterData/department/department';
import store, { rootReducer } from '../store/Index';
import { IInfoBoardData } from './model/home/infoBoardData';
import { IContactUs } from './model/home/contactUs';
import { ISaleLine } from './model/sales/saleLine';
import { ISale } from './model/sales/sale';
import { ISaleLineView } from './model/sales/saleLineView';
import { IStockView } from './model/stock/stockView';
import { IContactInfo } from './model/masterData/contactInfo/contactInfo';
import { IDispatchView } from './model/dispatch/dispatchView';
import { IMultiDispatchStockView } from './model/stock/multiDispatchStockView';
import { ITruckType } from './model/masterData/truckType/truckType';
import { IDevice } from './model/masterData/device/device';
import { IProject } from './model/masterData/project/project';
import { IStockTomatoReportView } from './model/stock/StockTomatoReportView';
import { IBatchView } from './model/batch/batchView';
import { ILotView } from './model/lot/lotView';
import { IIntakeView } from './model/intake/intakeView';
import { IBarcodeRange } from './model/masterData/barcodeRange/barcodeRange';
import { IAccreditation } from './model/masterData/accreditation/accreditation';
import { IRecipe } from './model/integration/recipe';
import { IOptionType } from './helper';
import { IPackLine } from './model/masterData/packLine/packLine';
import { IOutlet } from './model/masterData/outlet/outlet';
import { IPrinter } from './model/masterData/printer/printer';
import { IPrintServer } from './model/masterData/printServer/printServer';
import { IReport } from './model/masterData/report/report';
import { IAgreementCode } from './model/masterData/agreementCode/agreementCode';
import { IMaterialStockView } from './model/materialStock/materialStockView';
import { ISalesPoint } from './model/masterData/salesPoint/salesPoint';
import { IStockViewWithCartons } from './model/stock/stockViewWithCartons';
import { ILog } from './model/logs/Log';
import { IMaterialDispatchView } from './model/materialDispatch/materialDispatchView';
import { IFtpDetail } from './model/masterData/ftpDetail/ftpDetail';
import { ISiteSetting } from './model/masterData/siteSetting/siteSetting';

export type DispatchCallType = (...args : Array<any>) => Promise<any>;
export type DispatchCall<P> = (payload : P) => void;
export type DispatchRemoveSnackbar = (key : number) => void;

export interface IAuthState {
    isLoggedIn : boolean;
    session ?: IUserToken | null;
    token : string;
    isLoggingIn : boolean;
    ppecbToken ?: IConsignmentTokenResponse;
    turToken ?: ITURTokenResponse;
}

export interface IRootState {
    auth : IAuthState;
    data : IDataState;
    masterData : IMasterDataState;
    general : IGeneralState;
    log : ILogState;
    loading : boolean;
}

export interface INotification {
    message : string | React.ReactNode;
    options ?: OptionsObject;
}

export interface ISnackbarNotification extends INotification {
    key : number;
}

export interface IGeneralState {
    notifications : Array<ISnackbarNotification>;
}

export interface ILogState {
    logs : Array<ILog>;
}

export interface IDefaultAction<T> {
    type : string;
    value : T;
}

export type RootState = ReturnType<typeof rootReducer>;

export interface IDataState {
    selectedOrganizationIds ?: Array<number>;
    selectedSiteIds ?: Array<number>;
    selectedExporterOrganization ?: IOrganization;
    selectedInventory ?: IInventory;
    organizationToEdit ?: IOrganization;
    selectedDashboardSiteIds : Array<number>;
    selectedDashboardOrganizations : Array<number>;
    selectedDashboardCommodities : Array<ICommodity>;
    selectedDashboardVarieties : Array<IUniqueCodeObject>;
    selectedDashboardGrades : Array<IUniqueCodeObject>;
    selectedDashboardColours : Array<IUniqueCodeObject>;
    selectedDashboardPackCategories : Array<IPackCategory>;
    dashboardDispatchSelectedDispatchInstructionId ?: number;
    dashboardDispatchSelectedLoadDate : string;
    isEditingTrip : boolean;
    dispatchInstructionStocks : Array<IStock>;

    stocks : Array<IStock>;
    stockTomatoReportViews : Array<IStockTomatoReportView>;
    stockDataViews : Array<IStockView>;
    stockWithCartonsDataViews : Array<IStockViewWithCartons>;
    multiDispatchStockViews : Array<IMultiDispatchStockView>;

    tempStockUnits : Array<ITempStockUnit>;
    dispatchInstructions : Array<IDispatchInstruction>;
    dispatchViews : Array<IDispatchView>;
    trips : Array<ITrip>;
    orderHeaders : Array<IOrderHeader>;
    dashboardShowOnlyMyDispatches : boolean;
    dashboardSelectedMaterialDispatchId ?: number;
    compliances : Array<ICompliance>;
    rights : Array<IRight>;
    roles : Array<IRole>;
    lots : Array<ILot>;
    lotViews : Array<ILotView>;
    intakes : Array<IIntake>;
    intakeViews : Array<IIntakeView>;
    batches : Array<IBatch>;
    batchViews : Array<IBatchView>;
    materialStocks : Array<IMaterialStock>;
    materialStockViews : Array<IMaterialStockView>;
    materialDispatches : Array<IMaterialDispatch>;
    materialDispatchViews : Array<IMaterialDispatchView>;

    sales : Array<ISale>;
    saleLines : Array<ISaleLine>;
    saleLineViewData : Array<ISaleLineView>;

    frequentlyAskedQuestions : Array<IFaq>;
    infoBoardData : Array<IInfoBoardData>;
    contactUsData : Array<IContactUs>;

    recipes : Array<IRecipe>;
    recipeTables : Array<IOptionType>;
}

export interface IMasterDataState {
    masterDataSyncIsLoading : boolean;
    selectedCommodity ?: ICommodity;

    organizations : Array<IOrganization>;
    packs : Array<IPack>;
    packCategories : Array<IPackCategory>;
    sizes : Array<ISize>;
    grades : Array<IGrade>;
    agreementCodes : Array<IAgreementCode>;
    accreditations : Array<IAccreditation>;
    colours : Array<IColour>;
    commodityStates : Array<ICommodityState>;
    varieties : Array<IVariety>;
    countries : Array<ICountry>;
    farms : Array<IFarm>;
    ftpDetails : Array<IFtpDetail>;
    barcodeRanges : Array<IBarcodeRange>;
    contactInfos : Array<IContactInfo>;
    markets : Array<IMarket>;
    brands : Array<IBrand>;
    carriers : Array<ICarrier>;
    inventories : Array<IInventory>;
    lotTypes : Array<ILotType>;
    regions : Array<IRegion>;
    sites : Array<ISite>;
    storageUnits : Array<IStorageUnit>;
    seasons : Array<ISeason>;
    inspectionPoints : Array<IInspectionPoint>;
    orchards : Array<IOrchard>;
    departments : Array<IDepartment>;
    commodities : Array<ICommodity>;
    commodityConditions : Array<ICommodityCondition>;
    materials : Array<IMaterial>;
    materialTypes : Array<IMaterialType>;
    palletBaseTypes : Array<IPalletBaseType>;
    unitOfMeasures : Array<IUnitOfMeasure>;
    unitOfMeasureTypes : Array<IUnitOfMeasureType>;
    domains : Array<IDomain>;
    truckTypes : Array<ITruckType>;
    devices : Array<IDevice>;
    projects : Array<IProject>;
    packLines : Array<IPackLine>;
    outlets : Array<IOutlet>;
    printers : Array<IPrinter>;
    printServers : Array<IPrintServer>;
    siteSettings : Array<ISiteSetting>;
    reports : Array<IReport>;
    salesPoints : Array<ISalesPoint>;
}

export type GeneralAction = ActionType<typeof generalActions>;
export type LogAction = ActionType<typeof logActions>;
export type AuthAction = ActionType<typeof authActions>;
export type NavAction = ActionType<typeof navActions>;
export type DataAction = ActionType<typeof dataActions>;
export type MasterDataAction = ActionType<typeof masterDataActions>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector : TypedUseSelectorHook<RootState> = useReduxSelector;

export type RootAction =
    | GeneralAction | LogAction | AuthAction | NavAction | DataAction | AnyAction;
