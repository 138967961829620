import * as React from 'react';
import CustomTable from '../../../components/table/CustomTable';
import { Card, Input, TextField } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetRecipes, dataSetRecipeTables } from '../../../store/data/Actions';
import { formatDateTime, booleanToYesNo, compareDate, upsertArrayElement } from '../../../services/appFunctionsService';
import { IRecipe, Recipe } from '../../../@types/model/integration/recipe';
import RecipeHttpService from '../../../services/http/integration/recipe/recipeHttpService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import Screen from '../../../components/Screen';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikHelpers } from 'formik';
import { RecipeFormValues, IRecipeFormValues } from '../../../@types/model/integration/form/recipeFormValues';
import { createSelector } from 'reselect';
import RecipeForm from './form/RecipeForm';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import ConfirmationPrompt from '../../../components/dialog/ConfirmationPrompt';
import { IOptionType } from '../../../@types/helper';
import { sortBy } from 'lodash';
import PillButton from '../../../components/input/PillButton';
import { ConfirmationDialog, CustomTable } from '@zz2/zz2-ui';

interface IRecipeScreenProps extends RouteComponentProps {
    dataSetRecipes : DispatchCall<Array<IRecipe>>;
    dataSetRecipeTables : DispatchCall<Array<IOptionType>>;
    recipeData : Array<IRecipe>;
    recipeTableOptions : Array<IOptionType>;
    auth : IAuthState;
}

interface IRecipeScreenState {
    rows : Array<IRecipe>;
    isLoading : boolean;
    testResult ?: string;
    selectedRecipe ?: IRecipe;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    isDeletePopupOpen : boolean;
    deletingRecipe ?: IRecipe;
}

class RecipeScreen extends React.Component<IRecipeScreenProps, IRecipeScreenState> {
    constructor(props : IRecipeScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedRecipe: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        this.setLoading(true);
        try {
            const res = await RecipeHttpService.getRecipeData();
            const res2 = await RecipeHttpService.getTables();

            this.props.dataSetRecipes(res.data);

            const tables = res2.data;

            this.props.dataSetRecipeTables(sortBy(tables.map((x) => {
                return {
                    label: x.name,
                    value: x.tableName,
                };
            }), x => x.label));
            this.setLoading(false);
        } catch (e) {
            generalShowErrorSnackbar('An error occurred while loading recipes.');
            this.setLoading(false);
        }
    };

    public editRecipe = (recipe : IRecipe) => {
        this.setState({
            isDialogOpen: true,
            selectedRecipe: recipe,
        });
        this.openDialog();
    };

    public openDialog = () => {
        this.setState({
            isDialogOpen: true,
        });
    };

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            selectedRecipe: undefined,
        });
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    public getSelectedRecipe = (props : IRecipeScreenProps, state : IRecipeScreenState) => state.selectedRecipe;
    public getTables = (props : IRecipeScreenProps) => props.recipeTableOptions;

    public getInitialFormValues = createSelector(
        [this.getSelectedRecipe, this.getTables],
        (recipe, tables) => {
            return new RecipeFormValues(recipe, tables);
        },
    );

    public onReset = async (formValues : IRecipeFormValues, formikHelpers : FormikHelpers<IRecipeFormValues>) => {
        formikHelpers.resetForm();
        this.closeDialog();
    };

    public onSubmit = async (values : IRecipeFormValues) => {
        this.setLoading(true);

        try {
            const res = await RecipeHttpService.addOrUpdateRecipe(new Recipe(values));

            const newRecipeList = upsertArrayElement(this.props.recipeData, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetRecipes(newRecipeList);

            if (this.state.selectedRecipe) {
                generalShowSuccessSnackbar('Recipe updated successfully.');
            } else {
                generalShowSuccessSnackbar('Recipe added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating recipe data.');
        } finally {
            this.setLoading(false);
        }
    };

    private openDeleteConfirmationPopup = (recipe : IRecipe) => {
        this.setState({ isDeletePopupOpen: true, deletingRecipe: recipe });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingRecipe: undefined });
    };

    private closeTestResultPopup = () => {
        this.setState({ testResult: undefined });
    };

    private removeRecipe = async () => {
        const newRecipe = this.state.deletingRecipe;
        if (newRecipe) {
            newRecipe.isActive = false;
            this.setLoading(true);

            try {
                const res = await RecipeHttpService.addOrUpdateRecipe(newRecipe);

                const newRecipeList = upsertArrayElement(this.props.recipeData, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetRecipes(newRecipeList);

                generalShowSuccessSnackbar('Recipe updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting recipe.');
                newRecipe.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    private getRights = (props : IRecipeScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IRecipeScreenProps) => props.location.pathname;

    private submitTestField = async (recipeId : number, tableId ?: number) => {
        if (recipeId && tableId) {
            this.setLoading(true);

            try {
                const res = await RecipeHttpService.testRecipe(recipeId, tableId);

                this.setState({ testResult: res.data });
            } catch (e) {
                generalShowErrorSnackbar('An error occurred testing the recipe.');
            } finally {
                this.setLoading(false);
            }
        }
        return null;
    };

    private testField = (recipeId : number) => {
        const input : React.RefObject<{ value : number }> = React.createRef();
        const recipe = this.props.recipeData?.find(x => x.id === recipeId);
        return <div className={'fdr'}>
            <Input inputRef={input} placeholder={recipe?.startingTable + 'Id'} type={'number'}/>
            <PillButton color={'secondary'} className={'ml15 mr15 pl20 pr20 h30'} text={'test'} onClick={() => this.submitTestField(recipeId, Number(input?.current?.value))}/>
        </div>;
    };

    private isModifiable = (rowId : number) => !this.props.recipeData.some(x => x.recipeLines.some(y => y.callRecipeId === rowId));

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.props.recipeData ? this.props.recipeData : [];
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Recipe'
                    isEdit={!!this.state.selectedRecipe}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    fullScreen
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={RecipeFormValues.formSchema}
                        component={RecipeForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc'}>
                        <CustomTable<IRecipe>
                            enableAdding={this.hasEditingRight(this.props)}
                            enableDeleting={(recipe : IRecipe) => recipe.isActive && this.hasEditingRight(this.props) && this.isModifiable(recipe.id)}
                            addFunction={this.openDialog}
                            editFunction={this.editRecipe}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableEditing={(recipe : IRecipe) => this.hasEditingRight(this.props) && this.isModifiable(recipe.id)}
                            enableSorting
                            enableFiltering
                            fitWidthToPage
                            enablePagination
                            columns={[
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Starting Table', field: 'startingTable', enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', formatFunction: booleanToYesNo, type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Test', field: 'id', formatFunction: this.testField },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IRecipe) => row.isActive}
                        />
                    </Card>
                </div>
                <PackmanDialog
                    title='Test Result'
                    isOpen={!!this.state.testResult}
                    onClose={this.closeTestResultPopup}
                    fullWidth
                    maxWidth={'xl'}
                    className={'oyh'}
                >
                    <TextField
                        multiline
                        value={this.state.testResult}
                        className={'oya mnh150 mnw400'}
                        variant={'outlined'}
                        disabled
                    />

                </PackmanDialog>
                <ConfirmationDialog
                    title={'Delete Recipe'}
                    open={this.state.isDeletePopupOpen}
                    description={'Are you sure you want to delete this recipe?'}
                    onAccept={this.removeRecipe}
                    onClose={this.closeDeleteConfirmationPopup}
                    dialogType='orange'
                    isLoading={this.state.isLoading}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        recipeData: state.data.recipes,
        recipeTableOptions: state.data.recipeTables,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetRecipes, dataSetRecipeTables }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RecipeScreen);
