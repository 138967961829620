import * as React from 'react';
import {
    dataSetDashboardDispatchSelectedLoadDate,
    dataSetDashboardSelectedMaterialDispatchId,
    dataSetDashboardShowOnlyMyDispatches,
} from '../../../store/data/Actions';
import { DispatchCall, RootAction, IRootState, IAuthState } from '../../../@types/redux';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Drawer, ListItem, List, IconButton, Icon, Divider, Switch } from '@mui/material';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import { formatDateTimeToDateOnly, removeArrayElement, addArrayElement } from '../../../services/appFunctionsService';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DATEPICKER_FORMAT_DEFAULT } from '../../../appConstants';
import { v1 } from 'uuid';
import { dataSetMaterialDispatch } from '../../../store/data/Functions';
import { navMaterialDispatchDashboard } from '../../../store/nav/Actions';
import { IMaterialDispatch } from '../../../@types/model/materialDispatch/materialDispatch';
import { ISite } from '../../../@types/model/masterData/site/site';
import { IOrganization } from '../../../@types/model/masterData/organization/organization';
import { ICommodity } from '../../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../../@types/model/masterData/variety/variety';
import { IPack } from '../../../@types/model/masterData/pack/pack';
import { ISize } from '../../../@types/model/masterData/size/size';
import { IColour } from '../../../@types/model/masterData/colour/colour';
import { IGrade } from '../../../@types/model/masterData/grade/grade';
import { IMarket } from '../../../@types/model/masterData/market/market';
import { IStock } from '../../../@types/model/stock/stock';
import { IMaterialDispatchLine } from '../../../@types/model/materialDispatch/materialDispatchLine';
import MaterialDispatchHttpService from '../../../services/http/materialDispatch/materialDispatchHttpService';
import CustomTooltip from '../../../components/tooltip/tooltip';
import { getIconLocation } from '../../../services/iconHelperService';

interface IStockDashboardDispatchDraftSidePanelProps {
    dataSetDashboardDispatchSelectedLoadDate : DispatchCall<string>;
    dataSetDashboardSelectedMaterialDispatchId : DispatchCall<number | undefined>;
    dataSetDashboardShowOnlyMyDispatches : DispatchCall<boolean>;
    materialDispatches : Array<IMaterialDispatch>;
    showOnlyMyDispatches : boolean;
    selectedLoadDate : string;
    sites : Array<ISite>;
    organizations : Array<IOrganization>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    packs : Array<IPack>;
    sizes : Array<ISize>;
    colours : Array<IColour>;
    grades : Array<IGrade>;
    markets : Array<IMarket>;
    stockData : Array<IStock>;
    auth : IAuthState;
    selectedDispatchId ?: number;
    disableMaterialDispatchMode : () => void;
    materialDispatchMode : boolean;
    isLoading : boolean;
    setLoading : (loading : boolean) => void;
    refreshData : (noAnnounce ?: boolean) => void;
    drawerWidth : number;
}

interface IStockDashboardDispatchDraftSidePanelState {
    materialDispatches : Array<IMaterialDispatch>;
    showRemoveDispatchLineConfirmationPrompt : boolean;
    lineToRemove ?: IMaterialDispatchLine;
    expandedLines : Array<{ instructionId : number; index : number }>;
}

class StockDashboardDispatchDraftSidePanel extends React.Component<IStockDashboardDispatchDraftSidePanelProps, IStockDashboardDispatchDraftSidePanelState> {
    constructor(props : IStockDashboardDispatchDraftSidePanelProps) {
        super(props);

        this.state = {
            materialDispatches: [],
            showRemoveDispatchLineConfirmationPrompt: false,
            expandedLines: [],
        };
    }

    public componentDidUpdate = (prevProps : IStockDashboardDispatchDraftSidePanelProps) => {
        const nextProps = this.props;
        if (prevProps && nextProps) {
            if ((prevProps.materialDispatches !== nextProps.materialDispatches) || (prevProps.selectedLoadDate !== nextProps.selectedLoadDate) || (prevProps.showOnlyMyDispatches !== nextProps.showOnlyMyDispatches)) {
                this.setState({ materialDispatches: this.formatMaterialDispatches() });
            }
            if (prevProps.selectedLoadDate !== nextProps.selectedLoadDate) {
                this.props.dataSetDashboardSelectedMaterialDispatchId(undefined);
            }
        }
    };

    private getOrganizationCode = (id : number) => {
        const item = this.props.organizations.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getStockBarcode = (stockId : number) => {
        const stock = this.props.stockData.find(x => x.id === stockId);
        return stock?.barcode || '';
    };

    private getStockCartons = (stockId : number) => {
        const stock = this.props.stockData.find(x => x.id === stockId);
        return stock?.cartons || 0;
    };

    private getCommodityCode = (id : number) => {
        const item = this.props.commodities.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getVarietyCode = (id : number) => {
        const item = this.props.varieties.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getPackCode = (id : number) => {
        const item = this.props.packs.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getSizeCode = (id : number) => {
        const item = this.props.sizes.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getColourCode = (id : number) => {
        const item = this.props.colours.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getGradeCode = (id : number) => {
        const item = this.props.grades.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getMarketCode = (id : number) => {
        const item = this.props.markets.find(x => x.id === id);
        return item ? item.code : '';
    };

    private getSiteShortDescription = (id : number) => {
        const item = this.props.sites.find(x => x.id === id);
        return item ? item.shortDescription : '';
    };

    private formatMaterialDispatches = () => this.props.materialDispatches.filter(x => x.isActive && (formatDateTimeToDateOnly(x.loadDate) === formatDateTimeToDateOnly(this.props.selectedLoadDate)) && x.status === 'Draft' && (this.props.showOnlyMyDispatches ?  x.updatedBy === (this.props.auth.session && this.props.auth.session.userId) : true));

    private addNewDispatch = async () => {
        const newDispatch : IMaterialDispatch = {
            id: 0,
            guid: v1(),
            loadDate: this.props.selectedLoadDate,
            status: 'Draft',
            isPrinted: false,
            isActive: true,
            materialDispatchLines: [],
        };
        try {
            const res = await MaterialDispatchHttpService.addOrUpdateMaterialDispatch(newDispatch);

            if (res && res.data) {
                dataSetMaterialDispatch(res.data);
                this.props.dataSetDashboardSelectedMaterialDispatchId(res.data.id);
                generalShowSuccessSnackbar('Material dispatch successfully added.');
                this.props.setLoading(false);
            } else {
                generalShowErrorSnackbar('An error occurred adding the material dispatch.');
                this.props.setLoading(false);
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred adding the material dispatch.');
            this.props.setLoading(false);
        }
    };

    private removeDispatchLine = (line : IMaterialDispatchLine, event : React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        this.setState({ lineToRemove: line, showRemoveDispatchLineConfirmationPrompt: true });
    };

    private toggleSelectedInstruction = (id : number, event ?: React.MouseEvent<HTMLElement | MouseEvent>) => {
        if (event) {
            event.stopPropagation();
        }
        this.props.dataSetDashboardSelectedMaterialDispatchId(this.props.selectedDispatchId === id ? undefined : id);
    };

    private expandInstuctionLine = (expandedLine : { instructionId : number; index : number}, event : React.MouseEvent<HTMLElement | MouseEvent>) => {
        event.stopPropagation();
        const alreadyExpandedIndex = this.state.expandedLines.findIndex(x => x.instructionId === expandedLine.instructionId && x.index === expandedLine.index);
        this.setState({
            expandedLines: alreadyExpandedIndex !== - 1 ?
                removeArrayElement(this.state.expandedLines, alreadyExpandedIndex) :
                addArrayElement(this.state.expandedLines, expandedLine, 'end'),
        });
    };

    private submitInstructionFromDraft = async (instruction : IMaterialDispatch, event : React.MouseEvent<HTMLElement | MouseEvent>) => {
        event.stopPropagation();
        const newInstruction = { ...instruction };
        newInstruction.status = 'Planned';
        newInstruction.updatedOn = undefined;
        this.props.setLoading(true);
        try {
            const res = await MaterialDispatchHttpService.addOrUpdateMaterialDispatch(newInstruction);

            if (res && res.data) {
                dataSetMaterialDispatch(res.data);
                generalShowSuccessSnackbar('Material dispatch successfully moved from draft phase.');
                this.props.setLoading(false);
            } else {
                generalShowErrorSnackbar('An error occurred submitting the material dispatch draft.');
                this.props.setLoading(false);
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred submitting the material dispatch draft.');
            this.props.setLoading(false);
        }
    };

    private removeDispatch = async (dispatch : IMaterialDispatch, event : React.MouseEvent<HTMLElement | MouseEvent>) => {
        event.stopPropagation();
        const newDispatch = { ...dispatch };
        newDispatch.updatedOn = undefined;
        newDispatch.isActive = false;
        newDispatch.materialDispatchLines.forEach((x) => {
            if (x.isActive) {
                x.updatedOn = undefined;
                x.isActive = false;
            }
        });
        this.props.setLoading(true);
        try {
            const res = await MaterialDispatchHttpService.deleteMaterialDispatch(newDispatch.id);

            if (res && res.data) {
                this.props.refreshData(true);
                if (res.data.id === this.props.selectedDispatchId) {
                    this.props.dataSetDashboardSelectedMaterialDispatchId(undefined);
                }
                generalShowSuccessSnackbar('Material dispatch successfully removed.');
                this.props.setLoading(false);
            } else {
                generalShowErrorSnackbar('An error occurred removing the material dispatch.');
                this.props.setLoading(false);
            }
        } catch (e) {
            generalShowErrorSnackbar('An error occurred removing the material dispatch draft.');
            this.props.setLoading(false);
        }
    };

    public render() {
        return <Drawer
            variant='persistent'
            anchor='right'
            elevation={1}
            PaperProps={{ elevation: 1, style: this.props.materialDispatchMode ? { width: this.props.drawerWidth, paddingTop: 70, zIndex: 10, height: 'calc(100% - 70px)', overflowX: 'hidden' } : {} }}
            open={this.props.materialDispatchMode}
        >
            <div className={'wfill fdr pl10 aic pb10'}>
            Dispatch Instructions (Draft)
                <div className={'flx1'} />
                <CustomTooltip title={'Material Dispatch Dashboard'}><IconButton className={'p0 h30 w30'} onClick={navMaterialDispatchDashboard} disabled={this.props.isLoading}><img className={'h20 w20'} src={`${getIconLocation()}/dashboard.svg`} /></IconButton></CustomTooltip>
                <CustomTooltip title={'Close'}><IconButton className={'p0 h30 w30 mr20'} onClick={this.props.disableMaterialDispatchMode} disabled={this.props.isLoading}><Icon>delete</Icon></IconButton></CustomTooltip>
            </div>
            <Divider />
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                    label={'Load Date'}
                    format={DATEPICKER_FORMAT_DEFAULT}
                    className={'flx1 m10'}
                    value={moment(this.props.selectedLoadDate)}
                    onChange={(e : any) => {
                        this.props.dataSetDashboardDispatchSelectedLoadDate(moment(e, DATEPICKER_FORMAT_DEFAULT).format(DATEPICKER_FORMAT_DEFAULT).toString());
                    }}
                />
            </LocalizationProvider>
            <div className={'fdr aic pl10'}>
                <div className={'fs14'}>{'Show only my drafts'}</div>
                <div className={'flx1'} />
                <Switch color={'primary'} checked={this.props.showOnlyMyDispatches} onChange={(e, checked) => this.props.dataSetDashboardShowOnlyMyDispatches(checked)} />
            </div>
            <Divider />
            <List className={'oya hfill oxh'}>
                { this.state.materialDispatches.map((instruction, index) => {
                    if (!instruction.sourceSiteId || !instruction.destinationSiteId || !instruction.materialDispatchLines || instruction.materialDispatchLines.filter(x => x.isActive).length === 0) {
                        return <React.Fragment key={'list_instruction_' + index}>
                            <ListItem button disabled={this.props.isLoading} className={`curd fdc DispatchListItem${this.props.selectedDispatchId === instruction.id ? 'Selected' : ''}`} key={`dispatch_${index}`} onClick={() => this.toggleSelectedInstruction(instruction.id)}>
                                <div className={'fdr wfill'} >
                                    <div className={'cgray3 fs12 aic'}>#{instruction.id}</div>
                                    <div className={'flx1'}/>
                                    <div className={'fs12 aic'}>{instruction.materialDispatchCode}</div>
                                    <div className={'flx1'}/>
                                    <IconButton className={'p0'} disabled={this.props.isLoading} onClick={(event : React.MouseEvent<HTMLButtonElement>) => this.removeDispatch(instruction, event)}><Icon fontSize={'small'}>delete</Icon></IconButton>
                                </div>
                                <Divider className={'mt10 mb10 wfill'} />
                                <div className={'fdr wfill fs14 aic'} >
                                    {'Select pallets to add...'}
                                </div>
                            </ListItem>
                            <Divider />
                        </React.Fragment>;
                    }
                    const lineCount = instruction.materialDispatchLines.length;
                    return <React.Fragment key={'list_instruction_' + index}><ListItem button disabled={this.props.isLoading} className={`curd DispatchListItem${this.props.selectedDispatchId === instruction.id ? 'Selected' : ''}`} key={`dispatch_${index}`} onClick={() => this.toggleSelectedInstruction(instruction.id)}>
                        <div className={'fdc wfill'}>
                            <div className={'fdr'} >
                                <div className={'fdc'}>
                                    <div className={'cgray3 fs12 aic'}>#{instruction.id}</div>
                                    <div className={'cgray3 fs12 aic'}>{formatDateTimeToDateOnly(instruction.loadDate)}</div>
                                </div>
                                <div className={'flx1'}/>
                                <div className={'fdc aic'}>
                                    <div className={'fs12 aic'}>{instruction.materialDispatchCode}</div>
                                    <div className={'aic'}>{this.getSiteShortDescription(instruction.sourceSiteId) + ' - ' + this.getSiteShortDescription(instruction.destinationSiteId)}</div>
                                </div>
                                <div className={'flx1'} />
                                <IconButton className={'p0'} disabled={this.props.isLoading} onClick={(event : React.MouseEvent<HTMLButtonElement>) => this.submitInstructionFromDraft(instruction, event)}><Icon fontSize={'small'}>done</Icon></IconButton>
                                <span className={'w20'}/>
                                <IconButton className={'p0'} disabled={this.props.isLoading} onClick={(event : React.MouseEvent<HTMLButtonElement>) => this.removeDispatch(instruction, event)}><Icon fontSize={'small'}>delete</Icon></IconButton>
                            </div>
                            <Divider className={'mt10 mb10'} />
                            { instruction.materialDispatchLines.map((instructionLine, lineIndex) => (
                                <>
                                    <div className={'fdr aic'} key={`dispatch_line${index}_${lineIndex}`}>
                                        <IconButton className={'p0 mr10'} disabled={this.props.isLoading} onClick={(event : React.MouseEvent<HTMLButtonElement>) => this.removeDispatchLine(instructionLine, event)}><Icon fontSize={'small'}>delete</Icon></IconButton>
                                        <div className={'flx1'} />
                                        <div className={'h30 w30 Round bcpd cs ml10 aic jcc fs12'}>
                                            {instructionLine.amount}
                                        </div>
                                        <IconButton className={'p0 ml10'} disabled={this.props.isLoading} onClick={(e : React.MouseEvent<HTMLButtonElement>) => this.expandInstuctionLine({ instructionId: instruction.id, index: lineIndex }, e)}><Icon fontSize={'small'}>{this.state.expandedLines.some(w => w.instructionId === instruction.id && w.index === lineIndex) ? 'expand_less' : 'expand_more'}</Icon></IconButton>
                                    </div>
                                    {this.state.expandedLines.some(x => x.instructionId === instruction.id && x.index === lineIndex) && <Divider className={'mt5 mb5'} />}
                                    { lineIndex !== (lineCount - 1)  && <Divider className={'mt5 mb5'} />}
                                </>
                            ))}
                        </div>
                    </ListItem>
                    <Divider />
                    </React.Fragment>;
                })}
            </List>
            <Divider />
            <div className={'fdr p10'}>
                <div className={'flx1'} />
                <IconButton color='primary' onClick={this.addNewDispatch} disabled={this.props.isLoading}><Icon>add</Icon></IconButton>
            </div>
            {/* TODO: <ConfirmationPrompt open={this.state.showRemoveDispatchLineConfirmationPrompt} message={'Are you sure you want to remove this dispatch instruction line?'}
            onYesClicked={this.removeMaterialDispatchLineConfirmed} onNoClicked={() => this.setState({ showRemoveDispatchLineConfirmationPrompt: false })}/> */}
        </Drawer>;
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        materialDispatches: state.data.materialDispatches,
        organizations: state.masterData.organizations,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        packs: state.masterData.packs,
        sizes: state.masterData.sizes,
        colours: state.masterData.colours,
        grades: state.masterData.grades,
        markets: state.masterData.markets,
        sites: state.masterData.sites,
        stockData: state.data.stocks,
        selectedLoadDate: state.data.dashboardDispatchSelectedLoadDate,
        selectedDispatchId: state.data.dashboardSelectedMaterialDispatchId,
        showOnlyMyDispatches: state.data.dashboardShowOnlyMyDispatches,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    {
        dataSetDashboardDispatchSelectedLoadDate,
        dataSetDashboardSelectedMaterialDispatchId,
        dataSetDashboardShowOnlyMyDispatches,
    },
    dispatcher,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StockDashboardDispatchDraftSidePanel);
