import React from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Moment } from 'moment';
import { Field, FieldProps } from 'formik';
import { getFormikErrorMessage } from '../../../services/appFunctionsService';

interface IFormDateInputProps {
    label : string;
    name : string;
    onDateChange ?: (date : Moment, fieldProps : FieldProps) => void;
    onBlur ?: (fieldProps : FieldProps) => void;
    className ?: string;
    disabled ?: boolean;
    transparentBackground ?: boolean;
    variant ?: 'standard' | 'outlined' | 'filled';
    format ?: string;
}

type FormDateInputPropsType = IFormDateInputProps;

export default class FormDateInput extends React.PureComponent<FormDateInputPropsType> {
    constructor(props : FormDateInputPropsType) {
        super(props);
    }

    public onChange = (date : Moment, fieldProps : FieldProps) => {
        fieldProps.form.setFieldValue(fieldProps.field.name, date, true);
        if (this.props.onDateChange) {
            this.props.onDateChange(date, fieldProps);
        }
        if (this.props.onBlur) {
            this.props.onBlur(fieldProps);
        }
    };

    public renderInput = (fieldProps : FieldProps) => {
        const {
            label,
            className,
            disabled,
            transparentBackground = true,
            format = 'YYYY-MM-DD',
        } = this.props;

        return (
            <div className={`pl10 pr10 fdc ais jcfs ${className || ''}`}>
                <DatePicker
                    slotProps={{
                        textField: {
                            variant: this.props.variant,
                            id: fieldProps.field.name,
                            label,
                            margin: 'dense',
                            error: !!fieldProps.form.touched[fieldProps.field.name] && !!getFormikErrorMessage(fieldProps.form.errors, fieldProps.field.name),
                        },
                    }}
                    className={`${transparentBackground ? '' : 'bcw'}`}
                    disabled={disabled}
                    value={fieldProps.field.value}
                    onChange={date => this.onChange(date, fieldProps)}
                    format={format}
                />
                <FormHelperText error>{getFormikErrorMessage(fieldProps.form.errors, fieldProps.field.name) || ''}</FormHelperText>
            </div>
        );
    };

    public render() {
        return (
            <Field name={this.props.name} render={this.renderInput} />
        );
    }
}
