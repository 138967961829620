import * as React from 'react';
import { Card, IconButton, Icon, TextField, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { RouteComponentProps } from 'react-router';
import { DispatchCall, IAuthState, IRootState, RootAction } from '../../@types/redux';
import { IRight } from '../../@types/model/user/right';
import Screen from '../../components/Screen';
import CustomTable from '../../components/table/CustomTable';
import { booleanToYesNo, formatDateTime, removeArrayElement, setArrayElement } from '../../services/appFunctionsService';
import { ISaleLine } from '../../@types/model/sales/saleLine';
import { ICompliance } from '../../@types/model/compliance/compliance';
import { ISale } from '../../@types/model/sales/sale';
import SaleHttpService from '../../services/http/sale/saleHttpService';
import { dataSetSales, dataSetSaleLineViewData } from '../../store/data/Actions';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import PackmanDialog from '../../components/dialog/PackmanDialog';
import SaleImportDialog from './SaleImportDialog';
import PillButton from '../../components/input/PillButton';
import AutocompleteSelect from '../../components/input/AutoCompleteSelect';
import { CustomChangeEvent, IOptionType } from '../../@types/helper';
import { ISaleLineView } from '../../@types/model/sales/saleLineView';
import { Dispatch, bindActionCreators } from 'redux';
import ConfirmationPrompt from '../../components/dialog/ConfirmationPrompt';
import CustomTooltip from '../../components/tooltip/tooltip';
import { syncMasterData } from '../../services/masterDataSyncService';

interface ISaleScreenProps extends RouteComponentProps {
    dataSetSales : DispatchCall<Array<ISale>>;
    dataSetSaleLineViewData : DispatchCall<Array<ISaleLineView>>;
    auth : IAuthState;
    sales : Array<ISale>;
    saleLineData : Array<ISaleLine>;
    saleLineViewData : Array<ISaleLineView>;
    compliances : Array<ICompliance>;
}

interface ISaleScreenState {
    isLoading : boolean;
    isImportDialogOpen : boolean;
    selectedSale ?: IOptionType;

    isSaleRevertConfirmationOpen : boolean;
    isEditDialogOpen : boolean;

    newFileName : string;
}

class SaleScreen extends React.Component<ISaleScreenProps, ISaleScreenState> {
    constructor(props : ISaleScreenProps) {
        super(props);

        this.state = {
            isLoading: false,
            isImportDialogOpen: false,
            isSaleRevertConfirmationOpen: false,
            isEditDialogOpen: false,

            newFileName: '',
        };
    }

    public componentDidMount = async () => {
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        try {
            const res = await SaleHttpService.getSaleData();

            if (res && res.data) {
                this.props.dataSetSales(res.data);
            }
        } catch (e) {
            generalShowErrorSnackbar('Error occured while loading sale data');
        } finally {
            this.setLoading(false);
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    private LoadSaleLines = async () => {
        if (this.state.selectedSale !== null && this.state.selectedSale !== undefined) {
            this.setLoading(true);
            try {
                const res = await SaleHttpService.getSaleLines(Number(this.state.selectedSale.value));

                if (res && res.data) {
                    this.props.dataSetSaleLineViewData(res.data);
                }
            } catch (e) {
                generalShowErrorSnackbar('Failed to load sale lines');
            } finally {
                this.setLoading(false);
            }
        }
    };

    private getSaleLines = (props : ISaleScreenProps) => props.saleLineViewData;
    private getSelectedSale = (props : ISaleScreenProps, state : ISaleScreenState) => state.selectedSale;

    private getRows = createSelector(
        [this.getSaleLines, this.getSelectedSale],
        (saleLineViewData : Array<ISaleLineView>, selectedSale : IOptionType) => {
            if (!saleLineViewData || !selectedSale) return [];

            return saleLineViewData.filter(x => x.saleId === selectedSale?.value);
        },
    );

    private getRights = (props : ISaleScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : ISaleScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    private openSaleImportDialog = () => {
        this.setState({ isImportDialogOpen: true });
    };

    private closeSaleImportDialog = () => {
        this.setState({ isImportDialogOpen: false });
    };

    private getSales = (props : ISaleScreenProps) => props.sales;

    private getSaleOptions = createSelector(
        [this.getSales],
        (sales : Array<ISale>) => {
            if (!sales) return [];

            return sales.map((x) => {
                return { label: x.importNumber, value: x.id };
            });
        },
    );

    private onSelectedSaleChange = (e : React.ChangeEvent<{}>, selectedSale : IOptionType) => {
        this.setState({ selectedSale }, () => {
            this.LoadSaleLines();
        });
    };

    private openSaleRevertConfirmationDialog = () => {
        this.setState({ isSaleRevertConfirmationOpen: true });
    };

    private closeSaleRevertConfirmationDialog = () => {
        this.setState({ isSaleRevertConfirmationOpen: false });
    };

    private onSaleRevert = async () => {
        if (this.state.selectedSale) {
            this.setLoading(true);
            try {
                const res = await SaleHttpService.revertSaleImport(Number(this.state.selectedSale.value));

                if (res && res.data) {
                    let newSaleList : Array<ISale> = [...this.props.sales];
                    const index = newSaleList.findIndex(x => x.id === Number(this.state.selectedSale?.value));
                    newSaleList = removeArrayElement(newSaleList, index);
                    dataSetSales(newSaleList);
                    this.props.dataSetSaleLineViewData([]);
                    this.setState({ selectedSale: undefined });
                    generalShowSuccessSnackbar('Imported file reverted successfully!');
                }
            } catch (e) {
                generalShowErrorSnackbar('Error occured while trying to revert selected file');
            } finally {
                this.closeSaleRevertConfirmationDialog();
                this.setLoading(false);
            }
        } else {
            generalShowErrorSnackbar('No file selected');
        }
    };

    private onEditClick = () => {
        this.setState({ isEditDialogOpen: true });
    };

    private closeEditDialog = () => {
        this.setState({ isEditDialogOpen: false, newFileName: '' });
    };

    private onFileNameChange = (event : CustomChangeEvent) => {
        this.setState({ newFileName: event.target.value });
    };

    private onFileNameChangeSubmit = async () => {
        if (this.state.selectedSale && this.state.newFileName !== '') {
            this.setLoading(true);
            try {
                const res = await SaleHttpService.changeSaleImportFileName(Number(this.state.selectedSale?.value), this.state.newFileName);

                if (res && res.data) {
                    let newSaleList : Array<ISale> = [...this.props.sales];
                    const index = newSaleList.findIndex(x => x.id === res.data.id);
                    newSaleList = setArrayElement(newSaleList, index, res.data);
                    this.props.dataSetSales(newSaleList);
                    this.setState({ selectedSale: { label: res.data.fileName, value: res.data.id } });
                    generalShowSuccessSnackbar('File name updated successfully!');
                }
            } catch (e) {
                generalShowErrorSnackbar('Error occured trying to change file name');
            } finally {
                this.closeEditDialog();
                this.setLoading(false);
            }
        }
    };

    private onSaleImportSave = (selectedSale : IOptionType) => {
        this.setState({ selectedSale });
    };

    private getSelectedSaleFileName = (id : number) => {
        const sale = this.props.sales.find(x => x.id === id);

        return sale ? sale.fileName : '';
    };

    public render() {
        return (
            <Screen isLoading={this.state.isLoading} isScrollable={false}>
                <div className={'fdc hfill'}>
                    <div className={'fdr mb10'}>
                        <CustomTooltip title={`${this.state.selectedSale ? 'Edit selected file name' : 'No file selected'}`}>
                            <div style={{ marginTop: 25 }} className={`${this.hasEditingRight(this.props) ? 'ml5' : 'dn'}`}>
                                <IconButton className={'w48 mnw48'} disabled={!this.state.selectedSale} onClick={this.onEditClick}>
                                    <Icon>edit</Icon>
                                </IconButton>
                            </div>
                        </CustomTooltip>
                        <div className={'fdc'}>
                            <div className={'flx1'}/>
                            <div className={'fdr'}>
                                <Typography className={`${this.state.selectedSale ? 'ml5 fwb' : 'dn'}`}>{'File Name: '}</Typography>
                                <Typography className={`${this.state.selectedSale ? 'ml15' : 'dn'}`}>{this.getSelectedSaleFileName(Number(this.state.selectedSale?.value) ?? 0)}</Typography>
                            </div>
                            <div className={'fdr'}>
                                <Typography className={`${this.state.selectedSale ? 'ml5 fwb' : 'dn'}`}>{'Import Number: '}</Typography>
                                <Typography className={`${this.state.selectedSale ? 'ml5' : 'dn'}`}>{this.state.selectedSale?.label}</Typography>
                            </div>
                        </div>
                        <div className={'flx1'}/>
                        <AutocompleteSelect
                            className={'w400 mb10'}
                            name={'sales'}
                            label={'Select file'}
                            options={this.getSaleOptions(this.props)}
                            onChange={this.onSelectedSaleChange}
                            value={this.state.selectedSale}
                        />
                    </div>
                    <Card className={'fdc'}>
                        <CustomTable<ISaleLineView>
                            fitWidthToPage
                            enablePagination
                            enableFiltering
                            enableSorting
                            columns={[
                                { title: 'Season', field: 'seasonCode', enableFiltering: true, enableSorting: true },
                                { title: 'Farm', field: 'farmCode', enableFiltering: true, enableSorting: true },
                                { title: 'Supplier', field: 'supplier', enableFiltering: true, enableSorting: true },
                                { title: 'Export Pallet Id', field: 'exportStockBarcode', enableFiltering: true, enableSorting: true },
                                { title: 'Commodity Group Code', field: 'commodityGroupCode', enableFiltering: true, enableSorting: true },
                                { title: 'Commodity', field: 'commodityCode', enableFiltering: true, enableSorting: true },
                                { title: 'Variety Group Code', field: 'varietyGroupCode', enableFiltering: true, enableSorting: true },
                                { title: 'Variety', field: 'varietyCode', enableFiltering: true, enableSorting: true },
                                { title: 'Mark', field: 'brandCode', enableFiltering: true, enableSorting: true },
                                { title: 'Grade', field: 'gradeCode', enableFiltering: true, enableSorting: true },
                                { title: 'Pack', field: 'packCode', enableFiltering: true, enableSorting: true },
                                { title: 'Size Count', field: 'sizeCount', enableFiltering: true, enableSorting: true },
                                { title: 'Inventory Code', field: 'inventoryCode', enableFiltering: true, enableSorting: true },
                                { title: 'In Doc No', field: 'waybillNumber', enableFiltering: true, enableSorting: true },
                                { title: 'ETD Week', field: 'estimatedTimeDepartureWeekCode', enableFiltering: true, enableSorting: true },
                                { title: 'Target Market Code', field: 'targetMarketCode', enableFiltering: true, enableSorting: true },
                                { title: 'Cartons Quantity', field: 'cartons', enableFiltering: true, enableSorting: true },
                                { title: 'Nett Factor price', field: 'nettFactorPrice', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Core Commision', field: 'coreCommision', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Overseas Cost', field: 'overseasCost', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Sea Freight Cost', field: 'seaFreightCost', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Freight Forwarding Document Cost', field: 'freightForwardingDocumentCost', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Sea Freight Rebate', field: 'seaFreightRebate', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Insurance Cost', field: 'insuranceCost', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Cooling CTF', field: 'coolingCtf', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Transport From CTF To Boat', field: 'transportFromCtfToBoat', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Bank Cost', field: 'bankCost', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'AVO levy-SAAGA', field: 'avocadoLevySaaga', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Temperatuur recorders', field: 'temperatureRecording', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Freight forwarding management fee', field: 'freightForwardingManagementFee', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Cargo Dues', field: 'cargoDues', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Rente', field: 'interest', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Verpoeling', field: 'pool', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Advice No', field: 'adviceNumber', enableFiltering: true, enableSorting: true },
                                { title: 'Total Income', field: 'totalIncome', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Channel', field: 'channel', enableFiltering: true, enableSorting: true },
                                { title: 'Country', field: 'countryCode', enableFiltering: true, enableSorting: true },
                                { title: 'Payment Date', field: 'paymentDate', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', formatFunction: booleanToYesNo, type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={this.getRows(this.props, this.state)}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={250}
                        />
                    </Card>
                    <div className={'fdr flx1 jcfe mt10'}>
                        <PillButton
                            text={'Revert'}
                            color={'secondary'}
                            className={'w250 cpd fw500 mr5 p0 aic jcc'}
                            onClick={this.openSaleRevertConfirmationDialog}
                            disabled={!this.state.selectedSale}
                        />
                        <PillButton
                            text={'Import'}
                            color={'secondary'}
                            className={'w250 cpd fw500 mr5 p0 aic jcc'}
                            onClick={this.openSaleImportDialog}
                        />
                    </div>
                </div>
                {/* Import Dialog */}
                <PackmanDialog
                    title={'Import Sales'}
                    fullScreen
                    isInfo
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isImportDialogOpen}
                    onClose={this.closeSaleImportDialog}>
                    <SaleImportDialog onClose={this.closeSaleImportDialog} onSave={this.onSaleImportSave} history={this.props.history} location={this.props.location} match={this.props.match}/>
                </PackmanDialog >
                {/* Edit Dialog */}
                <PackmanDialog
                    title={'File Name'}
                    isEdit={this.state.isEditDialogOpen}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isEditDialogOpen}
                    onClose={this.closeEditDialog}>
                    <div className={'fdc w400 p10'}>
                        <TextField
                            label={'New File Name'}
                            name={'fileName'}
                            value={this.state.newFileName}
                            onChange={this.onFileNameChange}/>
                        <div className={'fdr jcc aic mt10'}>
                            <PillButton
                                text={'Save'}
                                color={'secondary'}
                                className={'w250 cpd fw500 mr5 p0 aic jcc'}
                                onClick={this.onFileNameChangeSubmit}
                            />
                        </div>
                    </div>
                </PackmanDialog >
                <ConfirmationPrompt
                    title={'Revert import'}
                    open={this.state.isSaleRevertConfirmationOpen}
                    isLoading={this.state.isLoading}
                    message={'Are you sure you want to revert this imported file and all its data?'}
                    onOkClicked={this.onSaleRevert}
                    onCancelClicked={this.closeSaleRevertConfirmationDialog}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        sales: state.data.sales,
        saleLineData: state.data.saleLines,
        saleLineViewData: state.data.saleLineViewData,
        compliances: state.data.compliances,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetSales, dataSetSaleLineViewData }, dispatcher,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SaleScreen);
