import React, { SyntheticEvent, ChangeEvent } from 'react';
import { Dialog, Slide, AppBar, DialogContent, Toolbar, IconButton, Typography, CircularProgress, FormControl, TextField, Icon, Checkbox } from '@mui/material';

import * as userService from '../../../services/right/userService';
import { addArrayElement, removeArrayElement, setArrayElement } from '../../../services/appFunctionsService';
import { IAuthState, IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import RightsSelect from '../../../components/right/RightsSelectComponent';
import RoleDropdown from '../../../components/right/RoleDropdownComponent';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { IUserListView } from '../../../@types/model/user/userListView';
import { IRole } from '../../../@types/model/user/role';
import lodash, { forEachRight } from 'lodash';
import { IUserUpdate } from '../../../@types/model/user/userUpdate';
import CustomToggleButton from '../../../components/input/CustomToggleButton';
import { RIGHT_GRANTS } from '../../../appConstants';
import { IRightGrant } from '../../../@types/model/user/rightGrant';
import { createSelector } from 'reselect';
import { IOrganization } from '../../../@types/model/masterData/organization/organization';
import { ISite } from '../../../@types/model/masterData/site/site';
import PillButton from '../../../components/input/PillButton';
import BusinessIcon from '@mui/icons-material/Business';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import CustomTable from '../../../components/table/CustomTable';
import { generalShowErrorSnackbar } from '../../../store/general/Functions';
import CustomTooltip from '../../../components/tooltip/tooltip';
import ConfirmationPrompt from '../../../components/dialog/ConfirmationPrompt';

interface IUserEditDialogProps {
    onClose : (event : SyntheticEvent<{}, Event>) => void;
    onSaved : (entry : IUserListView) => void;
    auth : IAuthState;

    open : boolean;

    user ?: IUserListView;
    organizations : Array<IOrganization>;
    sites : Array<ISite>;
}

interface IUserEditDialogState {
    isLoading : boolean;
    isMobile : boolean;
    selectedRights : Array<IRightGrant>;
    selectedOrganizations : Array<IOrganization>;
    selectedSites : Array<ISite>;
    role ?: IRole;
    userName : string;
    employeeNumber : string;
    requiresAuth : boolean;
    isActive : boolean;

    disablePillButton : boolean;
    isOrgAndSiteDialogOpen : boolean;
    isResetPasswordDialogOpen : boolean;
}

class UserEditDialog extends React.Component<IUserEditDialogProps, IUserEditDialogState> {

    constructor(props : IUserEditDialogProps) {
        super(props);

        this.state = {
            isLoading: false,
            userName: '',
            employeeNumber: '',
            requiresAuth: false,
            isActive: false,
            isMobile: false,
            disablePillButton: false,
            selectedRights: [],
            selectedOrganizations: [],
            selectedSites: [],
            isOrgAndSiteDialogOpen: false,
            isResetPasswordDialogOpen: false,
        };
    }

    public componentDidUpdate(prevProps : IUserEditDialogProps) {
        if (!prevProps.open && this.props.open && this.props.user) {
            this.setState({
                isLoading: true,
            });
            this.loadUser();
        }

        if ((prevProps.user?.organizationIds !== this.props.user?.organizationIds) || (prevProps.user?.siteIds !== this.props.user?.siteIds)) {
            this.setState({
                selectedOrganizations: this.getOrganizations(this.props).filter(x => this.props.user?.organizationIds?.some(y => y === x.id)),
                selectedSites: this.getSites(this.props).filter(x => x.isActive && x.isPackhouse && this.props.user?.siteIds?.some(y => y === x.id)),
            });
        }

        if (prevProps.open && !this.props.open) {
            this.setState({
                isLoading: false,
                selectedRights: [],
                userName: '',
                employeeNumber: '',
                requiresAuth: false,
                isActive: false,
                selectedOrganizations: [],
                selectedSites: [],
            });
        }
    }

    private getOrganizations = (props : IUserEditDialogProps) => props.organizations.filter(x => x.isActive);
    private getSites = (props : IUserEditDialogProps) => props.sites;
    private getSelectedOrganizations = (props : IUserEditDialogProps, state : IUserEditDialogState) => state.selectedOrganizations;

    private getSiteOptions = createSelector(
        [this.getSites, this.getSelectedOrganizations],
        (sites, selectedOrganizations) => {
            return sites.filter(x => x.isActive && x.isPackhouse && x.organizationIds.some(y => selectedOrganizations.some(z => z.id === y)));
        });

    private loadUser = async () => {
        if (this.props.user) {
            const user = await userService.get(this.props.user.id);

            if (user) {
                this.setState({
                    selectedRights: lodash.uniq(user.userRights.filter(x => x.isActive).map((x) => {
                        return { rightId: x.rightId, grant : x.rightGrant };
                    })),
                    userName: user.name ? user.name : '',
                    employeeNumber: user.employeeNumber ? user.employeeNumber : '',
                    role: user.role,
                    requiresAuth: user.requiresAuth,
                    isActive: user.isActive,
                });
            }
            this.setState({
                isLoading: false,
            });
        }
    };

    public transition = (props : any) => {
        return <Slide direction='up' {...props} />;
    };

    public close = (event : SyntheticEvent<{}, Event>, save : boolean) => {
        if (save) {
            this.setState({
                isLoading: true,
            });
            this.save().then(async (result) => {
                this.setState({
                    isLoading: false,
                });

                if (!!result) {
                    this.props.onSaved({
                        id: result.id,
                        createdOn: result.createdOn,
                        email: result.email,
                        employeeNumber: result.employeeNumber,
                        requiresAuth: result.requiresAuth,
                        isActive: result.isActive,
                        name: result.name,
                        roleId: result.role?.id,
                        role: result.role?.name,
                        updatedOn: result.updatedOn,
                        isDeveloper: this.props.user?.isDeveloper || false,
                        organizationIds: result.organizationIds,
                        siteIds: result.siteIds,
                        rightIds: result.rights.map(x => x.id),
                        rights: result.rights,
                    });
                }
            });
        } else {
            this.props.onClose(event);
        }
    };

    private submitForm = async (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    };

    private onRightSelected = (id : number, checked : boolean) => {
        let selectedRights = [...this.state.selectedRights];

        const index = selectedRights.findIndex(x => x.rightId === id);

        if (checked && index === -1) {
            selectedRights = addArrayElement(selectedRights, { rightId: id, grant: 0 }, 'end');
        } else if (checked && index !== -1) {
            selectedRights = setArrayElement(selectedRights, index, { rightId: id, grant: 0 });
        } else if (!checked && index !== -1) {
            selectedRights = removeArrayElement(selectedRights, index);
        }

        this.setState({
            selectedRights,
        });
    };

    private getRightsGranted = (grant : number) => {
        let remainingGrant = grant;
        const grants : Array<string> = [];
        forEachRight(RIGHT_GRANTS, (x, i) => {
            if ((remainingGrant - Number(i)) >= 0) {
                remainingGrant -= (Number(i));
                grants.push(x);
            }
        });

        return grants;
    };

    private hasGrant = (granted : number, grant : string) => this.getRightsGranted(granted).some(y => y === grant);

    private onRightCheckBoxChecked = (rightId : number, checked : boolean, grant : number) => {
        let selectedRights = [...this.state.selectedRights];

        const index = selectedRights.findIndex(x => x.rightId === rightId);

        if (index !== -1) {
            const granted = selectedRights[index]?.grant ?? 0;

            const alreadyGranted = this.hasGrant(granted, RIGHT_GRANTS[grant]);

            if (!alreadyGranted && checked) {
                selectedRights = setArrayElement(selectedRights, index, { rightId, grant: granted + grant });
            } else if (!checked && alreadyGranted) {
                selectedRights = setArrayElement(selectedRights, index, { rightId, grant: granted - grant });
            }
        }

        this.setState({
            selectedRights,
        });
    };

    private setPillButtonDisabled = (disablePillButton : boolean = false) => {
        this.setState({ disablePillButton });
    };

    private employeeNumberChanged = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            employeeNumber: event.target.value.toLocaleUpperCase(),
        });
    };

    private userNameChanged = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            userName: event.currentTarget.value,
        });
    };

    private onRoleSelected = (role : IRole) => {
        const selectedRights = role.rightIds?.map((n) => {
            return { rightId: n, grant: 0 };
        });

        this.setState({
            role,
            selectedRights,
        });
    };

    public save = () => {
        if (!!!this.props.user) return Promise.resolve(null);
        if (!!!this.state.role) return Promise.resolve(null);

        if (this.state.userName === '') {
            generalShowErrorSnackbar('User name required!');
            return Promise.resolve(null);
        }

        const user : IUserUpdate = {
            id: this.props.user.id,
            employeeNumber: this.state.employeeNumber,
            name: this.state.userName,
            roleId: this.state.role.id,
            rights: [...this.state.selectedRights],
            organizationIds: [...this.state.selectedOrganizations?.map(x => x.id)],
            siteIds: [...this.state.selectedSites?.map(x => x.id)],
            requiresAuth: this.state.requiresAuth,
            isActive: this.state.isActive,
        };

        return userService.save(user);
    };

    private toggleMobile = (value : boolean) => this.setState({ isMobile: value });

    private onOrganizationSelected = (organizationOption : IOrganization) => {
        const index = this.state.selectedOrganizations.findIndex(x => x.id === organizationOption.id);
        if (index === -1) {
            this.setState(prevState => ({ selectedOrganizations: addArrayElement(prevState.selectedOrganizations, organizationOption, 'end') }));
        } else {
            const selectedSites = this.state.selectedSites;

            let newSelectedSites : Array<ISite> = [];

            selectedSites.forEach((x) => {
                if (x.organizationIds.every(y => y !== organizationOption.id)) {
                    newSelectedSites = addArrayElement(newSelectedSites, x, 'end');
                }
            });

            this.setState(prevState => ({ selectedOrganizations: removeArrayElement(prevState.selectedOrganizations, index), selectedSites: newSelectedSites }));
        }
    };

    private onSiteSelected = (siteOption : ISite) => {
        const index = this.state.selectedSites.findIndex(x => x.id === siteOption.id);
        if (index === -1) {
            this.setState(prevState => ({ selectedSites: addArrayElement(prevState.selectedSites, siteOption, 'end') }));
        } else {
            this.setState(prevState => ({ selectedSites: removeArrayElement(prevState.selectedSites, index) }));
        }
    };

    private openResetPasswordDialog = () => {
        this.setState({ isResetPasswordDialogOpen: true });
    };

    private closeResetPasswordDialog = () => {
        this.setState({ isResetPasswordDialogOpen: false });
    };

    private resetPassword = async () => {
        this.setState({ isLoading: true });
        if (!this.props.user?.id) {
            generalShowErrorSnackbar('No user found/selected.');
            return;
        }
        await userService.resetUserPassword(this.props.user?.id ?? 0);
        this.setState({ isResetPasswordDialogOpen: false, isLoading: false });
    };

    private openOrgAndSiteDialog = () => {
        this.setState({ isOrgAndSiteDialogOpen: true });
    };

    private closeOrgAndSiteDialog = () => {
        this.setState({ isOrgAndSiteDialogOpen: false });
    };

    private onAllOrganizationsSelected = () => {
        const organizations = this.getOrganizations(this.props);

        if (this.state.selectedOrganizations.length !== organizations.length) {
            this.setState({ selectedOrganizations: organizations });
        } else {
            this.setState({ selectedOrganizations: [], selectedSites: [] });
        }
    };

    private onAllSitesSelected = () => {
        const sites = this.getSiteOptions(this.props, this.state);

        if (this.state.selectedSites.length !== sites.length) {
            this.setState({ selectedSites: sites });
        } else {
            this.setState({ selectedSites: [] });
        }
    };

    private isSaveDisabled = () => {
        const { selectedOrganizations, selectedSites } = this.state;
        return (!selectedOrganizations || selectedOrganizations.length === 0 || !selectedSites || selectedSites.length === 0);
    };

    public render() {
        const { isLoading, selectedRights, employeeNumber, userName, role } = this.state;
        const { open, onClose } = this.props;
        return (
            <Dialog
                open={open}
                aria-labelledby='user-edit-dialog-title'
                aria-describedby='user-edit-dialog-description'
                TransitionComponent={this.transition}
                transitionDuration={500}
                fullScreen
                onClose={onClose}
            >
                <AppBar className='posr'>
                    <Toolbar variant={'dialog'}>
                        <Typography variant='h6' className='flx1 cw'>
                                Edit User - {!!!this.props.user ? '' : this.props.user.name}
                        </Typography>
                        <CustomTooltip title={this.isSaveDisabled() ? 'Please select atleast one organization and site' : 'Save'}>
                            <IconButton aria-label='Submit' onClick={(event : React.MouseEvent<HTMLButtonElement>) => this.close(event, true)} disabled={isLoading || this.isSaveDisabled()}>
                                <Icon className={'cw'}>done</Icon>
                            </IconButton>
                        </CustomTooltip>
                        <IconButton aria-label='Close' onClick={(event : React.MouseEvent<HTMLButtonElement>) => this.close(event, false)} disabled={isLoading}>
                            <Icon className={'cw'}>close</Icon>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent style={{ paddingLeft: 0, paddingRight: 0 }}>
                    {
                        isLoading &&
                            <div className={'posa post0 posr0 posb0 posl0 aic jcc zi1000'}>
                                <div className={'posr aic jcc h50 w50'}>
                                    <div className={'posa post0 posr0 posb0 posl0 aic jcc'}>
                                        <img height={40} src={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`} />
                                    </div>
                                    <CircularProgress color={'primary'} className={'posa post0 posr0 posb0 posl0'} size={50} />
                                </div>
                            </div>
                    }
                    {
                        !isLoading &&
                            <div className={'fdc ais'}>
                                <form className={'fdr pl25 aic'} autoComplete='off' onSubmit={event => this.submitForm(event)}>
                                    <div className={'fdc flx1'}>
                                        <div className={'fdr'}>
                                            <div className={'aic p5 pr20'}>
                                                <FormControl>
                                                    <TextField
                                                        autoComplete='off'
                                                        id='userNameChanged'
                                                        label='User Name'
                                                        value={userName}
                                                        onChange={this.userNameChanged}
                                                        margin='normal'
                                                        className={'w200'}
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'aic p5 pr20'}>
                                                <FormControl>
                                                    <TextField
                                                        autoComplete='off'
                                                        id='employeeNumberChanged'
                                                        label='Employee Number'
                                                        value={employeeNumber}
                                                        onChange={this.employeeNumberChanged}
                                                        margin='normal'
                                                        className={'w200'}
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'fdc ais p5 pr20'}>
                                                <RoleDropdown role={role} onSelected={this.onRoleSelected} required/>
                                            </div>
                                        </div>
                                        <div className={'fdr'}>
                                            <div className={'aic p5 mb10 pr20'}>
                                                <CustomToggleButton
                                                    field={'requiresAuth'}
                                                    label={'Needs Auth'}
                                                    initialValue={this.state.requiresAuth}
                                                    onChange={(event : React.FormEvent<HTMLButtonElement | HTMLInputElement | HTMLAnchorElement>, selected ?: boolean) => this.setState({ requiresAuth: selected ?? false })}
                                                    className={'w170'}/>
                                            </div>
                                            <div className={'aic p5 mb10 pr20'}>
                                                <CustomToggleButton
                                                    field={'isActive'}
                                                    label={'Is Active'}
                                                    initialValue={this.state.isActive}
                                                    onChange={(event : React.FormEvent<HTMLButtonElement | HTMLInputElement | HTMLAnchorElement>, selected ?: boolean) => this.setState({ isActive: selected ?? false })}
                                                    className={'w170'}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flx1 aic jcc h90'>
                                        <ToggleButtonGroup exclusive value={this.state.isMobile}>
                                            <ToggleButton onClick={() => this.toggleMobile(false)} className={`ToggleButtonLeft${!this.state.isMobile ? 'Selected' : ''}`} value={false}>Web</ToggleButton>
                                            <ToggleButton onClick={() => this.toggleMobile(true)} className={`ToggleButtonRight${this.state.isMobile ? 'Selected' : ''}`} value={true}>Mobile</ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                    <div className='fdc flx1 aife jcfe pr20'>
                                        <CustomTooltip title={'Select organizations and sites'}>
                                            <PillButton
                                                className={'reducedPillButtonShadow mb20'}
                                                text={'Reset Password'}
                                                color={'secondary'}
                                                onClick={this.openResetPasswordDialog}
                                            />
                                        </CustomTooltip>
                                        <CustomTooltip title={'Select organizations and sites'}>
                                            <PillButton
                                                className={'w100 reducedPillButtonShadow'}
                                                text={''}
                                                color={'secondary'}
                                                icon={<BusinessIcon/>}
                                                onClick={this.openOrgAndSiteDialog}
                                            />
                                        </CustomTooltip>
                                    </div>
                                </form>
                                {
                                    <RightsSelect
                                        selectedRights={selectedRights}
                                        onSelected={this.onRightSelected}
                                        onCheckBoxChecked={this.onRightCheckBoxChecked}
                                        disablePillButton={this.state.isLoading}
                                        setPillButtonDisable={this.setPillButtonDisabled}
                                        isMobile={this.state.isMobile} />
                                }
                            </div>
                    }
                    {this.state.isResetPasswordDialogOpen &&
                        <ConfirmationPrompt isLoading={this.state.isLoading} open={this.state.isResetPasswordDialogOpen} message={'Are you sure you want to reset this user\'s password?'}
                            onOkClicked={this.resetPassword} onCancelClicked={this.closeResetPasswordDialog}/>}
                    {this.state.isOrgAndSiteDialogOpen &&
                            <PackmanDialog
                                title='Select Organizations and Sites'
                                isInfo={true}
                                maxWidth={'lg'}
                                fullWidth
                                isLoading={this.state.isLoading}
                                isOpen={!!this.state.isOrgAndSiteDialogOpen}
                                onClose={this.closeOrgAndSiteDialog}>
                                <div className={'wfill hfill p10 fdc'}>
                                    <div className={'fdr wfill'}>
                                        <div className={'fdc flx1'}>
                                            <Typography className='p10'>Organizations</Typography>
                                            <CustomTable<IOrganization>
                                                columns={[
                                                    { title: '', field: 'id',
                                                        containerComponent: (row : IOrganization) => {
                                                            return (
                                                                <div className={'wfill hfill jcc aic'}>
                                                                    <Checkbox
                                                                        checked={!!this.state.selectedOrganizations.find(x => x.id === row.id)}
                                                                        onChange={() => this.onOrganizationSelected(row)}
                                                                    />
                                                                </div>
                                                            );
                                                        },
                                                    },
                                                    { title: 'Name', field: 'name' },
                                                    { title: 'Code', field: 'code' },
                                                    { title: 'Employee Number', field: 'employeeNumber' },
                                                ]}
                                                rows={this.getOrganizations(this.props)}
                                                pageHeight={800}
                                            />
                                            <div className='fdr jcfe mt10'>
                                                <PillButton
                                                    className={'ml10 pl10 pr10 h35 w200 reducedPillButtonShadow'}
                                                    text={!!(this.getOrganizations(this.props).length !== this.state.selectedOrganizations.length) ? 'Select All' : 'Deselect All'}
                                                    color={'secondary'}
                                                    onClick={this.onAllOrganizationsSelected}
                                                />
                                            </div>
                                        </div>
                                        <div className='w20'/>
                                        <div className={'fdc flx1'}>
                                            <Typography className='p10'>Sites</Typography>
                                            <CustomTable<ISite>
                                                columns={[
                                                    { title: '', field: 'id',
                                                        containerComponent: (row : ISite) => {
                                                            return (
                                                                <div className={'wfill hfill jcc aic'}>
                                                                    <Checkbox
                                                                        checked={!!this.state.selectedSites.find(x => x.id === row.id)}
                                                                        onChange={() => this.onSiteSelected(row)}
                                                                    />
                                                                </div>
                                                            );
                                                        },
                                                    },
                                                    { title: 'Code', field: 'code' },
                                                    { title: 'Description', field: 'description' },
                                                    { title: 'Short Description', field: 'shortDescription' },
                                                ]}
                                                rows={this.getSiteOptions(this.props, this.state)}
                                                pageHeight={800}
                                            />
                                            <div className='fdr jcfe mt10'>
                                                <PillButton
                                                    className={'ml10 pl10 pr10 h35 w200 reducedPillButtonShadow'}
                                                    disabled={this.state.selectedOrganizations.length < 1}
                                                    text={!!(this.getSiteOptions(this.props, this.state).length !== this.state.selectedSites.length) ? 'Select All' : 'Deselect All'}
                                                    color={'secondary'}
                                                    onClick={this.onAllSitesSelected}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </PackmanDialog>
                    }
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        organizations: state.masterData.organizations,
        sites: state.masterData.sites,
    };
};

export default connect(
    mapStateToProps,
)(UserEditDialog);
