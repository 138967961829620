import * as React from 'react';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetGrades } from '../../../../store/masterData/Actions';
import { formatDateTime, isNullOrWhiteSpace, upsertArrayElement } from '../../../../services/appFunctionsService';
import { IGrade, Grade } from '../../../../@types/model/masterData/grade/grade';
import { ICommodity } from '../../../../@types/model/masterData/commodity/commodity';
import GradeHttpService from '../../../../services/http/masterData/gradeHttpService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../../store/general/Functions';
import { IGradeFormValues, GradeFormValues } from '../../../../@types/model/masterData/grade/gradeFormValues';
import { FormikHelpers, Formik } from 'formik';
import { createSelector } from 'reselect';
import PackmanDialog from '../../../../components/dialog/PackmanDialog';
import GradeForm from './form/GradeForm';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../../@types/model/user/right';
import { ConfirmationDialog, CustomTable } from '@zz2/zz2-ui';
import { v4 as uuidv4 } from 'uuid';
import { setGradeMasterDataIndexedDB, syncMasterData } from '../../../../services/masterDataSyncService';

interface IGradeScreenProps extends RouteComponentProps {
    dataSetGrades : DispatchCall<Array<IGrade>>;
    selectedCommodity ?: ICommodity;
    commodities : Array<ICommodity>;
    grades : Array<IGrade>;
    auth : IAuthState;
}

interface IGradeScreenState {
    rows : Array<IGrade>;
    isLoading : boolean;
    selectedGrade ?: IGrade;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    formGuid ?: string;
    isDeletePopupOpen : boolean;
    deletingGrade ?: IGrade;
}

class GradeScreen extends React.Component<IGradeScreenProps, IGradeScreenState> {
    constructor(props : IGradeScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedGrade: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await GradeHttpService.getGradeData();

                this.props.dataSetGrades(res.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading grades.');
                this.setLoading(false);
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading : loading });
    };

    private formatCommodities = (commodityIds : Array<number>) => {
        let string = this.props.commodities
            .filter(x => commodityIds.some(y => y === x.id))
            .map(x => x.code).toString().replace(/,/g, ', ');
        if (string.length > 85) {
            string = string.substr(0, 86) + '...';
        }
        return string;
    };

    public onSubmit = async (values : IGradeFormValues) => {
        this.setLoading(true);

        let payload = { ...values };
        if (isNullOrWhiteSpace(payload.guid)) {
            payload.guid = this.state.formGuid ?? '';
        }

        try {
            const res = await GradeHttpService.addOrUpdateGrade(new Grade(payload));

            const newGradeList = upsertArrayElement(this.props.grades, res.data, x => x.id === res.data.id) ?? [];

            this.props.dataSetGrades(newGradeList);
            await setGradeMasterDataIndexedDB(newGradeList);

            if (this.state.selectedGrade) {
                generalShowSuccessSnackbar('Grade updated successfully.');
            } else {
                generalShowSuccessSnackbar('Grade added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating grade data.');
        } finally {
            this.setLoading(false);
        }
    };

    public onReset = async (formValues : IGradeFormValues, formikHelpers : FormikHelpers<IGradeFormValues>) => {
        formikHelpers.resetForm();
        this.closeDialog();
    };

    private openDeleteConfirmationPopup = (grade : IGrade) => {
        this.setState({ isDeletePopupOpen: true, deletingGrade: grade });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingGrade: undefined });
    };

    private removeGrade = async () => {
        const newGrade = this.state.deletingGrade;
        if (newGrade) {
            newGrade.isActive = false;
            this.setLoading(true);

            try {
                const res = await GradeHttpService.deleteGrade(newGrade.id);

                const newGradeList = upsertArrayElement(this.props.grades, res.data, x => x.id === res.data.id) ?? [];

                this.props.dataSetGrades(newGradeList);
                await setGradeMasterDataIndexedDB(newGradeList);

                generalShowSuccessSnackbar('Grade updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting grade.');
                newGrade.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    public getSelectedGrade = (props : IGradeScreenProps, state : IGradeScreenState) => state.selectedGrade;
    public getSelectedCommodity = (props : IGradeScreenProps) => props.selectedCommodity;
    public getCommodities = (props : IGradeScreenProps) => props.commodities;

    public getInitialFormValues = createSelector(
        [this.getSelectedGrade, this.getSelectedCommodity, this.getCommodities],
        (grade : IGrade, commodity ?: ICommodity, commodities ?: Array<ICommodity>) => {
            return new GradeFormValues(grade, commodity, commodities);
        },
    );

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            formGuid: undefined,
            selectedGrade: undefined,
        });
    };

    public openDialog = () => {
        this.setState({
            formGuid: uuidv4(),
            isDialogOpen: true,
        });
    };

    private onGradeEdit = (row : IGrade) => {
        this.setState({
            isDialogOpen: true,
            selectedGrade: row,
        });
    };

    private getRights = (props : IGradeScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IGradeScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const selectedCommodity = this.props.selectedCommodity;
        const rows = selectedCommodity && this.props.grades ? this.props.grades.filter(x => x.commodityIds.some(y => y === selectedCommodity?.id)) : this.props.grades;
        const initialValues = this.getInitialFormValues(this.props, this.state);

        return (
            <>
                <PackmanDialog
                    title={'Grade' + (this.props.selectedCommodity ? ' - (' + this.props.selectedCommodity?.code + ')' : '')}
                    isEdit={!!this.state.selectedGrade}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={GradeFormValues.formSchema}
                        component={GradeForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IGrade>
                            enableAdding={!!this.props.selectedCommodity && this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.onGradeEdit}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(grade : IGrade) => grade.isActive && this.hasEditingRight(this.props)}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            enablePagination
                            columns={[
                                { title: 'Code', field: 'code' , enableFiltering: true, enableSorting: true },
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Commodities', field: 'commodityIds', formatFunction: this.formatCommodities, width: 300, enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            fitWidthToPage
                            initialSortOrder={[{ columnName: 'id_Id', direction : 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={340}
                            isActive={(row : IGrade) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationDialog
                    title={'Delete Grade'}
                    open={this.state.isDeletePopupOpen}
                    description ={'Are you sure you want to delete this grade?'}
                    onAccept ={this.removeGrade}
                    onClose ={this.closeDeleteConfirmationPopup}
                    dialogType='orange'
                    isLoading={this.state.isLoading}
                />
            </>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        grades: state.masterData.grades,
        commodities: state.masterData.commodities,
        auth: state.auth,
    };
};

const mapDispatchToProps  = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetGrades }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(GradeScreen);
