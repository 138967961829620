import { DATE_FORMAT_DEFAULT, DATE_FORMAT_DEFAULT_NO_TIME, TIME_FORMAT_DEFAULT, DATEPICKER_FORMAT_DEFAULT, DATE_FORMAT_TIMESTAMP_FROM_API, DATEONLY_PALTRACK_FILE, DATETIME_PALTRACK_FILE, DATETIME_SECONDS_PALTRACK_FILE } from '../appConstants';
import { IMapObject, IGeom } from '../@types/other';
import lodash from 'lodash';
import moment from 'moment/moment';
import { IFirebaseTimestamp } from '../@types/firebase';
import { firestore } from './firebaseService';
import { getState } from '../store/Index';
import { ISite } from '../@types/model/masterData/site/site';
import { IUser } from '../@types/model/user/user';
import { IStockLine } from '../@types/model/stock/stockLine';
import { IStockLineCSVSummary } from '../@types/model/stock/stockLineCSVSummary';
import { IStockLineCertificateSummary } from '../@types/model/stock/stockLineCertificateSummary';
import { IStockLinePISummary } from '../@types/model/stock/stockLinePISummary';
import { FormikErrors } from 'formik';
import { ICompliance } from '../@types/model/compliance/compliance';

/**
 * Inserts value if not currently in the provided array, otherwise updates the existing value, by finding the first
 * element for which the equals comparator passes.
 *
 * @param arr
 * @param value
 * @param equals
 * @param position
 */
export function upsertArrayElement <T extends {[key : string] : any}>(arr : null | Array<T>, value : T, equals : (a : T) => boolean, position : 'start' | 'end' = 'start') : null | Array<T> {
    if (arr === null) return null;

    const index = arr.findIndex(equals);
    if (index > -1) {
        return setArrayElement(arr, index, value);
    } else {
        return addArrayElement(arr, value, position);
    }
};

export function setArrayElement <T>(arr : null | Array<T>, index : number, value : T) : Array<T> {
    if (arr === undefined) return [];
    if (arr === null) return [];

    return Object.assign([...arr], { [index]: value });
};

export function addArrayElement <T>(arr : null | Array<T>, value : T, position : string = 'start') : Array<T> {
    if (arr === undefined) return [];
    if (arr === null) return [];

    if (position === 'start') {
        return [value, ...arr];
    } else if (position === 'end') {
        return [...arr, value];
    }
    return arr;
};

export function removeArrayElement <T>(arr : null | Array<T>, index : number) : Array<T> {
    if (arr === undefined) return [];
    if (arr === null) return [];
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

export function getIndexOfArrayElement <T>(arr : null | Array<T> | any, item : any, field : string) {
    if (arr === undefined) return -1;
    if (arr === null) return -1;
    if (item === undefined || item === null) return -1;

    for (let i = 0; i < arr.length; i++) {
        if (arr[i][field] === item[field]) {
            return i;
        }
    }
    return -1;
};

export function addObjectAttribute <T>(obj : IMapObject, key : string | number, value : T) : IMapObject {
    if (typeof key === 'string') {
        const tempObj : {[key : string] : T} = {};
        tempObj[key] = value;
        return Object.assign({}, obj, tempObj);
    } else {
        const tempObj : {[key : number] : T} = {};
        tempObj[key] = value;
        return Object.assign({}, obj, tempObj);
    }
};

export function removeObjectAttribute(obj : IMapObject, key : string | number) : IMapObject {
    return lodash.omit(obj, key);
};

export function isEmptyObject(obj : IMapObject) : boolean {
    return !Object.keys(obj).length;
};

export function isNullOrWhiteSpace(value ?: string | null) {
    return !value || value.trim() === '';
};

export function zeroFill(value : string | number | undefined, length : number) {
    let strValue = value ? value.toString() : '';
    while (strValue.length < length) {
        strValue = '0' + strValue;
    }
    while (strValue.length > length) {
        strValue = strValue.slice(1, strValue.length);
    }
    return strValue;
};

export function spaceFill(value : string | number | undefined, length : number) {
    let strValue = value ? value.toString() : '';
    while (strValue.length < length) {
        strValue = strValue + ' ';
    }
    while (strValue.length > length) {
        strValue = strValue.slice(0, strValue.length - 1);
    }
    return strValue;
};

export function formatTimestamp(timeStamp : IFirebaseTimestamp) : string {
    return formatDateTime(timeStamp.seconds * 1000);
}

export function formatDateTime(dateTime : string | number) : string {
    return dateTime ? moment.utc(dateTime).local().format(DATE_FORMAT_DEFAULT) : '';
}

export function formatTimestampToDateOnly(timeStamp : IFirebaseTimestamp) : string {
    return timeStamp ? formatDateTimeToDateOnly(timeStamp.seconds * 1000) : '';
}

export function formatDateTimeToDateOnly(dateTime : string | number) : string {
    return dateTime ? moment.utc(dateTime).local().format(DATE_FORMAT_DEFAULT_NO_TIME) : '';
}

export function formatDateTimeToTimeOnly(dateTime : string | number) : string {
    return dateTime ? moment(dateTime, DATE_FORMAT_TIMESTAMP_FROM_API).local().format(TIME_FORMAT_DEFAULT) : '';
}

export function formatDateOnlyStringToTimestamp(dateTime : string) : IFirebaseTimestamp {
    return firestore.Timestamp.fromMillis(moment(dateTime, DATE_FORMAT_DEFAULT_NO_TIME).valueOf());
}

export function formatDateToSeconds(dateTime ?: string) : number {
    return moment(dateTime, DATE_FORMAT_DEFAULT_NO_TIME).valueOf();
}

export function formatMomentToDatePicker(dateTime : moment.Moment) : string {
    return moment(dateTime).format(DATEPICKER_FORMAT_DEFAULT);
}

export function formatDateTimeToDatePicker(dateTime : string) : string {
    return moment(dateTime, DATE_FORMAT_TIMESTAMP_FROM_API).format(DATEPICKER_FORMAT_DEFAULT);
}

export function formatFromDatePicker(dateTime : string) : string {
    return moment(dateTime, DATEPICKER_FORMAT_DEFAULT).format(DATE_FORMAT_DEFAULT_NO_TIME);
}

export function formatPaltrackDateTime(dateTime : string | number) : string {
    return dateTime ? moment.utc(dateTime, DATE_FORMAT_TIMESTAMP_FROM_API).local().format(DATETIME_PALTRACK_FILE) : '';
}

export function formatPaltrackDateTimeWithSeconds(dateTime : string | number) : string {
    return dateTime ? moment.utc(dateTime, DATE_FORMAT_TIMESTAMP_FROM_API).local().format(DATETIME_SECONDS_PALTRACK_FILE) : '';
}

export function formatPaltrackDateOnly(dateTime : string | number) : string {
    return dateTime ? moment.utc(dateTime, DATE_FORMAT_TIMESTAMP_FROM_API).local().format(DATEONLY_PALTRACK_FILE) : '';
}

export function toTitleCase(value : string) : string {
    const words = value.split(' ');
    if (!words || words.length === 0) {
        return '';
    }
    return words.map(word =>  (word && word.length !== 0) ? word[0].toUpperCase() + word.toLowerCase().slice(1) : '').toString().replace(/,/g, ' ');
}

export const uppercase = (value : string) => value && value.toUpperCase();
export const lowercase = (value : string) => value && value.toLowerCase();

export const camelCase = (value : string) => {
    const words = value.split(' ');
    let returnValue = '';
    words.forEach((x, i) => {
        if (i === 0) {
            returnValue = lowercase(x);
        } else {
            returnValue = returnValue + toTitleCase(x);
        }
    });
    return returnValue;
};

export const addkg = (value : number | string) => (value === undefined || value === null) ? '' : value + ' kg';
export const addea = (value : number | string) => (value === undefined || value === null) ? '' : value + ' ea';

export const compareString = (a : string, b : string) : number => {
    if (a === b) {
        return 0;
    }

    if (!a && !b) return 0;
    if (!a && b) return -1;
    if (a && !b) return 1;

    return a < b ? -1 : 1;
};

export const compareNumber = (a : number, b : number) : number => {
    if (a === b) {
        return 0;
    }

    if (!a && !b) return 0;
    if (!a && b) return -1;
    if (a && !b) return 1;

    return a < b ? -1 : 1;
};

export const compareBool = (a : boolean, b : boolean) : number => {
    if (a === b) {
        return 0;
    }
    return b ? -1 : 1;
};

export const compareDate = (a : string, b : string) : number => {
    if (a === b) {
        return 0;
    }

    if (!a && !b) return 0;
    if (!a && b) return -1;
    if (a && !b) return 1;

    let newA = a;
    let timeA;
    let newB = b;
    let timeB;

    if (a.includes(' ')) {
        const temp = a.split(' ');
        if (temp[0].includes('/')) {
            newA = temp[0];
            timeA = temp[1];
        } else {
            newA = temp[1];
            timeA = temp[0];
        }
    }

    if (b.includes(' ')) {
        const temp = b.split(' ');
        if (temp[0].includes('/')) {
            newB = temp[0];
            timeB = temp[1];
        } else {
            newB = temp[1];
            timeB = temp[0];
        }
    }

    const dayA = newA.split('/')[0];
    const dayB = newB.split('/')[0];
    const monthA = newA.split('/')[1];
    const monthB = newB.split('/')[1];
    const yearA = newA.split('/')[2];
    const yearB = newB.split('/')[2];

    if (yearA === yearB) {
        if (monthA === monthB) {
            if (dayA === dayB) {
                if (timeA && timeB) {
                    if (timeA === timeB) {
                        return 0;
                    }
                    return timeA < timeB ? -1 : 1;
                }
                return 0;
            }
            return dayA < dayB ? -1 : 1;
        }
        return monthA < monthB ? -1 : 1;
    }
    return yearA < yearB ? -1 : 1;
};

export const booleanToYesNo = (val : boolean) => {
    switch (val) {
        case true:
            return 'Yes';
        case false:
            return 'No';
        default:
            return 'No Data';
    }
};

export const getGeomLat = (geom : IGeom) => geom && geom.xCoordinate;
export const getGeomLong = (geom : IGeom) => geom && geom.yCoordinate;

export const arrToString = (val : Array<string | number >) => val ? val.map((x, i) => i < val.length - 1 ? x.toString() + ', ' : x.toString()) : '' ;

export const getUniqueSiteCodes = (sites : Array<ISite>) => {
    const uniqueCodes : Array<string> = [];
    sites.forEach((x) => {
        if (uniqueCodes.findIndex(y => y === x.code) === -1) {
            uniqueCodes.push(x.code);
        }
    });
    return uniqueCodes;
};

export const getSiteIdsForCode = (code : string, sites : Array<ISite>) => sites.filter(x => x.code === code).map(x => x.id);

export const getSiteDescription = (code : string, sites : Array<ISite>) => {
    const site = sites.find(x => x.code === code);
    return site ? site.description : 'Unknown Site';
};

export const getSiteShortDescription = (code : string, sites : Array<ISite>) => {
    const site = sites.find(x => x.code === code);
    return site ? site.shortDescription : 'Unk';
};

export const getUserName = (user : IUser) => user && user.name;

export const parseParams = (params : any) => {
    const keys = Object.keys(params);
    let options = '';

    keys.forEach((key) => {
        const isParamTypeObject = typeof params[key] === 'object';
        const isParamTypeArray = isParamTypeObject && (params[key].length >= 0);

        if (!isParamTypeObject) {
            options += `${key}=${params[key]}&`;
        }

        if (isParamTypeObject && isParamTypeArray) {
            params[key].forEach((element : any) => {
                options += `${key}=${element}&`;
            });
        }
    });

    return options ? options.slice(0, -1) : options;
};

export const getCSVStockLineSummary = (stocklines : Array<IStockLine>) => {
    const summary : Array<IStockLineCSVSummary> = [];

    stocklines.forEach((x) => {
        const index = summary.findIndex(y => (y.stockId === x.stockId) && (y.commodityId === x.commodityId) && (y.varietyId === x.varietyId) &&
            (y.packId === x.packId) && (y.sizeId === x.sizeId) && (y.gradeId === x.gradeId) && (y.farmId === x.farmId) && (y.colourId === x.colourId));

        if (index === -1) {
            summary.push({
                stockId: x.stockId,
                commodityId: x.commodityId,
                varietyId: x.varietyId,
                packId: x.packId,
                sizeId: x.sizeId,
                gradeId: x.gradeId,
                colourId: x.colourId,
                farmId: x.farmId,
                cartons: x.cartons,
                totalInners: x.totalInners,
                stockLines: [x],
            });
        } else {
            summary[index].cartons += x.cartons;
            const currentInners = summary[index].totalInners;
            if (x.totalInners) {
                summary[index].totalInners = currentInners !== undefined ? currentInners + x.totalInners : x.totalInners;

            } else {
                summary[index].totalInners = x.totalInners;
            }
            summary[index].stockLines.push(x);
        }
    });
    return summary;
};

export const getCertificateStockLineSummary = (stocklines : Array<IStockLine>) => {
    const summary : Array<IStockLineCertificateSummary> = [];

    stocklines.forEach((x) => {
        const index = summary.findIndex(y => (y.stockId === x.stockId) && (y.commodityId === x.commodityId) && (y.varietyId === x.varietyId) &&
            (y.farmId === x.farmId) && (y.packId === x.packId) && (y.sizeId === x.sizeId) && (y.orchardId === x.orchardId) && (y.gradeId === x.gradeId) && (y.colourId === x.colourId));

        if (index === -1) {
            summary.push({
                stockId: x.stockId,
                commodityId: x.commodityId,
                varietyId: x.varietyId,
                farmId: x.farmId,
                packId: x.packId,
                sizeId: x.sizeId,
                gradeId: x.gradeId,
                colourId: x.colourId,
                orchardId: x.orchardId,
                cartons: x.cartons,
                stockLines: [x],
            });
        } else {
            summary[index].cartons += x.cartons;
            summary[index].stockLines.push(x);
        }
    });
    return summary;
};

export const getStock = (stockId : number) => {
    const state = getState();
    return state.data.stocks.find(y => y.id === stockId);
};

export const getMaterialStock = (stockId : number) => {
    const state = getState();
    return state.data.materialStocks.find(y => y.id === stockId);
};

export const getPIStockLineSummary = (stocklines : Array<IStockLine>, compliance ?: ICompliance) => {
    const summary : Array<IStockLinePISummary> = [];

    stocklines.filter(x => x.isActive).forEach((x) => {
        const stock = getStock(x.stockId);
        const complianceLineLinkedToStock = compliance?.complianceLines.find(y => y.isActive && x.stockId === y.stockId);
        if (stock) {
            const index = summary.findIndex(y => (y.commodityId === x.commodityId) && (y.varietyId === x.varietyId) &&
                (y.farmId === x.farmId) && (y.packId === x.packId) && (y.sizeId === x.sizeId) && (y.gradeId === x.gradeId) &&
                (y.colourId === x.colourId) && (y.marketId === stock.marketId) && (complianceLineLinkedToStock ? y.isRejected === complianceLineLinkedToStock?.isRejected : true));

            if (index === -1) {
                summary.push({
                    commodityId: x.commodityId,
                    varietyId: x.varietyId,
                    farmId: x.farmId,
                    packId: x.packId,
                    sizeId: x.sizeId,
                    gradeId: x.gradeId,
                    colourId: x.colourId,
                    marketId: stock.marketId,
                    cartons: x.cartons,
                    stockLines: [x],
                    isRejected: complianceLineLinkedToStock ? complianceLineLinkedToStock.isRejected : false,
                });
            } else {
                summary[index].cartons += x.cartons;
                summary[index].stockLines.push(x);
            }
        }
    });
    return summary;
};

export const getFormikErrorMessage = (errors : FormikErrors<any>, field : string) : null | string => {
    const propertyPath = field.split('.');

    // Since we are traversing multiple types, it's going to be more pain trying to keep this type safe when it inherently isn't.
    let currentValue : any = errors;

    for (const property of propertyPath) {
        if (!currentValue[property]) return null;
        currentValue = currentValue[property];
    }

    return currentValue;
};
