import * as React from 'react';
import { Dialog, Typography, IconButton, Icon, Toolbar, AppBar, Accordion, AccordionSummary, AccordionDetails, CircularProgress } from '@mui/material';
import { IRootState, IAuthState } from '../../@types/redux';
import { connect } from 'react-redux';
import CustomDatePicker from '../../components/input/CustomDatePicker';
import { Form, FormApi, FormState } from 'informed';
import { isEmptyObject, setArrayElement, addArrayElement, formatMomentToDatePicker, removeArrayElement } from '../../services/appFunctionsService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import { v1 } from 'uuid';
import { dataSetOrderHeader } from '../../store/data/Functions';
import validationFunctions from '../../services/validationService';
import CustomAutoSuggest from '../../components/input/CustomAutoSuggest';
import { IDropDownOptions } from '../../@types/other';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OrderRequestForm from './OrderRequestForm';
import jQuery from 'jquery';
import CustomTable from '../../components/table/CustomTable';
import moment from 'moment';
import { IOrderHeader } from '../../@types/model/order/orderHeader';
import { IOrganization } from '../../@types/model/masterData/organization/organization';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { ISize } from '../../@types/model/masterData/size/size';
import { IColour } from '../../@types/model/masterData/colour/colour';
import { IGrade } from '../../@types/model/masterData/grade/grade';
import { IOrderRequest } from '../../@types/model/order/orderRequest';
import OrderHttpService from '../../services/http/order/orderHttpService';
import CustomTextInput from '../../components/input/CustomTextInput';

interface IOrderRequestScreenProps {
    selectedOrder ?: IOrderHeader;
    orderHeaderData : Array<IOrderHeader>;
    organizations : Array<IOrganization>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    packs : Array<IPack>;
    sizes : Array<ISize>;
    colours : Array<IColour>;
    grades : Array<IGrade>;
    isEditing ?: boolean;
    isAdding : boolean;
    onClose : () => void;
    auth : IAuthState;
}
interface IOrderRequestScreenState {
    isLoading : boolean;
    orderRequests : Array<IOrderRequest>;
    selectedOrganization : number;
    isEditing : boolean;
    editingOrderRequest ?: IOrderRequest;
    requestsUpdated ?: boolean;
    formValid : boolean;
}

class OrderRequestScreen extends React.Component<IOrderRequestScreenProps, IOrderRequestScreenState> {
    private formApi : FormApi<IOrderHeader>;
    constructor(props : IOrderRequestScreenProps) {
        super(props);

        this.state = {
            isLoading: false,
            isEditing: false,
            orderRequests: [],
            selectedOrganization: 0,
            formValid: false,
        };

    }

    public componentDidUpdate = (prevProps : IOrderRequestScreenProps) => {
        const nextProps = this.props;
        if (nextProps && nextProps.selectedOrder && (!prevProps || !prevProps.selectedOrder)) {
            const orderHeader = nextProps.selectedOrder;
            this.setState({ orderRequests: orderHeader ? orderHeader.orderRequestLines : [], selectedOrganization: orderHeader ? orderHeader.supplierId : 0 });
        }
    };

    private close = () => {
        this.setState({
            orderRequests: [],
        });
        this.props.onClose();
    };

    private onOrderRequestEdit = (row : IOrderRequest) => {
        this.setState({ isEditing : true, editingOrderRequest: row });
    };

    private onOrderRequestEditFormClose = () => {
        this.setState({
            isEditing: false,
            editingOrderRequest: undefined,
        });
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading : loading });
    };

    private submit = async () => {
        const values = this.formApi.getState().values;
        const touched = this.formApi.getState().touched;
        const valid = !this.formApi.getState().invalid;

        const existingOrderHeader = this.props.selectedOrder;

        if (!valid) {
            generalShowErrorSnackbar('Check form validation!');
            return;
        }

        if (isEmptyObject(touched) && !this.state.requestsUpdated) {
            if (this.props.isEditing) {
                generalShowErrorSnackbar('Nothing to update!');
            } else if (this.props.isAdding) {
                generalShowErrorSnackbar('Nothing to add!');
            }
            return;
        }

        if (!this.state.orderRequests.some(x => x.isActive)) {
            generalShowErrorSnackbar('No order requests supplied!');
            return;
        }

        if ((!isEmptyObject(touched) && this.state.orderRequests.some(x => x.isActive)) || this.state.requestsUpdated) {
            const newOrderHeaderModel : IOrderHeader = { ...values };
            const newOrderRequests : Array<IOrderRequest> = [...this.state.orderRequests];
            if (this.props.isEditing) {
                newOrderHeaderModel.id = this.props.selectedOrder ? this.props.selectedOrder.id : 0;
                newOrderHeaderModel.guid = existingOrderHeader ? existingOrderHeader.guid : v1();
                newOrderHeaderModel.status = existingOrderHeader ? existingOrderHeader.status : 'Pending';
            }

            if (this.props.isAdding) {
                newOrderHeaderModel.guid = v1();
                newOrderHeaderModel.status = 'Pending';
                newOrderHeaderModel.isActive = true;
            }

            this.setLoading(true);
            newOrderHeaderModel.orderRequestLines = newOrderRequests;
            try {
                const res = await OrderHttpService.addOrUpdateOrderHeader(newOrderHeaderModel);

                if (res && res.data) {
                    dataSetOrderHeader(res.data);
                    if (this.props.isEditing) {
                        generalShowSuccessSnackbar('Order Plan updated successfully.');
                        this.setLoading(false);
                        this.close();
                    } else {
                        generalShowSuccessSnackbar('Order Plan added successfully.');
                        this.setLoading(false);
                        this.close();
                    }
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred updating order request data.');
                this.setLoading(false);
            }
        }
    };

    private getOrderNumber = () => this.props.selectedOrder ? this.props.selectedOrder.id : 0;

    private getRows = () => this.state.orderRequests;

    private setFormApi = (formApi : FormApi<IOrderHeader>) => {
        this.formApi = formApi;
    };

    private organizationOptions = (type : 'client' | 'supplier') : Array<IDropDownOptions> => {
        if (!this.props.organizations) {
            return [];
        }
        const returnValue : Array<IDropDownOptions> = [];
        returnValue.push({ value: '', label: 'Select Organization', disabled: true });
        this.props.organizations.filter(x => x.isActive && (type === 'supplier' ? x.isSupplier : x.isClient)).forEach(x => returnValue.push({ value: x.id, label: x.code + '-' + x.name }));
        return returnValue;
    };

    private getCommodityName = (commodityId : number) => {
        const commodities = this.props.commodities;
        const commodity = commodities && commodities.find(x => x.id === commodityId);
        return commodity ? commodity.name : '';
    };

    private getVarietyName = (varietyId : number) => {
        const varieties = this.props.varieties;
        const variety = varieties && varieties.find(x => x.id === varietyId);
        return variety ? variety.name : '';
    };

    private getPackDescription = (packId : number) => {
        const packs = this.props.packs;
        const pack = packs && packs.find(x => x.id === packId);
        return pack ? pack.code : '';
    };

    private getSizeName = (sizeId : number) => {
        const sizes = this.props.sizes;
        const size = sizes && sizes.find(x => x.id === sizeId);
        return size ? size.name : '';
    };

    private getGradeName = (gradeId : number) => {
        const grades = this.props.grades;
        const grade = grades && grades.find(x => x.id === gradeId);
        return grade ? grade.name : '';
    };

    private pageScrolled = () => {
        const scrollArea = document.getElementById('page_scrollArea');
        const scrolledValue = scrollArea && scrollArea.scrollTop;
        const fixedElements = jQuery('div[id*=dropdown]');
        fixedElements.toArray().forEach((x) => {
            x.style.marginTop = scrolledValue ? (0 - scrolledValue).toString() + 'px' : '';
        });
    };

    private removeOrderRequestAtGuid = (guid : string) => {
        const index = this.state.orderRequests.findIndex(x => x.guid === guid);
        if (index) {
            this.setState({ orderRequests: removeArrayElement(this.state.orderRequests, index) });
        }
    };

    private getColourName = (colourId : number) => {
        const colours = this.props.colours;
        const colour = colours && colours.find(x => x.id === colourId);
        return colour ? colour.name : '';
    };

    private getColumns = () => [
        { title: 'Commodity', field: 'commodityId', formatFunction: this.getCommodityName },
        { title: 'Variety', field: 'varietyId', formatFunction: this.getVarietyName },
        { title: 'Pack', field: 'packId', formatFunction: this.getPackDescription },
        { title: 'Size', field: 'sizeId', formatFunction: this.getSizeName },
        { title: 'Grade', field: 'gradeId', formatFunction: this.getGradeName },
        { title: 'Colour', field: 'colourId', formatFunction: this.getColourName },
        { title: 'Requested Quantity', field: 'quantity' },
    ];

    private addOrderRequest = (request : IOrderRequest) => {
        const oldRequestIndex = this.state.orderRequests.findIndex(x => x.guid === request.guid);
        if (oldRequestIndex !== -1) {
            this.setState({ requestsUpdated: true, orderRequests: setArrayElement([...this.state.orderRequests], oldRequestIndex, request) });
        } else {
            this.setState({ requestsUpdated: true, orderRequests: addArrayElement([...this.state.orderRequests], request, 'end') });
        }
    };

    private generateNewOrderNumber = () => {
        return 'OR' + '_' + Number(this.props.orderHeaderData.length + 1);
    };

    private onFormChange = (state : FormState<IOrderHeader>) => {
        if (state) {
            this.setState({ formValid: !state.invalid });
        }
    };

    public render() {
        const selectedOrder = this.props.selectedOrder;
        return <div>
            <Dialog open={!!this.props.selectedOrder || this.props.isAdding} fullScreen>
                <div className={'fdc flx1 p20'}>
                    <Form getApi={this.setFormApi} onChange={this.onFormChange}>
                        <AppBar>
                            <Toolbar>
                                { !this.props.isAdding ?
                                    <Typography variant='h6' color='inherit' className={'flx1'}>
                                        {selectedOrder ? 'Order ' + selectedOrder.orderNumber : ''}
                                    </Typography>
                                    :
                                    <Typography variant='h6' color='inherit' className={'flx1'}>
                                    </Typography>
                                }
                                <IconButton color='inherit' aria-label='Submit' onClick={this.submit} disabled={this.state.isLoading}>
                                    <Icon>done</Icon>
                                </IconButton>
                                <IconButton color='inherit' aria-label='Close' onClick={this.close} disabled={this.state.isLoading}>
                                    <Icon>close</Icon>
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                        <Toolbar />
                        {this.state.isLoading &&
                            <div className={'posa post0 posr0 posb0 posl0 aic jcc zi1000'}>
                                <div className={'posr aic jcc h50 w50'}>
                                    <div className={'posa post0 posr0 posb0 posl0 aic jcc'}>
                                        <img height={40} src={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`} />
                                    </div>
                                    <CircularProgress color={'primary'} className={'posa post0 posr0 posb0 posl0'} size={50} />
                                </div>
                            </div>
                        }
                        <div className={'h20'} />
                        <div className={'fdr'}>
                            <div className={'fdc flx1'}>
                                <Typography className={'wfill StockHeading1 bocp'} color='inherit' variant='h6'>
                                    Order Header
                                </Typography>
                                <div className={'fdr mb10'}>
                                    <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                        <CustomTextInput
                                            className={'flx1'}
                                            label={'Order Number'}
                                            type='text'
                                            field='orderNumber'
                                            initialValue={selectedOrder ? selectedOrder.orderNumber : this.generateNewOrderNumber()}
                                        />
                                    </Typography>
                                    <span className={'w10'} />
                                    <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                        <CustomAutoSuggest
                                            initialValue={selectedOrder ? selectedOrder.clientId : ''}
                                            hasInitialValue={!!(selectedOrder && selectedOrder.clientId)}
                                            className={'flx1'}
                                            field={'clientId'}
                                            label={'Client'}
                                            options={this.organizationOptions('client')}
                                            placeholder={'Select Client...'}
                                            validate={validationFunctions.required}
                                            validateOnMount
                                            validateOnChange
                                            validateOnBlur
                                        />
                                    </Typography>
                                    <span className={'w10'} />
                                    <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                        <CustomAutoSuggest
                                            initialValue={selectedOrder ? selectedOrder.supplierId : ''}
                                            hasInitialValue={!!(selectedOrder && selectedOrder.supplierId)}
                                            className={'flx1'}
                                            field={'supplierId'}
                                            label={'Supplier'}
                                            options={this.organizationOptions('supplier')}
                                            placeholder={'Select Supplier...'}
                                            onValueChange={value => this.setState({ selectedOrganization: Number(value) })}
                                            validate={validationFunctions.required}
                                            validateOnMount
                                            validateOnChange
                                            validateOnBlur
                                        />
                                    </Typography>
                                    <span className={'w10'} />
                                    <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                        <CustomDatePicker
                                            className={'flx1'}
                                            label={'Order Date'}
                                            field='orderDate'
                                            initialValue={selectedOrder ? selectedOrder.orderDate : formatMomentToDatePicker(moment().utc().startOf('day'))}
                                        />
                                    </Typography>
                                    <span className={'w10'} />
                                    <Typography className={'flx1 pl10 pr10'} color='inherit' variant='subtitle1'>
                                        <CustomTextInput
                                            className={'flx1'}
                                            type='text'
                                            label={'Comment'}
                                            field='comment'
                                            initialValue={selectedOrder ? selectedOrder.comment : ''}
                                        />
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </Form>
                    <Typography className={'wfill StockHeading1 bocp'} color='inherit' variant='h6'>
                        Order Requests
                    </Typography>
                    <div className={'oya fdc pl20 pr20 hfill'} id={'page_scrollArea'} onScroll={this.pageScrolled}>
                        <Accordion disabled={!this.state.formValid}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography>Capture Order Request</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className={'wfill'}>
                                    <OrderRequestForm
                                        isAdding
                                        addOrderRequest={this.addOrderRequest}
                                        selectedOrder={this.props.selectedOrder}
                                        selectedOrganization={this.state.selectedOrganization}
                                        onClose={() => null}/>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <div className={'flx1 fdr pt20 pb20'}>
                            <CustomTable<IOrderRequest>
                                columns={this.getColumns()}
                                rows={this.getRows()}
                                fitWidthToPage
                                enableDeleting={(row : IOrderRequest) => !row.id}
                                deleteFunction={(row : IOrderRequest) => this.removeOrderRequestAtGuid(row.guid)}
                                editFunction={this.onOrderRequestEdit}
                                enableEditing={() => true}
                            />
                        </div>
                    </div>
                    <Dialog open={this.state.isEditing} fullScreen>
                        <AppBar>
                            <Toolbar>
                                <Typography variant='h6' color='inherit' className={'flx1'}>
                                    {'Order ' + this.getOrderNumber() + ' - Editing Order Request'}
                                </Typography>
                                <IconButton color='inherit' aria-label='Close' onClick={this.onOrderRequestEditFormClose} disabled={this.state.isLoading}>
                                    <Icon>close</Icon>
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                        <Toolbar />
                        <div className={'flx1 fdc p20'}>
                            <OrderRequestForm
                                selectedOrderRequest={this.state.editingOrderRequest}
                                selectedOrder={this.props.selectedOrder}
                                selectedOrganization={this.state.selectedOrganization}
                                isEditing
                                addOrderRequest={this.addOrderRequest}
                                onClose={this.onOrderRequestEditFormClose}/>
                        </div>
                    </Dialog>
                </div>
            </Dialog>
        </div>;
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth : state.auth,
        orderHeaderData: state.data.orderHeaders,
        organizations: state.masterData.organizations,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        packs: state.masterData.packs,
        sizes: state.masterData.sizes,
        colours: state.masterData.colours,
        grades: state.masterData.grades,
    };
};

export default connect(
    mapStateToProps,
)(OrderRequestScreen);
