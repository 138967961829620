import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';

import NavBar from './modules/root/NavBar';
import Routes from './modules/root/Routes';
import SnackbarNotifier from './modules/root/SnackbarNotifier';
import { authSetLoggedIn, authSetLoggingIn, authSetSession } from './store/auth/Actions';
import NavDrawer from './modules/root/NavDrawer';
import { useAppDispatch, useAppSelector } from './@types/redux';
import { initializeInterceptor } from './services/httpService';
import { onSessionChanged, setOrganizationLocalStorage, setSiteLocalStorage } from './services/localStorageService';
import EmployeeNumberDialog from './components/dialog/EmployeeNumberDialog';
import PasswordDialog from './components/dialog/PasswordDialog';
import { IUserToken } from './@types/model/user/userToken';
import { CircularProgress, CssBaseline } from '@mui/material';
import Login from './modules/Login';
import { Router, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import store, { history } from './store/Index';
import PasswordRecovery from './modules/PasswordRecovery';
import OrgSiteDialog from './components/dialog/OrgSiteDialog';
import { dataSetSelectedOrganizationIds, dataSetSelectedSiteIds } from './store/data/Actions';
import { shouldSyncMasterData, syncMasterData } from './services/masterDataSyncService';
import { dataSetMasterDataSyncIsLoading } from './store/masterData/Actions';
import { generalShowErrorSnackbar, generalShowInfoSnackbar, generalShowSuccessSnackbar } from './store/general/Functions';
import materialTheme from './styles/materialTheme';

const App = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const isLoggingIn = useAppSelector<boolean>(x => x.auth.isLoggingIn);
    const session = useAppSelector<null | undefined | IUserToken>(x => x.auth.session);
    const [leftDrawerOpen, setLeftDrawerOpen] = useState(false);
    const selectedOrganizationIds = useAppSelector(x => x.data.selectedOrganizationIds);
    const selectedSiteIds = useAppSelector(x => x.data.selectedSiteIds);
    const isMasterDataSyncRunning = useAppSelector(x => x.masterData.masterDataSyncIsLoading);
    const [isOrgSiteOpen, setOrgSiteOpen] = useState(false);

    const showRecoveryScreen = location.pathname === '/reset-password';

    useEffect(() => {
        dispatch(authSetLoggingIn(true));
        onSessionChanged(async (user) => {
            if (user) { /* Logged In */
                initializeInterceptor(user.token);
                dispatch(authSetSession(user));
                dispatch(authSetLoggedIn(true));
                dispatch(authSetLoggingIn(false));

                try {
                    // checks if indexedDB master data object stores is populated to prevent any unnecessary syncs
                    const needsMasterDataSync = await shouldSyncMasterData();

                    if (needsMasterDataSync) {
                        dispatch(dataSetMasterDataSyncIsLoading(true));
                        generalShowInfoSnackbar('Master data is being synced');

                        await syncMasterData(true);

                        generalShowSuccessSnackbar('Master data sync completed.');
                        dispatch(dataSetMasterDataSyncIsLoading(false));
                    } else {
                        generalShowInfoSnackbar('Master data already synced');
                    }
                } catch (e) {
                    generalShowErrorSnackbar('An error occured while trying to sync master data.');
                }
            } else { /* Logged Out */
                initializeInterceptor('');
                dispatch(authSetLoggedIn(false));
                dispatch(authSetSession(null));
                dispatch(authSetLoggingIn(false));
            }
        });

    }, []);

    useEffect(() => {
        if (session?.user.organizationIds.length === 1) {
            setOrganizationLocalStorage(session?.user.organizationIds);
            dispatch(dataSetSelectedOrganizationIds(session?.user.organizationIds));
        }
        if (session?.user.siteIds.length === 1) {
            setSiteLocalStorage(session?.user.siteIds);
            dispatch(dataSetSelectedSiteIds(session?.user.siteIds));
        }
    }, [session, session?.user, session?.user.organizationIds, session?.user.siteIds]);

    useEffect(() => {
        if (selectedOrganizationIds !== undefined && selectedSiteIds !== undefined) {
            if (((selectedOrganizationIds?.length ?? 0) <= 0) || ((selectedSiteIds?.length ?? 0) <= 0)) {
                setOrgSiteOpen(true);
            }
        }
    }, [selectedOrganizationIds, selectedSiteIds]);

    const toggleLeftDrawer = () => setLeftDrawerOpen(!leftDrawerOpen);
    const closeLeftDrawer = () => setLeftDrawerOpen(false);
    const closeOrgSite = () => setOrgSiteOpen(false);

    return (
        <ThemeProvider theme={materialTheme}>
            <Provider store={store}>
                <Router history={history}>
                    <SnackbarNotifier />
                    <CssBaseline />
                    { isLoggingIn ? (
                        <div className={'fdc hfill aic jcc'}>
                            <div className={'posr aic jcc h50 w50'}>
                                <div className={'posa post0 posr0 posb0 posl0 aic jcc'}>
                                    <img height={40} src={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`} />
                                </div>
                                <CircularProgress color={'primary'} className={'posa post0 posr0 posb0 posl0'} size={50} />
                            </div>
                        </div>
                    ) : (session ? (
                        <div className={'fdc hfill wfill oh'}>
                            <NavBar toggleLeftDrawer={toggleLeftDrawer} />
                            <div className={'fdc hfill wfill oa'}>
                                <NavDrawer toggleDrawer={toggleLeftDrawer} closeDrawer={closeLeftDrawer} open={leftDrawerOpen} side={'left'} /> {/* For Navigation */}
                                {!isMasterDataSyncRunning ? (
                                    <Routes />
                                ) : (
                                    <div className={'fdc hfill aic jcc'}>
                                        <div className={'posr aic jcc h50 w50'}>
                                            <div className={'posa post0 posr0 posb0 posl0 aic jcc'}>
                                                <img height={40} src={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`} />
                                            </div>
                                            <CircularProgress color={'primary'} className={'posa post0 posr0 posb0 posl0'} size={50} />
                                        </div>
                                    </div>
                                )}
                            </div>
                            { !!session && !!session.user &&
                                <EmployeeNumberDialog open={!session.user.employeeNumber && !session.user.isDeveloper} />
                            }
                            { !!session && !!session.user &&
                                <OrgSiteDialog isOpen={isOrgSiteOpen} onClose={closeOrgSite}/>
                            }
                            { !!session && !!session.user &&
                                <PasswordDialog open={!session.user.password} />
                            }
                        </div>) : (
                        showRecoveryScreen ?
                            <PasswordRecovery/> :
                            <Login/>
                    ))
                    }
                </Router>
            </Provider>
        </ThemeProvider>);

};

export default App;
