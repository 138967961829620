import * as React from 'react';
import { v1 } from 'uuid';
import { Form, FormikProps } from 'formik';
import { RecipeFormValues } from '../../../../@types/model/integration/form/recipeFormValues';
import PillButton from '../../../../components/input/PillButton';
import Button from '@mui/material/Button';
import FormTextInput from '../../../../components/input/form/FormTextInput';
import FormSingleToggleButton from '../../../../components/input/form/FormSingleToggleButton';
import { Card, CircularProgress } from '@mui/material';
import FormAutocompleteSelect from '../../../../components/input/form/FormAutoCompleteSelect';
import RecipeHttpService from '../../../../services/http/integration/recipe/recipeHttpService';
import { generalShowErrorSnackbar } from '../../../../store/general/Functions';
import { IOptionType } from '../../../../@types/helper';
import { orderBy } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators, createSelector, Dispatch } from '@reduxjs/toolkit';
import { IRootState, RootAction } from '../../../../@types/redux';
import { IRecipeField } from '../../../../@types/model/integration/recipeField';
import CustomTable from '../../../../components/table/CustomTable';
import { IRecipeLine } from '../../../../@types/model/integration/recipeLine';
import { addArrayElement } from '../../../../services/appFunctionsService';
import { IRecipe } from '../../../../@types/model/integration/recipe';
import { IRecipeLineFormValues } from '../../../../@types/model/integration/form/recipeLineFormValues';

interface IRecipeFormProps {
    isLoading : boolean;
    recipeTables : Array<IOptionType>;
    recipes : Array<IRecipe>;
}

interface IRecipeFormState {
    isLoading : boolean;
    recipeFields : Array<IRecipeField>;
    collectionFields : Array<IRecipeField>;
}

const collectionTypesWithFields = ['min', 'max'];

type RecipeFormPropsType = IRecipeFormProps & FormikProps<RecipeFormValues>;

class RecipeForm extends React.Component<RecipeFormPropsType, IRecipeFormState> {

    constructor(props : RecipeFormPropsType) {
        super(props);

        this.state = {
            isLoading: false,
            recipeFields: [],
            collectionFields: [],
        };
    }

    public componentDidMount = async () => {
        this.setLoading(true);
        try {
            const res = await RecipeHttpService.getFields(this.getLatestTable(this.props) ?? '');

            this.setState({ recipeFields: res?.data ?? [] });
            this.setLoading(false);
        } catch (e) {
            generalShowErrorSnackbar('An error occurred while loading recipe fields.');
            this.setLoading(false);
        }
    };

    public componentDidUpdate = async (prevProps : RecipeFormPropsType) => {
        const nextProps = this.props;

        if (!!nextProps.values.startingTable
            && (this.getLatestTable(prevProps) !== this.getLatestTable(nextProps))
            && !this.isAtTermination(nextProps)) {
            this.setLoading(true);
            try {
                const res = await RecipeHttpService.getFields(this.getLatestTable(nextProps) ?? '');

                this.setState({ recipeFields: res?.data ?? [] });
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading recipe fields.');
                this.setLoading(false);
            }
        }
    };

    private getRecipeLines = (props : RecipeFormPropsType) => props.values.recipeLines.filter(x => x.isActive);
    private getInitialRecipeLines = (props : RecipeFormPropsType) => props.initialValues.recipeLines.filter(x => x.isActive);
    private getRecipes = (props : RecipeFormPropsType) => props.recipes.filter(x => x.isActive);
    private getRecipeId = (props : RecipeFormPropsType) => props.values.id;
    private getStartingTable = (props : RecipeFormPropsType) => props.values.startingTable;
    private getFieldName = (props : RecipeFormPropsType) => props.values.fieldName;
    private getCallRecipe = (props : RecipeFormPropsType) => props.values.callRecipe;
    private getCollectionMethod = (props : RecipeFormPropsType) => props.values.collectionMethod;
    private getCollectionField = (props : RecipeFormPropsType) => props.values.collectionField;
    private getCollectionFields = (props : RecipeFormPropsType, state : IRecipeFormState) => state.collectionFields;
    private getRecipeFields = (props : RecipeFormPropsType, state : IRecipeFormState) => state.recipeFields;

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    private isAtTermination = createSelector(
        [this.getRecipeLines, this.getRecipes],
        (lines, recipes) => {
            const latestLine = orderBy(lines, x => x.order, 'desc')[0];

            return (latestLine?.fieldType === 'value')
                || ((latestLine?.fieldType === 'array') && (latestLine?.collectionMethod === 'array'))
                || ((latestLine?.fieldType === 'recipe') && (this.isTermination(recipes, latestLine?.callRecipeId)));
        },
    );

    private isTermination : (recipes : Array<IRecipe>, recipeId ?: number) => boolean = (recipes : Array<IRecipe>, recipeId ?: number) => {
        const recipe = recipes.find(x => x.id === recipeId);
        if (!recipe) {
            return true;
        }
        const latestLine = orderBy(recipe.recipeLines, x => x.order, 'desc')[0];

        return (latestLine?.fieldType === 'value')
            || ((latestLine?.fieldType === 'array') && (latestLine?.collectionMethod === 'array'))
            || ((latestLine?.fieldType === 'recipe') && (this.isTermination(recipes, latestLine?.callRecipeId)));
    };

    private getField = createSelector(
        [this.getFieldName, this.getRecipeFields],
        (fieldName, fields) => fields.find(x => x.name === fieldName?.value),
    );

    private isAtCollection = createSelector(
        [this.getField],
        field => field?.fieldType === 'array',
    );

    private getCollectionFieldOptions = createSelector(
        [this.getField, this.getCollectionFields],
        (field, fields) => {
            if (field?.fieldType !== 'array') {return []; }

            return fields?.map((x) => {
                return {
                    value: x.name,
                    label: x.name,
                };
            }) ?? [];
        }
    );

    private hasCollectionField = collectionTypesWithFields.some(x => x === this.getCollectionMethod(this.props)?.value);

    private getLatestTable = createSelector(
        [this.getRecipeLines, this.getStartingTable],
        (lines, startingTable) => orderBy(lines, x => x.order, 'desc')[0]?.tableName ?? startingTable?.value.toString(),
    );

    private getFieldOptions = createSelector(
        [this.isAtTermination, this.getRecipeFields],
        (isAtTermination, recipeFields) => {
            if (isAtTermination) {return []; }

            return recipeFields.map((x) => {
                return {
                    value: x.name,
                    label: x.name,
                };
            });
        }
    );

    private getCollectionMethodOptions = () => [
        { value: 'max', label: 'Max' },
        { value: 'min', label: 'Min' },
        { value: 'array', label: 'Array' },
        { value: 'first', label: 'First' },
        { value: 'last', label: 'Last' },
    ];

    private getRecipeOptions = createSelector(
        [this.getRecipes, this.getLatestTable, this.isAtTermination, this.getRecipeId],
        (recipes, latestTable, isAtTermination, id) => {
            if (isAtTermination) {return []; }

            return recipes.filter(x => (x.startingTable === latestTable) && (x.id !== id)).map((x) => {
                return {
                    value: x.id,
                    label: x.name,
                };
            });
        }
    );

    private onAddRecipeLine = () => {
        const recipeLines = this.getRecipeLines(this.props);

        if (!this.getCallRecipe(this.props) && !this.getFieldName(this.props)) {return; }

        const field = this.state.recipeFields.find(x => x.name === this.getFieldName(this.props)?.value);

        const callRecipe = this.props.recipes.find(x => x.id === this.getCallRecipe(this.props)?.value);

        const tableName = field?.tableName ?? orderBy(callRecipe?.recipeLines, x => x.order, 'desc')[0]?.tableName ?? callRecipe?.startingTable;

        const newLine : IRecipeLineFormValues = {
            id: 0,
            guid: v1(),
            order: (this.getLastRecipeLineOrder(this.props) ?? 0) + 1,
            fieldName: field?.name,
            tableName,
            callRecipeId: callRecipe?.id,
            fieldType: field?.fieldType ?? 'recipe',
            recipeId: this.getRecipeId(this.props) ?? 0,
            isActive: true,

            collectionField: this.getCollectionField(this.props)?.value?.toString() ?? '',
            collectionMethod: this.getCollectionMethod(this.props)?.value?.toString() ?? '',
        };

        this.props.setFieldValue('recipeLines', addArrayElement(recipeLines, newLine, 'end'));
        this.props.setFieldValue('fieldName', undefined);
        this.props.setFieldValue('callRecipe', undefined);

    };

    private getLastRecipeLineOrder = createSelector(
        [this.getRecipeLines],
        lines => orderBy(lines, x => x.order, 'desc')[0]?.order,
    );

    private deleteRecipeLine = (row : IRecipeLine) => {
        const recipeLines = [...this.getRecipeLines(this.props)];
        const newRow = recipeLines.find(x => x.guid === row.guid);

        if (newRow) {newRow.isActive = false; }

        this.props.setFieldValue('recipeLines', recipeLines);
        this.props.setFieldValue('fieldName', undefined);
        this.props.setFieldValue('callRecipe', undefined);
    };

    private onRecipeFieldChange = async (value : IOptionType) => {
        const field = this.getRecipeFields(this.props, this.state).find(x => x.name === value?.value);
        if (field?.fieldType === 'array') {
            const res = await RecipeHttpService.getFields(field?.tableName ?? '');
            this.setState({ collectionFields: res.data });
        } else {
            this.setState({ collectionFields: [] });
            this.props.setFieldValue('collectionField', undefined);
            this.props.setFieldValue('collectionMethod', undefined);
        }
    };

    private getRecipeName = (id : number) => this.props.recipes?.find(x => x.id === id)?.name ?? '';

    public render() {
        return (
            <Form className={'p20 wfill hfill'}>
                { this.state.isLoading &&
                    <div className={'posa post0 posr0 posb0 posl0 aic jcc zi1000'}>
                        <div className={'posr aic jcc h50 w50'}>
                            <div className={'posa post0 posr0 posb0 posl0 aic jcc'}>
                                <img height={40} src={`${ASSET_BASE}/assets/images/ZZ2_Pallets.png`} />
                            </div>
                            <CircularProgress color={'primary'} className={'posa post0 posr0 posb0 posl0'} size={50} />
                        </div>
                    </div>
                }
                <div className='fdr wfill'>
                    <FormTextInput className='flx1' name={'name'} label={'Name'}/>
                    <div className={'w30'}/>
                    <FormAutocompleteSelect className='flx1' name={'startingTable'} label={'Starting Table'} options={this.props.recipeTables}/>
                </div>
                <Card className={'fdc wfill aic mb10'}>
                    <div className='fdr wfill aic pr10 pl10'>
                        <FormAutocompleteSelect disabled={this.isAtTermination(this.props) || !!this.getCallRecipe(this.props) || !this.getStartingTable(this.props) } className={'flx1'} name={'fieldName'} onChange={this.onRecipeFieldChange} label={'Field'} options={this.getFieldOptions(this.props, this.state)}></FormAutocompleteSelect>
                        <div className={'w30'}>OR</div>
                        <FormAutocompleteSelect disabled={this.isAtTermination(this.props) || !!this.getFieldName(this.props) || !this.getStartingTable(this.props)} className={'flx1'} name={'callRecipe'} label={'Call Recipe'} options={this.getRecipeOptions(this.props)}></FormAutocompleteSelect>
                    </div>
                    <div className='fdr wfill pr10 pl10'>
                        <FormAutocompleteSelect disabled={this.isAtTermination(this.props) || !!this.getCallRecipe(this.props) || !this.getStartingTable(this.props) || !this.isAtCollection(this.props, this.state)} className={'flx1'} name={'collectionMethod'} label={'Collection Method'} options={this.getCollectionMethodOptions()}></FormAutocompleteSelect>
                        <div className={'w30'}/>
                        <FormAutocompleteSelect disabled={this.isAtTermination(this.props) || !!this.getCallRecipe(this.props) || !this.getStartingTable(this.props) || !this.isAtCollection(this.props, this.state)} className={'flx1'} name={'collectionField'} label={'Collection Field'} options={this.getCollectionFieldOptions(this.props, this.state)}></FormAutocompleteSelect>
                    </div>
                    <div className='fdr wfill pb10 pr10 pl10'>
                        <div className='flx1'/>
                        <PillButton
                            disabled={!this.props.dirty || !this.props.isValid || this.state.isLoading || this.props.isLoading || this.isAtTermination(this.props)}
                            className={'ml15 pl20 pr20 h35 mr15'}
                            onClick={this.onAddRecipeLine}
                            text={'Add'}
                            color={'secondary'}
                        />
                    </div>
                </Card>
                <Card className={'fdc'}>
                    <CustomTable<IRecipeLine>
                        enableDeleting={(recipe : IRecipeLine) => recipe.isActive && recipe.order === this.getLastRecipeLineOrder(this.props)}
                        deleteFunction={this.deleteRecipeLine}
                        fitWidthToPage
                        columns={[
                            { title: 'Order', field: 'order' },
                            { title: 'Field Name', field: 'fieldName' },
                            { title: 'Table Name', field: 'tableName' },
                            { title: 'Field Type', field: 'fieldType' },
                            { title: 'Collection Method', field: 'collectionMethod' },
                            { title: 'Collection Field', field: 'collectionField' },
                            { title: 'Call Recipe', field: 'callRecipeId', formatFunction: this.getRecipeName },
                        ]}
                        rows={this.getRecipeLines(this.props) ?? this.getInitialRecipeLines(this.props)}
                        initialSortOrder={[{ columnName: 'order_Order', direction: 'asc' }]}
                        pageSizes={[50, 150, 250, 500, 1000]}
                        pageHeight={190}
                        isActive={(row : IRecipeLine) => row.isActive}
                    />
                </Card>
                <FormSingleToggleButton name={'isActive'} label={'Is Active'} />
                <div className={'fdr ml10 ais jcfe pt10 pb10'}>
                    <Button
                        className={'fwb h35'}
                        variant='text'
                        type={'reset'}>
                        Clear
                    </Button>
                    <PillButton
                        disabled={!this.props.dirty || !this.props.isValid || this.state.isLoading || this.props.isLoading}
                        className={'ml15 pl20 pr20 h35'}
                        text={'Save'}
                        type={'submit'}
                        color={'secondary'}
                    />
                </div>
            </Form>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        recipeTables: state.data.recipeTables,
        recipes: state.data.recipes,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RecipeForm);
