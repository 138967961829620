import * as React from 'react';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetCarriers } from '../../../store/masterData/Actions';
import { formatDateTime, isNullOrWhiteSpace, upsertArrayElement } from '../../../services/appFunctionsService';
import { ICarrier, Carrier } from '../../../@types/model/masterData/carrier/carrier';
import CarrierHttpService from '../../../services/http/masterData/carrierHttpService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import Screen from '../../../components/Screen';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikHelpers } from 'formik';
import { CarrierFormValues, ICarrierFormValues } from '../../../@types/model/masterData/carrier/carrierFormValues';
import { createSelector } from 'reselect';
import CarrierForm from './form/CarrierForm';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import { v4 as uuidv4 } from 'uuid';
import { setCarrierMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface ICarrierScreenProps extends RouteComponentProps {
    dataSetCarriers : DispatchCall<Array<ICarrier>>;
    carrierData : Array<ICarrier>;
    auth : IAuthState;
}

interface ICarrierScreenState {
    rows : Array<ICarrier>;
    isLoading : boolean;
    selectedCarrier ?: ICarrier;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    formGuid ?: string;
    isDeletePopupOpen : boolean;
    deletingCarrier ?: ICarrier;
}

class CarrierScreen extends React.Component<ICarrierScreenProps, ICarrierScreenState> {
    constructor(props : ICarrierScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedCarrier: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await CarrierHttpService.getCarrierData();

                this.props.dataSetCarriers(res.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading carriers.');
                this.setLoading(false);
            }
        }
    };

    public editCarrier = (carrier : ICarrier) => {
        this.setState({
            isDialogOpen: true,
            selectedCarrier: carrier,
        });
        this.openDialog();
    };

    public openDialog = () => {
        this.setState({
            formGuid: uuidv4(),
            isDialogOpen: true,
        });
    };

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            formGuid: undefined,
            selectedCarrier: undefined,
        });
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    public getSelectedCarrier = (props : ICarrierScreenProps, state : ICarrierScreenState) => state.selectedCarrier;

    public getInitialFormValues = createSelector(
        [this.getSelectedCarrier],
        (carrier : ICarrier) => {
            return new CarrierFormValues(carrier);
        },
    );

    public onReset = async (formValues : ICarrierFormValues, formikHelpers : FormikHelpers<ICarrierFormValues>) => {
        formikHelpers.resetForm();
        this.closeDialog();
    };

    public onSubmit = async (values : ICarrierFormValues) => {
        this.setLoading(true);

        let payload = { ...values };
        if (isNullOrWhiteSpace(payload.guid)) {
            payload.guid = this.state.formGuid ?? '';
        }

        try {
            const res = await CarrierHttpService.addOrUpdateCarrier(new Carrier(payload));

            const newCarrierList = upsertArrayElement(this.props.carrierData, res.data, x => x.id === res.data.id) ?? [];
            await setCarrierMasterDataIndexedDB(newCarrierList);
            this.props.dataSetCarriers(newCarrierList);

            if (this.state.selectedCarrier) {
                generalShowSuccessSnackbar('Carrier updated successfully.');
            } else {
                generalShowSuccessSnackbar('Carrier added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating carrier data.');
        } finally {
            this.setLoading(false);
        }
    };

    private openDeleteConfirmationPopup = (carrier : ICarrier) => {
        this.setState({ isDeletePopupOpen: true, deletingCarrier: carrier });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingCarrier: undefined });
    };

    private removeCarrier = async () => {
        const newCarrier = this.state.deletingCarrier;
        if (newCarrier) {
            newCarrier.isActive = false;
            this.setLoading(true);

            try {
                const res = await CarrierHttpService.deleteCarrier(newCarrier.id);

                const newCarrierList = upsertArrayElement(this.props.carrierData, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetCarriers(newCarrierList);
                await setCarrierMasterDataIndexedDB(newCarrierList);

                generalShowSuccessSnackbar('Carrier updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting carrier.');
                newCarrier.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    private getRights = (props : ICarrierScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : ICarrierScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.props.carrierData ? this.props.carrierData : [];
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Carrier'
                    isEdit={!!this.state.selectedCarrier}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={CarrierFormValues.formSchema}
                        component={CarrierForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<ICarrier>
                            enableAdding={this.hasEditingRight(this.props)}
                            enableDeleting={(carrier : ICarrier) => carrier.isActive && this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.editCarrier}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableSorting
                            enableFiltering
                            fitWidthToPage
                            enablePagination
                            columns={[
                                { title: 'Code', field: 'code', enableFiltering: true, enableSorting: true },
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : ICarrier) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationDialog
                    title={'Delete Carrier'}
                    open={this.state.isDeletePopupOpen}
                    description ={'Are you sure you want to delete this carrier?'}
                    onAccept ={this.removeCarrier}
                    onClose ={this.closeDeleteConfirmationPopup}
                    dialogType='orange'
                    isLoading={this.state.isLoading}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        carrierData: state.masterData.carriers,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetCarriers }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CarrierScreen);
