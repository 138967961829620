import { IRootState, IAuthState, DispatchCall, RootAction } from '../../../@types/redux';
import React from 'react';
import { connect } from 'react-redux';
import * as userService from '../../../services/right/userService';
import { Paper } from '@mui/material';
import { getIndexOfArrayElement, addArrayElement, setArrayElement, formatDateTime, compareDate, booleanToYesNo } from '../../../services/appFunctionsService';
import UserEditDialog from './UserEditComponent';
import CustomTable from '../../../components/datagrid/CustomTable';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import { IUserListView } from '../../../@types/model/user/userListView';
import { IRole } from '../../../@types/model/user/role';
import materialTheme from '../../../styles/materialTheme';
import * as httpService from '../../../services/httpService';
import ConfirmationPrompt from '../../../components/dialog/ConfirmationPrompt';
import PillButton from '../../../components/input/PillButton';
import { authSignOutGoogle } from '../../../store/auth/Functions';
import { setLocalStorageSession } from '../../../services/localStorageService';
import * as roleService from '../../../services/right/roleService';
import Screen from '../../../components/Screen';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import UserAddComponent from './UserAddComponent';
import DomainHttpService from '../../../services/right/domainHttpService';
import { IDomain } from '../../../@types/model/user/domain';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetDomains } from '../../../store/masterData/Actions';
import * as rightService from '../../../services/right/rightService';
import { IRight } from '../../../@types/model/user/right';
import AutocompleteSelect from '../../../components/input/AutoCompleteSelect';
import { createSelector } from 'reselect';
import { IOptionType } from '../../../@types/helper';
import { getIconLocation } from '../../../services/iconHelperService';

interface IUserListProps {
    auth : IAuthState;
    dataSetDomains : DispatchCall<Array<IDomain>>;
    domains : Array<IDomain>;
}

interface IUserListState {
    isLoading : boolean;

    userList : Array<IUserListView>;
    rightsList : Array<IRight>;

    selectedRightOptions : Array<IOptionType>;

    editItem ?: IUserListView;
    deleteItem ?: IUserListView;
    logoutItem ?: IUserListView;

    isAddDialogOpen : boolean;

    showSingleUserLogoutDialog : boolean;
    showAllUserLogoutDialog : boolean;

    role ?: IRole;
    employeeNumber : string;

    isDeleting : boolean;

    onYesClick : () => void;
    onNoClick : () => void;
    message : string;
    showPrompt : boolean;
}

class UserList extends React.Component<IUserListProps, IUserListState> {
    constructor(props : IUserListProps) {
        super(props);

        this.state = {
            isLoading: true,
            userList: [],
            rightsList: [],
            editItem: undefined,
            deleteItem: undefined,
            logoutItem: undefined,
            selectedRightOptions: [],

            isAddDialogOpen: false,

            showSingleUserLogoutDialog: false,
            showAllUserLogoutDialog: false,

            employeeNumber: '',

            isDeleting: false,

            onYesClick: () => null,
            onNoClick: () => null,
            message: '',
            showPrompt: false,
        };
    }

    public componentDidMount = async () => {
        try {
            const res = await DomainHttpService.getDomainData();
            if (res.data) {
                this.props.dataSetDomains(res.data);
            }
        } catch (ex) {
            generalShowErrorSnackbar('Error occured while loading domain data');
        }

        await roleService.getList(undefined, undefined, true);
        await this.loadData();
    };

    private loadData = async (selectedRightOptions ?: Array<IOptionType>) => {
        this.setLoading(true);
        const rightIds = (selectedRightOptions ?? this.state.selectedRightOptions).length > 0 ? (selectedRightOptions ?? this.state.selectedRightOptions).map(x => Number(x.value)) : undefined;
        try {
            await userService.getUserScreenListWithAllRights(rightIds).then((result) => {
                rightService.getList(undefined, undefined, undefined).then((rights) => {
                    this.setState({
                        userList: result,
                        rightsList: rights,
                        isLoading: false,
                    });
                });
            });
            this.setLoading(false);
        } catch (ex) {
            generalShowErrorSnackbar('Error occured while loading user data');
            this.setLoading(false);
        }
    };

    private setLoading = (isLoading : boolean = false) => {
        this.setState({
            isLoading,
        });
    };

    private setEditItem = (item ?: IUserListView) => {
        this.setState({
            editItem: item,
        });
    };

    private setSelectedRightOptions = (rights : Array<IOptionType>) => {
        this.setState({
            selectedRightOptions: rights,
        });
    };

    private setDeleteItem = (item ?: IUserListView) => {
        this.setState({
            deleteItem: item,
            isDeleting: true,
        });
    };

    private setSingleUserLogoutItem = (item ?: IUserListView) => {
        this.setState({
            logoutItem: item,
            showSingleUserLogoutDialog: true,
        });
    };

    private onDeleteYesClick = async () => {
        this.setState({ isLoading: true });

        const userId = this.state.deleteItem?.id;

        if (userId) {
            const res = await userService.deleteUser(userId);

            if (res) {
                this.onDeleteNoClick();

                await this.loadData();

                generalShowSuccessSnackbar('User deleted successfully.');
            }
        }
    };

    private onDeleteNoClick = () => {
        this.setState({
            isLoading: false,
            deleteItem: undefined,
            isDeleting: false,
        });
    };

    private onSinglyUserLogoutNoClick = () => {
        this.setState({
            logoutItem: undefined,
            showSingleUserLogoutDialog: false,
        });
    };

    private onSingleUserLogoutYesClick = async () => {
        this.setState({ isLoading: true });

        const currentUser = !!this.state.logoutItem?.id && (this.props.auth.session?.userId === this.state.logoutItem?.id);

        if (currentUser) {
            this.onSinglyUserLogoutNoClick();
            await authSignOutGoogle();
            return;
        }

        const userIds = this.state.userList.filter(x => x.id === this.state.logoutItem?.id).map(x => x.id);

        const res = await httpService.logoutUsers(userIds);

        this.onSinglyUserLogoutNoClick();

        if (res.status === 200) {
            await this.loadData();

            generalShowSuccessSnackbar('User was logged out successfully!');
        }
    };

    private onAllUserLogoutNoClick = () => {
        this.setState({
            showAllUserLogoutDialog: false,
        });
    };

    private onAllUserLogoutYesClick = async () => {
        this.setState({ isLoading: true });

        const userIds = this.state.userList.map(x => x.id);

        this.onAllUserLogoutNoClick();

        const res = await httpService.logoutUsers(userIds);

        await setLocalStorageSession(null);

        if (res.status === 200) {
            await this.loadData();

            generalShowSuccessSnackbar('All users logged out successfully!');
        }
    };

    private onFilteredUserLogoutYesClick = async () => {
        this.setState({ isLoading: true });

        const userIds = this.state.userList.filter(x => x.email === null).map(x => x.id);

        this.closeConfirmationPrompt();

        const res = await httpService.logoutUsers(userIds);

        await setLocalStorageSession(null);

        if (res.status === 200) {
            await this.loadData();

            generalShowSuccessSnackbar('All users logged out successfully!');
        }
    };

    private onItemSaved = (item : IUserListView) => {
        const index = getIndexOfArrayElement(this.state.userList, item, 'id');

        let items = [];
        if (index > -1) {
            items = setArrayElement<IUserListView>(this.state.userList, index, item);
        } else {
            items = addArrayElement<IUserListView>(this.state.userList, item, 'end');
        }

        this.setState(
            {
                userList: items,
                editItem: undefined,
            },
            () => generalShowSuccessSnackbar('User saved successfully!'));
    };

    private refresh = async () => {
        this.setState({
            isLoading: true,
        });

        await this.loadData();
    };

    private getRoleName = (role : IRole) => {
        if (role && role.name) return role.name;
        return role;
    };

    private showConfirmationPrompt = (onYesClick : () => void, onNoClick : () => void, message : string) => {
        this.setState({ onYesClick, onNoClick, message }, () => {
            this.setState({ showPrompt: true });
        });
    };

    private closeConfirmationPrompt = () => {
        this.setState({ onYesClick: () => null, onNoClick: () => null, message: '', showPrompt: false });
    };

    private onAdd = () => {
        this.setState({ isAddDialogOpen: true });
    };

    private closeAddUserDialog = () => {
        this.setState({ isAddDialogOpen: false });
    };

    private getUsers = (props : IUserListProps, state : IUserListState) => state.userList;
    private getRights = (props : IUserListProps, state : IUserListState) => state.rightsList;

    private getUserList = createSelector(
        [this.getUsers],
        (users) => {
            return users;
        },
    );

    private getRightOptions = createSelector(
        [this.getRights],
        (rights) => {
            return rights.map((x) => {
                return { label: `${x.parentId ? `(${this.getParentName(x.parentId ?? 0)})` : ''} ${x.name}`, value: x.id };
            });
        },
    );

    private onRightsChange = async (e : React.ChangeEvent<{}>, selectedRights : Array<IOptionType>) => {
        this.setSelectedRightOptions(selectedRights);
        await this.loadData(selectedRights);
    };

    private getParentName = (parentId : number) => {
        const rights = this.state.rightsList;
        const parent = rights && rights.find(x => x.id === parentId);
        return parent ? parent.name : '';
    };

    private setNewUsers = (newUsers : Array<IUserListView>) => {
        const existingUsers = this.state.userList;
        let updatedUserList : Array<IUserListView> = [];
        newUsers.forEach((x) => {
            const index = existingUsers.findIndex(y => y.id === x.id);

            if (index === -1) {
                updatedUserList = addArrayElement(existingUsers, x);
            }
        });

        this.setState({ userList: updatedUserList });
    };

    public render() {
        const { editItem } = this.state;

        return (
            <Screen isPadded={false} isScrollable={false} isLoading={this.state.isLoading}>
                <div className={'wfill hfill fdc pt20 pl20 pr20 pb130'}>
                    <div className={'fdr jcfe mr20'}>
                        <AutocompleteSelect
                            className={'w300'}
                            name={'rights'}
                            label={'Rights'}
                            options={this.getRightOptions(this.props, this.state)}
                            onChange={this.onRightsChange}
                            isMulti
                            value={this.state.selectedRightOptions ?? []}
                        />
                    </div>
                    <div style={{ position: 'absolute', right: 20, bottom: 20 }} className={'fdr zi999'}>
                        <PillButton
                            text={'LOGOUT USERS NOT ON FLOOR'}
                            color={'secondary'}
                            className={'w250 cpd fw500 mr5 p0 aic jcc'}
                            onClick={() => this.showConfirmationPrompt(this.onFilteredUserLogoutYesClick, this.closeConfirmationPrompt, 'Are you sure you want to logout all users not on the floor?')}
                        />
                        <PillButton
                            text={'LOGOUT All USERS'}
                            color={'secondary'}
                            className={'flx1 cpd fw500'}
                            onClick={() => this.showConfirmationPrompt(this.onAllUserLogoutYesClick, this.closeConfirmationPrompt, 'Are you sure you want to logout all the users?')}
                        />
                    </div>
                    <Paper className='hfill'>
                        <CustomTable<IUserListView>
                            enableEditing={() => true}
                            editColor={materialTheme.palette.primary.main}
                            editFunction={this.setEditItem}
                            enableAdding={true}
                            addFunction={this.onAdd}
                            enableSorting
                            enableFiltering
                            enablePagination
                            enableRefresh
                            enableDetails={(user : IUserListView) => user.isActive}
                            detailIcon={<img className={'h20 w20 pr2 pb2'} src={`${getIconLocation()}/user_logout.svg`} />}
                            detailTooltip={'Logout user?'}
                            detailFunction={this.setSingleUserLogoutItem}
                            detailsColor={materialTheme.palette.primary.main}
                            refreshFunction={this.refresh}
                            enableDeleting={(user : IUserListView) => user.isActive}
                            deleteColor={materialTheme.palette.primary.main}
                            deleteFunction={this.setDeleteItem}
                            fitWidthToPage
                            columns={[
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Email', field: 'email', enableFiltering: true, enableSorting: true },
                                { title: 'Employee Number', field: 'employeeNumber', enableFiltering: true, enableSorting: true },
                                { title: 'Role', field: 'role', formatFunction: this.getRoleName, enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', formatFunction: formatDateTime, sortFunction: compareDate, enableFiltering: true, enableSorting: true },
                                { title: 'Needs Auth?', field: 'requiresAuth', formatFunction: booleanToYesNo, type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', formatFunction: booleanToYesNo, type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={this.getUserList(this.props, this.state)}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={250}
                            initialSortOrder={[{ columnName:'name_Name', direction: 'asc' }]}
                            isActive={(row : IUserListView) => row.isActive}
                        />
                    </Paper>
                </div>
                {this.state.isAddDialogOpen &&
                    <PackmanDialog
                        title='Add User'
                        isInfo={true}
                        maxWidth={'md'}
                        fullWidth
                        isLoading={this.state.isLoading}
                        isOpen={this.state.isAddDialogOpen}
                        onClose={this.closeAddUserDialog}>
                        <UserAddComponent
                            userList={this.state.userList}
                            setNewUserList={users => this.setNewUsers(users)}
                            onClose={this.closeAddUserDialog} isLoading={this.state.isLoading}
                            setIsLoading={(value : boolean) => this.setState({ isLoading: value })}
                            domains={this.props.domains}/>
                    </PackmanDialog>
                }
                <ConfirmationPrompt open={this.state.showPrompt} message={this.state.message}
                    onOkClicked={this.state.onYesClick} onCancelClicked={this.state.onNoClick}/>
                <ConfirmationPrompt title={'Delete User'} open={this.state.isDeleting} message={'Are you sure you want to delete this user?'}
                    onOkClicked={this.onDeleteYesClick} onCancelClicked={this.onDeleteNoClick}/>
                <ConfirmationPrompt open={this.state.showSingleUserLogoutDialog} message={'Are you sure you want to logout this user?'}
                    onOkClicked={this.onSingleUserLogoutYesClick} onCancelClicked={this.onSinglyUserLogoutNoClick}/>
                <UserEditDialog open={!!editItem} user={editItem!} onClose={() => this.setEditItem(undefined)} onSaved={item => this.onItemSaved(item)} />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        domains: state.masterData.domains,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetDomains }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserList);
