import axios from 'axios';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../store/general/Functions';
import { parseParams } from '../appFunctionsService';
import { IBooleanResponse } from '../../@types/other';
import { PACKMAN_API_URL } from '../../appConstants';
import { IUserListView } from '../../@types/model/user/userListView';
import { IUser } from '../../@types/model/user/user';
import { IMobileUser } from '../../@types/model/user/mobileUser';
import { IUserUpdate } from '../../@types/model/user/userUpdate';
import { IUserAdd } from '../../@types/model/user/UserAdd';
import { IUserToken } from '../../@types/model/user/userToken';
import qs from 'qs';

const USER_API_PATH = `${PACKMAN_API_URL}Rights/User/`;

export async function getList() : Promise<Array<IUserListView>> {
    try {
        const result = await axios.get<Array<IUserListView>>(`${USER_API_PATH}GetList`, {
            params : {
            },
        });

        return result.data;
    } catch (ex) {
        if (!!ex && ex.status === 400) {
            generalShowErrorSnackbar(ex.data);
        } else if (!!ex && ex.status !== 401 && ex.status !== 403) {
            generalShowErrorSnackbar('Error getting users');
        }
        return [];
    }
}

export async function getUserScreenListWithAllRights(rightIds ?: Array<number>) : Promise<Array<IUserListView>> {
    try {
        const result = await axios.get<Array<IUserListView>>(`${USER_API_PATH}GetUserScreenList`, {
            params : {
                rightIds,
            },
            paramsSerializer: qs.stringify,
        });

        return result.data;
    } catch (ex) {
        if (!!ex && ex.status === 400) {
            generalShowErrorSnackbar(ex.data);
        } else if (!!ex && ex.status !== 401 && ex.status !== 403) {
            generalShowErrorSnackbar('Error getting users');
        }
        return [];
    }
}

export async function get(id : number) : Promise<IUser | undefined> {
    try {
        const result = await axios.get<IUser>(`${USER_API_PATH}Get`, {
            params : {
                id,
            },
        });

        return result.data;
    } catch (ex) {
        if (!!ex && ex.status === 400) {
            generalShowErrorSnackbar(ex.data);
        } else if (!!ex && ex.status !== 401 && ex.status !== 403) {
            generalShowErrorSnackbar('Error getting user');
        }
    }
}

export async function addUsers(userData : Array<IUserAdd>) : Promise<Array<IUserListView>> {
    try {
        const result = await axios.post(`${USER_API_PATH}AddUsers`, userData);

        return result.data;
    } catch (ex) {
        if (!!ex && ex.status === 400) {
            generalShowErrorSnackbar(ex.data.Message);
        } else if (!!ex && ex.status !== 401 && ex.status !== 403) {
            generalShowErrorSnackbar('Error adding users');
        }
        return [];
    }
}

export async function save(userUpdate : IUserUpdate) : Promise<IUser | null> {
    try {
        const result = await axios.post<IUser>(`${USER_API_PATH}Save`, userUpdate);

        return result.data;
    } catch (ex) {
        if (!!ex && ex.status === 400 && typeof(ex.data.Message) === 'string') {
            generalShowErrorSnackbar(ex.data.Message);
        } else if (!!ex && ex.status !== 401 && ex.status !== 403) {
            generalShowErrorSnackbar('Error saving user');
        }
        return null;
    }
}

export async function requestForgottenPasswordEmail(usernameOrEmail : string) : Promise<boolean> {
    try {
        await axios.post(`${USER_API_PATH}RequestForgottenPasswordEmail`, null, {
            params : { usernameOrEmail },
        });

        return true;
    } catch (ex) {
        if (!!ex && ex.status === 400 && typeof(ex.data) === 'string') {
            generalShowErrorSnackbar(ex.data);
        } else if (!!ex && ex.status !== 401 && ex.status !== 403) {
            generalShowErrorSnackbar('An error occurred trying to recover your password');
        }
        return false;
    }
}

export async function resetPassword(recoveryCode : string, newPassword : string, email : string) : Promise<IUserToken | null> {
    try {
        const result = await axios.post<IUserToken | null>(`${USER_API_PATH}ResetPassword`, {
            recoveryCode,
            newPassword,
            email,
        });

        return result.data;
    } catch (ex) {
        if (!!ex && ex.status === 400 && typeof(ex.data) === 'string') {
            generalShowErrorSnackbar(ex.data);
        } else if (!!ex && ex.status !== 401 && ex.status !== 403) {
            generalShowErrorSnackbar('An error occurred trying to update your password');
        }
        return null;
    }
}

export async function resetUserPassword(userId : number) : Promise<boolean> {
    try {
        const result = await axios.post<IUserToken | null>(`${USER_API_PATH}ResetUserPassword`, {
            userId,
        });

        if (result.status === 200) {
            generalShowSuccessSnackbar('User password updated successfully');
            return true;
        }

        generalShowErrorSnackbar('An error occurred trying to update the user\'s password');
        return false;
    } catch (ex) {
        if (!!ex && ex.status === 400 && typeof(ex.data) === 'string') {
            generalShowErrorSnackbar(ex.data);
        } else if (!!ex && ex.status !== 401 && ex.status !== 403) {
            generalShowErrorSnackbar('An error occurred trying to update the user\'s password');
        }
        return false;
    }
}

export async function deleteUser(userId : number) : Promise<boolean> {
    try {
        await axios.delete(`${USER_API_PATH}Delete`, {
            params : { userId },
        });

        return true;
    } catch (ex) {
        if (!!ex && ex.status === 400 && typeof(ex.data) === 'string') {
            generalShowErrorSnackbar(ex.data);
        } else if (!!ex && ex.status !== 401 && ex.status !== 403) {
            generalShowErrorSnackbar('Error deleting user');
        }
        return false;
    }
}

export async function validateEmployeeNumber(employeeNumber : string) : Promise<boolean> {
    try {
        const result = await axios.get<IBooleanResponse>(`${USER_API_PATH}ValidateEmployeeNumber`, {
            params : {
                employeeNumber,
            },
        });

        return result.data.result;

    } catch (ex) {
        if (!!ex && ex.status === 400) {
            generalShowErrorSnackbar(ex.data);
        } else if (!!ex && ex.status !== 401 && ex.status !== 403) {
            generalShowErrorSnackbar('Error setting employee nr.');
        }
        return false;
    }
}

export async function getMobileUserList() : Promise<Array<IMobileUser>> {
    try {
        const result = await axios.get<Array<IMobileUser>>(`${USER_API_PATH}GetMobileUsers`, {
            params : {
            },
        });

        return result.data;
    } catch (ex) {
        if (!!ex && ex.status === 400) {
            generalShowErrorSnackbar(ex.data);
        } else if (!!ex && ex.status !== 401 && ex.status !== 403) {
            generalShowErrorSnackbar('Error getting users');
        }
        return [];
    }
}

export async function saveMobileUser(mobileUserId : string, name : string, isActive : boolean) : Promise<IMobileUser | null> {
    try {
        const result = await axios.patch<IMobileUser>(`${USER_API_PATH}UpdateMobileUser`, {}, {
            params : {
                mobileUserId,
                name,
                isActive,
                // isBakkieUser,
            },
            paramsSerializer: params => parseParams(params),
        });

        return result.data;
    } catch (ex) {
        if (!!ex && ex.status === 400 && typeof(ex.data) === 'string') {
            generalShowErrorSnackbar(ex.data);
        } else if (!!ex && ex.status !== 401 && ex.status !== 403) {
            generalShowErrorSnackbar('Error saving user');
        }
        return null;
    }
}
