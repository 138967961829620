import * as React from 'react';
import { Card } from '@mui/material';
import { DispatchCall, IAuthState, IRootState, RootAction } from '../../../@types/redux';
import { dataSetStorageUnits, dataSetSites } from '../../../store/masterData/Actions';
import { bindActionCreators, Dispatch } from 'redux';
import { formatDateTime, isNullOrWhiteSpace, upsertArrayElement } from '../../../services/appFunctionsService';
import { IStorageUnit, StorageUnit } from '../../../@types/model/masterData/storageUnit/storageUnit';
import StorageUnitHttpService from '../../../services/http/masterData/storageUnitHttpService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import Screen from '../../../components/Screen';
import { connect } from 'react-redux';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikHelpers } from 'formik';
import { IStorageUnitFormValues, StorageUnitFormValues } from '../../../@types/model/masterData/storageUnit/storageUnitFormValues';
import { createSelector } from 'reselect';
import StorageUnitForm from './form/StorageUnitForm';
import { ISite } from '../../../@types/model/masterData/site/site';
import SiteHttpService from '../../../services/http/masterData/siteHttpService';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import { ConfirmationDialog, CustomTable } from '@zz2/zz2-ui';
import { v4 as uuidv4 } from 'uuid';
import { setStorageUnitMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';
import PillButton from '../../../components/input/PillButton';
import { generateStorageUnitCards, renderQRCodes } from '../../../services/http/masterData/storageUnitDocumentService';

interface IStorageUnitScreenProps extends RouteComponentProps {
    dataSetStorageUnits : DispatchCall<Array<IStorageUnit>>;
    dataSetSites : DispatchCall<Array<ISite>>;
    storageUnitData : Array<IStorageUnit>;
    siteData : Array<ISite>;
    auth : IAuthState;
    selectedSiteIds : Array<number>;
}

interface IStorageUnitScreenState {
    rows : Array<IStorageUnit>;
    isLoading : boolean;
    selectedStorageUnit ?: IStorageUnit;
    selectedStorageUnits : Array<number>;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    formGuid ?: string;
    isDeletePopupOpen : boolean;
    deletingStorageUnit ?: IStorageUnit;
}

class StorageUnitScreen extends React.Component<IStorageUnitScreenProps, IStorageUnitScreenState> {
    constructor(props : IStorageUnitScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedStorageUnit: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
            selectedStorageUnits: [],
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await StorageUnitHttpService.getStorageUnitData();

                const storageUnitData = res.data;
                if (storageUnitData) {
                    const res2 = await SiteHttpService.getSiteData();

                    const siteData = res2.data;
                    this.props.dataSetSites(siteData);
                    this.props.dataSetStorageUnits(storageUnitData);
                } else {
                    this.props.dataSetStorageUnits([]);
                }

                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading storage unit data.');
                this.setLoading(false);
            }
        }
    };

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            formGuid: undefined,
            selectedStorageUnit: undefined,
        });
    };

    public openDialog = () => {
        this.setState({
            formGuid: uuidv4(),
            isDialogOpen: true,
        });
    };

    public editStorageUnit = (storageUnit : IStorageUnit) => {
        this.setState({
            formGuid: uuidv4(),
            isDialogOpen: true,
            selectedStorageUnit: storageUnit,
        });
        this.openDialog();
    };

    public onSubmit = async (values : IStorageUnitFormValues) => {
        this.setLoading(true);

        let payload = { ...values };
        if (isNullOrWhiteSpace(payload.guid)) {
            payload.guid = this.state.formGuid ?? '';
        }

        try {
            const res = await StorageUnitHttpService.addOrUpdateStorageUnit(new StorageUnit(payload));

            const newStorageUnitList = upsertArrayElement(this.props.storageUnitData, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetStorageUnits(newStorageUnitList);
            await setStorageUnitMasterDataIndexedDB(newStorageUnitList);

            if (this.state.selectedStorageUnit) {
                generalShowSuccessSnackbar('Storage Unit updated successfully.');
            } else {
                generalShowSuccessSnackbar('Storage Unit added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating storage unit data.');
        } finally {
            this.setLoading(false);
        }
    };

    private openDeleteConfirmationPopup = (storageUnit : IStorageUnit) => {
        this.setState({ isDeletePopupOpen: true, deletingStorageUnit: storageUnit });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingStorageUnit: undefined });
    };

    private removeStorageUnit = async () => {
        const newStorageUnit = this.state.deletingStorageUnit;
        if (newStorageUnit) {
            newStorageUnit.isActive = false;
            this.setLoading(true);

            try {
                const res = await StorageUnitHttpService.deleteStorageUnit(newStorageUnit.id);

                const newStorageUnitList = upsertArrayElement(this.props.storageUnitData, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetStorageUnits(newStorageUnitList);
                await setStorageUnitMasterDataIndexedDB(newStorageUnitList);

                generalShowSuccessSnackbar('Storage Unit updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting storage unit.');
                newStorageUnit.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    public onReset = async (formValues : IStorageUnitFormValues, formikHelpers : FormikHelpers<IStorageUnitFormValues>) => {
        formikHelpers.resetForm();
        this.closeDialog();
    };

    private onStorageUnitSelect = (selectedStorageUnits : Array<number>) => this.setState({ selectedStorageUnits });

    public getSelectedStorageUnit = (props : IStorageUnitScreenProps, state : IStorageUnitScreenState) => state.selectedStorageUnit;
    public getSelectedSideIds = (props : IStorageUnitScreenProps) => props.selectedSiteIds;
    public getSites = (props : IStorageUnitScreenProps) => props.siteData;

    public getInitialFormValues = createSelector(
        [this.getSelectedStorageUnit, this.getSelectedSideIds, this.getSites],
        (storageUnit : IStorageUnit, siteIds : Array<number>, sites : Array<ISite>) => {
            const selectedSite = siteIds && siteIds.length === 1 ? sites.find(x => x.id === siteIds[0]) : undefined;
            return new StorageUnitFormValues(storageUnit, sites, selectedSite);
        },
    );

    private getSiteDescription = (siteId : number) => {
        const site = this.props.siteData?.find(x => x.id === siteId);
        return site?.description ?? '';
    };

    private getRights = (props : IStorageUnitScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IStorageUnitScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    private onStorageUnitCardGenerate = async () => {
        generateStorageUnitCards(this.props.storageUnitData.filter(x => this.state.selectedStorageUnits.some(y => y === x.id)), () => this.setState({ selectedStorageUnits: [] }));
    };

    public render() {
        const rows = this.props.storageUnitData ? this.props.storageUnitData : [];
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                {/* renderQRCodes is needed for generating storage unit cards */}
                {renderQRCodes(this.props.storageUnitData)}
                <PackmanDialog
                    title='Storage Unit'
                    isEdit={!!this.state.selectedStorageUnit}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}
                    fullScreen>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={StorageUnitFormValues.formSchema}
                        component={StorageUnitForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IStorageUnit>
                            enableAdding={this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.editStorageUnit}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(storageUnit : IStorageUnit) => storageUnit.isActive && this.hasEditingRight(this.props)}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableSelection
                            disableRowSelect={row => !row.isActive}
                            onSelectChange={this.onStorageUnitSelect}
                            selectedRows={this.state.selectedStorageUnits}
                            enableFiltering
                            fitWidthToPage
                            enablePagination
                            columns={[
                                { title: 'Code', field: 'code', enableFiltering: true, enableSorting: true },
                                { title: 'Description', field: 'description', enableFiltering: true, enableSorting: true },
                                { title: 'Raw Stock?', field: 'hasRawStock', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Tippable?', field: 'isTippable', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Packed Stock?', field: 'hasPackedStock', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Site', field: 'siteId', formatFunction: this.getSiteDescription, enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IStorageUnit) => row.isActive}
                        />
                    </Card>
                    <div className='fdr pt20 aife flx1 jcfe'>
                        <PillButton
                            className={'pl10 pr10 mr10 h35 w250 reducedPillButtonShadow'}
                            text={'Generate Storage Unit Cards'}
                            color={'secondary'}
                            disabled={this.state.selectedStorageUnits.length < 1}
                            onClick={this.onStorageUnitCardGenerate}
                        />
                    </div>
                </div>
                <ConfirmationDialog
                    title={'Delete Storage Unit'}
                    open={this.state.isDeletePopupOpen}
                    description={'Are you sure you want to delete this storage unit?'}
                    onAccept ={this.removeStorageUnit}
                    onClose ={this.closeDeleteConfirmationPopup}
                    dialogType='orange'
                    isLoading={this.state.isLoading}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        storageUnitData: state.masterData.storageUnits,
        siteData: state.masterData.sites,
        auth: state.auth,
        selectedSiteIds: state.data.selectedSiteIds,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetStorageUnits, dataSetSites }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StorageUnitScreen);
