import * as React from 'react';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetFtpDetails } from '../../../store/masterData/Actions';
import { formatDateTime, isNullOrWhiteSpace, upsertArrayElement } from '../../../services/appFunctionsService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import Screen from '../../../components/Screen';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikHelpers } from 'formik';
import { createSelector } from 'reselect';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import { ConfirmationDialog, CustomTable } from '@zz2/zz2-ui';
import { v4 as uuidv4 } from 'uuid';
import { IFtpDetail, FtpDetail } from '../../../@types/model/masterData/ftpDetail/ftpDetail';
import { IFtpDetailFormValues, FtpDetailFormValues } from '../../../@types/model/masterData/ftpDetail/ftpDetailFormValues';
import FtpDetailHttpService from '../../../services/http/masterData/ftpDetailHttpService';
import FtpDetailForm from './form/ftpDetailForm';
import { setFtpDetailMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface IFtpDetailScreenProps extends RouteComponentProps {
    dataSetFtpDetails : DispatchCall<Array<IFtpDetail>>;
    ftpDetailData : Array<IFtpDetail>;
    auth : IAuthState;
}

interface IFtpDetailScreenState {
    rows : Array<IFtpDetail>;
    isLoading : boolean;
    selectedFtpDetail ?: IFtpDetail;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    formGuid ?: string;
    isDeletePopupOpen : boolean;
    deletingFtpDetail ?: IFtpDetail;
}

class FtpDetailScreen extends React.Component<IFtpDetailScreenProps, IFtpDetailScreenState> {
    constructor(props : IFtpDetailScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedFtpDetail: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await FtpDetailHttpService.getFtpDetailData();

                this.props.dataSetFtpDetails(res.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading truck types.');
                this.setLoading(false);
            }
        }
    };

    public editFtpDetail = (ftpDetail : IFtpDetail) => {
        this.setState({
            isDialogOpen: true,
            selectedFtpDetail: ftpDetail,
        });
        this.openDialog();
    };

    public openDialog = () => {
        this.setState({
            formGuid: uuidv4(),
            isDialogOpen: true,
        });
    };

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            formGuid: undefined,
            selectedFtpDetail: undefined,
        });
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    public getSelectedFtpDetail = (props : IFtpDetailScreenProps, state : IFtpDetailScreenState) => state.selectedFtpDetail;

    public getInitialFormValues = createSelector(
        [this.getSelectedFtpDetail],
        (ftpDetail : IFtpDetail) => {
            return new FtpDetailFormValues(ftpDetail);
        },
    );

    public onReset = async (formValues : IFtpDetailFormValues, formikHelpers : FormikHelpers<IFtpDetailFormValues>) => {
        formikHelpers.resetForm();
        this.closeDialog();
    };

    public onSubmit = async (values : IFtpDetailFormValues) => {
        this.setLoading(true);

        let payload = { ...values };
        if (isNullOrWhiteSpace(payload.guid)) {
            payload.guid = this.state.formGuid ?? '';
        }

        try {
            const res = await FtpDetailHttpService.addOrUpdateFtpDetail(new FtpDetail(payload));

            const newFtpDetailList = upsertArrayElement(this.props.ftpDetailData, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetFtpDetails(newFtpDetailList);
            await setFtpDetailMasterDataIndexedDB(newFtpDetailList);

            if (this.state.selectedFtpDetail) {
                generalShowSuccessSnackbar('Ftp detail updated successfully.');
            } else {
                generalShowSuccessSnackbar('Ftp detail added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating Ftp detail data.');
        } finally {
            this.setLoading(false);
        }
    };

    private openDeleteConfirmationPopup = (ftpDetail : IFtpDetail) => {
        this.setState({ isDeletePopupOpen: true, deletingFtpDetail: ftpDetail });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingFtpDetail: undefined });
    };

    private removeFtpDetail = async () => {
        const newFtpDetail = this.state.deletingFtpDetail;
        if (newFtpDetail) {
            newFtpDetail.isActive = false;
            this.setLoading(true);

            try {
                const res = await FtpDetailHttpService.deleteFtpDetail(newFtpDetail.id);

                const newFtpDetailList = upsertArrayElement(this.props.ftpDetailData, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetFtpDetails(newFtpDetailList);
                await setFtpDetailMasterDataIndexedDB(newFtpDetailList);

                generalShowSuccessSnackbar('Ftp detail updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting Truck type.');
                newFtpDetail.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    private getRights = (props : IFtpDetailScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IFtpDetailScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.props.ftpDetailData ? this.props.ftpDetailData : [];
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Ftp Detail'
                    maxWidth={'md'}
                    isEdit={!!this.state.selectedFtpDetail}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={FtpDetailFormValues.formSchema}
                        component={FtpDetailForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IFtpDetail>
                            enableAdding={this.hasEditingRight(this.props)}
                            enableDeleting={(ftpDetail : IFtpDetail) => ftpDetail.isActive && this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.editFtpDetail}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableSorting
                            enableFiltering
                            fitWidthToPage
                            enablePagination
                            columns={[
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Address', field: 'address', enableFiltering: true, enableSorting: true },
                                { title: 'Port', field: 'port', enableFiltering: true, enableSorting: true },
                                { title: 'Path', field: 'path', enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IFtpDetail) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationDialog
                    title={'Delete Ftp Detail'}
                    open={this.state.isDeletePopupOpen}
                    description={'Are you sure you want to delete this truck type?'}
                    onAccept ={this.removeFtpDetail}
                    onClose ={this.closeDeleteConfirmationPopup}
                    dialogType='orange'
                    isLoading={this.state.isLoading}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        ftpDetailData: state.masterData.ftpDetails,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetFtpDetails }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FtpDetailScreen);
