import { IBrand } from './brand';
import * as Yup from 'yup';

export interface IBrandFormValues {
    id : number;
    guid : string;
    code : string;
    description : string;
    isActive : boolean;
}

export class BrandFormValues implements IBrandFormValues {
    public id : number;
    public guid : string;
    public code : string;
    public description : string;
    public isActive : boolean;

    public constructor(brand ?: IBrand) {
        this.initializeDefaultValues();
        if (brand) {
            this.id = brand.id;
            this.guid = brand.guid;
            this.code = brand.code;
            this.description = brand.description;
            this.isActive = brand.isActive;
        }
    }

    public initializeDefaultValues = () => {
        this.id = 0;
        this.guid = '';
        this.code = '';
        this.description = '';
        this.isActive = true;
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = Yup.object().shape({
        code: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
        isActive: Yup.boolean().required('Required'),
    });
}
