import * as React from 'react';
import { Card, Switch } from '@mui/material';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { DispatchCall, IAuthState, IRootState, RootAction } from '../../@types/redux';
import Screen from '../../components/Screen';
import CustomTable from '../../components/table/CustomTable';
import { addArrayElement, formatDateTime, removeArrayElement, setArrayElement } from '../../services/appFunctionsService';
import { ISaleLine } from '../../@types/model/sales/saleLine';
import { IInventory } from '../../@types/model/masterData/inventory/inventory';
import { ICountry } from '../../@types/model/masterData/country/country';
import { IMarket } from '../../@types/model/masterData/market/market';
import { IGrade } from '../../@types/model/masterData/grade/grade';
import { IBrand } from '../../@types/model/masterData/brand/brand';
import { IFarm } from '../../@types/model/masterData/farm/farm';
import { IStock } from '../../@types/model/stock/stock';
import { ICommodity } from '../../@types/model/masterData/commodity/commodity';
import { IVariety } from '../../@types/model/masterData/variety/variety';
import { IPack } from '../../@types/model/masterData/pack/pack';
import { ISale } from '../../@types/model/sales/sale';
import { ISeason } from '../../@types/model/masterData/season/season';
import SaleHttpService from '../../services/http/sale/saleHttpService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar, generalShowWarningSnackbar } from '../../store/general/Functions';
import FileSelector from '../../components/input/FileSelector';
import * as XLSX from 'xlsx';
import { dataSetSaleLineRelatedData } from '../../store/data/Functions';
import PillButton from '../../components/input/PillButton';
import moment from 'moment';
import { IRegion } from '../../@types/model/masterData/region/region';
import { ISaleUpsert } from '../../@types/model/sales/saleUpsert';
import { v1 } from 'uuid';
import { dataSetSales, dataSetSaleLineViewData } from '../../store/data/Actions';
import { DATEPICKER_FORMAT_DEFAULT } from '../../appConstants';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router';
import { ISaleLineView } from '../../@types/model/sales/saleLineView';
import { IOptionType } from '../../@types/helper';
import { ISaleImportTableRows } from '../../@types/model/sales/SaleImportTableRows';
import { syncMasterData } from '../../services/masterDataSyncService';

const valueBoldedStyle : React.CSSProperties = {
    fontSize: 16,
    fontWeight: 550,
};

interface ISaleImportDialogProps extends RouteComponentProps {
    dataSetSales : DispatchCall<Array<ISale>>;
    dataSetSaleLineViewData : DispatchCall<Array<ISaleLineView>>;
    auth : IAuthState;
    sales : Array<ISale>;
    saleLineData : Array<ISaleLine>;
    inventoryData : Array<IInventory>;
    countries : Array<ICountry>;
    regions : Array<IRegion>;
    markets : Array<IMarket>;
    brands : Array<IBrand>;
    grades : Array<IGrade>;
    stockData : Array<IStock>;
    farms : Array<IFarm>;
    commodities : Array<ICommodity>;
    varieties : Array<IVariety>;
    packs : Array<IPack>;
    seasons : Array<ISeason>;
    onClose : () => void;
    onSave : (value : IOptionType) => void;
}

interface ISaleImportDialogState {
    isLoading : boolean;
    selectedFiles : Array<File>;
    saleLines : Array<ISaleLine>;
    tableRows : Array<ISaleImportTableRows>;
    duplicateExportStockBarcodes : Array<string>;
    showRowsWithIncorrectData : boolean;
}

export const sheetColumns = {
    season: 'season',
    farm: 'farm',
    supplier: 'supplier',
    exportStock: 'export_plt_id',
    commodityGroupCode: 'comm_grp',
    commodity: 'commodity',
    varietyGroupCode: 'var_grp',
    variety: 'variety',
    brand: 'brand',
    grade: 'grade',
    pack: 'pack',
    sizeCount: 'size_count',
    inventory: 'inv_code',
    waybillNumber: 'in_doc_no',
    etdWeek: 'etd_week',
    targetMarket: 'targ_mkt',
    cartonsQuantity: 'ctn_qty',
    nettFactorePrice: ' Netto faktuur waarde ',
    coreCommision: ' Core kommissie ',
    overseasCost: ' Oorsese koste ',
    seaFreightCost: ' Seevrag ',
    freightForwardingDocumentationCost: ' Freight forwarding dokumentasie koste ',
    seeFreightRebate: ' Seevrag rebat ',
    insurance: ' Versekering ',
    coolingCtf: ' Verkoeling CFT ',
    transportFromCtfToBoat: ' Vervoer vanaf CFT tot in boot ',
    bankCost: ' Bank kostes ',
    avocadoLevySaaga: ' AVO levy-SAAGA ',
    temperatureRecording: ' Temperatuur recorders ',
    freightForwardingManagementFee: ' Freight forwarding management fee ',
    cargoDues: ' Cargo dues ',
    interest: 'Rente',
    pooling: ' Verpoeling ',
    adviceNumber: ' advice_no ',
    totalIncome: ' total_income ',
    channel: 'channel',
    country: 'country',
    paymentDate: 'Payment date',
};

class SaleImportDialog extends React.Component<ISaleImportDialogProps, ISaleImportDialogState> {
    constructor(props : ISaleImportDialogProps) {
        super(props);

        this.state = {
            isLoading: false,
            selectedFiles: [],
            saleLines: [],
            tableRows: [],
            duplicateExportStockBarcodes: [],
            showRowsWithIncorrectData: false,
        };
    }

    public componentDidMount = async () => {
        this.setLoading(true);
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }
        try {
            const res = await SaleHttpService.getSaleLineRelatedData(!isIndexedDBAvailable);

            if (res && res.data) {
                dataSetSaleLineRelatedData(res.data);
            }
        } catch (e) {
            generalShowErrorSnackbar('Failed to load sale line related data');
        } finally {
            this.setLoading(false);
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    private handleFileInputChange = (files : Array<File>) => {
        files.forEach(x => this.setState({ selectedFiles: addArrayElement(this.state.selectedFiles, x, 'end') }));
        this.getFileData();
    };

    private onFileRemove = (file : File) => {
        const index = this.state.selectedFiles?.findIndex(x => x.name === file.name);
        if (index > -1) {
            this.setState({ selectedFiles: removeArrayElement(this.state.selectedFiles, index), saleLines: [] });
        }
    };

    private getFileData = () => {
        const files = this.state.selectedFiles;
        let results : Array<ISaleLine> = [];
        let tableRows : Array<ISaleImportTableRows> = [];

        let seasonsNotFound : Array<string> = [];
        let farmsNotFound : Array<string> = [];
        let exportStockDataNotFound : Array<string> = [];
        let commoditiesNotFound : Array<string> = [];
        let varietiesNotFound : Array<string> = [];
        let brandsNotFound : Array<string> = [];
        let gradesNotFound : Array<string> = [];
        let packsNotFound : Array<string> = [];
        let inventoriesNotFound : Array<string> = [];
        let countriesNotFound : Array<string> = [];

        let duplicateExportStockBarcodes : Array<string> = [];

        if (files.length > 0) {
            files.forEach((file) => {
                const reader = new FileReader();
                reader.onload = () => {
                    const arrayBuffer = reader.result;
                    if (arrayBuffer && typeof arrayBuffer !== 'string') {
                        const data = new Uint8Array(arrayBuffer);
                        const arr = [];
                        for (let i = 0; i !== data.length; ++i) arr[i] = String.fromCharCode(data[i]);
                        const bstr = arr.join('');
                        const workbook = XLSX.read(bstr, { type: 'binary' });
                        const firstSheetName = workbook.SheetNames[0];
                        const worksheet = workbook.Sheets[firstSheetName];
                        const worksheetRows : Array<any> = XLSX.utils.sheet_to_json(worksheet);

                        worksheetRows.forEach((x : any) => {
                            if (x[sheetColumns.season]?.toString() !== 'Total') {
                                const season = this.props.seasons.find(y => y.code?.toString() === x[sheetColumns.season]?.toString());
                                const farm = this.props.farms.find(y => y.code?.toString() === x[sheetColumns.farm]?.toString());
                                const exportStock = this.props.stockData.find(y => y.barcode === x[sheetColumns.exportStock]);
                                const commodity = this.props.commodities.find(y => y.code?.toString() === x[sheetColumns.commodity]?.toString());
                                const variety = this.props.varieties.find(y => y.code?.toString() === x[sheetColumns.variety]?.toString());
                                const brand = this.props.brands.find(y => y.code?.toString() === x[sheetColumns.brand]?.toString());
                                const grade = this.props.grades.find(y => y.code?.toString() === x[sheetColumns.grade]?.toString());
                                const pack = this.props.packs.find(y => y.code?.toString() === x[sheetColumns.pack]?.toString());
                                const inventory = this.props.inventoryData.find(y => y.code?.toString() === x[sheetColumns.inventory]?.toString());
                                const country = this.props.countries.find(y => y.code?.toString() === x[sheetColumns.country]?.toString());
                                const targetMarket = this.props.markets.find(y => y.code?.toString() === x[sheetColumns.targetMarket]?.toString());

                                const duplicateExportStock = results.find(y => y.exportStockId === exportStock?.id);
                                if (exportStock) {
                                    if (duplicateExportStock) {
                                        const index = duplicateExportStockBarcodes.findIndex(y => y === exportStock?.barcode);
                                        if (index === -1) {
                                            duplicateExportStockBarcodes = addArrayElement(duplicateExportStockBarcodes, exportStock?.barcode, 'end');
                                        }
                                    }
                                } else {
                                    const index = exportStockDataNotFound.findIndex(y => y === x[sheetColumns.exportStock]?.toString());
                                    if (index === -1) {
                                        exportStockDataNotFound = addArrayElement(exportStockDataNotFound, x[sheetColumns.exportStock], 'end');
                                    }
                                }

                                if (!season) {
                                    const index = seasonsNotFound.findIndex(y => y === x[sheetColumns.season]?.toString());
                                    if (index === -1) {
                                        seasonsNotFound = addArrayElement(seasonsNotFound, x[sheetColumns.season], 'end');
                                    }
                                }
                                if (!farm) {
                                    const index = farmsNotFound.findIndex(y => y === x[sheetColumns.farm]?.toString());
                                    if (index === -1) {
                                        farmsNotFound = addArrayElement(farmsNotFound, x[sheetColumns.farm], 'end');
                                    }
                                }
                                if (!commodity) {
                                    const index = commoditiesNotFound.findIndex(y => y === x[sheetColumns.commodity]?.toString());
                                    if (index === -1) {
                                        commoditiesNotFound = addArrayElement(commoditiesNotFound, x[sheetColumns.commodity], 'end');
                                    }
                                }
                                if (!variety) {
                                    const index = varietiesNotFound.findIndex(y => y === x[sheetColumns.variety]?.toString());
                                    if (index === -1) {
                                        varietiesNotFound = addArrayElement(varietiesNotFound, x[sheetColumns.variety], 'end');
                                    }
                                }
                                if (!brand) {
                                    const index = brandsNotFound.findIndex(y => y === x[sheetColumns.brand]?.toString());
                                    if (index === -1) {
                                        brandsNotFound = addArrayElement(brandsNotFound, x[sheetColumns.brand], 'end');
                                    }
                                }
                                if (!grade) {
                                    const index = gradesNotFound.findIndex(y => y === x[sheetColumns.grade]?.toString());
                                    if (index === -1) {
                                        gradesNotFound = addArrayElement(gradesNotFound, x[sheetColumns.grade], 'end');
                                    }
                                }
                                if (!pack) {
                                    const index = packsNotFound.findIndex(y => y === x[sheetColumns.pack]?.toString());
                                    if (index === -1) {
                                        packsNotFound = addArrayElement(packsNotFound, x[sheetColumns.pack], 'end');
                                    }
                                }
                                if (!inventory) {
                                    const index = inventoriesNotFound.findIndex(y => y === x[sheetColumns.inventory]?.toString());
                                    if (index === -1) {
                                        inventoriesNotFound = addArrayElement(inventoriesNotFound, x[sheetColumns.inventory], 'end');
                                    }
                                }
                                if (!country) {
                                    const index = countriesNotFound.findIndex(y => y === x[sheetColumns.country]?.toString());
                                    if (index === -1) {
                                        countriesNotFound = addArrayElement(countriesNotFound, x[sheetColumns.country], 'end');
                                    }
                                }

                                if (!season || !farm || !exportStock || !commodity || !variety || !brand || !grade || !pack || !inventory || !country) {
                                    const validatedSaleLine = this.getSaleImportTableRowValues(true, x);
                                    tableRows = addArrayElement(tableRows, validatedSaleLine, 'end');
                                } else {
                                    const validatedSaleLine = this.getSaleImportTableRowValues(false, x);
                                    tableRows = addArrayElement(tableRows, validatedSaleLine, 'end');
                                }
                                const saleLine = this.getSaleLineValues(x, season, farm, exportStock, commodity, variety, brand, grade, pack, inventory, country, targetMarket);
                                results = addArrayElement(results, saleLine, 'end');
                            }
                        });

                        this.setState({
                            saleLines: results,
                            tableRows,
                            duplicateExportStockBarcodes,
                        });
                        if (duplicateExportStockBarcodes.length > 0) {
                            generalShowWarningSnackbar(`${duplicateExportStockBarcodes.length} dublicate stock ${duplicateExportStockBarcodes.length === 1 ? 'barcode' : 'barcodes'} found. Duplicate barcodes are bolded.`);
                        }
                    }
                };
                reader.readAsArrayBuffer(file);
            });
        }
    };

    private ExcelDateToJSDate = (serial : number) => {
        const utcDays  = Math.floor(serial - 25569);
        const utcValue = utcDays * 86400;
        const dateInfo = new Date(utcValue * 1000);

        const fractionalDay = serial - Math.floor(serial) + 0.0000001;

        let totalSeconds = Math.floor(86400 * fractionalDay);

        const seconds = totalSeconds % 60;

        totalSeconds -= seconds;

        const hours = Math.floor(totalSeconds / (60 * 60));
        const minutes = Math.floor(totalSeconds / 60) % 60;

        return new Date(dateInfo.getFullYear(), dateInfo.getMonth(), dateInfo.getDate(), hours, minutes, seconds);
    };

    private getSaleLineValues = (row : any, season ?: ISeason, farm ?: IFarm, exportStock ?: IStock, commodity ?: ICommodity, variety ?: IVariety, brand ?: IBrand, grade ?: IGrade, pack ?: IPack, inventory ?: IInventory, country ?: ICountry, targetMarket ?: IMarket) => {
        const result : ISaleLine = {
            id: 0,
            guid: v1(),
            isActive: true,
            saleId: 0,
            seasonId: season?.id ?? 0,
            farmId: farm?.id ?? 0,
            supplier: row[sheetColumns.supplier],
            exportStockId: exportStock?.id ?? 0,
            commodityGroupCode: row[sheetColumns.commodityGroupCode],
            commodityId: commodity?.id ?? 0,
            varietyGroupCode: row[sheetColumns.varietyGroupCode],
            varietyId: variety?.id ?? 0,
            brandId: mark?.id ?? 0,
            gradeId: grade?.id ?? 0,
            packId: pack?.id ?? 0,
            sizeCount: row[sheetColumns.sizeCount],
            inventoryId: inventory?.id ?? 0,
            waybillNumber: row[sheetColumns.waybillNumber],
            estimatedTimeDepartureWeekCode: row[sheetColumns.etdWeek],
            targetMarketId: targetMarket?.id,
            cartons: row[sheetColumns.cartonsQuantity],
            nettFactorPrice: row[sheetColumns.nettFactorePrice],
            coreCommision: row[sheetColumns.coreCommision],
            overseasCost: row[sheetColumns.overseasCost],
            seaFreightCost: row[sheetColumns.seaFreightCost],
            freightForwardingDocumentCost: row[sheetColumns.freightForwardingDocumentationCost],
            seaFreightRebate: row[sheetColumns.seeFreightRebate],
            insuranceCost: row[sheetColumns.insurance],
            coolingCtf: row[sheetColumns.coolingCtf],
            transportFromCtfToBoat: row[sheetColumns.transportFromCtfToBoat],
            bankCost: row[sheetColumns.bankCost],
            avocadoLevySaaga: row[sheetColumns.avocadoLevySaaga],
            temperatureRecording: row[sheetColumns.temperatureRecording],
            freightForwardingManagementFee: row[sheetColumns.freightForwardingManagementFee],
            cargoDues: row[sheetColumns.cargoDues],
            interest: row[sheetColumns.interest],
            pool: row[sheetColumns.pooling],
            adviceNumber: row[sheetColumns.adviceNumber].trim(),
            totalIncome: row[sheetColumns.totalIncome],
            channel: row[sheetColumns.channel],
            countryId: country?.id ?? 0,
            paymentDate: moment(this.ExcelDateToJSDate(row[sheetColumns.paymentDate])).format(DATEPICKER_FORMAT_DEFAULT),
        };

        return result;
    };

    private getSaleImportTableRowValues = (isSomethingWrong : boolean, row : any) => {
        const result : ISaleImportTableRows = {
            isSomethingWrong,
            seasonCode: this.getSeasonCode(row[sheetColumns.season]),
            farmCode: this.getFarmCode(row[sheetColumns.farm]),
            supplier: row[sheetColumns.supplier],
            exportStockBarcode: this.getExportStockBarcode(row[sheetColumns.exportStock]),
            commodityGroupCode: row[sheetColumns.commodityGroupCode],
            commodityCode: this.getCommodityCode(row[sheetColumns.commodity]),
            varietyGroupCode: row[sheetColumns.varietyGroupCode],
            varietyCode: this.getVarietyCode(row[sheetColumns.variety]),
            brandCode: this.getBrandCode(row[sheetColumns.brand]),
            gradeCode: this.getGradeCode(row[sheetColumns.grade]),
            packCode: this.getPackCode(row[sheetColumns.pack]),
            sizeCount: row[sheetColumns.sizeCount],
            inventoryCode: this.getInventoryCode(row[sheetColumns.inventory]),
            waybillNumber: row[sheetColumns.waybillNumber],
            estimatedTimeDepartureWeekCode: row[sheetColumns.etdWeek],
            targetMarketCode: this.getMarketCode(row[sheetColumns.targetMarket]),
            cartons: row[sheetColumns.cartonsQuantity],
            nettFactorPrice: row[sheetColumns.nettFactorePrice],
            coreCommision: row[sheetColumns.coreCommision],
            overseasCost: row[sheetColumns.overseasCost],
            seaFreightCost: row[sheetColumns.seaFreightCost],
            freightForwardingDocumentCost: row[sheetColumns.freightForwardingDocumentationCost],
            seaFreightRebate: row[sheetColumns.seeFreightRebate],
            insuranceCost: row[sheetColumns.insurance],
            coolingCtf: row[sheetColumns.coolingCtf],
            transportFromCtfToBoat: row[sheetColumns.transportFromCtfToBoat],
            bankCost: row[sheetColumns.bankCost],
            avocadoLevySaaga: row[sheetColumns.avocadoLevySaaga],
            temperatureRecording: row[sheetColumns.temperatureRecording],
            freightForwardingManagementFee: row[sheetColumns.freightForwardingManagementFee],
            cargoDues: row[sheetColumns.cargoDues],
            interest: row[sheetColumns.interest],
            pool: row[sheetColumns.pooling],
            adviceNumber: row[sheetColumns.adviceNumber].trim(),
            totalIncome: row[sheetColumns.totalIncome],
            channel: row[sheetColumns.channel],
            countryCode: this.getCountryCode(row[sheetColumns.country]),
            paymentDate: moment(this.ExcelDateToJSDate(row[sheetColumns.paymentDate])).format(DATEPICKER_FORMAT_DEFAULT),
        };

        return result;
    };

    private getSeasonCode = (seasonCode : string) => {
        const seasons = this.props.seasons;
        const season = seasons && seasons.find(y => y.code?.toString() === seasonCode.toString());
        return season ? season.code : `${seasonCode} Not Found`;
    };

    private getFarmCode = (farmCode : string) => {
        const farms = this.props.farms;
        const farm = farms && farms.find(x => x.code === farmCode);
        return farm ? farm.code : `${farmCode} Not Found`;
    };

    private getExportStockBarcode = (barcode : string) => {
        const stocks = this.props.stockData;
        const stock = stocks && stocks.find(x => x.barcode === barcode);
        return stock ? stock.barcode : `${barcode} Not Found`;
    };

    private getCommodityCode = (commodityCode : string) => {
        const commodities = this.props.commodities;
        const commodity = commodities && commodities.find(x => x.code === commodityCode);
        return commodity ? commodity.code : `${commodityCode} Not Found`;
    };

    private getVarietyCode = (varietyCode : string) => {
        const varieties = this.props.varieties;
        const variety = varieties && varieties.find(x => x.code === varietyCode);
        return variety ? variety.code : `${varietyCode} Not Found`;
    };

    private getBrandCode = (brandCode : string) => {
        const brands = this.props.brands;
        const brand = brands && brands.find(x => x.code === brandCode);
        return brand ? brand.code : `${brandCode} Not Found`;
    };

    private getGradeCode = (gradeCode : string) => {
        const grades = this.props.grades;
        const grade = grades && grades.find(x => x.code === gradeCode);
        return grade ? grade.code : `${gradeCode} Not Found`;
    };

    private getPackCode = (packCode : string) => {
        const packs = this.props.packs;
        const pack = packs && packs.find(x => x.code === packCode);
        return pack ? pack.code : `${packCode} Not Found`;
    };

    private getInventoryCode = (inventoryCode : string) => {
        const inventories = this.props.inventoryData;
        const inventory = inventories && inventories.find(x => x.code === inventoryCode);
        return inventory ? inventory.code : `${inventoryCode} Not Found`;
    };

    private getMarketCode = (marketCode : string) => {
        if (marketCode) {
            const markets = this.props.markets;
            const market = markets && markets.find(x => x.code === marketCode);
            return market ? market.code : `${marketCode} Not Found`;
        } else {
            return '';
        }
    };

    private getCountryCode = (countryCode : string) => {
        const countries = this.props.countries;
        const country = countries && countries.find(x => x.code === countryCode);

        return country ? country.code : `${countryCode} Not Found`;
    };

    private onSave = async () => {
        if (this.state.selectedFiles.length === 1 && this.state.saleLines.length > 0) {
            const saleImportsForTheDay = this.props.sales.filter(x => moment(x.createdOn).format('YYYYMMDD') === moment().utc(true).format('YYYYMMDD'));
            const sequence = saleImportsForTheDay && saleImportsForTheDay.length + 1;

            const fileName = this.state.selectedFiles[0].name.split('.')[0];
            const importNumber = `${fileName}-${moment().utc().format('YYYYMMDD')}-${sequence}`;

            const sale : ISaleUpsert = {
                fileName,
                importNumber,
                saleLines: this.state.saleLines,
            };

            try {
                this.setLoading(true);

                const res = await SaleHttpService.addSale(sale);

                if (res && res.data) {
                    let newSaleList : Array<ISale> = [...this.props.sales];
                    const index = newSaleList.findIndex(x => x.id === res.data.id);
                    newSaleList = setArrayElement(newSaleList, index, res.data);
                    this.props.dataSetSales(newSaleList);
                    this.props.dataSetSaleLineViewData(res.data.saleLines);

                    const newSelectedSale : IOptionType = {
                        label: res.data.importNumber,
                        value: res.data.id,
                    };

                    this.props.onSave(newSelectedSale);

                    generalShowSuccessSnackbar('File saved successfully');
                }

            } catch (e) {
                generalShowErrorSnackbar('Error occured while saving file');
            } finally {
                this.setLoading(false);
                this.props.onClose();
            }
        }
    };

    private getTableRows = (props : ISaleImportDialogProps, state : ISaleImportDialogState) => state.tableRows;
    private getShowRowsWithIncorrectData = (props : ISaleImportDialogProps, state : ISaleImportDialogState) => state.showRowsWithIncorrectData;

    private getRows = createSelector(
        [this.getTableRows, this.getShowRowsWithIncorrectData],
        (rows, showRowsWithIncorrectData : boolean) =>  {
            return rows.filter(x => !!showRowsWithIncorrectData ? !!x.isSomethingWrong : x);
        },
    );

    private toggleShowRowsWithIncorrectData = () => {
        this.setState({ showRowsWithIncorrectData: !this.state.showRowsWithIncorrectData });
    };

    public render() {
        return (
            <Screen isLoading={this.state.isLoading} isScrollable={false}>
                <div className={'fdc hfill'}>
                    <div style={{ height: 200 }} className={'wfill mb20'}>
                        <FileSelector
                            accept={'.xlsx, .xls'}
                            files={this.state.selectedFiles ? this.state.selectedFiles : []}
                            disabled={this.state.isLoading || this.state.selectedFiles.length > 0}
                            onFileSelected={this.handleFileInputChange}
                            onFileRemoved={this.onFileRemove}
                        />
                    </div>
                    <div className={'fdr wfill h48 aic pl10'}>
                        Show rows with incorrect data
                        <Switch
                            color={'primary'}
                            checked={this.state.showRowsWithIncorrectData}
                            onChange={this.toggleShowRowsWithIncorrectData} />
                    </div>
                    <Card className={'fdc'}>
                        <CustomTable<ISaleImportTableRows>
                            fitWidthToPage
                            enablePagination
                            enableFiltering
                            enableSorting
                            columns={[
                                { title: 'Season', field: 'seasonCode', style: (value : any) => value?.toString().includes('Not Found') ? valueBoldedStyle : {}, enableFiltering: true, enableSorting: true },
                                { title: 'Farm', field: 'farmCode', style: (value : any) => value?.toString().includes('Not Found') ? valueBoldedStyle : {}, enableFiltering: true, enableSorting: true },
                                { title: 'Supplier', field: 'supplier', enableFiltering: true, enableSorting: true },
                                { title: 'Export Pallet Code', field: 'exportStockBarcode', width: 200 , style: (value : any) => value?.toString().includes('Not Found') ? valueBoldedStyle : !!this.state.duplicateExportStockBarcodes.find(x => x === value.toString()) ? valueBoldedStyle : {}, enableFiltering: true, enableSorting: true },
                                { title: 'Commodity Group Code', field: 'commodityGroupCode', enableFiltering: true, enableSorting: true },
                                { title: 'Commodity', field: 'commodityCode', style: (value : any) => value?.toString().includes('Not Found') ? valueBoldedStyle : {}, enableFiltering: true, enableSorting: true },
                                { title: 'Variety Group Code', field: 'varietyGroupCode', enableFiltering: true, enableSorting: true },
                                { title: 'Variety', field: 'varietyCode', style: (value : any) => value?.toString().includes('Not Found') ? valueBoldedStyle : {}, enableFiltering: true, enableSorting: true },
                                { title: 'Mark', field: 'brandCode', style: (value : any) => value?.toString().includes('Not Found') ? valueBoldedStyle : {}, enableFiltering: true, enableSorting: true },
                                { title: 'Grade', field: 'gradeCode', style: (value : any) => value?.toString().includes('Not Found') ? valueBoldedStyle : {}, enableFiltering: true, enableSorting: true },
                                { title: 'Pack', field: 'packCode', style: (value : any) => value?.toString().includes('Not Found') ? valueBoldedStyle : {}, enableFiltering: true, enableSorting: true },
                                { title: 'Size Count', field: 'sizeCount', enableFiltering: true, enableSorting: true },
                                { title: 'Inventory Code', field: 'inventoryCode', style: (value : any) => value?.toString().includes('Not Found') ? valueBoldedStyle : {}, enableFiltering: true, enableSorting: true },
                                { title: 'In Doc No', field: 'waybillNumber', enableFiltering: true, enableSorting: true },
                                { title: 'ETD Week', field: 'estimatedTimeDepartureWeekCode', enableFiltering: true, enableSorting: true },
                                { title: 'Target Market Code', field: 'targetMarketCode', style: (value : any) => value?.toString().includes('Not Found') ? valueBoldedStyle : {}, enableFiltering: true, enableSorting: true },
                                { title: 'Cartons Quantity', field: 'cartons', enableFiltering: true, enableSorting: true },
                                { title: 'Nett Factor price', field: 'nettFactorPrice', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Core Commision', field: 'coreCommision', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Overseas Cost', field: 'overseasCost', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Sea Freight Cost', field: 'seaFreightCost', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Freight Forwarding Document Cost', field: 'freightForwardingDocumentCost', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Sea Freight Rebate', field: 'seaFreightRebate', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Insurance Cost', field: 'insuranceCost', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Cooling CTF', field: 'coolingCtf', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Transport From CTF To Boat', field: 'transportFromCtfToBoat', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Bank Cost', field: 'bankCost', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'AVO levy-SAAGA', field: 'avocadoLevySaaga', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Temperatuur recorders', field: 'temperatureRecording', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Freight forwarding management fee', field: 'freightForwardingManagementFee', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Cargo Dues', field: 'cargoDues', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Rente', field: 'interest', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Verpoeling', field: 'pool', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Advice No', field: 'adviceNumber', enableFiltering: true, enableSorting: true },
                                { title: 'Total Income', field: 'totalIncome', formatFunction: (value : number) => value.toFixed(3), enableFiltering: true, enableSorting: true },
                                { title: 'Channel', field: 'channel', enableFiltering: true, enableSorting: true },
                                { title: 'Country', field: 'countryCode', style: (value : any) => value?.toString().includes('Not Found') ? valueBoldedStyle : {}, enableFiltering: true, enableSorting: true },
                                { title: 'Payment Date', field: 'paymentDate', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                            ]}
                            rows={this.getRows(this.props, this.state)}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={500}
                            warning={(row : ISaleImportTableRows) => !!this.state.duplicateExportStockBarcodes.find(x => x === row.exportStockBarcode)}
                            isActive={(row : ISaleImportTableRows) => !row.isSomethingWrong}
                        />
                    </Card>
                    <div className={'fdr flx1 jcfe mt10'}>
                        <PillButton
                            text={'SAVE'}
                            color={'secondary'}
                            className={'w250 cpd fw500 mr5 p0 aic jcc'}
                            onClick={this.onSave}
                            disabled={this.state.isLoading
                                || this.state.saleLines.length < 1
                                || !!this.state.tableRows.some(x => !!x.isSomethingWrong)}
                        />
                    </div>
                </div>
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        sales: state.data.sales,
        saleLineData: state.data.saleLines,
        stockData: state.data.stocks,
        commodities: state.masterData.commodities,
        varieties: state.masterData.varieties,
        packs: state.masterData.packs,
        farms: state.masterData.farms,
        grades: state.masterData.grades,
        markets: state.masterData.markets,
        brands: state.masterData.brands,
        countries: state.masterData.countries,
        regions: state.masterData.regions,
        inventoryData: state.masterData.inventories,
        seasons: state.masterData.seasons,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetSales, dataSetSaleLineViewData }, dispatcher,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SaleImportDialog);
