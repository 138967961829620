import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

interface ClearCacheContextType {
    isLatestVersion : boolean;
    emptyCacheStorage : () => void;
}

const ClearCacheContext = createContext<ClearCacheContextType>({
    isLatestVersion: true,
    emptyCacheStorage: () => {},
});

export const ClearCacheProvider = ({ children } : { children : ReactNode }) : JSX.Element => {
    const [isLatestVersion, setIsLatestVersion] = useState<boolean>(true);

    useEffect(() => {
        const checkVersion = async () => {
            try {
                const response = await fetch(`/meta.json?v=${Date.now()}`); // Prevent caching
                if (!response.ok) throw new Error('Failed to fetch meta.json');

                // Ensure correct structure
                const data : { version : string } = await response.json();
                if (!data.version || typeof data.version !== 'string') {
                    throw new Error('Invalid meta.json format');
                }

                const storedVersion = localStorage.getItem('app_guid_version');

                if (storedVersion && storedVersion !== data.version) {
                    setIsLatestVersion(false);
                }

                localStorage.setItem('app_guid_version', data.version);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error checking version:', error);
            }
        };

        checkVersion();
        const interval = setInterval(checkVersion, 10000); // Check every 60 seconds

        return () => clearInterval(interval);
    }, []);

    const emptyCacheStorage = () => {
        caches.keys().then((names) => {
            names.forEach(name => caches.delete(name));
        });

        localStorage.removeItem('app_guid_version');
        sessionStorage.clear();

        window.location.reload();
    };

    return <ClearCacheContext.Provider value={{ isLatestVersion, emptyCacheStorage }}>
        {children}
    </ClearCacheContext.Provider>;
};

export const useClearCacheCtx = () : ClearCacheContextType => useContext(ClearCacheContext);
