import * as React from 'react';
import CustomTable, { ICustomTableColumn } from '../../../components/table/CustomTable';
import { connect } from 'react-redux';
import { IRootState, IAuthState } from '../../../@types/redux';
import { formatMomentToDatePicker } from '../../../services/appFunctionsService';
import { mapMaterialDispatchDashboard } from '../../../services/mappingFunctionsService';
import { Button, Dialog, TextField } from '@mui/material';
import { Form } from 'informed';
import CustomAutoSuggest from '../../../components/input/CustomAutoSuggest';
import { IDropDownOptions } from '../../../@types/other';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import moment from 'moment';
import CustomDatePicker from '../../../components/input/CustomDatePicker';
import validationService from '../../../services/validationService';
import { v1 } from 'uuid';
import { dataSetMaterialDispatch } from '../../../store/data/Functions';
import { DATEPICKER_FORMAT_DEFAULT } from '../../../appConstants';
import { ISite } from '../../../@types/model/masterData/site/site';
import { IOrganization } from '../../../@types/model/masterData/organization/organization';
import { IStock } from '../../../@types/model/stock/stock';
import { IMaterialDispatch } from '../../../@types/model/materialDispatch/materialDispatch';
import { IMaterialStock } from '../../../@types/model/materialStock/materialStock';
import { IMaterialDispatchDashboard } from '../../../@types/model/materialDispatch/materialDispatchDashboard';
import { IStockLine } from '../../../@types/model/stock/stockLine';
import { IMaterialDispatchLine } from '../../../@types/model/materialDispatch/materialDispatchLine';
import MaterialDispatchHttpService from '../../../services/http/materialDispatch/materialDispatchHttpService';

interface IDashboardTableProps {
    dataFetched : boolean;
    isLoading : boolean;
    setLoading : (loading : boolean) => void;
    sites : Array<ISite>;
    organizations : Array<IOrganization>;
    stockData : Array<IStock>;
    refreshData : (noAnnounce ?: boolean) => void;
    materialDispatchMode : boolean;
    materialDispatches : Array<IMaterialDispatch>;
    materialStocks : Array<IMaterialStock>;
    selectedDispatchId ?: number;
    sidePanelLoadDate : string;
    auth : IAuthState;
    showOnlyMyDispatches : boolean;
}

interface IDashboardTableState {
    rows : Array<IMaterialDispatchDashboard>;
    columns : Array<ICustomTableColumn>;
    clickedMaterialStock ?: IMaterialDispatchDashboard;
    selectedAmount : number;
    clickedMaterialStockAvailableAmount : number;
    sourceSite ?: ISite;
    selectedLoadDate : string;
    selectedSiteId ?: number;
    pageHeight : number;
}

class DashboardTable extends React.Component<IDashboardTableProps, IDashboardTableState> {
    constructor(props : IDashboardTableProps) {
        super(props);

        this.state = {
            rows: [],
            columns: [],
            selectedAmount: 0,
            clickedMaterialStockAvailableAmount: 0,
            selectedLoadDate: this.props.sidePanelLoadDate,
            pageHeight: 185,
        };
    }

    public componentDidUpdate = (prevProps : IDashboardTableProps) => {
        const nextProps = this.props;
        if (prevProps && nextProps) {
            if ((!prevProps.dataFetched && nextProps.dataFetched)
                || (prevProps.materialDispatches !== nextProps.materialDispatches)
                || (prevProps.showOnlyMyDispatches !== nextProps.showOnlyMyDispatches)) {
                this.setState({ rows: this.filterData(), columns: this.getColumns() });
            }
            if (prevProps.sidePanelLoadDate !== nextProps.sidePanelLoadDate) {
                this.setState({ selectedLoadDate: nextProps.sidePanelLoadDate });
            }
        }
    };

    private refreshData = (noAnnounce ?: boolean) => this.props.refreshData(noAnnounce);

    private getObjectCode = (obj : any) => {
        return obj && obj.code ? obj.code : 'UNK?';
    };

    private getColumns = () => {
        const sites = this.props.sites;
        const columns : Array<ICustomTableColumn> = [
            { title: 'Material', field: 'material', width: 80, formatFunction: this.getObjectCode, enableFiltering: true, enableSorting: true },
            { title: 'UnitOfMeasure', field: 'unitOfMeasure', width: 100, formatFunction: this.getObjectCode, enableFiltering: true, enableSorting: true  },
            { title : 'Date Code', field: 'dateCode', width: 150, enableFiltering: true, enableSorting: true },
            { title: 'Total', field: 'totalStock', width: 100, calculateTotal: true, calculateRedTotal: true, enableFiltering: true, enableSorting: true,
                style: () => {
                    return { fontWeight: 800 };
                },
                formatFunction: (value : { allocatedAmount : number; unallocatedAmount : number }) => value?.unallocatedAmount,
                redFormatFunction: (value : { allocatedAmount : number; unallocatedAmount : number }) => value?.allocatedAmount,
                containerComponent: (row : IMaterialDispatchDashboard) => <div className={'fdr'}>
                    {row.totalAmounts.unallocated}{(row.totalAmounts.allocated !== 0 ? <div className={'cred'} style={{ paddingLeft: 3 }}>{`(${row.totalAmounts.allocated})`}</div> : '')}
                </div>,
            },
        ];
        if (sites) {
            sites.forEach((x, i) => {
                columns.push({
                    title: x.shortDescription,
                    field: 'stock',
                    width: 100,
                    calculateTotal: true,
                    calculateRedTotal: true,
                    enableFiltering: true,
                    enableSorting: true,
                    style: (value : Array<{
                        site : ISite;
                        stockAmount : number;
                        stockAmountDispatching : number;
                        stockLines : Array<IStockLine>;
                    }>) => {
                        return value && value.length !== 0 && value[i] && value[i].stockAmount ? { fontWeight: 600 } : undefined;
                    },
                    containerComponent: (row : IMaterialDispatchDashboard, value : any) => (Number(value) > 0) && this.props.materialDispatchMode ?
                        <Button
                            disabled={this.isButtonDisabled(row, x)}
                            style={ {
                                padding: 0,
                                fontWeight: 600,
                                justifyContent: 'left',
                                paddingLeft: 5,
                                left: -6,
                                border: '1px solid',
                            }}
                            onClick={() => this.onSiteValueClicked(row, value, x)}
                        >
                            {value}
                            {(this.dispatchAllocatedAmount(row, x) !== 0 ?
                                <div className={'cred'} style={{ paddingLeft: 3 }}>{`(${this.dispatchAllocatedAmount(row, x)})`}</div>
                                :
                                ''
                            )}
                        </Button> :
                        <div className={'fdr'}>
                            {value}{(this.dispatchAllocatedAmount(row, x) !== 0 ?
                                <div className={'cred'} style={{ paddingLeft: 3 }}>{`(${this.dispatchAllocatedAmount(row, x)})`}</div>
                                :
                                '')}
                        </div>,
                    formatFunction: (value : Array<{
                        site : ISite;
                        allocatedAmount : number;
                        unallocatedAmount : number;
                        materialStocks : Array<IMaterialStock>;
                    }>) => value[i]?.unallocatedAmount?.toString() || '0',
                    redFormatFunction: (value : Array<{
                        site : ISite;
                        allocatedAmount : number;
                        unallocatedAmount : number;
                        materialStocks : Array<IMaterialStock>;
                    }>) => value[i]?.allocatedAmount?.toString() || '0',
                });
            });
        }
        return columns;
    };

    private dispatchAllocatedAmount = (matDisp : IMaterialDispatchDashboard, site : ISite) => {
        const a = matDisp.stock.find(x => x.site?.id === site.id);
        return a?.allocatedAmount || 0;
    };

    private onSiteValueClicked = (matDisp : IMaterialDispatchDashboard, value : number, sourceSite : ISite) => {
        if (this.props.selectedDispatchId) {
            this.setState({ clickedMaterialStock: matDisp, clickedMaterialStockAvailableAmount: Number(value), selectedAmount: Number(value), sourceSite });
        } else {
            this.setState({ clickedMaterialStock: matDisp, clickedMaterialStockAvailableAmount: Number(value), selectedAmount: Number(value), sourceSite });
        }
    };

    private isButtonDisabled = (matDisp : IMaterialDispatchDashboard, site : ISite) => {
        const selectedDispatch = this.props.materialDispatches.find(x => x.id === this.props.selectedDispatchId);
        if (selectedDispatch && (!selectedDispatch.sourceSiteId || !selectedDispatch.destinationSiteId)) {
            return false;
        }
        if (selectedDispatch) {
            return !(site.id === selectedDispatch.sourceSiteId);
        }
        return false;
    };

    private filterData = () => {
        const rows = [...mapMaterialDispatchDashboard()];
        return rows;
    };

    private addMaterialStockToDispatch = async () => {
        const matDisp : IMaterialDispatchDashboard | undefined = this.state.clickedMaterialStock && { ...this.state.clickedMaterialStock };
        const sourceSite = this.state.sourceSite;
        const loadDate = this.state.selectedLoadDate ? formatMomentToDatePicker(moment(this.state.selectedLoadDate, DATEPICKER_FORMAT_DEFAULT)) : formatMomentToDatePicker(moment().utc().startOf('day'));
        if (matDisp && loadDate) {
            if (sourceSite) {
                const existingDispatch = this.props.materialDispatches.find(x => x.id === this.props.selectedDispatchId);
                const materialDispatchLines : Array<IMaterialDispatchLine> = existingDispatch ? existingDispatch.materialDispatchLines : [];
                const sourceSiteId = sourceSite.id;
                const destinationSiteId = this.state.selectedSiteId;

                const selectedMaterialDispatch = this.state.clickedMaterialStock;
                const materialStock = this.props.materialStocks.find(x => (x.materialId === selectedMaterialDispatch?.material?.id)
                    && (x.unitOfMeasureId === selectedMaterialDispatch?.unitOfMeasure?.id)
                    && (x.siteId === sourceSiteId)
                    && (x.dateCode === selectedMaterialDispatch?.dateCode)
                    && x.isActive);

                if (materialStock) {
                    const newDispatchLine : IMaterialDispatchLine = {
                        id: 0,
                        guid: v1(),
                        materialDispatchId: 0,
                        sourceMaterialStockId: materialStock.id,
                        amount: this.state.selectedAmount,
                        isActive: true,
                    };

                    materialDispatchLines.push(newDispatchLine);

                    if (destinationSiteId) {
                        const newDispatch : IMaterialDispatch = {
                            id: this.props.selectedDispatchId ? this.props.selectedDispatchId : 0,
                            guid: existingDispatch ? existingDispatch.guid : v1(),
                            loadDate,
                            sourceSiteId,
                            destinationSiteId,
                            status: 'Draft',
                            isPrinted: false,
                            isActive: true,
                            materialDispatchLines,
                        };

                        this.props.setLoading(true);
                        try {
                            const res = await MaterialDispatchHttpService.addOrUpdateMaterialDispatch(newDispatch);

                            if (res && res.data) {
                                dataSetMaterialDispatch(res.data);
                                generalShowSuccessSnackbar('Material dispatch successfully added.');
                                this.refreshData(true);
                                this.props.setLoading(false);
                            } else {
                                generalShowErrorSnackbar('An error occurred adding the material dispatch.');
                                this.props.setLoading(false);
                            }
                        } catch (e) {
                            generalShowErrorSnackbar('An error occurred adding the material dispatch.');
                            this.props.setLoading(false);
                        }

                        this.clearState();
                    }
                }
            }

        }
    };

    private siteOptions = () : Array<IDropDownOptions> => {
        if (!this.props.sites) {
            return [];
        }
        const returnValue : Array<IDropDownOptions> = [];
        returnValue.push({ value: '', label: 'Select Site', disabled: true });
        this.props.sites.filter(x => (x.code !== (this.state.sourceSite && this.state.sourceSite.code)) && x.isActive)
            .forEach(x => returnValue.push({ value: x.id, label: x.code + '-' + x.description }));
        return returnValue;
    };

    private clearState = (event ?: {}, reason ?: 'backdropClick' | 'escapeKeyDown') => {
        if (reason !== 'backdropClick') {
            return;
        }
        if (this.props.selectedDispatchId) {
            this.setState({ clickedMaterialStock: undefined, clickedMaterialStockAvailableAmount: 0, selectedAmount: 0, sourceSite: undefined });
        } else {
            this.setState({ clickedMaterialStock: undefined, clickedMaterialStockAvailableAmount: 0, selectedAmount: 0, sourceSite: undefined, selectedSiteId: undefined });
        }
    };

    public render() {
        if (this.state.rows.length === 0 || !this.props.dataFetched) {
            return <div />;
        }
        return <div className={'fdc mnh260'}>
            <CustomTable<IMaterialDispatchDashboard>
                enableSorting
                enableFiltering
                enableTotalRow
                enableRefresh
                fitWidthToPage
                disableRefreshButton={this.props.isLoading}
                refreshFunction={this.refreshData}
                columns={this.state.columns}
                rows={this.state.rows}
                initialSortOrder={[{ columnName: 'id_Id', direction : 'asc' }]}
                pageHeight={this.state.pageHeight}
                isActive={(row : IMaterialDispatchDashboard) => row.isActive}
            />
            <Dialog open={!!this.state.clickedMaterialStock} onClose={this.clearState} maxWidth={'lg'}>
                <div className={'p20 fdc aic jcc'}>
                    <Form>
                        <div className={'fdr mb10'}>
                            <TextField type={'number'}
                                className={'flx1 m5'}
                                label={'Amount'}
                                placeholder={'Enter amount of materials'}
                                autoFocus
                                onChange={e => this.setState({ selectedAmount: (Number(e.target.value) > this.state.clickedMaterialStockAvailableAmount) ?
                                    this.state.clickedMaterialStockAvailableAmount : ((Number(e.target.value) < 0) ? 0 : Number(e.target.value)) })}
                                value={this.state.selectedAmount}
                            />
                            <CustomDatePicker
                                className={'flx1 m5'}
                                type='date'
                                field='loadDate'
                                label={'Load Date'}
                                disabled={!!this.props.selectedDispatchId}
                                onChange={(e : any) => this.setState({ selectedLoadDate: e })}
                                initialValue={this.props.sidePanelLoadDate}
                            />
                        </div>
                        <div className={'fdr mb10'}>
                            <CustomAutoSuggest
                                className={'flx1 m5'}
                                options={this.siteOptions()}
                                label={'Target Site'}
                                initialValue={this.state.selectedSiteId}
                                hasInitialValue={!!this.state.selectedSiteId}
                                disabled={!!this.props.selectedDispatchId && this.props.materialDispatches.some(x => x.id === this.props.selectedDispatchId && !!x.sourceSiteId && !!x.destinationSiteId)}
                                validate={validationService.required}
                                validateOnBlur
                                validateOnChange
                                validateOnMount
                                field={'targetSite'}
                                value={this.state.selectedSiteId ? this.state.selectedSiteId : 0}
                                onChange={(e : any) => this.setState({ selectedSiteId: e })}
                            />
                        </div>
                    </Form>
                    <div className={'fdr aic jcc wfill'}>
                        <Button className={'m5'} variant='contained' color='primary'
                            onClick={this.clearState}>
                            Cancel
                        </Button>
                        <Button className={'m5'} variant='contained' color='primary' onClick={this.addMaterialStockToDispatch}>
                            {this.props.selectedDispatchId ? 'Add to dispatch' : 'Add'}
                        </Button>
                    </div>

                </div>

            </Dialog>
        </div>;
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        sites: state.masterData.sites,
        auth: state.auth,
        stockData: state.data.stocks,
        organizations: state.masterData.organizations,
        selectedDashboardSiteIds: state.data.selectedDashboardSiteIds,
        selectedOrganizationIds: state.data.selectedDashboardOrganizations,
        selectedCommodities: state.data.selectedDashboardCommodities,
        selectedVarieties: state.data.selectedDashboardVarieties,
        selectedPackCategories: state.data.selectedDashboardPackCategories,
        selectedColours: state.data.selectedDashboardColours,
        selectedGrades: state.data.selectedDashboardGrades,
        sidePanelLoadDate: state.data.dashboardDispatchSelectedLoadDate,
        materialDispatches: state.data.materialDispatches,
        materialStocks: state.data.materialStocks,
        selectedDispatchId: state.data.dashboardSelectedMaterialDispatchId,
        showOnlyMyDispatches: state.data.dashboardShowOnlyMyDispatches,
    };
};

export default connect(
    mapStateToProps,
)(DashboardTable);
