import React, { useEffect, useMemo } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, Switch } from 'react-router';
import Login from '../Login';
import { useAppSelector } from '../../@types/redux';
import Stock from '../stock/Stock';
import Order from '../order/Order';
import DispatchInstructionTable from '../dispatchInstructions/DispatchInstructionTable';
import DispatchInstructionDashboard from '../dispatchInstructions/DispatchInstructionDashboard';
import DispatchInstructionWizard from '../dispatchInstructions/DispatchInstructionWizard';
import LandingPage from '../landingPage/LandingPage';
import Compliance from '../compliance/Compliance';
import ComplianceWizard from '../compliance/ComplianceWizard';
import ComplianceDashboard from '../compliance/ComplianceDashboard';
import UserList from '../right/user/UserListComponent';
import RoleList from '../right/role/RoleListComponent';
import RightsList from '../right/management/RightListComponent';
import Lot from '../lot/LotScreen';
import Batch from '../batch/Batch';
import Intake from '../intake/Intake';
import Material from '../material/MaterialStock';
import MaterialStockDashboard from '../material/materialDashboard/MaterialDashboard';
import MaterialDispatchDashboard from '../material/materialDispatch/MaterialDispatchDashboard';
import OverviewDashboard from '../overview/Overview';
import MasterDataManagement from '../masterData/MasterDataManagement';
import carrierScreen from '../masterData/carrier/CarrierScreen';
import barcodeRangeScreen from '../masterData/barcodeRange/BarcodeRangeScreen';
import countryScreen from '../masterData/country/CountryScreen';
import contactInfoScreen from '../masterData/contactInfo/ContactInfoScreen';
import farmScreen from '../masterData/farm/FarmScreen';
import marketScreen from '../masterData/market/MarketScreen';
import organizationScreen from '../masterData/organization/OrganizationScreen';
import palletBaseTypeScreen from '../masterData/palletBaseType/PalletBaseTypeScreen';
import regionScreen from '../masterData/region/RegionScreen';
import accreditationScreen from '../masterData/accreditation/AccreditationScreen';
import siteScreen from '../masterData/site/SiteScreen';
import storageUnitScreen from '../masterData/storageUnit/StorageUnitScreen';
import inspectionPointScreen from '../masterData/inspectionPoint/InspectionPointScreen';
import brandScreen from '../masterData/brand/BrandScreen';
import lotTypeScreen from '../masterData/lotType/LotTypeScreen';
import materialScreen from '../masterData/material/MaterialScreen';
import materialTypeScreen from '../masterData/materialType/MaterialTypeScreen';
import unitOfMeasureScreen from '../masterData/unitOfMeasure/UnitOfMeasureScreen';
import unitOfMeasureTypeScreen from '../masterData/unitOfMeasureType/UnitOfMeasureTypeScreen';
import commodityScreen from '../masterData/commodity/CommodityScreen';
import commoditySectionScreen from '../masterData/commoditySection/CommoditySectionScreen';
import commodityConditionScreen from '../masterData/commodityCondition/CommodityConditionScreen';
import { NoRightsScreen } from '../right/NoRightsScreen';
import Report from '../reports/Report';
import { getHomeUrlStorage } from '../../services/localStorageService';
import { navHome } from '../../store/nav/Actions';
import ComplianceTransactions from '../compliance/ComplianceTransactions';
import TripScreen from '../dispatchInstructions/trip/TripScreen';
import FaqScreen from '../faq/FaqScreen';
import { DEFAULT_RIGHTS } from '../../appConstants';
import MaterialDispatchWizard from '../material/materialDispatch/MaterialDispatchWizard';
import SiteMap from '../masterData/site/SiteMap';
import StorageUnitDashboard from '../lot/StorageUnitDashboard';
import TripDashboard from '../dispatchInstructions/trip/TripDashboard';
import InventoryScreen from '../masterData/inventory/InventoryScreen';
import PackCategoryScreen from '../masterData/packCategory/PackCategoryScreen';
import DepartmentScreen from '../masterData/department/DepartmentScreen';
import SaleScreen from '../sale/SaleScreen';
import MultiDispatchWizardExport from '../dispatchInstructions/MultiDispatchWizardExport';
import MultiDispatchWizardLocal from '../dispatchInstructions/MultiDispatchWizardLocal';
import truckTypeScreen from '../masterData/truckType/truckTypeScreen';
import DeviceScreen from '../masterData/device/DeviceScreen';
import ProjectScreen from '../masterData/project/ProjectScreen';
import StockTomatoReportScreen from '../stock/StockTomatoReportScreen';
import DomainScreen from '../masterData/domain/DomainScreen';
import RecipeScreen from '../integration/recipe/RecipeScreen';
import PackLineScreen from '../masterData/packLine/PackLineScreen';
import OutletScreen from '../masterData/outlet/OutletScreen';
import PrinterScreen from '../masterData/printer/PrinterScreen';
import PrintServerScreen from '../masterData/printServer/PrintServerScreen';
import ReportScreen from '../masterData/report/ReportScreen';
import AgreementCodeScreen from '../masterData/agreementCode/AgreementCodeScreen';
import SalesPointScreen from '../masterData/salesPoint/SalesPointScreen';
import ImportPOFileScreen from '../integration/import/poFile/ImportPOFileScreen';
import LogScreen from '../logs/LogScreen';
import MaterialDispatchTable from '../material/materialDispatch/MaterialDispatchTable';
import ftpDetailScreen from '../masterData/ftpDetail/ftpDetailScreen';

const PrivateRoute = (props : RouteProps) => {
    /**
     * Check if current user is associated with a right that corresponds to the destination url.
     *
     * @param url
     */
    const session = useAppSelector(x => x.auth.session);
    const isLoggedIn = useAppSelector(x => x.auth.isLoggedIn);
    const isLoggingIn = useAppSelector(x => x.auth.isLoggingIn);
    const { component, path, ...rest } = props;

    const hasMenuRight = (url : string) : boolean => {
        if (!session) return false;
        if (!session.user.rights || !session.user.rights.length) return false;

        const { rights } = session.user;
        return rights.some((n) => {
            /* Allow all sub urls if using the wildcard, otherwise match the url exactly */
            if (n.url && n.url.includes('/*')) {
                return url.startsWith(n.url.split('/*')[0]);
            } else if (n.url && url && url.includes('/:')) {
                return url.startsWith(`${n.url}/:`);
            } else {
                return n.url === url;
            }
        });
    };

    useEffect(() => {
        if (getHomeUrlStorage() !== '/' && path === '/') {
            navHome();
        }
    }, [session, isLoggedIn, isLoggingIn]);

    const render = (routeProps : RouteComponentProps<any>) : React.ReactNode => {
        if (session) {
            if (!component) {
                return;
            }

            const Component = component;
            if (!hasMenuRight(routeProps.match.url) && DEFAULT_RIGHTS.some(x => x !== routeProps.match.url)) {
                return <NoRightsScreen />;
            }
            if (isLoggedIn) {
                if (getHomeUrlStorage() !== '/' && path === '/') {
                    navHome();
                }
                return <Component {...routeProps} />;
            }
        }
        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
    };
    return <Route {...rest} render={render} />;
};

const Routes = () => {

    const rights = useAppSelector(x => x.auth.session?.user.rights);

    const routes = useMemo(() => {
        const result : Array<{path : string; component : React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>}> = [

            { path: '/masterData/management/commodity', component: commodityScreen },
            { path: '/masterData/management/commodityCondition', component: commodityConditionScreen },
            { path: '/masterData/management/contactInfo', component: contactInfoScreen },
            { path: '/masterData/management/commoditySection:section', component: commoditySectionScreen },
            { path: '/masterData/management/carrier', component: carrierScreen },
            { path: '/masterData/management/barcodeRange', component: barcodeRangeScreen },
            { path: '/masterData/management/accreditation', component: accreditationScreen },
            { path: '/masterData/management/country', component: countryScreen },
            { path: '/masterData/management/farm', component: farmScreen },
            { path: '/masterData/management/ftpDetail', component: ftpDetailScreen },
            { path: '/masterData/management/market', component: marketScreen },
            { path: '/masterData/management/organization', component: organizationScreen },
            { path: '/masterData/management/palletBaseType', component: palletBaseTypeScreen },
            { path: '/masterData/management/region', component: regionScreen },
            { path: '/masterData/management/site', component: siteScreen },
            { path: '/masterData/management/storageUnit', component: storageUnitScreen },
            { path: '/masterData/management/inspectionPoint', component: inspectionPointScreen },
            { path: '/masterData/management/brand', component: brandScreen },
            { path: '/masterData/management/lotType', component: lotTypeScreen },
            { path: '/masterData/management/material', component: materialScreen },
            { path: '/masterData/management/materialType', component: materialTypeScreen },
            { path: '/masterData/management/unitOfMeasure', component: unitOfMeasureScreen },
            { path: '/masterData/management/unitOfMeasureType', component: unitOfMeasureTypeScreen },
            { path: '/masterData/management/inventory', component: InventoryScreen },
            { path: '/masterData/management/packCategory', component: PackCategoryScreen },
            { path: '/masterData/management/department', component: DepartmentScreen },
            { path: '/masterData/management/truckType', component: truckTypeScreen },
            { path: '/masterData/management/device', component: DeviceScreen },
            { path: '/masterData/management/project', component: ProjectScreen },
            { path: '/masterData/management/domain', component: DomainScreen },
            { path: '/masterData/management/packLine', component: PackLineScreen },
            { path: '/masterData/management/outlet', component: OutletScreen },
            { path: '/masterData/management/printer', component: PrinterScreen },
            { path: '/masterData/management/printServer', component: PrintServerScreen },
            { path: '/masterData/management/report', component: ReportScreen },
            { path: '/masterData/management/agreementCode', component: AgreementCodeScreen },
            { path: '/masterData/management/salesPoint', component: SalesPointScreen },
            { path: '/masterData/management', component: MasterDataManagement },  // This needs to be last (in the masterdata section) to not trigger incorrect routing

            { path: '/stock/transactions', component: Stock },
            { path: '/stock/tomatoReports', component: StockTomatoReportScreen },

            { path: '/overview/dashboard', component: OverviewDashboard },
            { path: '/overview/sitesMap', component: SiteMap },

            { path: '/order/transactions', component: Order },

            { path: '/lot/transactions', component: Lot },
            { path: '/lot/storageUnit/dashboard', component: StorageUnitDashboard },

            { path: '/batch/transactions', component: Batch },

            { path: '/intake/transactions', component: Intake },

            { path: '/materialStock/transactions', component: Material },
            { path: '/materialStock/dashboard', component: MaterialStockDashboard },
            { path: '/materialDispatch/dashboard', component: MaterialDispatchDashboard },
            { path: '/materialDispatch/transactions', component: MaterialDispatchTable },
            { path: '/materialDispatch/wizard', component: MaterialDispatchWizard },

            { path: '/dispatch/dashboard', component: DispatchInstructionDashboard },
            { path: '/dispatch/transactions', component: DispatchInstructionTable },
            { path: '/dispatch/wizard', component: DispatchInstructionWizard },
            { path: '/dispatch/tripTransactions', component: TripScreen },
            { path: '/dispatch/tripDashboard', component: TripDashboard },
            { path: '/dispatch/multiDispatchWizardExport', component: MultiDispatchWizardExport },
            { path: '/dispatch/multiDispatchWizardLocal', component: MultiDispatchWizardLocal },

            { path: '/compliance/drafts', component: Compliance },
            { path: '/compliance/transactions', component: ComplianceTransactions },
            { path: '/compliance/dashboard', component: ComplianceDashboard },
            { path: '/compliance/wizard', component: ComplianceWizard },

            { path: '/right/user', component: UserList },
            { path: '/right/role', component: RoleList },
            { path: '/right/management', component: RightsList },

            { path: '/noRights', component: NoRightsScreen },

            { path: '/faq', component: FaqScreen },

            { path: '/SaleImports', component: SaleScreen },

            { path: '/integration/recipe', component: RecipeScreen },
            { path: '/integration/poFileImport', component: ImportPOFileScreen },

            { path: '/development/tools', component: LogScreen },

            { path: '/', component: LandingPage }, // This needs to be last to not trigger incorrect routing
        ];

        rights?.filter(x => x.url?.startsWith('/report/')).forEach(x => result.push({ path: x.url, component: Report }));

        return result.map(x => <PrivateRoute key={x.path} path={`${x.path}/:id?`} component={x.component} />);
    }, [rights]);

    return (
        <Switch>
            <Route path='/login' component={Login}/>
            {routes}
        </Switch>
    );
};

export default Routes;
