import * as React from 'react';
import { Card } from '@mui/material';
import { DispatchCall, IAuthState, IRootState, RootAction } from '../../../@types/redux';
import { dataSetPrintServers, dataSetSites } from '../../../store/masterData/Actions';
import { bindActionCreators, Dispatch } from 'redux';
import { formatDateTime, isNullOrWhiteSpace, upsertArrayElement } from '../../../services/appFunctionsService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import Screen from '../../../components/Screen';
import { connect } from 'react-redux';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikHelpers } from 'formik';
import { createSelector } from 'reselect';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import { ConfirmationDialog, CustomTable } from '@zz2/zz2-ui';
import { v4 as uuidv4 } from 'uuid';
import { IPrintServer, PrintServer } from '../../../@types/model/masterData/printServer/printServer';
import { ISite } from '../../../@types/model/masterData/site/site';
import PrintServerHttpService from '../../../services/http/masterData/printServerHttpService';
import SiteHttpService from '../../../services/http/masterData/siteHttpService';
import { IPrintServerFormValues, PrintServerFormValues } from '../../../@types/model/masterData/printServer/printServerFormValues';
import PrintServerForm from './form/PrintServerForm';
import { setPrintServerMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface IPrintServerScreenProps extends RouteComponentProps {
    dataSetPrintServers : DispatchCall<Array<IPrintServer>>;
    dataSetSites : DispatchCall<Array<ISite>>;
    printServers : Array<IPrintServer>;
    sites : Array<ISite>;
    auth : IAuthState;
}

interface IPrintServerScreenState {
    rows : Array<IPrintServer>;
    isLoading : boolean;
    selectedPrintServer ?: IPrintServer;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    formGuid ?: string;
    isDeletePopupOpen : boolean;
    deletingPrintServer ?: IPrintServer;
}

class PrintServerScreen extends React.Component<IPrintServerScreenProps, IPrintServerScreenState> {
    constructor(props : IPrintServerScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedPrintServer: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await PrintServerHttpService.getPrintServerData();

                const printServerData = res.data;
                if (printServerData) {
                    const res2 = await SiteHttpService.getSiteData();

                    const sites = res2.data;
                    this.props.dataSetSites(sites);
                    this.props.dataSetPrintServers(printServerData);
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading print servers.');
            } finally {
                this.setLoading(false);
            }
        }
    };

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            formGuid: undefined,
            selectedPrintServer: undefined,
        });
    };

    public openDialog = () => {
        this.setState({
            formGuid: uuidv4(),
            isDialogOpen: true,
        });
    };

    public editPrintServer = (selectedPrintServer : IPrintServer) => {
        this.setState({
            formGuid: uuidv4(),
            isDialogOpen: true,
            selectedPrintServer,
        });
        this.openDialog();
    };

    public onSubmit = async (values : IPrintServerFormValues) => {
        this.setLoading(true);

        let payload = { ...values };
        if (isNullOrWhiteSpace(payload.guid)) {
            payload.guid = this.state.formGuid ?? '';
        }

        try {
            const res = await PrintServerHttpService.addOrUpdatePrintServer(new PrintServer(payload));

            const newPrintServerList = upsertArrayElement(this.props.printServers, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetPrintServers(newPrintServerList);
            await setPrintServerMasterDataIndexedDB(newPrintServerList);

            if (this.state.selectedPrintServer) {
                generalShowSuccessSnackbar('Print server updated successfully.');
            } else {
                generalShowSuccessSnackbar('Print server added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating print server data.');
        } finally {
            this.setLoading(false);
        }
    };

    private openDeleteConfirmationPopup = (printServer : IPrintServer) => {
        this.setState({ isDeletePopupOpen: true, deletingPrintServer: printServer });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingPrintServer: undefined });
    };

    private removePrintServer = async () => {
        const newPrintServer = this.state.deletingPrintServer;
        if (newPrintServer) {
            newPrintServer.isActive = false;
            this.setLoading(true);

            try {
                const res = await PrintServerHttpService.deletePrintServer(newPrintServer.id);

                const newPrintServerList = upsertArrayElement(this.props.printServers, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetPrintServers(newPrintServerList);
                await setPrintServerMasterDataIndexedDB(newPrintServerList);

                generalShowSuccessSnackbar('Print server updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting print server.');
                newPrintServer.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    public onReset = async (formValues : IPrintServerFormValues, formikHelpers : FormikHelpers<IPrintServerFormValues>) => {
        formikHelpers.resetForm();
        this.closeDialog();
    };

    public getSelectedPrintServer = (props : IPrintServerScreenProps, state : IPrintServerScreenState) => state.selectedPrintServer;

    public getInitialFormValues = createSelector(
        [this.getSelectedPrintServer],
        (printServer : IPrintServer) => {
            return new PrintServerFormValues(printServer, this.props.sites);
        },
    );

    private getSiteShortDescription = (siteId : number) => {
        const site = this.props.sites?.find(x => x.id === siteId);
        return site?.shortDescription ?? '';
    };

    private getRights = (props : IPrintServerScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IPrintServerScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.props.printServers ? this.props.printServers : [];
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Print Server'
                    maxWidth={'xs'}
                    fullWidth
                    isEdit={!!this.state.selectedPrintServer}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={PrintServerFormValues.formSchema}
                        component={PrintServerForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IPrintServer>
                            enableAdding={this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.editPrintServer}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(printServer : IPrintServer) => printServer.isActive && this.hasEditingRight(this.props)}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            warning={row => !!row.primaryUrl && !row.isOverrideFlag}
                            fitWidthToPage
                            enablePagination
                            columns={[
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'MAC Address', field: 'macAddress', enableFiltering: true, enableSorting: true },
                                { title: 'IP Address', field: 'ipAddress', enableFiltering: true, enableSorting: true },
                                { title: 'Primary URL', field: 'primaryUrl', enableFiltering: true, enableSorting: true },
                                { title: 'Default Scheme', field: 'defaultScheme', enableFiltering: true, enableSorting: true },
                                { title: 'Port', field: 'port', enableFiltering: true, enableSorting: true },
                                { title: 'Site', field: 'siteId', formatFunction: this.getSiteShortDescription, enableFiltering: true, enableSorting: true },
                                { title: 'Is Mafroda Integrated?', field: 'isMafrodaIntegrated', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Override Flag?', field: 'isOverrideFlag', type: 'boolean', enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IPrintServer) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationDialog
                    title={'Delete Print Server'}
                    open={this.state.isDeletePopupOpen}
                    description={'Are you sure you want to delete this print server?'}
                    onAccept ={this.removePrintServer}
                    onClose ={this.closeDeleteConfirmationPopup}
                    dialogType='orange'
                    isLoading={this.state.isLoading}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        printServers: state.masterData.printServers,
        sites: state.masterData.sites,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetPrintServers, dataSetSites }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PrintServerScreen);
