import * as React from 'react';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState, RootAction, DispatchCall, IAuthState } from '../../../../@types/redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dataSetCommodityStates } from '../../../../store/masterData/Actions';
import { formatDateTime, isNullOrWhiteSpace, upsertArrayElement } from '../../../../services/appFunctionsService';
import { ICommodityState, CommodityState } from '../../../../@types/model/masterData/commodityState/commodityState';
import { ICommodity } from '../../../../@types/model/masterData/commodity/commodity';
import CommodityStateHttpService from '../../../../services/http/masterData/commodityStateHttpService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../../store/general/Functions';
import { ICommodityStateFormValues, CommodityStateFormValues } from '../../../../@types/model/masterData/commodityState/commodityStateFormValues';
import { FormikHelpers, Formik } from 'formik';
import { createSelector } from 'reselect';
import PackmanDialog from '../../../../components/dialog/PackmanDialog';
import CommodityStateForm from './form/CommodityStateForm';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../../@types/model/user/right';
import { ConfirmationDialog, CustomTable } from '@zz2/zz2-ui';
import { v4 as uuidv4 } from 'uuid';
import { setCommodityStateMasterDataIndexedDB, syncMasterData } from '../../../../services/masterDataSyncService';

interface ICommodityStateScreenProps extends RouteComponentProps {
    dataSetCommodityStates : DispatchCall<Array<ICommodityState>>;
    commodityStates : Array<ICommodityState>;
    selectedCommodity ?: ICommodity;
    commodities : Array<ICommodity>;
    auth : IAuthState;
}

interface ICommodityStateScreenState {
    rows : Array<ICommodityState>;
    isLoading : boolean;
    selectedCommodityState ?: ICommodityState;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    formGuid ?: string;
    isDeletePopupOpen : boolean;
    deletingCommodityState ?: ICommodityState;
}

class CommodityStateScreen extends React.Component<ICommodityStateScreenProps, ICommodityStateScreenState> {
    constructor(props : ICommodityStateScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedCommodityState: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await CommodityStateHttpService.getCommodityStateData();

                this.props.dataSetCommodityStates(res.data);
                this.setLoading(false);
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading commodity state.');
                this.setLoading(false);
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading : loading });
    };

    private formatCommodities = (commodityIds : Array<number>) => {
        let string = this.props.commodities
            .filter(x => commodityIds.some(y => y === x.id))
            .map(x => x.code).toString().replace(/,/g, ', ');
        if (string.length > 85) {
            string = string.substr(0, 86) + '...';
        }
        return string;
    };

    public onSubmit = async (values : ICommodityStateFormValues) => {
        this.setLoading(true);

        let payload = { ...values };
        if (isNullOrWhiteSpace(payload.guid)) {
            payload.guid = this.state.formGuid ?? '';
        }

        try {
            const res = await CommodityStateHttpService.addOrUpdateCommodityState(new CommodityState(payload));

            const newCommodityStateList = upsertArrayElement(this.props.commodityStates, res.data, x => x.id === res.data.id) ?? [];

            this.props.dataSetCommodityStates(newCommodityStateList);
            await setCommodityStateMasterDataIndexedDB(newCommodityStateList);

            if (this.state.selectedCommodityState) {
                generalShowSuccessSnackbar('Commodity State updated successfully.');
            } else {
                generalShowSuccessSnackbar('Commodity State added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating Commodity State data.');
        } finally {
            this.setLoading(false);
        }
    };

    public onReset = async (formValues : ICommodityStateFormValues, formikHelpers : FormikHelpers<ICommodityStateFormValues>) => {
        formikHelpers.resetForm();
        this.closeDialog();
    };

    private openDeleteConfirmationPopup = (commodityState : ICommodityState) => {
        this.setState({ isDeletePopupOpen: true, deletingCommodityState: commodityState });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingCommodityState: undefined });
    };

    private removeCommodityState = async () => {
        const newCommodityState = this.state.deletingCommodityState;
        if (newCommodityState) {
            newCommodityState.isActive = false;
            this.setLoading(true);

            try {
                const res = await CommodityStateHttpService.deleteCommodityState(newCommodityState.id);

                const newCommodityStateList = upsertArrayElement(this.props.commodityStates, res.data, x => x.id === res.data.id) ?? [];

                this.props.dataSetCommodityStates(newCommodityStateList);
                await setCommodityStateMasterDataIndexedDB(newCommodityStateList);

                generalShowSuccessSnackbar('Commodity State updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting Commodity State.');
                newCommodityState.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    public getSelectedCommodityState = (props : ICommodityStateScreenProps, state : ICommodityStateScreenState) => state.selectedCommodityState;
    public getSelectedCommodity = (props : ICommodityStateScreenProps) => props.selectedCommodity;
    public getCommodities = (props : ICommodityStateScreenProps) => props.commodities;

    public getInitialFormValues = createSelector(
        [this.getSelectedCommodityState, this.getSelectedCommodity, this.getCommodities],
        (commodityState : ICommodityState, commodity ?: ICommodity, commodities ?: Array<ICommodity>) => {
            return new CommodityStateFormValues(commodityState, commodity, commodities);
        },
    );

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            formGuid: undefined,
            selectedCommodityState: undefined,
        });
    };

    public openDialog = () => {
        this.setState({
            formGuid: uuidv4(),
            isDialogOpen: true,
        });
    };

    private onCommodityStateEdit = (row : ICommodityState) => {
        this.setState({
            isDialogOpen: true,
            selectedCommodityState: row,
        });
    };

    private getRights = (props : ICommodityStateScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : ICommodityStateScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const selectedCommodity = this.props.selectedCommodity;
        const rows = selectedCommodity && this.props.commodityStates ? this.props.commodityStates.filter(x => x.commodityIds.some(y => y === selectedCommodity?.id)) : this.props.commodityStates;
        const initialValues = this.getInitialFormValues(this.props, this.state);

        return (
            <>
                <PackmanDialog
                    title={'Commodity State' + (this.props.selectedCommodity ? ' - (' + this.props.selectedCommodity?.code + ')' : '')}
                    isEdit={!!this.state.selectedCommodityState}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={CommodityStateFormValues.formSchema}
                        component={CommodityStateForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<ICommodityState>
                            enableAdding={!!this.props.selectedCommodity && this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.onCommodityStateEdit}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(commodityState : ICommodityState) => commodityState.isActive && this.hasEditingRight(this.props)}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            enablePagination
                            columns={[
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Commodities', field: 'commodityIds', formatFunction: this.formatCommodities, width: 300, enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            fitWidthToPage
                            initialSortOrder={[{ columnName: 'id_Id', direction : 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={340}
                            isActive={(row : ICommodityState) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationDialog
                    title={'Delete Commodity State'}
                    open={this.state.isDeletePopupOpen}
                    description ={'Are you sure you want to delete this commodity state?'}
                    onAccept ={this.removeCommodityState}
                    onClose ={this.closeDeleteConfirmationPopup}
                    dialogType='orange'
                    isLoading={this.state.isLoading}
                />
            </>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        commodityStates: state.masterData.commodityStates,
        commodities: state.masterData.commodities,
        auth: state.auth,
    };
};

const mapDispatchToProps  = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetCommodityStates }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CommodityStateScreen);
