import * as React from 'react';
import { Card } from '@mui/material';
import { DispatchCall, IAuthState, IRootState, RootAction } from '../../../@types/redux';
import { dataSetPrinters, dataSetPrintServers } from '../../../store/masterData/Actions';
import { bindActionCreators, Dispatch } from 'redux';
import { formatDateTime, isNullOrWhiteSpace, upsertArrayElement } from '../../../services/appFunctionsService';
import { generalShowErrorSnackbar, generalShowSuccessSnackbar } from '../../../store/general/Functions';
import Screen from '../../../components/Screen';
import { connect } from 'react-redux';
import PackmanDialog from '../../../components/dialog/PackmanDialog';
import { Formik, FormikHelpers } from 'formik';
import { createSelector } from 'reselect';
import { RouteComponentProps } from 'react-router';
import { IRight } from '../../../@types/model/user/right';
import { ConfirmationDialog, CustomTable } from '@zz2/zz2-ui';
import { v4 as uuidv4 } from 'uuid';
import { IPrinter, Printer } from '../../../@types/model/masterData/printer/printer';
import { IPrintServer } from '../../../@types/model/masterData/printServer/printServer';
import PrinterHttpService from '../../../services/http/masterData/printerHttpService';
import PrintServerHttpService from '../../../services/http/masterData/printServerHttpService';
import { IPrinterFormValues, PrinterFormValues } from '../../../@types/model/masterData/printer/printerFormValues';
import PrinterForm from './form/PrinterForm';
import { setPrinterMasterDataIndexedDB, syncMasterData } from '../../../services/masterDataSyncService';

interface IPrinterScreenProps extends RouteComponentProps {
    dataSetPrinters : DispatchCall<Array<IPrinter>>;
    dataSetPrintServers : DispatchCall<Array<IPrintServer>>;
    printers : Array<IPrinter>;
    printServers : Array<IPrintServer>;
    auth : IAuthState;
}

interface IPrinterScreenState {
    rows : Array<IPrinter>;
    isLoading : boolean;
    selectedPrinter ?: IPrinter;
    isAdding : boolean;
    isEditing : boolean;
    isDialogOpen : boolean;
    formGuid ?: string;
    isDeletePopupOpen : boolean;
    deletingPrinter ?: IPrinter;
}

class PrinterScreen extends React.Component<IPrinterScreenProps, IPrinterScreenState> {
    constructor(props : IPrinterScreenProps) {
        super(props);

        this.state = {
            rows: [],
            isLoading: false,
            selectedPrinter: undefined,
            isAdding: false,
            isEditing: false,
            isDialogOpen: false,
            isDeletePopupOpen: false,
        };
    }

    public componentDidMount = async () => {
        // checks if indexedDB is available.
        const isIndexedDBAvailable = !!self.indexedDB ? true : false;

        if (isIndexedDBAvailable) {
            await syncMasterData(false);
        }

        if (!isIndexedDBAvailable) {
            this.setLoading(true);
            try {
                const res = await PrinterHttpService.getPrinterData();

                const printerData = res.data;
                if (printerData) {
                    const res2 = await PrintServerHttpService.getPrintServerData();

                    const printServers = res2.data;
                    this.props.dataSetPrintServers(printServers);
                    this.props.dataSetPrinters(printerData);
                }
            } catch (e) {
                generalShowErrorSnackbar('An error occurred while loading printers.');
            } finally {
                this.setLoading(false);
            }
        }
    };

    public closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            formGuid: undefined,
            selectedPrinter: undefined,
        });
    };

    public openDialog = () => {
        this.setState({
            formGuid: uuidv4(),
            isDialogOpen: true,
        });
    };

    public editPrinter = (selectedPrinter : IPrinter) => {
        this.setState({
            isDialogOpen: true,
            selectedPrinter,
        });
        this.openDialog();
    };

    public onSubmit = async (values : IPrinterFormValues) => {
        this.setLoading(true);

        let payload = { ...values };
        if (isNullOrWhiteSpace(payload.guid)) {
            payload.guid = this.state.formGuid ?? '';
        }

        try {
            const res = await PrinterHttpService.addOrUpdatePrinter(new Printer(payload));

            const newPrinterList = upsertArrayElement(this.props.printers, res.data, x => x.id === res.data.id) ?? [];
            this.props.dataSetPrinters(newPrinterList);
            await setPrinterMasterDataIndexedDB(newPrinterList);

            if (this.state.selectedPrinter) {
                generalShowSuccessSnackbar('Printer updated successfully.');
            } else {
                generalShowSuccessSnackbar('Printer added successfully.');
            }

            this.closeDialog();
        } catch (e) {
            generalShowErrorSnackbar('An error occurred updating printer data.');
        } finally {
            this.setLoading(false);
        }
    };

    private openDeleteConfirmationPopup = (printer : IPrinter) => {
        this.setState({ isDeletePopupOpen: true, deletingPrinter: printer });
    };

    private closeDeleteConfirmationPopup = () => {
        this.setState({ isDeletePopupOpen: false, deletingPrinter: undefined });
    };

    private removePrinter = async () => {
        const newPrinter = this.state.deletingPrinter;
        if (newPrinter) {
            newPrinter.isActive = false;
            this.setLoading(true);

            try {
                const res = await PrinterHttpService.deletePrinter(newPrinter.id);

                const newPrinterList = upsertArrayElement(this.props.printers, res.data, x => x.id === res.data.id) ?? [];
                this.props.dataSetPrinters(newPrinterList);
                await setPrinterMasterDataIndexedDB(newPrinterList);

                generalShowSuccessSnackbar('Printer updated successfully.');
            } catch (e) {
                generalShowErrorSnackbar('An error occurred deleting printer.');
                newPrinter.isActive = true;
            } finally {
                this.closeDeleteConfirmationPopup();
                this.setLoading(false);
            }
        }
    };

    private setLoading = (loading : boolean = false) => {
        this.setState({ isLoading: loading });
    };

    public onReset = async (formValues : IPrinterFormValues, formikHelpers : FormikHelpers<IPrinterFormValues>) => {
        formikHelpers.resetForm();
        this.closeDialog();
    };

    public getSelectedPrinter = (props : IPrinterScreenProps, state : IPrinterScreenState) => state.selectedPrinter;

    public getInitialFormValues = createSelector(
        [this.getSelectedPrinter],
        (printer : IPrinter) => {
            return new PrinterFormValues(printer, this.props.printServers);
        },
    );

    private getPrintServerName = (printServerId : number) => {
        const printServer = this.props.printServers?.find(x => x.id === printServerId);
        return printServer?.name ?? '';
    };

    private getRights = (props : IPrinterScreenProps) => props.auth?.session?.user?.rights || [];
    private getPathName = (props : IPrinterScreenProps) => props.location.pathname;

    private hasEditingRight = createSelector(
        [this.getRights, this.getPathName],
        (rights : Array<IRight>, url : string) => rights.some(x => url.includes(x.url) && x.isActive && x.code.endsWith('_EDIT')));

    public render() {
        const rows = this.props.printers ? this.props.printers : [];
        const initialValues = this.getInitialFormValues(this.props, this.state);
        return (
            <Screen isLoading={this.state.isLoading}>
                <PackmanDialog
                    title='Printer'
                    maxWidth={'xs'}
                    fullWidth
                    isEdit={!!this.state.selectedPrinter}
                    isLoading={this.state.isLoading}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.closeDialog}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onReset={this.onReset}
                        enableReinitialize
                        validationSchema={PrinterFormValues.formSchema}
                        component={PrinterForm} />
                </PackmanDialog >
                <div className={'fdc hfill'}>
                    <Card className={'fdc hfill'}>
                        <CustomTable<IPrinter>
                            enableAdding={this.hasEditingRight(this.props)}
                            addFunction={this.openDialog}
                            editFunction={this.editPrinter}
                            enableEditing={this.hasEditingRight(this.props)}
                            enableDeleting={(printer : IPrinter) => printer.isActive && this.hasEditingRight(this.props)}
                            deleteFunction={this.openDeleteConfirmationPopup}
                            enableSorting
                            enableFiltering
                            fitWidthToPage
                            enablePagination
                            columns={[
                                { title: 'Name', field: 'name', enableFiltering: true, enableSorting: true },
                                { title: 'Default Paper', field: 'defaultPaper', enableFiltering: true, enableSorting: true },
                                { title: 'API Address', field: 'apiAddress', enableFiltering: true, enableSorting: true },
                                { title: 'Print Server', field: 'printServerId', formatFunction: this.getPrintServerName, enableFiltering: true, enableSorting: true },
                                { title: 'Created By', field: 'createdByName', enableFiltering: true, enableSorting: true },
                                { title: 'Created On', field: 'createdOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Updated By', field: 'updatedByName', enableFiltering: true, enableSorting: true },
                                { title: 'Updated On', field: 'updatedOn', type: 'dateTime', formatFunction: formatDateTime, enableFiltering: true, enableSorting: true },
                                { title: 'Active?', field: 'isActive', type: 'boolean', enableFiltering: true, enableSorting: true },
                            ]}
                            rows={rows}
                            initialSortOrder={[{ columnName: 'id_Id', direction: 'asc' }]}
                            pageSizes={[50, 150, 250, 500, 1000]}
                            pageHeight={190}
                            isActive={(row : IPrinter) => row.isActive}
                        />
                    </Card>
                </div>
                <ConfirmationDialog
                    title={'Delete Printer'}
                    open={this.state.isDeletePopupOpen}
                    description={'Are you sure you want to delete this printer?'}
                    onAccept ={this.removePrinter}
                    onClose ={this.closeDeleteConfirmationPopup}
                    dialogType='orange'
                    isLoading={this.state.isLoading}
                />
            </Screen>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        printers: state.masterData.printers,
        printServers: state.masterData.printServers,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { dataSetPrinters, dataSetPrintServers }, dispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PrinterScreen);
