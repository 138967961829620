import axios, { AxiosPromise } from 'axios';
import { PACKMAN_API_URL } from '../../../appConstants';
import { IBrand } from '../../../@types/model/masterData/brand/brand';

export default class MarkHttpService {
    public static getMarkData() : AxiosPromise<Array<IBrand>> {
        return axios.get(`${PACKMAN_API_URL}Mark/GetAllWeb`);
    }

    public static addOrUpdateBrand(data : IBrand) : AxiosPromise<IBrand> {
        return axios.post(`${PACKMAN_API_URL}Mark/PostMark`, data);
    }

    public static deleteBrand(brandId : number) : AxiosPromise<IBrand> {
        return axios.delete(`${PACKMAN_API_URL}Mark/Delete`, {
            params : { brandId },
        });
    }
}
